export const Configuration_ProviderStatuses = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
} as const;

export type Configuration_ProviderStatuses = typeof Configuration_ProviderStatuses[keyof typeof Configuration_ProviderStatuses];
export const Core_AddressTypes = {
  HOME: 'HOME',
  WORK: 'WORK'
} as const;

export type Core_AddressTypes = typeof Core_AddressTypes[keyof typeof Core_AddressTypes];
export const Core_CoNssfOptions = {
  NO: 'NO',
  YES: 'YES'
} as const;

export type Core_CoNssfOptions = typeof Core_CoNssfOptions[keyof typeof Core_CoNssfOptions];
export const Core_CommissionTypes = {
  RATE: 'RATE',
  VALUE: 'VALUE'
} as const;

export type Core_CommissionTypes = typeof Core_CommissionTypes[keyof typeof Core_CommissionTypes];
export const Core_CoverPremiumTypes = {
  PERCENTAGE: 'PERCENTAGE',
  VALUE: 'VALUE'
} as const;

export type Core_CoverPremiumTypes = typeof Core_CoverPremiumTypes[keyof typeof Core_CoverPremiumTypes];
export const Core_CurrencyStatuses = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
} as const;

export type Core_CurrencyStatuses = typeof Core_CurrencyStatuses[keyof typeof Core_CurrencyStatuses];
export const Core_ExcessOnClaimTypes = {
  DAYS: 'DAYS',
  PERCENTAGE: 'PERCENTAGE',
  VALUE: 'VALUE'
} as const;

export type Core_ExcessOnClaimTypes = typeof Core_ExcessOnClaimTypes[keyof typeof Core_ExcessOnClaimTypes];
export const Core_IsSmoker = {
  NO: 'NO',
  YES: 'YES'
} as const;

export type Core_IsSmoker = typeof Core_IsSmoker[keyof typeof Core_IsSmoker];
export const Core_MaritalStatuses = {
  DIVORCED: 'DIVORCED',
  MARRIED: 'MARRIED',
  SINGLE: 'SINGLE'
} as const;

export type Core_MaritalStatuses = typeof Core_MaritalStatuses[keyof typeof Core_MaritalStatuses];
export const Core_PhoneTypes = {
  LANDLINE: 'LANDLINE',
  MOBILE: 'MOBILE'
} as const;

export type Core_PhoneTypes = typeof Core_PhoneTypes[keyof typeof Core_PhoneTypes];
export const Core_ReplacementCarAppliedOptions = {
  NO: 'NO',
  YES: 'YES'
} as const;

export type Core_ReplacementCarAppliedOptions = typeof Core_ReplacementCarAppliedOptions[keyof typeof Core_ReplacementCarAppliedOptions];
export const Core_SosServiceOptions = {
  NO: 'NO',
  YES: 'YES'
} as const;

export type Core_SosServiceOptions = typeof Core_SosServiceOptions[keyof typeof Core_SosServiceOptions];
export const Core_Statuses = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
} as const;

export type Core_Statuses = typeof Core_Statuses[keyof typeof Core_Statuses];
export const Core_TpaApplicableOnOptions = {
  GROSS_PREMIUM: 'GROSS_PREMIUM',
  NET_PREMIUM: 'NET_PREMIUM'
} as const;

export type Core_TpaApplicableOnOptions = typeof Core_TpaApplicableOnOptions[keyof typeof Core_TpaApplicableOnOptions];
export const Core_TpaTypes = {
  AMOUNT: 'AMOUNT',
  PERCENTAGE: 'PERCENTAGE'
} as const;

export type Core_TpaTypes = typeof Core_TpaTypes[keyof typeof Core_TpaTypes];
export const Insurance_AgencyRepairs = {
  NO: 'NO',
  YES: 'YES'
} as const;

export type Insurance_AgencyRepairs = typeof Insurance_AgencyRepairs[keyof typeof Insurance_AgencyRepairs];
export const Insurance_AgencyStatuses = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
} as const;

export type Insurance_AgencyStatuses = typeof Insurance_AgencyStatuses[keyof typeof Insurance_AgencyStatuses];
export const Insurance_AgentAccesses = {
  ISSUE_POLICIES: 'ISSUE_POLICIES',
  QUOTING: 'QUOTING'
} as const;

export type Insurance_AgentAccesses = typeof Insurance_AgentAccesses[keyof typeof Insurance_AgentAccesses];
export const Insurance_AgentStatuses = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
} as const;

export type Insurance_AgentStatuses = typeof Insurance_AgentStatuses[keyof typeof Insurance_AgentStatuses];
export const Insurance_AgentTypes = {
  EXTERNAL: 'EXTERNAL',
  INTERNAL: 'INTERNAL'
} as const;

export type Insurance_AgentTypes = typeof Insurance_AgentTypes[keyof typeof Insurance_AgentTypes];
export const Insurance_AmbulatoryIncludedCheck = {
  NO: 'NO',
  YES: 'YES'
} as const;

export type Insurance_AmbulatoryIncludedCheck = typeof Insurance_AmbulatoryIncludedCheck[keyof typeof Insurance_AmbulatoryIncludedCheck];
export const Insurance_ApplicationStatusReasons = {
  DRAFT: 'DRAFT',
  POLICY_ISSUED: 'POLICY_ISSUED',
  QUOTE_ACCEPTED: 'QUOTE_ACCEPTED',
  WON: 'WON'
} as const;

export type Insurance_ApplicationStatusReasons = typeof Insurance_ApplicationStatusReasons[keyof typeof Insurance_ApplicationStatusReasons];
export const Insurance_ApplicationStatuses = {
  CLOSED: 'CLOSED',
  NEW: 'NEW',
  QUOTE_CONFIRMATION: 'QUOTE_CONFIRMATION',
  QUOTE_PREPARATION: 'QUOTE_PREPARATION'
} as const;

export type Insurance_ApplicationStatuses = typeof Insurance_ApplicationStatuses[keyof typeof Insurance_ApplicationStatuses];
export const Insurance_CoverageTypes = {
  LUX_PLAN: 'LUX_PLAN',
  STANDARD_PLAN: 'STANDARD_PLAN'
} as const;

export type Insurance_CoverageTypes = typeof Insurance_CoverageTypes[keyof typeof Insurance_CoverageTypes];
export const Insurance_CustomerClassifications = {
  APPLICATION_CONTACT_DETAILS: 'APPLICATION_CONTACT_DETAILS',
  LEAD: 'LEAD',
  PROSPECT: 'PROSPECT'
} as const;

export type Insurance_CustomerClassifications = typeof Insurance_CustomerClassifications[keyof typeof Insurance_CustomerClassifications];
export const Insurance_CustomerStatuses = {
  ACTIVE: 'ACTIVE',
  DRAFT: 'DRAFT',
  INACTIVE: 'INACTIVE'
} as const;

export type Insurance_CustomerStatuses = typeof Insurance_CustomerStatuses[keyof typeof Insurance_CustomerStatuses];
export const Insurance_Genders = {
  FEMALE: 'FEMALE',
  MALE: 'MALE'
} as const;

export type Insurance_Genders = typeof Insurance_Genders[keyof typeof Insurance_Genders];
export const Insurance_InsuredRelations = {
  CHILD: 'CHILD',
  PRINCIPAL: 'PRINCIPAL',
  SPOUSE: 'SPOUSE'
} as const;

export type Insurance_InsuredRelations = typeof Insurance_InsuredRelations[keyof typeof Insurance_InsuredRelations];
export const Insurance_OccupationStatuses = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
} as const;

export type Insurance_OccupationStatuses = typeof Insurance_OccupationStatuses[keyof typeof Insurance_OccupationStatuses];
export const Insurance_PhoneType = {
  LANDLINE: 'LANDLINE',
  MOBILE: 'MOBILE'
} as const;

export type Insurance_PhoneType = typeof Insurance_PhoneType[keyof typeof Insurance_PhoneType];
export const Insurance_QuoteStatusReasons = {
  ACCEPTED: 'ACCEPTED',
  AGE_NOT_SUPPORTED_FOR_NEW_POLICY: 'AGE_NOT_SUPPORTED_FOR_NEW_POLICY',
  DECLINED: 'DECLINED',
  DRAFT: 'DRAFT',
  EXPIRED: 'EXPIRED',
  NOT_SUPPORTED_PLAN: 'NOT_SUPPORTED_PLAN',
  SENT: 'SENT'
} as const;

export type Insurance_QuoteStatusReasons = typeof Insurance_QuoteStatusReasons[keyof typeof Insurance_QuoteStatusReasons];
export const Insurance_QuoteStatuses = {
  CLOSED: 'CLOSED',
  INELIGIBLE: 'INELIGIBLE',
  PENDING: 'PENDING',
  REVISED: 'REVISED',
  REVISION_SENT: 'REVISION_SENT',
  SENT: 'SENT',
  UNDERWRITING: 'UNDERWRITING'
} as const;

export type Insurance_QuoteStatuses = typeof Insurance_QuoteStatuses[keyof typeof Insurance_QuoteStatuses];
export const Insurance_RangeStatuses = {
  ACTIVE: 'ACTIVE',
  CONSUMED: 'CONSUMED',
  READY: 'READY'
} as const;

export type Insurance_RangeStatuses = typeof Insurance_RangeStatuses[keyof typeof Insurance_RangeStatuses];
export const Insurance_RangeTypes = {
  EXPAT: 'EXPAT',
  MOTOR: 'MOTOR'
} as const;

export type Insurance_RangeTypes = typeof Insurance_RangeTypes[keyof typeof Insurance_RangeTypes];
export const Insurance_RenewalStatus = {
  NO: 'NO',
  YES: 'YES'
} as const;

export type Insurance_RenewalStatus = typeof Insurance_RenewalStatus[keyof typeof Insurance_RenewalStatus];
export const Insurance_UserRoles = {
  ADMIN: 'ADMIN',
  UNDERWRITER: 'UNDERWRITER'
} as const;

export type Insurance_UserRoles = typeof Insurance_UserRoles[keyof typeof Insurance_UserRoles];
export const Insurance_UserStatuses = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
} as const;

export type Insurance_UserStatuses = typeof Insurance_UserStatuses[keyof typeof Insurance_UserStatuses];
export const Insurance_VignetteRangeStatuses = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
} as const;

export type Insurance_VignetteRangeStatuses = typeof Insurance_VignetteRangeStatuses[keyof typeof Insurance_VignetteRangeStatuses];
export const PlanConfigManagement_ClauseStatuses = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
} as const;

export type PlanConfigManagement_ClauseStatuses = typeof PlanConfigManagement_ClauseStatuses[keyof typeof PlanConfigManagement_ClauseStatuses];
export const PlanConfigManagement_LineStatuses = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
} as const;

export type PlanConfigManagement_LineStatuses = typeof PlanConfigManagement_LineStatuses[keyof typeof PlanConfigManagement_LineStatuses];
export const PlanConfigManagement_PlanStatuses = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
} as const;

export type PlanConfigManagement_PlanStatuses = typeof PlanConfigManagement_PlanStatuses[keyof typeof PlanConfigManagement_PlanStatuses];
export const PlanConfigManagement_SublineStatuses = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
} as const;

export type PlanConfigManagement_SublineStatuses = typeof PlanConfigManagement_SublineStatuses[keyof typeof PlanConfigManagement_SublineStatuses];
export const System_Engine_Health_Status = {
  CRITICAL: 'CRITICAL',
  GOOD: 'GOOD',
  INFORMATION: 'INFORMATION',
  WARNING: 'WARNING'
} as const;

export type System_Engine_Health_Status = typeof System_Engine_Health_Status[keyof typeof System_Engine_Health_Status];
export const System_Metadata_Logtype = {
  ERROR: 'ERROR',
  INFORMATIONAL: 'INFORMATIONAL',
  WARNING: 'WARNING'
} as const;

export type System_Metadata_Logtype = typeof System_Metadata_Logtype[keyof typeof System_Metadata_Logtype];
export const SalesforceManagement_AssignedBusinessPartnerPlanStatuses = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
} as const;

export type SalesforceManagement_AssignedBusinessPartnerPlanStatuses = typeof SalesforceManagement_AssignedBusinessPartnerPlanStatuses[keyof typeof SalesforceManagement_AssignedBusinessPartnerPlanStatuses];
export const SalesforceManagement_BusinessPartnerLineStatuses = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
} as const;

export type SalesforceManagement_BusinessPartnerLineStatuses = typeof SalesforceManagement_BusinessPartnerLineStatuses[keyof typeof SalesforceManagement_BusinessPartnerLineStatuses];
export const SalesforceManagement_BusinessPartnerPlanStatuses = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
} as const;

export type SalesforceManagement_BusinessPartnerPlanStatuses = typeof SalesforceManagement_BusinessPartnerPlanStatuses[keyof typeof SalesforceManagement_BusinessPartnerPlanStatuses];
export const SalesforceManagement_BusinessPartnerStatuses = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  NEW: 'NEW'
} as const;

export type SalesforceManagement_BusinessPartnerStatuses = typeof SalesforceManagement_BusinessPartnerStatuses[keyof typeof SalesforceManagement_BusinessPartnerStatuses];
export const SalesforceManagement_BusinessUserStatuses = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  NEW: 'NEW'
} as const;

export type SalesforceManagement_BusinessUserStatuses = typeof SalesforceManagement_BusinessUserStatuses[keyof typeof SalesforceManagement_BusinessUserStatuses];
export const SalesforceManagement_PersonStatuses = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
} as const;

export type SalesforceManagement_PersonStatuses = typeof SalesforceManagement_PersonStatuses[keyof typeof SalesforceManagement_PersonStatuses];
export const SalesforceManagement_PersonTypes = {
  COMPANY: 'COMPANY',
  PERSON: 'PERSON'
} as const;

export type SalesforceManagement_PersonTypes = typeof SalesforceManagement_PersonTypes[keyof typeof SalesforceManagement_PersonTypes];
export const SalesforceManagement_VignetteRangeStatuses = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
} as const;

export type SalesforceManagement_VignetteRangeStatuses = typeof SalesforceManagement_VignetteRangeStatuses[keyof typeof SalesforceManagement_VignetteRangeStatuses];
/** An enum describing what kind of type a given `__Type` is. */
export const __TypeKind = {
  /** Indicates this type is a scalar. */
  SCALAR: 'SCALAR',
  /** Indicates this type is an object. `fields` and `interfaces` are valid fields. */
  OBJECT: 'OBJECT',
  /** Indicates this type is an interface. `fields`, `interfaces`, and `possibleTypes` are valid fields. */
  INTERFACE: 'INTERFACE',
  /** Indicates this type is a union. `possibleTypes` is a valid field. */
  UNION: 'UNION',
  /** Indicates this type is an enum. `enumValues` is a valid field. */
  ENUM: 'ENUM',
  /** Indicates this type is an input object. `inputFields` is a valid field. */
  INPUT_OBJECT: 'INPUT_OBJECT',
  /** Indicates this type is a list. `ofType` is a valid field. */
  LIST: 'LIST',
  /** Indicates this type is a non-null. `ofType` is a valid field. */
  NON_NULL: 'NON_NULL'
} as const;

export type __TypeKind = typeof __TypeKind[keyof typeof __TypeKind];