export interface IBusinessPartnerDetailsPageProps {}

export interface IBusinessPartnerDetails {
  agency: string;
  status: string;
  type: string;
  companyCode: string;
  MTPCommissionPercentage: number;
  MBICommissionPercentage: number;
  ExpatCommissionPercentage: number;
}

export const initialValues: IBusinessPartnerDetails = {
  agency: "",
  status: "",
  type: "",
  companyCode: "",
  MTPCommissionPercentage: 0,
  MBICommissionPercentage: 0,
  ExpatCommissionPercentage: 0,
};

export interface IGenericVignetteTableData {
  insurance_AgencyVignetteRange_Id: string;
  insurance_AgencyVignetteRange_RangeYear: string;
  insurance_AgencyVignetteRange_RangeCode: string;
  insurance_AgencyVignetteRange_RangeFrom: string;
  insurance_AgencyVignetteRange_RangeTo: string;
  insurance_AgencyVignetteRange_RangeStatus: string;
}
