import React, { useEffect, useState } from 'react';
import { IListingData } from '../../models/listing';
import StaticLayout from '../../page-layout/static-layout/StaticLayout';
import { useQuery } from '@apollo/client';
import { filterQuery, listQuery } from './queries';
import { dataToLovs, mapToListingData } from './utils';
import { actions, filterSectionsContent, headers } from './content';
import EnhancedTable from '../../components/enhanced-table/EnhancedTable';
import { IListingFilterWidgetSection } from '../../components/widgets/custom-listing-filter';
import ListingFilterWidget from '../../components/widgets/custom-listing-filter/ListingFilterWidget';
import { IAbstractRecord } from '../../models';
import _, { isEmpty } from 'lodash';
import { getFilter, setFilter } from '../../utils/filter-utils';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { toast } from 'react-toastify';
import AgentDrawer from '../../forms/agent-drawer/AgentDrawer';
import { IEnhanceTableHeaderClickable } from '../../components/enhanced-table';
import { DEFAULT_ERROR_TEXT } from '../../constants';

interface IAgentsPage {}

const AgentsPage: React.FC<IAgentsPage> = () => {
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [tableLoadingState, setTableLoadingState] = useState<boolean>(false);
  const [agentDrawerOpen, setAgentDrawerOpen] = useState<boolean>(false);
  const [chosenEditId, setChosenEditId] = useState<string>('');

  const FILTER_SESSION_KEY = 'agentsFilter';

  const [filterSections, setFilterSections] =
    useState<IListingFilterWidgetSection[]>();

  const initialfilterValues =
    Object.keys(getFilter(FILTER_SESSION_KEY)).length > 0
      ? getFilter(FILTER_SESSION_KEY)
      : {
          agency: [],
          status: ['ACTIVE'],
          type: ['INTERNAL'],
          access: ['QUOTING', 'ISSUE_POLICIES'],
        };

  const [filterValues, setFilterValues] =
    useState<IAbstractRecord>(initialfilterValues);

  const [tableData, setTableData] = useState<IListingData<any>>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 0,
    totalCount: 0,
  });

  const { loading, error, data, fetchMore } = useQuery(listQuery(), {
    variables: {
      currentPage: 1,
      currentPageSize: tableData.pageSize,
      agencyID:
        filterValues?.agency && filterValues?.agency?.length > 0
          ? filterValues?.agency
          : null,
      agentStatus:
        filterValues?.status && filterValues?.status?.length > 0
          ? filterValues?.status
          : [],
      agentType:
        filterValues?.type && filterValues?.type?.length > 0
          ? filterValues.type
          : [],
      agentAccesses:
        filterValues?.access && filterValues?.access?.length > 0
          ? filterValues.access
          : [],
    },
    errorPolicy: 'ignore', //temporary until date issue fixed from the backend
  });

  actions[0].onClick = () => {
    setChosenEditId(undefined);
    setAgentDrawerOpen(true);
  };
  (headers.insurance_Agent_FirstName as IEnhanceTableHeaderClickable).callback =
    (payload: any) => {
      setChosenEditId(payload.columns.insurance_Agent_Id);
      setAgentDrawerOpen(true);
    };

  const filterResponse = useQuery(filterQuery(), { fetchPolicy: 'no-cache' });
  useEffect(() => {
    if (filterResponse?.loading) setLoadingState(true);
    if (filterResponse?.error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
    if (filterResponse?.data) {
      let savedFilters = getFilter(FILTER_SESSION_KEY);
      if (isEmpty(savedFilters)) {
        savedFilters = _.cloneDeep(filterValues);
      }
      setFilterValues(savedFilters);

      const lovs = dataToLovs(filterResponse.data);

      if (!Object.keys(lovs.agency).length) {
        lovs.agency = filterValues?.agency;
      }

      if (!Object.keys(lovs.status).length) {
        lovs.status = filterValues?.status;
      }

      if (!Object.keys(lovs.type).length) {
        lovs.type = filterValues?.type;
      }

      if (!Object.keys(lovs.access).length) {
        lovs.access = filterValues?.access;
      }

      const newFilterSections = filterSectionsContent(lovs, savedFilters);
      setFilterSections(newFilterSections);
    }
    setLoadingState(false);
  }, [filterResponse]);

  useEffect(() => {
    if (loading) setLoadingState(true);
    if (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
    if (data) {
      const tableData = mapToListingData(data);
      setTableData({ ...tableData, pageNumber: 0 });
    }
    setLoadingState(false);
  }, [loading, error, data]);

  function handlePageChange(page: number) {
    setTableLoadingState(true);
    setTableData({ ...tableData, pageNumber: tableData.pageNumber });
    fetchMore({
      variables: {
        currentPage: ++page,
        currentPageSize: tableData.pageSize,
      },
    })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const updatedTableData = {
            ...mapToListingData(fetchMoreResult.data),
            pageNumber: --page,
          };
          setTableData(updatedTableData);
        }
      })
      .finally(() => {
        setTableLoadingState(false);
      });
  }

  function handleRowsPerPageChange(numberOfRecordsPerPage: number) {
    setTableLoadingState(true);
    setTableData({ ...tableData, pageNumber: 1 });
    fetchMore({
      variables: {
        currentPage: 1,
        currentPageSize: numberOfRecordsPerPage,
      },
    })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const updatedTableData = {
            ...mapToListingData(fetchMoreResult.data),
            pageNumber: 0,
            pageSize: numberOfRecordsPerPage,
          };
          setTableData(updatedTableData);
        }
      })
      .finally(() => {
        setTableLoadingState(false);
      });
  }

  const renderMainChildren = () => {
    return (
      <>
        {loading ? (
          <div></div>
        ) : (
          <div style={{ marginTop: '20px' }}>
            <EnhancedTable
              title='Business Users'
              name='count'
              orderByAscendingByDefault
              defaultOrderByColumn='name'
              inline={false}
              data={tableData}
              headers={headers}
              handlePageChange={(page: number) => handlePageChange(page)}
              handleRowsPerPageChange={(page: number) =>
                handleRowsPerPageChange(page)
              }
              showCellFullData = {true}
              currentPage={tableData.pageNumber}
              hideToolbar
              usePagination
              disableSelection
              actions={actions}
              loader={tableLoadingState}
            />
            {agentDrawerOpen && (
              <AgentDrawer
                open={agentDrawerOpen}
                onClose={() => setAgentDrawerOpen(false)}
                onSuccess={() => {
                  handlePageChange(0);
                }}
                agentId={chosenEditId}
              />
            )}
          </div>
        )}
      </>
    );
  };

  const onFilterUpdate = async (v: Record<string, any>) => {
    let newFilters = _.cloneDeep(v);
    setFilter(newFilters, FILTER_SESSION_KEY);
    setFilterValues(newFilters);
  };

  const renderFilter = () => {
    return (
      filterSections && (
        <ListingFilterWidget
          name={'Business Users Filter'}
          filters={filterSections}
          onApplyFilter={(v) => {
            onFilterUpdate(v);
          }}
        ></ListingFilterWidget>
      )
    );
  };

  return (
    <StaticLayout
      loading={loading || loadingState}
      name={'Business Users'}
      leftChildren={renderFilter()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default AgentsPage;
