import { gql } from "@apollo/client";

export function getEnvironmentSettings() {
  return gql`
    mutation getEnvironmentSettings {
      insurance {
        actions {
          getEnvironmentSettings {
            IssueDateReference
          }
        }
      }
    }
  `;
}
