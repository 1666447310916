import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { MAIN_ONE_THEME, mainFontFamilyMedium } from '../constants';
import { IApplication } from '../Shell';

interface INavMenuProps {
  applications: Record<string, IApplication>;
  currentApp: string | undefined;
}

const useStyles = makeStyles()(() => ({
  menu: {
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'normal',
    height: '100%',
  },
  currentApp: {
    height: '50%',
    minHeight: 20,
    borderRight: `1px solid ${MAIN_ONE_THEME.palette.secondary4.lighter}`,
    paddingRight: 20,
    verticalAlign: 'middle',
    display: 'flex',
    fontSize: MAIN_ONE_THEME.typography.regular.reg3.fontSize,
    alignItems: 'center',
    lineHeight: 20,
    fontFamily: mainFontFamilyMedium,
    color: MAIN_ONE_THEME.palette.secondary4.light,
    minWidth: 'fit-content',
  },
  link: {
    color: 'white',
    opacity: 0.7,
    fontSize: MAIN_ONE_THEME.typography.regular.reg1.fontSize,
    lineHeight: '15px',
    textDecoration: 'none',
    minWidth: 70,
    padding: '0 20px 2px 20px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'max-content',
    fontFamily: mainFontFamilyMedium,
  },
  activeLink: {
    opacity: 1,
    background: 'rgba(255, 255, 255, 0.19)',
    borderBottom: '2px solid white',
    padding: '0 20px',
    fontFamily: mainFontFamilyMedium,
    lineHeight: '15px',
  },
}));

const NavMenu: React.FC<INavMenuProps> = ({ currentApp, applications }) => {
  const { classes } = useStyles();

  return currentApp && applications[currentApp]?.modules ? (
    <>
      <span className={classes.currentApp}>
        {applications[currentApp].title}
      </span>
      <nav className={classes.menu}>
        {Object.keys(applications[currentApp].modules)
          .filter(
            (moduleName) => !applications[currentApp].modules[moduleName].hidden
          )
          .map((moduleName) => (
            <NavLink
              className={({ isActive }) =>
                clsx(classes.link, isActive ? classes.activeLink : undefined)
              }
              key={moduleName}
              to={
                applications[currentApp].route +
                applications[currentApp].modules[moduleName].route
              }
            >
              {applications[currentApp].modules[moduleName].title}
            </NavLink>
          ))}
      </nav>
    </>
  ) : (
    <></>
  );
};

export default NavMenu;
