import React from 'react';
import { makeStyles } from 'tss-react/mui';
import EntityInfoFields, { IEntityInfoFieldsProps } from './EntityInfoFields';
import EntityInfoHeader, { IEntityInfoHeaderProps } from './EntityInfoHeader';

export interface IEntityInfoDetailsProps
  extends IEntityInfoFieldsProps,
    IEntityInfoHeaderProps {
  // actions?: Action[];
}

const useStyles = makeStyles()(() => ({
  detailsContainer: {
    width: '100%',
    margin: '20px auto 0',
    maxWidth: 280,
    boxSizing: 'border-box',
    padding: '0',
  },
}));

const EntityInfoDetails: React.FC<IEntityInfoDetailsProps> = ({
  view,
  ...props
}) => {
  const { classes } = useStyles();

  return (
    <div className={classes.detailsContainer}>
      <EntityInfoHeader {...props} />
      <EntityInfoFields view={view} />
    </div>
  );
};

export default EntityInfoDetails;
