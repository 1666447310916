import { gql } from "@apollo/client";

export function allAgencies() {
  return gql`
    query allAgencies {
      Insurance {
        queries {
          allAgencies {
            insurance_Agency_Id
            insurance_Agency_AgencyName
          }
        }
      }
    }
  `;
}

export function agencyAgents() {
  return gql`
    query listAgencyAgents($agentId: String!) {
      Insurance {
        queries {
          listAgencyAgents(AgencyID: $agentId) {
            insurance_Agent_Id
            insurance_Agent_FirstName
            insurance_Agent_LastName
          }
        }
      }
    }
  `;
}

export function createActionAsUnderwriter() {
  return gql`
    mutation createActionAsUnderwriter(
      $agencyId: String!
      $agentId: String!
      $lineOfBusiness: String!
    ) {
      insurance {
        actions {
          createApplicationAsUnderwriter(
            agencyId: $agencyId
            agentId: $agentId
            lineOfBusiness: $lineOfBusiness
          ) {
            id
          }
        }
      }
    }
  `;
}
