import React from 'react';
import EnhancedBackdrop from '../components/common/EnhancedBackdrop';
import Loader from '../components/Loader';
import { HEADER_HEIGHT } from '../constants';

interface IPageLayoutLoaderProps {
  show: boolean;
  top?: string;
}

const PageLayoutLoader: React.FC<IPageLayoutLoaderProps> = ({
  show,
  top = HEADER_HEIGHT,
}) => {
  return show ? (
    <div
      style={{
        zIndex: 100,
        position: 'fixed',
        top: '60px',
        left: 0,
        right: 0,
        bottom: 0,
      }}
    >
      <EnhancedBackdrop open style={{ top, zIndex: 11 }} />
      <div
        style={{
          zIndex: 12,
          position: 'fixed',
          top: '60px',
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Loader />
      </div>
    </div>
  ) : (
    <></>
  );
};

export default PageLayoutLoader;
