import clsx from "clsx";
import React from "react";
import { makeStyles } from "tss-react/mui";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import { IEnhancedCommonProps } from "..";
import { MAIN_ONE_THEME, contentFontFamilyRegular } from "../../constants";

export interface IEnhancedInputWrapperProps extends IEnhancedCommonProps {
  name: string;
  title: string;
  description?: string;
  error?: string;
  childrenClass?: string;
  children?: React.ReactNode;
  customStyles?: {
    labelStyles?: string;
  };
  actions?: JSX.Element;
  hidden?: boolean;
  hasTooltip?: boolean;
  tooltipText?: string;
}

const useStyles = makeStyles()((theme) => ({
  labelWrapper: {
    display: "block",
    textAlign: "left",
    fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px`,
    lineHeight: "15px",
  },
  label: {
    display: "block",
    color: MAIN_ONE_THEME.palette.primary1.main,
    textAlign: "left",
    margin: "0 0 13px",
    fontFamily: contentFontFamilyRegular,
  },
  description: {
    fontSize: MAIN_ONE_THEME.typography.small.sm2.fontSize,
    fontStyle: "italic",
    marginLeft: "5px",
    color: MAIN_ONE_THEME.palette.primary1.main,
  },
  error: {
    textAlign: "right",
    fontSize: 11,
    minHeight: "15px",
    display: "block",
    color: MAIN_ONE_THEME.palette.error.main,
    margin: "5px 0 0 ",
  },
  childrenContainer: {
    position: "relative",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    direction: "ltr",
  },
  actionsContainer: {
    display: "flex",
    flexDirection: "row",
  },
  tooltipIcon: {
    marginLeft: "5px",
    cursor: "pointer",
    width: "12px",
    height: "12px",
  },
  tooltip: {
    backgroundColor: "white",
    color: "black",
    boxShadow: theme.shadows[1],
    fontSize: "12px",
    padding: "10px",
  },
  arrow: {
    color: "white",
    "&::before": {
      boxShadow: theme.shadows[1],
    },
  },
}));

const EnhancedInputsWrapper: React.FC<IEnhancedInputWrapperProps> = ({
  className,
  error,
  style,
  name,
  title,
  description,
  children,
  childrenClass,
  customStyles,
  actions = <></>,
  hidden = false,
  hasTooltip = false,
  tooltipText,
}) => {
  const { classes } = useStyles();

  return (
    !hidden && (
      <label
        htmlFor={name}
        className={clsx(classes.labelWrapper, className)}
        style={style}
      >
        <div className={classes.headerContainer}>
          <span
            className={clsx(
              classes.label,
              customStyles && customStyles.labelStyles
            )}
          >
            {title}
            {hasTooltip && tooltipText !== "" && (
              <Tooltip
                title={tooltipText || ""}
                placement="top"
                classes={{
                  tooltip: classes.tooltip,
                  arrow: classes.arrow,
                }}
                arrow
              >
                <InfoIcon className={classes.tooltipIcon} />
              </Tooltip>
            )}
          </span>
          <div className={classes.actionsContainer}>{actions}</div>
        </div>
        <div className={clsx(classes.childrenContainer, childrenClass)}>
          {children}
          <span id={`errorMsg-${name}`} className={classes.error}>
            {error}
          </span>
        </div>
      </label>
    )
  );
};

export default EnhancedInputsWrapper;
