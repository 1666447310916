import React, { useEffect, useState } from 'react';
import GenericDrawer from '../../components/common/generic-drawer/GenericDrawer';
import DynamicForm from '../../DynamicForm/DynamicForm';
import {
  DynamicFormInputType,
  IFormNumberDynamicProps,
  IFormSelectDynamicProps,
  IFormTextDynamicProps,
} from '../../DynamicForm';
import { toast } from 'react-toastify';
import { EnhancedButtonStatus } from '../../components/common/EnhancedButton';
import { normaliseDynamicValues } from '../../utils/dynamic-utils';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { inputs } from './content';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  checkPolicyCoverExists,
  getPolicyCoverEnums,
  getPolicyCoverInfo,
  checkPolicyCoverPrintingOrderExists,
  createPolicyCover,
  updatePolicyCover,
} from './queries';
import { LookupToList, graphqlEntityToPolicyCoverInfo } from './utils';
import Loader from '../../components/Loader';
import { cloneDeep } from 'lodash';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import { isEmpty } from '../../utils/validationUtils';
import { DEFAULT_ERROR_TEXT } from '../../constants';
import { getError } from '../../utils/graph-utils';
import { useNavigate } from 'react-router-dom';

const PolicyCoverDrawer: React.FC<IPolicyCoverDrawerProps> = ({
  policyCoverId,
  lineId,
  lineName,
  isLineNameDisabled = true,
  canNavigateToDetailsPage = false,
  open,
  onSuccess,
  onClose,
}) => {
  let policyCoverInfoResult: any;

  const navigate = useNavigate();

  const [hasPolicyCoverNameError, setHasPolicyCoverNameError] = useState('');
  const [hasPolicyCoverExternalCodeError, setHasPolicyCoverExternalCodeError] =
    useState('');
  const [
    hasPolicyCoverPrintingOrderError,
    setHasPolicyCoverPrintingOrderError,
  ] = useState('');
  let policyCoverNameError = '';
  let policyCoverExternalCodeError = '';
  let policyCoverPrintingOrderError = '';
  let [selectedLineId, setSelectedLineId] = useState('');

  let [submitButtonDisabled, setSubmitButtonDisabled] =
    useState<boolean>(false);

  const policyCoverEnumResults = useQuery(getPolicyCoverEnums(), {
    fetchPolicy: 'no-cache',
  });

  const [policyCoverAction] = useMutation(
    policyCoverId ? updatePolicyCover() : createPolicyCover(),
  );

  let updatedInputs: Record<string, DynamicFormInputType> = inputs;

  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();
  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);

  if (policyCoverId) {
    policyCoverInfoResult = useQuery(getPolicyCoverInfo(), {
      variables: { id: policyCoverId },
    });
  }

  function handleLineSelection(selectedOption: any) {
    selectedLineId = selectedOption;
    validatePolicyCoverExistsOnNameChange(
      selectedLineId,
      updatedInputs.policyCoverName.value,
    );
    validatePolicyCoverExistsOnExternalCodeChange(
      selectedLineId,
      updatedInputs.policyCoverExternalCode.value,
    );
    validatePolicyCoverPrintingOrderExistsOnPrintingOrderChange(
      selectedLineId,
      updatedInputs.policyCoverPrintingOrder.value,
    );
  }

  const [checkPolicyCoverExistsLazy] = useLazyQuery(
    checkPolicyCoverExists(),
    {},
  );
  const [checkPolicyCoverPrintingOrderExistsLazy] = useLazyQuery(
    checkPolicyCoverPrintingOrderExists(),
    {},
  );

  const initialize = () => {
    try {
      const updatedInputs = cloneDeep(inputsForm);

      if (isLineNameDisabled) {
        updatedInputs.lineName.disabled = isLineNameDisabled;
        updatedInputs.lineName.value = lineId;
      }

      updatedInputs.lineName.value = lineId;
      if (policyCoverEnumResults.data) {
        const newPlanEnums = LookupToList(policyCoverEnumResults.data);

        if (!lineId) {
          updatedInputs.lineName.value = lineId;
        }
        (updatedInputs.lineName as IFormSelectDynamicProps).selectOptions =
          newPlanEnums['lines'];

        (updatedInputs.lineName as IFormSelectDynamicProps).onSelect = (
          option,
        ) => {
          setSelectedLineId(option);
          handleLineSelection(option);
        };
      }

      (updatedInputs.policyCoverName as IFormTextDynamicProps).onChange = (
        event,
      ) => {
        const newName = event.target.value;
        setInputsForm(updatedInputs);
        (updatedInputs.policyCoverName as IFormTextDynamicProps).value =
          newName;
        if (!isEmpty(newName)) {
          validatePolicyCoverExistsOnNameChange(selectedLineId, newName);
        }
      };

      (
        updatedInputs.policyCoverExternalCode as IFormTextDynamicProps
      ).onChange = (event) => {
        const newExternalCode = event.target.value;
        setInputsForm(updatedInputs);
        (updatedInputs.policyCoverExternalCode as IFormTextDynamicProps).value =
          newExternalCode;
        if (!isEmpty(newExternalCode)) {
          validatePolicyCoverExistsOnExternalCodeChange(selectedLineId, newExternalCode);
        }
      };

      (
        updatedInputs.policyCoverPrintingOrder as IFormNumberDynamicProps
      ).onChange = (event) => {
        const newPrintingOrder: number = Number(event.target.value);
        setInputsForm(updatedInputs);
        (
          updatedInputs.policyCoverPrintingOrder as IFormNumberDynamicProps
        ).value = newPrintingOrder;
        if (!isEmpty(newPrintingOrder)) {
          validatePolicyCoverPrintingOrderExistsOnPrintingOrderChange(selectedLineId,
            newPrintingOrder,
          );
        }
      };

      if (policyCoverInfoResult?.data) {
        const policyCoverEntity = graphqlEntityToPolicyCoverInfo(
          policyCoverInfoResult.data,
          lineId,
          lineName,
        );

        if (policyCoverEntity) {
          updatedInputs.lineName.value = policyCoverEntity.lineName;
          updatedInputs.policyCoverName.value =
            policyCoverEntity.policyCoverName;
          updatedInputs.policyCoverExternalCode.value =
            policyCoverEntity.policyCoverExternalCode;
          updatedInputs.arabicName.value = policyCoverEntity.arabicName;
          updatedInputs.policyCoverPrintingOrder.value =
            policyCoverEntity.policyCoverPrintingOrder;
          updatedInputs.policyCoverDescription.value =
            policyCoverEntity.policyCoverDescription;
          selectedLineId = selectedLineId;
        }
      }

      setInputsForm(updatedInputs);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    if (policyCoverNameError === '' && policyCoverExternalCodeError === '') {
      const [data] = normaliseDynamicValues(inputs, values);
      setFormDisabled(true);
      setSubmitButtonState('loading');
      try {
        let variables = {
          policyCoverInputs: {
            name: data.policyCoverName,
            externalCode: data.policyCoverExternalCode,
            nameArabic: data.arabicName,
            printingOrder: parseInt(
              data.policyCoverPrintingOrder as unknown as string,
            ),
            policyCoverDescription: data.policyCoverDescription,
            policyCoverID: data.policyCover,
            lineID: lineId || selectedLineId,
          },
        };

        policyCoverAction({
          variables: policyCoverId
            ? { ...variables, entityId: policyCoverId }
            : variables,
          errorPolicy: 'all',
        }).then((res) => {
          if (isEmpty(res.errors)) {
            toast.success(
              <ToastSuccessMessage>
                {policyCoverId
                  ? 'Policy Cover successfully updated'
                  : 'Policy Cover successfully created'}
              </ToastSuccessMessage>,
            );

            if (canNavigateToDetailsPage) {
              const newPolicyCoverId = res.data.planConfigManagement.actions.createPolicyCover.id;
              setTimeout(() => {
                setSubmitButtonState('success');
                onSuccess();
                onClose();
                navigate(`/plan/covers/` + newPolicyCoverId);
              }, 500);
            } else {
              setTimeout(() => {
                setSubmitButtonState('success');
                onSuccess();
                onClose();
              }, 500);
            }
          } else {
            setSubmitButtonState(undefined);
            toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
          }
        });
      } catch {
        setSubmitButtonState(undefined);
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>,
        );
      } finally {
        setFormDisabled(false);
      }
    } else {
      setFormDisabled(true);
      setSubmitButtonState(undefined);
    }
  };  

  const validatePolicyCoverExistsOnNameChange = (
    selectedLineId?: string,
    policyCoverName?: string,
  ) => {
    if (!isEmpty(lineId || selectedLineId) && !isEmpty(policyCoverName)) {
      checkPolicyCoverExistsLazy({
        variables: {
          lineId: lineId || selectedLineId,
          name: policyCoverName,
          policyCoverId: policyCoverId,          
          externalCode: '',
        },
      }).then((response) => {
        setInputsForm((currentInputsForm) => {
          const updatedInputs = { ...currentInputsForm };

          if (response.data.PlanConfigManagement?.queries?.checkPolicyCoverExists.length > 0) {
            policyCoverNameError =
              'Policy Cover Name already exists under the same Line';
            setHasPolicyCoverNameError(
              'Policy Cover Name already exists under the same Line',
            );
            (updatedInputs.policyCoverName as IFormTextDynamicProps).error =
            policyCoverNameError;
          } else {
            policyCoverNameError = '';
            setHasPolicyCoverNameError('');
            (updatedInputs.policyCoverName as IFormTextDynamicProps).error =
            policyCoverNameError;
          }

          return updatedInputs;
        });
      });
    }
  };
  
  const validatePolicyCoverExistsOnExternalCodeChange = (
    selectedLineId?: string,
    policyCoverExternalCode?: string,
  ) => {
    if (
      !isEmpty(lineId || selectedLineId) &&
      !isEmpty(policyCoverExternalCode)
    ) {
      checkPolicyCoverExistsLazy({
        variables: {
          lineId: lineId || selectedLineId,
          externalCode: policyCoverExternalCode,
          policyCoverId: policyCoverId,
          name: '',
        },
      }).then((response) => {
        setInputsForm((currentInputsForm) => {
          const updatedInputs = { ...currentInputsForm };

          if (
            response.data.PlanConfigManagement?.queries?.checkPolicyCoverExists.length > 0
          ) {
            policyCoverExternalCodeError =
              'External Code already exists under the same Line';
            setHasPolicyCoverExternalCodeError(
              'External Code already exists under the same Line',
            );
            (
              updatedInputs.policyCoverExternalCode as IFormTextDynamicProps
            ).error = policyCoverExternalCodeError;
          } else {
            policyCoverExternalCodeError = '';
            setHasPolicyCoverExternalCodeError('');
            (
              updatedInputs.policyCoverExternalCode as IFormTextDynamicProps
            ).error = policyCoverExternalCodeError;
          }

          const newPolicyCoverEnums = LookupToList(policyCoverEnumResults.data);

          selectedLineId = Object.keys(newPolicyCoverEnums['lines'])[0];

          return updatedInputs;
        });
      });
    }
  };
  const validatePolicyCoverPrintingOrderExistsOnPrintingOrderChange = (
    selectedLineId: string,
    policyCoverPrintingOrder: number,
  ) => {
    if (
      !isEmpty(lineId || selectedLineId) &&
      !isEmpty(policyCoverPrintingOrder)
    ) {
      checkPolicyCoverPrintingOrderExistsLazy({
        variables: {
          printingOrder: policyCoverPrintingOrder,
          policyCoverId: policyCoverId,
          lineId: lineId || selectedLineId,
        },
      }).then((response) => {
        setInputsForm((currentInputsForm) => {
          const updatedInputs = { ...currentInputsForm };

          if (
            response.data.PlanConfigManagement?.queries
              ?.checkPolicyCoverPrintingOrderExists.length > 0
          ) {
            policyCoverPrintingOrderError =
              'Printing Order already assigned for another Policy Cover under the same Policy Cover';
            setHasPolicyCoverPrintingOrderError(
              'Printing Order already assigned for another Policy Cover under the same Policy Cover',
            );
            (
              updatedInputs.policyCoverPrintingOrder as IFormTextDynamicProps
            ).error = policyCoverPrintingOrderError;
          } else {
            policyCoverPrintingOrderError = '';
            setHasPolicyCoverPrintingOrderError('');
            (
              updatedInputs.policyCoverPrintingOrder as IFormTextDynamicProps
            ).error = policyCoverPrintingOrderError;
          }

          const newPolicyCoverEnums = LookupToList(policyCoverEnumResults.data);

          selectedLineId = Object.keys(newPolicyCoverEnums['lines'])[0];

          return updatedInputs;
        });
      });
    }
  };

  useEffect(() => {
    initialize();
  }, [lineName, policyCoverEnumResults.data, policyCoverInfoResult?.data]);

  useEffect(() => {
    const isDisabled =
      !isEmpty(hasPolicyCoverNameError) ||
      !isEmpty(hasPolicyCoverExternalCodeError) ||
      !isEmpty(hasPolicyCoverPrintingOrderError);

    setSubmitButtonDisabled(isDisabled);
  }, [
    hasPolicyCoverNameError,
    hasPolicyCoverExternalCodeError,
    hasPolicyCoverPrintingOrderError,
  ]);

  return (
    <GenericDrawer
      title={policyCoverId ? 'Edit Policy Cover' : 'New Policy Cover'}
      onClose={() => onClose()}
      isOpen={open}
    >
      {(policyCoverEnumResults.loading || policyCoverInfoResult?.loading) &&
      open ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={'Submit'}
            submitButtonState={submitButtonState}
            isSubmitButtonDisabled={submitButtonDisabled}
            disableForm={formDisabled}
            title="Information"
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default PolicyCoverDrawer;
