import React, { useCallback } from "react";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { makeStyles } from "tss-react/mui";
import clsx from "clsx";
import Tooltip from "@mui/material/Tooltip";
import { IEnhancedCommonProps } from "..";
import { MAIN_ONE_THEME, contentFontFamilyRegular } from "../../constants";

interface IEnhancedSwitchProps
  extends IEnhancedCommonProps,
    Omit<SwitchProps, "onChange"> {
  title: string;
  name: string;
  error?: string;
  value?: boolean;
  information?: string;
  informationIconUrl?: string;
  onChange: (name: string, value: boolean) => void;
}

const useStyles = makeStyles()(() => ({
  labelWrapper: {
    display: "block",
    textAlign: "left",
    fontSize: MAIN_ONE_THEME.typography.regular.reg2.fontSize,
    lineHeight: "15px",
    color: MAIN_ONE_THEME.palette.primary1.main,
    fontFamily: contentFontFamilyRegular,
  },
  label: {
    textAlign: "left",
    minWidth: "fit-content",
  },
  switch: {
    margin: "0 0 0 20px",
    "& .MuiSwitch-input": {
      width: "100%",
      left: "unset",
    },
    "& .MuiSwitch-root": {
      color: MAIN_ONE_THEME.palette.primary5.main,
    },
    "& .Mui-checked": {
      color: `${MAIN_ONE_THEME.palette.primary5.main} !important`,
      "&:hover": {
        backgroundColor: MAIN_ONE_THEME.palette.primary2.lighter,
      },
      "&  + .MuiSwitch-track": {
        backgroundColor: `${MAIN_ONE_THEME.palette.primary5.main} !important`,
      },
    },
  },
  error: {
    textAlign: "right",
    fontSize: MAIN_ONE_THEME.typography.small.sm2.fontSize,
    minHeight: "15px",
    display: "block",
    color: MAIN_ONE_THEME.palette.error.main,
  },
  iconContainer: {
    minWidth: "20px",
    height: "20px",
    margin: "0 0 0 10px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "80% 80%",
  },
  containerWithIcon: {
    justifyContent: "space-between",
  },
  container: {
    display: "flex",
  },
  labelContainer: {
    display: "flex",
    alignItems: "center",
  },
}));

const EnhancedSwitch: React.FC<IEnhancedSwitchProps> = ({
  title,
  name,
  value,
  information,
  informationIconUrl,
  error,
  onBlur,
  onChange,
  disabled,
  className,
}) => {
  const { classes } = useStyles();

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      onChange(event.target.name, checked);
    },
    [onChange]
  );

  return (
    <label htmlFor={name} className={clsx(classes.labelWrapper, className)}>
      <div
        className={clsx(classes.container, {
          [classes.containerWithIcon]: information && informationIconUrl,
        })}
      >
        <span className={classes.labelContainer}>
          <span className={classes.label}>{title}</span>
          {information && informationIconUrl && (
            <Tooltip title={information}>
              <figure
                className={classes.iconContainer}
                style={{ backgroundImage: `url(${informationIconUrl})` }}
              />
            </Tooltip>
          )}
        </span>
        <Tooltip title={information}>
          <Switch
            id={name}
            name={name}
            className={classes.switch}
            onChange={handleChange}
            aria-invalid={!!error}
            aria-describedby={`errorMsg-${name}`}
            checked={value}
            value={value}
            onBlur={onBlur}
            disabled={disabled}
          />
        </Tooltip>
      </div>
      <span id={`errorMsg-${name}`} className={classes.error}>
        {error}
      </span>
    </label>
  );
};

export default EnhancedSwitch;
