import { enumListAsRecordObject } from "../../utils/graph-utils";

export function LookupToList(data: any) {
  const sublineEnums: Record<string, Record<string, string>> = {};
  let lines: Record<string, string> = {};
  let lineStatus: Record<string, string> = {};

  sublineEnums[data.PlanConfigManagement_SublineStatuses.name] = enumListAsRecordObject(
    data.PlanConfigManagement_SublineStatuses.enumValues
  );

  data.PlanConfigManagement.queries.allLines.forEach(
    (element: {
      planConfigManagement_Line_Id: string;
      planConfigManagement_Line_Name: string;
    }) => {
      lines[element.planConfigManagement_Line_Id] = element.planConfigManagement_Line_Name;
    }
  );

  sublineEnums["lines"] = lines;

  data.PlanConfigManagement.queries.allLines.forEach(
    (element: {
      planConfigManagement_Line_Id: string;
      planConfigManagement_Line_Status: string;
    }) => {
      lineStatus[element.planConfigManagement_Line_Id] = element.planConfigManagement_Line_Status;
    }
  );

  sublineEnums["lineStatus"] = lineStatus;

  return sublineEnums;
}

export function graphqlEntityToSublineInfo(data: any, lineId: string, lineName: string): ISublineInfo {
  return {
    sublineId: "",
    lineId: lineId,
    lineName: lineName,
    sublineName:
      data?.PlanConfigManagement?.entities?.subline?.views?.PlanConfigManagement_all?.properties
        ?.Name,
    sublineExternalCode:
      data?.PlanConfigManagement?.entities?.subline?.views?.PlanConfigManagement_all?.properties
        ?.ExternalCode,
    arabicName:
      data?.PlanConfigManagement?.entities?.subline?.views?.PlanConfigManagement_all?.properties
        ?.NameArabic,
    sublineStatus:
      data?.PlanConfigManagement?.entities?.subline?.views?.PlanConfigManagement_all?.properties
        ?.Status,
  };
}
