import React, { useEffect, useState } from 'react';
import AuthLayout from '../layouts/AuthBody';
import { useAppSelector } from '../redux/hooks';
import { makeStyles } from 'tss-react/mui';
import { useSearchParams } from 'react-router-dom';
import DataService from '../services/dataService';
import { generateDownloadLink } from '../utils/file-utils';

const useStyles = makeStyles()((theme) => ({
  mainContainer: {
    backgroundColor: '#F8F8F8',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100vh',
    alignItems: 'center',
    width: '100%',
  },
  logoFig: {
    margin: '0 auto',
    lineHeight: '0',
  },
  loginBoxContainer: {
    width: '430px',
    margin: '46px auto 0',
    maxWidth: '491px',
    boxSizing: 'border-box',
    backgroundColor: '#fff',
    border: '1px solid rgba(0,0,0,.125)',
    boxShadow:
      '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    borderRadius: '4px',
  },
  formContainer: {
    padding: '24px 30px',
    width: '100%',
  },
  formError: {
    color: theme.palette.error.main,
    height: 'fit-content',
    marginTop: '8px',
    boxSizing: 'border-box',
    padding: '0px 12px 0px 12px',
    fontSize: '11px',
    textAlign: 'center',
  },
}));

const DownloadPage: React.FC = () => {
  const tenant = useAppSelector((state) => state.tenant);
  const loginMeta = tenant.login;
  const { classes } = useStyles();
  const [searchParams] = useSearchParams();
  const [downloadStatus, setDownloadStatus] = useState<string>(
    'Downloading File ...'
  );

  useEffect(() => {
    DataService.downloadAndOpenFileWithToken(
      generateDownloadLink(
        searchParams.get('fileId'),
        searchParams.get('entityTypeId'),
        searchParams.get('entityId'),
        searchParams.get('entityViewId'),
        searchParams.get('propertyId')
      )
    ).catch(() => {
      setDownloadStatus('Unable To Download File');
    });
  }, []);

  return (
    <AuthLayout>
      <figure className={classes.logoFig}>
        <img
          height={117}
          width={125}
          src={tenant.cdnUrl + loginMeta.logoUrl}
          alt={'UFA logo'}
        />
      </figure>
      <div className={classes.loginBoxContainer}>
        <div className={classes.formContainer}>{downloadStatus}</div>
      </div>
    </AuthLayout>
  );
};

export default DownloadPage;
