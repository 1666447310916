import React, { useEffect, useState } from "react";
import GenericDrawer from "../../components/common/generic-drawer/GenericDrawer";
import {
  DynamicFormInputType,
  IFormSelectDynamicProps,
} from "../../DynamicForm";
import { toast } from "react-toastify";
import { EnhancedButtonStatus } from "../../components/common/EnhancedButton";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { inputs } from "./content";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import Loader from "../../components/Loader";
import { cloneDeep, isEmpty } from "lodash";
import {
  DEFAULT_ERROR_TEXT,
  SEND_TO_BACKEND_DATE_FORMAT,
} from "../../constants";
import { ITaxationDrawerInfo, ITaxationDrawerProps } from ".";
import {
  getListForm,
  createTaxation,
  updateTaxation,
  getLatestTaxationByLine,
  getTaxationDetailsInfo,
} from "./queries";
import {
  extractLatestTaxationByLine,
  graphqlToTaxationInfo,
  toLookups,
} from "./utils";
import { getError } from "../../utils/graph-utils";
import { normaliseDynamicValues } from "../../utils/dynamic-utils";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import DynamicForm from "../../DynamicForm/DynamicForm";
import dayjs from "dayjs";

const TaxationDrawer: React.FC<ITaxationDrawerProps> = ({
  open,
  onSuccess,
  onClose,
  taxationId,
}) => {
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();
  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);
  const [selectedLineID, setSelectedLineID] = useState("");

  let taxationInfoResult: any;
  if (taxationId) {
    taxationInfoResult = useQuery(getTaxationDetailsInfo(), {
      variables: { id: taxationId },
    });
  }

  let taxationsInfo: ITaxationDrawerInfo = {
    line: "",
    lineTitle: "",
    fixedStamp: "",
    fixedStampCurrency: "39735",
    proportionalStamp: 0,
    municipalityTax: 0,
    taxOnCommission: 0,
    reinsuranceTax: 0,
    effectiveFrom: "",
    effectiveTo: "",
  };

  const taxationsDetailsListResults = useQuery(getListForm());
  const [getLatestTaxationByLineDetailsLazy] = useLazyQuery(
    getLatestTaxationByLine()
  );
  const [taxationAction] = useMutation(
    taxationId ? updateTaxation() : createTaxation()
  );

  const getLatestTaxationByLineDetails = (selectedLineID: string) => {
    if (!isEmpty(selectedLineID)) {
      getLatestTaxationByLineDetailsLazy({
        variables: {
          selectedLineID: selectedLineID,
        },
      }).then((response) => {
        const latestTaxationInfo = extractLatestTaxationByLine(response?.data);

        setInputsForm((currentInputsForm) => {
          const updatedInputs = { ...currentInputsForm };

          updatedInputs.fixedStamp.value = latestTaxationInfo.fixedStamp;
          updatedInputs.fixedStampCurrency.value =
            latestTaxationInfo.fixedStampCurrency;
          updatedInputs.proportionalStamp.value =
            latestTaxationInfo.proportionalStamp
              ? latestTaxationInfo.proportionalStamp
              : "39735";
          updatedInputs.municipalityTax.value =
            latestTaxationInfo.municipalityTax;
          updatedInputs.taxOnCommission.value =
            latestTaxationInfo.taxOnCommission;
          updatedInputs.reinsuranceTax.value =
            latestTaxationInfo.reinsuranceTax;

          return updatedInputs;
        });
      });
    }
  };

  function handleLineSelection(selectedOption: any) {
    setSelectedLineID(selectedOption);
    if (!taxationId && selectedOption.length >= 3) {
      getLatestTaxationByLineDetails(selectedOption);
    }
  }

  useEffect(() => {
    initialize();
  }, [taxationsDetailsListResults.data]);

  let updatedInputs: Record<string, DynamicFormInputType> = inputs;

  const initialize = () => {
    try {
      updatedInputs = cloneDeep(inputsForm);

      updatedInputs.line.disabled = !!taxationId;
      updatedInputs.effectiveTo.hidden = !taxationId;

      if (taxationsDetailsListResults.data) {
        const lookupLists = toLookups(taxationsDetailsListResults.data);

        (updatedInputs.line as IFormSelectDynamicProps).selectOptions =
          lookupLists["line"];

        (
          updatedInputs.fixedStampCurrency as IFormSelectDynamicProps
        ).selectOptions = lookupLists["fixedStampCurrency"];
      }

      (updatedInputs.line as IFormSelectDynamicProps).onSelect = (option) => {
        setSelectedLineID(option);
        handleLineSelection(option);
      };

      if (taxationInfoResult?.data) {
        taxationsInfo = graphqlToTaxationInfo(taxationInfoResult?.data);
      }

      if (taxationsInfo) {
        updatedInputs.line.value = taxationsInfo.line;
        updatedInputs.fixedStamp.value = taxationsInfo.fixedStamp;
        updatedInputs.fixedStampCurrency.value =
          taxationsInfo.fixedStampCurrency;
        updatedInputs.proportionalStamp.value = taxationsInfo.proportionalStamp;
        updatedInputs.municipalityTax.value = taxationsInfo.municipalityTax;
        updatedInputs.taxOnCommission.value = taxationsInfo.taxOnCommission;
        updatedInputs.reinsuranceTax.value = taxationsInfo.reinsuranceTax;
        updatedInputs.effectiveFrom.value = taxationsInfo.effectiveFrom;
        updatedInputs.effectiveTo.value = taxationsInfo.effectiveTo;

        if (taxationsInfo?.line) {
          updatedInputs.line.preselectedValues = taxationsInfo?.lineTitle;
          handleLineSelection(taxationsInfo?.line);
        }
      }

      setInputsForm(updatedInputs);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputs, values);

    setFormDisabled(true);
    setSubmitButtonState("loading");

    try {
      let variablesMutation = {};
      if (!taxationId) {
        variablesMutation = {
          effectiveFrom: dayjs(new Date(data.effectiveFrom)).format(
            SEND_TO_BACKEND_DATE_FORMAT
          ),
          fixedStamp: parseFloat(data.fixedStamp),
          fixedStampCurrency: data.fixedStampCurrency,
          lineID: selectedLineID,
          municipalityTax: parseInt(data.municipalityTax, 10) / 100,
          proportionalStamp: parseInt(data.proportionalStamp, 10) / 100,
          reinsuranceTax: parseInt(data.reinsuranceTax, 10) / 100,
          taxOnCommission: parseInt(data.taxOnCommission, 10) / 100,
        };
      } else {
        variablesMutation = {
          entityId: taxationId,
          effectiveFrom: dayjs(new Date(data.effectiveFrom)).format(
            SEND_TO_BACKEND_DATE_FORMAT
          ),
          fixedStamp: parseFloat(data.fixedStamp),
          fixedStampCurrency: data.fixedStampCurrency,
          municipalityTax: parseInt(data.municipalityTax, 10) / 100,
          proportionalStamp: parseInt(data.proportionalStamp, 10) / 100,
          reinsuranceTax: parseInt(data.reinsuranceTax, 10) / 100,
          taxOnCommission: parseInt(data.taxOnCommission, 10) / 100,
        };
      }

      taxationAction({
        variables: taxationId
          ? {
              ...variablesMutation,
              entityId: taxationId,
            }
          : variablesMutation,
        errorPolicy: "all",
      }).then((res) => {
        if (isEmpty(res.errors)) {
          toast.success(
            <ToastSuccessMessage>
              {taxationId
                ? "Taxation Record successfully updated."
                : "Taxation Record successfully created."}
            </ToastSuccessMessage>
          );
          setTimeout(() => {
            setSubmitButtonState("success");
            onSuccess();
            onClose();
          }, 500);
        } else {
          setSubmitButtonState(undefined);
          toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
        }
      });
    } catch {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setFormDisabled(false);
    }
  };

  return (
    <GenericDrawer
      title={taxationId ? "Modify Taxation Record" : "New Taxation Record"}
      onClose={() => onClose()}
      isOpen={open}
    >
      {taxationsDetailsListResults.loading && open ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={"Submit"}
            submitButtonState={submitButtonState}
            disableForm={formDisabled}
            title="Information"
            hasDoprdownSpecificBehavior={true}
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default TaxationDrawer;
