import { makeStyles } from 'tss-react/mui';
import React from 'react';
import clsx from 'clsx';
import { IEnhancedCommonProps } from '..';

interface IProgressBarProps extends IEnhancedCommonProps {
  percentage: number;
  background?: string;
}

const useStyles = makeStyles()(() => ({
  progressContainer: {
    display: 'inline-block',
    position: 'relative',
    width: '100%',
    height: '5px',
    margin: '0 0 25px 0',
  },
  progressBar: {
    display: 'inline-block',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.2)',
    width: '100%',
    height: '100%',
    borderRadius: '3px',
    opacity: 0.16,
    position: 'absolute',
  },
  progress: {
    borderRadius: '3px',
    height: '100%',
    width: '0',
    transition: 'width 0.5s ease-in',
    position: 'absolute',
  },
}));

const ProgressBar: React.FC<IProgressBarProps> = ({
  percentage,
  background,
  className,
  style,
}) => {
  const { classes } = useStyles();

  return (
    <div className={clsx(classes.progressContainer, className)} style={style}>
      <div className={classes.progressBar} style={{ background }} />
      <div
        className={classes.progress}
        style={{ width: `${percentage}%`, background }}
      />
    </div>
  );
};

export default ProgressBar;
