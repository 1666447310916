import { gql } from '@apollo/client';

export function getApplicationDetails() {
  return gql`
    query getHealthApplicationDetails($id: String!) {
      Insurance {
        entities {
          application(id: $id) {
            actions {
              able_To_SimulateAndSaveHealth
              able_To_SendQuotes
              able_To_AcceptQuotes
            }
            views {
              Insurance_all {
                properties {
                  createdBy
                  modifiedBy
                  createdOn
                  modifiedOn
                  ApplicationName
                  Status
                  StatusReason
                  EffectiveDate
                  LineOfBusiness {
                    Title
                  }
                  AssignedAgencyID {
                    views {
                      Insurance_all {
                        properties {
                          AgencyName
                        }
                      }
                    }
                  }
                  AssignedAgentID {
                    views {
                      Insurance_all {
                        properties {
                          FirstName
                          LastName
                        }
                      }
                    }
                  }
                  ApplicationContactDetailsID {
                    views {
                      Insurance_all {
                        properties {
                          PhoneType
                          FullName
                          Email
                          MobileNumber
                          Address {
                            Country {
                              Id
                              Title
                            }
                            City
                            Street
                            Building
                          }
                          Title {
                            Id
                            Title
                          }
                        }
                      }
                    }
                  }
                  HealthDetailsID {
                    queries {
                      insurance {
                        plans {
                          input_Id
                          input_Product {
                            Code
                            Title
                          }
                          input_StandardInHospitalClass {
                            Code
                            Title
                          }
                          input_OptimumInHospitalClass {
                            Code
                            Title
                          }
                          input_StandardAmbulatoryCoverage {
                            Code
                            Title
                          }
                          input_OptimumAmbulatoryCoverage {
                            Code
                            Title
                          }
                          quote_Id
                          quote_Name
                          quote_Status
                          quote_StatusReason
                          quote_TotalBasePremium
                          quote_TotalAnnualPremium
                          healthquote_Id
                          healthquote_InsuredPremiums {
                            InsuredAge
                            InsuredInHospitalPremium
                            InsuredAmbulatoryPremium
                            InsuredTotalBasePremium
                          }
                        }
                      }
                    }
                    views {
                      Insurance_all {
                        properties {
                          InsuredDetails {
                            InsuredName
                            InsuredRelation
                            InsuredAge
                            InsuredDOB
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        lookups {
          products(Insurance_LinesOfBusiness: "Health") {
            Id
            Title
          }
          countries {
            Id
            Title
          }
          standardInHospitalClasses {
            Id
            Title
          }
          optimumInHospitalClasses {
            Id
            Title
          }
          standardAmbulatoryCoverages {
            Id
            Title
          }
          optimumAmbulatoryCoverages {
            Id
            Title
          }
          titles {
            Id
            Title
          }
        }
      }
      Insurance_InsuredRelations: __type(name: "Insurance_InsuredRelations") {
        name
        enumValues {
          name
        }
      }
      Insurance_PhoneType: __type(name: "Insurance_PhoneType") {
        name
        enumValues {
          name
        }
      }
    }
  `;
}

export function calculateAction() {
  return gql`
    mutation healthCalculateAction(
      $entityId: String!
      $effectiveDate: Date!
      $applicationContactInputs: Insurance_CustomerInputs!
      $plansInputs: [Insurance_HealthPlanInputs!]!
      $insuredDetailsInputs: [Insurance_InsuredDetailsInputs!]!
    ) {
      insurance {
        entities {
          application {
            insurance {
              simulateAndSaveHealth(
                entityId: $entityId
                effectiveDate: $effectiveDate
                applicationContactInputs: $applicationContactInputs
                plansInputs: $plansInputs
                insuredDetailsInputs: $insuredDetailsInputs
              ) {
                Items
              }
            }
          }
        }
      }
    }
  `;
}

export function acceptQuotes() {
  return gql`
    mutation acceptQuotes($entityId: String!, $quoteIds: [String!]!) {
      insurance {
        entities {
          application {
            insurance {
              acceptQuotes(entityId: $entityId, quoteIds: $quoteIds)
            }
          }
        }
      }
    }
  `;
}

export function sendQuotes() {
  return gql`
    mutation healthSendQuotes($entityId: String!, $quoteIds: [String!]!, $customerEmail: String!) {
      insurance {
        entities {
          application {
            insurance {
              sendQuotes(
                entityId: $entityId
                quoteIds: $quoteIds
                customerEmail: $customerEmail
              )
            }
          }
        }
      }
    }
  `;
}
