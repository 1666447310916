import React, { useCallback } from "react";
import {
  isEmpty,
  isValidNumber,
  isValidNumberInput,
} from "../../utils/validationUtils";
import EnhancedInput, { IEnhancedInputProps } from "./EnhancedInput";

export interface IEnhancedNumberInputProps
  extends Omit<IEnhancedInputProps, "type"> {
  value: string | number;
  minValue?: number;
  maxValue?: number;
  loader?: boolean;
  hasBetweenValidation?: boolean;
  disableNegativeValues?: boolean;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  hidden?: boolean;
  isYear?: boolean;
  isEditable?: boolean;
}

const EnhancedNumberInput: React.FC<IEnhancedNumberInputProps> = ({
  name,
  title,
  value,
  placeholder,
  onChange,
  onBlur,
  minValue,
  maxValue,
  hasBetweenValidation = false,
  isYear = false,
  material,
  disableNegativeValues,
  error,
  hidden = false,
  isEditable = false,
  ...props
}) => {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let validation = false;
      if (!isYear) {
        if (!isEmpty(event.target.value)) {
          if (
            Number(event.target.value) < 0 &&
            disableNegativeValues &&
            !isEmpty(event.target.value)
          ) {
            event.target.value = minValue?.toString() || "0";
          }
          const inputValue = Number(event.target.value);
          if (isValidNumber(minValue) && isValidNumber(maxValue)) {
            validation = isValidNumberInput(
              inputValue,
              Number(minValue),
              Number(maxValue)
            );
          } else if (isValidNumber(maxValue)) {
            validation = inputValue <= Number(maxValue);
          } else if (isValidNumber(minValue)) {
            validation = inputValue >= Number(minValue);
          } else {
            validation = true;
          }
        } else {
          validation = true;
        }
        if (validation) {
          onChange(event);
        }
      }
    },
    [maxValue, minValue, onChange]
  );

  return (
    <>
      {!hidden && (
        <EnhancedInput
          {...props}
          type="number"
          name={name}
          title={title}
          value={value}
          placeholder={placeholder}
          onChange={handleChange}
          onBlur={onBlur}
          material={material}
          error={error}
          minValue={minValue}
          maxValue={maxValue}
          isEditable={isEditable}
        />
      )}
    </>
  );
};

export default EnhancedNumberInput;
