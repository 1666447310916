import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
} from '../../components/enhanced-table';
import { IEntityInfoView } from '../../components/widgets/entity-info/EntityInfoFields';

export const steps = ['Inactive', 'Active'];

export const entityView: IEntityInfoView = {
  name: 'section1',
  title: 'Policy Cover Details',
  iconUrl: '',
  sections: [
    {
      name: 'policyCoverDetailsSection1',
      title: '',
      properties: {
        lineName: {
          value: '',
          multiline: false,
          name: 'lineName',
          title: 'Line',
        },
        externalCode: {
          value: '',
          multiline: false,
          name: 'externalCode',
          title: 'External Code',
        },
        arabicName: {
          value: '',
          multiline: false,
          name: 'arabicName',
          title: 'Arabic Name',
        },
        printingOrder: {
          value: '',
          multiline: false,
          name: 'printingOrder',
          title: 'Printing Order',
        },
        policyCoverDescription: {
          value: '',
          multiline: false,
          name: 'policyCoverDescription',
          title: 'Description',
        },
      },
    },
    {
      name: 'policyCoverDetailsSection2',
      title: '',
      properties: {
        createdBy: {
          value: '',
          multiline: false,
          name: 'createdBy',
          title: 'Created By',
        },
        createdOn: {
          value: '',
          multiline: false,
          name: 'createdOn',
          title: 'Created On',
        },
        modifiedBy: {
          value: '',
          multiline: false,
          name: 'modifiedBy',
          title: 'Modified By',
        },
        modifiedOn: {
          value: '',
          multiline: false,
          name: 'modifiedOn',
          title: 'Modified On',
        },
      },
    },
  ],
  socialMedia: {
    facebook: '',
    linkedIn: '',
    twitter: '',
  },
};

export const claimCoverHeaders: Record<string, EnhancedTableHeader> = {
  planConfigManagement_ClaimCover_Name: {
    name: 'planConfigManagement_ClaimCover_Name',
    title: 'Claim Cover Name',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_ClaimCover_ExternalCode: {
    name: 'planConfigManagement_ClaimCover_ExternalCode',
    title: 'External Code',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_ClaimCover_PolicyCoverID: {
    name: 'planConfigManagement_ClaimCover_PolicyCoverID',
    title: 'Policy Cover',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_ClaimCover_NameArabic: {
    name: 'planConfigManagement_ClaimCover_NameArabic',
    title: 'Arabic Name',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_ClaimCover_PrintingOrder: {
    name: 'planConfigManagement_ClaimCover_PrintingOrder',
    title: 'Printing Order',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_ClaimCover_ClaimCoverDescription: {
    name: 'planConfigManagement_ClaimCover_ClaimCoverDescription',
    title: 'Description',
    type: EnhancedTableHeaderType.Text,
  },
  createdOn: {
    name: 'planConfigManagement_ClaimCover_createdOn',
    title: 'Created On',
    type: EnhancedTableHeaderType.Date,
  },
  modifiedOn: {
    name: 'planConfigManagement_ClaimCover_modifiedOn',
    title: 'Modified On',
    type: EnhancedTableHeaderType.Date,
  },
};