export interface IPersonDetailsPageProps {}

export interface IPersonDetails {
  name: string;
}

export const initialValues: IPersonDetails = {
  name: '',
};

export interface IGenericPersonAddressTableData {
  salesforceManagement_PersonAddress_Id: string;
  salesforceManagement_PersonAddress_IsPrimary: string;
  salesforceManagement_PersonAddress_AddressType: string;
  salesforceManagement_PersonAddress_Address: {
    Country: {
      Title: string;
    };
    Zone: {
      Title: string;
    };
    City: string;
    Street: string;
    Building: string;
    AddressDetails: string;
    AddressDetailsArabic: string;
  };
}
