import React from 'react';
import Tooltip from '@mui/material/Tooltip';

interface ILimitedTextWrapper {
  text: string;
  maxCharacter: number;
}

const LimitedTextWrapper: React.FC<ILimitedTextWrapper> = ({
  text,
  maxCharacter = -1,
}) => {
  const renderText = () => {
    if (text && maxCharacter >= 0 && text.length > maxCharacter) {
      return (
        <Tooltip title={text}>
          <span>{`${text.substring(0, maxCharacter).trim()}...`}</span>
        </Tooltip>
      );
    }
    return <>{text}</>;
  };
  return renderText();
};

export default LimitedTextWrapper;
