import { Backdrop } from '@mui/material';
import { withStyles } from 'tss-react/mui';

// const EnhancedBackdrop = withStyles({
//   root: {
//     '@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none)': {
//       background:
//         'rgba(250, 250, 250, 0.1) 0% 0% no-repeat padding-box !important',
//       backdropFilter: 'blur(18px) !important',
//     },
//     '@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none))':
//       {
//         background:
//           'rgba(255, 255, 255, 0.9) 0% 0% no-repeat padding-box !important',
//       },
//   },
// })(Backdrop);

const EnhancedBackdrop = withStyles(Backdrop, () => ({
  root: {
    '@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none)': {
      background:
        'rgba(250, 250, 250, 0.1) 0% 0% no-repeat padding-box !important',
      backdropFilter: 'blur(18px) !important',
    },
    '@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none))':
      {
        background:
          'rgba(255, 255, 255, 0.9) 0% 0% no-repeat padding-box !important',
      },
  },
}));

export default EnhancedBackdrop;
