import React, { useEffect, useState } from "react";
import GenericDrawer from "../../components/common/generic-drawer/GenericDrawer";
import {
  DynamicFormInputType,
  IDynamicSection,
  IFormSelectDynamicProps,
} from "../../DynamicForm";
import { toast } from "react-toastify";
import { EnhancedButtonStatus } from "../../components/common/EnhancedButton";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { inputs } from "./content";
import { useMutation, useQuery } from "@apollo/client";
import Loader from "../../components/Loader";
import { cloneDeep, isEmpty } from "lodash";
import {
  DEFAULT_ERROR_TEXT,
  SEND_TO_BACKEND_DATE_FORMAT,
} from "../../constants";
import { IPersonDetailsDrawerProps } from ".";
import SectionDynamicForm from "../../DynamicForm/SectionDynamicForm";
import { getListForm, updatePersonInfo } from "./queries";
import { toLookups } from "./utils";
import { getError } from "../../utils/graph-utils";
import { normaliseDynamicValues } from "../../utils/dynamic-utils";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import { formatDateTime } from "../../utils/formatting-utils";
// import { formatDateTime } from '../../utils/formatting-utils';

const PersonDetailsDrawer: React.FC<IPersonDetailsDrawerProps> = ({
  personId,
  open,
  onSuccess,
  onClose,
  personDetailsInfo,
}) => {
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();
  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);

  const personDetailsListResults = useQuery(getListForm());

  const [personAction] = useMutation(updatePersonInfo());

  const sections: Record<string, IDynamicSection> = {
    section1: {
      title: "General Details",
      inputs: {
        type: inputsForm.type,
        companyName: inputsForm.companyName,
        title: inputsForm.title,
        firstName: inputsForm.firstName,
        middleName: inputsForm.middleName,
        lastName: inputsForm.lastName,
        surname: inputsForm.surname,
        initials: inputsForm.initials,
        dateOfBirth: inputsForm.dateOfBirth,
        nationality: inputsForm.nationality,
      },
    },
    section2: {
      title: "Contact Details",
      inputs: {
        email: inputsForm.email,
        phoneType: inputsForm.phoneType,
        phoneNumber: inputsForm.phoneNumber,
        relativeOf: inputsForm.relativeOf,
      },
    },
    section3: {
      title: "Health Details",
      inputs: {
        smoker: inputsForm.smoker,
        weight: inputsForm.weight,
        height: inputsForm.height,
        dateOfDeath: inputsForm.dateOfDeath,
        dateOfDeathNotified: inputsForm.dateOfDeathNotified,
      },
    },
    section4: {
      title: "Business Details",
      inputs: {
        taxable: inputsForm.taxable,
        financialReference: inputsForm.financialReference,
        occupationClass: inputsForm.occupationClass,
        profession: inputsForm.profession,
        employer: inputsForm.employer,
        dateOfEmployment: inputsForm.dateOfEmployment,
        salary: inputsForm.salary,
        dateOfSelfCertificateReceived: inputsForm.dateOfSelfCertificateReceived,
        dateOfSelfCertificateValidated:
          inputsForm.dateOfSelfCertificateValidated,
        category: inputsForm.category,
        dateOfKYC: inputsForm.dateOfKYC,
      },
    },
    section5: {
      title: "",
      inputs: {
        comments: inputsForm.comments,
      },
    },
  };

  const validateFields = (option: any) => {
    if (!isEmpty(option)) {
      const selectedType = option.toLowerCase();
      const isPerson = selectedType === "person";
      const isCompany = selectedType === "company";

      updatedInputs.title.hidden = !isPerson;
      updatedInputs.title.disabled = !isPerson;
      updatedInputs.title.required = isPerson;

      updatedInputs.firstName.hidden = !isPerson;
      updatedInputs.firstName.disabled = !isPerson;
      updatedInputs.firstName.required = isPerson;

      updatedInputs.middleName.hidden = !isPerson;
      updatedInputs.middleName.disabled = !isPerson;
      updatedInputs.middleName.required = isPerson;

      updatedInputs.lastName.hidden = !isPerson;
      updatedInputs.lastName.disabled = !isPerson;
      updatedInputs.lastName.required = isPerson;

      updatedInputs.dateOfBirth.hidden = !isPerson;
      updatedInputs.dateOfBirth.disabled = !isPerson;

      updatedInputs.surname.hidden = !isPerson;
      updatedInputs.surname.disabled = !isPerson;

      updatedInputs.nationality.hidden = !isPerson;
      updatedInputs.nationality.disabled = !isPerson;

      updatedInputs.smoker.hidden = !isPerson;
      updatedInputs.smoker.disabled = !isPerson;

      updatedInputs.weight.hidden = !isPerson;
      updatedInputs.weight.disabled = !isPerson;

      updatedInputs.height.hidden = !isPerson;
      updatedInputs.height.disabled = !isPerson;

      updatedInputs.dateOfDeath.hidden = !isPerson;
      updatedInputs.dateOfDeath.disabled = !isPerson;

      updatedInputs.dateOfDeathNotified.hidden = !isPerson;
      updatedInputs.dateOfDeathNotified.disabled = !isPerson;

      updatedInputs.occupationClass.hidden = !isPerson;
      updatedInputs.occupationClass.disabled = !isPerson;

      updatedInputs.profession.hidden = !isPerson;
      updatedInputs.profession.disabled = !isPerson;

      updatedInputs.employer.hidden = !isPerson;
      updatedInputs.employer.disabled = !isPerson;

      updatedInputs.dateOfEmployment.hidden = !isPerson;
      updatedInputs.dateOfEmployment.disabled = !isPerson;

      updatedInputs.salary.hidden = !isPerson;
      updatedInputs.salary.disabled = !isPerson;

      updatedInputs.dateOfSelfCertificateReceived.hidden = !isPerson;
      updatedInputs.dateOfSelfCertificateReceived.disabled = !isPerson;

      updatedInputs.dateOfSelfCertificateValidated.hidden = !isPerson;
      updatedInputs.dateOfSelfCertificateValidated.disabled = !isPerson;

      updatedInputs.category.hidden = !isPerson;
      updatedInputs.category.disabled = !isPerson;

      updatedInputs.dateOfKYC.hidden = !isPerson;
      updatedInputs.dateOfKYC.disabled = !isPerson;

      updatedInputs.companyName.hidden = !isCompany;
      updatedInputs.companyName.disabled = !isCompany;
      updatedInputs.companyName.required = isCompany;
    }
  };

  const validatePersonEmailVisibility = (user: string) => {
    if (isEmpty(user)) {
      updatedInputs.email.disabled = false;
    } else if (!isEmpty(user)) {
      updatedInputs.email.disabled = true;
    }
  };
  useEffect(() => {
    initialize();
  }, [personDetailsListResults.data]);

  let updatedInputs: Record<string, DynamicFormInputType> = inputs;

  //validateFields(updatedInputs.type.value);

  const initialize = () => {
    try {
      updatedInputs = cloneDeep(inputsForm);
      

      validatePersonEmailVisibility(personDetailsInfo?.UserID);

      validateFields(updatedInputs.type.value);

      if (personDetailsListResults.data) {
        const lookupLists = toLookups(personDetailsListResults.data);

        (updatedInputs.type as IFormSelectDynamicProps).selectOptions =
          lookupLists["SalesforceManagement_PersonTypes"];

        (updatedInputs.phoneType as IFormSelectDynamicProps).selectOptions =
          lookupLists["phoneType"];

        (updatedInputs.smoker as IFormSelectDynamicProps).selectOptions =
          lookupLists["isSmoker"];

        (updatedInputs.title as IFormSelectDynamicProps).selectOptions =
          lookupLists["titles"];

        (updatedInputs.nationality as IFormSelectDynamicProps).selectOptions =
          lookupLists["nationalities"];

        (
          updatedInputs.occupationClass as IFormSelectDynamicProps
        ).selectOptions = lookupLists["occupationClasses"];

        (updatedInputs.category as IFormSelectDynamicProps).selectOptions =
          lookupLists["personsCategories"];

        (updatedInputs.type as IFormSelectDynamicProps).onSelect = (option) => {
          validateFields(option);
        };
      }

      if (personDetailsInfo) {
        updatedInputs.type.value = personDetailsInfo.type;
        updatedInputs.companyName.value = personDetailsInfo.companyName;
        updatedInputs.title.value = personDetailsInfo.titleId;
        updatedInputs.firstName.value = personDetailsInfo.firstName;
        updatedInputs.middleName.value = personDetailsInfo.middleName;
        updatedInputs.lastName.value = personDetailsInfo.lastName;
        updatedInputs.surname.value = personDetailsInfo.surname;
        updatedInputs.initials.value = personDetailsInfo.initials;
        updatedInputs.dateOfBirth.value = personDetailsInfo.dateOfBirth;
        updatedInputs.nationality.value = personDetailsInfo.nationalityId;

        updatedInputs.email.value = personDetailsInfo.email;
        updatedInputs.phoneType.value = personDetailsInfo.phoneType;
        updatedInputs.phoneNumber.value = personDetailsInfo.phoneNumber;
        updatedInputs.relativeOf.value = personDetailsInfo.relativeOf;

        updatedInputs.smoker.value = personDetailsInfo.smoker;
        updatedInputs.weight.value = personDetailsInfo.weight;
        updatedInputs.height.value = personDetailsInfo.height;
        updatedInputs.dateOfDeath.value = personDetailsInfo.dateOfDeath;
        updatedInputs.dateOfDeathNotified.value =
          personDetailsInfo.dateOfDeathNotified;

        updatedInputs.taxable.value = personDetailsInfo.taxable;
        updatedInputs.financialReference.value =
          personDetailsInfo.financialReference;
        updatedInputs.occupationClass.value = personDetailsInfo.occupationClass;
        updatedInputs.profession.value = personDetailsInfo.profession;
        updatedInputs.employer.value = personDetailsInfo.employer;
        updatedInputs.dateOfEmployment.value =
          personDetailsInfo.dateOfEmployment;
        updatedInputs.salary.value = personDetailsInfo.salary;
        updatedInputs.dateOfSelfCertificateReceived.value =
          personDetailsInfo.dateOfSelfCertificateReceived;
        updatedInputs.dateOfSelfCertificateValidated.value =
          personDetailsInfo.dateOfSelfCertificateValidated;
        updatedInputs.category.value = personDetailsInfo.category;
        updatedInputs.dateOfKYC.value = personDetailsInfo.dateOfKYC;

        updatedInputs.comments.value = personDetailsInfo.comments;
      }

      setInputsForm(updatedInputs);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputs, values);

    setFormDisabled(true);
    setSubmitButtonState("loading");

    try {
      const selectedType = data.type.toLowerCase();
      const isPerson = selectedType === "person";
      const isCompany = selectedType === "company";

      let variablesMutation = {
        entityId: personId,
        dateOfBirth: isPerson
          ? formatDateTime(data.dateOfBirth, SEND_TO_BACKEND_DATE_FORMAT) ||
            null
          : null,
        dateOfDeath: isPerson
          ? formatDateTime(data.dateOfDeath, SEND_TO_BACKEND_DATE_FORMAT) ||
            null
          : null,
        dateOfDeathNotified: isPerson
          ? formatDateTime(
              data.dateOfDeathNotified,
              SEND_TO_BACKEND_DATE_FORMAT
            ) || null
          : null,
        dateOfEmployment: isPerson
          ? formatDateTime(
              data.dateOfEmployment,
              SEND_TO_BACKEND_DATE_FORMAT
            ) || null
          : null,
        dateOfKYC: isPerson
          ? formatDateTime(data.dateOfKYC, SEND_TO_BACKEND_DATE_FORMAT) || null
          : null,
        dateOfSelfCertificateReceived: isPerson
          ? formatDateTime(
              data.dateOfSelfCertificateReceived,
              SEND_TO_BACKEND_DATE_FORMAT
            ) || null
          : null,
        dateOfSelfCertificateValidated: isPerson
          ? formatDateTime(
              data.dateOfSelfCertificateValidated,
              SEND_TO_BACKEND_DATE_FORMAT
            ) || null
          : null,
        personDetailsInputs: {
          firstName: isPerson ? data.firstName || null : null,
          middleName: isPerson ? data.middleName || null : null,
          lastName: isPerson ? data.lastName || null : null,
          email: data.email || null,
          personType: data.type,
          title: isPerson ? data.title : null,
          phoneType: data.phoneType,
          phoneNumber: data.phoneNumber,
          companyName: isCompany ? data.companyName || null : null,
        },
        personnalInfoInputs: {
          comments: data.comments || null,
          employer: isPerson ? data.employer || null : null,
          height: isPerson ? parseInt(data.height as unknown as string) : null,
          initials: data.initials || null,
          nationalityOne: isPerson ? data.nationality : null,
          profession: isPerson ? data.profession || null : null,
          relativeOf: data.relativeOf || null,
          salary: isPerson ? parseInt(data.salary as unknown as string) : null,
          smoker: isPerson ? data.smoker || null : null,
          surname: isPerson ? data.surname || null : null,
          weight: isPerson ? parseInt(data.weight as unknown as string) : null,
          financialReference: data.financialReference || null,
          isTaxable: data.taxable ? data.taxable : false,
        },
      };

      personAction({
        variables: variablesMutation,
        errorPolicy: "all",
      }).then((res) => {
        if (isEmpty(res.errors)) {
          toast.success(
            <ToastSuccessMessage>
              {"Person successfully updated"}
            </ToastSuccessMessage>
          );
          setTimeout(() => {
            setSubmitButtonState("success");
            onSuccess();
            onClose();
          }, 500);
        } else {
          setSubmitButtonState(undefined);
          toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
        }
      });
    } catch {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setFormDisabled(false);
    }
  };

  return (
    <GenericDrawer
      title="Modify Personal Information"
      onClose={() => onClose()}
      isOpen={open}
    >
      {personDetailsListResults.loading && open ? (
        <Loader />
      ) : (
        <>
          <SectionDynamicForm
            onSubmit={(values) => submitForm(values)}
            buttonText={"Submit"}
            submitButtonState={submitButtonState}
            disableForm={formDisabled}
            inputs={inputsForm}
            sections={sections}
            onChange={(
              fieldName: string,
              value: string | string[],
              allValues
            ) => {
              //TODO: refactor code in way to map values automatically

              const formInputs = cloneDeep(inputsForm);

              if (fieldName === "isRenewal") {
                if (value == "YES") {
                  formInputs.renewalNumber.required = true;
                  formInputs.renewalNumber.hidden = false;
                } else {
                  formInputs.renewalNumber.hidden = true;
                }

                Object.values(formInputs).forEach((input) => {
                  input.value = allValues[input.name];
                });

                setInputsForm({ ...formInputs });
              }

              if (fieldName === "brand") {
                Object.values(formInputs).forEach((input) => {
                  input.value = allValues[input.name];
                });

                setInputsForm({ ...formInputs });
              }
            }}
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default PersonDetailsDrawer;
