import CircleIcon from '@mui/icons-material/Circle';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import PageShell from '../components/PageShell';

const useStyles = makeStyles()(() => ({
  container: {
    marginTop: '61px',
    marginLeft: '162px',
  },
  title: {
    fontSize: '20px',
    color: '#231F20',
    fontFamily: 'HelveticaNeue-Bold',
    lineHeight: '25px',
    marginBottom: 13,
  },
  subTitle: {
    fontSize: '15px',
    color: '#231F20',
    fontFamily: 'SourceSansPro-Semibold',
    lineHeight: '19px',
  },
  content: {
    fontSize: '15px',
    color: '#231F20',
    fontFamily: 'SourceSansPro-Regular',
    lineHeight: '19px',
  },
  listItemText: {
    fontSize: '15px',
    color: '#231F20',
    fontFamily: 'SourceSansPro-Regular',
    lineHeight: '18px',
  },
  bulletIcon: {
    color: '#CE0E2D',
    fontSize: '8px',
  },
  bullet: {
    minWidth: '17px',
  },
  listItem: {
    paddingLeft: '0px',
    paddingBottom: 0,
  },
  gridList: {
    display: 'grid',
    gridTemplateColumns: `12.5% 12.5% 70%`,
  },
  email: {
    color: '#CE0E2D',
  },
}));

type BulletProps = {
  text: string;
};

const PrivacyPolicyPage: React.FC = () => {
  const { classes } = useStyles();

  const BulletListItem = (props: BulletProps) => {
    return (
      <>
        <ListItem className={classes.listItem}>
          <ListItemIcon className={classes.bullet}>
            <CircleIcon className={classes.bulletIcon} />
          </ListItemIcon>
          <ListItemText disableTypography>
            <Typography className={classes.listItemText}>
              {props?.text}
            </Typography>
          </ListItemText>
        </ListItem>
      </>
    );
  };

  return (
    <PageShell>
      <div className={classes.container}>
        <section style={{ marginBottom: '39px' }}>
          <p className={classes.title}>Introduction</p>
          <p className={classes.content}>
            UFA Assurances is committed to protecting the privacy of our users.
            This Privacy Policy explains how we collect, use, and protect your
            personal information when you use the UFA Assurances app.
          </p>
        </section>
        <section style={{ marginBottom: '39px' }}>
          <p className={classes.title}>Information We Collect</p>
          <p className={classes.subTitle}>
            We collect the following personal information from you when you use
            the UFA Assurances app:
          </p>
          <List>
            <BulletListItem text='Contact information: Your name, email address, and phone number.' />
            <BulletListItem text='Demographic information: Your date of birth and address.' />
            <BulletListItem text='Policy information: Information about the insurance policy you are requesting a quotation for, such as the type of insurance, the insured property, and the policy limits.' />
          </List>
          <p className={classes.subTitle}>
            We collect this information only at the stage where you are
            accepting or requesting a copy of your quotation.
          </p>
        </section>
        <section style={{ marginBottom: '33px' }}>
          <p className={classes.title}>How We Use Your Information</p>
          <p className={classes.subTitle}>
            We use your personal information to:
          </p>
          <List>
            <BulletListItem text='Generate insurance policy quotations for you.' />
            <BulletListItem text='Contact you about your insurance policy quotations.' />
          </List>
        </section>
        <section style={{ marginBottom: '39px' }}>
          <p className={classes.title}>Sharing Your Information</p>
          <p className={classes.subTitle}>
            We do not share your personal information with any third parties.
          </p>
        </section>
        <section style={{ marginBottom: '33px' }}>
          <p className={classes.title}>Cloud-Based Security</p>
          <p className={classes.subTitle}>
            We employ a variety of security measures to protect your personal
            information from unauthorized access, use, disclosure, alteration,
            or destruction.
          </p>
          <p className={classes.subTitle}>These measures include:</p>
          <List>
            <BulletListItem text='Encryption of data at rest and in transit.' />
            <BulletListItem text='Access controls to restrict access to your personal information.' />
            <BulletListItem text='Comprehensive cloud security measures.' />
          </List>
        </section>
        <section style={{ marginBottom: '29px' }}>
          <p className={classes.title}>Changes to This Privacy Policy</p>
          <p className={classes.subTitle}>
            We may update this Privacy Policy from time to time. We will notify
            you of any material changes by posting he updated Privacy Policy on
            our website.
          </p>
        </section>
        <section style={{ marginBottom: '29px' }}>
          <p className={classes.title}>Contact Us</p>
          <p className={classes.subTitle}>
            If you have any questions about this Privacy Policy, please contact
            us at{' '}
            <span className={classes.email}>ufa-support@asteya.world</span>
          </p>
        </section>
        <section style={{ marginBottom: '52px' }}>
          <p className={classes.title}>Information You Need to Fill Out</p>
          <p className={classes.subTitle}>
            To request an insurance policy quotation, you will need to provide
            the following information:
          </p>
          <List className={classes.gridList}>
            <BulletListItem text='Your name' />
            <BulletListItem text='Your phone number' />
            <BulletListItem text='Your address' />
            <BulletListItem text='Your email address' />
            <BulletListItem text='Your date of birth' />
            <BulletListItem text='Information about the insurance policy you are requesting a quotation for, such as the type of insurance, the insured property, and the policy limits.' />
          </List>
          <p className={classes.subTitle}>
            You will only be required to provide this information at the stage
            where you are accepting or requesting a copy of your quotation.
          </p>
        </section>
      </div>
    </PageShell>
  );
};

export default PrivacyPolicyPage;
