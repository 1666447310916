import { DynamicFormInputType, FormInputTypes } from '../../DynamicForm';
import { isEmpty } from '../../utils/validationUtils';

export const initialValues = {
  minimumIssueDate: '',
};

export const inputs: Record<string, DynamicFormInputType> = {
  minimumIssueDate: {
    name: 'minimumIssueDate',
    title: 'New Reference Date',
    type: FormInputTypes.date,
    placeholder: 'New Reference Date',
    value: !isEmpty(initialValues.minimumIssueDate)
      ? initialValues.minimumIssueDate
      : [],
    required: true,
    disabled: false,
    minDate: new Date(),
  },
};
