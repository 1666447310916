declare global {
  interface Window {
    env: any;
  }
}

export function getEnviromentApiUrl(): string {
  //return window["env"]["API_BASE_URL"] || 'https://ufa-service-dev.baibars.club/';
  return window.env?.API_BASE_URL || 'https://ufa-gateway-dev.baibars.club/';
  // var origin = window.location.origin;
  // if(origin.includes('asteya.world')){
  //   return '';
  // } else if(origin.includes('staging')){
  // return "https://ufa-service-staging.baibars.club/";
  // } else if (origin.includes('uat')) {
  //   return 'https://ufa-service-uat.baibars.club/';
  // } else if (origin.includes('stable')) {
  //   return 'https://ufa-service-stable.baibars.club/';
  // }
  // return 'https://ufa-service-dev.baibars.club/';
}

export function isMobileDevice() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
}

export function supportsWebCrypto() {
  return window.crypto && window.crypto.subtle;
}
