import {
    enumListAsRecordObject,
    lookupListAsRecordObject,
  } from "../../utils/graph-utils";
  
  export function toLookups(data: any) {
    const businessUserDetailsList: Record<string, Record<string, string>> = {};
    businessUserDetailsList[data.SalesforceManagement_BusinessUserStatuses.name] =
      enumListAsRecordObject(
        data.SalesforceManagement_BusinessUserStatuses.enumValues
      );
  
    businessUserDetailsList["relatedCompany"] = lookupListAsRecordObject(
      data.SalesforceManagement.lookups.relatedCompanies
    );
    businessUserDetailsList["type"] = lookupListAsRecordObject(
      data.SalesforceManagement.lookups.businessUserTypes
    );
    businessUserDetailsList["access"] = lookupListAsRecordObject(
      data.SalesforceManagement.lookups.businessUserAccesses
    );
  
    return businessUserDetailsList;
  }
  
  export function extractPersons(data: any) {
    let persons: Record<string, string> = {};
  
    data.SalesforceManagement.queries.AllActivePersons.forEach(
      (element: {
        salesforceManagement_Person_Id: string;
        salesforceManagement_Person_FullName: string;
        salesforceManagement_Person_PersonCode: string;
      }) => {
        persons[
          element.salesforceManagement_Person_Id
        ] = `${element.salesforceManagement_Person_PersonCode} - ${element.salesforceManagement_Person_FullName}`;
      }
    );
  
    return persons;
  }
  