import React, { useEffect, useState } from 'react';
import FixedColumn from './FixedColumn';
import MainColumn from './MainColumn';
import { ColumnDirection, COLLAPSED_COLUMN_WIDTH } from '.';
import { IThreeColumnLayout } from '..';
import { GridWidth } from '../../models/grid';
import { isEmpty } from '../../utils/validationUtils';

interface IThreeColumnLayoutProps {
  // Name is essential for the sake of reseting the page layout
  name: string;
  layout: IThreeColumnLayout;
  leftChildren?: JSX.Element;
  rightChildren?: JSX.Element;
  mainChildren?: JSX.Element;
}

const ThreeColumnLayout: React.FC<IThreeColumnLayoutProps> = ({
  name,
  layout,
  leftChildren,
  rightChildren,
  mainChildren,
}) => {
  const [leftCollapsed, setLeftCollapsed] = useState<boolean>(
    layout.leftColumn.collapsed || false,
  );
  const [rightCollapsed, setRightCollapsed] = useState<boolean>(
    layout.rightColumn.collapsed || false,
  );
  const [mainWidth, setMainWidth] = useState<GridWidth>(
    layout.mainColumn.width as GridWidth,
  );

  useEffect(() => {
    setLeftCollapsed(
      !isEmpty(layout.leftColumn.collapsed)
        ? (layout.leftColumn.collapsed as boolean)
        : false,
    );
  }, [name, layout.leftColumn.collapsed]);

  useEffect(() => {
    setRightCollapsed(
      !isEmpty(layout.rightColumn.collapsed)
        ? (layout.rightColumn.collapsed as boolean)
        : false,
    );
  }, [name, layout.rightColumn.collapsed]);

  useEffect(() => {
    let curMainWidth = layout.mainColumn.width;
    if (
      (leftCollapsed && layout.leftColumn.width > 0) ||
      layout.leftColumn.collapsable
    ) {
      curMainWidth += layout.leftColumn.width - COLLAPSED_COLUMN_WIDTH;
    }
    if (
      (rightCollapsed && layout.rightColumn.width > 0) ||
      layout.rightColumn.collapsable
    ) {
      curMainWidth += layout.rightColumn.width - COLLAPSED_COLUMN_WIDTH;
    }
    setMainWidth(curMainWidth as GridWidth);
  }, [
    leftCollapsed,
    rightCollapsed,
    layout.leftColumn.width,
    layout.mainColumn.width,
    layout.rightColumn.width,
    layout.leftColumn.collapsable,
    layout.rightColumn.collapsable,
  ]);

  const toggleColumn = (side: ColumnDirection) => {
    if (side === ColumnDirection.left) {
      setLeftCollapsed(!leftCollapsed);
    } else {
      setRightCollapsed(!rightCollapsed);
    }
  };

  return (
    <>
      <FixedColumn
        background={layout.leftColumn.backgroundColor}
        collapsible={layout.leftColumn.collapsable}
        collapsed={leftCollapsed}
        direction={ColumnDirection.left}
        width={layout.leftColumn.width as GridWidth}
        onToggle={toggleColumn}
      >
        {leftChildren}
      </FixedColumn>
      <MainColumn
        background={layout.mainColumn.backgroundColor}
        width={mainWidth}
        leftMargin={
          (layout.leftColumn.width > 0 && leftCollapsed) ||
          layout.leftColumn.collapsable
            ? (COLLAPSED_COLUMN_WIDTH as GridWidth)
            : (layout.leftColumn.width as GridWidth)
        }
        rightMargin={
          (layout.rightColumn.width > 0 && rightCollapsed) ||
          layout.rightColumn.collapsable
            ? (COLLAPSED_COLUMN_WIDTH as GridWidth)
            : (layout.rightColumn.width as GridWidth)
        }
        
      >
        {mainChildren}
      </MainColumn>
      <FixedColumn
        background={layout.rightColumn.backgroundColor}
        collapsible={layout.rightColumn.collapsable}
        collapsed={rightCollapsed}
        direction={ColumnDirection.right}
        width={layout.rightColumn.width as GridWidth}
        onToggle={toggleColumn}
      >
        {rightChildren}
      </FixedColumn>
    </>
  );
};

export default ThreeColumnLayout;
