import React, { useEffect, useState } from "react";
import { IListingData } from "../../models/listing";
import StaticLayout from "../../page-layout/static-layout/StaticLayout";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  exportMotorPolicies,
  filterLovsQuery,
  getMotorAgentInfo,
  listQuery,
} from "./queries";
import { dataToLovs, mapToListingData } from "./utils";
import { filterSectionsContent, headers } from "./content";
import EnhancedTable from "../../components/enhanced-table/EnhancedTable";
import { IListingFilterWidgetSection } from "../../components/widgets/custom-listing-filter";
import ListingFilterWidget from "../../components/widgets/custom-listing-filter/ListingFilterWidget";
import { IAbstractRecord } from "../../models";
import _, { isEmpty } from "lodash";
import { getFilter, setFilter } from "../../utils/filter-utils";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { toast } from "react-toastify";
import {
  IEnhancedTableMenuItem,
  IEnhanceTableHeaderClickable,
} from "../../components/enhanced-table";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import {
  DEFAULT_ERROR_TEXT,
  SEND_TO_BACKEND_DATE_FORMAT,
} from "../../constants";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { IConfirmation } from "../../redux/confirmation/types";
import { getError } from "../../utils/graph-utils";
import { OpenConfirmationAction } from "../../redux/confirmation/actions";
import DownloadJsonasExcel from "../../components/custom/DownloadJsonasExcel";

const PolicyListing: React.FC = ({}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.user);

  const [loadingState, setLoadingState] = useState<boolean>(false);

  const [tableLoadingState, setTableLoadingState] = useState<boolean>(false);

  const FILTER_SESSION_KEY = "motorPoliciesFilter";

  let agentInfoResult: any;
  agentInfoResult = useQuery(getMotorAgentInfo(), {
    variables: { id: user.info.agentId },
    skip: user.userRoles.includes("Insurance-Admin"),
  });

  const excelHeaders = {
    insuranceCustomer_FullName: {
      label: "Customer",
      visible: true,
      isDate: false,
    },
    insurance_Policy_FullPolicyNumber: {
      label: "Policy Number",
      visible: true,
      isDate: false,
    },
    insurancePolicyMotorDetails_Product: {
      label: "Product",
      visible: true,
      isDate: false,
    },
    insurance_Policy_PolicyEffectiveDate: {
      label: "Policy Effective Date",
      visible: true,
      isDate: true,
    },
    insurance_Policy_PolicyIssueDate: {
      label: "Policy Issue Date",
      visible: true,
      isDate: true,
    },
    insurance_Policy_PolicyExpiryDate: {
      label: "Policy Expiry Date",
      visible: true,
      isDate: true,
    },
    insuranceAgency_AgencyName: {
      label: "Business Partner",
      visible: true,
      isDate: false,
    },
    insuranceAgent_FirstName: {
      label: "Business User",
      visible: true,
      isDate: false,
    },
    insurance_Policy_TotalAnnualPremium: {
      label: "Premium",
      visible: true,
      isDate: false,
    },
    insurance_Policy_IsRenewal: {
      label: "Renewal",
      visible: true,
      isDate: false,
    },
    insurance_Policy_RenewalNumber: {
      label: "Renewal Number",
      visible: true,
      isDate: false,
    },
    insurancePolicyMotorDetails_Vignette: {
      label: "Vignette",
      visible: true,
      isDate: false,
    },
    insurancePolicyMotorDetails_PlateNumber: {
      label: "Plate Number",
      visible: true,
      isDate: false,
    },
    insurancePolicyMotorDetails_UsageType: {
      label: "Usage",
      visible: true,
      isDate: false,
    },
    insurancePolicyMotorDetails_BodyType: {
      label: "Body",
      visible: true,
      isDate: false,
    },
    insurancePolicyMotorDetails_EngineType: {
      label: "Engine Type",
      visible: true,
      isDate: false,
    },
    insurancePolicyMotorDetails_Brand: {
      label: "Make and Model",
      visible: true,
      isDate: false,
    },
    insurancePolicyMotorDetails_YearOfMake: {
      label: "Year of Make",
      visible: true,
      isDate: false,
    },
    insurancePolicyMotorDetails_Motor: {
      label: "Motor",
      visible: true,
      isDate: false,
    },
    insurancePolicyMotorDetails_Weight: {
      label: "Weight (in Tons)",
      visible: true,
      isDate: false,
    },
    insurancePolicyMotorDetails_EngineSize: {
      label: "Engine Size (in cc)",
      visible: true,
      isDate: false,
    },
    insurancePolicyMotorDetails_Seats: {
      label: "Seating Capacity",
      visible: true,
      isDate: false,
    },
    insurancePolicyMotorDetails_Chassis: {
      label: "Chassis",
      visible: true,
      isDate: false,
    },
    insurancePolicyMotorDetails_HorsePower: {
      label: "Horse Power",
      visible: true,
      isDate: false,
    },
    insurance_Policy_AgencyCommission: {
      label: "Commission",
      visible:
        user.userRoles.includes("Insurance-Admin") ||
        (!isEmpty(agentInfoResult) &&
          agentInfoResult?.data?.Insurance?.entities?.agent?.views
            ?.Insurance_all?.properties?.CanViewCommission),
      isDate: false,
    },
  };

  const specialHeaders = {
    "Business User": ["insuranceAgent_FirstName", "insuranceAgent_LastName"],
    "Plate Number": [
      "insurancePolicyMotorDetails_PlateCode",
      "insurancePolicyMotorDetails_PlateNumber",
    ],
    Vignette: [
      "insurancePolicyMotorDetails_VignetteCode",
      "insurancePolicyMotorDetails_Vignette",
    ],
  };

  const actions: IEnhancedTableMenuItem[] = [
    {
      title: "Export Policies",
      onClick: () => {},
      isEntity: false,
      isBulk: false,
      iconUrl: "",
      hidden: user.userRoles.includes('Insurance-Underwriter'),
    },
  ];

  const [filterSections, setFilterSections] =
    useState<IListingFilterWidgetSection[]>();

  const initialfilterValues =
    Object.keys(getFilter(FILTER_SESSION_KEY)).length > 0
      ? getFilter(FILTER_SESSION_KEY)
      : {
          agency: [],
          plan: [],
          effectiveDate: [],
          issueDate: [],
        };

  const [filterValues, setFilterValues] =
    useState<IAbstractRecord>(initialfilterValues);

  const [tableData, setTableData] = useState<IListingData<any>>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 0,
    totalCount: 0,
  });

  const [getApplicationsLazy, { loading, error, data, fetchMore }] =
    useLazyQuery(listQuery(), {
      variables: {
        currentPage: 1,
        currentPageSize: tableData.pageSize,
        agencyId:
          filterValues?.agency && filterValues?.agency?.length > 0
            ? filterValues?.agency
            : null,
        product:
          filterValues?.plan && filterValues?.plan?.length > 0
            ? filterValues?.plan
            : null,
        effectiveDateFrom: filterValues?.effectiveDate[0]
          ? dayjs(filterValues.effectiveDate[0]).format(
              SEND_TO_BACKEND_DATE_FORMAT
            )
          : null,
        effectiveDateTo: filterValues?.effectiveDate[1]
          ? dayjs(filterValues?.effectiveDate[1]).format(
              SEND_TO_BACKEND_DATE_FORMAT
            )
          : null,
        issueDateFrom: filterValues?.issueDate[0]
          ? dayjs(filterValues.issueDate[0]).format(SEND_TO_BACKEND_DATE_FORMAT)
          : null,
        issueDateTo: filterValues?.issueDate[1]
          ? dayjs(filterValues.issueDate[1]).format(SEND_TO_BACKEND_DATE_FORMAT)
          : null,
      },
    });

  const [getLovsLazy, filterLovsResponse] = useLazyQuery(filterLovsQuery(), {
    fetchPolicy: "no-cache",
  });

  const [exportMotorPoliciesLazy] = useLazyQuery(exportMotorPolicies(), {
    variables: {
      currentPage: 1,
      currentPageSize: tableData.pageSize,
      products:
        filterValues?.plan && filterValues?.plan?.length > 0
          ? filterValues?.plan
          : null,
      effectiveDateFrom: filterValues?.effectiveDate[0]
        ? dayjs(filterValues.effectiveDate[0]).format(
            SEND_TO_BACKEND_DATE_FORMAT
          )
        : null,
      effectiveDateTo: filterValues?.effectiveDate[1]
        ? dayjs(filterValues?.effectiveDate[1]).format(
            SEND_TO_BACKEND_DATE_FORMAT
          )
        : null,
      issueFrom: filterValues?.issueDate[0]
        ? dayjs(filterValues.issueDate[0]).format(SEND_TO_BACKEND_DATE_FORMAT)
        : null,
      issueTo: filterValues?.issueDate[1]
        ? dayjs(filterValues.issueDate[1]).format(SEND_TO_BACKEND_DATE_FORMAT)
        : null,
      lineOfBusiness: "Motor",
    },
  });

  if (
    tableData &&
    tableData.pagedItems &&
    Object.keys(tableData.pagedItems).length > 0
  ) {
    actions[0].onClick = () => {
      const confirmation: IConfirmation = {
        open: true,
        title: "Export Motor Policies",
        message: `Are you sure you want to Export Policies and download the Excel file?`,
        callback: async () => {
          try {
            const result = await exportMotorPoliciesLazy();
            if (isEmpty(result.error)) {
              const data =
                result.data?.Insurance?.queries?.ExportPolicies?.flat() ?? [];
              if (!isEmpty(data)) {
                dispatch(
                  OpenConfirmationAction({
                    open: true,
                    title: "Export Motor Policies",
                    message: (
                      <div>
                        <p>
                          {`Are you sure you want to Export Policies and download the Excel file?`}
                        </p>
                        <DownloadJsonasExcel
                          data={data}
                          filename="Motor Policy Listing"
                          headers={excelHeaders}
                          specialHeaders={specialHeaders}
                        />
                      </div>
                    ),
                    submitButtonText: "Export",
                    cancelButtonText: "Cancel",
                  })
                );
              }
              if (isEmpty(data)) {
                toast.info(
                  <ToastErrorMessage>
                    {"No Data To Be Exported!"}
                  </ToastErrorMessage>
                );
              }
            } else {
              toast.error(
                <ToastErrorMessage>{getError(result)}</ToastErrorMessage>
              );
            }
          } catch (error) {
            toast.error(<ToastErrorMessage>{error.message}</ToastErrorMessage>);
          }
        },
        submitButtonText: "Export",
        cancelButtonText: "Cancel",
      };
      dispatch(OpenConfirmationAction(confirmation));
    };
  } else {
    actions[0].onClick = () => {
      toast.info(
        <ToastErrorMessage>{"No Data To Be Exported"}</ToastErrorMessage>
      );
    };
  }

  useEffect(() => {
    getApplicationsLazy();
    getLovsLazy();
  }, []);

  useEffect(() => {
    if (filterLovsResponse?.loading) setLoadingState(true);
    if (filterLovsResponse?.error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
    if (filterLovsResponse?.data) {
      let savedFilters = getFilter(FILTER_SESSION_KEY);
      if (isEmpty(savedFilters)) {
        savedFilters = _.cloneDeep(filterValues);
      }
      setFilterValues(savedFilters);

      const lovs = dataToLovs(filterLovsResponse.data);

      const newFilterSections = filterSectionsContent(
        lovs,
        savedFilters,
        user.userRoles
      );
      setFilterSections(newFilterSections);
    }
    setLoadingState(false);
  }, [filterLovsResponse]);

  useEffect(() => {
    if (loading) setTableLoadingState(true);
    if (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
    if (data) {
      const tableData = mapToListingData(data);
      setTableData({ ...tableData, pageNumber: 0 });
    }
    setTableLoadingState(false);
  }, [loading, error, data]);

  function handlePageChange(page: number) {
    setTableLoadingState(true);
    fetchMore({
      variables: {
        currentPage: ++page,
        currentPageSize: tableData.pageSize,
      },
    })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const updatedTableData = {
            ...mapToListingData(fetchMoreResult.data),
            pageNumber: --page,
          };
          setTableData(updatedTableData);
        }
      })
      .finally(() => {
        setTableLoadingState(false);
      });
  }

  function handleRowsPerPageChange(numberOfRecordsPerPage: number) {
    setTableLoadingState(true);
    setTableData({ ...tableData, pageNumber: 1 });
    fetchMore({
      variables: {
        currentPage: 1,
        currentPageSize: numberOfRecordsPerPage,
      },
    })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const updatedTableData = {
            ...mapToListingData(fetchMoreResult.data),
            pageNumber: 0,
            pageSize: numberOfRecordsPerPage,
          };
          setTableData(updatedTableData);
        }
      })
      .finally(() => {
        setTableLoadingState(false);
      });
  }

  (headers.applicationContactDetails as IEnhanceTableHeaderClickable).callback =
    (payload: any) => {
      navigate("/plm/policies/" + payload.columns.id);
    };

  const renderMainChildren = () => {
    return (
      <>
        {loading ? (
          <div></div>
        ) : (
          <div style={{ marginTop: "20px" }}>
            <EnhancedTable
              title="Motor Policies"
              name="count"
              orderByAscendingByDefault
              defaultOrderByColumn="applicationContactDetails"
              inline={false}
              data={tableData}
              headers={headers}
              handlePageChange={(page: number) => handlePageChange(page)}
              handleRowsPerPageChange={(page: number) =>
                handleRowsPerPageChange(page)
              }
              currentPage={tableData.pageNumber}
              hideToolbar
              usePagination
              disableSelection
              loader={tableLoadingState}
              actions={actions}
            />
          </div>
        )}
      </>
    );
  };

  const onFilterUpdate = async (v: Record<string, any>) => {
    let newFilters = _.cloneDeep(v);
    setFilter(newFilters, FILTER_SESSION_KEY);
    setFilterValues(newFilters);
  };

  const renderFilter = () => {
    return (
      filterSections && (
        <ListingFilterWidget
          name={""}
          filters={filterSections}
          onApplyFilter={(v) => {
            onFilterUpdate(v);
          }}
        ></ListingFilterWidget>
      )
    );
  };

  return (
    <StaticLayout
      loading={loading || loadingState}
      name={"Policies"}
      leftChildren={renderFilter()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default PolicyListing;
