import { DynamicFormInputType, FormInputTypes } from "../../DynamicForm";
import { isEmpty } from "../../utils/validationUtils";

export const initialValues = {
  businessPartner: "",
  line: "",
  plan: "",
};

export const inputs: Record<string, DynamicFormInputType> = {
  businessPartner: {
    name: "businessPartner",
    title: "Business Partner",
    type: FormInputTypes.chips,
    placeholder: "Business Partner",
    value: !isEmpty(initialValues.businessPartner)
      ? initialValues.businessPartner
      : [],
    required: true,
    multiple: false,
    keyToClear: "line",
  },
  line: {
    name: "line",
    title: "Line",
    type: FormInputTypes.chips,
    placeholder: "Line",
    value: !isEmpty(initialValues.line) ? initialValues.line : [],
    required: true,
    multiple: false,
    keyToClear: "plan",
  },
  plan: {
    name: "plan",
    title: "Plan",
    type: FormInputTypes.chips,
    placeholder: "Plan",
    value: !isEmpty(initialValues.plan) ? initialValues.plan : [],
    required: true,
    multiple: true,
  },
};
