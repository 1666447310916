import React, { useEffect, useState } from "react";
import GenericDrawer from "../../components/common/generic-drawer/GenericDrawer";
import {
  DynamicFormInputType,
  IFormNumberDynamicProps,
  IFormSelectDynamicProps,
} from "../../DynamicForm";
import { toast } from "react-toastify";
import { EnhancedButtonStatus } from "../../components/common/EnhancedButton";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { inputs } from "./content";
import { useMutation, useQuery } from "@apollo/client";
import Loader from "../../components/Loader";
import { cloneDeep, isEmpty } from "lodash";
import { DEFAULT_ERROR_TEXT } from "../../constants";
import { ITravelPlanRatesDrawerInfo, ITravelPlanRatesDrawerProps } from ".";
import {
  createPlanSpecificTravel,
  getTravelPlanRatesEnums,
  getPlanSpecificTravelInfo,
  updatePlanSpecificTravel,
} from "./queries";
import { LookupToList, graphqlToTravelPlanRatesInfo } from "./utils";
import { getError } from "../../utils/graph-utils";
import { normaliseDynamicValues } from "../../utils/dynamic-utils";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import DynamicForm from "../../DynamicForm/DynamicForm";

const TravelPlanRatesDrawer: React.FC<ITravelPlanRatesDrawerProps> = ({
  open,
  onSuccess,
  onClose,
  planId,
  planCurrency,
  travelPlanRateId,
}) => {
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();
  let [submitButtonDisabled, setSubmitButtonDisabled] =
    useState<boolean>(false);
  const [hasPlanActiveToError, setHasPlanActiveToError] = useState("");
  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);

  const [ageFrom, setAgeFrom] = useState<string>("");
  const [ageTo, setAgeTo] = useState<string>("");
  const [travelDurationFrom, setTravelDurationFrom] = useState<string>("");
  const [travelDurationTo, setTravelDurationTo] = useState<string>("");
  const [comparFromTo, setComparFromTo] = useState<number>(0);

  const travelPlanRatesDetailsListResults = useQuery(getTravelPlanRatesEnums());

  const [travelPlanRatesAction] = useMutation(
    travelPlanRateId ? updatePlanSpecificTravel() : createPlanSpecificTravel()
  );

  let travelPlanRateInfoResult: any;
  if (travelPlanRateId) {
    travelPlanRateInfoResult = useQuery(getPlanSpecificTravelInfo(), {
      variables: { id: travelPlanRateId },
    });
  }

  let travelPlanRatesDetailsInfo: ITravelPlanRatesDrawerInfo = {
    ageFrom: "",
    ageTo: "",
    travelDurationFrom: "",
    travelDurationTo: "",
    travelDestination: "",
    numberOfAdults: "",
    numberOfChildren: "",
    premium: "",
  };

  let updatedInputs: Record<string, DynamicFormInputType> = inputs;
  let newTravelPlanRatesEnums: Record<string, Record<string, string>> = {};

  const handleCarChange = (
    carFrom: string,
    carTo: string,
    type: "Age" | "Travel Duration",
    operand: "less" | "greater"
  ) => {
    let error = "";
    if (carTo && carFrom) {
      if (operand === "less") {
        if (parseInt(carFrom) > parseInt(carTo)) {
          error = `${type} From must be less than ${type} To`;
        }
      } else {
        if (parseInt(carTo) < parseInt(carFrom)) {
          error = `${type} To must be greater than ${type} From`;
        }
      }
    }

    setHasPlanActiveToError(error);
    return error;
  };

  const initialize = () => {
    try {
      updatedInputs = cloneDeep(inputsForm);
      updatedInputs.planCurrency.value = planCurrency;

      if (travelPlanRatesDetailsListResults.data) {
        newTravelPlanRatesEnums = LookupToList(
          travelPlanRatesDetailsListResults.data
        );

        (updatedInputs.planCurrency as IFormSelectDynamicProps).selectOptions =
          newTravelPlanRatesEnums["currencies"];

        (
          updatedInputs.travelDestination as IFormSelectDynamicProps
        ).selectOptions = newTravelPlanRatesEnums["travelDestinations"];
      }

      (updatedInputs.ageFrom as IFormNumberDynamicProps).onChange = (event) => {
        setAgeFrom(event.target.value);
        setComparFromTo((oldState) => oldState + 1);
      };

      (updatedInputs.ageTo as IFormNumberDynamicProps).onChange = (event) => {
        setAgeTo(event.target.value);
        setComparFromTo((oldState) => oldState + 1);
      };

      (updatedInputs.travelDurationFrom as IFormNumberDynamicProps).onChange = (
        event
      ) => {
        setTravelDurationFrom(event.target.value);
        setComparFromTo((oldState) => oldState + 1);
      };

      (updatedInputs.travelDurationTo as IFormNumberDynamicProps).onChange = (
        event
      ) => {
        setTravelDurationTo(event.target.value);
        setComparFromTo((oldState) => oldState + 1);
      };

      if (travelPlanRateInfoResult?.data) {
        travelPlanRatesDetailsInfo = graphqlToTravelPlanRatesInfo(
          travelPlanRateInfoResult?.data
        );
      }

      if (travelPlanRatesDetailsInfo) {
        updatedInputs.ageFrom.value = travelPlanRatesDetailsInfo.ageFrom;
        updatedInputs.ageTo.value = travelPlanRatesDetailsInfo.ageTo;
        updatedInputs.travelDurationFrom.value =
          travelPlanRatesDetailsInfo.travelDurationFrom;
        updatedInputs.travelDurationTo.value =
          travelPlanRatesDetailsInfo.travelDurationTo;
        updatedInputs.travelDestination.value =
          travelPlanRatesDetailsInfo.travelDestination;
        updatedInputs.numberOfAdults.value =
          travelPlanRatesDetailsInfo.numberOfAdults;
        updatedInputs.numberOfChildren.value =
          travelPlanRatesDetailsInfo.numberOfChildren;
        updatedInputs.premium.value = travelPlanRatesDetailsInfo.premium;

        setAgeFrom(travelPlanRatesDetailsInfo.ageFrom);
        setAgeTo(travelPlanRatesDetailsInfo.ageTo);

        setTravelDurationFrom(travelPlanRatesDetailsInfo.travelDurationFrom);
        setTravelDurationTo(travelPlanRatesDetailsInfo.travelDurationTo);
      }

      setInputsForm(updatedInputs);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputs, values);

    setFormDisabled(true);
    setSubmitButtonState("loading");

    try {
      let variablesMutation = {};
      variablesMutation = {
        planSpecificTravelInputs: {
          planID: planId,
          ageFrom: Number(data?.ageFrom),
          ageTo: Number(data?.ageTo),
          travelDurationFrom: Number(data?.travelDurationFrom),
          travelDurationTo: Number(data?.travelDurationTo),
          travelDestination: data?.travelDestination,
          numberOfAdults: Number(data?.numberOfAdults),
          numberOfChildren: Number(data?.numberOfChildren),
          premium: Number(data?.premium),
        },
      };

      travelPlanRatesAction({
        variables: travelPlanRateId
          ? {
              ...variablesMutation,
              entityId: travelPlanRateId,
            }
          : variablesMutation,
        errorPolicy: "all",
      })
        .then((res) => {
          if (isEmpty(res.errors)) {
            toast.success(
              <ToastSuccessMessage>
                {travelPlanRateId
                  ? "Plan Rate successfully updated."
                  : "Plan Rate successfully added."}
              </ToastSuccessMessage>
            );
            setTimeout(() => {
              setSubmitButtonState("success");
              onSuccess();
              onClose();
            }, 500);
          } else {
            setSubmitButtonState(undefined);
            toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
          }
        })
        .catch((err) => {
          toast.error(<ToastErrorMessage>{getError(err)}</ToastErrorMessage>);
        });
    } catch {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setFormDisabled(false);
    }
  };

  useEffect(() => {
    initialize();
  }, [travelPlanRatesDetailsListResults.data, travelPlanRateInfoResult?.data]);

  useEffect(() => {
    const errorFrom = handleCarChange(ageFrom, ageTo, "Age", "less");
    setInputsForm((currentInputsForm) => ({
      ...currentInputsForm,
      ageFrom: {
        ...currentInputsForm.ageFrom,
        value: ageFrom && ageFrom.toString(),
        error: errorFrom,
      },
    }));
    const errorTo = handleCarChange(ageFrom, ageTo, "Age", "greater");
    setInputsForm((currentInputsForm) => ({
      ...currentInputsForm,
      ageTo: {
        ...currentInputsForm.ageTo,
        value: ageTo && ageTo.toString(),
        error: errorTo,
      },
    }));
    const errorTravelFrom = handleCarChange(
      travelDurationFrom,
      travelDurationTo,
      "Travel Duration",
      "less"
    );
    setInputsForm((currentInputsForm) => ({
      ...currentInputsForm,
      travelDurationFrom: {
        ...currentInputsForm.travelDurationFrom,
        value: travelDurationFrom && travelDurationFrom.toString(),
        error: errorTravelFrom,
      },
    }));
    const errorTravelTo = handleCarChange(
      travelDurationFrom,
      travelDurationTo,
      "Travel Duration",
      "greater"
    );
    setInputsForm((currentInputsForm) => ({
      ...currentInputsForm,
      travelDurationTo: {
        ...currentInputsForm.travelDurationTo,
        value: travelDurationTo && travelDurationTo.toString(),
        error: errorTravelTo,
      },
    }));
  }, [ageFrom, ageTo, travelDurationFrom, travelDurationTo, comparFromTo]);

  useEffect(() => {
    setSubmitButtonDisabled(hasPlanActiveToError !== "");
  }, [hasPlanActiveToError]);

  return (
    <GenericDrawer
      title={travelPlanRateId ? "Modify Plan Rate" : "Add Plan Rate"}
      onClose={() => onClose()}
      isOpen={open}
    >
      {travelPlanRatesDetailsListResults.loading && open ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={"Submit"}
            submitButtonState={submitButtonState}
            isSubmitButtonDisabled={submitButtonDisabled}
            disableForm={formDisabled}
            title="Information"
            hasDoprdownSpecificBehavior={true}
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default TravelPlanRatesDrawer;
