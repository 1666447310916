import { gql } from "@apollo/client";

export function createVignetteRange() {
  return gql`
    mutation createVignetteRange(
      $entityId: String!
      $rangeFrom: Long!
      $rangeTo: Long!
      $rangeStatus: Insurance_VignetteRangeStatuses!
      $rangeCode: String!
      $rangeYear: Long!
    ) {
      insurance {
        entities {
          agency {
            insurance {
              createVignetteRange(
                entityId: $entityId
                rangeFrom: $rangeFrom
                rangeTo: $rangeTo
                rangeStatus: $rangeStatus
                rangeCode: $rangeCode
                rangeYear: $rangeYear
              ) {
                id
              }
            }
          }
        }
      }
    }
  `;
}

export function getVignetteStatusesEnums() {
  return gql`
    query getVignetteStatusesEnums {
      Insurance_VignetteRangeStatuses: __type(
        name: "Insurance_VignetteRangeStatuses"
      ) {
        name
        enumValues {
          name
        }
      }
    }
  `;
}
