import { gql } from "@apollo/client";

export function getAllRiskPlanRatesEnums() {
  return gql`
    query getAllRiskPlanRatesEnums {
      Core {
        lookups {
          currencies {
            Id
            Title
          }
          carCategories {
            Id
            Title
          }
        }
      }
    }
  `;
}

export function getPlanSpecificAllRiskInfo() {
  return gql`
    query getPlanSpecificAllRiskInfo($id: String!) {
      PlanConfigManagement {
        entities {
          planSpecificAllRisk(id: $id) {
            id
            views {
              PlanConfigManagement_all {
                properties {
                  Id
                  PlanID {
                    id
                  }
                  CarValueFrom
                  CarValueTo
                  CarAgeFrom
                  CarAgeTo
                  CarCategory {
                    Id
                    Title
                  }
                  MinimumPremium
                  Rate
                  AgencyYear
                  NoDepreciationYear
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function createPlanSpecificAllRisk() {
  return gql`
    mutation createPlanSpecificAllRisk(
      $planSpecificAllRiskInputs: PlanConfigManagement_PlanSpecificAllRiskInputs!
    ) {
      planConfigManagement {
        actions {
          createPlanSpecificAllRisk(
            planSpecificAllRiskInputs: $planSpecificAllRiskInputs
          ) {
            id
          }
        }
      }
    }
  `;
}

export function updatePlanSpecificAllRisk() {
  return gql`
    mutation updatePlanSpecificAllRisk(
      $entityId: String!
      $planSpecificAllRiskInputs: PlanConfigManagement_PlanSpecificAllRiskInputs!
    ) {
      planConfigManagement {
        entities {
          planSpecificAllRisk {
            planConfigManagement {
              updatePlanSpecificAllRisk(
                entityId: $entityId
                planSpecificAllRiskInputs: $planSpecificAllRiskInputs
              ) {
                id
              }
            }
          }
        }
      }
    }
  `;
}
