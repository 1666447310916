import { IAbstractRecord } from "../../models";
import { IListingData } from "../../models/listing";
import { capitalizeFirstLetter } from "../../utils/formatting-utils";
import {
  enumListAsRecordObject,
  lookupListAsRecordObject,
} from "../../utils/graph-utils";

export function mapToListingData(data: any): IListingData<any> {
  const list: IAbstractRecord = {};

  data?.SalesforceManagement?.queries?.getBusinessUserList?.items?.forEach(
    (obj: IGenericBusinessUsersTableData, i: number) => {
      list[i] = {
        id: obj?.salesforceManagement_BusinessUser_Id,
        code: obj?.salesforceManagement_BusinessUser_Code,
        name: obj?.salesforceManagement_BusinessUser_PersonID.views
          .SalesforceManagement_all.properties.FullName,
        type: obj?.salesforceManagement_BusinessUser_Type?.Title,
        access: obj?.salesforceManagement_BusinessUser_Access?.Title,
        email:
          obj?.salesforceManagement_BusinessUser_PersonID.views
            .SalesforceManagement_all.properties.Email,
        relatedCompany:
          obj?.salesforceManagement_BusinessUser_RelatedCompany.Title,
        phoneNumber:
          obj?.salesforceManagement_BusinessUser_PersonID.views
            .SalesforceManagement_all.properties.PhoneDetails?.PhoneNumber,
        status: capitalizeFirstLetter(
          obj?.salesforceManagement_BusinessUser_Status?.replaceAll("_", " ")
        ),
        createdOn: obj?.salesforceManagement_BusinessUser_createdOn,
        modifiedOn: obj?.salesforceManagement_BusinessUser_modifiedOn,
      };
    }
  );

  return {
    pagedItems: list,
    pageSize:
      data.SalesforceManagement.queries.getBusinessUserList?.paging
        ?.pageSize,
    pageNumber:
      data.SalesforceManagement.queries.getBusinessUserList?.paging
        ?.pageNumber,
    totalCount:
      data.SalesforceManagement.queries.getBusinessUserList?.paging
        ?.totalCount,
  };
}

export function toLookups(data: any) {
  let lovs: Record<string, Record<string, string>> = {
    type: {},
    access: {},
    status: {},
    company: {},
  };

  lovs.type = lookupListAsRecordObject(
    data?.SalesforceManagement?.lookups?.businessUserTypes
  ); 
  lovs.access = lookupListAsRecordObject(
    data?.SalesforceManagement?.lookups?.businessUserAccesses
  );
  lovs.company = lookupListAsRecordObject(
    data?.SalesforceManagement?.lookups?.relatedCompanies
  );
  lovs.status = enumListAsRecordObject(
    data?.SalesforceManagement_BusinessUserStatuses?.enumValues
  );

  return lovs;
}
