import {
  IConfirmation,
  OpenConfirmationAction,
} from './types';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';

const initialState: IConfirmation = {
  open: false,
  title: '',
  message: '',
};

const confirmationSlice = createSlice({
  name: 'confirmation',
  initialState: initialState,
  reducers: {
    openConfirmation(state, action: PayloadAction<OpenConfirmationAction>) {
      state = action.payload.confirmation;
      return state;
    },
    closeConfirmation(state) {
      state = initialState;
      return state;
    },
  },
});

export const {openConfirmation, closeConfirmation} = confirmationSlice.actions;

const confirmationReducer = confirmationSlice.reducer;
export default confirmationReducer;
