import { DynamicFormInputType, FormInputTypes } from '../../DynamicForm';
import { isEmpty } from '../../utils/validationUtils';

export const initialValues = {
  policyNumberRangeInfo: {
    rangeFrom: '',
    rangeTo: '',
    rangeType: '',
  },
};

export const inputs: Record<string, DynamicFormInputType> = {
  rangeFrom: {
    name: 'rangeFrom',
    title: 'Policy Number From',
    type: FormInputTypes.number,
    placeholder: 'Policy Number From',
    value: initialValues.policyNumberRangeInfo.rangeFrom,
    required: true,
    material: false
  },
  rangeTo: {
    name: 'rangeTo',
    title: 'Policy Number To',
    type: FormInputTypes.number,
    placeholder: 'Policy Number To',
    value: initialValues.policyNumberRangeInfo.rangeTo,
    required: true,
    material: false
  },
  rangeType: {
    name: 'rangeType',
    title: 'Range Type',
    type: FormInputTypes.chips,
    value: !isEmpty(initialValues.policyNumberRangeInfo.rangeType)
    ? initialValues.policyNumberRangeInfo.rangeType
    : [],
    required: true,
    multiple: false,
    disabled: true,
  }
};
