import { gql } from "@apollo/client";

export function getListForm() {
  return gql`
    query getTaxationInfoEnums {
      PlanConfigManagement {
        queries {
          allLines {
            planConfigManagement_Line_Id
            planConfigManagement_Line_Name
            planConfigManagement_Line_Status
          }
        }
      }
      Core {
        lookups {
          currencies {
            Id
            Title
          }
        }
      }
    }
  `;
}

export function getTaxationDetailsInfo() {
  return gql`
    query getTaxationDetailsInfo($id: String!) {
      Configuration {
        entities {
          taxation(id: $id) {
            views {
              Configuration_all {
                properties {
                  LineID {
                    id
                    views {
                      PlanConfigManagement_all {
                        properties {
                          ExternalCode
                          Name
                          Status
                        }
                      }
                    }
                  }
                  Id
                  TaxOnCommission
                  ReinsuranceTax
                  EffectiveFrom
                  EffectiveTo
                  IsLatest
                  FixedStamp
                  FixedStampCurrency {
                    Code
                    Title
                    Id
                  }
                  ProportionalStamp
                  MunicipalityTax
                  createdBy
                  createdOn
                  modifiedBy
                  modifiedOn
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function createTaxation() {
  return gql`
    mutation createTaxation(
      $effectiveFrom: Date!
      $fixedStamp: Decimal!
      $fixedStampCurrency: String!
      $lineID: String!
      $municipalityTax: Decimal!
      $proportionalStamp: Decimal!
      $reinsuranceTax: Decimal!
      $taxOnCommission: Decimal!
    ) {
      configuration {
        actions {
          createTaxation(
            effectiveFrom: $effectiveFrom
            fixedStamp: $fixedStamp
            fixedStampCurrency: $fixedStampCurrency
            lineID: $lineID
            municipalityTax: $municipalityTax
            proportionalStamp: $proportionalStamp
            reinsuranceTax: $reinsuranceTax
            taxOnCommission: $taxOnCommission
          ) {
            id
            views {
              Configuration_all {
                properties {
                  IsLatest
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function updateTaxation() {
  return gql`
    mutation updateTaxation(
      $entityId: String!
      $effectiveFrom: Date!
      $fixedStamp: Decimal!
      $fixedStampCurrency: String!
      $municipalityTax: Decimal!
      $proportionalStamp: Decimal!
      $reinsuranceTax: Decimal!
      $taxOnCommission: Decimal!
    ) {
      configuration {
        entities {
          taxation {
            configuration {
              updateTaxation(
                entityId: $entityId
                effectiveFrom: $effectiveFrom
                fixedStamp: $fixedStamp
                fixedStampCurrency: $fixedStampCurrency
                municipalityTax: $municipalityTax
                proportionalStamp: $proportionalStamp
                reinsuranceTax: $reinsuranceTax
                taxOnCommission: $taxOnCommission
              ) {
                id
                views {
                  Configuration_all {
                    properties {
                      IsLatest
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function fetchedLines() {
  return gql`
    query fetchedLines($searchKeyword: String) {
      PlanConfigManagement {
        queries {
          allLines(searchKeyword: $searchKeyword) {
            planConfigManagement_Line_Id
            planConfigManagement_Line_Name
            planConfigManagement_Line_ExternalCode
          }
        }
      }
    }
  `;
}

export function getLatestTaxationByLine() {
  return gql`
    query getLatestTaxationByLine($selectedLineID: String!) {
      Configuration {
        queries {
          getLatestTaxationByLine(selectedLineID: $selectedLineID) {
            configuration_Taxation_Id
            configuration_Taxation_TaxOnCommission
            configuration_Taxation_ReinsuranceTax
            configuration_Taxation_EffectiveFrom
            configuration_Taxation_EffectiveTo
            configuration_Taxation_IsLatest
            line_Id
            line_Name
            line_ExternalCode
            configuration_Taxation_createdOn
            configuration_Taxation_FixedStamp
            configuration_Taxation_FixedStampCurrency {
              Code
              Title
            }
            configuration_Taxation_ProportionalStamp
            configuration_Taxation_MunicipalityTax
          }
        }
      }
    }
  `;
}
