import { DynamicFormInputType, FormInputTypes } from "../../DynamicForm";
import { isEmpty } from "../../utils/validationUtils";

export const initialValues = {
  providerDetails: {
    person: "",
    type: "",
    isCompany: false,
    isLocal: false,
    effectiveDate: "",
    expiryDate: "",
    Configuration_ProviderStatuses: "ACTIVE",
  },
};

export const inputs: Record<string, DynamicFormInputType> = {
  person: {
    name: "person",
    title: "Person",
    type: FormInputTypes.chips,
    placeholder: "Type to search for Person",
    value: !isEmpty(initialValues.providerDetails.person)
      ? [initialValues.providerDetails.person]
      : [],
    multiple: false,
    disabled: false,
    required: true,
    hasPagination: true,
    itemsPerPage: 15,
  },
  type: {
    name: "type",
    title: "Type",
    type: FormInputTypes.chips,
    placeholder: "Type",
    value: !isEmpty(initialValues.providerDetails.type)
      ? initialValues.providerDetails.type
      : "",
    multiple: false,
    disabled: false,
    required: true,
  },
  isCompany: {
    name: "isCompany",
    title: "Is Company",
    placeholder: "Is Company",
    type: FormInputTypes.switch,
    value: initialValues.providerDetails.isCompany,
    required: false,
    disabled: false,
  },
  isLocal: {
    name: "isLocal",
    title: "Is Local",
    placeholder: "Is Local",
    type: FormInputTypes.switch,
    value: initialValues.providerDetails.isLocal,
    required: false,
    disabled: false,
  },
  effectiveDate: {
    name: "effectiveDate",
    title: "Effective Date",
    type: FormInputTypes.date,
    placeholder: "Effective Date",
    value: initialValues.providerDetails.effectiveDate,
    required: true,
    disabled: false,
  },
  expiryDate: {
    name: "expiryDate",
    title: "Expiry Date",
    type: FormInputTypes.date,
    placeholder: "Expiry Date",
    value: initialValues.providerDetails.expiryDate,
    required: false,
    disabled: false,
  },
  Configuration_ProviderStatuses: {
    name: "Configuration_ProviderStatuses",
    title: "Status",
    type: FormInputTypes.chips,
    value: initialValues.providerDetails.Configuration_ProviderStatuses,
    required: true,
    multiple: false,
    disabled: false,
  },
};
