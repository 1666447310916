import { IEntityInfoView } from '../../components/widgets/entity-info/EntityInfoFields';
import { ITabsLayout, LayoutType } from '../../page-layout';

export const steps = ["Inactive", "Active"];

export const entityView: IEntityInfoView = {
  name: 'section1',
  title: 'Provider Details',
  iconUrl: '',
  sections: [
    {
      name: 'providerDetailsSection1',
      title: '',
      properties: {
        name: {
          value: '',
          multiline: false,
          name: 'name',
          title: 'Full Name',
        },
        providerCode: {
          value: '',
          multiline: false,
          name: 'providerCode',
          title: 'Provider Code',
        },
        dateOfBirth: {
          value: '',
          multiline: false,
          name: 'dateOfBirth',
          title: 'Date Of Birth',
        },
        email: {
          value: '',
          multiline: false,
          name: 'email',
          title: 'Email',
        },
        phoneType: {
          value: '',
          multiline: false,
          name: 'phoneType',
          title: 'Phone Type',
        },
        phoneNumber: {
          value: '',
          multiline: false,
          name: 'phoneNumber',
          title: 'Phone Number',
        },
        taxable : {
          value: '',
          multiline: false,
          name: 'taxable ',
          title: 'Taxable ',
        },
      },
    },
    {
      name: 'providerDetailsSection2',
      title: '',
      properties: {
        createdBy: {
          value: '',
          multiline: false,
          name: 'createdBy',
          title: 'Created By',
        },
        createdOn: {
          value: '',
          multiline: false,
          name: 'createdOn',
          title: 'Created On',
        },
        modifiedBy: {
          value: '',
          multiline: false,
          name: 'modifiedBy',
          title: 'Modified By',
        },
        modifiedOn: {
          value: '',
          multiline: false,
          name: 'modifiedOn',
          title: 'Modified On',
        },
      },
    },
  ],
  socialMedia: {
    facebook: '',
    linkedIn: '',
    twitter: '',
  },
};

export const providerTabs: ITabsLayout = {
  typeLogicalId: LayoutType.tabslayout,
  leftColumn: {
    backgroundColor: '#FFFFFF',
    collapsable: false,
    width: 2,
    collapsed: false,
  },
  mainColumn: {
    backgroundColor: '#F7F7F7',
    collapsable: false,
    width: 10,
    collapsed: false,
  },
  rightColumn: {
    backgroundColor: '#FFFFFF',
    collapsable: false,
    width: 0,
    collapsed: false,
  },
  tabs: [
    {
      name: 'providerDetailswidget',
      title: 'Provider Details widget',
      iconUrl: '/icons/file-summary-primary.svg',
      widgets: [{}],
    },
  ],
};
