import React, { useState, useEffect } from 'react';
import AppLauncherModal from './AppLauncherModal';
import { makeStyles } from 'tss-react/mui';
import { IApplication } from '../../Shell';
import { mainFontFamilyMedium } from '../../constants';
import ShowForUser from '../user/ShowForUser';

interface IAppLauncherProps {
  appLauncherUrl: string;
  applications: Record<string, IApplication>;
}

const useStyles = makeStyles()(() => ({
  appLauncherButton: {
    margin: '0 30px 0 40px',
    border: 'none',
    outline: 'none',
    background: 'unset',
  },
  appLauncherFig: {
    margin: 0,
  },
  appLauncherImg: {
    height: 25,
    width: 25,
    minWidth: 25,
    cursor: 'pointer',
    fontFamily: mainFontFamilyMedium,
  },
  dialogPaper: {
    height: '100%',
  },
  dialogContent: {},
}));

const AppLauncher: React.FC<IAppLauncherProps> = ({
  appLauncherUrl,
  applications,
}) => {
  const [open, setOpen] = useState(false);
  const [filteredApplication, setFilteredApplications] = useState(applications);

  const { classes } = useStyles();

  useEffect(() => {
    const apps: Record<string, IApplication> = {};
    Object.keys(applications).forEach((app) => {
      if (!applications[app].hidden) {
        apps[app] = applications[app];
      }
    });
    setFilteredApplications(apps);
  }, [applications]);

  function openModal() {
    setOpen(true);
  }

  function closeModal() {
    setOpen(false);
  }

  return (
    <>
      <ShowForUser
        allowedRoles={[
          'Insurance-Admin',
          'Insurance-Underwriter',
          'Insurance-IssuingAgent',
          'Insurance-QuotingAgent',
        ]}
      >
        <button
          className={classes.appLauncherButton}
          onClick={openModal}
          type='button'
        >
          <figure className={classes.appLauncherFig}>
            <img
              className={classes.appLauncherImg}
              src={appLauncherUrl}
              alt={'App Launcher'}
            />
          </figure>
        </button>
        <AppLauncherModal
          open={open}
          closeModal={closeModal}
          applications={filteredApplication}
        />
      </ShowForUser>
    </>
  );
};

export default AppLauncher;
