import { GetExpatApplicationDetailsQuery } from '../../gql/__generated__/graphql';
import {
  enumListAsRecordObject,
  lookupListAsRecordObject,
  lookupListAsRecordObjectWithArabicTitle,
} from '../../utils/graph-utils';

export function toLookups(data: GetExpatApplicationDetailsQuery, agentType: string) {
  const policyDetailsList: Record<string, Record<string, string>> = {};
  policyDetailsList['phoneType'] = enumListAsRecordObject(
    data?.Insurance_PhoneType?.enumValues as {
      __typename: string;
      name: string;
    }[]
  );
  policyDetailsList['countries'] = lookupListAsRecordObject(
    data?.Insurance?.lookups?.countries
  );

  policyDetailsList['title'] = lookupListAsRecordObject(
    data?.Insurance?.lookups?.titles
  );

  policyDetailsList['insuredGender'] = enumListAsRecordObject(
    data?.Insurance_Genders?.enumValues as {
      __typename: string;
      name: string;
    }[]
  );

  policyDetailsList['nationalities'] = lookupListAsRecordObject(
    data?.Insurance?.lookups?.nationalities
  );

  if(agentType && agentType?.toUpperCase() === "EXTERNAL"){
    delete policyDetailsList["nationalities"][290];
  }

  delete policyDetailsList["nationalities"][291];

  policyDetailsList['occupations'] = lookupListAsRecordObjectWithArabicTitle(
    data?.Insurance?.lookups?.occupations
  );

  policyDetailsList['renewalStatus'] = enumListAsRecordObject(
    data?.Insurance_RenewalStatus?.enumValues as {
      __typename: string;
      name: string;
    }[]
  );

  return policyDetailsList;
}
