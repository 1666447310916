export interface ISublineDetailsPageProps {}

export interface ISublineDetails {
  sublineName: string;
  lineName: string;
  externalCode: string;
  arabicName: string;
  status: string;
}

export const initialValues: ISublineDetails = {
  sublineName: "",
  lineName: "",
  externalCode: "",
  arabicName: "",
  status: "",
};

export interface IGenericPlanTableData {
  planConfigManagement_Plan_Id: string;
  planConfigManagement_Plan_EffectiveFrom: string;
  planConfigManagement_Plan_EffectiveTo: string;
  planConfigManagement_Plan_PlanStatus: string;
  planConfigManagement_Plan_createdOn: string;
  planConfigManagement_Plan_modifiedOn: string;
  planConfigManagement_Plan_ExternalCode: string;
  planConfigManagement_Plan_Name: string;
  planConfigManagement_Plan_NameArabic: string;
  planConfigManagement_Plan_SublineID: {
    views: {
      PlanConfigManagement_all: {
        properties: {
          Name: string;          
        };
      };
    };
  };
}