import { DynamicFormInputType, FormInputTypes } from '../../DynamicForm';

export const initialValues = {
  nssfCodeRangeInfo: {
    rangeFrom: '',
    rangeTo: '',
  },
};

export const inputs: Record<string, DynamicFormInputType> = {
  rangeFrom: {
    name: 'rangeFrom',
    title: 'Range From',
    type: FormInputTypes.number,
    placeholder: 'Range From',
    value: initialValues.nssfCodeRangeInfo.rangeFrom,
    required: true,
    material: false
  },
  rangeTo: {
    name: 'rangeTo',
    title: 'Range To',
    type: FormInputTypes.number,
    placeholder: 'Range To',
    value: initialValues.nssfCodeRangeInfo.rangeTo,
    required: true,
    material: false
  }
};
