import React from 'react';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';
import { EnhancedTableFilterType, IEnhancedTableSelectFilter } from '.';
import CustomCheckboxDropdown from '../common/custom-checkbox-dropdown/CustomCheckBoxDropdown';
import { contentFontFamilyBold } from '../../constants';

// TODO Unfinished component, left here until further notice
interface IEnhancedTableFilterProps {
  filters: Record<string, EnhancedTableFilterType>;
  onFilterClick(filter: string, field: string, value: boolean): void;
  onClear(): void;
}

const useStyles = makeStyles()(() => ({
  title: {
    fontSize: '12px',
    minWidth: '125px',
    display: 'block',
    cursor: 'pointer',
    height: '100%',
  },
  boldTitle: {
    fontFamily: contentFontFamilyBold,
  },
}));

const EnhancedTableFilter: React.FC<IEnhancedTableFilterProps> = ({
  filters,
  onFilterClick,
  onClear,
}) => {
  const { classes } = useStyles();

  const getDropdownValue = (filter: IEnhancedTableSelectFilter) => {
    const { length } = filter.selectedValues;
    if (length > 0) {
      return (
        <span className={clsx(classes.title, classes.boldTitle)}>
          {filter.title} {`(+${length})`}
        </span>
      );
    }

    return (
      <span className={clsx(classes.title, classes.boldTitle)}>
        {filter.title}
      </span>
    );
  };

  const renderFilter = (filter: EnhancedTableFilterType) => {
    if (
      filter.type == 'select' &&
      filter.options &&
      filter.options.length > 0
    ) {
      <CustomCheckboxDropdown
        key={filter.name}
        name={filter.name}
        options={filter.options}
        selectedOptions={filter.selectedValues}
        onOptionClick={(field, value) =>
          onFilterClick(filter.name, field, value)
        }
        onClear={onClear}
        value={getDropdownValue(filter)}
        disabled={false}
      />;
    }

    return <></>;
  };

  return (
    filters && (
      <>{Object.values(filters).map((filter) => renderFilter(filter))}</>
    )
  );
};

export default EnhancedTableFilter;
