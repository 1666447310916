import { gql } from "@apollo/client";

export function getDefaultCommissionEnums() {
  return gql`
    query getDefaultCommissionEnums {
      Core_CommissionTypes: __type(name: "Core_CommissionTypes") {
        name
        enumValues {
          name
        }
      }
      Core {
        lookups {
          currencies {
            Id
            Title
          }
          commissionApplicableOnOptions {
            Id
            Title
          }
        }
      }
    }
  `;
}

export function updatePlanSpecialCommissions() {
  return gql`
    mutation updatePlanSpecialCommissions(
      $entityId: String!
      $planSpecialCommissionInputs: PlanConfigManagement_PlanSpecialCommissionInputs!
    ) {
      planConfigManagement {
        entities {
          plan {
            planConfigManagement {
              updatePlanSpecialCommissions(
                entityId: $entityId
                planSpecialCommissionInputs: $planSpecialCommissionInputs
              ) {
                id
              }
            }
          }
        }
      }
    }
  `;
}
