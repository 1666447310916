import React, { useEffect, useState } from "react";
import GenericDrawer from "../../components/common/generic-drawer/GenericDrawer";
import {
  DynamicFormInputType,
  IFormSelectDynamicProps,
} from "../../DynamicForm";
import { toast } from "react-toastify";
import { EnhancedButtonStatus } from "../../components/common/EnhancedButton";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { inputs } from "./content";
import { useMutation, useQuery } from "@apollo/client";
import Loader from "../../components/Loader";
import { cloneDeep, isEmpty } from "lodash";
import { DEFAULT_ERROR_TEXT } from "../../constants";
import { ISalesForceMaxCreditLimitDrawerProps } from ".";
import { getListForm, updateMaxCreditLimit } from "./queries";
import { toLookups } from "./utils";
import { getError } from "../../utils/graph-utils";
import { normaliseDynamicValues } from "../../utils/dynamic-utils";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import DynamicForm from "../../DynamicForm/DynamicForm";

const SalesForceMaxCreditLimitDrawer: React.FC<
  ISalesForceMaxCreditLimitDrawerProps
> = ({
  open,
  onSuccess,
  onClose,
  salesForceMaxCreditLimitDetailsInfo,
  businessPartnerId,
}) => {
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();
  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);

  const maxCreditLimitsDetailsListResults = useQuery(getListForm());

  const [maxCreditLimitAction] = useMutation(updateMaxCreditLimit());

  useEffect(() => {
    initialize();
  }, [maxCreditLimitsDetailsListResults.data]);

  let updatedInputs: Record<string, DynamicFormInputType> = inputs;

  const initialize = () => {
    try {
      updatedInputs = cloneDeep(inputsForm);

      if (maxCreditLimitsDetailsListResults.data) {
        const lookupLists = toLookups(maxCreditLimitsDetailsListResults.data);

        (
          updatedInputs.maxCreditLimitAllowedCurrency as IFormSelectDynamicProps
        ).selectOptions = lookupLists["maxCreditLimitAllowedCurrency"];
      }

      if (salesForceMaxCreditLimitDetailsInfo) {
        updatedInputs.maxCreditLimitAllowedCurrency.value =
          salesForceMaxCreditLimitDetailsInfo.maxCreditLimitAllowedCurrency;
        updatedInputs.maxCreditLimitAllowed.value =
          salesForceMaxCreditLimitDetailsInfo.maxCreditLimitAllowed;
      }

      updatedInputs.maxCreditLimitAllowedCurrency.value = updatedInputs
        .maxCreditLimitAllowedCurrency.value
        ? updatedInputs.maxCreditLimitAllowedCurrency.value
        : "39735";

      setInputsForm(updatedInputs);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputs, values);

    setFormDisabled(true);
    setSubmitButtonState("loading");

    try {
      let variablesMutation = {};
      variablesMutation = {
        entityId: businessPartnerId,
        maxCreditLimitAllowedCurrency:
          data.maxCreditLimitAllowedCurrency || null,
        maxCreditLimitAllowed: parseInt(
          data.maxCreditLimitAllowed as unknown as string
        ),
      };

      maxCreditLimitAction({
        variables: variablesMutation,
        errorPolicy: "all",
      })
        .then((res) => {
          if (isEmpty(res.errors)) {
            toast.success(
              <ToastSuccessMessage>
                Maximum Credit Limit successfully updated.
              </ToastSuccessMessage>
            );
            setTimeout(() => {
              setSubmitButtonState("success");
              onSuccess();
              onClose();
            }, 500);
          } else {
            setSubmitButtonState(undefined);
            toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
          }
        })
        .catch((err) => {
          toast.error(<ToastErrorMessage>{getError(err)}</ToastErrorMessage>);
        });
    } catch {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setFormDisabled(false);
    }
  };

  return (
    <GenericDrawer
      title={"Modify Maximum Credit Limit"}
      onClose={() => onClose()}
      isOpen={open}
    >
      {maxCreditLimitsDetailsListResults.loading && open ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={"Submit"}
            submitButtonState={submitButtonState}
            disableForm={formDisabled}
            title="Information"
            hasDoprdownSpecificBehavior={true}
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default SalesForceMaxCreditLimitDrawer;
