import React, { useEffect, useState } from "react";
import GenericDrawer from "../../components/common/generic-drawer/GenericDrawer";
import {
  DynamicFormInputType,
  IFormChipsDynamicProps,
  IFormSelectDynamicProps,
  IFormSwitchDynamicProps,
} from "../../DynamicForm";
import { toast } from "react-toastify";
import { EnhancedButtonStatus } from "../../components/common/EnhancedButton";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { inputs } from "./content";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import Loader from "../../components/Loader";
import { cloneDeep, isEmpty } from "lodash";
import { DEFAULT_ERROR_TEXT } from "../../constants";
import { IAssignPolicyCoverDrawerProps, IAssignPolicyCoverInfo } from ".";
import {
  createAssignPolicyCover,
  fetchedClauses,
  fetchedPolicyCovers,
  getAssignPolicyCoverEnums,
  getAssignedPolicyCoverInfo,
  updateAssignPolicyCover,
} from "./queries";
import {
  extractClauses,
  extractPolicyCovers,
  LookupToList,
  mapToAssignPolicyCoverInfo,
} from "./utils";
import { getError } from "../../utils/graph-utils";
import { normaliseDynamicValues } from "../../utils/dynamic-utils";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import DynamicForm from "../../DynamicForm/DynamicForm";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  isEditableStyle: {
    marginBottom: "17px",
  },
}));

const AssignPolicyCoverDrawer: React.FC<IAssignPolicyCoverDrawerProps> = ({
  open,
  onSuccess,
  onClose,
  planId,
  lineId,
  planCurrencyId,
  planCurrencyTitle,
  assignPolicyCoverId,
  planLineExternalCode,
}) => {
  const { classes } = useStyles();
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();
  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);
  const [newAllValues, setNewAllValues] = useState<Record<string, any>>();

  const assignPolicyCoverDetailsListResults = useQuery(
    getAssignPolicyCoverEnums()
  );

  const [fetchedPolicyCoversLazy, fetchedPolicyCoversQueryResult] =
    useLazyQuery(fetchedPolicyCovers());
  const [fetchedClausesLazy, fetchedClausesQueryResult] = useLazyQuery(
    fetchedClauses()
  );

  const [assignPolicyCoverAction] = useMutation(
    assignPolicyCoverId ? updateAssignPolicyCover() : createAssignPolicyCover()
  );

  let policyCoverInfoResult: any;
  if (assignPolicyCoverId) {
    policyCoverInfoResult = useQuery(getAssignedPolicyCoverInfo(), {
      variables: { id: assignPolicyCoverId },
    });
  }

  const [selectedPolicyCoverID, setSelectedPolicyCoverID] = useState("");
  const [selectedClauseReferenceID, setSelectedClauseReferenceID] =
    useState("");
  function handleSelection(selectedOption: any, type: string) {
    if (type === "policycover") {
      setSelectedPolicyCoverID(selectedOption);
    } else if (type === "clause") {
      setSelectedClauseReferenceID(selectedOption);
    }
  }

  let assignPolicyCoverDetailsInfo: IAssignPolicyCoverInfo = {
    policyCover: "",
    policyCoverTitle: "",
    coverPremiumType: "VALUE",
    coverPremiumPercentage: "",
    coverPremiumValue: 0,
    coverSumInsured: 0,
    additiveSumInsured: false,
    main: false,
    mandatory: false,
    editable: false,
    excessOnClaimType: "PERCENTAGE",
    excessOnClaimPercentage: "",
    excessOnClaimAmount: 0,
    excessOnClaimDays: 0,
    clauseReference: "",
    clauseReferenceTitle: "",
  };

  let updatedInputs: Record<string, DynamicFormInputType> = inputs;
  const initialize = () => {
    try {
      updatedInputs = cloneDeep(inputsForm);
      updatedInputs.currency.value = planCurrencyTitle;

      (updatedInputs.editable as IFormSwitchDynamicProps).className =
        classes.isEditableStyle;

      const isExternalCode51 = planLineExternalCode === "51";

      if (isExternalCode51) {
        updatedInputs.premiumType.hidden = false;
        updatedInputs.premiumType.required = true;

        updatedInputs.premiumPercentage.hidden = false;
        updatedInputs.premiumPercentage.required = true;
      } else if (!isExternalCode51) {
        updatedInputs.premiumType.hidden = true;
        updatedInputs.premiumType.required = false;

        updatedInputs.premiumPercentage.hidden = true;
        updatedInputs.premiumPercentage.required = false;
      }

      if (assignPolicyCoverDetailsListResults.data) {
        const newAssignPolicyCoverEnums = LookupToList(
          assignPolicyCoverDetailsListResults.data
        );

        (updatedInputs.premiumType as IFormSelectDynamicProps).selectOptions =
          newAssignPolicyCoverEnums["coverPremiumTypes"];
        (
          updatedInputs.excessOnClaimType as IFormSelectDynamicProps
        ).selectOptions = newAssignPolicyCoverEnums["excessOnClaimTypes"];

        (updatedInputs.currency as IFormSelectDynamicProps).selectOptions =
          newAssignPolicyCoverEnums["currencies"];

        (updatedInputs.premiumType as IFormChipsDynamicProps).onChange = (
          option
        ) => {
          const selectedType = option.toLowerCase();
          const isTypePercentage = selectedType === "percentage";
          const isTypeValue = selectedType === "value";

          if (isExternalCode51) {
            updatedInputs.premiumPercentage.hidden = !isTypePercentage;
            updatedInputs.premiumPercentage.required = isTypePercentage;

            updatedInputs.premium.hidden = !isTypeValue;
            updatedInputs.premium.required = isTypeValue;
          } else if (!isExternalCode51) {
            updatedInputs.premiumPercentage.hidden = true;
            updatedInputs.premiumPercentage.required = false;

            updatedInputs.premium.hidden = true;
            updatedInputs.premium.required = false;
          }
        };

        (updatedInputs.excessOnClaimType as IFormChipsDynamicProps).onChange = (
          option
        ) => {
          const selectedType = option.toLowerCase();
          const isTypePercentage = selectedType === "percentage";
          const isTypeValue = selectedType === "value";
          const isTypeDays = selectedType === "days";

          updatedInputs.excessOnClaim.hidden = !isTypeValue;
          updatedInputs.excessOnClaim.required = isTypeValue;

          updatedInputs.excessOnClaimPercentage.hidden = !isTypePercentage;
          updatedInputs.excessOnClaimPercentage.required = isTypePercentage;

          updatedInputs.excessOnClaimDays.hidden = !isTypeDays;
          updatedInputs.excessOnClaimDays.required = isTypeDays;
        };

        (updatedInputs.policyCover as IFormChipsDynamicProps).onChange = (
          option
        ) => {
          handlePolicyCoverInput(option);
          handleSelection(option[0], "policycover");
        };

        (updatedInputs.clauseReference as IFormChipsDynamicProps).onChange = (
          option
        ) => {
          handleClauseInput(option);
          handleSelection(option[0], "clause");
        };
      }

      if (policyCoverInfoResult?.data) {
        assignPolicyCoverDetailsInfo = mapToAssignPolicyCoverInfo(
          policyCoverInfoResult?.data
        );
      }

      if (assignPolicyCoverDetailsInfo) {
        updatedInputs.policyCover.value =
          assignPolicyCoverDetailsInfo.policyCover;
        updatedInputs.clauseReference.value =
          assignPolicyCoverDetailsInfo.clauseReference;

        updatedInputs.premiumPercentage.value =
          assignPolicyCoverDetailsInfo?.coverPremiumPercentage;
        updatedInputs.premiumType.value =
          assignPolicyCoverDetailsInfo?.coverPremiumType;
        updatedInputs.premium.value =
          assignPolicyCoverDetailsInfo?.coverPremiumValue;
        updatedInputs.sumInsured.value =
          assignPolicyCoverDetailsInfo?.coverSumInsured;
        updatedInputs.excessOnClaim.value =
          assignPolicyCoverDetailsInfo?.excessOnClaimAmount;
        updatedInputs.excessOnClaimDays.value =
          assignPolicyCoverDetailsInfo?.excessOnClaimDays;
        updatedInputs.excessOnClaimPercentage.value =
          assignPolicyCoverDetailsInfo?.excessOnClaimPercentage;
        updatedInputs.excessOnClaimType.value =
          assignPolicyCoverDetailsInfo?.excessOnClaimType;
        updatedInputs.main.value = assignPolicyCoverDetailsInfo?.main;
        updatedInputs.mandatory.value = assignPolicyCoverDetailsInfo?.mandatory;
        updatedInputs.editable.value = assignPolicyCoverDetailsInfo?.editable;
        updatedInputs.additiveSumInsured.value =
          assignPolicyCoverDetailsInfo?.additiveSumInsured;

        const selectedCoverPremiumType =
          assignPolicyCoverDetailsInfo?.coverPremiumType.toLowerCase();
        const isCoverPremiumPercentage =
          selectedCoverPremiumType === "percentage";
        const isCoverPremiumValue = selectedCoverPremiumType === "value";
        if (isExternalCode51) {
          updatedInputs.premiumPercentage.hidden = !isCoverPremiumPercentage;
          updatedInputs.premiumPercentage.required = isCoverPremiumPercentage;
          updatedInputs.premium.hidden = !isCoverPremiumValue;
          updatedInputs.premium.required = isCoverPremiumValue;
        } else if (!isExternalCode51) {
          updatedInputs.premiumPercentage.hidden = true;
          updatedInputs.premiumPercentage.required = false;
          updatedInputs.premium.hidden = true;
          updatedInputs.premium.required = false;
        }

        const selectedClaimType =
          assignPolicyCoverDetailsInfo?.excessOnClaimType.toLowerCase();
        const isTypePercentage = selectedClaimType === "percentage";
        const isTypeValue = selectedClaimType === "value";
        const isTypeDays = selectedClaimType === "days";
        updatedInputs.excessOnClaim.hidden = !isTypeValue;
        updatedInputs.excessOnClaim.required = isTypeValue;
        updatedInputs.excessOnClaimPercentage.hidden = !isTypePercentage;
        updatedInputs.excessOnClaimPercentage.required = isTypePercentage;
        updatedInputs.excessOnClaimDays.hidden = !isTypeDays;
        updatedInputs.excessOnClaimDays.required = isTypeDays;

        if (assignPolicyCoverDetailsInfo?.policyCover) {
          updatedInputs.policyCover.preselectedValues =
            assignPolicyCoverDetailsInfo?.policyCoverTitle;

          handlePolicyCoverInput(assignPolicyCoverDetailsInfo?.policyCover);
          handleSelection(
            assignPolicyCoverDetailsInfo?.policyCover,
            "policycover"
          );
        }
        if (assignPolicyCoverDetailsInfo?.clauseReference) {
          updatedInputs.clauseReference.preselectedValues =
            assignPolicyCoverDetailsInfo?.clauseReferenceTitle;

          handleClauseInput(assignPolicyCoverDetailsInfo?.clauseReference);
          handleSelection(
            assignPolicyCoverDetailsInfo?.clauseReference,
            "clause"
          );
        }
      }

      updatedInputs.premium.hidden = false;
      updatedInputs.premium.required = true;

      setInputsForm(updatedInputs);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputs, values);

    setFormDisabled(true);
    setSubmitButtonState("loading");

    try {
      const isPremiumPercentage =
        data.premiumType.toLowerCase() === "percentage";
      let variables = {};
      variables = {
        planCoverInputs: {
          planID: planId,
          clauseID: selectedClauseReferenceID || null,
          policyCoverID: selectedPolicyCoverID,
          coverPremiumPercentage: isPremiumPercentage
            ? data?.premiumPercentage !== "" || 0
              ? parseInt(data.premiumPercentage, 10) / 100
              : 0
            : 0,
          coverPremiumType: !isEmpty(data.premiumType)
            ? data.premiumType
            : null,
          coverPremiumValue: !isPremiumPercentage ? Number(data?.premium) : 0,
          coverSumInsured: Number(data?.sumInsured),
          excessOnClaimAmount:
            data.excessOnClaimType.toLowerCase() === "value"
              ? Number(data?.excessOnClaim)
              : 0,
          excessOnClaimDays:
            data.excessOnClaimType.toLowerCase() === "days"
              ? Number(data?.excessOnClaimDays)
              : 0,
          excessOnClaimPercentage:
            data.excessOnClaimType.toLowerCase() === "percentage"
              ? data?.excessOnClaimPercentage !== ""
                ? parseInt(data.excessOnClaimPercentage, 10) / 100
                : 0
              : 0,
          excessOnClaimType: !isEmpty(data.excessOnClaimType)
            ? data.excessOnClaimType
            : null,
          isMain: data.main,
          isMandatory: data.mandatory,
          isEditable: data.editable,
          sumInsuredIsAdditive: data.additiveSumInsured,
        },
      };

      assignPolicyCoverAction({
        variables: assignPolicyCoverId
          ? {
              ...variables,
              entityId: assignPolicyCoverId,
            }
          : variables,
        errorPolicy: "all",
      })
        .then((res) => {
          if (isEmpty(res.errors)) {
            toast.success(
              <ToastSuccessMessage>
                {assignPolicyCoverId
                  ? "Assigned Policy Cover details successfully updated."
                  : "Policy cover successfully assigned."}
              </ToastSuccessMessage>
            );
            setTimeout(() => {
              setSubmitButtonState("success");
              onSuccess();
              onClose();
            }, 500);
          } else {
            setSubmitButtonState(undefined);
            toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
          }
        })
        .catch((err) => {
          toast.error(<ToastErrorMessage>{getError(err)}</ToastErrorMessage>);
        });
    } catch {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setFormDisabled(false);
    }
  };

  useEffect(() => {
    if (fetchedPolicyCoversQueryResult?.data) {
      const extractedFetchedPolicyCovers = extractPolicyCovers(
        fetchedPolicyCoversQueryResult.data
      );

      setInputsForm((currentInputsForm) => {
        const updatedForm = {
          ...createInputsFormFromAllValues(currentInputsForm, newAllValues),
        };

        (updatedForm.policyCover as IFormChipsDynamicProps).selectOptions = {
          ...extractedFetchedPolicyCovers,
        };

        return updatedForm;
      });
    } else {
      (updatedInputs.policyCover as IFormChipsDynamicProps).selectOptions = {};
    }
  }, [fetchedPolicyCoversQueryResult.data]);

  useEffect(() => {
    if (fetchedClausesQueryResult?.data) {
      const extractedFetchedPClauses = extractClauses(
        fetchedClausesQueryResult.data
      );

      setInputsForm((currentInputsForm) => {
        const updatedForm = {
          ...createInputsFormFromAllValues(currentInputsForm, newAllValues),
        };

        (updatedForm.clauseReference as IFormChipsDynamicProps).selectOptions =
          {
            ...extractedFetchedPClauses,
          };

        return updatedForm;
      });
    } else {
      (updatedInputs.clauseReference as IFormChipsDynamicProps).selectOptions =
        {};
    }
  }, [fetchedClausesQueryResult.data]);

  function handlePolicyCoverInput(inputValue: any) {
    if (inputValue !== null) {
      if (inputValue.length >= 3) {
        fetchedPolicyCoversLazy({
          variables: {
            selectedLineIds: [lineId],
            searchKeyword: inputValue,
          },
        });
      }
    } else {
      (updatedInputs.policyCover as IFormChipsDynamicProps).selectOptions = {};
    }
  }

  function handleClauseInput(inputValue: any) {
    if (inputValue !== null) {
      if (inputValue.length >= 3) {
        fetchedClausesLazy({
          variables: {
            selectedLineIds: [lineId],
            searchKeyword: inputValue,
          },
        });
      }
    } else {
      (updatedInputs.clauseReference as IFormChipsDynamicProps).selectOptions =
        {};
    }
  }

  useEffect(() => {
    initialize();
  }, [assignPolicyCoverDetailsListResults.data, policyCoverInfoResult?.data]);

  const createInputsFormFromAllValues = (
    currentInputsForm: any,
    allValues: Record<string, any>
  ) => {
    const isExternalCode51 = planLineExternalCode === "51";

    if (newAllValues) {
      const newInputsForm = {
        policyCover: {
          ...currentInputsForm.policyCover,
          value: allValues["policyCover"],
        },
        currency: {
          ...currentInputsForm.currency,
          value: allValues["currency"],
        },
        premiumType: {
          ...currentInputsForm.premiumType,
          value: allValues["premiumType"],
        },
        premiumPercentage: {
          ...currentInputsForm.premiumPercentage,
          value: allValues["premiumPercentage"],
        },
        premium: {
          ...currentInputsForm.premium,
          value: allValues["premium"],
        },
        sumInsured: {
          ...currentInputsForm.sumInsured,
          value: allValues["sumInsured"],
        },
        additiveSumInsured: {
          ...currentInputsForm.additiveSumInsured,
          value: allValues["additiveSumInsured"],
        },
        main: {
          ...currentInputsForm.main,
          value: allValues["main"],
        },
        mandatory: {
          ...currentInputsForm.mandatory,
          value: allValues["mandatory"],
        },
        editable: {
          ...currentInputsForm.editable,
          value: allValues["editable"],
        },
        excessOnClaimType: {
          ...currentInputsForm.excessOnClaimType,
          value: allValues["excessOnClaimType"],
        },
        excessOnClaim: {
          ...currentInputsForm.excessOnClaim,
          value: allValues["excessOnClaim"],
        },
        excessOnClaimPercentage: {
          ...currentInputsForm.excessOnClaimPercentage,
          value: allValues["excessOnClaimPercentage"],
        },
        excessOnClaimDays: {
          ...currentInputsForm.excessOnClaimDays,
          value: allValues["excessOnClaimDays"],
        },
        clauseReference: {
          ...currentInputsForm.clauseReference,
          value: allValues["clauseReference"],
        },
      };

      (newInputsForm.premiumType as IFormChipsDynamicProps).onChange = (
        option
      ) => {
        const selectedType = option.toLowerCase();
        const isTypePercentage = selectedType === "percentage";
        const isTypeValue = selectedType === "value";

        if (isExternalCode51) {
          newInputsForm.premiumPercentage.hidden = !isTypePercentage;
          newInputsForm.premiumPercentage.required = isTypePercentage;

          newInputsForm.premium.hidden = !isTypeValue;
          newInputsForm.premium.required = isTypeValue;
        } else if (!isExternalCode51) {
          newInputsForm.premiumPercentage.hidden = true;
          newInputsForm.premiumPercentage.required = false;

          newInputsForm.premium.hidden = true;
          newInputsForm.premium.required = false;
        }
      };

      (newInputsForm.excessOnClaimType as IFormChipsDynamicProps).onChange = (
        option
      ) => {
        const selectedType = option.toLowerCase();
        const isTypePercentage = selectedType === "percentage";
        const isTypeValue = selectedType === "value";
        const isTypeDays = selectedType === "days";

        newInputsForm.excessOnClaim.hidden = !isTypeValue;
        newInputsForm.excessOnClaim.required = isTypeValue;

        newInputsForm.excessOnClaimPercentage.hidden = !isTypePercentage;
        newInputsForm.excessOnClaimPercentage.required = isTypePercentage;

        newInputsForm.excessOnClaimDays.hidden = !isTypeDays;
        newInputsForm.excessOnClaimDays.required = isTypeDays;
      };

      return newInputsForm;
    } else return currentInputsForm;
  };

  return (
    <GenericDrawer
      title={
        assignPolicyCoverId
          ? "Modify Assigned Policy Cover"
          : "Assign Policy Cover"
      }
      onClose={() => onClose()}
      isOpen={open}
    >
      {assignPolicyCoverDetailsListResults.loading && open ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={"Submit"}
            submitButtonState={submitButtonState}
            disableForm={formDisabled}
            title="Information"
            hasDoprdownSpecificBehavior={true}
            onChange={(
              fieldName: string,
              value: string | string[],
              allValues
            ) => {
              if (
                fieldName === "clauseReference" ||
                fieldName === "policyCover"
              ) {
                setNewAllValues(allValues);
              }
            }}
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default AssignPolicyCoverDrawer;
