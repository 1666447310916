import { FormInputTypes } from '../../DynamicForm';
import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
  IEnhancedTableMenuItem,
} from '../../components/enhanced-table';
import { IListingFilterWidgetSection } from '../../components/widgets/custom-listing-filter';
import { IAbstractRecord } from '../../models';
import { enumListAsRecordObject, lookupListAsRecordObject } from '../../utils/graph-utils';

export const headers: Record<string, EnhancedTableHeader> = {
  insurance_Agency_AgencyName: {
    name: 'insurance_Agency_AgencyName',
    title: 'Name',
    type: EnhancedTableHeaderType.Clickable,
    callback: () => {},
  },
  insurance_Agency_AgencyStatus: {
    name: 'insurance_Agency_AgencyStatus',
    title: 'Status',
    type: EnhancedTableHeaderType.Text,
  },
  insurance_Agency_AgencyType: {
    name: 'insurance_Agency_AgencyType',
    title: 'Type',
    type: EnhancedTableHeaderType.Text,
  },
  insurance_Agency_createdOn: {
    name: 'insurance_Agency_createdOn',
    title: 'Created on',
    type: EnhancedTableHeaderType.Date,
  },
  insurance_Agency_modifiedOn: {
    name: 'insurance_Agency_modifiedOn',
    title: 'Modified on',
    type: EnhancedTableHeaderType.Date,
  },
};

export function filterSectionsContent(
  data: any,
  filterValues: IAbstractRecord,
): IListingFilterWidgetSection[] {
  const newFilterSections: IListingFilterWidgetSection[] = [
    {
      title: 'Filters',
      inputs: {
        status: {
          name: 'status',
          title: 'Status',
          type: FormInputTypes.chips,
          value: filterValues?.status || [],
          placeholder: 'Status',
          triggerUpdate: true,
          selectOptions: enumListAsRecordObject(
            data?.Insurance_AgentStatuses?.enumValues,
          ),
        },
        type: {
          name: 'type',
          title: 'Type',
          type: FormInputTypes.chips,
          value: filterValues?.type || [],
          placeholder: 'Type',
          triggerUpdate: true,
          selectOptions: lookupListAsRecordObject(data?.Insurance?.lookups?.agencyTypes),
        },
      },
    },
  ];
  return newFilterSections;
}

export const actions: IEnhancedTableMenuItem[] = [
  {
    title: '+ New',
    onClick: () => {},
    isEntity: false,
    isBulk: false,
    iconUrl: '',
  },
];
