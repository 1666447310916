import { gql } from "@apollo/client";

export function getSublineEnums() {
  return gql`
    query getSublineEnums {
      PlanConfigManagement_SublineStatuses: __type(name: "PlanConfigManagement_SublineStatuses") {
        name
        enumValues {
          name
        }
      }
      PlanConfigManagement {
        queries {          
          allLines {
            planConfigManagement_Line_Id
            planConfigManagement_Line_Name
            planConfigManagement_Line_Status
          }
        }
      }
    }    
  `;
}

export function checkSublineExists() {
  return gql`
    query checkSublineExists(
      $lineId: String!
      $name: String
      $externalCode: String
      $sublineId: String
    ) {
      PlanConfigManagement {
        queries {
          checkSublineExists(
            lineId: $lineId
            sublineId: $sublineId
            name: $name
            externalCode: $externalCode
          ) {
            planConfigManagement_Subline_Id
            planConfigManagement_Subline_Name
            planConfigManagement_Subline_ExternalCode
          }
        }
      }
    }
  `;
}

export function createSubline() {
  return gql`
    mutation createSubline($subLineInputs: PlanConfigManagement_SublineInputs!) {
      planConfigManagement {
        actions {
          createSubline(subLineInputs: $subLineInputs) {
            id
            views {
              PlanConfigManagement_all {
                properties {
                  ExternalCode
                  Name
                  NameArabic
                  Status
                  createdOn
                  modifiedOn
                  LineID {
                    id
                    views {
                      PlanConfigManagement_all {
                        properties {
                          ExternalCode
                          Name
                          NameArabic
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function getSublineInfo() {
  return gql`
    query getSublineInfo($id: String!) {
      PlanConfigManagement {
        entities {
          subline(id: $id) {
            views {
              PlanConfigManagement_all {
                properties {
                  Name
                  ExternalCode
                  NameArabic
                  Status
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function updateSubline() {
  return gql`
    mutation updateSubline($entityId: String!, $subLineInputs: PlanConfigManagement_SublineInputs!) {
      planConfigManagement {
        entities {
          subline {
            planConfigManagement {
              updateSubline (entityId: $entityId, subLineInputs: $subLineInputs) {
                id
              }
            }
          }
        }
      }
    }
  `;
}
