import { LoadUserSuccess, LoginSuccess, LogoutUser } from './reducer';
import { IUserDetails, RefreshTokenObject, UserActionTypes } from './types';

export function LoginSuccessAction(
  jwtToken: string,
  id_token: string,
  refreshTokenObject?: RefreshTokenObject
) {
  return LoginSuccess({
    type: UserActionTypes.LOGIN_SUCCESS,
    jwtToken,
    id_token,
    refreshTokenObject,
  });
}

export function LoadUserSuccessAction(user: IUserDetails) {
  return LoadUserSuccess({ type: UserActionTypes.LOAD_USER_SUCCESS, user });
}

export function SignoutUserAction() {
  return LogoutUser();
}
