import { makeStyles } from "tss-react/mui";
import React from "react";
import clsx from "clsx";
import { IEnhancedCommonProps } from "..";
import { MAIN_ONE_THEME } from "../../constants";

export interface IEnhancedNoteProps extends IEnhancedCommonProps {
  name: string;
  title: string;
  text: string;
}

const useStyles = makeStyles()(() => ({
  note: {
    color: MAIN_ONE_THEME.palette.primary1.main,
    fontSize: "13px",
    margin: "0 0 20px 0",
  },
}));

const EnhancedNote: React.FC<IEnhancedNoteProps> = ({
  title,
  text,
  className,
}) => {
  const { classes } = useStyles();
  return (
    <p className={clsx(classes.note, className)}>
      <b>{`${title}`}</b>
      {text}
    </p>
  );
};

export default EnhancedNote;
