import React, { useEffect, useState } from "react";
import GenericDrawer from "../../components/common/generic-drawer/GenericDrawer";
import DynamicForm from "../../DynamicForm/DynamicForm";
import {
  DynamicFormInputType,
  IFormNumberDynamicProps,
  IFormSelectDynamicProps,
  IFormTextDynamicProps,
} from "../../DynamicForm";
import { toast } from "react-toastify";
import { EnhancedButtonStatus } from "../../components/common/EnhancedButton";
import { normaliseDynamicValues } from "../../utils/dynamic-utils";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { inputs } from "./content";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  checkClaimCoverExists,
  createClaimCover,
  getClaimCoverEnums,
  getClaimCoverInfo,
  updateClaimCover,
  checkClaimCoverPrintingOrderExists,
} from "./queries";
import { LookupToList, graphqlEntityToClaimCoverInfo } from "./utils";
import Loader from "../../components/Loader";
import { cloneDeep } from "lodash";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import { isEmpty } from "../../utils/validationUtils";
import { DEFAULT_ERROR_TEXT } from "../../constants";
import { getError } from "../../utils/graph-utils";

const ClaimCoverDrawer: React.FC<IClaimCoverDrawerProps> = ({
  claimCoverId,
  lineId,
  lineName,
  isPolicyCoverNameDisabled = false,
  policyCoverId,
  open,
  onSuccess,
  onClose,
}) => {
  let claimCoverInfoResult: any;

  const [hasClaimCoverNameError, setHasClaimCoverNameError] = useState("");
  const [hasClaimCoverExternalCodeError, setHasClaimCoverExternalCodeError] = useState("");
  const [hasClaimCoverPrintingOrderError, setHasClaimCoverPrintingOrderError] = useState("");
  let claimCoverNameError = "";
  let claimCoverExternalCodeError = "";
  let claimCoverPrintingOrderError = "";
  let [selectedPolicyCoverId, setSelectedPolicyCoverId] = useState("");

  let [submitButtonDisabled, setSubmitButtonDisabled] =
    useState<boolean>(false);

  const claimCoverEnumResults = useQuery(getClaimCoverEnums(), {
    fetchPolicy: "no-cache",
    variables: { SelectedLineId: lineId },
  });

  const [claimCoverAction] = useMutation(
    claimCoverId ? updateClaimCover() : createClaimCover()
  );

  let updatedInputs:Record<string, DynamicFormInputType> = inputs;

  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();
  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);

  if (claimCoverId) {
    claimCoverInfoResult = useQuery(getClaimCoverInfo(), {
      variables: { id: claimCoverId },
    });
  }

  function handlePolicyCoverSelection(selectedOption: any) {
    setSelectedPolicyCoverId(selectedOption);
    policyCoverId = selectedOption;

    validateClaimCoverExistsOnNameChange(selectedOption, updatedInputs.claimCoverName.value);
    validateClaimCoverExistsOnExternalCodeChange(selectedOption, updatedInputs.claimCoverExternalCode.value);
    validateClaimCoverPrintingOrderExistsOnPrintingOrderChange(selectedOption, updatedInputs.claimCoverPrintingOrder.value);
  }

  const [checkClaimCoverExistsLazy] = useLazyQuery(checkClaimCoverExists(), {});
  const [checkClaimCoverPrintingOrderExistsLazy] = useLazyQuery(
    checkClaimCoverPrintingOrderExists(),
    {}
  );

  const initialize = () => {
    try {
      updatedInputs = cloneDeep(inputsForm);

      updatedInputs.lineName.value = lineId;
      if (claimCoverEnumResults?.data) {
        const newClaimCoverEnums = LookupToList(claimCoverEnumResults.data);

        if (!lineId) {
          updatedInputs.lineName.value = lineId;
        }
        if (isPolicyCoverNameDisabled) {
          updatedInputs.policyCover.disabled = isPolicyCoverNameDisabled;
          updatedInputs.policyCover.value = policyCoverId;
        }
        (updatedInputs.lineName as IFormSelectDynamicProps).selectOptions =
        newClaimCoverEnums['lines'];

        (updatedInputs.policyCover as IFormSelectDynamicProps).selectOptions =
          newClaimCoverEnums["policiesCovers"];
        (updatedInputs.policyCover as IFormSelectDynamicProps).onSelect = (
          option
        ) => handlePolicyCoverSelection(option);
      }

      (updatedInputs.claimCoverName as IFormTextDynamicProps).onChange = (
        event
      ) => {
        const newName = event.target.value;
        setInputsForm(updatedInputs);
        (updatedInputs.claimCoverName as IFormTextDynamicProps).value = newName;
        if (!isEmpty(newName)) {
          validateClaimCoverExistsOnNameChange(selectedPolicyCoverId === '' ? policyCoverId : selectedPolicyCoverId, newName);
        }
      };

      (updatedInputs.claimCoverExternalCode as IFormTextDynamicProps).onChange =
        (event) => {
          const newExternalCode = event.target.value;
          setInputsForm(updatedInputs);
          (
            updatedInputs.claimCoverExternalCode as IFormTextDynamicProps
          ).value = newExternalCode;
          if (!isEmpty(newExternalCode)) {
            validateClaimCoverExistsOnExternalCodeChange(
              selectedPolicyCoverId === '' ? policyCoverId : selectedPolicyCoverId,
              newExternalCode
            );
          }
        };

      (
        updatedInputs.claimCoverPrintingOrder as IFormNumberDynamicProps
      ).onChange = (event) => {
        const newPrintingOrder: number = Number(event.target.value);
        setInputsForm(updatedInputs);
        (
          updatedInputs.claimCoverPrintingOrder as IFormNumberDynamicProps
        ).value = newPrintingOrder;
        if (!isEmpty(newPrintingOrder)) {
          validateClaimCoverPrintingOrderExistsOnPrintingOrderChange(
            selectedPolicyCoverId === '' ? policyCoverId : selectedPolicyCoverId,
            newPrintingOrder
          );
        }
      };

      if (claimCoverInfoResult?.data) {
        const claimCoverEntity = graphqlEntityToClaimCoverInfo(
          claimCoverInfoResult.data,
          lineId,
          lineName
        );

        if (claimCoverEntity) {
          updatedInputs.lineName.value = claimCoverEntity.lineName;
          updatedInputs.policyCover.value = claimCoverEntity.policyCover;
          updatedInputs.claimCoverName.value = claimCoverEntity.claimCoverName;
          updatedInputs.claimCoverExternalCode.value =
            claimCoverEntity.claimCoverExternalCode;
          updatedInputs.arabicName.value = claimCoverEntity.arabicName;
          updatedInputs.claimCoverPrintingOrder.value =
            claimCoverEntity.claimCoverPrintingOrder;
          updatedInputs.claimCoverDescription.value =
            claimCoverEntity.claimCoverDescription;

          selectedPolicyCoverId = policyCoverId = claimCoverEntity.policyCover;
        }
      }

      setInputsForm(updatedInputs);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    if (claimCoverNameError === "" && claimCoverExternalCodeError === "") {
      const [data] = normaliseDynamicValues(inputs, values);
      setFormDisabled(true);
      setSubmitButtonState("loading");
      try {
        let variables = {
          claimCoverInputs: {
            name: data.claimCoverName,
            externalCode: data.claimCoverExternalCode,
            nameArabic: data.arabicName,
            printingOrder: parseInt(
              data.claimCoverPrintingOrder as unknown as string
            ),
            claimCoverDescription: data.claimCoverDescription,
            policyCoverID: data.policyCover,
            lineID: lineId,
          },
        };

        claimCoverAction({
          variables: claimCoverId
            ? { ...variables, entityId: claimCoverId }
            : variables,
          errorPolicy: "all",
        }).then((res) => {
          if (isEmpty(res.errors)) {
            toast.success(
              <ToastSuccessMessage>
                {claimCoverId
                  ? "Claim Cover successfully updated"
                  : "Claim Cover successfully created"}
              </ToastSuccessMessage>
            );

            setTimeout(() => {
              setSubmitButtonState("success");
              onSuccess();
              onClose();
            }, 500);
          } else {
            setSubmitButtonState(undefined);
            toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
          }
        });
      } catch {
        setSubmitButtonState(undefined);
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
      } finally {
        setFormDisabled(false);
      }
    } else {
      setFormDisabled(true);
      setSubmitButtonState(undefined);
    }
  };

  const validateClaimCoverExistsOnNameChange = (
    selectedPolicyCoverId: string,
    claimCoverName?: string
  ) => {
    isEmpty(selectedPolicyCoverId)
      ? setSelectedPolicyCoverId(
          !isEmpty(policyCoverId) ? policyCoverId : selectedPolicyCoverId
        )
      : selectedPolicyCoverId;
    if (
      !isEmpty(lineId) &&
      !isEmpty(claimCoverName) &&
      !isEmpty(selectedPolicyCoverId)
    ) {
      checkClaimCoverExistsLazy({
        variables: {
          policyCoverId: !isEmpty(policyCoverId) ? policyCoverId : selectedPolicyCoverId,
          name: claimCoverName,
          claimCoverId: claimCoverId,
          externalCode: '',
        },
      }).then((response) => {
        setInputsForm((currentInputsForm) => {
          const updatedInputs = { ...currentInputsForm };

          if (
            response.data.PlanConfigManagement?.queries?.checkClaimCoverExists.length > 0
          ) {
            claimCoverNameError = "Claim Cover Name already exists under the same Line";
            setHasClaimCoverNameError("Claim Cover Name already exists under the same Line");
              
            (updatedInputs.claimCoverName as IFormTextDynamicProps).error =
              claimCoverNameError;
          } else {
            claimCoverNameError = "";
            setHasClaimCoverNameError("");
            (updatedInputs.claimCoverName as IFormTextDynamicProps).error =
              claimCoverNameError;
          }

          // const newClaimCoverEnums = LookupToList(claimCoverEnumResults.data);

          // (updatedInputs.policyCover as IFormSelectDynamicProps).selectOptions =
          //   newClaimCoverEnums["policiesCovers"];
          // selectedPolicyCoverId = Object.keys(
          //   newClaimCoverEnums["policiesCovers"]
          // )[0];
          // updatedInputs.policyCover.value = currentInputsForm.policyCover.value;

          return updatedInputs;
        });
      });
    }
  };
  const validateClaimCoverExistsOnExternalCodeChange = (
    selectedPolicyCoverId: string,
    claimCoverExternalCode?: string
  ) => {
    setSelectedPolicyCoverId(!isEmpty(policyCoverId) ? policyCoverId : selectedPolicyCoverId);
    if (
      !isEmpty(lineId) &&
      !isEmpty(claimCoverExternalCode) &&
      !isEmpty(selectedPolicyCoverId)
    ) {
      checkClaimCoverExistsLazy({
        variables: {
          lineId: lineId,
          externalCode: claimCoverExternalCode,
          claimCoverId: claimCoverId,
          policyCoverId: selectedPolicyCoverId,
          name: '',
        },
      }).then((response) => {
        setInputsForm((currentInputsForm) => {
          const updatedInputs = { ...currentInputsForm };

          if (
            response.data.PlanConfigManagement?.queries?.checkClaimCoverExists.length > 0
          ) {
            claimCoverExternalCodeError = "External Code already exists under the same Line";
            setHasClaimCoverExternalCodeError("External Code already exists under the same Line");
            (
              updatedInputs.claimCoverExternalCode as IFormTextDynamicProps
            ).error = claimCoverExternalCodeError;
          } else {
            claimCoverExternalCodeError = "";
            setHasClaimCoverExternalCodeError("");
            (
              updatedInputs.claimCoverExternalCode as IFormTextDynamicProps
            ).error = claimCoverExternalCodeError;
          }

          const newClaimCoverEnums = LookupToList(claimCoverEnumResults.data);

          (updatedInputs.policyCover as IFormSelectDynamicProps).selectOptions =
            newClaimCoverEnums["policiesCovers"];
          selectedPolicyCoverId = Object.keys(
            newClaimCoverEnums["policiesCovers"]
          )[0];
          updatedInputs.policyCover.value = currentInputsForm.policyCover.value;

          return updatedInputs;
        });
      });
    }
  };
  const validateClaimCoverPrintingOrderExistsOnPrintingOrderChange = (
    selectedPolicyCoverId: string,
    claimCoverPrintingOrder: number
  ) => {
    isEmpty(selectedPolicyCoverId)
      ? setSelectedPolicyCoverId(
          !isEmpty(policyCoverId) ? policyCoverId : selectedPolicyCoverId
        )
      : selectedPolicyCoverId;
    if (!isEmpty(lineId) && !isEmpty(claimCoverPrintingOrder)) {
      checkClaimCoverPrintingOrderExistsLazy({
        variables: {
          printingOrder: claimCoverPrintingOrder,
          policyCoverId: selectedPolicyCoverId,
          claimCoverId: claimCoverId,
        },
      }).then((response) => {
        setInputsForm((currentInputsForm) => {
          const updatedInputs = { ...currentInputsForm };

          if (
            response.data.PlanConfigManagement?.queries?.checkClaimCoverPrintingOrderExists
              .length > 0
          ) {
            claimCoverPrintingOrderError = "Printing Order already assigned for another Claim Cover under the same Policy Cover";
            setHasClaimCoverPrintingOrderError("Printing Order already assigned for another Claim Cover under the same Policy Cover");
            (
              updatedInputs.claimCoverPrintingOrder as IFormTextDynamicProps
            ).error = claimCoverPrintingOrderError;
          } else {
            claimCoverPrintingOrderError = "";
            setHasClaimCoverPrintingOrderError("");
            (
              updatedInputs.claimCoverPrintingOrder as IFormTextDynamicProps
            ).error = claimCoverPrintingOrderError;
          }

          const newClaimCoverEnums = LookupToList(claimCoverEnumResults.data);

          (updatedInputs.policyCover as IFormSelectDynamicProps).selectOptions =
            newClaimCoverEnums["policiesCovers"];
          selectedPolicyCoverId = Object.keys(
            newClaimCoverEnums["policiesCovers"]
          )[0];
          updatedInputs.policyCover.value = currentInputsForm.policyCover.value;

          return updatedInputs;
        });
      });
    }
  };

  useEffect(() => {
    initialize();
  }, [lineName, claimCoverEnumResults.data, claimCoverInfoResult?.data, selectedPolicyCoverId]);

  useEffect(() => {
    const isDisabled =
      !isEmpty(hasClaimCoverNameError) ||
      !isEmpty(hasClaimCoverExternalCodeError) ||
      !isEmpty(hasClaimCoverPrintingOrderError);

    setSubmitButtonDisabled(isDisabled);
  }, [
    hasClaimCoverNameError,
    hasClaimCoverExternalCodeError,
    hasClaimCoverPrintingOrderError,
  ]);

  return (
    <GenericDrawer
      title={claimCoverId ? "Edit Claim Cover" : "New Claim Cover"}
      onClose={() => onClose()}
      isOpen={open}
    >
      {(claimCoverEnumResults.loading || claimCoverInfoResult?.loading) &&
      open ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={"Submit"}
            submitButtonState={submitButtonState}
            isSubmitButtonDisabled={submitButtonDisabled}
            disableForm={formDisabled}
            title="Information"
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default ClaimCoverDrawer;
