import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { initialValues, IPersonDetailsPageProps, IPersonDetails } from ".";
import Loader from "../../components/Loader";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { IEntityInfoView } from "../../components/widgets/entity-info/EntityInfoFields";
import EntityInfoWidget from "../../components/widgets/entity-info/EntityInfoWidget";
import {
  DEFAULT_ERROR_TEXT,
  MAIN_ONE_THEME,
  contentFontFamilyBold,
} from "../../constants";
import { entityView, headers, steps } from "./content";
import {
  activatePerson,
  deactivatePerson,
  getPersonAddressList,
  getPersonDetailsInfo,
} from "./queries";
import {
  entityToPersonDetails,
  getPersonStatus,
  mapToPersonAddressesListingData,
} from "./utils";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import dayjs from "dayjs";
import EnhancedStepperSpecificDesign from "../../components/common/EnhancedStepperSpecificDesign";
import { makeStyles } from "tss-react/mui";
import StaticLayout from "../../page-layout/static-layout/StaticLayout";
import PersonDrawer from "../person-drawer/PersonDrawer";
import EnhancedButton, {
  EnhancedButtonStatus,
} from "../../components/EnhancedButton";
import { IConfirmation } from "../../redux/confirmation/types";
import { isEmpty } from "lodash";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import { getError } from "../../utils/graph-utils";
import { OpenConfirmationAction } from "../../redux/confirmation/actions";
import { IDynamicDiplayView } from "../../components/widgets/dynamic-display";
import DynamicWidget from "../../components/widgets/dynamic-display/DynamicWidget";
import EnhancedTable from "../../components/enhanced-table/EnhancedTable";
import WidgetSection from "../../components/common/WidgetSection";
import { IListingData } from "../../models/listing";
import {
  capitalizeFirstLetter,
  valueCommaSeparated,
} from "../../utils/formatting-utils";
import { IEnhancedTableMenuItem } from "../../components/enhanced-table";
import PersonAddressDrawer from "../person-address-drawer/PersonAddressDrawer";
import ShowForUser from "../../components/user/ShowForUser";
import AdditionalInformationDrawer from "../person-additional-iformation-drawer/AdditionalInformationDrawer";
import PersonDetailsDrawer from "../personal-information-drawer/PersonDetailsDrawer";
import { IPersonDetailsDrawerInfo } from "../personal-information-drawer";

const useStyles = makeStyles()(() => ({
  stepper: {
    backgroundColor: "transparent",
    maxWidth: "90%",
    marginBottom: "20px",
    "& .MuiStepLabel-root .Mui-active, .MuiStepLabel-root ": {
      color: MAIN_ONE_THEME.palette.primary5.main,
      "& .MuiStepIcon-text": {
        fontSize: 0,
        lineHeight: "0px",
        fill: "black",
      },
    },

    ".Mui-completed": {
      color: MAIN_ONE_THEME.palette.primary5.main,
      "& .MuiStepIcon-text": {
        fontSize: 0,
        lineHeight: "0px",
        fill: "black",
      },
    },

    "@media only screen and (min-width: 600px)": {
      maxWidth: "1300px",
    },
  },
  section: {
    display: "grid",
    "grid-template-columns": "19.5% 19.5% 19.5% 19.5% 19.5%",
    justifyContent: "space-between",
    flexWrap: "wrap",

    "& label": {
      "& span": {
        margin: "-2px 0",
      },
    },
  },
  sectionTitle: {
    padding: "-10x 10x!important",
  },
  sectionFullRow: {
    display: "grid",
    "grid-template-columns": "100%",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  row: {
    flexWrap: "wrap",
    margin: "15px 20px 10px 0px",
    alignItems: "flex-start",
    display: "grid",
    gap: "5px",
    "& label": {
      flexGrow: "1",
      flexBasis: "0",
      minWidth: "0",
    },
    "grid-template-columns": "19.5% 19.5% 19.5% 19% 16% 3% 3%",
  },
  thickSeperator: {
    height: 10,
    margin: "10px 0 10px 0",
    "grid-column-start": "1",
    "grid-column-end": "4",
  },
  field: {
    width: "96%",
    marginRight: "10px !important",
  },
  repeaterField: {
    margin: "0px!important",
  },
  arField: {
    width: "96%",
    marginRight: "10px !important",
    direction: "rtl",
  },
  header: {
    fontFamily: contentFontFamilyBold,
    fontSize: MAIN_ONE_THEME.typography.regular.reg3.fontSize,
    color: MAIN_ONE_THEME.palette.primary2.main,
    "&:hover": {
      color: MAIN_ONE_THEME.palette.primary1.main,
    },
  },
  content: {
    padding: "0",
  },
  dirtyChip: {
    margin: "0 0 -15px 0",
    backgroundColor: MAIN_ONE_THEME.palette.primary2.main,
    "& span": {
      color: "#ffffff",
    },
  },
  tabPanelStyle: {
    padding: "unset",
    marginTop: "20px",
  },
  actionButton: {
    display: "inline-block",
    marginRight: "5px",
  },
}));

const PersonDetailsPage: React.FC<IPersonDetailsPageProps> = () => {
  //#region react and redux hooks
  const { classes } = useStyles();
  const params = useParams();
  const tenant = useAppSelector((state) => state.tenant);
  const client = useApolloClient();
  const user = useAppSelector((state) => state.user);

  const [activateButtonState, setActivateButtonState] =
    useState<EnhancedButtonStatus>();
  const [deactivateButtonState, setDeactivateButtonState] =
    useState<EnhancedButtonStatus>();
  const [personInfoDrawerOpen, setPersonInfoDrawerOpen] =
    useState<boolean>(false);
  const [personAddressDrawerOpen, setPersonAddressDrawerOpen] =
    useState<boolean>(false);
  const [additionalInfoDrawerOpen, setAdditionalInfoDrawerOpen] =
    useState<boolean>(false);
  const [isPerson, setIsPerson] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const [selectedPersonAddressItems, setSelectedPersonAddressItems] =
    useState<number>(0);

  //#endregion

  //#region component hooks and states
  const [personDetailsInfo, setPersonDetailsInfo] =
    useState<IPersonDetails>(initialValues);

  const [updatedLeftSideInfo, setUpdatedLeftSideInfo] =
    useState<IEntityInfoView>(entityView);

  const [loadingState, setLoadingState] = useState<boolean>(true);
  const [personDrawerOpen, setPersonDrawerOpen] = useState<boolean>(false);

  const [selectedPersonAddressEntityId, setSelectedPersonAddressEntityId] =
    useState("");

  const [actionClicked, setActionClicked] = useState(null);

  const [personalInformationViewConfig, setPersonalInformationViewConfig] =
    useState<IDynamicDiplayView>();
  const [additionalInformationViewConfig, setAdditionalInformationViewConfig] =
    useState<IDynamicDiplayView>();

  const entityId = params.id;

  const [personType, setPersonType] = useState();

  const [tableLoadingState, setTableLoadingState] = useState<boolean>(false);

  //#endregion

  //#region graphql hooks
  const [tableData, setTableData] = useState<IListingData<any>>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 2,
    totalCount: 0,
  });

  const [getPersonDetailsLazy, entityResult] = useLazyQuery(
    getPersonDetailsInfo(),
    {
      variables: { id: entityId },
    }
  );

  const [activateAction] = useMutation(activatePerson(), {
    variables: {
      entityId: entityId,
    },
    errorPolicy: "all",
    refetchQueries: [getPersonDetailsInfo()],
  });
  const [deactivateAction] = useMutation(deactivatePerson(), {
    variables: {
      entityId: entityId,
    },
    refetchQueries: [getPersonDetailsInfo()],
  });

  const personAddressListResult = useQuery(getPersonAddressList(), {
    variables: {
      selectedPersonID: params.id,
      currentPage: 1,
      currentPageSize: tableData.pageSize,
    },
    errorPolicy: "all",
  });

  //#endregion

  const isAdmin = user.userRoles.includes("Insurance-Admin");

  let personDrawerListingActions: IEnhancedTableMenuItem[] = [];
  personDrawerListingActions = [
    {
      title: "New",
      onClick: () => {},
      isEntity: false,
      isBulk: false,
      iconUrl: tenant.cdnUrl + "/icons/add-primary-red.svg",
      hidden: !isAdmin,
      disabled: false,
    },
    {
      title: "Edit",
      onClick: () => {},
      isEntity: false,
      isBulk: false,
      iconUrl: tenant.cdnUrl + "/icons/edit-secondary.svg",
      hidden: !isAdmin,
      disabled: selectedPersonAddressItems !== 1,
    },
  ];

  personDrawerListingActions[0].onClick = () => {
    setActionClicked("new");
    setPersonAddressDrawerOpen(true);
  };

  personDrawerListingActions[1].onClick = () => {
    setActionClicked("edit");
    setPersonAddressDrawerOpen(true);
  };

  //#region useEffect
  useEffect(() => {
    try {
      if (!entityResult.loading) {
        setLoadingState(false);
      }

      if (entityResult?.data) {
        const personDetails = entityToPersonDetails(entityResult?.data);

        setPersonType(personDetails.type);

        if (personDetails.type === "PERSON") {
          setIsPerson(true);
        }

        //get application section properties
        const applicationProperties = entityView.sections[0];
        applicationProperties.properties.code.value = personDetails.code;
        applicationProperties.properties.name.value = personDetails.name;
        applicationProperties.properties.type.value =
          personDetails.type.toLowerCase() === "person"
            ? "Individual"
            : capitalizeFirstLetter(personDetails.type);
        applicationProperties.properties.email.value = personDetails.email;
        applicationProperties.properties.phoneNumber.value =
          personDetails.phoneNumber;

        //get the system propeties section
        const systemProperties = entityView.sections[1];
        systemProperties.properties.createdBy.value = personDetails.createdBy;
        systemProperties.properties.createdOn.value = dayjs(
          new Date(personDetails.createdOn)
        ).format("DD/MM/YYYY");
        systemProperties.properties.modifiedBy.value = personDetails.modifiedBy;
        systemProperties.properties.modifiedOn.value = dayjs(
          new Date(personDetails.modifiedOn)
        ).format("DD/MM/YYYY");

        const newLeftEntityView = {
          ...entityView,
          sections: [applicationProperties, systemProperties],
        };

        setPersonDetailsInfo(personDetails);
        setUpdatedLeftSideInfo(newLeftEntityView);

        const isPerson = personDetails.type === "PERSON";

        const dynamicView: IDynamicDiplayView = {
          name: "Personal Information",
          sections: [
            {
              name: "generalDetails",
              title: "General Details",
              showActionButtons: true,
              showSection: true,
              properties: {
                type: {
                  multiline: false,
                  value:
                    personDetails.type.toLowerCase() === "person"
                      ? "Individual"
                      : capitalizeFirstLetter(personDetails.type),
                  name: "type",
                  title: "Type",
                },
                companyName: {
                  multiline: false,
                  value: personDetails.companyName,
                  name: "companyName",
                  title: "Company Name",
                  hidden: isPerson,
                },
                title: {
                  multiline: false,
                  value: capitalizeFirstLetter(personDetails.title),
                  name: "title",
                  title: "Title",
                  hidden: !isPerson,
                },
                firstName: {
                  multiline: false,
                  value: personDetails.firstName,
                  name: "firstName",
                  title: "First Name",
                  hidden: !isPerson,
                },
                middleName: {
                  multiline: false,
                  value: personDetails.middleName,
                  name: "middleName",
                  title: "Middle Name",
                  hidden: !isPerson,
                },
                lastName: {
                  multiline: false,
                  value: personDetails.lastName,
                  name: "lastName",
                  title: "Last Name",
                  hidden: !isPerson,
                },
                surname: {
                  multiline: false,
                  value: personDetails.surname,
                  name: "Maiden Name",
                  title: "Maiden Name",
                  hidden: !isPerson,
                },
                initials: {
                  multiline: false,
                  value: personDetails.initials,
                  name: "initials",
                  title: "Initials",
                },
                dateOfBirth: {
                  multiline: false,
                  value: personDetails.dateOfBirth
                    ? dayjs(new Date(personDetails.dateOfBirth)).format(
                        "DD/MM/YYYY"
                      )
                    : "- -",
                  name: "dateOfBirth",
                  title: "Date of Birth",
                  hidden: !isPerson,
                },
                nationality: {
                  multiline: false,
                  value: capitalizeFirstLetter(personDetails.nationality),
                  name: "nationality",
                  title: "Nationality",
                  hidden: !isPerson,
                },
              },
            },
            {
              name: "contactDetails",
              title: "Contact Details",
              showActionButtons: false,
              showSection: true,
              properties: {
                email: {
                  multiline: false,
                  value: personDetails.email,
                  name: "email",
                  title: "Email",
                },
                phoneType: {
                  multiline: false,
                  value: capitalizeFirstLetter(personDetails.phoneType),
                  name: "phoneType",
                  title: "Phone Type",
                },
                phoneNumber: {
                  multiline: false,
                  value: personDetails.phoneNumber,
                  name: "phoneNumber",
                  title: "Phone Number",
                },
                relativeOf: {
                  multiline: false,
                  value: personDetails.relativeOf,
                  name: "relativeOf",
                  title: "Relative of",
                },
              },
            },
            {
              name: "healthDetails",
              title: "Health Details",
              showActionButtons: false,
              hidden: !isPerson,
              properties: {
                smoker: {
                  multiline: false,
                  value: capitalizeFirstLetter(personDetails.smoker),
                  name: "smoker",
                  title: "Smoker",
                  hidden: !isPerson,
                },
                weight: {
                  multiline: false,
                  value: personDetails.weight,
                  name: "weight",
                  title: "Weight (kg)",
                  hidden: !isPerson,
                },
                height: {
                  multiline: false,
                  value: personDetails.height,
                  name: "Height",
                  title: "Height (cm)",
                  hidden: !isPerson,
                },
                dateOfDeath: {
                  multiline: false,
                  value: personDetails.dateOfDeath
                    ? dayjs(new Date(personDetails.dateOfDeath)).format(
                        "DD/MM/YYYY"
                      )
                    : "- -",
                  name: "dateOfDeath",
                  title: "Date of Death",
                  hidden: !isPerson,
                },
                dateOfDeathNotified: {
                  multiline: false,
                  value: personDetails.dateOfDeathNotified
                    ? dayjs(new Date(personDetails.dateOfDeathNotified)).format(
                        "DD/MM/YYYY"
                      )
                    : "- -",
                  name: "dateOfDeathNotified",
                  title: "Date of Death Notified",
                  hidden: !isPerson,
                },
              },
            },
            {
              name: "businessDetails",
              title: "Business Details",
              showActionButtons: false,
              showSection: true,
              properties: {
                taxable: {
                  multiline: false,
                  value: personDetails.taxable ? "Yes" : "No",
                  name: "taxable",
                  title: "Taxable",
                },
                financialReference: {
                  multiline: false,
                  value: personDetails.financialReference,
                  name: "financialReference",
                  title: "Financial Reference",
                },
                occupationClass: {
                  multiline: false,
                  value: capitalizeFirstLetter(personDetails.occupationClass),
                  name: "occupationClass",
                  title: "Occupation Class",
                  hidden: !isPerson,
                },
                profession: {
                  multiline: false,
                  value: personDetails.profession,
                  name: "profession",
                  title: "Profession",
                  hidden: !isPerson,
                },
                employer: {
                  multiline: false,
                  value: personDetails.employer,
                  name: "employer",
                  title: "Employer / Institute",
                  hidden: !isPerson,
                },
                dateOfEmployment: {
                  multiline: false,
                  value: personDetails.dateOfEmployment
                    ? dayjs(new Date(personDetails.dateOfEmployment)).format(
                        "DD/MM/YYYY"
                      )
                    : "- -",
                  name: "dateOfEmployment",
                  title: "Date of Employment",
                  hidden: !isPerson,
                },
                salary: {
                  multiline: false,
                  value: `$ ${valueCommaSeparated(personDetails.salary)}`,
                  name: "salary",
                  title: "Salary",
                  hidden: !isPerson,
                },
                dateOfSelfCertificateReceived: {
                  multiline: false,
                  value: personDetails.dateOfSelfCertificateReceived
                    ? dayjs(
                        new Date(personDetails.dateOfSelfCertificateReceived)
                      ).format("DD/MM/YYYY")
                    : "- -",
                  name: "dateOfSelfCertificateReceived",
                  title: "Date of Self Certificate Received",
                  hidden: !isPerson,
                },
                dateOfSelfCertificateValidated: {
                  multiline: false,
                  value: personDetails.dateOfSelfCertificateValidated
                    ? dayjs(
                        new Date(personDetails.dateOfSelfCertificateValidated)
                      ).format("DD/MM/YYYY")
                    : "- -",
                  name: "dateOfSelfCertificateValidated",
                  title: "Date of Self Certificate Validated",
                  hidden: !isPerson,
                },
                category: {
                  multiline: false,
                  value: capitalizeFirstLetter(personDetails.category),
                  name: "category",
                  title: "Category",
                  hidden: !isPerson,
                },
                dateOfKYC: {
                  multiline: false,
                  value: personDetails.dateOfKYC
                    ? dayjs(new Date(personDetails.dateOfKYC)).format(
                        "DD/MM/YYYY"
                      )
                    : "- -",
                  name: "dateOfKYC",
                  title: "Date of KYC",
                  hidden: !isPerson,
                },
                comments: {
                  multiline: true,
                  value: personDetails.comments,
                  name: "comments",
                  title: "Comments",
                  hidden: false,
                },
              },
            },
          ],
        };
        setPersonalInformationViewConfig(dynamicView);

        const additionalInformationDynamicView: IDynamicDiplayView = {
          name: "Additional Information",
          sections: [
            {
              name: "additionalInformation",
              title: "Additional Information",
              // middle: policyDetails?.pendingDocumentRegeneration ? (
              //   <Chip
              //     label={
              //       'Policy successfully updated. Regenerate the documents to reflect the changes'
              //     }
              //     className={classes.dirtyChip}
              //   />
              // ) : (
              //   <></>
              // ),
              showSection: true,
              properties: {
                gender: {
                  multiline: false,
                  value: capitalizeFirstLetter(personDetails.gender),
                  name: "gender",
                  title: "Gender",
                },
                maritalStatus: {
                  multiline: false,
                  value: capitalizeFirstLetter(personDetails.maritalStatus),
                  name: "maritalStatus",
                  title: "Marital Status",
                },
                fatherName: {
                  multiline: false,
                  value: personDetails.fatherName,
                  name: "fatherName",
                  title: "Father Name",
                },
                motherName: {
                  multiline: false,
                  value: personDetails.motherName,
                  name: "motherName",
                  title: "Mother Name",
                },
                countryOfBirth: {
                  multiline: false,
                  value: capitalizeFirstLetter(personDetails.countryOfBirth),
                  name: "countryOfBirth",
                  title: "Country of Birth",
                },
                cityOfBirth: {
                  multiline: false,
                  value: personDetails.cityOfBirth,
                  name: "cityOfBirth",
                  title: "City of Birth",
                },
                nationalityID: {
                  multiline: false,
                  value: personDetails.nationalityID,
                  name: "nationalityID",
                  title: "Nationality ID",
                },
                passportNumber: {
                  multiline: false,
                  value: personDetails.passportNumber,
                  name: "passportNumber",
                  title: "Passport Number",
                },
                nationalityTwo: {
                  multiline: false,
                  value: capitalizeFirstLetter(personDetails.nationalityTwo),
                  name: "nationalityTwo",
                  title: "Second Nationality",
                },
              },
            },
          ],
        };
        setAdditionalInformationViewConfig(additionalInformationDynamicView);
      }
    } catch (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  }, [entityResult?.data]);

  useEffect(() => {
    // Initial loading state set to true if any of the results is loading
    const isLoading = personAddressListResult?.loading;
    setLoadingState(isLoading);

    // Handling errors
    if (personAddressListResult?.error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }

    // Handling personAddressListResult data
    if (personAddressListResult?.data) {
      const tableData = mapToPersonAddressesListingData(
        personAddressListResult.data
      );
      setTableData({ ...tableData, pageNumber: 0 });
    }

    // Finally, set loading state to false if none are loading
    if (!isLoading) {
      setLoadingState(false);
    }
  }, [
    personAddressListResult?.loading,
    personAddressListResult?.error,
    personAddressListResult?.data,
  ]);

  //#endregion

  //#region functions
  function handlePageChange(page: number) {
    setTableLoadingState(true);
    personAddressListResult
      ?.fetchMore({
        variables: {
          currentPage: ++page,
          currentPageSize: tableData.pageSize,
        },
      })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const newApplicationList = mapToPersonAddressesListingData(
            fetchMoreResult.data
          );
          const updatedTableData = {
            ...newApplicationList,
            pageNumber: --page,
          };
          setTableData(updatedTableData);
        }
      })
      .finally(() => {
        setTableLoadingState(false);
      });
  }

  function handleRowsPerPageChange(numberOfRecordsPerPage: number) {
    setTableLoadingState(true);
    setTableData({ ...tableData, pageNumber: 1 });
    personAddressListResult
      ?.fetchMore({
        variables: {
          currentPage: 1,
          currentPageSize: numberOfRecordsPerPage,
        },
      })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const newApplicationList = mapToPersonAddressesListingData(
            fetchMoreResult.data
          );

          const updatedTableData = {
            ...newApplicationList,
            pageNumber: 0,
            pageSize: numberOfRecordsPerPage,
          };
          setTableData(updatedTableData);
        }
      })
      .finally(() => {
        setTableLoadingState(false);
      });
  }

  const handlePersonSectionChange = async () => {
    try {
      client.refetchQueries({
        include: [getPersonDetailsInfo()],
      });
    } catch (error) {
      toast.error(<ToastErrorMessage>{error}</ToastErrorMessage>);
    }
  };

  useEffect(() => {
    getPersonDetailsLazy();
  }, []);

  const renderMainChildren = () => {
    const status =
      entityResult?.data?.SalesforceManagement?.entities?.person?.views?.SalesforceManagement_all?.properties?.Status.toLowerCase();
    const isPersonActive = status === "active";

    return (
      <>
        <div style={{ marginTop: "20px" }}>
          <EnhancedStepperSpecificDesign
            activeStep={getPersonStatus(status)}
            steps={steps}
            className={classes.stepper}
            showStepperButton
            buttonTitle={isPersonActive ? "Deactivate" : "Activate"}
            buttonState={
              isPersonActive ? activateButtonState : deactivateButtonState
            }
            buttonOnClick={() => {
              if (isPersonActive) {
                const confirmation: IConfirmation = {
                  open: true,
                  title: "Deactivate",
                  message: `Are you sure you want to deactivate this person?`,
                  callback: async () => {
                    setDeactivateButtonState("loading");
                    deactivateAction()
                      .then((result) => {
                        if (isEmpty(result.errors)) {
                          setDeactivateButtonState("success");
                          toast.success(
                            <ToastSuccessMessage>
                              {
                                "Person successfully deactivated. Relevant Business Partners are deactivated as well."
                              }
                            </ToastSuccessMessage>
                          );
                        } else {
                          setDeactivateButtonState(undefined);
                          toast.error(
                            <ToastErrorMessage>
                              {getError(result)}
                            </ToastErrorMessage>
                          );
                        }
                      })
                      .finally(() => {
                        setTimeout(() => {
                          setDeactivateButtonState(undefined);
                        }, 1000);
                      });
                  },
                  submitButtonText: "Yes",
                  cancelButtonText: "No",
                };
                dispatch(OpenConfirmationAction(confirmation));
              } else {
                const confirmation: IConfirmation = {
                  open: true,
                  title: "Activate",
                  message: `Are you sure you want to activate this person?`,
                  callback: async () => {
                    setActivateButtonState("loading");
                    activateAction()
                      .then((result) => {
                        if (isEmpty(result.errors)) {
                          setActivateButtonState("success");
                          toast.success(
                            <ToastSuccessMessage>
                              {"Person successfully activated."}
                            </ToastSuccessMessage>
                          );
                        } else {
                          setActivateButtonState(undefined);
                          toast.error(
                            <ToastErrorMessage>
                              {getError(result)}
                            </ToastErrorMessage>
                          );
                        }
                      })
                      .finally(() => {
                        setTimeout(() => {
                          setActivateButtonState(undefined);
                        }, 1000);
                      });
                  },
                  submitButtonText: "Yes",
                  cancelButtonText: "No",
                };
                dispatch(OpenConfirmationAction(confirmation));
              }
            }}
          />
          {personDrawerOpen && (
            <PersonDrawer
              open={personDrawerOpen}
              onClose={() => setPersonDrawerOpen(false)}
              onSuccess={() => {
                handlePersonSectionChange();
              }}
              //canNavigateToDetailsPage={false}
              personId={entityId}
            />
          )}
          {personalInformationViewConfig && (
            <>
              <DynamicWidget
                hasFourFields={true}
                view={personalInformationViewConfig}
                actions={
                  <ShowForUser allowedRoles={["Insurance-Admin"]}>
                    <div>
                      <EnhancedButton
                        type="button"
                        backgroundColor={MAIN_ONE_THEME.palette.primary1.main}
                        color="#FFF"
                        onClick={() => setPersonInfoDrawerOpen(true)}
                        className={classes.actionButton}
                      >
                        Modify
                      </EnhancedButton>
                    </div>
                  </ShowForUser>
                }
              />
              {isPerson && (
                <div style={{ marginTop: "20px" }}>
                  {additionalInformationViewConfig && (
                    <DynamicWidget
                      hasFourFields={true}
                      view={additionalInformationViewConfig}
                      actions={
                        <ShowForUser allowedRoles={["Insurance-Admin"]}>
                          <div>
                            <EnhancedButton
                              type="button"
                              backgroundColor={
                                MAIN_ONE_THEME.palette.primary1.main
                              }
                              color="#FFF"
                              onClick={() => setAdditionalInfoDrawerOpen(true)}
                              className={classes.actionButton}
                            >
                              Modify
                            </EnhancedButton>
                          </div>
                        </ShowForUser>
                      }
                    />
                  )}
                </div>
              )}
              <>
                <WidgetSection style={{ margin: "-23px 0 0 0" }}>
                  <form>
                    {personAddressListResult?.loading ? (
                      <div></div>
                    ) : (
                      <div
                        style={{
                          marginTop: "20px",
                          padding: "-23px 0 0 0 !important",
                        }}
                      >
                        <EnhancedTable
                          title={capitalizeFirstLetter(
                            " Addresses Information"
                          )}
                          inlineTitle={capitalizeFirstLetter(
                            " Addresses Information"
                          )}
                          name="count"
                          entityName={"PersonAddress"}
                          entityIdColumnName={
                            "salesforceManagement_PersonAddress_Id"
                          }
                          showCellFullData={true}
                          isTitlePascalCase={true}
                          orderByAscendingByDefault
                          defaultOrderByColumn="name"
                          inline={true}
                          data={tableData}
                          headers={headers}
                          handlePageChange={(page: number) =>
                            handlePageChange(page)
                          }
                          handleRowsPerPageChange={(page: number) =>
                            handleRowsPerPageChange(page)
                          }
                          currentPage={tableData.pageNumber}
                          hideToolbar={false}
                          usePagination
                          disableSelection={false}
                          actions={personDrawerListingActions}
                          loader={tableLoadingState}
                          showTablePagination={true}
                          onSelectionChange={(newDetailedSelection) => {
                            setSelectedPersonAddressItems(
                              newDetailedSelection.length
                            );
                            if (newDetailedSelection.length > 0) {
                              setSelectedPersonAddressEntityId(
                                newDetailedSelection[0].id
                              );
                            } else {
                              setSelectedPersonAddressEntityId("");
                            }
                          }}
                        />

                        {personAddressDrawerOpen && (
                          <PersonAddressDrawer
                            open={personAddressDrawerOpen}
                            personAddressId={
                              actionClicked === "new"
                                ? null
                                : selectedPersonAddressEntityId
                            }
                            onClose={() => setPersonAddressDrawerOpen(false)}
                            onSuccess={() => {
                              handlePageChange(0);
                            }}
                            personId={entityId}
                          />
                        )}
                      </div>
                    )}
                  </form>
                </WidgetSection>
              </>
            </>
          )}
        </div>
      </>
    );
  };

  const renderLeftSection = () => {
    return (
      <>
        <EntityInfoWidget
          background="rgb(193, 0, 0)"
          classification={{ classifications: {}, value: "" }}
          progress={100}
          supportsClassification={true}
          supportsProgressBar={true}
          title={personDetailsInfo.name}
          view={updatedLeftSideInfo}
        ></EntityInfoWidget>
      </>
    );
  };

  //#endregion

  return loadingState ? (
    <Loader />
  ) : (
    <>
      <div>
        {personInfoDrawerOpen && (
          <PersonDetailsDrawer
            open={personInfoDrawerOpen}
            onClose={() => setPersonInfoDrawerOpen(false)}
            onSuccess={() => {
              getPersonDetailsLazy();
            }}
            personDetailsInfo={
              personDetailsInfo as unknown as IPersonDetailsDrawerInfo
            }
            personId={params.id}
          />
        )}
      </div>
      <div>
        {additionalInfoDrawerOpen && (
          <AdditionalInformationDrawer
            open={additionalInfoDrawerOpen}
            onClose={() => setAdditionalInfoDrawerOpen(false)}
            onSuccess={() => {}}
            // onSuccess={() => {
            //   getPolicyDetailsLazy();
            // }}
            personId={entityId}
            personType={personType}
          />
        )}
      </div>
      <StaticLayout
        name={"Person Details"}
        leftChildren={renderLeftSection()}
        mainChildren={renderMainChildren()}
      />
    </>
  );
};

export default PersonDetailsPage;
