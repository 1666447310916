import { gql } from '@apollo/client';

export function getUserLookups() {
  return gql`
    query getUserEnums {
      Insurance_UserRoles: __type(name: "Insurance_UserRoles") {
        name
        enumValues {
          name
        }
      }
      Insurance_UserStatuses: __type(name: "Insurance_UserStatuses") {
        name
        enumValues {
          name
        }
      }
    }
  `;
}

export function createUser() {
  return gql`
    mutation createUser(
      $firstName: String!
      $lastName: String!
      $email: String!
      $mobile: String!
      $role: Insurance_UserRoles!
      $status: Insurance_UserStatuses!
    ) {
      insurance {
        actions {
          createUser(
            firstName: $firstName
            lastName: $lastName
            email: $email
            mobile: $mobile
            role: $role
            status: $status
          ) {
            id
          }
        }
      }
    }
  `;
}

export function getUserInfo() {
  return gql`
    query GetUserInfo($userId: String!) {
      Insurance {
        queries {
          getUser(userId: $userId) {
            system_User_Id
            system_User_firstName
            system_User_lastName
            system_User_mobile
            system_User_email
            system_User_roleEnum
            system_User_status
          }
        }
      }
    }
  `;
}

export function updateUser() {
  return gql`
    mutation updateUser(
      $userId: String!
      $firstName: String!
      $lastName: String!
      $mobile: String!
      $role: Insurance_UserRoles!
      $status: Insurance_UserStatuses!
    ) {
      insurance {
        actions {
          updateUser(
            userId: $userId
            firstName: $firstName
            lastName: $lastName
            mobile: $mobile
            role: $role
            status: $status
          ) {
            id
          }
        }
      }
    }
  `;
}
