import { FormInputTypes } from '../../DynamicForm';
import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
} from '../../components/enhanced-table';
import { IListingFilterWidgetSection } from '../../components/widgets/custom-listing-filter';
import { IAbstractRecord } from '../../models';

export const headers: Record<string, EnhancedTableHeader> = {
  code: {
    name: 'code',
    title: 'Code',
    type: EnhancedTableHeaderType.Clickable,
    callback: () => {},
  },
  name: {
    name: 'name',
    title: 'Name',
    type: EnhancedTableHeaderType.Clickable,
    callback: () => {},
  },
  title: {
    name: 'title',
    title: 'Title',
    type: EnhancedTableHeaderType.Text,
  },
  type: {
    name: 'type',
    title: 'Type',
    type: EnhancedTableHeaderType.Text,
  },
  email: {
    name: 'email',
    title: 'Email',
    type: EnhancedTableHeaderType.Text,
  },
  phoneNumber: {
    name: 'phoneNumber',
    title: 'Phone Number',
    type: EnhancedTableHeaderType.PhoneNumber,
  },
  status: {
    name: 'status',
    title: 'Status',    
    type: EnhancedTableHeaderType.Text,
  },
  createdOn: {
    name: 'createdOn',
    title: 'Created On',
    type: EnhancedTableHeaderType.Date,
  },
  modifiedOn: {
    name: 'modifiedOn',
    title: 'Modified on',
    type: EnhancedTableHeaderType.Date,
  },
};

export function filterSectionsContent(
  lovs: Record<string, Record<string, string>>,
  filterValues: IAbstractRecord,
): IListingFilterWidgetSection[] {
  const newFilterSections: IListingFilterWidgetSection[] = [
    {
      title: 'Filters',
      inputs: {
        type: {
          name: 'type',
          title: 'Type',
          type: FormInputTypes.chips,
          value: filterValues?.type || [],
          placeholder: 'Type',
          triggerUpdate: true,
          selectOptions: { ...lovs.type },
        },
        status: {
          name: 'status',
          title: 'Status',
          type: FormInputTypes.chips,
          value: filterValues?.status || [],
          placeholder: 'Status',
          triggerUpdate: true,
          selectOptions: { ...lovs.status },
        },
        createdOn: {
          name: 'createdOn',
          title: 'Created On',
          type: FormInputTypes.daterange,
          value: filterValues.createdOn || [],
          placeholder: 'Created On',
          triggerUpdate: true,
        },
      },
    },
  ];
  return newFilterSections;
}
