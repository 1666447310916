import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import GenericDrawer from "../../components/common/generic-drawer/GenericDrawer";
import DynamicForm from "../../DynamicForm/DynamicForm";
import { inputs } from "./content";
import { cloneDeep, isEmpty } from "lodash";
import {
  DynamicFormInputType,
  IFormDateDynamicProps,
  IFormSelectDynamicProps,
} from "../../DynamicForm";
import { toast } from "react-toastify";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import {
  DEFAULT_ERROR_TEXT,
  SEND_TO_BACKEND_DATE_FORMAT,
} from "../../constants";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  createBusinessPartnerPlan,
  getBusinesspartnerAssignedLineInfo,
  getBusinessPartnerPlansLinesListWithoutPagination,
  getDefaultCommissionEnums,
  getPlanListByAssignedLines,
} from "./queries";
import {
  LookupToList,
  extractLines,
  extractPlans,
  extractSelectedLine,
  extractSelectedPlanByLine,
} from "./utils";
import Loader from "../../components/Loader";
import EnhancedNote from "../../components/enhanced-form/EnhancedNote";
import { normaliseDynamicValues } from "../../utils/dynamic-utils";
import { EnhancedButtonStatus } from "../../components/EnhancedButton";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import { getError } from "../../utils/graph-utils";
import {
  PlanConfigManagement_PlanStatuses,
  SalesforceManagement_BusinessPartnerLineStatuses,
} from "../../gql/__generated__/apollo-enums";
import { formatDateTime } from "../../utils/formatting-utils";
import dayjs from "dayjs";
import { IBusinessPartnerAddPlansDrawerProps } from ".";

const useStyles = makeStyles()(() => ({
  note: {
    color: "red !important",
    fontSize: "12px !important",
    fontStyle: "italic",
    margin: "10px 0 0 0",
  },
}));

const BusinessPartnerAddPlansDrawer: React.FC<
  IBusinessPartnerAddPlansDrawerProps
> = ({
  businessPartnerId,
  businessPartnerType,
  assignedLineId,
  selectedBusinessPlanIds,
  selectedDetailedSelection,
  open,
  onSuccess,
  onClose,
}) => {
  const { classes } = useStyles();
  let defaultCommissionInfoResult: any;

  let newDefualtCommissionEnums: Record<string, Record<string, string>> = {};

  let defaultCommissionEnumResults = useQuery(getDefaultCommissionEnums(), {
    fetchPolicy: "no-cache",
  });

  const [createBusinessPartnerPlanAction] = useMutation(
    createBusinessPartnerPlan()
  );

  const [selectedLineID, setSelectedLineID] = useState<string>("");
  let [submitButtonDisabled, setSubmitButtonDisabled] =
    useState<boolean>(false);
  const [hasPlanActiveToError, setHasPlanActiveToError] = useState("");
  let [newActiveTo, setNewActiveTo] = useState<any>();
  let [newActiveFrom, setNewActiveFrom] = useState<any>();
  const [selectedBusinessPartnerLineId, setSelectedBusinessPartnerLineId] =
    useState<string>("");
  const [selectedBusinessPartnerLine, setSelectedBusinessPartnerLine] =
    useState<any>();
  const relatedBusinessPartnerType = businessPartnerType;

  const [
    getBusinessPartnerLinesListWithoutPaginationLazy,
    businessPartnerLinesListWithoutPaginationQueryRes,
  ] = useLazyQuery(getBusinessPartnerPlansLinesListWithoutPagination(), {
    fetchPolicy: "no-cache",
    variables: {
      SelectedStatuses: [
        SalesforceManagement_BusinessPartnerLineStatuses.ACTIVE,
      ],
      selectedBusinessPartnerID: businessPartnerId,
    },
  });

  const [getPlanListByAssignedLinesLazy, planListByAssignedLinesQueryRes] =
    useLazyQuery(getPlanListByAssignedLines(), {
      fetchPolicy: "no-cache",
      variables: {
        SelectedStatuses: [PlanConfigManagement_PlanStatuses.ACTIVE],
        selectedBusinessPartnerID: businessPartnerId,
        selectedLineID: selectedLineID,
      },
    });

  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();

  let updatedInputs: Record<string, DynamicFormInputType> = inputs;
  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);

  if (assignedLineId) {
    defaultCommissionInfoResult = useQuery(
      getBusinesspartnerAssignedLineInfo(),
      {
        variables: { id: assignedLineId },
      }
    );
  }

  const getdataFromSeletedPlans = (selectedDetailsPlan: any) => {
    const type =
      selectedDetailsPlan?.salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionApplicable ===
      true
        ? selectedDetailsPlan?.salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionType.toUpperCase()
        : selectedDetailsPlan?.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_LineCommissionType !==
          null
        ? selectedDetailsPlan?.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_LineCommissionType
        : "RATE";
    const rate =
      selectedDetailsPlan?.salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionApplicable ===
      true
        ? selectedDetailsPlan?.salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionRate *
          100
        : selectedDetailsPlan?.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_LineCommissionRate !==
          (0 || undefined || null)
        ? selectedDetailsPlan?.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_LineCommissionRate *
          100
        : 0;
    const value =
      selectedDetailsPlan?.salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionApplicable ===
      true
        ? selectedDetailsPlan?.salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionValue
        : selectedDetailsPlan?.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_LineCommissionValue !==
          (0 || undefined || null)
        ? selectedDetailsPlan?.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_LineCommissionValue
        : 0;

    const valueCurrency =
      selectedDetailsPlan?.salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionApplicable ===
      true
        ? selectedDetailsPlan?.salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionValueCurrency
        : selectedDetailsPlan?.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_ValueCurrency !==
          null
        ? selectedDetailsPlan?.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_ValueCurrency
        : "39735";
    const applicableOn =
      selectedDetailsPlan?.salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionApplicable ===
      true
        ? selectedDetailsPlan?.salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionApplicableOn
        : selectedDetailsPlan?.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_ApplicableOn !==
          null
        ? selectedDetailsPlan?.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_ApplicableOn
        : "";
    const activeFrom =
      selectedDetailsPlan?.businessPartnerActiveFrom !== null
        ? selectedDetailsPlan?.businessPartnerActiveFrom
        : "";
    const activeTo =
      selectedDetailsPlan?.businessPartnerActiveTo !== null
        ? selectedDetailsPlan?.businessPartnerActiveTo
        : "";

    return {
      type,
      rate,
      value,
      valueCurrency,
      applicableOn,
      activeFrom,
      activeTo,
    };
  };

  const initializeUpdatedInputs = () => {
    const isMultipleSelection = selectedBusinessPlanIds.length > 1;
    const isSingleSelection = selectedBusinessPlanIds.length === 1;

    if (isMultipleSelection) {
      updatedInputs.commissionType.value = "RATE";
      updatedInputs.lineCommissionValue.value = 0;
      updatedInputs.ValueCurrency.value = "39735";
      updatedInputs.lineCommissionRate.value = 0;
      updatedInputs.ApplicableOn.value = "";
      updatedInputs.activeFrom.value = "";
      updatedInputs.activeTo.value = "";
    } else if (isSingleSelection) {
      const {
        type,
        rate,
        value,
        valueCurrency,
        applicableOn,
        activeFrom,
        activeTo,
      } = getdataFromSeletedPlans(selectedDetailedSelection[0]);

      updatedInputs.commissionType.value = type;
      updatedInputs.lineCommissionValue.value = value;
      updatedInputs.ValueCurrency.value = valueCurrency;
      updatedInputs.lineCommissionRate.value = Math.round(
        parseFloat(Number(rate).toFixed(10))
      );
      updatedInputs.ApplicableOn.value = applicableOn;
      updatedInputs.activeFrom.value = activeFrom;
      updatedInputs.activeTo.value = activeTo;

      updatedInputs.activeTo.disabled = false;
    }
  };

  const initialize = () => {
    try {
      updatedInputs = cloneDeep(inputsForm);

      if (isEmpty(businessPartnerLinesListWithoutPaginationQueryRes.data)) {
        getBusinessPartnerLinesListWithoutPaginationLazy();
      }
      if (businessPartnerLinesListWithoutPaginationQueryRes?.data) {
        const extractedLineSublines = extractLines(
          businessPartnerLinesListWithoutPaginationQueryRes.data
        );

        setInputsForm((currentInputsForm) => ({
          ...currentInputsForm,
          assignedLine: {
            ...currentInputsForm.assignedLine,
            selectOptions: extractedLineSublines.lines,
            onSelect: (option) => {
              setSelectedLineID(option);
              getPlanListByAssignedLinesLazy({
                variables: {
                  SelectedStatuses: [PlanConfigManagement_PlanStatuses.ACTIVE],
                  selectedBusinessPartnerID: businessPartnerId,
                  selectedLineID: option,
                },
              });
            },
          },
        }));

        (updatedInputs.assignedLine as IFormSelectDynamicProps).selectOptions =
          extractedLineSublines.lines;

        const isBusinessPartnerTypeDurectSalesforce =
          relatedBusinessPartnerType === "Direct_Salesforce";

        updatedInputs.commissionType.hidden =
          isBusinessPartnerTypeDurectSalesforce ? true : false;
        updatedInputs.ApplicableOn.hidden =
          isBusinessPartnerTypeDurectSalesforce;

        (updatedInputs.assignedLine as IFormSelectDynamicProps).onSelect = (
          option
        ) => {
          setSelectedLineID(option);
          const extractedLinePlans = extractSelectedLine(
            option,
            businessPartnerLinesListWithoutPaginationQueryRes?.data
          );
          setSelectedBusinessPartnerLine(extractedLinePlans);
          setSelectedBusinessPartnerLineId(
            extractedLinePlans.selectedBusinessPartnerLineId
          );

          const isBusinessPartnerTypeDurectSalesforce =
            relatedBusinessPartnerType === "Direct_Salesforce";

          updatedInputs.commissionType.value =
            extractedLinePlans.commissionType || "RATE";
          updatedInputs.lineCommissionValue.value =
            extractedLinePlans.lineCommissionValue || 0;
          updatedInputs.ValueCurrency.value =
            extractedLinePlans.ValueCurrency || "39735";
          updatedInputs.lineCommissionRate.value =
            (extractedLinePlans?.lineCommissionRate ?? 0) * 100.0;
          updatedInputs.ApplicableOn.value = extractedLinePlans.ApplicableOn;

          updatedInputs.commissionType.hidden =
            isBusinessPartnerTypeDurectSalesforce ? true : false;

          const isRate =
            updatedInputs.commissionType.value.toLowerCase() === "rate";
          const isCommissionTypeEmpty =
            updatedInputs.commissionType.value === "";

          updatedInputs.commissionType.required = isRate;
          updatedInputs.lineCommissionValue.hidden =
            isRate ||
            isBusinessPartnerTypeDurectSalesforce ||
            isCommissionTypeEmpty;
          updatedInputs.lineCommissionValue.disabled = isRate;
          updatedInputs.lineCommissionValue.required = !isRate;
          updatedInputs.ValueCurrency.hidden =
            isRate ||
            isBusinessPartnerTypeDurectSalesforce ||
            isCommissionTypeEmpty;
          updatedInputs.ValueCurrency.disabled = isRate;
          updatedInputs.ValueCurrency.required = !isRate;

          updatedInputs.lineCommissionRate.hidden =
            !isRate ||
            isBusinessPartnerTypeDurectSalesforce ||
            isCommissionTypeEmpty;
          updatedInputs.lineCommissionRate.disabled = !isRate;
          updatedInputs.lineCommissionRate.required = isRate;

          updatedInputs.ApplicableOn.disabled =
            isBusinessPartnerTypeDurectSalesforce;
          updatedInputs.ApplicableOn.required =
            !isBusinessPartnerTypeDurectSalesforce;

          setInputsForm((currentInputsForm) => ({
            ...currentInputsForm,
            activeFrom: {
              ...currentInputsForm.activeFrom,
              value: null,
            },
            activeTo: {
              ...currentInputsForm.activeTo,
              value: null,
            },
          }));

          if (isEmpty(planListByAssignedLinesQueryRes.data)) {
            getPlanListByAssignedLinesLazy();
          }

          if (planListByAssignedLinesQueryRes?.data) {
            const extractedLinePlans = extractPlans(
              planListByAssignedLinesQueryRes.data
            );

            (
              updatedInputs.assignedPlans as IFormSelectDynamicProps
            ).selectOptions = extractedLinePlans.plans;
          }
        };
      }

      (updatedInputs.activeFrom as IFormDateDynamicProps).onChange = (
        event
      ) => {
        setNewActiveFrom(event);
        setInputsForm((currentInputsForm) => ({
          ...currentInputsForm,
          activeTo: {
            ...currentInputsForm.activeTo,
            minDate: event,
          },
        }));
        if (event !== null && newActiveFrom !== null) {
          updatedInputs.activeTo.disabled = false;
        }

        handleActiveDateChange(newActiveTo, newActiveFrom);
      };

      (updatedInputs.activeTo as IFormDateDynamicProps).onChange = (event) => {
        setNewActiveTo(event);

        (updatedInputs.activeTo as IFormDateDynamicProps).minDate =
          updatedInputs.activeFrom.value;

        handleActiveDateChange(newActiveTo, newActiveFrom);
      };

      if (defaultCommissionEnumResults.data) {
        newDefualtCommissionEnums = LookupToList(
          defaultCommissionEnumResults.data
        );

        (
          updatedInputs.commissionType as IFormSelectDynamicProps
        ).selectOptions = newDefualtCommissionEnums["commissionTypes"];

        (updatedInputs.commissionType as IFormSelectDynamicProps).onSelect = (
          option
        ) => {
          const isRate = option === "RATE";

          updatedInputs.commissionType.required = isRate;

          updatedInputs.lineCommissionValue.hidden =
            isRate || businessPartnerType === "Direct_Salesforce";
          updatedInputs.lineCommissionValue.disabled = isRate;
          updatedInputs.lineCommissionValue.required = !isRate;
          updatedInputs.ValueCurrency.hidden =
            isRate || businessPartnerType === "Direct_Salesforce";
          updatedInputs.ValueCurrency.disabled = isRate;
          updatedInputs.ValueCurrency.required = !isRate;

          updatedInputs.lineCommissionRate.hidden =
            !isRate || businessPartnerType === "Direct_Salesforce";
          updatedInputs.lineCommissionRate.disabled = !isRate;
          updatedInputs.lineCommissionRate.required = isRate;
          updatedInputs.ApplicableOn.hidden =
            businessPartnerType === "Direct_Salesforce";
          updatedInputs.ApplicableOn.disabled =
            businessPartnerType === "Direct_Salesforce";
          updatedInputs.ApplicableOn.required = !(
            businessPartnerType === "Direct_Salesforce"
          );
        };

        (updatedInputs.ValueCurrency as IFormSelectDynamicProps).selectOptions =
          newDefualtCommissionEnums["currencies"];

        (updatedInputs.ApplicableOn as IFormSelectDynamicProps).selectOptions =
          newDefualtCommissionEnums["commissionApplicableOnOptions"];

        const isRate =
          updatedInputs.commissionType.value.toLowerCase() === "rate";

        updatedInputs.lineCommissionValue.hidden =
          isRate || businessPartnerType === "Direct_Salesforce";
        updatedInputs.lineCommissionValue.disabled = isRate;
        updatedInputs.lineCommissionValue.required = !isRate;
        updatedInputs.ValueCurrency.hidden =
          isRate || businessPartnerType === "Direct_Salesforce";
        updatedInputs.ValueCurrency.disabled = isRate;
        updatedInputs.ValueCurrency.required = !isRate;

        updatedInputs.lineCommissionRate.hidden =
          !isRate || businessPartnerType === "Direct_Salesforce";
        updatedInputs.lineCommissionRate.disabled = !isRate;
        updatedInputs.lineCommissionRate.required = isRate;
        updatedInputs.ApplicableOn.hidden =
          businessPartnerType === "Direct_Salesforce";
        updatedInputs.ApplicableOn.disabled =
          businessPartnerType === "Direct_Salesforce";
        updatedInputs.ApplicableOn.required = !(
          businessPartnerType === "Direct_Salesforce"
        );
      }

      setInputsForm(updatedInputs);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputs, values);

    setFormDisabled(true);
    setSubmitButtonState("loading");

    try {
      const isRate = data.commissionType.toLowerCase() === "rate";

      let variables = {
        selectedPlanIDs: data.assignedPlans,
        businessPartnerLineID: selectedBusinessPartnerLineId,
        businessPartnerPlanInputs: {
          activeFrom: formatDateTime(
            data.activeFrom,
            SEND_TO_BACKEND_DATE_FORMAT
          ),
          activeTo: formatDateTime(data.activeTo, SEND_TO_BACKEND_DATE_FORMAT),
          planCommissionApplicableOn: data.ApplicableOn || null,
          planCommissionRate: isRate
            ? parseInt(data.lineCommissionRate, 10) / 100
            : parseInt("0", 10),
          planCommissionType: data.commissionType,
          planCommissionValue: !isRate
            ? parseInt(data.lineCommissionValue, 10)
            : parseInt("0", 10),
          planCommissionValueCurrency: !isRate ? data.ValueCurrency : "39735",
        },
      };

      createBusinessPartnerPlanAction({
        variables: { ...variables, entityId: businessPartnerId },
        errorPolicy: "all",
      }).then((res) => {
        if (isEmpty(res.errors)) {
          toast.success(
            <ToastSuccessMessage>
              {"Plan(s) successfully assigned."}
            </ToastSuccessMessage>
          );
          setTimeout(() => {
            setSubmitButtonState("success");
            onSuccess();
            onClose();
          }, 500);
        } else {
          setSubmitButtonState(undefined);
          toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
        }
      });
    } catch {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setFormDisabled(false);
    }
  };

  const handleActiveDateChange = (activeTo: any, activeFrom: any) => {
    if (activeTo && activeFrom && dayjs(activeTo).isBefore(dayjs(activeFrom))) {
      setHasPlanActiveToError(
        "Effective To Date shall be equal or greater than Effective From Date"
      );
      setInputsForm((currentInputsForm) => ({
        ...currentInputsForm,
        activeTo: {
          ...currentInputsForm.activeTo,
          minDate: activeFrom,
          error:
            "Effective To Date shall be equal or greater than Effective From Date",
        },
      }));
      setSubmitButtonDisabled(true);
    } else {
      setHasPlanActiveToError("");
      setInputsForm((currentInputsForm) => ({
        ...currentInputsForm,
        activeTo: {
          ...currentInputsForm.activeTo,
          minDate: activeFrom,
          error: "",
        },
      }));

      setSubmitButtonDisabled(hasPlanActiveToError !== "");
    }
  };
  useEffect(() => {
    initialize();
  }, [
    businessPartnerLinesListWithoutPaginationQueryRes?.error,
    businessPartnerLinesListWithoutPaginationQueryRes?.data,
    defaultCommissionEnumResults?.data,
    defaultCommissionInfoResult?.data,
  ]);

  useEffect(() => {
    if (planListByAssignedLinesQueryRes?.data) {
      const extractedLinePlans = extractPlans(
        planListByAssignedLinesQueryRes.data
      );
      setInputsForm((currentInputsForm) => ({
        ...currentInputsForm,
        assignedPlans: {
          ...currentInputsForm.assignedPlans,
          selectOptions: extractedLinePlans.plans,
          onSelect: (option) => {
            const selectedCount = Array.isArray(option) ? option.length : 0;
            if (selectedCount === 1) {
              const selectedOptionData =
                planListByAssignedLinesQueryRes.data.SalesforceManagement.queries.GetPlanListByAssignedLines.find(
                  (item: any) => item.planConfigManagement_Plan_Id === option[0]
                );

              const selectedPlanData = extractSelectedPlanByLine(
                selectedBusinessPartnerLine,
                selectedOptionData
              );

              const { type, rate, value, valueCurrency, applicableOn } =
                getdataFromSeletedPlans(selectedPlanData);

              setNewActiveFrom(
                selectedOptionData.planConfigManagement_Plan_EffectiveFrom
              );
              setNewActiveTo(
                selectedOptionData.planConfigManagement_Plan_EffectiveTo
              );

              if (selectedOptionData) {
                setInputForm(
                  type,
                  rate,
                  value,
                  valueCurrency,
                  applicableOn,
                  selectedOptionData.planConfigManagement_Plan_EffectiveFrom,
                  selectedOptionData.planConfigManagement_Plan_EffectiveTo,
                  false
                );
              }
            } else {
              setInputForm("RATE", 0, 0, "39735", null, null, null);
            }
          },
          onChange: (option) => {
            if (option.length === 0) {
              setInputForm("RATE", 0, 0, "39735", null, null, null);
            }
          },
        },
      }));
    }
  }, [planListByAssignedLinesQueryRes?.data]);

  useEffect(() => {
    handleActiveDateChange(newActiveTo, newActiveFrom);
  }, [newActiveFrom]);
  useEffect(() => {
    handleActiveDateChange(newActiveTo, newActiveFrom);
  }, [newActiveTo]);
  useEffect(() => {
    initializeUpdatedInputs();
  }, [selectedBusinessPlanIds]);

  const setInputForm = (
    type?: string,
    rate?: number,
    value?: number,
    valueCurrency?: string,
    applicableOn?: string,
    activeFrom?: string,
    activeTo?: string,
    disabledActiveTo?: boolean
  ) => {
    setInputsForm((currentInputsForm) => {
      const updatedInputs = { ...currentInputsForm };

      updatedInputs.commissionType.value = type;
      updatedInputs.lineCommissionValue.value = value;
      updatedInputs.ValueCurrency.value = valueCurrency;
      updatedInputs.lineCommissionRate.value = rate;
      updatedInputs.ApplicableOn.value = applicableOn;
      updatedInputs.activeFrom.value = activeFrom;
      updatedInputs.activeTo.value = activeTo;
      updatedInputs.activeTo.disabled =
        disabledActiveTo != undefined ? false : updatedInputs.activeTo.disabled;

      const isRate =
        updatedInputs.commissionType.value.toLowerCase() === "rate";

      updatedInputs.lineCommissionValue.hidden =
        isRate || businessPartnerType === "Direct_Salesforce";
      updatedInputs.lineCommissionValue.disabled = isRate;
      updatedInputs.lineCommissionValue.required = !isRate;
      updatedInputs.ValueCurrency.hidden =
        isRate || businessPartnerType === "Direct_Salesforce";
      updatedInputs.ValueCurrency.disabled = isRate;
      updatedInputs.ValueCurrency.required = !isRate;

      updatedInputs.lineCommissionRate.hidden =
        !isRate || businessPartnerType === "Direct_Salesforce";
      updatedInputs.lineCommissionRate.disabled = !isRate;
      updatedInputs.lineCommissionRate.required = isRate;
      updatedInputs.ApplicableOn.hidden =
        businessPartnerType === "Direct_Salesforce";
      updatedInputs.ApplicableOn.disabled =
        businessPartnerType === "Direct_Salesforce";
      updatedInputs.ApplicableOn.required = !(
        businessPartnerType === "Direct_Salesforce"
      );

      (updatedInputs.commissionType as IFormSelectDynamicProps).onSelect = (
        option
      ) => {
        const isRate = option === "RATE";

        updatedInputs.commissionType.required = isRate;

        updatedInputs.lineCommissionValue.hidden =
          isRate || businessPartnerType === "Direct_Salesforce";
        updatedInputs.lineCommissionValue.disabled = isRate;
        updatedInputs.lineCommissionValue.required = !isRate;
        updatedInputs.ValueCurrency.hidden =
          isRate || businessPartnerType === "Direct_Salesforce";
        updatedInputs.ValueCurrency.disabled = isRate;
        updatedInputs.ValueCurrency.required = !isRate;

        updatedInputs.lineCommissionRate.hidden =
          !isRate || businessPartnerType === "Direct_Salesforce";
        updatedInputs.lineCommissionRate.disabled = !isRate;
        updatedInputs.lineCommissionRate.required = isRate;
        updatedInputs.ApplicableOn.hidden =
          businessPartnerType === "Direct_Salesforce";
        updatedInputs.ApplicableOn.disabled =
          businessPartnerType === "Direct_Salesforce";
        updatedInputs.ApplicableOn.required = !(
          businessPartnerType === "Direct_Salesforce"
        );
      };

      return updatedInputs;
    });
  };

  return (
    <GenericDrawer
      title={"Add Plan(s)"}
      onClose={() => onClose()}
      isOpen={open}
    >
      {(defaultCommissionEnumResults.loading ||
        defaultCommissionInfoResult?.loading) &&
      open ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={"Submit"}
            submitButtonState={submitButtonState}
            isSubmitButtonDisabled={submitButtonDisabled}
            disableForm={formDisabled}
            title="Information"
            noteSection={
              <EnhancedNote
                name="note"
                title=""
                text="Note that plans' preset special commissions will override the commissions entered in this form."
                className={classes.note}
              />
            }
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default BusinessPartnerAddPlansDrawer;
