import React, { useEffect, useState } from 'react';
import GenericDrawer from '../../components/common/generic-drawer/GenericDrawer';
import DynamicForm from '../../DynamicForm/DynamicForm';
import {
  DynamicFormInputType,
} from '../../DynamicForm';
import { toast } from 'react-toastify';
import { EnhancedButtonStatus } from '../../components/common/EnhancedButton';
import { normaliseDynamicValues } from '../../utils/dynamic-utils';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { inputs } from './content';
import { useMutation } from '@apollo/client';
import Loader from '../../components/Loader';
import { cloneDeep } from 'lodash';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import { DEFAULT_ERROR_TEXT, SEND_TO_BACKEND_DATE_FORMAT } from '../../constants';
import { isEmpty } from '../../utils/validationUtils';
import _ from 'lodash';
import { getError } from '../../utils/graph-utils';
import { cancelPolicy } from './queries';
import { formatDateTime } from '../../utils/formatting-utils';

const CancelPolicyDrawer: React.FC<ICancelPolicyDrawerProps> = ({
  id,
  open,
  onSuccess,
  onClose,
}) => {
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();
  
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);

  const [userAction] = useMutation(cancelPolicy()); //useMutation(createUser());

  const initialize = () => {
      const updatedInputs = cloneDeep(inputsForm);
      updatedInputs.cancelationDate.value = new Date();
      setInputsForm(updatedInputs);
  };

  useEffect(() => {
    initialize();
  }, []);

  const submitForm = async (values: Record<string, any>) => {
    if(!submitting){
      setSubmitting(true);
      const [data] = normaliseDynamicValues(inputs, values);
      setSubmitButtonState('loading');
      setFormDisabled(true);
      try {
        let variables = {
          id: id,
          reason: data.reason || "",
          date: formatDateTime(data.cancelationDate, SEND_TO_BACKEND_DATE_FORMAT)
        };
  
        userAction({
          variables: id ? { ...variables, userId: id } : variables,
          errorPolicy: 'all',
        }).then((res) => {
          if (isEmpty(res.errors)) {
            toast.success(
              <ToastSuccessMessage>
                Policy Canceled successfully
              </ToastSuccessMessage>
            );
            setTimeout(() => {
              setSubmitButtonState('success');
              setSubmitting(false);
              onSuccess();
              onClose();
            }, 500);
          } else {
            setSubmitting(false);
            setSubmitButtonState(undefined);
            toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
          }
        });
      } catch {
        setSubmitting(false);
        setSubmitButtonState(undefined);
        toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
      }
    }
  };

  return (
    <GenericDrawer
      title='Cancel Policy'
      onClose={() => onClose()}
      isOpen={open}
    >
      {!open ? (
        <Loader />
      ) : (
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={'Submit'}
            submitButtonState={submitButtonState}
            disableForm={formDisabled}
            title='Information'
          />
      )}
    </GenericDrawer>
  );
};

export default CancelPolicyDrawer;
