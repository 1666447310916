import { gql } from '@apollo/client';

export function cancelPolicy() {
  return gql`
  mutation cancelPolicy($id: String!, $date: Date!, $reason: String!) {
    insurance {
      entities {
        policy {
          insurance {
            cancelPolicy(
              cancelationDate: $date
              cancelationReason: $reason
              entityId: $id
            )
          }
        }
      }
    }
  }
  
  `;
}
