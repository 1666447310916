import Popover from '@mui/material/Popover/Popover';
import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';

interface IEnhancedTooltipProps {
  children: JSX.Element;
  displayContent: JSX.Element;
}

const useStyles = makeStyles()(() => ({
  button: {
    border: 'none',
    fontSize: 'inherit',
    fontFamily: 'inherit',
    color: 'inherit',
    backgroundColor: 'transparent',
    paddingLeft: '0'
  },
}));

const EnhancedToolTip: React.FC<IEnhancedTooltipProps> = ({
  children,
  displayContent,
}) => {
  const { classes } = useStyles();

  const [open, setOpen] = useState<boolean>(false);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  return (
    <>
      <button type="button" onClick={handleClick} className={classes.button}>
        {displayContent}
      </button>
      <Popover
        id={open ? 'simple-popover' : undefined}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div style={{ padding: '5px' }}> {children}</div>
      </Popover>
    </>
  );
};

export default EnhancedToolTip;
