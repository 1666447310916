import {
  enumListAsRecordObject,
  lookupListAsRecordObject,
} from "../../utils/graph-utils";

export function toLookups(data: any) {
  const providerDetailsList: Record<string, Record<string, string>> = {};
  providerDetailsList[data.Configuration_ProviderStatuses.name] =
    enumListAsRecordObject(data.Configuration_ProviderStatuses.enumValues);

    providerDetailsList["type"] = lookupListAsRecordObject(
    data.Configuration.lookups.providerTypes
  );

  return providerDetailsList;
}

export function extractPersons(data: any) {
  let persons: Record<string, string> = {};

  data.SalesforceManagement.queries.AllActivePersons.forEach(
    (element: {
      salesforceManagement_Person_Id: string;
      salesforceManagement_Person_FullName: string;
      salesforceManagement_Person_PersonCode: string;
    }) => {
      persons[
        element.salesforceManagement_Person_Id
      ] = `${element.salesforceManagement_Person_PersonCode} - ${element.salesforceManagement_Person_FullName}`;
    }
  );

  return persons;
}
