import { IDocumentInfo } from '.';
import { DynamicFormInputType, FormInputTypes } from '../../DynamicForm';
import { FileType } from '../../models/file';

export const initialValues: IDocumentInfo = {
  documentName: '',
  document:{
    id: '',
    location: '',
    path: '',
    fileName: '',
    length: undefined,
    contentType: '',
  },
};

export const inputs: Record<string, DynamicFormInputType> = {
  documentName: {
    name: 'documentName',
    title: 'Document Name',
    type: FormInputTypes.text,
    value: initialValues.documentName,
    required: true,
  },
  document: {
    name: 'document',
    title: 'Document',
    type:FormInputTypes.fileuploader,
    value: null,
    required: true,
    disabled: false,
    maxFileSizeInMB: 5,
    allowedFileTypes: [FileType.pdf, FileType.docx, FileType.jpeg,FileType.png],
    imageUrl:'',
  },
};
