import { DynamicFormInputType, FormInputTypes } from '../../DynamicForm';
import { REGEX, isEmpty } from '../../utils/validationUtils';

export const initialValues = {
  businessUserDetails: {
    person: '',
    email: '',
    password: '',
    relatedCompany: '',
    type: '',
    account: '',
    access: '',
    SalesforceManagement_BusinessUserStatuses: 'NEW',
  },
};

export const inputs: Record<string, DynamicFormInputType> = {
  person: {
    name: 'person',
    title: 'Person',
    type: FormInputTypes.chips,
    placeholder: 'Type to search for Person',
    value: !isEmpty(initialValues.businessUserDetails.person)
      ? [initialValues.businessUserDetails.person]
      : [],
    multiple: false,
    disabled: false,
    required: true,
    hasPagination: true,
    itemsPerPage: 15,
  },
  email: {
    name: 'email',
    title: 'Email',
    placeholder: 'Email',
    type: FormInputTypes.text,
    value: initialValues.businessUserDetails.email,
    required: true,
    disabled: true,
  },
  password: {
    name: 'password',
    title: 'Password',
    placeholder: 'Password',
    type: FormInputTypes.password,
    value: initialValues.businessUserDetails.password,
    regex: REGEX.password,
    includePasswordHint: true,
    includePasswordVisibility: true,
    required: false,
    disabled: false,
    autoCompelte: "new-password",
  },
  relatedCompany: {
    name: 'relatedCompany',
    title: 'Related Company',
    type: FormInputTypes.chips,
    placeholder: 'Related Company',
    value: !isEmpty(initialValues.businessUserDetails.relatedCompany)
      ? initialValues.businessUserDetails.relatedCompany
      : '',
    multiple: false,
    disabled: false,
    required: true,
  },
  type: {
    name: 'type',
    title: 'Type',
    type: FormInputTypes.chips,
    placeholder: 'Type',
    value: !isEmpty(initialValues.businessUserDetails.type)
      ? initialValues.businessUserDetails.type
      : '',
    multiple: false,
    disabled: false,
    required: true,
  },
  account: {
    name: 'account',
    title: 'Account',
    placeholder: 'Account',
    type: FormInputTypes.text,
    value: initialValues.businessUserDetails.account,
    required: false,
    disabled: false,
  },
  access: {
    name: 'access',
    title: 'Access',
    type: FormInputTypes.chips,
    placeholder: 'Access',
    value: !isEmpty(initialValues.businessUserDetails.access)
      ? initialValues.businessUserDetails.access
      : '',
    multiple: false,
    disabled: false,
    required: true,
  },
  SalesforceManagement_BusinessUserStatuses: {
    name: 'SalesforceManagement_BusinessUserStatuses',
    title: 'Status',
    type: FormInputTypes.chips,
    value: initialValues.businessUserDetails.SalesforceManagement_BusinessUserStatuses,
    required: true,
    multiple: false,
    disabled: true,
  },
};
