import { capitalizeFirstLetter } from "../../utils/formatting-utils";
import { steps } from "./content";

export function entityToProviderDetails(data: any): any {
  const providerDetailsInfo =
    data?.Configuration?.entities?.provider?.views?.Configuration_all
      ?.properties;
  const personDetailsInfo =
    providerDetailsInfo.PersonID?.views?.SalesforceManagement_all?.properties;
  const providerRetaledUsersInfo =
    data?.Configuration?.entities?.provider?.queries?.configuration
      ?.relatedUsers[0];

  return {
    name: personDetailsInfo?.FullName,
    providerCode: providerDetailsInfo?.ProviderCode,
    personId: providerDetailsInfo?.PersonID?.id,
    dateOfBirth: personDetailsInfo?.DateOfBirth,
    email: personDetailsInfo?.Email,
    phoneNumber: personDetailsInfo?.PhoneDetails?.PhoneNumber,
    phoneType: capitalizeFirstLetter(
      personDetailsInfo?.PhoneDetails?.PhoneType
    ),
    taxable: personDetailsInfo?.IsTaxable ? "Yes" : "No",

    createdBy: `${providerRetaledUsersInfo?.createdBy_firstName} ${providerRetaledUsersInfo?.createdBy_lastName}`,
    createdOn: providerDetailsInfo?.createdOn,
    modifiedBy: `${providerRetaledUsersInfo?.modifiedBy_firstName} ${providerRetaledUsersInfo?.modifiedBy_lastName}`,
    modifiedOn: providerDetailsInfo?.modifiedOn,

    type: providerDetailsInfo.Type?.Id,
    typeTitle: providerDetailsInfo.Type?.Title,
    isLocal: providerDetailsInfo.IsLocal,
    isCompany: providerDetailsInfo.IsCompany,
    effectiveDate: providerDetailsInfo.EffectiveDate,
    expiryDate: providerDetailsInfo.ExpiryDate,
    status: providerDetailsInfo.Status.toLowerCase(),
  };
}

export const getProviderStatus = (status: string) => {
  switch (status) {
    case "inactive":
      return steps[0];
    case "active":
      return steps[1];
    default:
      return steps[0];
  }
};
