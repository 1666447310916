import _ from "lodash";
import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import EnhancedButton from "../components/EnhancedButton";

import EnhancedCheckbox from "../components/EnhancedCheckbox";
import EnhancedChipInput from "../components/enhanced-form/EnhancedChipInput";
import EnhancedDatePicker from "../components/enhanced-form/EnhancedDatePicker";
import EnhancedInput from "../components/enhanced-form/EnhancedInput";
import EnhancedInternationalPhoneInput from "../components/enhanced-form/EnhancedInternationalPhoneInput";
import EnhancedNumberInput from "../components/enhanced-form/EnhancedNumberInput";
import EnhancedSwitch from "../components/enhanced-form/EnhancedSwitch";
import EnhancedUploader from "../components/enhanced-form/EnhancedUploader";
import { MAIN_ONE_THEME } from "../constants";
import { useAppSelector } from "../redux/hooks";
import { validateDynamicForm } from "../utils/dynamic-utils";
import { isEmpty } from "../utils/validationUtils";
import {
  DynamicFormInputType,
  FormInputTypes,
  IDynamicForm,
  IFormChipsDynamicProps,
} from "./index";
import EnhancedCurrencyInput from "../components/enhanced-form/EnhancedCurrencyInput";
import EnhancedInlinePaginatedChipInput from "../components/enhanced-form/EnhancedInlinePaginatedChipInput";
import EnhancedPercentageInput from "../components/enhanced-form/EnhancedPercentageInput";
import EnhancedFormattedNumberInput from "../components/enhanced-form/EnhancedFormattedNumberInput";
import EnhancedTinyMCEEditor from "../components/enhanced-form/EnhancedTinyMCEEditor";

const useStyles = makeStyles<{ secondaryButton: boolean }>()(
  (theme, { secondaryButton }) => ({
    form: {
      width: "100%",
    },
    submitBtn: {
      margin: "0 auto 25px auto!important",
      display: "flex!important",
    },
    footerContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: secondaryButton ? "space-between" : "center",
    },
    informationTitle: {
      padding: "10px 0px",
      fontSize: "16px",
      fontFamily: "SourceSansPro-Regular",
      fontWeight: 600,
      boxSizing: "border-box",
      minHeight: "58px",
      lineHeight: "18px",
      borderBottom: "1px #E4E4E4 solid",
      marginBottom: "20px",
    },
  })
);

const DynamicForm: React.FC<
  IDynamicForm & {
    children?: React.ReactNode;
  }
> = ({
  title,
  inputs,
  buttonText,
  onSubmit,
  disableForm = false,
  submitButtonState = null,
  isSubmitButtonDisabled = false,
  children,
  secondaryButton = false,
  secondaryButtonText = "",
  onClickSecondaryButton = () => {},
  mainButtonBgColor = "",
  loadingFields = {},
  customStyles,
  noteSection,
  onChange,
  hasDoprdownSpecificBehavior = false,
  itemsPerPage,
  areNextToEachOther = false,
  popUpStyling = false,
  ...restOfProps
}) => {
  const tenant = useAppSelector((state) => state.tenant);

  const [values, setValues] = useState<Record<string, any>>();
  const [errors, setErrors] = useState<Record<string, any>>();
  const [touched, setTouched] = useState<Record<string, boolean>>();

  const { classes } = useStyles({ secondaryButton });

  useEffect(() => {
    const initialValues: Record<string, any> = {};
    const initialErrors: Record<string, string> = {};
    const initialTouched: Record<string, boolean> = {};

    let mappedValues = _.cloneDeep(values);

    if (!mappedValues) {
      mappedValues = {};

      Object.keys(inputs).forEach((inputKey) => {
        mappedValues[inputKey] = inputs[inputKey]?.value;
      });
    } else {
      Object.keys(mappedValues).forEach((inputKey) => {
        if (
          inputs[inputKey] && // Check if inputs[inputKey] is defined
          inputs[inputKey].value !== undefined // Check if inputs[inputKey].value is defined
        ) {
          if (
            (isEmpty(mappedValues[inputKey]) &&
              inputs[inputKey] &&
              inputs[inputKey].value) ||
            (!isEmpty(mappedValues[inputKey]) &&
              !isEmpty(inputs[inputKey].value))
          ) {
            if (inputs[inputKey] && inputs[inputKey].value !== undefined) {
              mappedValues[inputKey] = inputs[inputKey].value;
            }
            return;
          }

          if (
            ((inputs[inputKey] as IFormChipsDynamicProps).allowDeleteValue &&
              inputs[inputKey].allowDeleteValue === true) ||
            inputs[inputKey].type === FormInputTypes.date ||
            inputs[inputKey].type === FormInputTypes.number
          ) {
            if (
              (isEmpty(mappedValues[inputKey]) ||
                isEmpty(inputs[inputKey].value)) &&
              inputs[inputKey]
            ) {
              mappedValues[inputKey] = inputs[inputKey].value;
              return;
            }
          }
        }
      });
    }

    Object.values(inputs).forEach((input) => {
      initialValues[input.name] = mappedValues[input.name];
      initialErrors[input.name] = input.error || "";
      initialTouched[input.name] = !!input.error;
    });
    setValues(initialValues);
    setErrors(initialErrors);
    setTouched(initialTouched);
  }, [inputs]);

  const onFieldBlur = (name: string) => {
    let touchedTemp = Object.assign({}, touched);
    touchedTemp[name] = true;
    setTouched(touchedTemp);
  };

  const onFieldChange = (name: string, value: any) => {
    let errorsTemp = Object.assign({}, errors);
    let valuesTemp = Object.assign({}, values);
    valuesTemp[name] = value;
    errorsTemp[name] = validateDynamicForm(value, inputs[name], values);
    if (onChange) {
      // to do change acrose all the onChange in all fields to return the inputs with new values
      onChange(name, value, valuesTemp);
    }
    setValues(valuesTemp);
    setErrors(errorsTemp);
  };

  const onFieldChangeByEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    onFieldChange(event.target.name, event.target.value);
  };

  const validate = (): Record<string, string> => {
    const newErrors: Record<string, string> = _.cloneDeep(errors);
    Object.values(inputs).forEach((input) => {
      newErrors[input.name] = validateDynamicForm(
        values[input.name],
        input,
        values
      );
    });
    return newErrors;
  };

  const submit = () => {
    if (!disableForm) {
      const validateErrors = validate();
      const isValid = Object.values(validateErrors).every((v) => isEmpty(v));
      if (isValid) {
        onSubmit(values);
      } else {
        const initialTouched: Record<string, boolean> = {};
        Object.values(inputs).forEach((input) => {
          initialTouched[input.name] = true;
        });
        setErrors(validateErrors);
        setTouched(initialTouched);
      }
    }
  };

  const renderInput = (input: DynamicFormInputType) => {
    const title =
      input.required && !isEmpty(input.title) ? `${input.title}*` : input.title;

    const inputComponent = () => {
      switch (input.type) {
        case FormInputTypes.text:
          return input.isEditor ? (
            <EnhancedTinyMCEEditor
              key={input.name}
              name={input.name}
              title={title}
              placeholder={input.placeholder}
              value={values[input.name]}
              error={touched[input.name] ? errors[input.name] : ""}
              onChange={(name, value) => {
                onFieldChange(name, value);
              }}
              disabled={input.disabled || disableForm}
            />
          ) : (
            <EnhancedInput
              key={input.name}
              name={input.name}
              title={title}
              type="text"
              placeholder={input.placeholder}
              value={values[input.name]}
              error={touched[input.name] ? errors[input.name] : ""}
              onBlur={(event) => {
                // Extract the new value when the input FOCUS OUT
                const newValue = event.target.value;
                // Call the onFieldBlur function for addition handling the BLUR
                onFieldBlur(input.name);
                // The needed logic to catch the final value
                if (!isEmpty(newValue)) {
                  onFieldChange(input.name, newValue);
                  if (input.onChange) {
                    input.onChange(event);
                  }
                }
              }}
              onChange={(event) => {
                // State Update is now Simplified (lightweight operations)
                const newValue = event.target.value;
                onFieldChange(input.name, newValue);
              }}
              //OLD IMPLEMENTATION
              // onBlur={(v) => onFieldBlur(input.name)}
              // onChange={(v) => onFieldChange(input.name, v.target.value)}
              //OLD IMPLEMENTATION
              disabled={input.disabled || disableForm}
              description={input.description}
              multiline={input.multiline}
              material={input.material}
              customStyles={input.customStyles}
              hidden={input.hidden}
            />
          );

        case FormInputTypes.password:
          return (
            <EnhancedInput
              key={input.name}
              name={input.name}
              title={title}
              type="password"
              placeholder={input.placeholder}
              value={values[input.name]}
              error={touched[input.name] ? errors[input.name] : ""}
              onBlur={(v) => onFieldBlur(input.name)}
              onChange={(v) => onFieldChange(input.name, v.target.value)}
              disabled={input.disabled || disableForm}
              material={input.material}
              description={input.description}
              includePasswordhint={input.includePasswordHint}
              includePasswordVisibility={input.includePasswordVisibility}
              customStyles={input.customStyles}
              autoComplete={input.autoCompelte}
              hidden={input.hidden}
            />
          );

      case FormInputTypes.date:
        return (
          <EnhancedDatePicker
            key={input.name}
            name={input.name}
            title={title}
            value={values[input.name]}
            error={touched[input.name] ? errors[input.name] : ""}
            onBlur={() => onFieldBlur(input.name)}
            onDateChange={(dateValue) => {
              onFieldChange(input.name, dateValue);

              input.value = dateValue;

              if (input.onChange) {
                input.onChange(dateValue);
              }
            }}
            // OLD IMPLEMENTATION
            // onDateChange={(v) => onFieldChange(input.name, v)}
            // OLD IMPLEMENTATION
            disabled={input.disabled || disableForm}
            material={input.material}
            minDate={input.minDate}
            maxDate={input.maxDate}
            placeholder={input.placeholder}
            format={tenant.dateFormat}
            hidden={input.hidden}
            canClearDate={input.canClearDate}
          />
        );

      case FormInputTypes.imageFile:
        return (
          <EnhancedUploader
            key={input.name}
            name={input.name}
            title={title}
            value={values[input.name]}
            error={touched[input.name] ? errors[input.name] : ""}
            onBlur={(v) => onFieldBlur(input.name)}
            onUpload={(v) => onFieldChange(input.name, v)}
            disabled={input.disabled || disableForm}
            allowedFileTypes={input.allowedFileTypes}
            type={""}
          />
        );

        case FormInputTypes.select:
          return <span>deprecated</span>;
        case FormInputTypes.number:
          return (
            <EnhancedNumberInput
              key={input.name}
              name={input.name}
              title={title}
              placeholder={input.placeholder}
              value={values[input.name]}
              error={touched[input.name] ? errors[input.name] : ""}
              onBlur={(event) => {
                const newValue = event.target.value;
                onFieldBlur(input.name);
                if (!isEmpty(newValue)) {
                  onFieldChange(input.name, newValue);
                  if (input.onChange) {
                    input.onChange(event);
                  }
                }
              }}
              onChange={(v) => onFieldChangeByEvent(v)}
              disabled={input.disabled || disableForm}
              material={input.material}
              minValue={input.minNumber}
              maxValue={input.maxNumber}
              hasBetweenValidation={input.hasBetweenValidation}
              hidden={input.hidden}
            />
          );
        case FormInputTypes.formattedNumber:
          return (
            <EnhancedFormattedNumberInput
              key={input.name}
              name={input.name}
              title={title}
              placeholder={input.placeholder}
              value={values[input.name]}
              error={touched[input.name] ? errors[input.name] : ""}
              onBlur={(event) => {
                const newValue = event.target.value;
                onFieldBlur(input.name);
                if (!isEmpty(newValue)) {
                  onFieldChange(input.name, newValue);
                  if (input.onChange) {
                    input.onChange(event);
                  }
                }
              }}
              onChange={(v) => onFieldChangeByEvent(v)}
              disabled={input.disabled || disableForm}
              type={""}
              onFocus={(v) => onFieldBlur(input.name)}
              hidden={input.hidden}
              minValue={input.minValue}
              maxValue={input.maxValue}
            />
          );
        case FormInputTypes.percentage:
          return (
            <EnhancedPercentageInput
              key={input.name}
              name={input.name}
              title={title}
              placeholder={input.placeholder}
              value={values[input.name]}
              error={touched[input.name] ? errors[input.name] : ""}
              onBlur={(event) => {
                // Extract the new value when the input FOCUS OUT
                const newValue = event.target.value;
                // Call the onFieldBlur function for addition handling the BLUR
                onFieldBlur(input.name);
                // The needed logic to catch the final value
                if (!isEmpty(newValue)) {
                  onFieldChange(input.name, newValue);
                  if (input.onChange) {
                    input.onChange(event);
                  }
                }
              }}
              onChange={(v) => onFieldChangeByEvent(v)}
              disabled={input.disabled || disableForm}
              material={input.material}
              hasBetweenValidation={input.hasBetweenValidation}
              hidden={input.hidden}
            />
          );

      case FormInputTypes.switch:
        return (
          <EnhancedSwitch
            key={input.name}
            name={input.name}
            title={title}
            value={values[input.name]}
            onBlur={(v) => onFieldBlur(input.name)}
            onChange={(name, val) => onFieldChange(input.name, val)}
            disabled={input.disabled || disableForm}
            required={input.required}
            information={input.information}
            className={input.className}
          />
        );

        case FormInputTypes.multiSelect:
          return <span>deprecated</span>;
        case FormInputTypes.chips:
          return hasDoprdownSpecificBehavior && input.hasPagination ? (
            <EnhancedInlinePaginatedChipInput
              key={input.name}
              name={input.name}
              title={title}
              placeholder={input.placeholder}
              value={values[input.name]}
              preselectedValues={input.preselectedValues}
              error={touched[input.name] ? errors[input.name] : ""}
              onChange={(v) => {
                onFieldChange(input.name, v);
                if (!isEmpty(v) && input.onSelect) {
                  input.onSelect(v as string);
                }
                const newValue = v;
                onFieldBlur(input.name);
                if (!isEmpty(newValue)) {
                  onFieldChange(input.name, newValue);
                  if (input.onChange) {
                    input.onChange(newValue);
                  }
                }
              }}
              disabled={input.disabled || disableForm}
              customStyles={input.customStyles}
              selectOptions={input.selectOptions}
              required={input.required}
              freeSolo={input.freeSolo}
              multiple={input.multiple}
              material={input.material}
              loader={loadingFields[input.name] || false}
              showSelectAll={input.showSelectAll}
              strongStyledOption={input.strongStyledOption}
              hidden={input.hidden}
              itemsPerPage={input.itemsPerPage}
            />
          ) : (
            <EnhancedChipInput
              key={input.name}
              name={input.name}
              title={title}
              placeholder={input.placeholder}
              value={values[input.name]}
              error={touched[input.name] ? errors[input.name] : ""}
              onChange={(v) => {
                onFieldChange(input.name, v);
                if (!isEmpty(v) && input.onSelect) {
                  input.onSelect(v as string);
                }
                if (input.keyToClear) {
                  const newValues = values;

                  // should be recursive and refactor
                  if (input.keyToClear) {
                    newValues[input.keyToClear] = [];
                    if (
                      (inputs[input.keyToClear] as IFormChipsDynamicProps)
                        .keyToClear
                    ) {
                      newValues[
                        (
                          inputs[input.keyToClear] as IFormChipsDynamicProps
                        ).keyToClear
                      ] = [];
                    }
                  }
                  values[input.name] = v;
                  setValues(newValues);
                }
                const newValue = v;
                onFieldBlur(input.name);
                if (!isEmpty(newValue)) {
                  onFieldChange(input.name, newValue);
                  if (input.onChange) {
                    input.onChange(newValue);
                  }
                } else {
                  if (input.onChange) {
                    input.onChange([]);
                  }
                }
              }}
              disabled={input.disabled || disableForm}
              customStyles={input.customStyles}
              selectOptions={input.selectOptions}
              required={input.required}
              freeSolo={input.freeSolo}
              multiple={input.multiple}
              material={input.material}
              loader={loadingFields[input.name] || false}
              showSelectAll={input.showSelectAll}
              strongStyledOption={input.strongStyledOption}
              hidden={input.hidden}
              allowDeleteValue={input.allowDeleteValue}
              keyToClear={input.keyToClear}
              canClearSingleValueSelection={input.canClearSingleValueSelection}
              hasTooltip={input.hasTooltip}
              tooltipText={input.tooltipText}
            />
          );
        case FormInputTypes.phoneNumber:
          return (
            <EnhancedInternationalPhoneInput
              key={input.name}
              name={input.name}
              title={title}
              placeholder={input.placeholder}
              value={values[input.name]}
              error={touched[input.name] ? errors[input.name] : ""}
              onChange={(v) => onFieldChange(input.name, v)}
              onBlur={(v) => onFieldBlur(input.name)}
              disabled={input.disabled || disableForm}
              countriesToShow={input.countriesToShow}
              disableDropDown={input.disableDropDown}
              editCountryCode={input.editCountryCode}
              defaultCountry={input.defaultCountry}
              customFormat={input.customFormat}
              material={input.material}
            />
          );
        case FormInputTypes.fileuploader:
          return (
            <EnhancedUploader
              key={input.name}
              name={input.name}
              title={title}
              placeholder={input.placeholder}
              value={values[input.name]}
              error={touched[input.name] ? errors[input.name] : ""}
              onBlur={(v) => onFieldBlur(input.name)}
              onUpload={(v) => onFieldChange(input.name, v)}
              disabled={input.disabled || disableForm}
              allowedFileTypes={input.allowedFileTypes}
              type={""}
              iconUrl={`${tenant.cdnUrl}/icons/upload-field-secondary.svg`}
            />
          );
        case FormInputTypes.checkbox:
          return (
            <EnhancedCheckbox
              key={input.name}
              name={input.name}
              title={title}
              checked={values[input.name]}
              onChange={(name, val) => onFieldChange(input.name, val)}
              disabled={input.disabled || disableForm}
              className={input.className}
              classes={input.classes}
            />
          );
        case FormInputTypes.currency:
          return (
            <EnhancedCurrencyInput
              key={input.name}
              name={input.name}
              title={title}
              placeholder={input.placeholder}
              value={values[input.name]}
              error={touched[input.name] ? errors[input.name] : ""}
              onBlur={(v) => onFieldBlur(input.name)}
              onChange={(v) => onFieldChangeByEvent(v)}
              disabled={input.disabled || disableForm}
              type={""}
              currencyTitle="$"
              currencyIcon={tenant.cdnUrl + "/icons/dollar-primary.svg"}
              onFocus={(v) => onFieldBlur(input.name)}
              hidden={input.hidden}
            />
          );
        default:
          return null;
      }
    };

    // const maxIndex = Object.values(inputs).reduce((max, input) => {
    //   if (
    //     input.nextToEachOtherIndex !== undefined &&
    //     input.nextToEachOtherIndex > max
    //   ) {
    //     return input.nextToEachOtherIndex;
    //   } else {
    //     return max;
    //   }
    // }, -1);

    return (
      <div
      // style={{
      //   width:
      //     input.areNextToEachOther === true
      //       ? `${100 / maxIndex - maxIndex}%`
      //       : "100%",
      //   display: "inline-block",
      //   marginRight:
      //     input.areNextToEachOther && input.nextToEachOtherIndex < maxIndex
      //       ? `${maxIndex * maxIndex * (maxIndex * maxIndex)}px`
      //       : '',
      // }}
      >
        {inputComponent()}
      </div>
    );
  };

  return values ? (
    <form
      className={classes.form}
      onSubmit={(e) => {
        e.preventDefault();
        submit();
      }}
    >
      {!isEmpty(title) && (
        <div className={classes.informationTitle}>
          <span>{title}</span>
        </div>
      )}
      {popUpStyling ? (
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {Object.values(inputs).map((input) =>
            input.popUpStyling ? (
              <div
                style={{
                  width: "45%",
                  minWidth: "45%",
                  maxWidth: "45%",
                  marginRight: input.popUpStyling ? "20px" : "0",
                }}
              >
                {renderInput(input)}
              </div>
            ) : (
              <div style={{ width: "100%" }}>{renderInput(input)}</div>
            )
          )}
        </div>
      ) : (
        Object.values(inputs).map((input) => renderInput(input))
      )}

      {children}
      {noteSection}
      <div className={classes.footerContainer}>
        {secondaryButton && (
          <EnhancedButton
            onClick={() => onClickSecondaryButton()}
            disabled={disableForm}
            variant="contained"
            className={classes.submitBtn}
            state={submitButtonState}
          >
            {secondaryButtonText}
          </EnhancedButton>
        )}

        <EnhancedButton
          type="submit"
          disabled={disableForm || isSubmitButtonDisabled}
          variant="contained"
          backgroundColor={
            mainButtonBgColor
              ? mainButtonBgColor
              : MAIN_ONE_THEME.palette.primary5.main
          }
          color="rgba(255, 255, 255, 1)"
          style={{ width: "108px" }}
          state={submitButtonState}
          className={customStyles && customStyles.submitButtonStyles}
        >
          {buttonText}
        </EnhancedButton>
      </div>
    </form>
  ) : (
    <></>
  );
};

export default DynamicForm;
