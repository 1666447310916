import { gql } from "@apollo/client";

export function getProviderDetailsInfo() {
  return gql`
    query getProviderDetailsInfo($id: String!) {
      Configuration {
        entities {
          provider(id: $id) {
            queries {
              configuration {
                relatedUsers {
                  createdBy_firstName
                  createdBy_lastName
                  modifiedBy_firstName
                  modifiedBy_lastName
                }
              }
            }
            views {
              Configuration_all {
                properties {
                  Code
                  ProviderCode
                  Status
                  Type {
                    Id
                    Title
                  }
                  IsLocal
                  IsCompany
                  EffectiveDate
                  ExpiryDate
                  createdOn
                  createdBy
                  modifiedOn
                  modifiedBy
                  PersonID {
                    id
                    views {
                      SalesforceManagement_all {
                        properties {
                          FullName
                          Email
                          PersonCode
                          DateOfBirth
                          PhoneDetails {
                            PhoneType
                            PhoneNumber
                          }
                          IsTaxable
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function activateProvider() {
  return gql`
    mutation activateProvider($entityId: String!) {
      configuration {
        entities {
          provider {
            configuration {
              activateProvider(entityId: $entityId) {
                id
              }
            }
          }
        }
      }
    }
  `;
}
