import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { ITheme } from "../../../redux/tenant/types";
import { IUserMeta } from "../../../redux/user/types";

import { makeStyles } from "tss-react/mui";
import { ITabsProps } from "../../../page-layout";
import {
  fetchWidgetMetaFromSession,
  storeWidgetMetaFromSession,
} from "../../../utils/session-storage-utils";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { ITabPageProps } from "..";

interface ITabsWidgetsProps {
  mainPageName: string;
  tabs: ITabsProps[];
  cdnUrl: string;
  theme: ITheme;
  userInfo: IUserMeta["info"];
  tabPanelClassName?: string;
  firstTabAsActiveTab?: boolean;
  onTabChange?: (newTab: string) => void;
}

const useStyles = makeStyles<{ theme: ITheme }>()((_, { theme }) => ({
  container: {
    width: "100%",
    boxSizing: "border-box",
    marginTop: "1.5em",
  },
  tabPane: {
    display: "flex",
    color: theme.palette.primary1.main,
    alignItems: "center",
    justifyContent: 'flex-start',
    minWidth: "90%",
  },
  tabIcon: {
    width: "18px",
    height: "18px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundColor: "transparent",
    backgroundPosition: "center",
    margin: 0,
    float: "left",
    marginRight: "10px",
  },
  countNumber: {
    height: "20px",
    width: "20px",
    border: "none",
    borderRadius: "50%",
    backgroundColor: "#EEEEEE",
    margin: "0 5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "11px",
  },
  tabBox: {
    "& .MuiTabs-indicator": {
      background: theme.palette.primary1.main,
      height: "3px",
    },
  },
  tabs: {
    minWidth: "185px",
  },
  tabTitle: {
    fontSize: "16px",
    fontFamily: "HelveticaNeue-Regular",
    textTransform: "none",
    lineHeight: "24px",
  },
  tabActiveTitle: {
    fontFamily: "HelveticaNeue-Bold",
    fontWeight: "bold",
  },
}));

const TabsWidgets: React.FC<ITabsWidgetsProps> = ({
  mainPageName,
  tabs = [],
  cdnUrl,
  theme,
  userInfo,
  tabPanelClassName,
  firstTabAsActiveTab = false,
  onTabChange,
}) => {
  const [activeTab, setActiveTab] = useState<string>(tabs[0].name);
  const { classes } = useStyles({ theme: theme });
  const [internalActiveTab, setInternalActiveTab] = useState<string>(
    tabs[0]?.name
  );

  const getWidgetView = (widget: ITabPageProps, name: string) => {
    return (
      <>
        <TabPanel value={name} className={tabPanelClassName}>
          {widget.children}
        </TabPanel>
      </>
    );
  };

  const renderWidgets = (): JSX.Element[] => {
    let widgetViews: JSX.Element[] = [];

    tabs.forEach((element) => {
      element.widgets.forEach((widget) =>
        widgetViews.push(getWidgetView(widget, element.name))
      );
    });

    return widgetViews;
  };

  const handleTabChange = (newValue: string) => {
    storeWidgetMetaFromSession(newValue, userInfo, mainPageName, "_activeTab");
    setInternalActiveTab(newValue);
    if (onTabChange) {
      onTabChange(newValue); // This is used to Notify the parent components of the change
    }
  };

  useEffect(() => {
    const storedTabKey = fetchWidgetMetaFromSession(
      userInfo,
      mainPageName,
      "_activeTab"
    );
    if (firstTabAsActiveTab) {
      setActiveTab(tabs[0].name);
    } else {
      if (storedTabKey && tabs.find((tab) => tab.name === storedTabKey)) {
        setActiveTab(storedTabKey);
      } else {
        setActiveTab(tabs[0].name);
      }
    }
  }, [tabs, userInfo]);

  useEffect(() => {
    if (firstTabAsActiveTab) {
      setInternalActiveTab(tabs[0]?.name);
    }
  }, [firstTabAsActiveTab, tabs]);

  return (
    <section className={classes.container}>
      <Box>
        <TabContext value={internalActiveTab}>
          <Box
            className={classes.tabBox}
            sx={{ borderBottom: 1, borderColor: "divider" }}
          >
            <TabList
              className={classes.tabs}
              onChange={(event: React.SyntheticEvent, newValue: string) => {
                firstTabAsActiveTab
                  ? handleTabChange(newValue)
                  : storeWidgetMetaFromSession(
                      newValue,
                      userInfo,
                      mainPageName,
                      "_activeTab"
                    );
                setActiveTab(newValue);
              }}
            >
              {tabs.map((tab) => (
                <Tab
                  className={classes.tabs}
                  key={tab.name}
                  value={tab.name}
                  label={
                    <div className={classes.tabPane}>
                      <figure
                        className={classes.tabIcon}
                        style={{
                          backgroundImage: tab.iconUrl
                            ? `url(${cdnUrl}${tab.iconUrl})`
                            : `url(${cdnUrl}/icons/file-summary-primary.svg)`,
                        }}
                      />
                      <span
                        className={clsx(classes.tabTitle, {
                          [classes.tabActiveTitle]: tab.name === activeTab,
                        })}
                      >
                        {tab.title}
                      </span>
                      {tab.count && (
                        <div className={classes.countNumber}>{tab.count}</div>
                      )}
                    </div>
                  }
                ></Tab>
              ))}
            </TabList>
          </Box>
          {renderWidgets()}
        </TabContext>
      </Box>
    </section>
  );
};

export default TabsWidgets;
