import { makeStyles } from "tss-react/mui";
import React, { useCallback, useRef } from "react";
import clsx from "clsx";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  FilledInputProps,
  OutlinedInputProps,
  TextField,
  InputProps as IInputProps,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Clear } from "@mui/icons-material";
import dayjs from "dayjs";
import { IEnhancedCommonProps } from "..";
import { getDefaultDate } from "../../utils/date-utils";
import { MAIN_ONE_THEME, contentFontFamilyRegular } from "../../constants";

export interface IEnhancedDatePickerProps extends IEnhancedCommonProps {
  name: string;
  title: string;
  error?: string;
  disabled?: boolean;
  value: string;
  format?: string;
  placeholder?: string;
  inputVariant?: "filled" | "standard" | "outlined";
  maxDate?: Date;
  minDate?: Date;
  material?: boolean;
  defaultValue?: string | null;
  viewInternalLabel?: boolean;
  hidden?: boolean;
  InputProps?:
    | Partial<IInputProps>
    | Partial<FilledInputProps>
    | Partial<OutlinedInputProps>;
  onDateChange: (date: Date | "") => void;
  onBlur: () => void;
  canClearDate?: boolean;
}

const useStyles = makeStyles<{ material: boolean }>()(
  (theme, { material }) => ({
    labelWrapper: {
      display: "block",
      textAlign: "left",
      fontSize: MAIN_ONE_THEME.typography.regular.reg2.fontSize,
      lineHeight: "15px",
      overflow: "visible",
      position: "relative",
      "& .react-datepicker-wrapper": {
        width: "100%",
      },
      "& input": {
        height: 35,
        width: "100%",
        padding: "0 15px",
        margin: "0 auto 0 auto",
        backgroundColor: MAIN_ONE_THEME.palette.secondary4.main,
        boxSizing: "border-box",
        borderRadius: "5px",
        "&::placeholder": {
          color: "rgba(0, 0, 0, 0.20)",
          fontStyle: "italic",
        },
      },
      fontFamily: contentFontFamilyRegular,
    },
    materialInput: {
      display: "block",
      textAlign: "left",
      fontSize: MAIN_ONE_THEME.typography.regular.reg2.fontSize,
      lineHeight: "15px",
      overflow: "visible",
      position: "relative",
      "& .MuiInputBase-input:focus": {
        borderBottomColor: MAIN_ONE_THEME.palette.primary.main,
        border: "0 0 1px 0 !important",
        borderRadius: "0px",
        outline: `0px!important`,
        fontFamily: contentFontFamilyRegular,
        fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px !important`,
      },
      "& .MuiFilledInput-root": {
        backgroundColor: "unset !important",

        "& .MuiInputAdornment-root": {
          marginLeft: "-40px",
          "& .MuiSvgIcon-root": {
            marginTop: "7px",
            fontFamily: contentFontFamilyRegular,
            fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px`,
          },
          fontFamily: contentFontFamilyRegular,
          fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px !important`,
        },
      },
      "& .react-datepicker-wrapper": {
        width: "100%",
        fontFamily: contentFontFamilyRegular,
      },
      "& input": {
        width: "100%",
        backgroundColor: "unset",
        color: MAIN_ONE_THEME.palette.primary2.main,
        margin: "0 auto",
        borderRadius: 0,
        padding: "10px 15px 4px 4px",
        fontFamily: contentFontFamilyRegular,
        fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px !important`,
      },
    },
    datePicker: {
      width: "100%",
      fontFamily: contentFontFamilyRegular,
    },
    label: {
      display: "block",
      color: MAIN_ONE_THEME.palette.primary1.main,
      textAlign: "left",
      margin: "0 0 13px",
      fontFamily: contentFontFamilyRegular,
      fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px !important`,
    },
    error: {
      textAlign: "right",
      fontSize: MAIN_ONE_THEME.typography.small.sm2.fontSize,
      minHeight: "15px",
      display: "block",
      color: MAIN_ONE_THEME.palette.error.main,
      margin: "5px 0 0 ",
    },
    inputProps: {
      fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px !important`,
      width: "100%",
      backgroundColor: material
        ? "white"
        : `${MAIN_ONE_THEME.palette.secondary4.main} `,
      "& .MuiInputBase-input": {
        width: "100%",
        backgroundColor: material
          ? "unset"
          : `${MAIN_ONE_THEME.palette.secondary4.main} `,
        color: MAIN_ONE_THEME.palette.primary2.main,
        margin: "0 auto",
        borderRadius: material ? 0 : 6,
        padding: material
          ? "4px 15px 0px 4px !important"
          : "8px 15px 8px 13px !important",
        fontFamily: contentFontFamilyRegular,
        fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px !important`,
        "&::placeholder": {
          fontStyle: "italic",
          fontFamily: contentFontFamilyRegular,
          paddingLeft: material ? "10px" : "1px",
          color: "rgba(0, 0, 0, 0.45)",
          fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px !important`,
        },
      },

      // delete this because it don't show the error border
      // "& .MuiInputBase-input:focus": {
      //   borderColor: "#D2D2D2",
      //   borderRadius: "0px",
      //   outline: "2px solid #D2D2D2",
      //   fontFamily: contentFontFamilyRegular,
      // },

      "& .MuiFilledInput-root": {
        "& .MuiInputAdornment-root": {
          marginLeft: "-40px",
          "& .MuiSvgIcon-root": {
            // put here the style of icon Buttons
          },
          fontFamily: contentFontFamilyRegular,
          fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px !important`,
        },
      },
      "& .MuiButtonBase-root": {
        marginRight: !material && "1px",
      },
    },
    disabled: {
      "& .MuiInputBase-input.Mui-disabled": {
        cursor: "not-allowed",
        color: MAIN_ONE_THEME.palette.primary2.main,
        fontFamily: contentFontFamilyRegular,
      },
      opacity: 0.4,
      fontFamily: contentFontFamilyRegular,
    },
    errorStyles: {
      borderRadius: material ? 0 : 5,
      "& fieldset": {
        outlineColor: "red !important",
        fontFamily: contentFontFamilyRegular,
      },
      fontFamily: contentFontFamilyRegular,
    },

    borderStyleInput: {
      border: `1px solid ${MAIN_ONE_THEME.palette.secondary3.main}`,
    },

    borderError: {
      border: `1px solid ${MAIN_ONE_THEME.palette.error.main} !important`,
    },
    materialBorder: {
      border: "none",
      borderBottom: `1px solid ${MAIN_ONE_THEME.palette.secondary3.main}`,
    },
    clearButton: {
      cursor: "pointer",
      height: "12px",
      width: "12px",
      position: "absolute",
      right: "8px",
      bottom: "50%",
      top: "calc(50% - 7.5px)",
      color: "rgba(0, 0, 0, 0.25)",
      fontSize: "1.0rem !important",
    },
  })
);

const EnhancedDatePicker: React.FC<IEnhancedDatePickerProps> = ({
  className,
  style,
  name,
  title,
  error,
  disabled,
  value,
  format = "dd/MM/yyyy",
  maxDate,
  minDate,
  material,
  placeholder,
  inputVariant,
  viewInternalLabel,
  InputProps = { disableUnderline: true },
  hidden = false,
  onDateChange,
  onBlur,
  canClearDate = true,
}) => {
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [dateValue, setDateValue] = React.useState<string>(value);
  const { classes } = useStyles({ material });
  const acceptParse = useRef<boolean>(true);
  const chooseFromCalendar = useRef<boolean>(false);

  const handleDateChange = useCallback(
    (date: Date) => {
      if ((date && acceptParse.current) || chooseFromCalendar.current) {
        if (minDate && date < minDate) {
          date = minDate;
        }
        onDateChange(getDefaultDate(dayjs(date).toDate()));
        setDateValue(dayjs(date).toDate().toDateString());
      } else onDateChange("");
    },
    [onDateChange]
  );

  const handleClearDate = (event: React.MouseEvent) => {
    event.stopPropagation();
    setDateValue("");
    onDateChange("");
  };

  const dateLengthEqual = (value: string) => {
    return value.length === format.length;
  };

  return (
    <label
      htmlFor={`${name}-datePicker`}
      className={clsx(className, {
        [classes.labelWrapper]: className,
        [classes.materialInput]: material,
      })}
      style={style}
      hidden={hidden}
    >
      <span className={classes.label}>{title}</span>
      <DatePicker
        open={openDialog}
        value={value || dateValue || null}
        onChange={(e, value) => {
          if (!value && e) {
            chooseFromCalendar.current = true;
          } else if (value && dateLengthEqual(value)) {
            acceptParse.current = true;
          } else {
            acceptParse.current = false;
            chooseFromCalendar.current = false;
          }

          handleDateChange(e);
        }}
        disabled={disabled}
        onClose={() => {
          if (!disabled) setOpenDialog(false);
          onBlur();
        }}
        minDate={minDate}
        maxDate={maxDate}
        inputFormat={format}
        orientation="portrait"
        InputProps={{ ...InputProps }}
        renderInput={(params) => (
          <TextField
            {...params}
            className={clsx(classes.inputProps, {
              [classes.errorStyles]: error,
              [classes.materialBorder]: material,
              [classes.borderStyleInput]: !material,
              [classes.borderError]: !material && error,
            })}
            name={name}
            value={value || dateValue}
            placeholder={placeholder}
            onKeyPressCapture={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                setOpenDialog(true);
              }
            }}
            disabled={disabled}
            hidden={hidden}
            error={!!error}
            label={viewInternalLabel ? title : ""}
            onClickCapture={(e) => {
              if (
                !disabled &&
                (e.target as HTMLElement).tagName !== "svg" &&
                (e.target as HTMLElement).tagName !== "BUTTON"
              ) {
                setOpenDialog(true);
              }
            }}
            variant={
              material ? inputVariant || "filled" : inputVariant || "standard"
            }
            InputProps={{
              ...params.InputProps,
              startAdornment: params.InputProps.startAdornment,
              endAdornment: (
                <>
                  {canClearDate && (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="clear date"
                        onClick={handleClearDate}
                        edge="end"
                        size="small"
                        style={{ marginRight: material ? "30px" : "-17px" }}
                      >
                        <Clear />
                      </IconButton>
                    </InputAdornment>
                  )}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
      <span id={`errorMsg-${name}`} className={classes.error}>
        {error}
      </span>
    </label>
  );
};

export default EnhancedDatePicker;
