import React, { useState } from "react";
import GenericDrawer from "../../components/common/generic-drawer/GenericDrawer";
import DynamicForm from "../../DynamicForm/DynamicForm";
import { EnhancedButtonStatus } from "../../components/common/EnhancedButton";
import { inputs } from "./content";

const ApplicationCopyPolicyDetailsDrawer: React.FC<
  IApplicationCopyPolicyDetailsDrawer
> = ({ open, onSuccess, onClose }) => {
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();

  const submitForm = async (values: Record<string, any>) => {
    setSubmitButtonState("success");
    onSuccess(values.policyNumber);
    onClose();
  };

  return (
    <GenericDrawer
      title={"Copy Policy Details"}
      onClose={() => onClose()}
      isOpen={open}
    >
      <DynamicForm
        inputs={inputs}
        onSubmit={(values) => submitForm(values)}
        buttonText={"Submit"}
        submitButtonState={submitButtonState}
        title="Information"
      />
    </GenericDrawer>
  );
};

export default ApplicationCopyPolicyDetailsDrawer;
