import React, { CSSProperties } from "react";
import { makeStyles } from "tss-react/mui";
import { Step, StepLabel, Stepper, Grid } from "@mui/material";
import { MAIN_ONE_THEME, contentFontFamilyRegular } from "../../constants";
import EnhancedButton, { EnhancedButtonStatus } from "../EnhancedButton";
import { useAppSelector } from "../../redux/hooks";

export interface IEnhancedStepperProps {
  activeStep: string;
  steps: string[];
  className?: string;
  style?: CSSProperties;
  showStepperButton?: boolean;
  buttonTitle?: string;
  buttonOnClick?: () => void;
  buttonColor?: string;
  buttonState?: EnhancedButtonStatus;
}

const useStyles = makeStyles()(() => ({
  stepper: {
    backgroundColor: "transparent",
    padding: 0,
    width: "100%",
  },
  step: {
    "& .MuiStepIcon-root": {
      height: 26,
      width: 26,
      color: MAIN_ONE_THEME.palette.secondary2.main,
    },
    // Update the color to gray
    color: "#777",
    "& .MuiStepIcon-active": {
      color: MAIN_ONE_THEME.palette.primary2.main,
      "& .MuiStepIcon-text": {
        fontSize: MAIN_ONE_THEME.typography.small.sm2.fontSize,
        lineHeight: "12px",
        fill: "white",
      },
    },
    "& .MuiStepConnector-alternativeLabel": {
      left: "calc(-50% + 35px)",
      right: "calc(50% + 35px)",
    },
  },
  stepLabel: {
    "& .MuiStepLabel-label": {
      fontSize: MAIN_ONE_THEME.typography.small.sm2.fontSize,
      lineHeight: "13px",
      fontFamily: contentFontFamilyRegular,
      margin: "1.5em 0 0 0",
    },
  },
}));

const EnhancedStepperSpecificDesign: React.FC<IEnhancedStepperProps> = ({
  steps,
  activeStep,
  className,
  style,
  showStepperButton = false,
  buttonTitle = "Modify",
  buttonOnClick,
  buttonColor = MAIN_ONE_THEME.palette.primary1.main,
  buttonState = undefined,
}) => {
  const { classes } = useStyles();
  const cdnUrl = useAppSelector((state) => state.tenant.cdnUrl);

  // Create a set of completed steps based on the active step
  const completedSteps = steps.slice(0, steps.indexOf(activeStep));

  return (
    <Grid container alignItems="center" spacing={showStepperButton ? 2 : 2}>
      <Grid item xs={showStepperButton ? 10 : 10}>
        <Stepper
          alternativeLabel
          classes={{ root: classes.stepper }}
          className={className}
          style={style}
        >
          {steps.map((label) => {
            const isActive = activeStep === label;
            const isCompleted = completedSteps.includes(label);
            const labelColor = isCompleted
              ? MAIN_ONE_THEME.palette.primary1.main
              : isActive
              ? MAIN_ONE_THEME.palette.primary1.main
              : MAIN_ONE_THEME.palette.secondary3.main;

            return (
              <Step key={label} classes={{ root: classes.step }}>
                <StepLabel
                  classes={{ root: classes.stepLabel }}
                  StepIconComponent={
                    activeStep.includes(label)
                      ? () => (
                          <img
                            src={cdnUrl + "/icons/active-step.svg"}
                            alt="Active"
                            width={33}
                            height={33}
                            style={{ margin: "-3px 0px" }}
                          />
                        )
                      : undefined
                  }
                  StepIconProps={{
                    completed: completedSteps.includes(label) ? false : false,
                  }}
                >
                  <span style={{ color: labelColor }}>{label}</span>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Grid>
      {showStepperButton && (
        <Grid item xs={2} justifyContent="flex-end">
          <EnhancedButton
            type="button"
            backgroundColor={buttonColor}
            color="#FFF"
            onClick={buttonOnClick}
            style={{ marginTop: "-15px" }}
            state={buttonState}
          >
            {buttonTitle}
          </EnhancedButton>
        </Grid>
      )}
    </Grid>
  );
};

export default EnhancedStepperSpecificDesign;
