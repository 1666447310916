import { DynamicFormInputType, FormInputTypes } from '../../DynamicForm';
import { isEmpty } from '../../utils/validationUtils';

export const initialValues = {
  gender: '',
  maritalStatus: '',
  fatherName: '',
  motherName: '',
  countryOfBirth: '',
  cityOfBirth: '',
  nationalityID: '',
  passportNumber: '',
  secondNationality: '',
};

export const inputs: Record<string, DynamicFormInputType> = {
  gender: {
    name: 'gender',
    title: 'Gender',
    type: FormInputTypes.chips,
    placeholder: 'Gender',
    value: !isEmpty(initialValues.gender) ? [initialValues.gender] : null,
    multiple: false,
    hidden: true,
    disabled: true,
  },
  maritalStatus: {
    name: 'maritalStatus',
    title: 'Marital Status',
    placeholder: 'Marital Status',
    type: FormInputTypes.chips,
    value: !isEmpty(initialValues.maritalStatus)
      ? [initialValues.maritalStatus]
      : null,
    multiple: false,
    hidden: true,
    disabled: true,
  },
  fatherName: {
    name: 'fatherName',
    title: 'Father Name',
    placeholder: 'Father Name',
    type: FormInputTypes.text,
    value: initialValues.fatherName,
    minCharacters: 2,
    maxCharacters: 100,
    hidden: true,
    disabled: true,
  },
  motherName: {
    name: 'motherName',
    title: 'Mother Name',
    placeholder: 'Mother Name',
    type: FormInputTypes.text,
    value: initialValues.motherName,
    minCharacters: 2,
    maxCharacters: 100,
    hidden: true,
    disabled: true,
  },
  countryOfBirth: {
    name: 'countryOfBirth',
    title: 'Country of Birth',
    placeholder: 'Country of Birth',
    type: FormInputTypes.chips,
    value: !isEmpty(initialValues.countryOfBirth)
      ? [initialValues.countryOfBirth]
      : null,
    multiple: false,
    hidden: true,
    disabled: true,
  },
  cityOfBirth: {
    name: 'cityOfBirth',
    title: 'City of Birth',
    placeholder: 'City of Birth',
    type: FormInputTypes.text,
    value: initialValues.cityOfBirth,
    minCharacters: 2,
    maxCharacters: 100,
    hidden: true,
    disabled: true,
  },
  nationalityID: {
    name: 'nationalityID',
    title: 'Nationality ID',
    placeholder: 'Nationality ID',
    type: FormInputTypes.text,
    value: initialValues.nationalityID,
    minCharacters: 2,
    maxCharacters: 100,
  },
  passportNumber: {
    name: 'passportNumber',
    title: 'Passport Number',
    type: FormInputTypes.text,
    placeholder: 'Passport Number',
    value: initialValues.passportNumber,
    minCharacters: 2,
    maxCharacters: 100,
    hidden: true,
    disabled: true,
  },
  secondNationality: {
    name: 'secondNationality',
    title: 'Second Nationality',
    type: FormInputTypes.chips,
    placeholder: 'Second Nationality',
    value: !isEmpty(initialValues.secondNationality)
      ? initialValues.secondNationality
      : null,
    multiple: false,
    hidden: true,
    disabled: true,
  },
};
