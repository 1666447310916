import { gql } from '@apollo/client';

export function listQuery() {
  return gql`
    query GET_POLICYNUMBERRANGE(
      $currentPage: Int!
      $currentPageSize: Int!
      $rangeStatus: [Insurance_RangeStatuses]
      $rangeType: Insurance_RangeTypes!
    ) {
      Insurance {
        queries {
          listPolicyNumberRange(
            RangeStatus: $rangeStatus
            RangeType: $rangeType
            pagination: { pageNumber: $currentPage, pageSize: $currentPageSize }
          ) {
            paging {
              pageSize
              pageNumber
              totalCount
            }
            items {
              insurance_PolicyNumberRange_Id
              insurance_PolicyNumberRange_RangeFrom
              insurance_PolicyNumberRange_RangeTo
              insurance_PolicyNumberRange_PolicyNumberReached
              insurance_PolicyNumberRange_RangeStatus
              insurance_PolicyNumberRange_createdOn
              insurance_PolicyNumberRange_modifiedOn
            }
          }
        }
      }
      Insurance_RangeStatuses: __type(name: "Insurance_RangeStatuses") {
        name
        enumValues {
          name
        }
      }
    }
  `;
}
