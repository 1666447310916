import {
  enumListAsRecordObject,
  lookupListAsRecordObject,
} from '../../utils/graph-utils';

export function toLookups(data: any) {
  const businessPartnerDetailsList: Record<string, Record<string, string>> = {};
  businessPartnerDetailsList[data.SalesforceManagement_BusinessPartnerStatuses.name] =
    enumListAsRecordObject(data.SalesforceManagement_BusinessPartnerStatuses.enumValues);

    businessPartnerDetailsList['maxCreditLimitAllowedCurrency'] = lookupListAsRecordObject(
      data.Core.lookups.currencies
    );

    businessPartnerDetailsList['relatedCompany'] = lookupListAsRecordObject(
      data.SalesforceManagement.lookups.relatedCompanies
    );
    businessPartnerDetailsList['type'] = lookupListAsRecordObject(
      data.SalesforceManagement.lookups.businessPartnerTypes
    );
    businessPartnerDetailsList['category'] = lookupListAsRecordObject(
      data.SalesforceManagement.lookups.businessPartnerCategories
    );

  return businessPartnerDetailsList;
}

export function extractPersons(data: any) {
  let persons: Record<string, string> = {};

  data.SalesforceManagement.queries.AllActivePersons.forEach(
    (element: {
      salesforceManagement_Person_Id: string;
      salesforceManagement_Person_FullName: string;
      salesforceManagement_Person_PersonCode: string;
    }) => {
      persons[element.salesforceManagement_Person_Id] = `${element.salesforceManagement_Person_PersonCode} - ${element.salesforceManagement_Person_FullName}`;
    },
  );

  return persons;
}