import React from 'react';
import { IEnhancedCommonProps } from '..';

interface CloseButtonIconProps extends IEnhancedCommonProps {
  closeToast?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const CloseButtonIcon: React.FC<CloseButtonIconProps> = ({
  closeToast,
  className,
}) => {
  return (
    <button
      type="button"
      aria-label="close"
      className={className}
      onClick={closeToast}
    >
      ✖︎
    </button>
  );
};

export default CloseButtonIcon;
