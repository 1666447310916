import { DynamicFormInputType, FormInputTypes } from "../../DynamicForm";
import { isEmpty } from "../../utils/validationUtils";

export const initialValues = {
  policyCoverId: "",
  lineId: "",
  lineName: "",
  policyCoverName: "",
  policyCoverExternalCode: "",
  arabicName: "",
  policyCoverPrintingOrder: "",
  policyCoverDescription: "",
};

export const inputs: Record<string, DynamicFormInputType> = {
  lineName: {
    name: "lineName",
    title: "Line",
    type: FormInputTypes.chips,
    placeholder: "Line",
    value: !isEmpty(initialValues.lineName) ? initialValues.lineName : "",
    disabled: false,
    multiple: false,
  },
  policyCoverName: {
    name: "policyCoverName",
    title: "Policy Cover Name",
    type: FormInputTypes.text,
    placeholder: "Policy Cover Name",
    value: !isEmpty(initialValues.policyCoverName) ? initialValues.policyCoverName : "",
    required: true,
  },
  policyCoverExternalCode: {
    name: "policyCoverExternalCode",
    title: "External Code",
    type: FormInputTypes.text,
    placeholder: "External Code",
    value: !isEmpty(initialValues.policyCoverExternalCode)
      ? initialValues.policyCoverExternalCode
      : "",
    required: true,
  },
  arabicName: {
    name: "arabicName",
    title: "Arabic Name",
    type: FormInputTypes.text,
    placeholder: "Arabic Name",
    value: !isEmpty(initialValues.arabicName) ? initialValues.arabicName : "",
    required: true,
  },
  policyCoverPrintingOrder: {
    name: "policyCoverPrintingOrder",
    title: "Printing Order",
    placeholder: "Printing Order",
    type: FormInputTypes.number,
    value: !isEmpty(initialValues.policyCoverPrintingOrder)
      ? initialValues.policyCoverPrintingOrder
      : [],
    required: true,
  },
  policyCoverDescription: {
    name: "policyCoverDescription",
    title: "Description",
    placeholder: "Description",
    type: FormInputTypes.text,
    value: !isEmpty(initialValues.policyCoverDescription)
      ? initialValues.policyCoverDescription
      : [],
    required: true,
  },
};
