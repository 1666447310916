import React, { CSSProperties } from 'react';
import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';

interface IHeaderContainerProps {
  background: string;
  height: number;
  className?: string;
  style?: CSSProperties;
  children?: React.ReactNode;
}

const useStyles = makeStyles<{ background: string; height: number }>()(
  (theme, { background, height }) => ({
    header: {
      background: background,
      height: height,
      minHeight: height,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'normal',
      overflow: 'hidden',
    },
  }),
);

const HeaderContainer: React.FC<IHeaderContainerProps> = ({
  children,
  className,
  style,
  background,
  height,
}) => {
  const { classes } = useStyles({ background, height });

  return (
    <header className={clsx(classes.header, className)} style={style}>
      {children}
    </header>
  );
};

export default HeaderContainer;
