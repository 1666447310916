export function extractBusinessPartners(data: any) {
  let businessPartners: Record<string, string> = {};

  data.SalesforceManagement.queries.getBusinessPartnerListWithoutPagination?.forEach(
    (element: {
      salesforceManagement_BusinessPartner_Id: string;
      salesforceManagement_BusinessPartner_PersonID: {
        views: {
          SalesforceManagement_all: {
            properties: {
              FullName: string;
            };
          };
        };
      };
    }) => {
      businessPartners[element.salesforceManagement_BusinessPartner_Id] =
        element.salesforceManagement_BusinessPartner_PersonID?.views?.SalesforceManagement_all?.properties?.FullName;
    }
  );

  return businessPartners;
}

export function extractLines(data: any) {
  let lines: Record<string, string> = {};

  data?.SalesforceManagement?.queries?.getBusinessPartnerLinesList?.items?.forEach(
    (element: {
      salesforceManagement_BusinessPartnerLine_Id: string;
      salesforceManagement_BusinessPartnerLine_LineID: {
        views: {
          PlanConfigManagement_all: {
            properties: {
              Name: string;
            };
          };
        };
      };
    }) => {
      lines[element.salesforceManagement_BusinessPartnerLine_Id] =
        element.salesforceManagement_BusinessPartnerLine_LineID.views.PlanConfigManagement_all.properties.Name;
    }
  );

  return lines;
}

export function extractPlans(data: any) {
  let plans: Record<string, string> = {};

  data?.SalesforceManagement?.queries?.getBPPlanListByAssignedLines?.forEach(
    (element: {
      salesforceManagement_BusinessPartnerPlan_Id: string;
      salesforceManagement_BusinessPartnerPlan_PlanID: {
        views: {
          PlanConfigManagement_all: {
            properties: {
              Name: string;
            };
          };
        };
      };
    }) => {
      plans[element.salesforceManagement_BusinessPartnerPlan_Id] =
        element.salesforceManagement_BusinessPartnerPlan_PlanID.views.PlanConfigManagement_all.properties.Name;
    }
  );

  return plans;
}
