import { gql } from '@apollo/client';

export function getPlanEnums() {
  return gql`
    query getPlanEnums($SelectedLineId: [String]) {
      PlanConfigManagement_PlanStatuses: __type(
        name: "PlanConfigManagement_PlanStatuses"
      ) {
        name
        enumValues {
          name
        }
      }
      PlanConfigManagement {
        queries {
          allSublines(SelectedLineId: $SelectedLineId) {
            planConfigManagement_Subline_Id
            planConfigManagement_Subline_Name
            planConfigManagement_Subline_Status
          }
          allLines {
            planConfigManagement_Line_Id
            planConfigManagement_Line_Name
            planConfigManagement_Line_Status
          }
        }
      }
    }
  `;
}

export function relatedSublines() {
  return gql`
    query relatedSublines($id: String!) {
      PlanConfigManagement {
        entities {
          line(id: $id) {
            queries {
              planConfigManagement {
                relatedSublines {
                  subline_Id
                  subline_Name
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function getPlanInfo() {
  return gql`
    query getPlanInfo($id: String!) {
      PlanConfigManagement {
        entities {
          plan(id: $id) {
            id
            views {
              PlanConfigManagement_all {
                properties {
                  Name
                  ExternalCode
                  NameArabic
                  NameFrench
                  Abbreviation
                  EffectiveFrom
                  EffectiveTo
                  PlanStatus
                  SublineID {
                    id
                    views {
                      PlanConfigManagement_all {
                        properties {
                          Name
                          ExternalCode
                        }
                      }
                    }
                  }
                  LineID {
                    id
                    views {
                      PlanConfigManagement_all {
                        properties {
                          Name
                          ExternalCode
                        }
                      }
                    }
                  }
                  createdOn
                  createdBy
                  modifiedOn
                  modifiedBy
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function updatePlan() {
  return gql`
    mutation updatePlan(
      $entityId: String!
      $planDetailInputs: PlanConfigManagement_PlanDetailInputs!
    ) {
      planConfigManagement {
        entities {
          plan {
            planConfigManagement {
              updatePlanDetails(
                entityId: $entityId
                planDetailInputs: $planDetailInputs
              ) {
                id
                views {
                  PlanConfigManagement_all {
                    properties {
                      Name
                      ExternalCode
                      NameArabic
                      NameFrench
                      Abbreviation
                      EffectiveFrom
                      EffectiveTo
                      PlanStatus
                      SublineID {
                        id
                        views {
                          PlanConfigManagement_all {
                            properties {
                              Name
                              ExternalCode
                            }
                          }
                        }
                      }
                      LineID {
                        id
                        views {
                          PlanConfigManagement_all {
                            properties {
                              Name
                              ExternalCode
                            }
                          }
                        }
                      }
                      createdOn
                      createdBy
                      modifiedOn
                      modifiedBy
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function checkPlanExists() {
  return gql`
    query checkPlanExists(
      $name: String
      $externalCode: String
      $planId: String
      $sublineId: String!
    ) {
      PlanConfigManagement {
        queries {
          checkPlanExists(
            name: $name
            externalCode: $externalCode
            planId: $planId
            sublineId: $sublineId
          ) {
            planConfigManagement_Plan_Id
            planConfigManagement_Plan_Name
            planConfigManagement_Plan_ExternalCode
            planConfigManagement_Plan_LineID {
              views {
                PlanConfigManagement_all {
                  properties {
                    Name
                  }
                }
              }
            }
            planConfigManagement_Plan_SublineID {
              id
              views {
                PlanConfigManagement_all {
                  properties {
                    Name
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function createPlan() {
  return gql`
    mutation createPlanDetails(
      $planDetailInputs: PlanConfigManagement_PlanDetailInputs!
    ) {
      planConfigManagement {
        actions {
          createPlanDetails(planDetailInputs: $planDetailInputs) {
            id
            views {
              PlanConfigManagement_all {
                properties {
                  ExternalCode
                  Name
                  NameArabic
                  PlanStatus
                  EffectiveFrom
                  EffectiveTo
                  Abbreviation
                  createdOn
                  modifiedOn
                  SublineID {
                    id
                    views {
                      PlanConfigManagement_all {
                        properties {
                          Name
                        }
                      }
                    }
                  }
                  LineID {
                    id
                    views {
                      PlanConfigManagement_all {
                        properties {
                          ExternalCode
                          Name
                          NameArabic
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}
