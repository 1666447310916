import React, { PropsWithChildren } from 'react';
import { useAppSelector } from '../../redux/hooks';
import { USER_ROLES } from '../../redux/user/types';

interface IShowForUserProps {
  allowedRoles: (keyof typeof USER_ROLES)[];
}

const ShowForUser: React.FC<PropsWithChildren<IShowForUserProps>> = ({
  allowedRoles,
  children,
}) => {
  const user = useAppSelector((state) => state.user);

  return user.userRoles.some((userRole) => allowedRoles.includes(userRole)) ? (
    <>{children}</>
  ) : (
    <></>
  );
};

export default ShowForUser;
