import { EnhancedTableHeader, EnhancedTableHeaderType } from '../../components/enhanced-table';
import { IEntityInfoView } from '../../components/widgets/entity-info/EntityInfoFields';
import { ITabsLayout, LayoutType } from '../../page-layout';

export const entityView: IEntityInfoView = {
  name: 'section1',
  title: 'Business Partner Details',
  iconUrl: '',
  sections: [
    {
      name: 'businessPartnerDetailsSection1',
      title: '',
      properties: {
        name: {
          value: '',
          multiline: false,
          name: 'name',
          title: 'Full Name',
        },
        businessPartnerCode: {
          value: '',
          multiline: false,
          name: 'businessPartnerCode',
          title: 'Business Partner Code',
        },
        dateOfBirth: {
          value: '',
          multiline: false,
          name: 'dateOfBirth',
          title: 'Date Of Birth',
        },
        email: {
          value: '',
          multiline: false,
          name: 'email',
          title: 'Email',
        },
        phoneType: {
          value: '',
          multiline: false,
          name: 'phoneType',
          title: 'Phone Type',
        },
        phoneNumber: {
          value: '',
          multiline: false,
          name: 'phoneNumber',
          title: 'Phone Number',
        },
        taxable : {
          value: '',
          multiline: false,
          name: 'taxable ',
          title: 'Taxable ',
        },
      },
    },
    {
      name: 'businessPartnerDetailsSection2',
      title: '',
      properties: {
        createdBy: {
          value: '',
          multiline: false,
          name: 'createdBy',
          title: 'Created By',
        },
        createdOn: {
          value: '',
          multiline: false,
          name: 'createdOn',
          title: 'Created On',
        },
        modifiedBy: {
          value: '',
          multiline: false,
          name: 'modifiedBy',
          title: 'Modified By',
        },
        modifiedOn: {
          value: '',
          multiline: false,
          name: 'modifiedOn',
          title: 'Modified On',
        },
      },
    },
  ],
  socialMedia: {
    facebook: '',
    linkedIn: '',
    twitter: '',
  },
};

export const businessPartnerTabs: ITabsLayout = {
  typeLogicalId: LayoutType.tabslayout,
  leftColumn: {
    backgroundColor: '#FFFFFF',
    collapsable: false,
    width: 2,
    collapsed: false,
  },
  mainColumn: {
    backgroundColor: '#F7F7F7',
    collapsable: false,
    width: 10,
    collapsed: false,
  },
  rightColumn: {
    backgroundColor: '#FFFFFF',
    collapsable: false,
    width: 0,
    collapsed: false,
  },
  tabs: [
    {
      name: 'summary',
      title: 'Summary',
      iconUrl: '/icons/file-summary-primary.svg',
      widgets: [{}],
    },
    {
      name: 'commissions&Limits',
      title: 'Commissions & Limits',
      iconUrl: '/icons/file-summary-primary.svg',
      widgets: [{}],
    },
    {
      name: 'vignetteRange',
      title: 'Vignette Range',
      iconUrl: '/icons/folder-primary.svg',
      widgets: [{}],
    },
  ],
};

export const headers: Record<string, EnhancedTableHeader> = {
  salesforceManagement_BusinessPartnerLine_LineExternalCode: {
    name: "salesforceManagement_BusinessPartnerLine_LineExternalCode",
    title: "Line Code ",
    type: EnhancedTableHeaderType.Text,
  },
  salesforceManagement_BusinessPartnerLine_LineName: {
    name: "salesforceManagement_BusinessPartnerLine_LineName",
    title: "Line",
    type: EnhancedTableHeaderType.Clickable,
    callback: () => {},
  },
  salesforceManagement_BusinessPartnerLine_LineStatus: {
    name: "salesforceManagement_BusinessPartnerLine_LineStatus",
    title: "Line Status",
    type: EnhancedTableHeaderType.Text,
  },
  salesforceManagement_BusinessPartnerLine_LineCommissionType: {
    name: "salesforceManagement_BusinessPartnerLine_LineCommissionType",
    title: "Commission Type",
    type: EnhancedTableHeaderType.Text,
  },
  salesforceManagement_BusinessPartnerLine_ValueCurrency: {
    name: "salesforceManagement_BusinessPartnerLine_ValueCurrency",
    title: "Commission",
    type: EnhancedTableHeaderType.Text,
  },
  salesforceManagement_BusinessPartnerLine_ApplicableOnTitle: {
    name: "salesforceManagement_BusinessPartnerLine_ApplicableOnTitle",
    title: "Applicable On",
    type: EnhancedTableHeaderType.Text,
  },
  salesforceManagement_BusinessPartnerLine_CreditLimit: {
    name: "salesforceManagement_BusinessPartnerLine_CreditLimit",
    title: "Credit Limit",
    type: EnhancedTableHeaderType.Text,
  },
  salesforceManagement_BusinessPartnerLine_CreditLimitCurrencyTitle: {
    name: "salesforceManagement_BusinessPartnerLine_CreditLimitCurrencyTitle",
    title: "Credit Limit Currency",
    type: EnhancedTableHeaderType.Text,
  },
  salesforceManagement_BusinessPartnerLine_GracePeriod: {
    name: "salesforceManagement_BusinessPartnerLine_GracePeriod",
    title: "Grace Period (in days)",
    type: EnhancedTableHeaderType.Text,
  },
  salesforceManagement_BusinessPartnerLine_Status: {
    name: "salesforceManagement_BusinessPartnerLine_Status",
    title: "",
    type: EnhancedTableHeaderType.Image,
    hasConditionalImage: true,
    variableOneImageKey: "https://ufa-cdn.azureedge.net/ufa-app-portal/images/green-correct-img.png",
    variableTwoImageKey: "https://ufa-cdn.azureedge.net/ufa-app-portal/images/red-clean-img.png",
  },
};

export const assignedPLansHeaders: Record<string, EnhancedTableHeader> = {
  line_ExternalCode: {
    name: "line_ExternalCode",
    title: "Line Code ",
    type: EnhancedTableHeaderType.Text,
  },
  line_Name: {
    name: "line_Name",
    title: "Line",
    type: EnhancedTableHeaderType.Clickable,
    callback: () => {},
  },
  line_Status: {
    name: "line_Status",
    title: "Line Status",
    type: EnhancedTableHeaderType.Text,
  },

  plan_ExternalCode: {
    name: "plan_ExternalCode",
    title: "Plan Code ",
    type: EnhancedTableHeaderType.Text,
  },
  plan_Name: {
    name: "plan_Name",
    title: "Plan",
    type: EnhancedTableHeaderType.Clickable,
    callback: () => {},
  },
  plan_PlanStatus: {
    name: "plan_PlanStatus",
    title: "Plan Status",
    type: EnhancedTableHeaderType.Text,
  },

  salesforceManagement_BusinessPartnerPlan_PlanCommissionTypeTitle: {
    name: "salesforceManagement_BusinessPartnerPlan_PlanCommissionTypeTitle",
    title: "Commission Type",
    type: EnhancedTableHeaderType.Text,
  },
  salesforceManagement_BusinessPartnerPlan_PlanCommissionValue: {
    name: "salesforceManagement_BusinessPartnerPlan_PlanCommissionValue",
    title: "Commission",
    type: EnhancedTableHeaderType.Text,
  },
  salesforceManagement_BusinessPartnerPlan_ApplicableOnTitle: {
    name: "salesforceManagement_BusinessPartnerPlan_ApplicableOnTitle",
    title: "Applicable On",
    type: EnhancedTableHeaderType.Text,
  },
  salesforceManagement_BusinessPartnerPlan_ActiveFrom: {
    name: "salesforceManagement_BusinessPartnerPlan_ActiveFrom",
    title: "Active From",
    type: EnhancedTableHeaderType.Date,
  },
  salesforceManagement_BusinessPartnerPlan_ActiveTo: {
    name: "salesforceManagement_BusinessPartnerPlan_ActiveTo",
    title: "Active To",
    type: EnhancedTableHeaderType.Date,
  },
  salesforceManagement_BusinessPartnerPlan_Status: {
    name: "salesforceManagement_BusinessPartnerPlan_Status",
    title: "",
    type: EnhancedTableHeaderType.Image,
    hasConditionalImage: true,
    variableOneImageKey: "https://ufa-cdn.azureedge.net/ufa-app-portal/images/green-correct-img.png",
    variableTwoImageKey: "https://ufa-cdn.azureedge.net/ufa-app-portal/images/red-clean-img.png",
  },
};

export const vignetteRangeheaders: Record<string, EnhancedTableHeader> = {
  salesforceManagement_BusinessPartnerVignetteRange_RangeYear: {
    name: "salesforceManagement_BusinessPartnerVignetteRange_RangeYear",
    title: "Vignette Range Year",
    type: EnhancedTableHeaderType.Text,
  },
  salesforceManagement_BusinessPartnerVignetteRange_RangeCode: {
    name: "salesforceManagement_BusinessPartnerVignetteRange_RangeCode",
    title: "Vignette Range Code",
    type: EnhancedTableHeaderType.Text,
  },
  salesforceManagement_BusinessPartnerVignetteRange_RangeFrom: {
    name: "salesforceManagement_BusinessPartnerVignetteRange_RangeFrom",
    title: "Vignette Range Fom",
    type: EnhancedTableHeaderType.Text,
  },
  salesforceManagement_BusinessPartnerVignetteRange_RangeTo: {
    name: "salesforceManagement_BusinessPartnerVignetteRange_RangeTo",
    title: "Vignette Range To",
    type: EnhancedTableHeaderType.Text,
  },
  salesforceManagement_BusinessPartnerVignetteRange_RangeStatus: {
    name: "salesforceManagement_BusinessPartnerVignetteRange_RangeStatus",
    title: "Vignette Range Status",
    type: EnhancedTableHeaderType.Text,
  },
};
