import { gql } from '@apollo/client';

export function listQuery() {
  return gql`
    query LIST_EXPATAPPLICATIONS(
      $currentPage: Int!
      $currentPageSize: Int!
      $agencyId: [String]
      $effectiveDateFrom: Date
      $effectiveDateTo: Date
      $createdDateFrom: Date
      $createdDateTo: Date
      $selectedStatuses: [Insurance_ApplicationStatuses]
    ) {
      Insurance {
        queries {
          listApplicationDetailsExpat(
            pagination: { pageNumber: $currentPage, pageSize: $currentPageSize }
            AssignedAgencyID: $agencyId
            EffectiveDateFrom: $effectiveDateFrom
            EffectiveDateTo: $effectiveDateTo
            CreatedOnFrom: $createdDateFrom
            CreatedOnTo: $createdDateTo
            selectedStatuses: $selectedStatuses
          ) {
            paging {
              pageSize
              pageNumber
              totalCount
            }
            items {
              insurance_Application_Id
              insurance_Application_ApplicationName
              insuranceCustomer_FullName
              insuranceApplicationExpatDetails_InsuredName
              insurancePolicy_PolicyNumber
              insuranceQuote_TotalAnnualPremium
              insuranceAgency_AgencyName
              insuranceAgent_FirstName
              insuranceAgent_LastName
              insurance_Application_createdOn
              insurance_Application_modifiedOn
              insurance_Application_EffectiveDate
              insurance_Application_ExpiryDate
              insurance_Application_Status
              insurance_Application_StatusReason

              insurance_Application_Policies {
                EntityId
                Title
              }
            }
          }
        }
        actions {
          able_To_CreateApplicationAsAgent
          able_To_CreateApplicationAsUnderwriter
        }
      }
    }
  `;
}

export function filterLovsQuery() {
  return gql`
    query EXPAT_APPLICATIONS_LISTING_LOVS {
      Insurance_ApplicationStatuses: __type(name: "Insurance_ApplicationStatuses") {
        name
        enumValues {
          name
        }
      }
      Insurance {
        queries {
          allAgencies {
            insurance_Agency_Id
            insurance_Agency_AgencyName
          }
        }
        lookups {
          products(Insurance_LinesOfBusiness: "Expat") {
            Id
            Code
            Title
          }
        }
      }
    }
  `;
}

export function createActionAsAgent() {
  return gql`
    mutation createActionAsAgent(
      $agencyId: String
      $agentId: String
      $lineOfBusiness: String!
    ) {
      insurance {
        actions {
          createApplicationAsAgent(
            lineOfBusiness: $lineOfBusiness
            agencyId: $agencyId
            agentId: $agentId
          ) {
            id
          }
        }
      }
    }
  `;
}