import { DynamicFormInputType, FormInputTypes } from "../../DynamicForm";
import { REGEX, isEmpty } from "../../utils/validationUtils";

export const HEADQUARTER = "Headquarter";

export const initialValues = {
  agentInfo: {
    agentId: "",
    name: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    ufaCode: "",
    mobileNumber: "",
    type: "EXTERNAL",
    agentAccess: "",
    status: "INACTIVE",
    linesOfBusiness: [] as string[],
    products: [] as string[],
    eligiblePlans: [] as string[],
    agentICCNumber: "",
    agentRegistrationNumber: "",
    agentRegistrationExpiryDate: "",
    agentAddress: "",
    canViewCommission: false,
  },
};

export const inputs: Record<string, DynamicFormInputType & { order: number }> =
  {
    agencyName: {
      name: "agencyName",
      title: "Business Partner",
      type: FormInputTypes.chips,
      placeholder: "Business Partner",
      value: "",
      required: true,
      multiple: true,
      allowDeleteValue: false,
      order: 1,
      showSelectAll: true
    },
    firstName: {
      name: "firstName",
      title: "First Name",
      placeholder: "First Name",
      type: FormInputTypes.text,
      value: initialValues.agentInfo.firstName,
      required: true,
      order: 2,
    },
    lastName: {
      name: "lastName",
      title: "Last Name",
      placeholder: "Last Name",
      type: FormInputTypes.text,
      value: initialValues.agentInfo.lastName,
      required: true,
      order: 3,
    },
    email: {
      name: "email",
      title: "Email",
      placeholder: "Email",
      type: FormInputTypes.text,
      value: initialValues.agentInfo.email,
      required: true,
      regex: REGEX.email,
      regexError: "Invalid Email",
      order: 4,
    },
    agentPassword: {
      name: "agentPassword",
      title: "Password",
      placeholder: "Password",
      type: FormInputTypes.password,
      value: initialValues.agentInfo.password,
      includePasswordHint: true,
      includePasswordVisibility: true,
      regex: REGEX.password,
      order: 5,
      autoCompelte: "new-password",
    },
    ufaCode: {
      name: "ufaCode",
      title: "UFA Code",
      placeholder: "UFA Code",
      type: FormInputTypes.text,
      value: initialValues.agentInfo.ufaCode,
      required: true,
      order: 6,
    },
    mobileNumber: {
      name: "mobileNumber",
      title: "Mobile Number",
      placeholder: "Mobile Number",
      type: FormInputTypes.phoneNumber,
      value: initialValues.agentInfo.mobileNumber,
      required: true,
      editCountryCode: false,
      defaultCountry: "lb",
      disableDropDown: true,
      customFormat: { lb: ".. ......" },
      order: 7,
    },
    agentAddress: {
      name: "agentAddress",
      title: "Address",
      placeholder: "Address",
      type: FormInputTypes.text,
      value: initialValues.agentInfo.agentAddress,
      required: true,
      order: 8,
    },
    agentType: {
      name: "agentType",
      title: "Type",
      placeholder: "Type",
      type: FormInputTypes.chips,
      value: !isEmpty(initialValues.agentInfo.type)
        ? initialValues.agentInfo.type
        : [],
      required: true,
      multiple: false,
      allowDeleteValue: false,
      order: 9,
    },
    agentAccess: {
      name: "agentAccess",
      title: "Access",
      placeholder: "Access",
      type: FormInputTypes.chips,
      value: !isEmpty(initialValues.agentInfo.agentAccess)
        ? initialValues.agentInfo.agentAccess
        : [],
      required: true,
      multiple: true,
      allowDeleteValue: false,
      order: 10,
    },
    linesOfBusiness: {
      name: "linesOfBusiness",
      title: "Line Of Business",
      placeholder: "Line Of Business",
      type: FormInputTypes.chips,
      value: initialValues.agentInfo.linesOfBusiness,
      required: true,
      multiple: true,
      keyToClear: "products",
      order: 11,
    },
    products: {
      name: "products",
      title: "Products",
      placeholder: "Products",
      type: FormInputTypes.chips,
      value: initialValues.agentInfo.products,
      required: true,
      multiple: true,
      allowDeleteValue: true,
      keyToClear: "eligiblePlans",
      order: 12,
    },
    eligiblePlans: {
      name: "eligiblePlans",
      title: "Plans",
      placeholder: "Plans",
      type: FormInputTypes.chips,
      value: initialValues.agentInfo.eligiblePlans,
      required: false,
      multiple: true,
      showSelectAll: true,
      strongStyledOption: true,
      allowDeleteValue: true,
      order: 13,
    },
    agentStatus: {
      name: "agentStatus",
      title: "Status",
      placeholder: "Status",
      type: FormInputTypes.chips,
      value: !isEmpty(initialValues.agentInfo.status)
        ? initialValues.agentInfo.status
        : [],
      required: true,
      multiple: false,
      allowDeleteValue: false,
      order: 14,
    },
    agentICCNumber: {
      name: "agentICCNumber",
      title: "ICC Number",
      placeholder: "ICC Number",
      type: FormInputTypes.text,
      value: initialValues.agentInfo.agentICCNumber,
      required: false,
      hidden: true,
      order: 15,
    },
    agentRegistrationNumber: {
      name: "agentRegistrationNumber",
      title: "Registration Number",
      placeholder: "Registration Number",
      type: FormInputTypes.text,
      value: initialValues.agentInfo.agentRegistrationNumber,
      required: false,
      hidden: true,
      order: 16,
    },
    agentRegistrationExpiryDate: {
      name: "agentRegistrationExpiryDate",
      title: "Registration Expiry Date",
      placeholder: "Registration Expiry Date",
      type: FormInputTypes.date,
      value: initialValues.agentInfo.agentRegistrationExpiryDate,
      required: false,
      hidden: true,
      order: 17,
    },
    canViewCommission: {
      name: "canViewCommission",
      title: "View Commission",
      placeholder: "View Commission",
      type: FormInputTypes.switch,
      value: initialValues.agentInfo.canViewCommission,
      required: true,
      order: 18,
    },
  };
