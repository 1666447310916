import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import EnhancedButton from '../components/EnhancedButton';
import EnhancedChipInput from '../components/enhanced-form/EnhancedChipInput';
import EnhancedDatePicker from '../components/enhanced-form/EnhancedDatePicker';
import EnhancedInput from '../components/enhanced-form/EnhancedInput';
import EnhancedInternationalPhoneInput from '../components/enhanced-form/EnhancedInternationalPhoneInput';
import EnhancedNumberInput from '../components/enhanced-form/EnhancedNumberInput';
import EnhancedSwitch from '../components/enhanced-form/EnhancedSwitch';
import EnhancedUploader from '../components/enhanced-form/EnhancedUploader';
import { MAIN_ONE_THEME, mainFontFamilyBold } from '../constants';
import { useAppSelector } from '../redux/hooks';
import { validateDynamicForm } from '../utils/dynamic-utils';
import { isEmpty } from '../utils/validationUtils';
import {
  DynamicFormInputType,
  FormInputTypes,
  IDynamicSection,
  IDynamicWithSectionsForm,
} from './index';
import EnhancedCurrencyInput from '../components/enhanced-form/EnhancedCurrencyInput';
import EnhancedPercentageInput from '../components/enhanced-form/EnhancedPercentageInput';

const useStyles = makeStyles<{ secondaryButton: boolean }>()(
  (theme, { secondaryButton }) => ({
    form: {
      width: '100%',
    },
    submitBtn: {
      margin: '0 auto 25px auto!important',
      display: 'flex!important',
    },
    footerContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: secondaryButton ? 'space-between' : 'center',
    },
    titleStyling: {
      fontFamily: mainFontFamilyBold,
      lineHeight: '18px',
    },
  })
);

const SectionDynamicForm: React.FC<
  IDynamicWithSectionsForm & {
    children?: React.ReactNode;
  }
> = ({
  title,
  sections,
  buttonText,
  onSubmit,
  disableForm = false,
  submitButtonState = null,
  isSubmitButtonDisabled = false,
  children,
  secondaryButton = false,
  secondaryButtonText = '',
  onClickSecondaryButton = () => {},
  mainButtonBgColor = '',
  loadingFields = {},
  customStyles,
  onChange,
  ...restOfProps
}) => {
  const tenant = useAppSelector((state) => state.tenant);

  const [values, setValues] = useState<Record<string, any>>();
  const [errors, setErrors] = useState<Record<string, any>>();
  const [touched, setTouched] = useState<Record<string, boolean>>();

  const { classes } = useStyles({ secondaryButton });

  const inputs = useMemo(() => {
    let inputs: Record<string, DynamicFormInputType> = {};

    Object.values(sections).forEach((section) => {
      inputs = { ...inputs, ...section.inputs };
    });
    return inputs;
  }, [sections]);

  useEffect(() => {
    const initialValues: Record<string, any> = {};
    const initialErrors: Record<string, string> = {};
    const initialTouched: Record<string, boolean> = {};
    let sectionInputs: Record<string, DynamicFormInputType> = {};

    Object.values(sections).forEach((section) => {
      sectionInputs = { ...sectionInputs, ...section.inputs };
    });

    Object.values(sectionInputs).forEach((input) => {
      initialValues[input.name] = input.value;
      initialErrors[input.name] = input.error || '';
      initialTouched[input.name] = !!input.error;
    });
    setValues(initialValues);
    setErrors(initialErrors);
    setTouched(initialTouched);
  }, [sections]);

  const onFieldBlur = (name: string) => {
    let touchedTemp = Object.assign({}, touched);
    touchedTemp[name] = true;
    setTouched(touchedTemp);
  };

  const onFieldChange = (name: string, value: any) => {
    let errorsTemp = Object.assign({}, errors);
    let valuesTemp = Object.assign({}, values);
    valuesTemp[name] = value;
    errorsTemp[name] = validateDynamicForm(value, inputs[name], values);
    if (onChange) {
      onChange(name, value, valuesTemp);
    }
    setValues(valuesTemp);
    setErrors(errorsTemp);
  };

  const onFieldChangeByEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    onFieldChange(event.target.name, event.target.value);
  };

  const validate = (): Record<string, string> => {
    const newErrors: Record<string, string> = _.cloneDeep(errors);
    Object.values(inputs).forEach((input) => {
      newErrors[input.name] = validateDynamicForm(
        values[input.name],
        input,
        values
      );
    });
    return newErrors;
  };

  const submit = () => {
    if (!disableForm) {
      const validateErrors = validate();
      const isValid = Object.values(validateErrors).every((v) => isEmpty(v));
      if (isValid) {
        onSubmit(values);
      } else {
        const initialTouched: Record<string, boolean> = {};
        Object.values(inputs).forEach((input) => {
          initialTouched[input.name] = true;
        });
        setErrors(validateErrors);
        setTouched(initialTouched);
      }
    }
  };

  const renderInput = (input: DynamicFormInputType) => {
    if (input.hidden) {
      return <></>;
    }
    const title =
      input.required && !isEmpty(input.title) ? `${input.title}*` : input.title;

    switch (input.type) {
      case FormInputTypes.text:
        return (
          <EnhancedInput
            key={input.name}
            name={input.name}
            title={title}
            type='text'
            placeholder={input.placeholder}
            value={values[input.name]}
            error={errors[input.name]}
            onBlur={(event) => {
              // Extract the new value when the input FOCUS OUT
              const newValue = event.target.value;
              // Call the onFieldBlur function for addition handling the BLUR
              onFieldBlur(input.name);
              // The needed logic to catch the final value
              if (!isEmpty(newValue)) {
                onFieldChange(input.name, newValue);
                if (input.onChange) {
                  input.onChange(event);
                }
              }
            }}
            onChange={(v) => onFieldChange(input.name, v.target.value)}
            disabled={input.disabled || disableForm}
            description={input.description}
            multiline={input.multiline}
            material={input.material}
            customStyles={input.customStyles}
          />
        );

      case FormInputTypes.password:
        return (
          <EnhancedInput
            key={input.name}
            name={input.name}
            title={title}
            type='password'
            placeholder={input.placeholder}
            value={values[input.name]}
            error={errors[input.name]}
            onBlur={(v) => onFieldBlur(input.name)}
            onChange={(v) => onFieldChange(input.name, v.target.value)}
            disabled={input.disabled || disableForm}
            material={input.material}
            description={input.description}
            includePasswordhint={input.includePasswordHint}
            includePasswordVisibility={input.includePasswordVisibility}
            customStyles={input.customStyles}
          />
        );

      case FormInputTypes.date:
        return (
          <EnhancedDatePicker
            key={input.name}
            name={input.name}
            title={title}
            value={values[input.name]}
            error={errors[input.name]}
            onBlur={() => onFieldBlur(input.name)}
            onDateChange={(dateValue) => {
              onFieldChange(input.name, dateValue);

              input.value = dateValue;

              if (input.onChange) {
                input.onChange(dateValue);
              }
            }}
            disabled={input.disabled || disableForm}
            material={input.material}
            minDate={input.minDate}
            maxDate={input.maxDate}
            placeholder={input.placeholder}
            format={tenant.dateFormat}
            canClearDate={input.canClearDate}
          />
        );

      case FormInputTypes.imageFile:
        return (
          <EnhancedUploader
            key={input.name}
            name={input.name}
            title={title}
            value={values[input.name]}
            error={errors[input.name]}
            onBlur={(v) => onFieldBlur(input.name)}
            onUpload={(v) => onFieldChange(input.name, v)}
            disabled={input.disabled || disableForm}
            allowedFileTypes={input.allowedFileTypes}
            type={''}
          />
        );

      case FormInputTypes.select:
        return <span>deprecated</span>;
      case FormInputTypes.number:
        return (
          <EnhancedNumberInput
            key={input.name}
            name={input.name}
            title={title}
            placeholder={input.placeholder}
            value={values[input.name]}
            error={errors[input.name]}
            onBlur={(v) => onFieldBlur(input.name)}
            onChange={(v) => onFieldChangeByEvent(v)}
            disabled={input.disabled || disableForm}
            material={input.material}
            minValue={input.minNumber}
            maxValue={input.maxNumber}
          />
        );
      case FormInputTypes.percentage:
        return (
          <EnhancedPercentageInput
            key={input.name}
            name={input.name}
            title={title}
            placeholder={input.placeholder}
            value={values[input.name]}
            error={errors[input.name]}
            onBlur={(v) => onFieldBlur(input.name)}
            onChange={(v) => onFieldChangeByEvent(v)}
            disabled={input.disabled || disableForm}
            material={input.material}
          />
        );

      case FormInputTypes.switch:
        return (
          <EnhancedSwitch
            key={input.name}
            name={input.name}
            title={title}
            value={values[input.name]}
            onBlur={(v) => onFieldBlur(input.name)}
            onChange={(name, val) => onFieldChange(input.name, val)}
            disabled={input.disabled || disableForm}
            required={input.required}
          />
        );

      case FormInputTypes.multiSelect:
        return <span>deprecated</span>;
      case FormInputTypes.chips:
        return (
          <EnhancedChipInput
            key={input.name}
            name={input.name}
            title={title}
            placeholder={input.placeholder}
            value={values[input.name]}
            error={errors[input.name]}
            onChange={(v) => {
              onFieldChange(input.name, v);
              if (!isEmpty(v) && input.onSelect != undefined) {
                input.onSelect(v as string);
              }
            }}
            disabled={input.disabled || disableForm}
            customStyles={input.customStyles}
            selectOptions={input.selectOptions}
            required={input.required}
            freeSolo={input.freeSolo}
            multiple={input.multiple}
            material={input.material}
            loader={loadingFields[input.name] || false}
            canClearSingleValueSelection={input.canClearSingleValueSelection}
          />
        );
      case FormInputTypes.phoneNumber:
        return (
          <EnhancedInternationalPhoneInput
            key={input.name}
            name={input.name}
            title={title}
            placeholder={input.placeholder}
            value={values[input.name]}
            error={errors[input.name]}
            onChange={(v) => onFieldChange(input.name, v)}
            onBlur={(v) => onFieldBlur(input.name)}
            disabled={input.disabled || disableForm}
            countriesToShow={input.countriesToShow}
            disableDropDown={input.disableDropDown}
            editCountryCode={input.editCountryCode}
            defaultCountry={input.defaultCountry}
            customFormat={input.customFormat}
            material={input.material}
          />
        );
        case FormInputTypes.currency:
          return (
            <EnhancedCurrencyInput
            key={input.name}
            name={input.name}
            title={title}
            placeholder={input.placeholder}
            value={values[input.name]}
            error={touched[input.name] ? errors[input.name] : ''}
            onBlur={(v) => onFieldBlur(input.name)}
            onChange={(v) => onFieldChangeByEvent(v)}
            disabled={input.disabled || disableForm}
            type={''}
            currencyTitle='$'
            currencyIcon={tenant.cdnUrl + '/icons/dollar-primary.svg'}
            onFocus={(v) => onFieldBlur(input.name)}
          />
          );
        default:
        return <></>;
    }
  };

  const renderSection = (section: IDynamicSection) => {
    return (
      <>
        <div className={classes.titleStyling}>
          <p>{section.hasTitleSpecificDesign ? section.specificTitleDesign() : section.title}</p>
          <hr style={{ opacity: '0.25' }} />
          <br />
        </div>
        {Object.values(section.inputs).map((input) => renderInput(input))}
      </>
    );
  };

  return values ? (
    <form
      className={classes.form}
      onSubmit={(e) => {
        e.preventDefault();
        submit();
      }}
    >
      <p>{title}</p>
      {Object.values(sections).map((section) => renderSection(section))}

      {children}
      <div className={classes.footerContainer}>
        {secondaryButton && (
          <EnhancedButton
            onClick={() => onClickSecondaryButton()}
            disabled={disableForm}
            variant='contained'
            className={classes.submitBtn}
            state={submitButtonState}
          >
            {secondaryButtonText}
          </EnhancedButton>
        )}

        <EnhancedButton
          type='submit'
          disabled={disableForm || isSubmitButtonDisabled}
          variant='contained'
          backgroundColor={
            mainButtonBgColor
              ? mainButtonBgColor
              : MAIN_ONE_THEME.palette.primary5.main
          }
          color='rgba(255, 255, 255, 1)'
          style={{ width: '108px' }}
          state={submitButtonState}
          className={customStyles && customStyles.submitButtonStyles}
        >
          {buttonText}
        </EnhancedButton>
      </div>
    </form>
  ) : (
    <></>
  );
};

export default SectionDynamicForm;
