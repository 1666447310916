import { gql } from "@apollo/client";

export function getAdditionalInformationEnums() {
  return gql`
    query getAdditionalInformationEnums {
      Insurance_Genders: __type(name: "Insurance_Genders") {
        name
        enumValues {
          name
        }
      }
      Core_MaritalStatuses: __type(name: "Core_MaritalStatuses") {
        name
        enumValues {
          name
        }
      }
      Insurance {
        lookups {
          countries {
            Id
            Title
          }
          nationalities {
            Id
            Title
          }
        }
      }
    }
  `;
}

export function getAdditionalInfoPersonDetailsInfo() {
  return gql`
    query getAdditionalInfoPersonDetailsInfo($id: String!) {
      SalesforceManagement {
        entities {
          person(id: $id) {
            views {
              SalesforceManagement_all {
                properties {
                  Gender
                  MaritalStatus
                  FatherName
                  MotherName
                  CountryOfBirth {
                    Id
                    Title
                  }
                  CityOfBirth
                  NationalityOne {
                    Id
                    Title
                  }
                  NationalityTwo {
                    Id
                    Title
                  }
                  NationalityID
                  PassportNumber
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function modifyAdditionalInfo() {
  return gql`
    mutation modifyAdditionalInfo(
      $entityId: String!
      $additionalInfoInputs: SalesforceManagement_PersonAdditionalInfoInputs!
    ) {
      salesforceManagement {
        entities {
          person {
            salesforceManagement {
              updatePersonAdditionalInfo(
                entityId: $entityId
                additionalInfoInputs: $additionalInfoInputs
              ) {
                id
              }
            }
          }
        }
      }
    }
  `;
}
