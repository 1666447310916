import { enumListAsRecordObject } from "../../utils/graph-utils";

export function VignetteLookupsToList(data: any) {
  const vigentteLookups: Record<string, Record<string, string>> = {};

  vigentteLookups[data.Insurance_VignetteRangeStatuses.name] =
    enumListAsRecordObject(data.Insurance_VignetteRangeStatuses.enumValues);

  return vigentteLookups;
}
