import { IAbstractRecord } from '../../models';
import { IListingData } from '../../models/listing';
import { capitalizeFirstLetter } from '../../utils/formatting-utils';

export function mapToListingData(data: any): IListingData<any> {
  const list: IAbstractRecord = {};

  data.PlanConfigManagement.queries.getLineList.items.forEach(
    (obj: IGenericLinesTableData, i: number) => {
      list[i] = {
        id: obj.planConfigManagement_Line_Id,
        lineName: obj.planConfigManagement_Line_Name,
        externalCode: obj.planConfigManagement_Line_ExternalCode,
        abbreviation: obj.planConfigManagement_Line_Abbreviation,
        arabicName: obj.planConfigManagement_Line_NameArabic,
        createdOn: obj.planConfigManagement_Line_createdOn,
        modifiedOn: obj.planConfigManagement_Line_modifiedOn,
        status: capitalizeFirstLetter(
          obj.planConfigManagement_Line_Status?.replaceAll('_', ' ')
        ),
      };
    }
  );

  return {
    pagedItems: list,
    pageSize: data.PlanConfigManagement.queries.getLineList?.paging?.pageSize,
    pageNumber:
      data.PlanConfigManagement.queries.getLineList?.paging?.pageNumber,
    totalCount:
      data.PlanConfigManagement.queries.getLineList?.paging?.totalCount,
  };
}