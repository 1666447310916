import {
  IGenericAssignedLinesTableData,
  IGenericAssignedPlansTableData,
  IGenericVignetteRangesTableData,
} from ".";
import { IAbstractRecord } from "../../models";
import { IListingData } from "../../models/listing";
import {
  capitalizeFirstLetter,
  valueCommaSeparated,
} from "../../utils/formatting-utils";

export function entityToBusinessPartnerDetails(data: any): any {
  const businessPartnerDetailsInfo =
    data?.SalesforceManagement?.entities?.businessPartner?.views
      ?.SalesforceManagement_all?.properties;
  const personDetailsInfo =
    businessPartnerDetailsInfo.PersonID?.views?.SalesforceManagement_all
      ?.properties;
  const businessPartnerRetaledUsersInfo =
    data?.SalesforceManagement?.entities?.businessPartner?.queries
      ?.salesforceManagement?.relatedUsers[0];

  return {
    name: personDetailsInfo?.FullName,
    businessPartnerCode: businessPartnerDetailsInfo?.Code,
    personCode: personDetailsInfo?.PersonCode,
    personId: businessPartnerDetailsInfo?.PersonID?.id,
    dateOfBirth: personDetailsInfo?.DateOfBirth,
    email: personDetailsInfo?.Email,
    phoneNumber: personDetailsInfo?.PhoneDetails?.PhoneNumber,
    phoneType: capitalizeFirstLetter(
      personDetailsInfo?.PhoneDetails?.PhoneType
    ),
    taxable: personDetailsInfo?.IsTaxable ? "Yes" : "No",

    createdBy: `${businessPartnerRetaledUsersInfo?.createdBy_firstName} ${businessPartnerRetaledUsersInfo?.createdBy_lastName}`,
    createdOn: businessPartnerDetailsInfo?.createdOn,
    modifiedBy: `${businessPartnerRetaledUsersInfo?.modifiedBy_firstName} ${businessPartnerRetaledUsersInfo?.modifiedBy_lastName}`,
    modifiedOn: businessPartnerDetailsInfo?.modifiedOn,

    type: businessPartnerDetailsInfo.Type?.Id,
    typeTitle: businessPartnerDetailsInfo.Type?.Title,
    taxAccount: businessPartnerDetailsInfo.TaxAccount,
    account: businessPartnerDetailsInfo.Account,
    registerNumber: businessPartnerDetailsInfo.RegisterNumber,
    isRegistered: businessPartnerDetailsInfo.IsRegistered,
    isLocal: businessPartnerDetailsInfo.IsLocal,
    isCompany: businessPartnerDetailsInfo.IsCompany,
    category: businessPartnerDetailsInfo.Category?.Id,
    categoryTitle: businessPartnerDetailsInfo.Category?.Title,
    relatedCompany: businessPartnerDetailsInfo.RelatedCompany?.Id,
    relatedCompanyTitle: businessPartnerDetailsInfo.RelatedCompany?.Title,

    SalesforceManagement_BusinessPartnerStatuses:
      businessPartnerDetailsInfo.Status,
    maxCreditLimitAllowedCurrency:
      businessPartnerDetailsInfo.MaxCreditLimitAllowedCurrency?.Id,
    maxCreditLimitAllowedCurrencyTitle:
      businessPartnerDetailsInfo.MaxCreditLimitAllowedCurrency?.Title,
    maxCreditLimitAllowed: businessPartnerDetailsInfo.MaxCreditLimitAllowed,
    maxCreditLimitAllowedShow: valueCommaSeparated(
      businessPartnerDetailsInfo.MaxCreditLimitAllowed
    ),
  };
}

export const getBusinessPartnerStatus = (steps: string[], status: string) => {
  switch (status) {
    case "active":
      return steps[1];
    case "inactive":
      return steps[1];
    default:
      return steps[0];
  }
};

export function mapToAssignedLinesListingData(data: any): IListingData<any> {
  const list: IAbstractRecord = {};

  data.SalesforceManagement.queries.getBusinessPartnerLinesList.items.forEach(
    (obj: IGenericAssignedLinesTableData, i: number) => {
      list[i] = {
        salesforceManagement_BusinessPartnerLine_LineId:
          obj.salesforceManagement_BusinessPartnerLine_LineID?.id,
        salesforceManagement_BusinessPartnerLine_Id:
          obj.salesforceManagement_BusinessPartnerLine_Id,
        salesforceManagement_BusinessPartnerLine_LineExternalCode:
          obj.salesforceManagement_BusinessPartnerLine_LineID?.views
            ?.PlanConfigManagement_all.properties?.ExternalCode,
        salesforceManagement_BusinessPartnerLine_LineName:
          obj.salesforceManagement_BusinessPartnerLine_LineID?.views
            ?.PlanConfigManagement_all.properties?.Name,
        salesforceManagement_BusinessPartnerLine_LineStatus:
          capitalizeFirstLetter(
            obj.salesforceManagement_BusinessPartnerLine_LineID?.views
              ?.PlanConfigManagement_all.properties?.Status
          ),
        salesforceManagement_BusinessPartnerLine_LineCommissionType:
          capitalizeFirstLetter(
            obj.salesforceManagement_BusinessPartnerLine_LineCommissionType
          ),
        salesforceManagement_BusinessPartnerLine_ValueCurrency:
          obj.salesforceManagement_BusinessPartnerLine_LineCommissionType ===
          "VALUE"
            ? obj.salesforceManagement_BusinessPartnerLine_LineCommissionValue !==
              null
              ? `${valueCommaSeparated(
                  obj.salesforceManagement_BusinessPartnerLine_LineCommissionValue
                )} ${
                  obj.salesforceManagement_BusinessPartnerLine_ValueCurrency
                    ?.Title
                }`
              : ""
            : obj.salesforceManagement_BusinessPartnerLine_LineCommissionRate !==
              null
            ? `${Math.round(
                parseFloat(
                  (
                    Number(
                      obj.salesforceManagement_BusinessPartnerLine_LineCommissionRate
                    ) * 100
                  ).toFixed(10)
                )
              )}%`
            : "",
        salesforceManagement_BusinessPartnerLine_ApplicableOnTitle:
          obj.salesforceManagement_BusinessPartnerLine_ApplicableOn?.Title,
        salesforceManagement_BusinessPartnerLine_ApplicableOn:
          obj.salesforceManagement_BusinessPartnerLine_ApplicableOn?.Id,
        salesforceManagement_BusinessPartnerLine_createdOn:
          obj.salesforceManagement_BusinessPartnerLine_createdOn,
        salesforceManagement_BusinessPartnerLine_modifiedOn:
          obj.salesforceManagement_BusinessPartnerLine_modifiedOn,
        salesforceManagement_BusinessPartnerLine_Status: capitalizeFirstLetter(
          obj.salesforceManagement_BusinessPartnerLine_Status?.replaceAll(
            "_",
            " "
          )
        ),

        salesforceManagement_BusinessPartnerLine_CreditLimit:
          valueCommaSeparated(
            obj.salesforceManagement_BusinessPartnerLine_CreditLimit
          ),
        salesforceManagement_BusinessPartnerLine_CreditLimitCurrency:
          obj.salesforceManagement_BusinessPartnerLine_CreditLimitCurrency?.Id,
        salesforceManagement_BusinessPartnerLine_CreditLimitCurrencyTitle:
          obj.salesforceManagement_BusinessPartnerLine_CreditLimitCurrency
            ?.Title,
        salesforceManagement_BusinessPartnerLine_GracePeriod:
          obj.salesforceManagement_BusinessPartnerLine_GracePeriod,
      };
    }
  );

  return {
    pagedItems: list,
    pageSize:
      data?.SalesforceManagement?.queries?.getBusinessPartnerLinesList?.paging
        ?.pageSize,
    pageNumber:
      data?.SalesforceManagement?.queries?.getBusinessPartnerLinesList?.paging
        ?.pageNumber,
    totalCount:
      data?.SalesforceManagement?.queries?.getBusinessPartnerLinesList?.paging
        ?.totalCount,
  };
}

export function mapToAssignedPlanListingData(data: any): IListingData<any> {
  const list: IAbstractRecord = {};

  data?.SalesforceManagement?.queries?.getBusinessPartnerPlanList?.items?.forEach(
    (obj: IGenericAssignedPlansTableData, i: number) => {
      list[i] = {
        salesforceManagement_BusinessPartnerPlan_LineID:
          obj.salesforceManagement_BusinessPartnerPlan_LineID?.id,
        salesforceManagement_BusinessPartnerPlan_PlanID:
          obj.salesforceManagement_BusinessPartnerPlan_PlanID?.id,

        salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID:
          obj.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID
            ?.id,
        salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_Status:
          obj.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID
            ?.views?.SalesforceManagement_all?.properties?.Status,

        salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_LineCommissionType:
          obj.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID
            ?.views?.SalesforceManagement_all?.properties?.LineCommissionType,
        salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_LineCommissionValue:
          obj.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID
            ?.views?.SalesforceManagement_all?.properties?.LineCommissionValue,
        salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_ValueCurrency:
          obj.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID
            ?.views?.SalesforceManagement_all?.properties?.ValueCurrency?.Code,
        salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_LineCommissionRate:
          obj.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID
            ?.views?.SalesforceManagement_all?.properties?.LineCommissionRate,
        salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_ApplicableOn:
          obj.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID
            ?.views?.SalesforceManagement_all?.properties?.ApplicableOn?.Code,

        salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionApplicable:
          obj.salesforceManagement_BusinessPartnerPlan_PlanID?.views
            ?.PlanConfigManagement_all?.properties?.SpecialCommissionApplicable,
        salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionType:
          capitalizeFirstLetter(
            obj.salesforceManagement_BusinessPartnerPlan_PlanID?.views
              ?.PlanConfigManagement_all?.properties?.SpecialCommissionType
          ),
        salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionValue:
          obj.salesforceManagement_BusinessPartnerPlan_PlanID?.views
            ?.PlanConfigManagement_all?.properties?.SpecialCommissionValue,
        salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionValueCurrency:
          obj.salesforceManagement_BusinessPartnerPlan_PlanID?.views
            ?.PlanConfigManagement_all?.properties
            ?.SpecialCommissionValueCurrency?.Code,
        salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionRate:
          obj.salesforceManagement_BusinessPartnerPlan_PlanID?.views
            ?.PlanConfigManagement_all?.properties?.SpecialCommissionRate,
        salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionApplicableOn:
          obj.salesforceManagement_BusinessPartnerPlan_PlanID?.views
            ?.PlanConfigManagement_all?.properties
            ?.SpecialCommissionApplicableOn?.Code,

        salesforceManagement_BusinessPartnerID:
          obj.salesforceManagement_BusinessPartnerPlan_BusinessPartnerID?.id,
        salesforceManagement_BusinessPartner_Status:
          obj.salesforceManagement_BusinessPartnerPlan_BusinessPartnerID?.views
            ?.SalesforceManagement_all?.properties?.Status,

        salesforceManagement_BusinessPartnerPlan_Id:
          obj.salesforceManagement_BusinessPartnerPlan_Id,

        line_ExternalCode: obj.line_ExternalCode,
        line_Name: obj.line_Name,
        line_Status: capitalizeFirstLetter(obj.line_Status),
        plan_ExternalCode: obj.plan_ExternalCode,
        plan_Name: obj.plan_Name,
        plan_PlanStatus: capitalizeFirstLetter(obj.plan_PlanStatus),

        salesforceManagement_BusinessPartnerPlan_PlanCommissionType:
          obj?.salesforceManagement_BusinessPartnerPlan_PlanCommissionType,
        salesforceManagement_BusinessPartnerPlan_PlanCommissionTypeTitle:
          capitalizeFirstLetter(
            obj?.salesforceManagement_BusinessPartnerPlan_PlanCommissionType
          ),
        salesforceManagement_BusinessPartnerPlan_PlanCommissionValue_Only:
          obj.salesforceManagement_BusinessPartnerPlan_PlanCommissionValue,
        salesforceManagement_BusinessPartnerPlan_PlanCommissionValueCurrency_Only:
          obj.salesforceManagement_BusinessPartnerPlan_ValueCurrency?.Code,
        salesforceManagement_BusinessPartnerPlan_PlanCommissionRate_Only:
          obj.salesforceManagement_BusinessPartnerPlan_PlanCommissionRate,

        salesforceManagement_BusinessPartnerPlan_PlanCommissionValue:
          obj.salesforceManagement_BusinessPartnerPlan_PlanCommissionType ===
          "VALUE"
            ? obj.salesforceManagement_BusinessPartnerPlan_PlanCommissionValue !==
              null
              ? `${valueCommaSeparated(
                  obj.salesforceManagement_BusinessPartnerPlan_PlanCommissionValue
                )} ${
                  obj.salesforceManagement_BusinessPartnerPlan_ValueCurrency
                    ?.Title
                }`
              : ""
            : obj.salesforceManagement_BusinessPartnerPlan_PlanCommissionRate !==
              null
            ? `${Math.round(
                parseFloat(
                  (
                    Number(
                      obj.salesforceManagement_BusinessPartnerPlan_PlanCommissionRate
                    ) * 100
                  ).toFixed(10)
                )
              )}%`
            : "",
        salesforceManagement_BusinessPartnerPlan_ApplicableOn:
          obj?.salesforceManagement_BusinessPartnerPlan_ApplicableOn?.Code,
        salesforceManagement_BusinessPartnerPlan_ApplicableOnTitle:
          obj?.salesforceManagement_BusinessPartnerPlan_ApplicableOn?.Title,
        salesforceManagement_BusinessPartnerPlan_ActiveFrom:
          obj.salesforceManagement_BusinessPartnerPlan_ActiveFrom,
        salesforceManagement_BusinessPartnerPlan_ActiveTo:
          obj.salesforceManagement_BusinessPartnerPlan_ActiveTo,
        salesforceManagement_BusinessPartnerPlan_Status: capitalizeFirstLetter(
          obj.salesforceManagement_BusinessPartnerPlan_Status?.replaceAll(
            "_",
            " "
          )
        ),
      };
    }
  );

  return {
    pagedItems: list,
    pageSize:
      data?.SalesforceManagement?.queries?.getBusinessPartnerPlanList?.paging
        ?.pageSize,
    pageNumber:
      data?.SalesforceManagement?.queries?.getBusinessPartnerPlanList?.paging
        ?.pageNumber,
    totalCount:
      data?.SalesforceManagement?.queries?.getBusinessPartnerPlanList?.paging
        ?.totalCount,
  };
}

export function mapToVignetteRangeListingData(data: any): IListingData<any> {
  const list: IAbstractRecord = {};

  data?.SalesforceManagement?.queries?.getBusinessPartnerVignetteRange?.items?.forEach(
    (obj: IGenericVignetteRangesTableData, i: number) => {
      list[i] = {
        salesforceManagement_BusinessPartnerVignetteRange_Id:
          obj.salesforceManagement_BusinessPartnerVignetteRange_Id,
        salesforceManagement_BusinessPartnerVignetteRange_BusinessPartnerID:
          obj
            .salesforceManagement_BusinessPartnerVignetteRange_BusinessPartnerID
            ?.id,
        salesforceManagement_BusinessPartnerVignetteRange_RangeFrom:
          obj.salesforceManagement_BusinessPartnerVignetteRange_RangeFrom,
        salesforceManagement_BusinessPartnerVignetteRange_RangeTo:
          obj.salesforceManagement_BusinessPartnerVignetteRange_RangeTo,
        salesforceManagement_BusinessPartnerVignetteRange_RangeStatus:
          capitalizeFirstLetter(
            obj.salesforceManagement_BusinessPartnerVignetteRange_RangeStatus
          ),
        salesforceManagement_BusinessPartnerVignetteRange_RangeYear:
          obj.salesforceManagement_BusinessPartnerVignetteRange_RangeYear,
        salesforceManagement_BusinessPartnerVignetteRange_RangeCode:
          obj.salesforceManagement_BusinessPartnerVignetteRange_RangeCode,
      };
    }
  );

  return {
    pagedItems: list,
    pageSize:
      data?.SalesforceManagement?.queries?.getBusinessPartnerVignetteRange
        ?.paging?.pageSize,
    pageNumber:
      data?.SalesforceManagement?.queries?.getBusinessPartnerVignetteRange
        ?.paging?.pageNumber,
    totalCount:
      data?.SalesforceManagement?.queries?.getBusinessPartnerVignetteRange
        ?.paging?.totalCount,
  };
}

export function extractLines(
  data: any
): Record<string, Record<string, string>> {
  let lovs: Record<string, Record<string, string>> = {
    lines: {},
  };

  data?.SalesforceManagement?.queries?.getBusinessPartnerLinesListWithoutPagination.forEach(
    (element: {
      salesforceManagement_BusinessPartnerLine_Id: string;
      salesforceManagement_BusinessPartnerLine_LineID: {
        views: {
          PlanConfigManagement_all: {
            properties: {
              Name: string;
            };
          };
        };
      };
    }) => {
      lovs.lines[element.salesforceManagement_BusinessPartnerLine_Id] =
        element.salesforceManagement_BusinessPartnerLine_LineID.views.PlanConfigManagement_all.properties.Name;
    }
  );

  return lovs;
}
