export interface IEnvironmentSettingsPageProps {}

export interface IEnvironmentSettings {
  environmentName: string;
  minimumIssueDate: string;
}

export const initialValues: IEnvironmentSettings = {
  environmentName: '',
  minimumIssueDate: '',
};
