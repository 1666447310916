import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
} from "../../components/enhanced-table";
import { IEntityInfoView } from "../../components/widgets/entity-info/EntityInfoFields";

export const steps = ["Inactive", "Active"];

export const entityView: IEntityInfoView = {
  name: "section1",
  title: "Business Partner Details",
  iconUrl: "",
  sections: [
    {
      name: "businessPartnerDetailsSection1",
      title: "",
      properties: {
        type: {
          value: "",
          multiline: false,
          name: "type",
          title: "Type",
        },
        companyCode: {
          value: "",
          multiline: false,
          name: "companyCode",
          title: "Company Code",
        },
        status: {
          value: "",
          multiline: false,
          name: "status",
          title: "Status",
        },
        mTPCommissionPercentage: {
          value: "",
          multiline: false,
          name: "mTPCommissionPercentage",
          title: "MTP Commission Percentage",
        },
        mBICommissionPercentage: {
          value: "",
          multiline: false,
          name: "mBICommissionPercentage",
          title: "MBI Commission Percentage",
        },
        expatCommissionPercentage: {
          value: "",
          multiline: false,
          name: "expatCommissionPercentage",
          title: "Expat Commission Percentage",
        },
      },
    },
    {
      name: "businessPartnerDetailsSection2",
      title: "",
      properties: {
        createdBy: {
          value: "",
          multiline: false,
          name: "createdBy",
          title: "Created By",
        },
        createdOn: {
          value: "",
          multiline: false,
          name: "createdOn",
          title: "Created On",
        },
        modifiedBy: {
          value: "",
          multiline: false,
          name: "modifiedBy",
          title: "Modified By",
        },
        modifiedOn: {
          value: "",
          multiline: false,
          name: "modifiedOn",
          title: "Modified On",
        },
      },
    },
  ],
  socialMedia: {
    facebook: "",
    linkedIn: "",
    twitter: "",
  },
};

export const headers: Record<string, EnhancedTableHeader> = {
  insurance_AgencyVignetteRange_RangeYear: {
    name: "insurance_AgencyVignetteRange_RangeYear",
    title: "Vignette Range Year",
    type: EnhancedTableHeaderType.Text,
  },
  insurance_AgencyVignetteRange_RangeCode: {
    name: "insurance_AgencyVignetteRange_RangeCode",
    title: "Vignette Range Code",
    type: EnhancedTableHeaderType.Text,
  },
  insurance_AgencyVignetteRange_RangeFrom: {
    name: "insurance_AgencyVignetteRange_RangeFrom",
    title: "Vignette Range Fom",
    type: EnhancedTableHeaderType.Text,
  },
  insurance_AgencyVignetteRange_RangeTo: {
    name: "insurance_AgencyVignetteRange_RangeTo",
    title: "Vignette Range To",
    type: EnhancedTableHeaderType.Text,
  },
  insurance_AgencyVignetteRange_RangeStatus: {
    name: "insurance_AgencyVignetteRange_RangeStatus",
    title: "Vignette Range Status",
    type: EnhancedTableHeaderType.Text,
  },
};