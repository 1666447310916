import { gql } from "@apollo/client";

export function getLatestCurrencyExchangeRateList() {
  return gql`
    query getLatestCurrencyExchangeRateList(
      $currentPage: Int!
      $currentPageSize: Int!
    ) {
      Core {
        queries {
          getLatestCurrencyExchangeRateList(
            pagination: { pageNumber: $currentPage, pageSize: $currentPageSize }
          ) {
            paging {
              pageSize
              pageNumber
              totalCount
            }
            items {
              core_CurrencyExchangeRate_Id
              core_CurrencyExchangeRate_EffectiveFrom
              core_CurrencyExchangeRate_CurrencyID {
                Code
                Title
              }
              core_CurrencyExchangeRate_Rate
            }
          }
        }
      }
    }
  `;
}

export const createCurrencyExchangeRate = () =>
  gql`
    mutation createCurrencyExchangeRate(
      $rateImputs: [Core_CurrencyExchangeRateInputs!]!
    ) {
      core {
        actions {
          createCurrencyExchangeRate(rateImputs: $rateImputs)
        }
      }
    }
  `;
