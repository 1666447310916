import React, { useEffect, useState } from 'react';
import _ from 'lodash';
// import EntityInfoActions from './EntityInfoActions';
import EntityInfoDetails, {
  IEntityInfoDetailsProps,
} from './EntityInfoDetails';

export interface IEntityInfoWidgetProps
  extends
  IEntityInfoDetailsProps {
}

const EntityInfoWidget: React.FC<IEntityInfoWidgetProps> = ({ view, ...props }) => {
  const [updatedView, setUpdatedView] = useState<typeof view>(view);


  useEffect(() => {
    setUpdatedView({ ...view });
  }, [view]);

  return (
    <>
      {/* <EntityInfoActions actions={widgetActions} /> */}
      <EntityInfoDetails
        // actions={entityActions}
        view={updatedView}
        {...props}
      />
    </>
  );
};



export default EntityInfoWidget;
