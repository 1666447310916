import { quote } from "../../components/custom/QuotesCards";
import { isEmpty } from "../../utils/validationUtils";
import { IDocument } from "../../models/file";
import { getDefaultDate } from "../../utils/date-utils";
import dayjs from "dayjs";
import { capitalizeFirstLetter } from "../../utils/formatting-utils";

export function entityToPolicyDetails(data: any): any {
  const policyInfo =
    data?.Insurance?.entities?.policy?.views?.Insurance_all?.properties;
  const motorDetailsInfo =
    data?.Insurance?.entities?.policy?.views?.Insurance_all?.properties
      ?.MotorDetailsID?.views?.Insurance_all?.properties;
  const policyDocumnetInfo =
    data?.Insurance?.entities?.policy?.views?.Insurance_all?.properties
      ?.PolicyDocument;
  const AdditionalDocumentInfo =
    data?.Insurance?.entities?.policy?.views?.Insurance_all?.properties
      ?.MotorDetailsID?.views?.Insurance_all?.properties?.AdditionalDocument;
  const regeneratedDocuments = data?.Insurance?.queries?.regeneratedDocuments;
  const additionalDocuments = data?.Insurance?.queries?.additionalDocuments;
  return {
    statusReason: policyInfo?.PolicyStatusReason,
    lineOfBusiness: policyInfo?.LineOfBusiness?.Title,
    plan: motorDetailsInfo?.Product?.Title,
    productCode: motorDetailsInfo?.Product?.Code,
    agencyName:
      policyInfo?.AssignedAgencyID?.views?.Insurance_all?.properties
        ?.AgencyName,
    agentName:
      policyInfo?.AssignedAgentID?.views?.Insurance_all?.properties?.FirstName +
      " " +
      policyInfo?.AssignedAgentID?.views?.Insurance_all?.properties?.LastName,
    customerName:
      policyInfo?.PolicyHolderID?.views?.Insurance_all?.properties?.FullName,
    createdBy: `${data?.Insurance?.entities?.policy?.queries?.insurance?.relatedUsers[0]?.createdBy_firstName} ${data?.Insurance?.entities?.policy?.queries?.insurance?.relatedUsers[0]?.createdBy_lastName}`,
    createdOn: policyInfo?.createdOn,
    modifiedBy: `${data?.Insurance?.entities?.policy?.queries?.insurance?.relatedUsers[0]?.modifiedBy_firstName} ${data?.Insurance?.entities?.policy?.queries?.insurance?.relatedUsers[0]?.modifiedBy_lastName}`,
    modifiedOn: policyInfo?.modifiedOn,
    nameOnLicenseEn:
      policyInfo?.MotorDetailsID?.views?.Insurance_all?.properties
        ?.NameOnLicenseEn,
    nameOnLicenseAr:
      policyInfo?.MotorDetailsID?.views?.Insurance_all?.properties
        ?.NameOnLicenseAr,
    firstName:
      policyInfo?.PolicyHolderID?.views?.Insurance_all?.properties?.FirstName,
    middleName:
      policyInfo?.PolicyHolderID?.views?.Insurance_all?.properties?.MiddleName,
    lastName:
      policyInfo?.PolicyHolderID?.views?.Insurance_all?.properties?.LastName,
    email: policyInfo?.PolicyHolderID?.views?.Insurance_all?.properties?.Email,
    detailsPhoneType: capitalizeFirstLetter(
      policyInfo?.PolicyHolderID?.views?.Insurance_all?.properties?.PhoneType
    ),
    phoneType:
      policyInfo?.PolicyHolderID?.views?.Insurance_all?.properties?.PhoneType,
    mobileNumber:
      policyInfo?.PolicyHolderID?.views?.Insurance_all?.properties
        ?.MobileNumber,
    countryId:
      policyInfo?.PolicyHolderID?.views?.Insurance_all?.properties?.Address
        ?.Country?.Id,
    country:
      policyInfo?.PolicyHolderID?.views?.Insurance_all?.properties?.Address
        ?.Country?.Title,
    city: policyInfo?.PolicyHolderID?.views?.Insurance_all?.properties?.Address
      ?.City,
    street:
      policyInfo?.PolicyHolderID?.views?.Insurance_all?.properties?.Address
        ?.Street,
    building:
      policyInfo?.PolicyHolderID?.views?.Insurance_all?.properties?.Address
        ?.Building,
    policyNumber: policyInfo?.PolicyNumber,
    isRenewal: policyInfo?.IsRenewal,
    renewalNumber: policyInfo?.RenewalNumber,
    policyEffectiveDate: policyInfo?.PolicyEffectiveDate,
    policyExpiryDate: policyInfo?.PolicyExpiryDate,
    cancelationEffectiveDate: policyInfo?.CancelationEffectiveDate,
    policyIssueDate: policyInfo?.PolicyIssueDate,
    towingService: motorDetailsInfo?.TowingService?.Title,
    plateCode: motorDetailsInfo?.PlateCode?.Title,
    plateNumber: motorDetailsInfo?.PlateNumber,
    usageType: motorDetailsInfo?.UsageType?.Title,
    bodyType: motorDetailsInfo?.BodyType?.Title,
    engineType: motorDetailsInfo?.EngineType?.Title,
    brandId: motorDetailsInfo?.Brand?.Id,
    brand: motorDetailsInfo?.Brand?.Title,
    modelId: motorDetailsInfo?.Model?.Id,
    model: motorDetailsInfo?.Model?.Title,
    yearOfMake: motorDetailsInfo?.YearOfMake,
    motor: motorDetailsInfo?.Motor,
    weight: motorDetailsInfo?.Weight,
    engineSize: motorDetailsInfo?.EngineSize,
    seats: motorDetailsInfo?.Seats,
    chassis: motorDetailsInfo?.Chassis,
    horsePower: motorDetailsInfo?.HorsePower,
    vignetteCode: motorDetailsInfo?.VignetteCode,
    vignette: motorDetailsInfo?.Vignette,
    fullPolicyNumber: policyInfo?.FullPolicyNumber,
    pendingDocumentRegeneration: policyInfo?.PendingDocumentRegeneration,
    policyDocument: {
      id: policyDocumnetInfo?.id,
      location: policyDocumnetInfo?.location,
      path: policyDocumnetInfo?.path,
      fileName: policyDocumnetInfo?.fileName,
      length: policyDocumnetInfo?.length,
      contentType: policyDocumnetInfo?.contentType,
    },
    additionalDocument: {
      id: AdditionalDocumentInfo?.id,
      location: AdditionalDocumentInfo?.location,
      path: AdditionalDocumentInfo?.path,
      fileName: AdditionalDocumentInfo?.fileName,
      length: AdditionalDocumentInfo?.length,
      contentType: AdditionalDocumentInfo?.contentType,
    },
    regeneratedDocuments: getPolicyDocuments(regeneratedDocuments),
    additionalDocuments: getPolicyDocuments(additionalDocuments),
    userCanUploadAdditionalDocument:
      data?.Insurance?.entities?.policy?.actions
        ?.able_To_UploadAdditionalDocument,
  };
}

export function graphqlEntityToQuoteDetails(
  data: any,
  currencySymbol: string
): quote {
  return {
    id: data?.Insurance?.entities?.policy?.views?.Insurance_all?.properties
      ?.QuoteID?.id,
    title:
      data?.Insurance?.entities?.policy?.views?.Insurance_all?.properties
        ?.QuoteID?.views?.Insurance_all?.properties?.Name,
    status:
      data?.Insurance?.entities?.policy?.views?.Insurance_all?.properties
        ?.QuoteID?.views?.Insurance_all?.properties?.Status,
    statusReason:
      data?.Insurance?.entities?.policy?.views?.Insurance_all?.properties
        ?.QuoteID?.views?.Insurance_all?.properties?.StatusReason,
    createdOn:
      data?.Insurance?.entities?.policy?.views?.Insurance_all?.properties
        ?.QuoteID?.views?.Insurance_all?.properties?.createdOn,
    modifiedOn:
      data?.Insurance?.entities?.policy?.views?.Insurance_all?.properties
        ?.QuoteID?.views?.Insurance_all?.properties?.modifiedOn,
    hasCoverage: !isEmpty(
      data?.Insurance?.entities?.policy?.views?.Insurance_all?.properties
        ?.MotorDetailsID
    ),
    coverageAmount:
      data?.Insurance?.entities?.policy?.views?.Insurance_all?.properties
        ?.QuoteID?.views?.Insurance_all?.properties?.MotorDetailsID?.views
        ?.Insurance_all?.properties?.SumInsured,
    coverageAmountCurrencySymbol: currencySymbol,
    premiumAmount:
      data?.Insurance?.entities?.policy?.views?.Insurance_all?.properties
        ?.QuoteID?.views?.Insurance_all?.properties?.TotalAnnualPremium,
    premiumAmountCurrencySymbol: currencySymbol,
    towingServiceTitle:
      data?.Insurance?.entities?.policy?.views?.Insurance_all?.properties
        ?.QuoteID?.views?.Insurance_all?.properties
        ?.ApplicationMotorPlanDetailsID?.views?.Insurance_all?.properties
        ?.TowingService?.Title,
    vignetteCode:
      data?.Insurance?.entities?.policy?.views?.Insurance_all?.properties
        ?.MotorDetailsID?.views?.Insurance_all?.properties?.VignetteCode,
    vignette:
      data?.Insurance?.entities?.policy?.views?.Insurance_all?.properties
        ?.MotorDetailsID?.views?.Insurance_all?.properties?.Vignette,
  };
}

function getPolicyDocuments(policyDocuments: any[]) {
  const policDoc: IDocument[] = [];
  if (policyDocuments && policyDocuments.length > 0) {
    policyDocuments.map((document) => {
      policDoc.push({
        id: document?.docs_Id,
        code: document?.docs_DocumentCode,
        version: document?.docs_Version,
        createdOn: getDefaultDate(
          dayjs(new Date(document?.docs_createdOn))?.toDate()
        )?.toDateString(),
        file: document?.docs_PolicyDocument,
      });
    });
  }
  return policDoc;
}
