
import { IUserInfo } from '../redux/user/types';

export function fetchWidgetMetaFromSession(
  userInfo: IUserInfo,
  pageContext?: string,
  widgetName?: string,
  // I know the null here is pointless, but I wanted to leave it
  // for future cases, should we refactor it
  
): any | null {
  const path = generateSessionStoragePath(
    userInfo,
    pageContext,
    widgetName,
  );
  const payload = window.sessionStorage.getItem(path);
  return payload ? JSON.parse(payload) : null;
}

export function storeWidgetMetaFromSession(
  
  payload: any,
  userInfo: IUserInfo,
  pageContext?: string,
  widgetName?: string,
) {
  const path = generateSessionStoragePath(
    userInfo,
    pageContext,
    widgetName,
  );
  if (payload) {
    window.sessionStorage.setItem(path, JSON.stringify(payload));
  }
}

export function generateSessionStoragePath(
  userInfo: IUserInfo,
  pageContext?: string,
  widgetName?: string,
): string {
  return (
    (userInfo.email || '') +
    (pageContext || '') +
    (widgetName || '')
  );
}
