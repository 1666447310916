import { FileType, FILE_TYPES_META } from '../models/file';
import { resolveGraphqlBaseUrl } from './tenant-utils';

export function isFile(input: any) {
  if ('File' in window && input instanceof File) return true;
  else return false;
}

export function getFileExtension(fileName: string) {
  return fileName
    ? fileName.slice((Math.max(0, fileName.lastIndexOf('.')) || Infinity) + 1)
    : '';
}

export function getFileNameWithoutExtension(fileName: string) {
  return fileName.split('.').slice(0, -1).join('.');
}

export function deriveMimeTypes(fileTypes?: FileType[]): string {
  if (fileTypes) {
    const extMimeTypes = fileTypes.map(
      (type) => FILE_TYPES_META[type].mimetype,
    );
    return extMimeTypes.join(', ');
  }
  return '';
}

export function getFileNameFromContentDisposition(
  disposition?: string | null,
): string {
  if (disposition && disposition.indexOf('attachment') !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      return matches[1].replace(/['"]/g, '');
    }
  }
  return '';
}

export function generateDownloadLink(
  fileId: string,
  entityTypeId: string,
  entityId: string,
  entityViewId: string,
  propertyId: string,
): string {
  return `${resolveGraphqlBaseUrl()}/api/Files/download/entities?entityTypeId=${entityTypeId}&entityId=${entityId}&entityViewId=${entityViewId}&propertyId=${propertyId}&fileId=${fileId}`;
}
