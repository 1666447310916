import React, { useEffect, useState } from "react";
import EntityInfoWidget from "../../components/widgets/entity-info/EntityInfoWidget";
import { entityView, steps } from "./content";
import { makeStyles } from "tss-react/mui";
import { useLazyQuery, useMutation } from "@apollo/client";
import { activateProvider } from "./queries";
import { useParams } from "react-router-dom";
import { IEntityInfoView } from "../../components/widgets/entity-info/EntityInfoFields";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";
import ToastErrorMessage from "../../components/ToastErrorMessage";

import {
  IConfigurationProviderDetailsPageProps,
  IConfigurationProviderDetails,
  initialValues,
} from ".";

import {
  DEFAULT_ERROR_TEXT,
  MAIN_ONE_THEME,
  contentFontFamilyBold,
} from "../../constants";

import dayjs from "dayjs";
import { useAppDispatch } from "../../redux/hooks";
import { IDynamicDiplayView } from "../../components/widgets/dynamic-display";
import DynamicWidget from "../../components/widgets/dynamic-display/DynamicWidget";
import { capitalizeFirstLetter } from "../../utils/formatting-utils";
import EnhancedButton, {
  EnhancedButtonStatus,
} from "../../components/EnhancedButton";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import { getError } from "../../utils/graph-utils";
import { IConfirmation } from "../../redux/confirmation/types";
import { getProviderDetailsInfo } from "./queries";
import { OpenConfirmationAction } from "../../redux/confirmation/actions";
import { entityToProviderDetails, getProviderStatus } from "./utils";
import ShowForUser from "../../components/user/ShowForUser";
import { isEmpty } from "../../utils/validationUtils";
import { EnhancedDisplayType } from "../../components/enhanced-form/EnhancedDisplay";
import ProviderDrawer from "../providers-drawer/ProviderDrawer";
import ProviderDeactivateDrawer from "../providers-deactivate-drawer/ProviderDeactivateDrawer";
import StaticLayout from "../../page-layout/static-layout/StaticLayout";
import EnhancedStepperSpecificDesign from "../../components/common/EnhancedStepperSpecificDesign";
import WidgetSection from "../../components/common/WidgetSection";

const useStyles = makeStyles()(() => ({
  stepper: {
    backgroundColor: "transparent",
    maxWidth: "90%",
    marginBottom: "20px",
    "& .MuiStepLabel-root .Mui-active, .MuiStepLabel-root ": {
      color: MAIN_ONE_THEME.palette.primary5.main,
      "& .MuiStepIcon-text": {
        fontSize: 0,
        lineHeight: "0px",
        fill: "black",
      },
    },

    ".Mui-completed": {
      color: MAIN_ONE_THEME.palette.primary5.main,
      "& .MuiStepIcon-text": {
        fontSize: 0,
        lineHeight: "0px",
        fill: "black",
      },
    },

    "@media only screen and (min-width: 600px)": {
      maxWidth: "1300px",
    },
  },
  section: {
    display: "grid",
    "grid-template-columns": "19.5% 19.5% 19.5% 19.5% 19.5%",
    justifyContent: "space-between",
    flexWrap: "wrap",

    "& label": {
      "& span": {
        margin: "-2px 0",
      },
    },
  },
  sectionFullRow: {
    display: "grid",
    "grid-template-columns": "100%",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  row: {
    flexWrap: "wrap",
    margin: "15px 20px 10px 0px",
    alignItems: "flex-start",
    display: "grid",
    gap: "5px",
    "& label": {
      flexGrow: "1",
      flexBasis: "0",
      minWidth: "0",
    },
    "grid-template-columns": "19.5% 19.5% 19.5% 19% 16% 3% 3%",
  },
  thickSeperator: {
    height: 10,
    margin: "10px 0 10px 0",
    "grid-column-start": "1",
    "grid-column-end": "4",
  },
  field: {
    width: "96%",
    marginRight: "10px !important",
  },
  header: {
    fontFamily: contentFontFamilyBold,
    fontSize: MAIN_ONE_THEME.typography.regular.reg3.fontSize,
    color: MAIN_ONE_THEME.palette.primary2.main,
    "&:hover": {
      color: MAIN_ONE_THEME.palette.primary1.main,
    },
  },
  content: {
    padding: "0",
  },
  dirtyChip: {
    margin: "0 0 -15px 0",
    backgroundColor: MAIN_ONE_THEME.palette.primary2.main,
    "& span": {
      color: "#ffffff",
    },
  },
  actionButton: {
    display: "inline-block",
    marginRight: "5px",
  },
}));

const ProviderDetailsPage: React.FC<
  IConfigurationProviderDetailsPageProps
> = () => {
  const { classes } = useStyles();
  const params = useParams();
  const [activateButtonState, setActivateButtonState] =
    useState<EnhancedButtonStatus>();

  const [updatedLeftSideInfo, setUpdatedLeftSideInfo] =
    useState<IEntityInfoView>(entityView);

  const [loadingState, setLoadingState] = useState<boolean>(true);
  const [providerInfoDrawerOpen, setProviderInfoDrawerOpen] =
    useState<boolean>(false);
  const [deactivateDrawerOpen, setDeactivateDrawerOpen] =
    useState<boolean>(false);

  const [providerDetailsInfo, setProviderDetailsInfo] =
    useState<IConfigurationProviderDetails>(initialValues);
  const [providerInformationViewConfig, setProviderInformationViewConfig] =
    useState<IDynamicDiplayView>();
  const [status, setStatus] = useState<string>();

  const [providerStatus, setProviderStatus] = useState<string>("");

  const dispatch = useAppDispatch();
  const entityId = params.id;

  const [getProviderDetailsLazy, entityResult] = useLazyQuery(
    getProviderDetailsInfo(),
    {
      variables: { id: entityId },
      errorPolicy: "all",
      fetchPolicy: "no-cache",
    }
  );

  const [activateAction] = useMutation(activateProvider(), {
    variables: {
      entityId: entityId,
    },
    errorPolicy: "all",
    refetchQueries: [getProviderDetailsInfo()],
    onCompleted: () => {
      setProviderStatus("");
    },
  });

  useEffect(() => {
    try {
      if (!entityResult.loading) {
        setLoadingState(false);
      }

      if (entityResult?.data) {
        const providerDetails = entityToProviderDetails(entityResult?.data);

        if (isEmpty(providerStatus)) {
          setProviderStatus(providerDetails.status);
        }

        //get application section properties
        const applicationProperties = entityView.sections[0];
        applicationProperties.properties.name = {
          multiline: false,
          name: "name",
          title: "Full Name",
          value: providerDetails.name,
          type: EnhancedDisplayType.RelativeLink,
          tooltip: false,
          url: "/salesforce/persons/" + providerDetails.personId,
        };
        applicationProperties.properties.providerCode.value =
          providerDetails.providerCode;
        applicationProperties.properties.dateOfBirth.value =
          providerDetails.dateOfBirth
            ? dayjs(new Date(providerDetails.dateOfBirth)).format("DD/MM/YYYY")
            : null;
        applicationProperties.properties.email.value = providerDetails.email;
        applicationProperties.properties.phoneType.value =
          providerDetails.phoneType;
        applicationProperties.properties.phoneNumber.value =
          providerDetails.phoneNumber;
        applicationProperties.properties.taxable.value =
          providerDetails.taxable;

        //get the system propeties section
        const systemProperties = entityView.sections[1];
        systemProperties.properties.createdBy.value = providerDetails.createdBy;
        systemProperties.properties.createdOn.value = dayjs(
          new Date(providerDetails.createdOn)
        ).format("DD/MM/YYYY");
        systemProperties.properties.modifiedBy.value =
          providerDetails.modifiedBy;
        systemProperties.properties.modifiedOn.value = dayjs(
          new Date(providerDetails.modifiedOn)
        ).format("DD/MM/YYYY");

        const newLeftEntityView = {
          ...entityView,
          sections: [applicationProperties, systemProperties],
        };

        setProviderDetailsInfo(providerDetails);
        setUpdatedLeftSideInfo(newLeftEntityView);

        const dynamicView: IDynamicDiplayView = {
          name: "Provider Information",
          sections: [
            {
              name: "providerDetails",
              title: "Provider Details",
              showActionButtons: true,
              properties: {
                type: {
                  multiline: false,
                  value: capitalizeFirstLetter(providerDetails.typeTitle),
                  name: "type",
                  title: "Type",
                },
                isCompany: {
                  multiline: false,
                  value: providerDetails.isCompany ? "Yes" : "No",
                  name: "isCompany",
                  title: "Is Company",
                },
                isLocal: {
                  multiline: false,
                  value: providerDetails.isLocal ? "Yes" : "No",
                  name: "isLocal",
                  title: "Is Local",
                },
                effectiveDate: {
                  multiline: false,
                  value: dayjs(new Date(providerDetails.effectiveDate)).format(
                    "DD/MM/YYYY"
                  ),
                  name: "effectiveDate",
                  title: "Effective Date",
                },
                expiryDate: {
                  multiline: false,
                  value: dayjs(new Date(providerDetails.expiryDate)).format(
                    "DD/MM/YYYY"
                  ),
                  name: "expiryDate",
                  title: "Expiry Date",
                  hidden: !(providerDetails.status === "inactive"),
                },
              },
            },
          ],
        };

        setProviderInformationViewConfig(dynamicView);

        const newStatus =
          entityResult?.data?.Configuration?.entities?.provider?.views?.Configuration_all?.properties?.Status.toLowerCase();
        setStatus(newStatus);
      }
    } catch (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  }, [entityResult?.data]);

  useEffect(() => {
    getProviderDetailsLazy();
  }, []);

  const renderMainChildren = () => {
    const isProviderActive = status === "active";

    return (
      <>
        <div style={{ marginTop: "20px" }}>
          <EnhancedStepperSpecificDesign
            activeStep={getProviderStatus(status)}
            steps={steps}
            className={classes.stepper}
            showStepperButton
            buttonTitle={isProviderActive ? "Deactivate" : "Activate"}
            buttonState={isProviderActive ? activateButtonState : undefined}
            buttonOnClick={() => {
              if (isProviderActive) {
                setDeactivateDrawerOpen(true);
              } else {
                const confirmation: IConfirmation = {
                  open: true,
                  title: "Activate",
                  message: `Are you sure you want to activate this provider?`,
                  callback: async () => {
                    setActivateButtonState("loading");
                    activateAction()
                      .then((result) => {
                        if (isEmpty(result.errors)) {
                          setActivateButtonState("success");
                          toast.success(
                            <ToastSuccessMessage>
                              {"Provider successfully activated."}
                            </ToastSuccessMessage>
                          );
                        } else {
                          setActivateButtonState(undefined);
                          toast.error(
                            <ToastErrorMessage>
                              {getError(result)}
                            </ToastErrorMessage>
                          );
                        }
                      })
                      .finally(() => {
                        setTimeout(() => {
                          setActivateButtonState(undefined);
                        }, 1000);
                      });
                  },
                  submitButtonText: "Yes",
                  cancelButtonText: "No",
                };
                dispatch(OpenConfirmationAction(confirmation));
              }
            }}
          />
          <WidgetSection>
            {providerInformationViewConfig && (
              <DynamicWidget
                hasFourFields={true}
                view={providerInformationViewConfig}
                actions={
                  <ShowForUser allowedRoles={["Insurance-Admin"]}>
                    {status != "inactive" && (
                      <div>
                        <EnhancedButton
                          type="button"
                          backgroundColor={MAIN_ONE_THEME.palette.primary1.main}
                          color="#FFF"
                          onClick={() => setProviderInfoDrawerOpen(true)}
                          className={classes.actionButton}
                        >
                          Modify
                        </EnhancedButton>
                      </div>
                    )}
                  </ShowForUser>
                }
              />
            )}
          </WidgetSection>
        </div>
      </>
    );
  };

  const renderLeftSection = () => {
    return (
      <>
        <EntityInfoWidget
          background="rgb(193, 0, 0)"
          classification={{ classifications: {}, value: "" }}
          progress={100}
          supportsClassification={true}
          supportsProgressBar={true}
          title={providerDetailsInfo.name}
          view={updatedLeftSideInfo}
        ></EntityInfoWidget>
      </>
    );
  };


  return loadingState ? (
    <Loader />
  ) : (
    <>
      <div>
        {providerInfoDrawerOpen && (
          <ProviderDrawer
            open={providerInfoDrawerOpen}
            onClose={() => setProviderInfoDrawerOpen(false)}
            onSuccess={() => {
              getProviderDetailsLazy();
            }}
            providerId={params.id}
            providerDetailsInfo={entityToProviderDetails(entityResult?.data)}
          />
        )}
        {deactivateDrawerOpen && (
          <ProviderDeactivateDrawer
            open={deactivateDrawerOpen}
            onClose={() => setDeactivateDrawerOpen(false)}
            onSuccess={() => {
              getProviderDetailsLazy();
              setProviderStatus("");
            }}
            providerId={params.id}
            effectiveDate={
              entityToProviderDetails(entityResult?.data).effectiveDate
            }
          />
        )}
      </div>
      <StaticLayout
        name={"Provider Details"}
        leftChildren={renderLeftSection()}
        mainChildren={renderMainChildren()}
      />
    </>
  );
};

export default ProviderDetailsPage;
