import React, { useCallback } from "react";
import {
  isEmpty,
  isValidNumber,
  isValidNumberInput,
} from "../../utils/validationUtils";
import { IEnhancedInputProps } from "./EnhancedInput";
import EnhancedInputsWrapper from "./EnhancedInputsWrapper";
import EnhancedIcon from "../EnhancedIcon";
import { useAppSelector } from "../../redux/hooks";
import clsx from "clsx";
import { makeStyles } from "tss-react/mui";
import { MAIN_ONE_THEME, contentFontFamilyRegular } from "../../constants";

export interface IEnhancedPercentageInputProps
  extends Omit<IEnhancedInputProps, "type"> {
  value: string | number;
  loader?: boolean;
  hasBetweenValidation?: boolean;
  disableNegativeValues?: boolean;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  hidden?: boolean;
}

const useStyles = makeStyles()((theme) => ({
  input: {
    fontSize: MAIN_ONE_THEME.typography.regular.reg2.fontSize,
    lineHeight: "15px",
    height: 35,
    margin: "0 auto",
    backgroundColor: MAIN_ONE_THEME.palette.secondary4.main,
    border: `1px solid ${MAIN_ONE_THEME.palette.secondary3.main}`,
    boxSizing: "border-box",
    borderRadius: "5px",
    "&:disabled": {
      cursor: "not-allowed",
      opacity: 0.4,
      color: MAIN_ONE_THEME.palette.primary2.main,
    },
    "&::placeholder": {
      color: "rgba(0, 0, 0, 0.20)",
      fontStyle: "italic",
    },
    fontFamily: contentFontFamilyRegular,
    "&:focus": {
      outline: "none",
    },
    "::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  // TODO make style common using JSS
  materialInput: {
    backgroundColor: "unset",
    border: "none",
    borderBottom: `1px solid ${MAIN_ONE_THEME.palette.secondary3.main}`,
    color: MAIN_ONE_THEME.palette.primary2.main,
    margin: "0 auto",
    borderRadius: 0,
    padding: "10px 15px 4px 4px",
    fontFamily: contentFontFamilyRegular,
    fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px`,
  },
  percentageIcon: {
    display: "inline-block !important",
    height: "8px",
    width: "8px",
    minHeight: "12px",
    minWidth: "12px",
    top: "10px",
    marginLeft: "194px !important",
    fontFamily: contentFontFamilyRegular,
  },
  formPercentageIcon: {
    position: "absolute",
    bottom: "0",
    left: "7px",
    height: "11px",
    width: "15px",
    marginTop: "2px",
    fontFamily: contentFontFamilyRegular,
  },
  percentageInput: {
    paddingLeft: "15px",
    fontFamily: contentFontFamilyRegular,
  },
  inputError: {
    borderColor: `${MAIN_ONE_THEME.palette.error.main} !important`,
    outlineColor: `${MAIN_ONE_THEME.palette.error.main} !important`,
    fontFamily: contentFontFamilyRegular,
  },
  inlineContainer: {
    position: "relative",
    "& $formPercentageIcon": {
      height: "10px",
      width: "10px",
      display: "block",
    },
    fontFamily: contentFontFamilyRegular,
  },
  inputWrapper: {
    height: "88.6px",
  },
}));

const EnhancedPercentageInput: React.FC<IEnhancedPercentageInputProps> = ({
  name,
  title,
  value,
  placeholder,
  onChange,
  onBlur,
  material,
  disableNegativeValues,
  error,
  hidden = false,
  inline,
  className,
  description,
  style,
  disabled,
  onFocus,
  customStyles,
}) => {
  const tenant = useAppSelector((state) => state.tenant);
  const { classes } = useStyles();

  const minValue = 0;
  const maxValue = 100;

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let validation = false;
      if (!isEmpty(event.target.value)) {
        if (Number(event.target.value) < 0 && disableNegativeValues) {
          event.target.value = minValue?.toString() || "0";
        }
        const inputValue = Number(event.target.value);
        if (isValidNumber(minValue) && isValidNumber(maxValue)) {
          validation = isValidNumberInput(
            inputValue,
            Number(minValue),
            Number(maxValue)
          );
        } else if (isValidNumber(maxValue)) {
          validation = inputValue <= Number(maxValue);
        } else if (isValidNumber(minValue)) {
          validation = inputValue >= Number(minValue);
        } else {
          validation = true;
        }
      } else {
        validation = true;
      }
      if (validation) {
        onChange(event);
      }
    },
    [maxValue, minValue, onChange]
  );

  const renderInput = () => {
    return (
      <>
        <EnhancedIcon
          className={clsx(classes.percentageIcon, {
            [classes.formPercentageIcon]: !material,
          })}
          classes={{ image: classes.percentageIcon }}
          title={"%"}
          iconUrl={tenant.cdnUrl + "/icons/percentage-primary.png"}
          hidden={hidden}
        />
        <input
          className={clsx(
            clsx(classes.input, className, {
              [classes.materialInput]: material,
              [classes.percentageInput]: !material,
              [classes.inputError]: error,
            })
          )}
          style={{
            width: material ? "88%" : "100%",
          }}
          title={disabled && !isEmpty(value) ? value.toString() : title}
          id={name}
          name={name}
          aria-invalid={!!error}
          aria-describedby={`errorMsg-${name}`}
          value={value}
          onChange={handleChange}
          onBlur={onBlur}
          onFocus={onFocus}
          type="number"
          disabled={disabled}
          placeholder={placeholder}
          onWheel={(e) => (e.target as any).blur()}
          hidden={hidden}
        />
      </>
    );
  };

  return inline ? (
    <span className={classes.inlineContainer}>{renderInput()}</span>
  ) : (
    <EnhancedInputsWrapper
      title={title}
      description={description}
      error={error}
      name={name}
      className={clsx(classes.inputWrapper, className)}
      style={style}
      customStyles={customStyles}
      hidden={hidden}
    >
      <div>{renderInput()}</div>
    </EnhancedInputsWrapper>
  );
};

export default EnhancedPercentageInput;
