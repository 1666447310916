import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export interface IHomePage {
  homePageLink: string;
}

const HomePage: React.FC<IHomePage> = ({ homePageLink = '' }) => {
  const nav = useNavigate();
  useEffect(() => {
    nav(homePageLink);
  }, [homePageLink]);
  return <div></div>;
};

export default HomePage;
