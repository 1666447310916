import { IEntityInfoView } from '../../components/widgets/entity-info/EntityInfoFields';
import { ITabsLayout, LayoutType } from '../../page-layout';

export const steps = ['Bound', 'Closed'];

export const entityView: IEntityInfoView = {
  name: 'section1',
  title: 'Policy Details 1',
  iconUrl: '',
  sections: [
    {
      name: 'policyDetails',
      title: '',
      properties: {
        stateReason: {
          value: '',
          multiline: false,
          name: 'stateReason',
          title: 'Status Reason',
        },
        lineOfBusiness: {
          value: '',
          multiline: false,
          name: 'lineOfBusiness',
          title: 'Line Of Business',
        },
        productCode: {
          value: '',
          multiline: false,
          name: 'productCode',
          title: 'Product',
        },
        agency: {
          value: '',
          multiline: false,
          name: 'agency',
          title: 'Business Partner',
        },
        agentName: {
          value: '',
          multiline: false,
          name: 'agentName',
          title: 'Business User',
        },
      },
    },
    {
      name: 'policyDetails2',
      title: '',
      properties: {
        createdBy: {
          value: '',
          multiline: false,
          name: 'createdBy',
          title: 'Created By',
        },
        createdOn: {
          value: '',
          multiline: false,
          name: 'createdOn',
          title: 'Created On',
        },
        modifiedBy: {
          value: '',
          multiline: false,
          name: 'modifiedBy',
          title: 'Modified By',
        },
        modifiedOn: {
          value: '',
          multiline: false,
          name: 'modifiedOn',
          title: 'Modified On',
        },
      },
    },
  ],
  socialMedia: {
    facebook: '',
    linkedIn: '',
    twitter: '',
  },
};

export const policyTabs: ITabsLayout = {
  typeLogicalId: LayoutType.tabslayout,
  leftColumn: {
    backgroundColor: '#FFFFFF',
    collapsable: false,
    width: 2,
    collapsed: false,
  },
  mainColumn: {
    backgroundColor: '#F7F7F7',
    collapsable: false,
    width: 10,
    collapsed: false,
  },
  rightColumn: {
    backgroundColor: '#FFFFFF',
    collapsable: false,
    width: 0,
    collapsed: false,
  },
  tabs: [
    {
      name: 'summary',
      title: 'Summary',
      iconUrl: '/icons/file-summary-primary.svg',
      widgets: [{}],
    },
    {
      name: 'offers',
      title: 'Offers',
      iconUrl: '/icons/file-summary-primary.svg',
      widgets: [{}],
    },
    {
      name: 'documents',
      title: 'Documents',
      iconUrl: '/icons/folder-primary.svg',
      widgets: [{}],
    },
  ],
};
