import { IListingData } from '../../models/listing';
import { capitalizeFirstLetter } from '../../utils/formatting-utils';

export function mapToListingData(data: any): IListingData<any> {
  const list: Record<string, INssfCodeNumberRangeTableData> = {};

  data.Insurance.queries.listNSSFCodeRange.items.forEach(
    (obj: INssfCodeNumberRangeTableData, i: number) => {
      let reached = '';
      if (obj.insurance_NSSFCodeRange_RangeStatus.toLowerCase() == 'consumed') {
        reached = obj.insurance_NSSFCodeRange_RangeTo;
      } else if (
        obj.insurance_NSSFCodeRange_RangeStatus.toLowerCase() == 'active'
      ) {
        reached = obj.insurance_NSSFCodeRange_NSSFCodeReached;
      } else {
        reached = '';
      }

      list[i] = {
        insurance_NSSFCodeRange_Id: obj.insurance_NSSFCodeRange_Id,
        insurance_NSSFCodeRange_RangeFrom:
          obj.insurance_NSSFCodeRange_RangeFrom,
        insurance_NSSFCodeRange_RangeTo: obj.insurance_NSSFCodeRange_RangeTo,
        insurance_NSSFCodeRange_NSSFCodeReached: reached,
        insurance_NSSFCodeRange_RangeStatus: capitalizeFirstLetter(
          obj.insurance_NSSFCodeRange_RangeStatus
        ),
        insurance_NSSFCodeRange_createdOn:
          obj.insurance_NSSFCodeRange_createdOn,

        insurance_NSSFCodeRange_modifiedOn:
          obj.insurance_NSSFCodeRange_modifiedOn,
      };
    }
  );

  return {
    pagedItems: list,
    pageSize: data.Insurance.queries.listNSSFCodeRange?.paging?.pageSize,
    pageNumber: data.Insurance.queries.listNSSFCodeRange?.paging?.pageNumber,
    totalCount: data.Insurance.queries.listNSSFCodeRange?.paging?.totalCount,
  };
}
