import { gql } from "@apollo/client";

export function listQuery() {
  return gql`
    query LIST_PROVIDERSAPPLICATIONS(
      $currentPage: Int!
      $currentPageSize: Int!
      $selectedTypes: [String!]
      $providerStatus: [Configuration_ProviderStatuses!]
      $isCompany: [Boolean]
      $isLocal: [Boolean]
      $createdDateFrom: Date
      $createdDateTo: Date
      $effectiveDateFrom: Date
      $effectiveDateTo: Date
    ) {
      Configuration {
        queries {
          getProviderList(
            pagination: { pageNumber: $currentPage, pageSize: $currentPageSize }
            IsCompany: $isCompany
            IsLocal: $isLocal
            selectedTypes: $selectedTypes
            selectedStatuses: $providerStatus
            FromCreatedOn: $createdDateFrom
            ToCreatedOn: $createdDateTo
            FromEffectiveDate: $effectiveDateFrom
            ToEffectiveDate: $effectiveDateTo
          ) {
            paging {
              pageSize
              pageNumber
              totalCount
            }
            items {
              configuration_Provider_Id
              configuration_Provider_Code
              configuration_Provider_ProviderCode
              configuration_Provider_Type {
                Id
                Title
              }
              configuration_Provider_IsCompany
              configuration_Provider_IsLocal
              configuration_Provider_EffectiveDate
              configuration_Provider_ExpiryDate
              configuration_Provider_Status
              configuration_Provider_createdOn
              configuration_Provider_createdBy
              configuration_Provider_modifiedOn
              configuration_Provider_modifiedBy
              configuration_Provider_PersonID {
                id
                views {
                  SalesforceManagement_all {
                    properties {
                      FullName
                      Email
                      PhoneDetails {
                        PhoneNumber
                      }
                    }
                  }
                }
              }
            }
          }
        }
        lookups {
          providerTypes {
            Code
            Title
            Id
          }
        }
      }
      Configuration_ProviderStatuses: __type(
        name: "Configuration_ProviderStatuses"
      ) {
        name
        enumValues {
          name
        }
      }
    }
  `;
}
