
export type ListingDataItemType<T> = Record<string, T & {actions?: string[]}>;
export interface ITableRowSettings {
  iconType: string;
  iconUrl: string;
  iconTooltip: string;
  operator: ConditionOperator;
  conditions: ITableRowSettingsCondition[];
}

export interface ITableRowSettingsCondition {
  iconVisiblePropertyName: string;
  iconVisiblePropertyValues: Array<any>;
  operator: PropertyOperator;
}

export enum PropertyOperator {
  Equal = 0,
  NotEqual = 1,
  GreaterThan = 2,
  GreaterThanOrEqual = 3,
  LessThan = 4,
  LessThanOrEqual = 5,
}

export enum ConditionOperator {
  And = 0,
  Or = 1,
}
