import {closeConfirmation, openConfirmation} from './reducer';
import {ConfirmationActionTypes, IConfirmation} from './types';

export function OpenConfirmationAction(confirmation: IConfirmation) {
  return openConfirmation({
    type: ConfirmationActionTypes.OPEN_CONFIRMATION,
    confirmation,
  });
}

export function CloseConfirmationAction() {
  return closeConfirmation();
}
