import { gql } from "@apollo/client";

export function getClaimCoverEnums() {
  return gql`
  query getClaimCoverEnums($SelectedLineId: String) {
    PlanConfigManagement {
      queries {
        allPoliciesCovers(SelectedLineId: $SelectedLineId) {
          planConfigManagement_PolicyCover_Id
          planConfigManagement_PolicyCover_Name
          planConfigManagement_PolicyCover_LineID {
            id
            views {
              PlanConfigManagement_all {
                properties {
                  Name
                }
              }
            }
          }
        }
        allLines {
          planConfigManagement_Line_Id
          planConfigManagement_Line_Name
          planConfigManagement_Line_Status
        }
      }
    }
  }
  `;
}

export function checkClaimCoverExists() {
  return gql`
    query checkClaimCoverExists(
      $claimCoverId: String
      $name: String
      $externalCode: String
      $policyCoverId: String!
    ) {
      PlanConfigManagement {
        queries {
          checkClaimCoverExists(
            claimCoverId: $claimCoverId
            policyCoverId: $policyCoverId
            name: $name
            externalCode: $externalCode
          ) {
            planConfigManagement_ClaimCover_Id
            planConfigManagement_ClaimCover_Name
            planConfigManagement_ClaimCover_ExternalCode
            planConfigManagement_ClaimCover_PolicyCoverID {
              id
              views {
                PlanConfigManagement_all {
                  properties {
                    Name
                    ExternalCode
                  }
                }
              }
            }
            planConfigManagement_ClaimCover_LineID {
              id
              views {
                PlanConfigManagement_all {
                  properties {
                    Name
                    ExternalCode
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function createClaimCover() {
  return gql`
    mutation createClaimCover($claimCoverInputs: PlanConfigManagement_ClaimCoverInputs!) {
      planConfigManagement {
        actions {
          createClaimCover(claimCoverInputs: $claimCoverInputs) {
            id
            views {
              PlanConfigManagement_all {
                properties {
                  Name
                  ExternalCode
                  NameArabic
                  NameFrench
                  PrintingOrder
                  ClaimCoverDescription
                  createdOn
                  createdBy
                  modifiedOn
                  modifiedBy
                  PolicyCoverID {
                    id
                    views {
                      PlanConfigManagement_all {
                        properties {
                          Name
                          ExternalCode
                        }
                      }
                    }
                  }
                  LineID {
                    id
                    views {
                      PlanConfigManagement_all {
                        properties {
                          Name
                          ExternalCode
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function getClaimCoverInfo() {
  return gql`
    query getClaimCoverInfo($id: String!) {
      PlanConfigManagement {
        entities {
          claimCover(id: $id) {
            views {
              PlanConfigManagement_all {
                properties {
                  LineID {
                    id
                    views {
                      PlanConfigManagement_all {
                        properties {
                          Name
                        }
                      }
                    }
                  }
                  PolicyCoverID {
                    id
                    views {
                      PlanConfigManagement_all {
                        properties {
                          Name
                        }
                      }
                    }
                  }
                  Name
                  ExternalCode
                  NameArabic
                  PrintingOrder
                  ClaimCoverDescription
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function updateClaimCover() {
  return gql`
    mutation updateClaimCover(
      $entityId: String!
      $claimCoverInputs: PlanConfigManagement_ClaimCoverInputs!
    ) {
      planConfigManagement {
        entities {
          claimCover {
            planConfigManagement {
              updateClaimCover(
                entityId: $entityId
                claimCoverInputs: $claimCoverInputs
              ) {
                id
              }
            }
          }
        }
      }
    }
  `;
}

export function checkClaimCoverPrintingOrderExists() {
  return gql`
    query checkClaimCoverPrintingOrderExists(
      $printingOrder: Long
      $policyCoverId: String!
      $claimCoverId: String
    ) {
      PlanConfigManagement {
        queries {
          checkClaimCoverPrintingOrderExists(
            printingOrder: $printingOrder
            policyCoverId: $policyCoverId
            claimCoverId: $claimCoverId
          ) {
            planConfigManagement_ClaimCover_Id
            planConfigManagement_ClaimCover_Name
            planConfigManagement_ClaimCover_PrintingOrder
            planConfigManagement_ClaimCover_PolicyCoverID {
              id
              views {
                PlanConfigManagement_all {
                  properties {
                    Name
                    ExternalCode
                  }
                }
              }
            }
            planConfigManagement_ClaimCover_LineID {
              id
              views {
                PlanConfigManagement_all {
                  properties {
                    Name
                    ExternalCode
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}