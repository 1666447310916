// import { IAbstractRecord } from "../../models";

export interface IApplicationDetailsPageHealthProps {}

export interface IApplicationDetails {
  applicationName: string;
  effectiveDate: string;
  status: string;
  statusReason: string;
  isRenewal: string;
  renewalNumber: string;
  lineOfBusiness: string;
  agencyName: string;
  agentName: string;
  customerName: string;
  createdBy: string;
  createdOn: string;
  modifiedBy: string;
  modifiedOn: string;
  planDetails: [Record<string, string | number>];
  insuredDetails: [Record<string, string | number>];
  fullName: string;
  email: string;
  mobileNumber: string;
  country: string;
  city: string;
  street: string;
  building: string;
  title: string;
  phoneType: string;
}

export const initialValues: IApplicationDetails = {
  applicationName: '',
  effectiveDate: '',
  status: '',
  statusReason: '',
  isRenewal: '',
  renewalNumber: '',
  lineOfBusiness: '',
  agencyName: '',
  agentName: '',
  customerName: '',
  createdBy: '',
  createdOn: '',
  modifiedBy: '',
  modifiedOn: '',
  planDetails: [
    {
      planId: null,
      class: null,
      ambulatory: null,
    },
  ],
  insuredDetails: [
    {
      insuredName: null,
      insuredRelation: '',
      insuredDOB: '',
    },
  ],
  fullName: '',
  email: '',
  mobileNumber: '',
  country: 'LB',
  city: '',
  street: '',
  building: '',
  title: '',
  phoneType: 'MOBILE',
};

export const NO_DDUPLICATE_RELATEION_TYPES_KEYS = ['PRINCIPAL', 'SPOUSE'];

export interface IPlan {
  input_Id: string;
  input_Product: {
    Code: string;
    Title: string;
  };
  input_StandardInHospitalClass: {
    Code: string;
    Title: string;
  };
  input_OptimumInHospitalClass: {
    Code: string;
    Title: string;
  };
  input_StandardAmbulatoryCoverage: {
    Code: string;
    Title: string;
  };
  input_OptimumAmbulatoryCoverage: {
    Code: string;
    Title: string;
  };
}

export interface IInsured {
  InsuredName: string;
  InsuredRelation: string;
  InsuredDOB: string;
}
