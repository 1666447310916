import { gql } from "@apollo/client";

export function listQuery() {
  return gql`
    query LIST_POLICIES(
      $currentPage: Int!
      $currentPageSize: Int!
      $agencyId: [String]
      $product: [String]
      $effectiveDateFrom: Date
      $effectiveDateTo: Date
      $issueDateFrom: Date
      $issueDateTo: Date
    ) {
      Insurance {
        queries {
          listDetailsPolicy(
            pagination: { pageNumber: $currentPage, pageSize: $currentPageSize }
            AgencyID: $agencyId
            Products: $product
            EffectiveDateFrom: $effectiveDateFrom
            EffectiveDateTo: $effectiveDateTo
            IssueFrom: $issueDateFrom
            IssueTo: $issueDateTo
          ) {
            paging {
              pageSize
              pageSize
              totalCount
            }
            items {
              insurance_Policy_Id
              insuranceCustomer_FullName
              insurance_Policy_FullPolicyNumber
              insurance_Policy_LineOfBusiness {
                Code
                Title
              }
              insurancePolicyMotorDetails_Product {
                Code
                Title
              }
              insuranceAgency_AgencyName
              insuranceAgent_FirstName
              insuranceAgent_LastName
              insurance_Policy_TotalAnnualPremium
              insurance_Policy_PolicyStatus {
                Code
                Title
              }
              insurance_Policy_PolicyEffectiveDate
              insurance_Policy_PolicyExpiryDate
              insurance_Policy_createdOn
              insurance_Policy_modifiedOn
              insuranceCustomer_FullName
              insurance_Policy_PolicyIssueDate
            }
          }
        }
      }
    }
  `;
}

export function filterLovsQuery() {
  return gql`
    query POLICY_LISTING_LOVS {
      Insurance {
        queries {
          allAgencies {
            insurance_Agency_Id
            insurance_Agency_AgencyName
          }
        }
        lookups {
          products(Insurance_LinesOfBusiness: "Motor") {
            Id
            Code
            Title
          }
        }
      }
    }
  `;
}

export function exportMotorPolicies() {
  return gql`
    query exportMotorPolicies(
      $effectiveDateFrom: Date
      $effectiveDateTo: Date
      $issueFrom: Date
      $issueTo: Date
      $products: [String]
      $lineOfBusiness: String!
    ) {
      Insurance {
        queries {
          ExportPolicies(
            EffectiveDateFrom: $effectiveDateFrom
            EffectiveDateTo: $effectiveDateTo
            IssueFrom: $issueFrom
            IssueTo: $issueTo
            Products: $products
            LineOfBusiness: $lineOfBusiness
          ) {
            insurance_Policy_FullPolicyNumber
            insurance_Policy_modifiedOn
            insurancePolicyMotorDetails_Id
            insurance_Policy_AgencyCommission
            insurancePolicyMotorDetails_Vignette
            insurancePolicyMotorDetails_VignetteCode
            insurance_Policy_IsRenewal
            insurance_Policy_RenewalNumber
            insurancePolicyMotorDetails_Motor
            insuranceAgent_CanViewCommission
            insurancePolicyMotorDetails_Product {
              Title
            }
            insurancePolicyMotorDetails_UsageType {
              Title
            }
            insurancePolicyMotorDetails_BodyType {
              Title
            }
            insurancePolicyMotorDetails_EngineType {
              Title
            }
            insurancePolicyMotorDetails_Brand {
              Title
            }
            insurancePolicyMotorDetails_Model {
              Title
            }
            insurancePolicyMotorDetails_YearOfMake
            insurancePolicyMotorDetails_PlateCode {
              Title
            }
            insurance_Policy_LineOfBusiness {
              Title
            }
            insurancePolicyMotorDetails_PlateNumber
            insurancePolicyMotorDetails_Weight
            insurancePolicyMotorDetails_EngineSize
            insurancePolicyMotorDetails_Seats
            insurancePolicyMotorDetails_Chassis
            insurancePolicyMotorDetails_HorsePower
            insuranceCustomer_FullName
            insuranceAgency_AgencyName
            insuranceAgent_FirstName
            insuranceAgent_LastName
            insurance_Policy_PolicyStatus {
              Title
            }
            insurance_Policy_PolicyEffectiveDate
            insurance_Policy_PolicyExpiryDate
            insurance_Policy_TotalAnnualPremium
            insurance_Policy_PolicyIssueDate
            insurance_Policy_createdOn
          }
        }
      }
    }
  `;
}

export function getMotorAgentInfo() {
  return gql`
    query getMotorAgentInfo($id: String!) {
      Insurance {
        entities {
          agent(id: $id) {
            views {
              Insurance_all {
                properties {
                  CanViewCommission
                }
              }
            }
          }
        }
      }
    }
  `;
}
