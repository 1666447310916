import { ISalesforceBusinessPartnerCommissionInfo } from ".";
import {
  enumListAsRecordObject,
  lookupListAsRecordObject,
} from "../../utils/graph-utils";

export function LookupToList(data: any) {
  const lovs: Record<string, Record<string, string>> = {
    lines: {},
  };

  data?.SalesforceManagement?.queries?.getBusinessPartnerLinesListWithoutPagination.forEach(
    (element: {
      salesforceManagement_BusinessPartnerLine_Id: string;
      salesforceManagement_BusinessPartnerLine_LineID: { 
        views: {
        PlanConfigManagement_all: {
          properties: {
            Name: string;
          }
        }
      }};
    }) => {
      lovs.lines[element.salesforceManagement_BusinessPartnerLine_Id] =
        element.salesforceManagement_BusinessPartnerLine_LineID.views.PlanConfigManagement_all.properties.Name;
    }
  );

  lovs["commissionTypes"] = enumListAsRecordObject(
    data?.Core_CommissionTypes?.enumValues as {
      __typename: string;
      name: string;
    }[]
  );

  lovs["currencies"] = lookupListAsRecordObject(
    data?.Core?.lookups?.currencies
  );

  lovs["commissionApplicableOnOptions"] =
    lookupListAsRecordObject(
      data?.Core?.lookups?.commissionApplicableOnOptions
    );

  return lovs;
}

export function extractLines(data: any): Record<string, Record<string, string>> {
  let lovs: Record<string, Record<string, string>> = {
    lines: {},
  };

  data?.SalesforceManagement?.queries?.getBusinessPartnerLinesListWithoutPagination.forEach(
    (element: {
      salesforceManagement_BusinessPartnerLine_Id: string;
      salesforceManagement_BusinessPartnerLine_LineID: { 
        id: string;
        views: {
        PlanConfigManagement_all: {
          properties: {
            Name: string;
          }
        }
      }};
    }) => {
      lovs.lines[element.salesforceManagement_BusinessPartnerLine_LineID.id] =
        element.salesforceManagement_BusinessPartnerLine_LineID.views.PlanConfigManagement_all.properties.Name;
    }
  );

  return lovs;
}

export function extractPlans(data: any): Record<string, Record<string, string>> {
  let lovs: Record<string, Record<string, string>> = {
    plans: {},
  };

  data?.SalesforceManagement?.queries?.GetPlanListByAssignedLines.forEach(
    (element: {
      planConfigManagement_Plan_Id: string;
      planConfigManagement_Plan_Name: string;
    }) => {
      lovs.plans[element.planConfigManagement_Plan_Id] =
        element.planConfigManagement_Plan_Name;
    }
  );

  return lovs;
}
export function graphqlToSalesforceBusinessPartnerCommissionInfo(
  data: any
): ISalesforceBusinessPartnerCommissionInfo {
  const businessPartnerLine =
    data?.SalesforceManagement?.entities?.businessPartnerLine?.views
      ?.SalesforceManagement_all?.properties;
  return {
    assignedLineId: "",
    commissionType: businessPartnerLine?.LineCommissionType,
    lineCommissionValue: businessPartnerLine?.LineCommissionValue,
    lineCommissionValueCurrency:
      businessPartnerLine?.LineCommissionValueCurrency?.Title,
    lineCommissionRate: businessPartnerLine?.LineCommissionRate,
    lineCommissionApplicableOn:
      businessPartnerLine?.LineCommissionApplicableOn?.Title,
    activeFrom: businessPartnerLine?.activeFrom,
    activeTo: businessPartnerLine?.activeTo,
  };
}
