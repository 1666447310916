import React, { useEffect, useState } from "react";
import { ICurrencyExchangeRateNewPageProps } from ".";
import { IEntityInfoView } from "../../components/widgets/entity-info/EntityInfoFields";
import EntityInfoWidget from "../../components/widgets/entity-info/EntityInfoWidget";
import { currencyHeaders, entityView, steps } from "./content";
import dayjs from "dayjs";
import StaticLayout from "../../page-layout/static-layout/StaticLayout";
import { IListingData } from "../../models/listing";
import { useMutation, useQuery } from "@apollo/client";
import {
  createCurrencyExchangeRate,
  getLatestCurrencyExchangeRateList,
} from "./queries";
import { IEnhancedTableMenuItem } from "../../components/enhanced-table";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { getError } from "../../utils/graph-utils";
import { OpenConfirmationAction } from "../../redux/confirmation/actions";
import { useAppDispatch } from "../../redux/hooks";
import { mapToCurrencyListingData } from "./utils";
import { DEFAULT_ERROR_TEXT, MAIN_ONE_THEME } from "../../constants";
import WidgetSection from "../../components/common/WidgetSection";
import EnhancedStepperSpecificDesign from "../../components/common/EnhancedStepperSpecificDesign";
import EnhancedTable from "../../components/enhanced-table/EnhancedTable";
import { capitalizeFirstLetter } from "../../utils/formatting-utils";
import EnhancedButton from "../../components/EnhancedButton";
import { makeStyles } from "tss-react/mui";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles()((theme) => ({
  actionButton: {
    display: "inline-block",
    marginRight: "5px",
    backgroundColor: `${MAIN_ONE_THEME.palette.primary5.main} !important`,
    borderRadius: "5px !important",
  },
  editableCell: {
    paddingTop: "6px",
    paddingBottom: "6px",
    paddingLeft: "6px",
    paddingRight: "6px",
    backgroundColor: "#ffffff",
  },
}));

interface ICurrencyRate {
  rate: string;
  disabled?: boolean;
}

interface ICurrencyExchangeRateRow {
  core_CurrencyExchangeRate_CurrencyID_Code: string;
  core_CurrencyExchangeRate_CurrencyID_Title: string;
  core_CurrencyExchangeRate_Rate: ICurrencyRate;
  core_CurrencyExchangeRate_EffectiveFrom: Date;
}

const CurrencyExchangeRateNewPage: React.FC<
  ICurrencyExchangeRateNewPageProps
> = () => {
  const dispatch = useAppDispatch();
  //#region react and redux hooks
  const { classes } = useStyles();
  const navigate = useNavigate();

  const [updatedLeftSideInfo, setUpdatedLeftSideInfo] =
    useState<IEntityInfoView>(entityView);

  //#region useEffect
  useEffect(() => {
    const applicationProperties = entityView.sections[0];

    applicationProperties.properties.effectiveDate.value = dayjs(
      new Date()
    ).format("DD/MM/YYYY");

    const newLeftEntityView = {
      ...entityView,
      sections: [applicationProperties],
    };

    setUpdatedLeftSideInfo(newLeftEntityView);
  }, []);
  //#endregion

  // #region Group Stage Listing
  const [currencyTableLoadingState, setCurrencyTableLoadingState] =
    useState<boolean>(false);
  // // const [modifiedRows, setModifiedRows] = useState([]);
  const [currencyTableData, setCurrencyTableData] = useState<IListingData<any>>(
    {
      pagedItems: {},
      pageSize: 200,
      pageNumber: 1,
      totalCount: 0,
    }
  );

  useEffect(() => {
    const today = new Date();
    currencyTableData.pagedItems = Object.values(
      currencyTableData.pagedItems
    ).map((row: ICurrencyExchangeRateRow) => {
      return {
        core_CurrencyExchangeRate_CurrencyID_Code:
          row.core_CurrencyExchangeRate_CurrencyID_Code,
        core_CurrencyExchangeRate_CurrencyID_Title:
          row.core_CurrencyExchangeRate_CurrencyID_Title,
        core_CurrencyExchangeRate_Rate: row.core_CurrencyExchangeRate_Rate,
        core_CurrencyExchangeRate_EffectiveFrom:
          row.core_CurrencyExchangeRate_EffectiveFrom,
        disabled: row.core_CurrencyExchangeRate_EffectiveFrom
          ? dayjs(row.core_CurrencyExchangeRate_EffectiveFrom).isSame(
              today,
              "day"
            )
          : false,
      };
    });
  }, [currencyTableData.pagedItems]);

  const currencyListResult = useQuery(getLatestCurrencyExchangeRateList(), {
    variables: {
      currentPage: 1,
      currentPageSize: currencyTableData.pageSize,
    },
    errorPolicy: "all",
  });
  const [createDailyExchangeRateResultsAction] = useMutation(
    createCurrencyExchangeRate(),
    {
      errorPolicy: "all",
      refetchQueries: [getLatestCurrencyExchangeRateList()],
    }
  );
  // // function emptyLastModifiedRows() {
  // //   setModifiedRows([]);
  // // }
  function handleCurrencyPageChange(page: number) {
    setCurrencyTableLoadingState(true);
    currencyListResult
      ?.fetchMore({
        variables: {
          currentPage: ++page,
          currentPageSize: currencyTableData.pageSize,
        },
      })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const newApplicationList = mapToCurrencyListingData(
            fetchMoreResult.data
          );
          const updatedTableData = {
            ...newApplicationList,
            pageNumber: --page,
          };
          setCurrencyTableData(updatedTableData);
        }
      })
      .finally(() => {
        setCurrencyTableLoadingState(false);
      });
  }
  function handleCurrencyRowsPerPageChange(numberOfRecordsPerPage: number) {
    setCurrencyTableLoadingState(true);
    setCurrencyTableData((prevData) => ({ ...prevData, pageNumber: 0 }));
    currencyListResult
      ?.fetchMore({
        variables: {
          currentPage: 1,
          currentPageSize: numberOfRecordsPerPage,
        },
      })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const newApplicationList = mapToCurrencyListingData(
            fetchMoreResult.data
          );

          const updatedTableData = {
            ...newApplicationList,
            pageNumber: 0,
            pageSize: numberOfRecordsPerPage,
          };
          setCurrencyTableData(updatedTableData);
        }
      })
      .finally(() => {
        setCurrencyTableLoadingState(false);
      });
  }

  let currencyListingActions: IEnhancedTableMenuItem[] = [];
  currencyListingActions = [
    {
      title: "Save",
      onClick: () => {},
      isEntity: false,
      isBulk: false,
      hidden: false,
      disabled: !(
        currencyTableData &&
        currencyTableData.pagedItems &&
        Object.keys(currencyTableData.pagedItems).length > 0
      ),
      isSecondary: true,
    },
  ];
  currencyListingActions[0].onClick = () => {
    const confirmation = {
      open: true,
      title: "Save",
      message: (
        <>
          {"Are you sure you want to save your changes?"}
          <br />
          {/* <span style={{ color: "#D30D2B" }}> */}
          <i>
            <u>{"Note that you can save the daily rate only once per day."}</u>
          </i>
          {/* </span> */}
        </>
      ),
      callback: async () => {
        const modifiedRowData = Object.values(currencyTableData.pagedItems).map(
          (row: {
            core_CurrencyExchangeRate_CurrencyID_Code: string;
            core_CurrencyExchangeRate_Rate: string;
          }) => ({
            core_CurrencyExchangeRate_CurrencyID_Code:
              row.core_CurrencyExchangeRate_CurrencyID_Code,
            core_CurrencyExchangeRate_Rate: row.core_CurrencyExchangeRate_Rate,
          })
        );

        let variables = {
          rateImputs: modifiedRowData.map(
            (row: {
              core_CurrencyExchangeRate_CurrencyID_Code: any;
              core_CurrencyExchangeRate_Rate: string | number;
            }) => {
              return {
                currencyCode: row.core_CurrencyExchangeRate_CurrencyID_Code,
                rate:
                  row.core_CurrencyExchangeRate_Rate === 0
                    ? 0
                    : row.core_CurrencyExchangeRate_Rate === "" ||
                      null ||
                      undefined
                    ? null
                    : row.core_CurrencyExchangeRate_Rate,
              };
            }
          ),
        };

        createDailyExchangeRateResultsAction({
          variables: variables,
          errorPolicy: "all",
        })
          .then((result) => {
            if (isEmpty(result.errors)) {
              toast.success(
                <ToastSuccessMessage>
                  {"Currency Exchange Rate successfully updated."}
                </ToastSuccessMessage>
              );

              setTimeout(() => {
                navigate(`/configuration/currency-exchange-rate/`);
              }, 500);
            } else {
              toast.error(
                <ToastErrorMessage>{getError(result)}</ToastErrorMessage>
              );
              // // emptyLastModifiedRows();
            }
          })
          .finally(() => {
            refetchCurrencyData();
          });
      },
      submitButtonText: "Submit",
      cancelButtonText: "Cancel",
    };
    dispatch(OpenConfirmationAction(confirmation));
  };

  const handleCurrencySaveButtonClick = () => {
    const confirmation = {
      open: true,
      title: "Save",
      message: (
        <>
          {"Are you sure you want to save your changes?"}
          <br />
          {"Note that you can save the daily rate only once per day."}
        </>
      ),
      callback: async () => {
        const modifiedRowData = Object.values(currencyTableData.pagedItems).map(
          (rowIndex: string | number) => currencyTableData.pagedItems[rowIndex]
        );

        let variables = {
          rateImputs: modifiedRowData.map(
            (row: {
              core_CurrencyExchangeRate_CurrencyID_Code: any;
              core_CurrencyExchangeRate_Rate: string | number;
            }) => {
              return {
                currencyCode: row.core_CurrencyExchangeRate_CurrencyID_Code,
                rate:
                  row.core_CurrencyExchangeRate_Rate === 0
                    ? 0
                    : row.core_CurrencyExchangeRate_Rate === "" || null
                    ? null
                    : row.core_CurrencyExchangeRate_Rate,
              };
            }
          ),
        };

        createDailyExchangeRateResultsAction({
          variables: variables,
          errorPolicy: "all",
        })
          .then((result) => {
            if (isEmpty(result.errors)) {
              toast.success(
                <ToastSuccessMessage>
                  {"Currency Exchange Rate successfully updated."}
                </ToastSuccessMessage>
              );
            } else {
              toast.error(
                <ToastErrorMessage>{getError(result)}</ToastErrorMessage>
              );
              // // emptyLastModifiedRows();
            }
          })
          .finally(() => {
            refetchCurrencyData();
          });
      },
      submitButtonText: "Submit",
      cancelButtonText: "Cancel",
    };
    dispatch(OpenConfirmationAction(confirmation));
  };

  function refetchCurrencyData() {
    setCurrencyTableLoadingState(true);

    currencyListResult
      ?.fetchMore({
        variables: {
          currentPage: 1,
          currentPageSize: currencyTableData.pageSize,
        },
      })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const newApplicationList = mapToCurrencyListingData(
            fetchMoreResult.data
          );
          const updatedTableData = {
            ...newApplicationList,
            pageNumber: 1,
          };
          setCurrencyTableData(updatedTableData);
        }
      })
      .finally(() => {
        setCurrencyTableLoadingState(false);
      });
  }

  useEffect(() => {
    if (currencyListResult?.loading) setCurrencyTableLoadingState(true);
    if (currencyListResult?.error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
    if (currencyListResult?.data) {
      const currenciesTableData = mapToCurrencyListingData(
        currencyListResult?.data
      );
      setCurrencyTableData({ ...currenciesTableData, pageNumber: 0 });
    }
    setCurrencyTableLoadingState(false);
  }, [
    currencyListResult?.loading,
    currencyListResult?.error,
    currencyListResult?.data,
  ]);

  // #region Btns
  const handleCurrencyCellValueChanged = (
    rowIndex: number,
    columnId: string,
    newValue: any
  ) => {
    setCurrencyTableData((prevData) => {
      const currentValue = prevData.pagedItems[rowIndex]?.[columnId];
      let parsedValue =
        typeof newValue === "string" ? parseFloat(newValue) : newValue;

      if (isNaN(parsedValue)) {
        parsedValue = null;
      }

      if (currentValue === parsedValue || newValue === "") {
        return prevData;
      }

      const updatedPagedItems = {
        ...prevData.pagedItems,
        [rowIndex]: {
          ...prevData.pagedItems[rowIndex],
          [columnId]: parsedValue,
        },
      };

      return {
        ...prevData,
        pagedItems: updatedPagedItems,
      };
    });

    // // const prevDataCopy = currencyTableData;

    // // setModifiedRows((prevRows) => {
    // //   if (
    // //     newValue === "" ||
    // //     newValue === prevDataCopy.pagedItems[rowIndex]?.[columnId]
    // //   ) {
    // //     return prevRows.filter((index) => index !== rowIndex);
    // //   }

    // //   if (!prevRows.includes(rowIndex)) {
    // //     return [...prevRows, rowIndex];
    // //   }

    // //   return prevRows;
    // // });
  };

  // // const handleSaveChanges = () => {
  // //   const modifiedRowData = modifiedRows.map(
  // //     (rowIndex) => groupStagesTableData.pagedItems[rowIndex]
  // //   );

  // // };
  // #endregion
  // #endregion

  const renderMainChildren = () => {
    return (
      <>
        <div style={{ marginTop: "20px" }}>
          <EnhancedStepperSpecificDesign
            activeStep={""}
            showStepperButton={false}
            style={{ visibility: "hidden" }}
            steps={steps}
          />

          <WidgetSection
            style={{ margin: "-23px 0 0 0" }}
            actions={
              <div>
                <EnhancedButton
                  type="button"
                  backgroundColor={MAIN_ONE_THEME.palette.primary5.main}
                  color="#FFF"
                  onClick={() => {
                    handleCurrencySaveButtonClick();
                  }}
                  className={classes.actionButton}
                >
                  {"Save"}
                </EnhancedButton>
              </div>
            }
          >
            <form>
              {currencyListResult?.loading ? (
                <div></div>
              ) : (
                <div
                  style={{
                    marginTop: "20px",
                    padding: "-23px 0 0 0 !important",
                  }}
                >
                  <EnhancedTable
                    title={capitalizeFirstLetter("Currency Exchange Rate")}
                    inlineTitle={capitalizeFirstLetter(
                      "Currency Exchange Rate"
                    )}
                    name="count"
                    entityName={"ClaimCover"}
                    entityIdColumnName={"planConfigManagement_ClaimCover_Id"}
                    showCellFullData={false}
                    isTitlePascalCase={true}
                    orderByAscendingByDefault
                    defaultOrderByColumn="name"
                    inline={true}
                    data={currencyTableData}
                    headers={currencyHeaders}
                    handlePageChange={(page: number) =>
                      handleCurrencyPageChange(page)
                    }
                    handleRowsPerPageChange={(page: number) =>
                      handleCurrencyRowsPerPageChange(page)
                    }
                    currentPage={currencyTableData.pageNumber}
                    hideToolbar={false}
                    usePagination
                    disableSelection={true}
                    actions={currencyListingActions}
                    loader={currencyTableLoadingState}
                    showTablePagination={false}
                    onSelectionChange={() => {}}
                    handleCellValueChanged={handleCurrencyCellValueChanged}
                    showInlineFilter={false}
                  />
                </div>
              )}
            </form>
          </WidgetSection>
        </div>
      </>
    );
  };

  const renderLeftSection = () => {
    return (
      <>
        <EntityInfoWidget
          background="rgb(193, 0, 0)"
          classification={{ classifications: {}, value: "" }}
          progress={100}
          supportsClassification={true}
          supportsProgressBar={true}
          title={"Currency Exchange Rate"}
          view={updatedLeftSideInfo}
        ></EntityInfoWidget>
      </>
    );
  };

  //#endregion

  return (
    <StaticLayout
      name={"Currency Exchange Rate"}
      leftChildren={renderLeftSection()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default CurrencyExchangeRateNewPage;
