import React, { useEffect, useState } from "react";
import GenericDrawer from "../../components/common/generic-drawer/GenericDrawer";
import {
  DynamicFormInputType,
  IFormChipsDynamicProps,
  IFormDateDynamicProps,
  IFormSelectDynamicProps,
  IFormSwitchDynamicProps,
} from "../../DynamicForm";
import { toast } from "react-toastify";
import { EnhancedButtonStatus } from "../../components/common/EnhancedButton";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { inputs } from "./content";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import Loader from "../../components/Loader";
import { cloneDeep, isEmpty } from "lodash";
import {
  DEFAULT_ERROR_TEXT,
  SEND_TO_BACKEND_DATE_FORMAT,
} from "../../constants";
import { IProviderDrawerProps } from ".";
import {
  getListForm,
  createProvider,
  fetchedPersons,
  updateProvider,
} from "./queries";
import { extractPersons, toLookups } from "./utils";
import { getError } from "../../utils/graph-utils";
import { normaliseDynamicValues } from "../../utils/dynamic-utils";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import DynamicForm from "../../DynamicForm/DynamicForm";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import dayjs from "dayjs";

const useStyles = makeStyles()(() => ({
  isLocalStyle: {
    marginBottom: "17px",
  },
}));

const ProviderDrawer: React.FC<IProviderDrawerProps> = ({
  open,
  onSuccess,
  onClose,
  providerDetailsInfo,
  providerId,
}) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();
  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);
  const [newAllValues, setNewAllValues] = useState<Record<string, any>>();

  const [hasPlanExpiryDateError, setHasPlanExpiryDateError] = useState("");
  const [newEffectiveDate, setNewEffectiveDate] = useState<any>();
  const [newExpiryDate, setNewExpiryDate] = useState<any>();
  let [submitButtonDisabled, setSubmitButtonDisabled] =
    useState<boolean>(false);

  const providersDetailsListResults = useQuery(getListForm());

  const [selectedPersonID, setSelectedPersonID] = useState("");

  const [fetchedPersonsLazy, fetchedPersonsQueryResult] = useLazyQuery(
    fetchedPersons()
  );

  const [providerAction] = useMutation(
    providerId ? updateProvider() : createProvider()
  );

  let updatedInputs: Record<string, DynamicFormInputType> = inputs;

  function handlePersonSelection(selectedOption: any) {
    setSelectedPersonID(selectedOption);
  }

  function handlePersonInput(inputValue: any) {
    if (inputValue !== null) {
      if (inputValue.length >= 3) {
        fetchedPersonsLazy({
          variables: {
            searchKeyword: inputValue,
          },
        });
      }
    } else {
      (updatedInputs.person as IFormChipsDynamicProps).selectOptions = {};
    }
  }

  const initialize = () => {
    try {
      updatedInputs = cloneDeep(inputsForm);

      //#region show condtitions
      updatedInputs.person.hidden = !!providerId;
      updatedInputs.person.required = !providerId;

      updatedInputs.expiryDate.hidden = !!providerId;

      updatedInputs.Configuration_ProviderStatuses.hidden = !!providerId;
      updatedInputs.Configuration_ProviderStatuses.required = !providerId;

      updatedInputs.type.disabled = !!providerId;
      updatedInputs.type.required = !providerId;
      //#endregion

      (updatedInputs.isLocal as IFormSwitchDynamicProps).className =
        classes.isLocalStyle;

      if (providersDetailsListResults.data) {
        const lookupLists = toLookups(providersDetailsListResults.data);

        (
          updatedInputs.Configuration_ProviderStatuses as IFormSelectDynamicProps
        ).selectOptions = lookupLists["Configuration_ProviderStatuses"];

        (updatedInputs.type as IFormSelectDynamicProps).selectOptions =
          lookupLists["type"];
      }

      (updatedInputs.person as IFormChipsDynamicProps).onChange = (option) => {
        handlePersonInput(option);
        handlePersonSelection(option[0]);
      };

      (
        updatedInputs.Configuration_ProviderStatuses as IFormChipsDynamicProps
      ).onSelect = (option) => {
        const isInactive = option === "INACTIVE";
        updatedInputs.expiryDate.hidden = !isInactive;
      };

      (updatedInputs.effectiveDate as IFormDateDynamicProps).onChange = (
        event
      ) => {
        setNewEffectiveDate(event);
        (updatedInputs.effectiveDate as IFormDateDynamicProps).minDate =
          newEffectiveDate;
        handleEffectiveDateChange(newExpiryDate, newEffectiveDate);
      };

      (updatedInputs.expiryDate as IFormDateDynamicProps).onChange = (
        event
      ) => {
        setNewExpiryDate(event);
        (updatedInputs.expiryDate as IFormDateDynamicProps).value = event;

        (updatedInputs.expiryDate as IFormDateDynamicProps).minDate =
          updatedInputs.effectiveDate.value;
        handleEffectiveDateChange(newExpiryDate, newEffectiveDate);
      };

      if (providerDetailsInfo) {
        (updatedInputs.expiryDate as IFormDateDynamicProps).minDate = new Date(
          providerDetailsInfo.expiryDate
        );

        updatedInputs.person.value = providerDetailsInfo.person;
        updatedInputs.type.value = providerDetailsInfo.type;
        updatedInputs.isCompany.value = providerDetailsInfo.isCompany;
        updatedInputs.isLocal.value = providerDetailsInfo.isLocal;
        updatedInputs.effectiveDate.value = providerDetailsInfo.effectiveDate;
        updatedInputs.expiryDate.value = providerDetailsInfo.expiryDate;
        updatedInputs.Configuration_ProviderStatuses.value =
          providerDetailsInfo.Configuration_ProviderStatuses;
      }

      const isInactive =
        updatedInputs.Configuration_ProviderStatuses.value === "INACTIVE";
      updatedInputs.expiryDate.hidden = !isInactive;

      setInputsForm(updatedInputs);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputs, values);

    setFormDisabled(true);
    setSubmitButtonState("loading");

    try {
      let variablesMutation = {};
      if (!providerId) {
        variablesMutation = {
          personId: selectedPersonID,
          type: data.type,
          isCompany: data.isCompany ? data.isCompany : false,
          isLocal: data.isLocal ? data.isLocal : false,
          effectiveDate: dayjs(new Date(data.effectiveDate)).format(
            SEND_TO_BACKEND_DATE_FORMAT
          ),
          expiryDate: data.expiryDate
            ? dayjs(new Date(data.expiryDate)).format(
                SEND_TO_BACKEND_DATE_FORMAT
              )
            : null,
          status: data.Configuration_ProviderStatuses,
        };
      } else {
        variablesMutation = {
          isCompany: data.isCompany ? data.isCompany : false,
          isLocal: data.isLocal ? data.isLocal : false,
          effectiveDate: dayjs(new Date(data.effectiveDate)).format(
            SEND_TO_BACKEND_DATE_FORMAT
          ),
        };
      }

      providerAction({
        variables: providerId
          ? {
              ...variablesMutation,
              entityId: providerId,
            }
          : variablesMutation,
        errorPolicy: "all",
      }).then((res) => {
        if (isEmpty(res.errors)) {
          toast.success(
            <ToastSuccessMessage>
              {providerId
                ? "Provider successfully updated."
                : "Provider successfully created."}
            </ToastSuccessMessage>
          );
          setTimeout(() => {
            setSubmitButtonState("success");
            onSuccess();
            onClose();
            if (!providerId) {
              const newProviderId =
                res.data.configuration.actions.createProvider.id;
              navigate(`/configuration/providers/` + newProviderId);
            }
          }, 500);
        } else {
          setSubmitButtonState(undefined);
          toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
        }
      });
    } catch {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setFormDisabled(false);
    }
  };

  const handleEffectiveDateChange = (expiryDate: any, effectiveDate: any) => {
    if (
      expiryDate &&
      effectiveDate &&
      dayjs(expiryDate).isBefore(dayjs(effectiveDate))
    ) {
      setHasPlanExpiryDateError(
        "Expiry Date shall be equal or greater than Effective Date"
      );
      setInputsForm((currentInputsForm) => {
        const updatedForm = {
          ...createInputsFormFromAllValues(currentInputsForm, newAllValues),
        };
        updatedForm.expiryDate.minDate = effectiveDate;
        updatedForm.expiryDate.error =
          "Expiry Date shall be equal or greater than Effective Date";

        return updatedForm;
      });
      setSubmitButtonDisabled(true);
    } else {
      setHasPlanExpiryDateError("");
      setInputsForm((currentInputsForm) => {
        const updatedForm = {
          ...createInputsFormFromAllValues(currentInputsForm, newAllValues),
        };
        updatedForm.expiryDate.minDate = effectiveDate;
        updatedForm.expiryDate.error = "";

        return updatedForm;
      });
      setSubmitButtonDisabled(
        hasPlanExpiryDateError !== "" || hasPlanExpiryDateError !== ""
      );
    }
  };

  //#region useEffect
  useEffect(() => {
    initialize();
  }, [providersDetailsListResults.data]);

  useEffect(() => {
    if (fetchedPersonsQueryResult?.data) {
      const extractedFetchedPersons = extractPersons(
        fetchedPersonsQueryResult.data
      );

      setInputsForm((currentInputsForm) => {
        const updatedForm = {
          ...createInputsFormFromAllValues(currentInputsForm, newAllValues),
        };

        (updatedForm.person as IFormChipsDynamicProps).selectOptions = {
          ...extractedFetchedPersons,
        };

        return updatedForm;
      });
    } else {
      (updatedInputs.person as IFormChipsDynamicProps).selectOptions = {};
    }
  }, [fetchedPersonsQueryResult.data]);

  useEffect(() => {
    handleEffectiveDateChange(newExpiryDate, newEffectiveDate);
  }, [newEffectiveDate]);
  useEffect(() => {
    handleEffectiveDateChange(newExpiryDate, newEffectiveDate);
  }, [newExpiryDate]);

  useEffect(() => {
    const isDisabled = !isEmpty(hasPlanExpiryDateError);

    setSubmitButtonDisabled(isDisabled);
  }, [hasPlanExpiryDateError]);
  //#endregion

  const createInputsFormFromAllValues = (
    currentInputsForm: any,
    allValues: Record<string, any>
  ) => {
    if (newAllValues) {
      const newInputsForm = {
        person: {
          ...currentInputsForm.person,
          value: allValues["person"],
        },
        type: {
          ...currentInputsForm.type,
          value: allValues["type"],
        },
        isCompany: {
          ...currentInputsForm.isCompany,
          value: allValues["isCompany"],
        },
        isLocal: {
          ...currentInputsForm.isLocal,
          value: allValues["isLocal"],
        },
        effectiveDate: {
          ...currentInputsForm.effectiveDate,
          value: allValues["effectiveDate"],
        },
        expiryDate: {
          ...currentInputsForm.expiryDate,
          value: allValues["expiryDate"],
        },
        Configuration_ProviderStatuses: {
          ...currentInputsForm.Configuration_ProviderStatuses,
          value: allValues["Configuration_ProviderStatuses"],
        },
      };

      (
        newInputsForm.Configuration_ProviderStatuses as IFormChipsDynamicProps
      ).onSelect = (option) => {
        const isInactive = option === "INACTIVE";
        newInputsForm.expiryDate.hidden = !isInactive;
        setHasPlanExpiryDateError(isInactive ? hasPlanExpiryDateError : "");
      };

      return newInputsForm;
    } else return currentInputsForm;
  };

  return (
    <GenericDrawer
      title={providerId ? "Modify Provider Details" : "New Provider"}
      onClose={() => onClose()}
      isOpen={open}
    >
      {providersDetailsListResults.loading && open ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={"Submit"}
            submitButtonState={submitButtonState}
            isSubmitButtonDisabled={submitButtonDisabled}
            disableForm={formDisabled}
            title="Information"
            hasDoprdownSpecificBehavior={true}
            onChange={(
              fieldName: string,
              value: string | string[],
              allValues
            ) => {
              if (
                fieldName === "person" ||
                fieldName === "effectiveDate" ||
                fieldName === "expiryDate"
              ) {
                setNewAllValues(allValues);
              }
            }}
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default ProviderDrawer;
