import React, { useEffect, useState } from 'react';
import GenericDrawer from '../../components/common/generic-drawer/GenericDrawer';
import DynamicForm from '../../DynamicForm/DynamicForm';
import {
  DynamicFormInputType,
  IFormSelectDynamicProps,
  IFormTextDynamicProps,
} from '../../DynamicForm';
import { toast } from 'react-toastify';
import { EnhancedButtonStatus } from '../../components/common/EnhancedButton';
import { normaliseDynamicValues } from '../../utils/dynamic-utils';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { inputs } from './content';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  checkSublineExists,
  createSubline,
  getSublineEnums,
  getSublineInfo,
  updateSubline,
} from './queries';
import { LookupToList, graphqlEntityToSublineInfo } from './utils';
import Loader from '../../components/Loader';
import { cloneDeep } from 'lodash';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import { isEmpty } from '../../utils/validationUtils';
import { DEFAULT_ERROR_TEXT } from '../../constants';
import { getError } from '../../utils/graph-utils';
import { useNavigate } from 'react-router-dom';

const SublineDrawer: React.FC<ISublineDrawerProps> = ({
  sublineId,
  lineId,
  lineName,
  lineStatus,
  isLineNameDisabled = true,
  canNavigateToDetailsPage = false,
  open,
  onSuccess,
  onClose,
}) => {
  let sublineInfoResult: any;

  const navigate = useNavigate();

  const [hasSublineNameError, setHasSublineNameError] = useState('');
  const [hasSublineExternalCodeError, setHasSublineExternalCodeError] =
    useState('');
  let sublineNameError = '';
  let sublineExternalCodeError = '';
  let [selectedLineId, setSelectedLineId] = useState('');

  let [submitButtonDisabled, setSubmitButtonDisabled] =
    useState<boolean>(false);

  const sublineEnumResults = useQuery(getSublineEnums(), {
    fetchPolicy: 'no-cache',
  });

  const [sublineAction] = useMutation(
    sublineId ? updateSubline() : createSubline(),
  );

  let updatedInputs: Record<string, DynamicFormInputType> = inputs;

  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();
  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);

  if (sublineId) {
    sublineInfoResult = useQuery(getSublineInfo(), {
      variables: { id: sublineId },
    });
  }

  function handleLineSelection(selectedOption: any) {
    selectedLineId = selectedOption;
    validateSublineExistsOnNameChange(
      selectedLineId,
      updatedInputs.sublineName.value,
    );
    validateSublineExistsOnExternalCodeChange(
      selectedLineId,
      updatedInputs.sublineExternalCode.value,
    );
  }

  const [checkSublineExistsLazy] = useLazyQuery(checkSublineExists(), {});

  const initialize = () => {
    try {
      updatedInputs = cloneDeep(inputsForm);

      if (isLineNameDisabled) {
        updatedInputs.lineName.disabled = isLineNameDisabled;
        updatedInputs.lineName.value = lineId;
      }

      updatedInputs.lineName.value = lineId;
      if (sublineEnumResults.data) {
        const newSublineEnums = LookupToList(sublineEnumResults.data);

        if (!lineId) {
          updatedInputs.lineName.value = lineId;
        }
        (updatedInputs.lineName as IFormSelectDynamicProps).selectOptions =
          newSublineEnums['lines'];

        (updatedInputs.lineName as IFormSelectDynamicProps).onSelect = (
          option,
        ) => {
          setSelectedLineId(option);
          handleLineSelection(option);
          const selectedLineStatus =
            newSublineEnums['lineStatus'][selectedLineId].toLowerCase();
          updatedInputs.sublineStatus.disabled =
            selectedLineStatus === 'active' ? false : true;
        };

        (updatedInputs.sublineStatus as IFormSelectDynamicProps).selectOptions =
          newSublineEnums['PlanConfigManagement_SublineStatuses'];
        if (!sublineId) {
          updatedInputs.sublineStatus.value = 'INACTIVE';
        }
        updatedInputs.sublineStatus.disabled =
          lineStatus === 'active' ? false : true;
      }

      (updatedInputs.sublineName as IFormTextDynamicProps).onChange = (
        event,
      ) => {
        const newName = event.target.value;
        setInputsForm(updatedInputs);
        (updatedInputs.sublineName as IFormTextDynamicProps).value = newName;
        if (!isEmpty(newName)) {
          validateSublineExistsOnNameChange(selectedLineId, newName);
        }
      };

      (updatedInputs.sublineExternalCode as IFormTextDynamicProps).onChange = (
        event,
      ) => {
        const newExternalCode = event.target.value;
        setInputsForm(updatedInputs);
        (updatedInputs.sublineExternalCode as IFormTextDynamicProps).value =
          newExternalCode;
        if (!isEmpty(newExternalCode)) {
          validateSublineExistsOnExternalCodeChange(
            selectedLineId,
            newExternalCode,
          );
        }
      };

      if (sublineInfoResult?.data) {
        const sublineEntity = graphqlEntityToSublineInfo(
          sublineInfoResult.data,
          lineId,
          lineName,
        );

        if (sublineEntity) {
          updatedInputs.lineName.value = sublineEntity.lineId;
          updatedInputs.sublineName.value = sublineEntity.sublineName;
          updatedInputs.sublineExternalCode.value =
            sublineEntity.sublineExternalCode;
          updatedInputs.arabicName.value = sublineEntity.arabicName;
          updatedInputs.sublineStatus.value = sublineEntity.sublineStatus;
          selectedLineId = selectedLineId;
        }
      }

      setInputsForm(updatedInputs);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    if (sublineNameError === '' && sublineExternalCodeError === '') {
      const [data] = normaliseDynamicValues(inputs, values);
      setFormDisabled(true);
      setSubmitButtonState('loading');
      try {
        let variables = {
          subLineInputs: {
            externalCode: data.sublineExternalCode,
            lineID: lineId || selectedLineId,
            name: data.sublineName,
            nameArabic: data.arabicName,
            sublineStatus: data.sublineStatus,
          },
        };

        sublineAction({
          variables: sublineId
            ? { ...variables, entityId: sublineId }
            : variables,
          errorPolicy: 'all',
        }).then((res) => {
          if (isEmpty(res.errors)) {
            toast.success(
              <ToastSuccessMessage>
                {sublineId
                  ? 'Subline successfully updated'
                  : 'Subline successfully created'}
              </ToastSuccessMessage>,
            );

            if (canNavigateToDetailsPage) {
              const newSublineId = res.data.planConfigManagement.actions.createSubline.id;
              setTimeout(() => {
                setSubmitButtonState('success');
                onSuccess();
                onClose();
                navigate(`/plan/sublines/` + newSublineId);
              }, 500);
            } else {
              setTimeout(() => {
                setSubmitButtonState('success');
                onSuccess();
                onClose();
              }, 500);
            }
          } else {
            setSubmitButtonState(undefined);
            toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
          }
        });
      } catch {
        setSubmitButtonState(undefined);
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>,
        );
      } finally {
        setFormDisabled(false);
      }
    } else {
      setFormDisabled(true);
      setSubmitButtonState(undefined);
    }
  };

  const validateSublineExistsOnNameChange = (
    selectedLineId?: string,
    sublineName?: string,
  ) => {
    if (!isEmpty(lineId || selectedLineId) && !isEmpty(sublineName)) {
      checkSublineExistsLazy({
        variables: {
          lineId: lineId || selectedLineId,
          name: sublineName,
          sublineId: sublineId,          
          externalCode: '',
        },
      }).then((response) => {
        setInputsForm((currentInputsForm) => {
          const updatedInputs = { ...currentInputsForm };

          if (response.data.PlanConfigManagement?.queries?.checkSublineExists.length > 0) {
            sublineNameError =
              'Subline Name already exists under the same Line';
            setHasSublineNameError(
              'Subline Name already exists under the same Line',
            );
            (updatedInputs.sublineName as IFormTextDynamicProps).error =
              sublineNameError;
          } else {
            sublineNameError = '';
            setHasSublineNameError('');
            (updatedInputs.sublineName as IFormTextDynamicProps).error =
              sublineNameError;
          }

          const newSublineEnums = LookupToList(sublineEnumResults.data);

          (
            updatedInputs.sublineStatus as IFormSelectDynamicProps
          ).selectOptions = newSublineEnums['PlanConfigManagement_SublineStatuses'];
          updatedInputs.sublineStatus.value =
            currentInputsForm.sublineStatus.value;

          return updatedInputs;
        });
      });
    }
  };

  const validateSublineExistsOnExternalCodeChange = (
    selectedLineId?: string,
    sublineExternalCode?: string,
  ) => {
    if (!isEmpty(lineId || selectedLineId) && !isEmpty(sublineExternalCode)) {
      checkSublineExistsLazy({
        variables: {
          lineId: lineId || selectedLineId,
          externalCode: sublineExternalCode,
          sublineId: sublineId,
          name: '',
        },
      }).then((response) => {
        setInputsForm((currentInputsForm) => {
          const updatedInputs = { ...currentInputsForm };
          if (response.data.PlanConfigManagement?.queries?.checkSublineExists.length > 0) {
            sublineExternalCodeError =
              'External Code already exists under the same Line';
            setHasSublineExternalCodeError(
              'External Code already exists under the same Line',
            );
            (updatedInputs.sublineExternalCode as IFormTextDynamicProps).error =
              sublineExternalCodeError;
          } else {
            sublineExternalCodeError = '';
            setHasSublineExternalCodeError('');
            (updatedInputs.sublineExternalCode as IFormTextDynamicProps).error =
              sublineExternalCodeError;
          }

          const newSublineEnums = LookupToList(sublineEnumResults.data);

          (
            updatedInputs.sublineStatus as IFormSelectDynamicProps
          ).selectOptions = newSublineEnums['PlanConfigManagement_SublineStatuses'];
          updatedInputs.sublineStatus.value =
            currentInputsForm.sublineStatus.value;
          selectedLineId = Object.keys(newSublineEnums['lines'])[0];

          return updatedInputs;
        });
      });
    }
  };

  useEffect(() => {
    initialize();
  }, [lineName, sublineEnumResults.data, sublineInfoResult?.data]);

  useEffect(() => {
    const isDisabled =
      !isEmpty(hasSublineNameError) || !isEmpty(hasSublineExternalCodeError);

    setSubmitButtonDisabled(isDisabled);
  }, [hasSublineNameError, hasSublineExternalCodeError]);

  return (
    <GenericDrawer
      title={sublineId ? 'Edit Subline' : 'New Subline'}
      onClose={() => onClose()}
      isOpen={open}
    >
      {(sublineEnumResults.loading || sublineInfoResult?.loading) && open ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={'Submit'}
            submitButtonState={submitButtonState}
            isSubmitButtonDisabled={submitButtonDisabled}
            disableForm={formDisabled}
            title="Information"
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default SublineDrawer;
