import { gql } from '@apollo/client';

export function getPersonAddressEnums() {
  return gql`
    query getPersonAddressEnums {
      Core_AddressTypes: __type(name: "Core_AddressTypes") {
        name
        enumValues {
          name
        }
      }
      Insurance {
        lookups {
          countries {
            Id
            Title
          }
        }
      }
    }
  `;
}

export function createPersonAddress() {
  return gql`
    mutation createPersonAddress(
      $entityId: String!
      $addressInputs: SalesforceManagement_PersonAddressInputs!
    ) {
      salesforceManagement {
        entities {
          person {
            salesforceManagement {
              createPersonAddress(
                entityId: $entityId
                addressInputs: $addressInputs
              ) {
                id
                views {
                  SalesforceManagement_all {
                    properties {
                      PersonID {
                        id
                      }
                      AddressType
                      Address {
                        Country {
                          Title
                        }
                        Zone {
                          Title
                        }
                        City
                        Street
                        Building
                        AddressDetails
                        AddressDetailsArabic
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function updatePersonAddress() {
  return gql`
    mutation updatePersonAddress(
      $entityId: String!
      $addressInputs: SalesforceManagement_PersonAddressInputs!
    ) {
      salesforceManagement {
        entities {
          personAddress {
            salesforceManagement {
              updatePersonAddress(
                entityId: $entityId
                addressInputs: $addressInputs
              ) {
                id
              }
            }
          }
        }
      }
    }
  `;
}

export function getAddressList() {
  return gql`
    query getAddressList(
      $selectedPersonID: String
      $currentPage: Int!
      $currentPageSize: Int!
    ) {
      SalesforceManagement {
        queries {
          getPersonAddressList(
            selectedPersonID: $selectedPersonID
            pagination: { pageNumber: $currentPage, pageSize: $currentPageSize }
          ) {
            paging {
              pageSize
              pageNumber
              totalCount
            }
            items {
              salesforceManagement_PersonAddress_Id
              salesforceManagement_PersonAddress_createdOn
              salesforceManagement_PersonAddress_createdBy
              salesforceManagement_PersonAddress_modifiedOn
              salesforceManagement_PersonAddress_modifiedBy
              salesforceManagement_PersonAddress_PersonID {
                id
                views {
                  SalesforceManagement_all {
                    properties {
                      FullName
                    }
                  }
                }
              }
              salesforceManagement_PersonAddress_AddressType
              salesforceManagement_PersonAddress_Address {
                Country {
                  Title
                }
                Zone {
                  Title
                }
                City
                Street
                Building
                AddressDetails
                AddressDetailsArabic
              }
              salesforceManagement_PersonAddress_IsPrimary
            }
          }
        }
      }
    }
  `;
}

export function getPersonAddressDetailsInfo() {
  return gql`
    query getPersonAddressDetailsInfo($id: String!) {
      SalesforceManagement {
        entities {
          personAddress(id: $id) {
            views {
              SalesforceManagement_all {
                properties {
                  PersonID {
                    id
                  }
                  AddressType
                  Address {
                    City
                    Street
                    Street
                    Building
                    AddressDetails
                    AddressDetailsArabic
                    Country {
                      Title
                      Id
                    }
                    Zone {
                      Title
                      Id
                    }
                  }
                  IsPrimary
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function getZonesByCountry() {
  return gql`
    query getZonesByCountry($countryID: String!) {
      Insurance {
        lookups {
          zones(Insurance_Countries: $countryID) {
            Id
            Title
          }
        }
      }
    }
  `;
}