import dayjs from 'dayjs';
import { DynamicFormInputType, FormInputTypes } from '../../DynamicForm';
import { isEmpty } from '../../utils/validationUtils';

export const initialValues = {
  policyDetails: {
    statusReason: '',
    lineOfBusiness: '',
    plan: '',
    agencyName: '',
    agentName: '',
    title: '',
    firstName: '',
    middleName: '',
    lastName: '',
    createdBy: '',
    createdOn: '',
    modifiedBy: '',
    modifiedOn: '',
    email: '',
    mobileNumber: '',
    phoneType: '',
    phoneNumber: '',
    countryId: '',
    country: '',
    city: '',
    street: '',
    building: '',
    policyEffectiveDate: '',
    policyExpiryDate: '',
    insuredName: '',
    insuredFirstName: '',
    insuredMiddleName: '',
    insuredLastName: '',
    insuredDateOfBirth: '',
    insuredNationalityId: '',
    insuredNationality: '',
    insuredGender: '',
    insuredGenderCode: '',
    insuredPassportNumber: '',
    insuredOccupationId: '',
    insuredOccupation: '',
    productCode: '',
    policyNumber: '',
    isRenewal: '',
    renewalNumber: '',
  },
};

export const inputs: Record<string, DynamicFormInputType> = {
  policyEffectiveDate: {
    name: 'policyEffectiveDate',
    title: 'Policy Effective Date',
    type: FormInputTypes.date,
    placeholder: 'Policy Effective Date',
    value: initialValues.policyDetails.policyEffectiveDate,
    minDate: new Date(),
    maxDate:
      dayjs().month() === 11
        ? dayjs().endOf("month").toDate()
        : dayjs().add(1, "month").toDate(),
    required: true,
    disabled: false,
  },
  policyNumber: {
    name: 'policyNumber',
    title: 'Policy Number',
    type: FormInputTypes.text,
    placeholder: 'Policy Number',
    value: initialValues.policyDetails.policyNumber,
    disabled: true,
    hidden: false
  },
  isRenewal: {
    name: "isRenewal",
    title: "Renewal",
    type: FormInputTypes.chips,
    placeholder: "Renewal",
    value: !isEmpty(initialValues.policyDetails.isRenewal)
      ? initialValues.policyDetails.isRenewal
      : "" as any,
    required: true,
    multiple: false,
    triggerUpdate: true,
  },
  renewalNumber: {
    name: 'renewalNumber',
    title: 'Renewal Number',
    type: FormInputTypes.text,
    placeholder: 'Renewal Number',
    value: initialValues.policyDetails.renewalNumber,
    disabled: false,
    hidden: true
  },
  createdOn: {
    name: 'createdOn',
    title: 'Policy Issue Date',
    type: FormInputTypes.date,
    placeholder: 'Policy Issue Date',
    value: initialValues.policyDetails.createdOn,
    disabled: true,
  },
  policyExpiryDate: {
    name: 'policyExpiryDate',
    title: 'Policy Expiry Date',
    type: FormInputTypes.date,
    placeholder: 'Policy Expiry Date',
    value: initialValues.policyDetails.policyExpiryDate,
    disabled: true,
  },
  product: {
    name: 'product',
    title: 'Product',
    type: FormInputTypes.text,
    placeholder: 'Product',
    value: initialValues.policyDetails.plan,
    disabled: true,
    hidden: true
  },
  productCode: {
    name: 'productCode',
    title: 'Product Code',
    type: FormInputTypes.text,
    placeholder: 'Product',
    value: initialValues.policyDetails.productCode,
    disabled: true,
    hidden: true,
  },
  title: {
    name: 'title',
    title: 'Title',
    type: FormInputTypes.chips,
    placeholder: 'Title',
    value: !isEmpty(initialValues.policyDetails.title)
      ? initialValues.policyDetails.title
      : [],
    required: true,
    multiple: false
  },
  firstName: {
    name: 'firstName',
    title: 'First Name',
    type: FormInputTypes.text,
    placeholder: 'First Name',
    value: initialValues.policyDetails.firstName,
    required: true,
  },
  middleName: {
    name: 'middleName',
    title: 'Middle Name',
    type: FormInputTypes.text,
    placeholder: 'Middle Name',
    value: initialValues.policyDetails.middleName,
    required: true,
  },
  lastName: {
    name: 'lastName',
    title: 'Last Name',
    type: FormInputTypes.text,
    placeholder: 'Last Name',
    value: initialValues.policyDetails.lastName,
    required: true,
  },
  email: {
    name: 'email',
    title: 'Email',
    type: FormInputTypes.text,
    placeholder: 'Email',
    value: initialValues.policyDetails.email,
    required: false,
  },
  phoneType: {
    name: 'phoneType',
    title: 'Phone Type',
    type: FormInputTypes.chips,
    placeholder: 'Phone Type',
    value: !isEmpty(initialValues.policyDetails.phoneType)
      ? initialValues.policyDetails.phoneType
      : [],
    required: true,
    multiple: false,
  },
  mobileNumber: {
    name: 'mobileNumber',
    title: 'Phone Number',
    type: FormInputTypes.phoneNumber,
    placeholder: 'Phone Number',
    value: initialValues.policyDetails.phoneNumber,
    required: true,
    editCountryCode: false,
  },
  country: {
    name: 'country',
    title: 'Country',
    type: FormInputTypes.chips,
    placeholder: 'Country',
    value: !isEmpty(initialValues.policyDetails.countryId)
      ? initialValues.policyDetails.countryId
      : [],
    required: true,
    multiple: false,
  },
  city: {
    name: 'city',
    title: 'City',
    type: FormInputTypes.text,
    placeholder: 'City',
    value: initialValues.policyDetails.city,
    required: true,
  },
  street: {
    name: 'street',
    title: 'Street',
    type: FormInputTypes.text,
    placeholder: 'Street',
    value: initialValues.policyDetails.street,
    required: false,
  },
  building: {
    name: 'building',
    title: 'Building',
    type: FormInputTypes.text,
    placeholder: 'Building',
    value: initialValues.policyDetails.building || null,
    required: false,
  },
  insuredFirstName: {
    name: 'insuredFirstName',
    title: 'Insured First Name',
    type: FormInputTypes.text,
    placeholder: 'Insured First Name',
    value: initialValues.policyDetails.insuredFirstName,
    required: true,
    minCharacters: 2,
    maxCharacters: 200
  },
  insuredMiddleName: {
    name: 'insuredMiddleName',
    title: 'Insured Middle Name',
    type: FormInputTypes.text,
    placeholder: 'Insured Middle Name',
    value: initialValues.policyDetails.insuredMiddleName,
    required: false,
    minCharacters: 2,
    maxCharacters: 200
  },
  insuredLastName: {
    name: 'insuredLastName',
    title: 'Insured Last Name',
    type: FormInputTypes.text,
    placeholder: 'Insured Last Name',
    value: initialValues.policyDetails.insuredLastName,
    required: true,
    minCharacters: 2,
    maxCharacters: 200
  },
  insuredDateOfBirth: {
    name: 'insuredDateOfBirth',
    title: 'Insured Date Of Birth',
    type: FormInputTypes.date,
    placeholder: 'Insured Date Of Birth',
    value: initialValues.policyDetails.insuredDateOfBirth,
    required: true,
    disabled: true,
  },
  insuredNationality: {
    name: 'insuredNationality',
    title: 'Insured Nationality',
    type: FormInputTypes.chips,
    placeholder: 'Insured Nationality',
    value: !isEmpty(initialValues.policyDetails.insuredNationalityId)
      ? initialValues.policyDetails.insuredNationalityId
      : [],
    required: true,
    disabled: true,
    multiple: false,
  },
  insuredGender: {
    name: 'insuredGender',
    title: 'Insured Gender',
    type: FormInputTypes.chips,
    placeholder: 'Insured Gender',
    value: !isEmpty(initialValues.policyDetails.insuredGender)
      ? initialValues.policyDetails.insuredGender
      : [],
    required: true,
    disabled: true,
    multiple: false,
  },
  insuredGenderCode: {
    name: 'insuredGenderCode',
    title: 'Insured Gender Code',
    type: FormInputTypes.text,
    placeholder: 'Insured Gender Code',
    value: initialValues.policyDetails.insuredGenderCode,
    disabled: true,
    hidden: true,
  },
  insuredPassportNumber: {
    name: 'insuredPassportNumber',
    title: 'Insured Passport Number',
    type: FormInputTypes.text,
    placeholder: 'Insured Passport Number',
    value: initialValues.policyDetails.insuredPassportNumber,
    required: true,
  },
  insuredOccupation: {
    name: 'insuredOccupation',
    title: 'Insured Occupation',
    type: FormInputTypes.chips,
    placeholder: 'Insured Occupation',
    value: !isEmpty(initialValues.policyDetails.insuredOccupationId)
      ? initialValues.policyDetails.insuredOccupationId
      : [],
    required: true,
    multiple: false,
  },
};
