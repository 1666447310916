export interface ISalesforceBusinessPartnerDetailsPageProps {}

export interface ISalesforceBusinessPartnerDetails {
  name: string;
  lineName: string;
}

export const initialValues: ISalesforceBusinessPartnerDetails = {
  name: "",
  lineName: "",
};

export interface IBusinessPartnerDetailsPageProps {}

export interface IBusinessPartnerDetails {}

export const initialBusinessPartnerValues: IBusinessPartnerDetails = {};

export interface IGenericAssignedLinesTableData {
  salesforceManagement_BusinessPartnerLine_Id: string;
  salesforceManagement_BusinessPartnerLine_LineExternalCode: string;
  salesforceManagement_BusinessPartnerLine_LineID: {
    id: string;
    views: {
      PlanConfigManagement_all: {
        properties: {
          ExternalCode: string;
          Name: string;
          Status: string;
        };
      };
    };
  };
  salesforceManagement_BusinessPartnerLine_LineStatus: string;
  salesforceManagement_BusinessPartnerLine_LineCommissionType: string;
  salesforceManagement_BusinessPartnerLine_LineCommissionValue: string;
  salesforceManagement_BusinessPartnerLine_ValueCurrency: {
    Code: string;
    Title: string;
  };
  salesforceManagement_BusinessPartnerLine_LineCommissionRate: string;
  salesforceManagement_BusinessPartnerLine_ApplicableOn: {
    Id: string;
    Title: string;
  };

  salesforceManagement_BusinessPartnerLine_CreditLimit: string;
  salesforceManagement_BusinessPartnerLine_CreditLimitCurrency: {
    Id: string;
    Title: string;
  };
  salesforceManagement_BusinessPartnerLine_GracePeriod: string;
  salesforceManagement_BusinessPartnerLine_Status: string;
  salesforceManagement_BusinessPartnerLine_createdOn: string;
  salesforceManagement_BusinessPartnerLine_modifiedOn: string;
}

export interface IGenericAssignedPlansTableData {
  salesforceManagement_BusinessPartnerPlan_Id: string;

  salesforceManagement_BusinessPartnerPlan_LineID: {
    id: string;
    views: {
      PlanConfigManagement_all: {
        properties: {
          ExternalCode: string;
          Name: string;
          Status: string;
        };
      };
    };
  };

  salesforceManagement_BusinessPartnerPlan_PlanID: {
    id: string;
    views: {
      PlanConfigManagement_all: {
        properties: {
          ExternalCode: string;
          Name: string;
          Status: string;
          SpecialCommissionApplicable: string;
          SpecialCommissionType: string;
          SpecialCommissionValue: string;
          SpecialCommissionValueCurrency: {
            Code: string;
            Title: string;
          };
          SpecialCommissionRate: string;
          SpecialCommissionApplicableOn: {
            Code: string;
            Title: string;
          };
        };
      };
    };
  };

  salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID: {
    id: string;
    views: {
      SalesforceManagement_all: {
        properties: {
          Status: string;
          LineCommissionType: string;
          LineCommissionValue: string;
          ValueCurrency: {
            Code: string;
            Title: string;
          };
          LineCommissionRate: string;
          ApplicableOn: {
            Code: string;
            Title: string;
          };
        };
      };
    };
  };

  salesforceManagement_BusinessPartnerPlan_BusinessPartnerID: {
    id: string;
    views: {
      SalesforceManagement_all: {
        properties: {
          Status: string;
        };
      };
    };
  };

  line_ExternalCode: string;
  line_Name: string;
  line_Status: string;
  plan_ExternalCode: string;
  plan_Name: string;
  plan_PlanStatus: string;

  salesforceManagement_BusinessPartnerPlan_PlanCommissionType: string;
  salesforceManagement_BusinessPartnerPlan_PlanCommissionValue: string;
  salesforceManagement_BusinessPartnerPlan_ValueCurrency: {
    Code: string;
    Title: string;
  };
  salesforceManagement_BusinessPartnerPlan_PlanCommissionRate: string;

  salesforceManagement_BusinessPartnerPlan_ApplicableOn: {
    Code: string;
    Title: string;
  };
  salesforceManagement_BusinessPartnerPlan_ActiveFrom: string;
  salesforceManagement_BusinessPartnerPlan_ActiveTo: string;
  salesforceManagement_BusinessPartnerPlan_Status: string;
}

export interface IDetailedSelection {
  id: string;
  index: string;
  businessPartnerStatus: string;
  businessPartnerPlanStatus: string;
  businessPartnerPlan_BusinessPartnerLine_Status: string;
  businessPartnerPlan_Line_Status: string;
  businessPartnerPlan_Plan_Status: string;
  salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_ApplicableOn?: any;
  salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_LineCommissionRate: number | null;
  salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_LineCommissionType: string | null;
  salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_LineCommissionValue: number | null;
  salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_ValueCurrency?: any;
  salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionApplicable: boolean;
  salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionApplicableOn?: any;
  salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionRate: number | null;
  salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionType: string | null;
  salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionValue: number | null;
  salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionValueCurrency?: any;

  businessPartnerCommissionType: string | null;
  businessPartnerCommissionValue: number | null;
  businessPartnerCommissionValueCurrency?: any;
  businessPartnerCommissionRate: number | null;
  businessPartnerCommissionApplicableOn?: any;
  businessPartnerActiveFrom: Date | null;
  businessPartnerActiveTo: Date | null;
}

export interface IGenericVignetteRangesTableData {
  salesforceManagement_BusinessPartnerVignetteRange_Id: string;
  salesforceManagement_BusinessPartnerVignetteRange_BusinessPartnerID: {
    id: string;
  };
  salesforceManagement_BusinessPartnerVignetteRange_RangeFrom: number;
  salesforceManagement_BusinessPartnerVignetteRange_RangeTo: number;
  salesforceManagement_BusinessPartnerVignetteRange_RangeStatus: string;
  salesforceManagement_BusinessPartnerVignetteRange_RangeYear: number;
  salesforceManagement_BusinessPartnerVignetteRange_RangeCode: string;
}
