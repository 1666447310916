import React, { useEffect, useState } from "react";
import { IListingData } from "../../models/listing";
import StaticLayout from "../../page-layout/static-layout/StaticLayout";
import { useLazyQuery } from "@apollo/client";
import { listQuery } from "./queries";
import { mapToListingData, toLookups } from "./utils";
import { filterSectionsContent, headers } from "./content";
import EnhancedTable from "../../components/enhanced-table/EnhancedTable";
import { IListingFilterWidgetSection } from "../../components/widgets/custom-listing-filter";
import ListingFilterWidget from "../../components/widgets/custom-listing-filter/ListingFilterWidget";
import { IAbstractRecord } from "../../models";
import _, { isEmpty } from "lodash";
import { getFilter, setFilter } from "../../utils/filter-utils";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { toast } from "react-toastify";
import {
  IEnhanceTableHeaderClickable,
  IEnhancedTableMenuItem,
} from "../../components/enhanced-table";
import { useNavigate } from "react-router-dom";
import {
  DEFAULT_ERROR_TEXT,
  SEND_TO_BACKEND_DATE_FORMAT,
} from "../../constants";
import dayjs from "dayjs";
import { useAppSelector } from "../../redux/hooks";
import SalesForceBusinessPartnerDrawer from "../../forms/salesforce-business-partners-drawer/SalesForceBusinessPartnerDrawer";
interface IBusinessPartnersPage {}

const BusinessPartnersPage: React.FC<IBusinessPartnersPage> = ({}) => {
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user);

  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [tableLoadingState, setTableLoadingState] = useState<boolean>(false);
  const [businessPartnersDrawerOpen, setBusinessPartnersDrawerOpen] =
    useState<boolean>(false);
  const [chosenEditId, setChosenEditId] = useState<string>("");

  const isAdmin = user.userRoles.includes("Insurance-Admin");
  const actions: IEnhancedTableMenuItem[] = [
    {
      title: "+ New",
      onClick: () => {},
      isEntity: false,
      isBulk: false,
      iconUrl: "",
      hidden: !isAdmin,
    },
  ];

  const FILTER_SESSION_KEY = "businessPartnersFilter";

  const [filterSections, setFilterSections] =
    useState<IListingFilterWidgetSection[]>();

  const initialfilterValues =
    Object.keys(getFilter(FILTER_SESSION_KEY)).length > 0
      ? getFilter(FILTER_SESSION_KEY)
      : {
          isCompany: false,
          isLocal: false,
          isRegistered: false,
          selectedTypes: [],
          selectedRelatedCompany: [],
          businessPartnerStatus: [],
          createdOn: [],
        };

  const [filterValues, setFilterValues] =
    useState<IAbstractRecord>(initialfilterValues);

  const [tableData, setTableData] = useState<IListingData<any>>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 0,
    totalCount: 0,
  });

  const [getBusinessPartnersLazy, { loading, error, data, fetchMore }] =
    useLazyQuery(listQuery(), {
      variables: {
        currentPage: 1,
        currentPageSize: tableData.pageSize,
        selectedTypes:
          filterValues?.type && filterValues?.type?.length > 0
            ? filterValues?.type
            : [],
        isCompany:
          filterValues?.isCompany && filterValues?.isCompany?.length > 0
            ? filterValues?.isCompany.map((bool: string) => {
                return bool === "true";
              })
            : [],
        isLocal:
          filterValues?.isLocal && filterValues?.isLocal?.length > 0
            ? filterValues?.isLocal.map((bool: string) => {
                return bool === "true";
              })
            : [],
        isRegistered:
          filterValues?.isRegistered && filterValues?.isRegistered?.length > 0
            ? filterValues?.isRegistered.map((bool: string) => {
                return bool === "true";
              })
            : [],
        selectedRelatedCompany:
          filterValues?.company && filterValues?.company?.length > 0
            ? filterValues?.company
            : [],
        businessPartnerStatus:
          filterValues?.status && filterValues?.status?.length > 0
            ? filterValues?.status
            : [],
        createdDateFrom: filterValues?.createdOn[0]
          ? dayjs(filterValues.createdOn[0]).format(SEND_TO_BACKEND_DATE_FORMAT)
          : null,
        createdDateTo: filterValues?.createdOn[1]
          ? dayjs(filterValues.createdOn[1]).format(SEND_TO_BACKEND_DATE_FORMAT)
          : null,
      },
    });

  useEffect(() => {
    getBusinessPartnersLazy();
  }, []);

  useEffect(() => {
    if (loading) setLoadingState(true);
    if (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
    if (data) {
      let savedFilters = getFilter(FILTER_SESSION_KEY);
      if (isEmpty(savedFilters)) {
        savedFilters = _.cloneDeep(filterValues);
      }
      setFilterValues(savedFilters);

      const lovs = toLookups(data);

      if (!Object.keys(lovs.type).length) {
        lovs.type = filterValues?.type;
      }

      if (!Object.keys(lovs.company).length) {
        lovs.company = filterValues?.company;
      }

      if (!Object.keys(lovs.isCompany).length) {
        lovs.isCompany = filterValues?.isCompany;
      }

      if (!Object.keys(lovs.isLocal).length) {
        lovs.isLocal = filterValues?.isLocal;
      }

      if (!Object.keys(lovs.isRegistered).length) {
        lovs.isRegistered = filterValues?.isRegistered;
      }

      if (!Object.keys(lovs.status).length) {
        lovs.status = filterValues?.status;
      }

      const newFilterSections = filterSectionsContent(lovs, savedFilters);
      setFilterSections(newFilterSections);
    }
    setLoadingState(false);
  }, [data]);

  useEffect(() => {
    if (loading) setTableLoadingState(true);
    if (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
    if (data) {
      const tableData = mapToListingData(data);

      setTableData({ ...tableData, pageNumber: 0 });
    }
    setTableLoadingState(false);
  }, [loading, error, data]);

  function handlePageChange(page: number) {
    setTableLoadingState(true);
    fetchMore({
      variables: {
        currentPage: ++page,
        currentPageSize: tableData.pageSize,
      },
    })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const newApplicationList = mapToListingData(fetchMoreResult.data);

          const updatedTableData = {
            ...newApplicationList,
            pageNumber: --page,
          };
          setTableData(updatedTableData);
        }
      })
      .finally(() => {
        setTableLoadingState(false);
      });
  }

  function handleRowsPerPageChange(numberOfRecordsPerPage: number) {
    setTableLoadingState(true);
    setTableData({ ...tableData, pageNumber: 1 });
    fetchMore({
      variables: {
        currentPage: 1,
        currentPageSize: numberOfRecordsPerPage,
      },
    })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const newApplicationList = mapToListingData(fetchMoreResult.data);

          const updatedTableData = {
            ...newApplicationList,
            pageNumber: 0,
            pageSize: numberOfRecordsPerPage,
          };
          setTableData(updatedTableData);
        }
      })
      .finally(() => {
        setTableLoadingState(false);
      });
  }

  actions[0].onClick = () => {
    setChosenEditId(undefined);
    setBusinessPartnersDrawerOpen(true);
  };

  (headers.code as IEnhanceTableHeaderClickable).callback = (payload: any) => {
    navigate("/salesforce/business-partners/" + payload.columns.id);
  };

  (headers.name as IEnhanceTableHeaderClickable).callback = (payload: any) => {
    navigate("/salesforce/business-partners/" + payload.columns.id);
  };

  const renderMainChildren = () => {
    return (
      <>
        {loading ? (
          <div></div>
        ) : (
          <div style={{ marginTop: "20px" }}>
            <EnhancedTable
              title="Business Partners"
              name="count"
              orderByAscendingByDefault
              inline={false}
              data={tableData}
              headers={headers}
              handlePageChange={(page: number) => handlePageChange(page)}
              handleRowsPerPageChange={(page: number) =>
                handleRowsPerPageChange(page)
              }
              currentPage={tableData.pageNumber}
              hideToolbar
              usePagination
              disableSelection
              actions={actions}
              loader={tableLoadingState}
              showCellFullData={true}
            />
            {businessPartnersDrawerOpen && (
              <SalesForceBusinessPartnerDrawer
                open={businessPartnersDrawerOpen}
                onClose={() => setBusinessPartnersDrawerOpen(false)}
                onSuccess={() => {
                  handlePageChange(0);
                }}
                personId={chosenEditId}
                salesForceBusinessPartnerDetailsInfo={undefined}
              />
            )}
          </div>
        )}
      </>
    );
  };

  const onFilterUpdate = async (v: Record<string, any>) => {
    let newFilters = _.cloneDeep(v);
    setFilter(newFilters, FILTER_SESSION_KEY);
    setFilterValues(newFilters);
  };

  const renderFilter = () => {
    return (
      filterSections && (
        <ListingFilterWidget
          name={""}
          filters={filterSections}
          onApplyFilter={(v) => {
            onFilterUpdate(v);
          }}
        ></ListingFilterWidget>
      )
    );
  };

  return (
    <StaticLayout
      loading={loading || loadingState}
      name={"Applications"}
      leftChildren={renderFilter()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default BusinessPartnersPage;
