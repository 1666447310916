import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITenant, LoadTenantSuccessAction } from './types';

const initialState: ITenant = {
  systemReferenceDate: '',
  phoneNumberPattern: '',
  name: '',
  backgroundTemplate: '',
  apiUrl: '',
  cdnUrl: '',
  enrolleeApiUrl: '',
  signalRUrl: '',
  schedulerUrl: '',
  tenantId: '',
  header: {
    appLauncherUrl: '',
    logoAlt: '',
    logoUrl: '',
  },
  login: {
    background: '',
    logoUrl: '',
    logoHeight: '',
    logoWidth: '',
    btnBackgroundColor: '',
    btnFontColor: '',
  },
  theme: {
    palette: {
      primary1: {
        main: '',
      },
      primary2: {
        main: '',
        lighter: '',
      },
      primary3: {
        main: '',
      },
      primary4: {
        main: '',
      },
      primary5: {
        main: '',
      },
      secondary1: {
        main: '#5A5A5A',
      },
      secondary2: {
        main: '#CECECE',
      },
      secondary3: {
        main: '#E5E5E5',
        light: '#DBDBDB',
      },
      secondary4: {
        main: '#F7F7F7',
        light: 'rgba(247, 247, 247, 0.59)',
        lighter: 'rgba(247, 247, 247, 0.49)',
      },
      secondary5: {
        main: '#f7f7f7',
      },
      error: {
        main: 'red',
      },
    },
    typography: {
      small: {
        sm1: {
          fontSize: 10,
        },
        sm2: {
          fontSize: 11,
        },
        sm3: {
          fontSize: 12,
        },
      },
      regular: {
        reg1: {
          fontSize: 13,
        },
        reg2: {
          fontSize: 14,
        },
        reg3: {
          fontSize: 16,
        },
        reg4: {
          fontSize: 18,
        },
      },
      medium: {
        med1: {
          fontSize: 21,
        },
        med2: {
          fontSize: 22,
        },
      },
      big: {
        big1: {
          fontSize: 30,
        },
        big2: {
          fontSize: 32,
        },
      },
    },
  },
  astAccountName: '',
  aircall: false,
  currencySymbol: '',
  dateFormat: '',
};

const tenantSlice = createSlice({
  name: 'tenant',
  initialState: initialState,
  reducers: {
    LoadTenantSuccess(state, action: PayloadAction<LoadTenantSuccessAction>) {
      state = { ...state, ...action.payload.tenant };
      return state;
    },
  },
});

export const { LoadTenantSuccess } = tenantSlice.actions;

const tenantReducer = tenantSlice.reducer;
export default tenantReducer;
