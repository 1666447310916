import { useApolloClient, useLazyQuery, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { IPlanDetailsPageProps, IPlanDetails, initialValues } from ".";
import Loader from "../../components/Loader";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { IEntityInfoView } from "../../components/widgets/entity-info/EntityInfoFields";
import EntityInfoWidget from "../../components/widgets/entity-info/EntityInfoWidget";
import {
  DEFAULT_ERROR_TEXT,
  MAIN_ONE_THEME,
  contentFontFamilyBold,
} from "../../constants";
import {
  allRiskPlanRatesHeaders,
  entityView,
  expatPlanRatesHeaders,
  planTabs,
  steps,
  travelPlanRatesHeaders,
  medicalPlanRatesHeaders,
  planCoversRatesHeaders,
} from "./content";
import {
  activatePlan,
  deactivatePlan,
  getPlanDetailsInfo,
  getPlanSpecificAllRiskList,
  getPlanSpecificExpatList,
  getPlanSpecificMedicalList,
  getPlanSpecificTravelList,
  getPlanCoversRatesList,
} from "./queries";
import {
  entityToPlanDetails,
  getPlanStatus,
  mapToAllRiskPlanRatesListingData,
  mapToExpatPlanRatesListingData,
  mapToMedicalPlanRatesListingData,
  mapToPlanCoversRatesListingData,
  mapToTravelPlanRatesListingData,
} from "./utils";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import dayjs from "dayjs";
import EnhancedStepperSpecificDesign from "../../components/common/EnhancedStepperSpecificDesign";
import { makeStyles } from "tss-react/mui";
import { EnhancedDisplayType } from "../../components/enhanced-form/EnhancedDisplay";
import PlanDrawer from "../plan-drawer/PlanDrawer";
import EnhancedButton, {
  EnhancedButtonStatus,
} from "../../components/EnhancedButton";
import { IConfirmation } from "../../redux/confirmation/types";
import { isEmpty } from "lodash";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import { getError } from "../../utils/graph-utils";
import { OpenConfirmationAction } from "../../redux/confirmation/actions";
import TabsLayout from "../../page-layout/tabs-layout/TabsLayout";
import { IDynamicDiplayView } from "../../components/widgets/dynamic-display";
import {
  capitalizeFirstLetter,
  valueCommaSeparated,
} from "../../utils/formatting-utils";
import DynamicWidget from "../../components/widgets/dynamic-display/DynamicWidget";
import ShowForUser from "../../components/user/ShowForUser";
import PlanDetailsDefaultCommissionDrawer from "../plan-details-default-commission-drawer/PlanDetailsDefaultCommissionDrawer";
import WidgetSection from "../../components/common/WidgetSection";
import EnhancedTable from "../../components/enhanced-table/EnhancedTable";
import { IListingData } from "../../models/listing";
import {
  IEnhancedTableMenuItem,
  IEnhanceTableHeaderClickable,
} from "../../components/enhanced-table";
import PlanInformationDrawer from "../plan-information-drawer/PlanInformationDrawer";
import AllRiskPlanRatesDrawer from "../plan-allRisk-rates-drawer/AllRiskPlanRates";
import ExpatPlanRatesDrawer from "../plan-expat-rates-drawer/ExpatPlanRates";
import TravelPlanRatesDrawer from "../plan-travel-rates-drawer/TravelPlanRates";
import MedicalPlanRatesDrawer from "../plan-medical-rates-drawer/MedicalPlanRates";
import PlanAdditionalFeesAndChargesDrawer from "../plan-additional-fees-drawer/PlanAdditionalFeesAndChargesDrawer";
import AssignPolicyCoverDrawer from "../assign-policy-cover-drawer/AssignPolicyCoverDrawer";

const useStyles = makeStyles()(() => ({
  stepper: {
    backgroundColor: "transparent",
    maxWidth: "90%",
    marginBottom: "20px",
    "& .MuiStepLabel-root .Mui-active, .MuiStepLabel-root ": {
      color: MAIN_ONE_THEME.palette.primary5.main,
      "& .MuiStepIcon-text": {
        fontSize: 0,
        lineHeight: "0px",
        fill: "black",
      },
    },

    ".Mui-completed": {
      color: MAIN_ONE_THEME.palette.primary5.main,
      "& .MuiStepIcon-text": {
        fontSize: 0,
        lineHeight: "0px",
        fill: "black",
      },
    },

    "@media only screen and (min-width: 600px)": {
      maxWidth: "1300px",
    },
  },
  section: {
    display: "grid",
    "grid-template-columns": "19.5% 19.5% 19.5% 19.5% 19.5%",
    justifyContent: "space-between",
    flexWrap: "wrap",

    "& label": {
      "& span": {
        margin: "-2px 0",
      },
    },
  },
  sectionTitle: {
    padding: "-10x 10x!important",
  },
  sectionFullRow: {
    display: "grid",
    "grid-template-columns": "100%",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  row: {
    flexWrap: "wrap",
    margin: "15px 20px 10px 0px",
    alignItems: "flex-start",
    display: "grid",
    gap: "5px",
    "& label": {
      flexGrow: "1",
      flexBasis: "0",
      minWidth: "0",
    },
    "grid-template-columns": "19.5% 19.5% 19.5% 19% 16% 3% 3%",
  },
  thickSeperator: {
    height: 10,
    margin: "10px 0 10px 0",
    "grid-column-start": "1",
    "grid-column-end": "4",
  },
  field: {
    width: "96%",
    marginRight: "10px !important",
  },
  repeaterField: {
    margin: "0px!important",
  },
  arField: {
    width: "96%",
    marginRight: "10px !important",
    direction: "rtl",
  },
  header: {
    fontFamily: contentFontFamilyBold,
    fontSize: MAIN_ONE_THEME.typography.regular.reg3.fontSize,
    color: MAIN_ONE_THEME.palette.primary2.main,
    "&:hover": {
      color: MAIN_ONE_THEME.palette.primary1.main,
    },
  },
  content: {
    padding: "0",
  },
  dirtyChip: {
    margin: "0 0 -15px 0",
    backgroundColor: MAIN_ONE_THEME.palette.primary2.main,
    "& span": {
      color: "#ffffff",
    },
  },
  tabPanelStyle: {
    padding: "unset",
    marginTop: "20px",
  },
  actionButton: {
    display: "inline-block",
    marginRight: "5px",
  },
}));

const PlanDetailsPage: React.FC<IPlanDetailsPageProps> = () => {
  //#region react and redux hooks
  const { classes } = useStyles();
  const params = useParams();
  const dispatch = useAppDispatch();
  const tenant = useAppSelector((state) => state.tenant);
  const user = useAppSelector((state) => state.user);
  const client = useApolloClient();
  const navigate = useNavigate();

  const [activateButtonState, setActivateButtonState] =
    useState<EnhancedButtonStatus>();
  const [deactivateButtonState, setDeactivateButtonState] =
    useState<EnhancedButtonStatus>();
  const [planCurrency, setPlanCurrency] = useState<string>("");
  const [planCurrencyTitle, setPlanCurrencyTitle] = useState<string>("");

  const [planDrawerOpen, setPlanDrawerOpen] = useState<boolean>(false);
  const [planInformationDrawerOpen, setPlanInformationDrawerOpen] =
    useState<boolean>(false);
  const [defaultCommissionDrawerOpen, setDefaultCommissionDrawerOpen] =
    useState<boolean>(false);
  const [allRiskPlanRatesDrawerOpen, setAllRiskPlanRatesDrawerOpen] =
    useState<boolean>(false);
  const [expatPlanRatesDrawerOpen, setExpatPlanRatesDrawerOpen] =
    useState<boolean>(false);
  const [travelPlanRatesDrawerOpen, setTravelPlanRatesDrawerOpen] =
    useState<boolean>(false);
  const [medicalPlanRatesDrawerOpen, setMedicalPlanRatesDrawerOpen] =
    useState<boolean>(false);
  const [planCoversRatesDrawerOpen, setPlanCoversRatesDrawerOpen] =
    useState<boolean>(false);
  const [
    additionalFeesandChargesDrawerOpen,
    setAdditionalFeesandChargesDrawerOpen,
  ] = useState<boolean>(false);

  const [planInformationViewConfig, setPlanInformationViewConfig] =
    useState<IDynamicDiplayView>();
  const [coversInformationViewConfig, setCoversInformationViewConfig] =
    useState<IDynamicDiplayView>();
  const [defaultCommissionViewConfig, setDefaultCommissionViewConfig] =
    useState<IDynamicDiplayView>();
  const [
    additionalFeesandChargesViewConfig,
    setAdditionalFeesandChargesViewConfig,
  ] = useState<IDynamicDiplayView>();

  const [selectedAllRiskPlanRatesItems, setAllRiskPlanRatesItems] =
    useState<number>(0);
  const [selectedAllRiskPlanRatesId, setAllRiskPlanRatesId] =
    useState<string>("");
  const [allRiskActionClicked, setAllRiskActionClicked] = useState(null);

  const [selectedExpatPlanRatesItems, setExpatPlanRatesItems] =
    useState<number>(0);
  const [selectedExpatPlanRatesId, setExpatPlanRatesId] = useState<string>("");
  const [expatActionClicked, setExpatActionClicked] = useState(null);

  const [selectedTravelPlanRatesItems, setTravelPlanRatesItems] =
    useState<number>(0);
  const [selectedTravelPlanRatesId, setTravelPlanRatesId] =
    useState<string>("");
  const [travelActionClicked, setTravelActionClicked] = useState(null);

  const [selectedMedicalPlanRatesItems, setMedicalPlanRatesItems] =
    useState<number>(0);
  const [selectedMedicalPlanRatesId, setMedicalPlanRatesId] =
    useState<string>("");
  const [medicalActionClicked, setMedicalActionClicked] = useState(null);

  const [selectedPlanCoversRatesItems, setPlanCoversRatesItems] =
    useState<number>(0);
  const [selectedPlanCoversRatesId, setPlanCoversRatesId] =
    useState<string>("");
  const [planCoversRatesActionClicked, setPlanCoversRatesActionClicked] =
    useState(null);

  //#endregion

  //#region component hooks and states
  const [lineExternalCode, setLineExternalCode] = useState<string>("");

  const [planDetailsInfo, setPlanDetailsInfo] =
    useState<IPlanDetails>(initialValues);

  const [updatedLeftSideInfo, setUpdatedLeftSideInfo] =
    useState<IEntityInfoView>(entityView);

  const [
    allRiskPlanRatesTableLoadingState,
    setAllRiskPlanRatesTableLoadingState,
  ] = useState<boolean>(false);
  const [expatPlanRatesTableLoadingState, setExpatPlanRatesTableLoadingState] =
    useState<boolean>(false);
  const [
    travelPlanRatesTableLoadingState,
    setTravelPlanRatesTableLoadingState,
  ] = useState<boolean>(false);
  const [
    medicalPlanRatesTableLoadingState,
    setMedicalPlanRatesTableLoadingState,
  ] = useState<boolean>(false);
  const [
    planCoversRatesTableLoadingState,
    setPlanCoversRatesTableLoadingState,
  ] = useState<boolean>(false);

  const [loadingState, setLoadingState] = useState<boolean>(true);

  const entityId = params.id;

  const [getPlanDetailsLazy, entityResult] = useLazyQuery(
    getPlanDetailsInfo(),
    {
      variables: { id: entityId },
      errorPolicy: "all",
      fetchPolicy: "no-cache",
    }
  );

  const [allRiskPlanRatesTableData, setAllRiskPlanRatesTableData] = useState<
    IListingData<any>
  >({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 1,
    totalCount: 0,
  });

  const [expatPlanRatesTableData, setExpatPlanRatesTableData] = useState<
    IListingData<any>
  >({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 1,
    totalCount: 0,
  });

  const [travelPlanRatesTableData, setTravelPlanRatesTableData] = useState<
    IListingData<any>
  >({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 1,
    totalCount: 0,
  });

  const [medicalPlanRatesTableData, setMedicalPlanRatesTableData] = useState<
    IListingData<any>
  >({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 1,
    totalCount: 0,
  });

  const [planCoversRatesTableData, setPlanCoversRatesTableData] = useState<
    IListingData<any>
  >({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 1,
    totalCount: 0,
  });

  const [activateAction] = useMutation(activatePlan(), {
    variables: {
      entityId: entityId,
    },
    errorPolicy: "all",
    refetchQueries: [getPlanDetailsInfo()],
  });
  const [deactivateAction] = useMutation(deactivatePlan(), {
    variables: {
      entityId: entityId,
    },
    refetchQueries: [getPlanDetailsInfo()],
  });

  const [getPlanSpecificAllRiskListLazy, allRiskPlanRatesListResult] =
    useLazyQuery(getPlanSpecificAllRiskList(), {
      variables: {
        currentPage: 1,
        currentPageSize: allRiskPlanRatesTableData.pageSize,
        selectedPlanIds: [entityId],
      },
      errorPolicy: "all",
    });
  const [getPlanSpecificExpatListLazy, expatPlanRatesListResult] = useLazyQuery(
    getPlanSpecificExpatList(),
    {
      variables: {
        currentPage: 1,
        currentPageSize: expatPlanRatesTableData.pageSize,
        selectedPlanIds: [entityId],
      },
      errorPolicy: "all",
    }
  );
  const [getPlanSpecificTravelListLazy, travelPlanRatesListResult] =
    useLazyQuery(getPlanSpecificTravelList(), {
      variables: {
        currentPage: 1,
        currentPageSize: expatPlanRatesTableData.pageSize,
        selectedPlanIds: [entityId],
      },
      errorPolicy: "all",
    });

  const [getPlanSpecificMedicalListLazy, medicalPlanRatesListResult] =
    useLazyQuery(getPlanSpecificMedicalList(), {
      variables: {
        currentPage: 1,
        currentPageSize: medicalPlanRatesTableData.pageSize,
        selectedPlanIds: [entityId],
      },
      errorPolicy: "all",
    });
  const [getPlanCoversRatesListLazy, planCoversRatesListResult] = useLazyQuery(
    getPlanCoversRatesList(),
    {
      variables: {
        currentPage: 1,
        currentPageSize: planCoversRatesTableData.pageSize,
        selectedPlanIds: [entityId],
      },
      errorPolicy: "all",
    }
  );

  function handleAllRiskPlanRatesPageChange(page: number) {
    setAllRiskPlanRatesTableLoadingState(true);
    allRiskPlanRatesListResult
      ?.fetchMore({
        variables: {
          currentPage: ++page,
          currentPageSize: allRiskPlanRatesTableData.pageSize,
        },
      })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const newApplicationList = mapToAllRiskPlanRatesListingData(
            fetchMoreResult.data,
            planCurrencyTitle
          );
          const updatedTableData = {
            ...newApplicationList,
            pageNumber: --page,
          };
          setAllRiskPlanRatesTableData(updatedTableData);
        }
      })
      .finally(() => {
        setAllRiskPlanRatesTableLoadingState(false);
      });
  }
  function handleAllRiskPlanRatesRowsPerPageChange(
    numberOfRecordsPerPage: number
  ) {
    setAllRiskPlanRatesTableLoadingState(true);
    setAllRiskPlanRatesTableData({
      ...allRiskPlanRatesTableData,
      pageNumber: 1,
    });
    allRiskPlanRatesListResult
      ?.fetchMore({
        variables: {
          currentPage: 1,
          currentPageSize: numberOfRecordsPerPage,
        },
      })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const newApplicationList = mapToAllRiskPlanRatesListingData(
            fetchMoreResult.data,
            planCurrencyTitle
          );

          const updatedTableData = {
            ...newApplicationList,
            pageNumber: 0,
            pageSize: numberOfRecordsPerPage,
          };
          setAllRiskPlanRatesTableData(updatedTableData);
        }
      })
      .finally(() => {
        setAllRiskPlanRatesTableLoadingState(false);
      });
  }

  function handleExpatPlanRatesPageChange(page: number) {
    setExpatPlanRatesTableLoadingState(true);
    expatPlanRatesListResult
      ?.fetchMore({
        variables: {
          currentPage: ++page,
          currentPageSize: expatPlanRatesTableData.pageSize,
        },
      })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const newApplicationList = mapToExpatPlanRatesListingData(
            fetchMoreResult.data,
            planCurrencyTitle
          );
          const updatedTableData = {
            ...newApplicationList,
            pageNumber: --page,
          };
          setExpatPlanRatesTableData(updatedTableData);
        }
      })
      .finally(() => {
        setExpatPlanRatesTableLoadingState(false);
      });
  }
  function handleExpatPlanRatesRowsPerPageChange(
    numberOfRecordsPerPage: number
  ) {
    setExpatPlanRatesTableLoadingState(true);
    setExpatPlanRatesTableData({ ...expatPlanRatesTableData, pageNumber: 1 });
    expatPlanRatesListResult
      ?.fetchMore({
        variables: {
          currentPage: 1,
          currentPageSize: numberOfRecordsPerPage,
        },
      })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const newApplicationList = mapToExpatPlanRatesListingData(
            fetchMoreResult.data,
            planCurrencyTitle
          );

          const updatedTableData = {
            ...newApplicationList,
            pageNumber: 0,
            pageSize: numberOfRecordsPerPage,
          };
          setExpatPlanRatesTableData(updatedTableData);
        }
      })
      .finally(() => {
        setExpatPlanRatesTableLoadingState(false);
      });
  }

  function handleTravelPlanRatesPageChange(page: number) {
    setTravelPlanRatesTableLoadingState(true);
    travelPlanRatesListResult
      ?.fetchMore({
        variables: {
          currentPage: ++page,
          currentPageSize: travelPlanRatesTableData.pageSize,
        },
      })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const newApplicationList = mapToTravelPlanRatesListingData(
            fetchMoreResult.data,
            planCurrencyTitle
          );
          const updatedTableData = {
            ...newApplicationList,
            pageNumber: --page,
          };
          setTravelPlanRatesTableData(updatedTableData);
        }
      })
      .finally(() => {
        setTravelPlanRatesTableLoadingState(false);
      });
  }
  function handleTravelPlanRatesRowsPerPageChange(
    numberOfRecordsPerPage: number
  ) {
    setTravelPlanRatesTableLoadingState(true);
    setTravelPlanRatesTableData({ ...travelPlanRatesTableData, pageNumber: 1 });
    travelPlanRatesListResult
      ?.fetchMore({
        variables: {
          currentPage: 1,
          currentPageSize: numberOfRecordsPerPage,
        },
      })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const newApplicationList = mapToTravelPlanRatesListingData(
            fetchMoreResult.data,
            planCurrencyTitle
          );

          const updatedTableData = {
            ...newApplicationList,
            pageNumber: 0,
            pageSize: numberOfRecordsPerPage,
          };
          setTravelPlanRatesTableData(updatedTableData);
        }
      })
      .finally(() => {
        setTravelPlanRatesTableLoadingState(false);
      });
  }

  function handleMedicalPlanRatesPageChange(page: number) {
    setMedicalPlanRatesTableLoadingState(true);
    medicalPlanRatesListResult
      ?.fetchMore({
        variables: {
          currentPage: ++page,
          currentPageSize: medicalPlanRatesTableData.pageSize,
        },
      })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const newApplicationList = mapToMedicalPlanRatesListingData(
            fetchMoreResult.data,
            planCurrencyTitle
          );
          const updatedTableData = {
            ...newApplicationList,
            pageNumber: --page,
          };
          setMedicalPlanRatesTableData(updatedTableData);
        }
      })
      .finally(() => {
        setMedicalPlanRatesTableLoadingState(false);
      });
  }
  function handleMedicalPlanRatesRowsPerPageChange(
    numberOfRecordsPerPage: number
  ) {
    setMedicalPlanRatesTableLoadingState(true);
    setMedicalPlanRatesTableData({
      ...medicalPlanRatesTableData,
      pageNumber: 1,
    });
    medicalPlanRatesListResult
      ?.fetchMore({
        variables: {
          currentPage: 1,
          currentPageSize: numberOfRecordsPerPage,
        },
      })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const newApplicationList = mapToMedicalPlanRatesListingData(
            fetchMoreResult.data,
            planCurrencyTitle
          );

          const updatedTableData = {
            ...newApplicationList,
            pageNumber: 0,
            pageSize: numberOfRecordsPerPage,
          };
          setMedicalPlanRatesTableData(updatedTableData);
        }
      })
      .finally(() => {
        setMedicalPlanRatesTableLoadingState(false);
      });
  }

  function handlePlanCoversRatesPageChange(page: number) {
    setPlanCoversRatesTableLoadingState(true);
    planCoversRatesListResult
      ?.fetchMore({
        variables: {
          currentPage: ++page,
          currentPageSize: planCoversRatesTableData.pageSize,
        },
      })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const newApplicationList = mapToPlanCoversRatesListingData(
            fetchMoreResult.data
          );
          const updatedTableData = {
            ...newApplicationList,
            pageNumber: --page,
          };
          setPlanCoversRatesTableData(updatedTableData);
        }
      })
      .finally(() => {
        setPlanCoversRatesTableLoadingState(false);
      });
  }
  function handlePlanCoversRatesRowsPerPageChange(
    numberOfRecordsPerPage: number
  ) {
    setPlanCoversRatesTableLoadingState(true);
    setPlanCoversRatesTableData({
      ...planCoversRatesTableData,
      pageNumber: 1,
    });
    planCoversRatesListResult
      ?.fetchMore({
        variables: {
          currentPage: 1,
          currentPageSize: numberOfRecordsPerPage,
        },
      })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const newApplicationList = mapToPlanCoversRatesListingData(
            fetchMoreResult.data
          );

          const updatedTableData = {
            ...newApplicationList,
            pageNumber: 0,
            pageSize: numberOfRecordsPerPage,
          };
          setPlanCoversRatesTableData(updatedTableData);
        }
      })
      .finally(() => {
        setPlanCoversRatesTableLoadingState(false);
      });
  }

  //#region useEffect

  useEffect(() => {
    getPlanDetailsLazy();
    getPlanCoversRatesListLazy();
  }, []);

  useEffect(() => {
    if (lineExternalCode === "51") {
      getPlanSpecificAllRiskListLazy();
    }
    if (lineExternalCode === "43") {
      getPlanSpecificExpatListLazy();
    }
    if (lineExternalCode === "48") {
      getPlanSpecificTravelListLazy();
    }
    if (lineExternalCode === "6") {
      getPlanSpecificMedicalListLazy();
    }
  }, [lineExternalCode]);

  useEffect(() => {
    try {
      if (!entityResult.loading) {
        setLoadingState(false);
      }

      if (entityResult?.data) {
        const planDetails = entityToPlanDetails(entityResult?.data);
        setLineExternalCode(planDetails.lineIdExternalCode);
        setPlanCurrency(planDetails.planCurrency);
        setPlanCurrencyTitle(planDetails.planCurrencyTitle);

        //get application section properties
        const applicationProperties = entityView.sections[0];

        applicationProperties.properties.sublineName = {
          title: "Subline Name",
          name: "SublineName",
          multiline: false,
          value: planDetails.sublineName,
          type: EnhancedDisplayType.RelativeLink,
          tooltip: false,
          url:
            "/plan/sublines/" +
            entityResult?.data?.PlanConfigManagement?.entities?.plan?.views
              ?.PlanConfigManagement_all?.properties?.SublineID?.id,
        };
        applicationProperties.properties.lineName = {
          title: "Line",
          name: "Line",
          multiline: false,
          value: planDetails.lineName,
          type: EnhancedDisplayType.RelativeLink,
          tooltip: false,
          url:
            "/plan/lines/" +
            entityResult?.data?.PlanConfigManagement?.entities?.plan?.views
              ?.PlanConfigManagement_all?.properties?.LineID?.id,
        };

        //get the system propeties section
        const systemProperties = entityView.sections[1];
        systemProperties.properties.createdBy.value = planDetails.createdBy;
        systemProperties.properties.createdOn.value = dayjs(
          new Date(planDetails.createdOn)
        ).format("DD/MM/YYYY");
        systemProperties.properties.modifiedBy.value = planDetails.modifiedBy;
        systemProperties.properties.modifiedOn.value = dayjs(
          new Date(planDetails.modifiedOn)
        ).format("DD/MM/YYYY");

        const newLeftEntityView = {
          ...entityView,
          sections: [applicationProperties, systemProperties],
        };

        setPlanDetailsInfo(planDetails);

        setUpdatedLeftSideInfo(newLeftEntityView);

        const summaryDynamicView: IDynamicDiplayView = {
          name: "Plan Details",
          sections: [
            {
              name: "generalDetails",
              title: "General Details",
              showActionButtons: true,
              properties: {
                planName: {
                  multiline: false,
                  value: planDetails.planName,
                  name: "planName",
                  title: "Plan Name",
                },
                externalCode: {
                  multiline: false,
                  value: planDetails.externalCode,
                  name: "externalCode",
                  title: "External Code",
                },
                arabicName: {
                  multiline: false,
                  value: planDetails.arabicName,
                  name: "arabicName",
                  title: "Arabic Name",
                },
                abbreviation: {
                  multiline: false,
                  value: planDetails.abbreviation,
                  name: "abbreviation",
                  title: "Abbreviation",
                },
                effectiveFrom: {
                  multiline: false,
                  value: dayjs(new Date(planDetails.effectiveFrom)).format(
                    "DD/MM/YYYY"
                  ),
                  name: "effectiveFrom",
                  title: "Effective From",
                },
                effectiveTo: {
                  multiline: false,
                  value: dayjs(new Date(planDetails.effectiveTo)).format(
                    "DD/MM/YYYY"
                  ),
                  name: "effectiveTo",
                  title: "Effective To",
                },
                planCurrency: {
                  multiline: false,
                  value: planDetails.planCurrencyTitle,
                  name: "planCurrency",
                  title: "Plan Currency",
                },
                maxNumberofInsured: {
                  multiline: false,
                  value: planDetails.maxNumberofInsured,
                  name: "maxNumberofInsured",
                  title: "Max Number of Insured",
                },
                maxNumberofBeneficiary: {
                  multiline: false,
                  value: planDetails.maxNumberofBeneficiary,
                  name: "maxNumberofBeneficiary",
                  title: "Max Number of Beneficiary",
                },
                clauseEditable: {
                  multiline: false,
                  value: planDetails.clauseEditable? "Yes" : "No",
                  name: "clauseEditable",
                  title: "Clause Editable",
                },
              },
            },
          ],
        };

        if (
          planDetails.lineIdExternalCode === "51" ||
          planDetails.lineIdExternalCode === "50" ||
          planDetails.lineIdExternalCode === "4"
        ) {
          summaryDynamicView.sections.push({
            name: "additionalDetails",
            title: "Additional Details",
            showActionButtons: false,
            properties: {
              acalCategory: {
                multiline: false,
                value: planDetails.acalCategoryTitle,
                name: "acalCategory",
                title: "Acal Category",
                hidden: !(
                  planDetails.lineIdExternalCode === "51" ||
                  planDetails.lineIdExternalCode === "50" ||
                  planDetails.lineIdExternalCode === "4"
                ),
              },
              sosService: {
                multiline: false,
                value: planDetails.sOSServiceTitle,
                name: "sosService",
                title: "SOS Service",
                hidden: !(
                  planDetails.lineIdExternalCode === "51" ||
                  planDetails.lineIdExternalCode === "4"
                ),
              },
              replacementCarApplied: {
                multiline: false,
                value: planDetails.replacementCarAppliedTitle,
                name: "replacementCarApplied",
                title: "Replacement Car Applied",
                hidden: !(planDetails.lineIdExternalCode === "51"),
              },
              replacementCarValue: {
                multiline: false,
                value: planDetails.replacementCarValue,
                name: "replacementCarValue",
                title: "Replacement Car Value",
                hidden: !(planDetails.lineIdExternalCode === "51"),
              },
              replacementCarDays: {
                multiline: false,
                value: planDetails.replacementCarDays,
                name: "replacementCarDays",
                title: "Replacement Car Days",
                hidden: !(planDetails.lineIdExternalCode === "51"),
              },
            },
          });
        }
        setPlanInformationViewConfig(summaryDynamicView);

        const coversDynamicView: IDynamicDiplayView = {
          name: "Plan Details",
          sections: [
            {
              name: "generalDetails",
              title: "General Details",
              showActionButtons: true,
              properties: {
                planName: {
                  multiline: false,
                  value: planDetails.relatedCompanyTitle,
                  name: "planName",
                  title: "Plan Name",
                },
                externalCode: {
                  multiline: false,
                  value: capitalizeFirstLetter(planDetails.typeTitle),
                  name: "externalCode",
                  title: "External Code",
                },
                arabicName: {
                  multiline: false,
                  value: planDetails.categoryTitle,
                  name: "arabicName",
                  title: "Arabic Name",
                },
                abbreviation: {
                  multiline: false,
                  value: planDetails.isCompany ? "Yes" : "No",
                  name: "abbreviation",
                  title: "Abbreviation",
                },
                effectiveFrom: {
                  multiline: false,
                  value: planDetails.isLocal ? "Yes" : "No",
                  name: "effectiveFrom",
                  title: "Effective From",
                },
                effectiveTo: {
                  multiline: false,
                  value: planDetails.isRegistered ? "Yes" : "No",
                  name: "effectiveTo",
                  title: "Effective To",
                },
                planCurrency: {
                  multiline: false,
                  value: planDetails.registerNumber,
                  name: "planCurrency",
                  title: "Plan Currency",
                },
              },
            },
            {
              name: "additionalDetails",
              title: "Additional Details",
              showActionButtons: false,
              hidden: !(
                lineExternalCode === "51" ||
                lineExternalCode === "50" ||
                lineExternalCode === "4"
              ),
              properties: {
                acalCategory: {
                  multiline: false,
                  value: capitalizeFirstLetter(planDetails.smoker),
                  name: "acalCategory",
                  title: "Acal Category",
                  hidden: !(
                    lineExternalCode === "51" ||
                    lineExternalCode === "50" ||
                    lineExternalCode === "4"
                  ),
                },
                sosService: {
                  multiline: false,
                  value: planDetails.weight,
                  name: "sosService",
                  title: "SOS Service",
                  hidden: !(
                    lineExternalCode === "51" || lineExternalCode === "4"
                  ),
                },
                replacementCarApplied: {
                  multiline: false,
                  value: planDetails.height,
                  name: "replacementCarApplied",
                  title: "Replacement Car Applied",
                  hidden: !(lineExternalCode === "51"),
                },
                replacementCarValue: {
                  multiline: false,
                  value: planDetails.dateOfDeath
                    ? dayjs(new Date(planDetails.dateOfDeath)).format(
                        "DD/MM/YYYY"
                      )
                    : "- -",
                  name: "replacementCarValue",
                  title: "Replacement Car Value",
                  hidden: !(lineExternalCode === "51"),
                },
                replacementCarDays: {
                  multiline: false,
                  value: planDetails.dateOfDeathNotified
                    ? dayjs(new Date(planDetails.dateOfDeathNotified)).format(
                        "DD/MM/YYYY"
                      )
                    : "- -",
                  name: "replacementCarDays",
                  title: "Replacement Car Days",
                  hidden: !(lineExternalCode === "51"),
                },
              },
            },
          ],
        };
        setCoversInformationViewConfig(coversDynamicView);

        const defaultCommissionView: IDynamicDiplayView = {
          name: "Business Partner Default Commissions",
          sections: [
            {
              name: "businessPartnerDefaultCommissions",
              title: "Business Partner Default Commissions",
              showActionButtons: true,
              properties: {
                specialCommissionApplicable: {
                  multiline: false,
                  value: planDetails.specialCommissionApplicable ? "Yes" : "No",
                  name: "specialCommissionApplicable",
                  title: "Special Commission Applicable",
                },
                specialCommissionType: {
                  multiline: false,
                  value: capitalizeFirstLetter(
                    planDetails.specialCommissionType
                  ),
                  name: "specialCommissionType",
                  title: "Special Commission Type",
                  hidden: !planDetails.specialCommissionApplicable,
                },
                specialCommissionValue: {
                  multiline: false,
                  value: valueCommaSeparated(
                    planDetails.specialCommissionValue
                  ),
                  name: "specialCommissionValue",
                  title: "Special Commission Value",
                  hidden: !(
                    planDetails.specialCommissionApplicable &&
                    planDetails.specialCommissionType === "VALUE"
                  ),
                },
                specialCommissionValueCurrency: {
                  multiline: false,
                  value: planDetails.specialCommissionValueCurrencyTitle,
                  name: "specialCommissionValueCurrency",
                  title: "Special Commission Value Currency",
                  hidden: !(
                    planDetails.specialCommissionApplicable &&
                    planDetails.specialCommissionType === "VALUE"
                  ),
                },
                specialCommissionRate: {
                  multiline: false,
                  value: planDetails.specialCommissionRateTitle,
                  name: "specialCommissionRate",
                  title: "Special Commission Rate",
                  hidden: !(
                    planDetails.specialCommissionApplicable &&
                    planDetails.specialCommissionType === "RATE"
                  ),
                },
                specialCommissionApplicableOn: {
                  multiline: false,
                  value: planDetails.specialCommissionApplicableOnTitle,
                  name: "specialCommissionApplicableOn",
                  title: "Special Commission Applicable On",
                  hidden: !planDetails.specialCommissionApplicable,
                },
              },
            },
          ],
        };
        setDefaultCommissionViewConfig(defaultCommissionView);

        const additionalFeesandChargesView: IDynamicDiplayView = {
          name: "Additional Fees and Charges",
          sections: [
            {
              name: "additionalFeesandCharges",
              title: "Additional Fees and Charges",
              showActionButtons: true,
              properties: {
                policyCost: {
                  multiline: false,
                  value: planDetails.policyCostTitle,
                  name: "policyCost",
                  title: "Policy Cost",
                },
                chargesPercentage: {
                  multiline: false,
                  value: planDetails.chargesPercentageTitle,
                  name: "chargesPercentage",
                  title: "Charges Percentage",
                },
                tPAFeesType: {
                  multiline: false,
                  value: planDetails.tPAFeesTypeTitle,
                  name: "tPAFeesType",
                  title: "TPA Fees Type",
                },
                tPAFeesAmount: {
                  multiline: false,
                  value: planDetails.tPAFeesAmountTitle,
                  name: "tPAFeesAmount",
                  title: "TPA Fees Amount",
                  hidden: planDetails.tPAFeesType === "PERCENTAGE",
                },
                tPAFeesPercentage: {
                  multiline: false,
                  value: planDetails.tPAFeesPercentageTitle,
                  name: "tPAFeesPercentage",
                  title: "TPA Fees Percentage",
                  hidden: planDetails.tPAFeesType !== "PERCENTAGE",
                },
                tPAFeesApplicableOn: {
                  multiline: false,
                  value: planDetails.tPAFeesApplicableOnTitle,
                  name: "tPAFeesApplicableOn",
                  title: "TPA Fees ApplicableOn",
                },
                minTPA: {
                  multiline: false,
                  value: planDetails.minTPATitle,
                  name: "minTPA",
                  title: "Min TPA",
                },
                maxTPA: {
                  multiline: false,
                  value: planDetails.maxTPATitle,
                  name: "maxTPA",
                  title: "Max TPA",
                },
              },
            },
          ],
        };
        setAdditionalFeesandChargesViewConfig(additionalFeesandChargesView);
      }
    } catch (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  }, [entityResult?.data]);

  useEffect(() => {
    if (allRiskPlanRatesListResult?.loading)
      setAllRiskPlanRatesTableLoadingState(true);
    if (allRiskPlanRatesListResult?.error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
    if (allRiskPlanRatesListResult?.data) {
      const allRiskPlanRateTableData = mapToAllRiskPlanRatesListingData(
        allRiskPlanRatesListResult?.data,
        planCurrencyTitle
      );
      setAllRiskPlanRatesTableData({
        ...allRiskPlanRateTableData,
        pageNumber: 0,
      });
    }
    setAllRiskPlanRatesTableLoadingState(false);
  }, [
    allRiskPlanRatesListResult?.loading,
    allRiskPlanRatesListResult?.error,
    allRiskPlanRatesListResult?.data,
  ]);

  useEffect(() => {
    if (expatPlanRatesListResult?.loading)
      setExpatPlanRatesTableLoadingState(true);
    if (expatPlanRatesListResult?.error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
    if (expatPlanRatesListResult?.data) {
      const expatPlanRateTableData = mapToExpatPlanRatesListingData(
        expatPlanRatesListResult?.data,
        planCurrencyTitle
      );
      setExpatPlanRatesTableData({ ...expatPlanRateTableData, pageNumber: 0 });
    }
    setExpatPlanRatesTableLoadingState(false);
  }, [
    expatPlanRatesListResult?.loading,
    expatPlanRatesListResult?.error,
    expatPlanRatesListResult?.data,
  ]);

  useEffect(() => {
    if (travelPlanRatesListResult?.loading)
      setTravelPlanRatesTableLoadingState(true);
    if (travelPlanRatesListResult?.error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
    if (travelPlanRatesListResult?.data) {
      const travelPlanRateTableData = mapToTravelPlanRatesListingData(
        travelPlanRatesListResult?.data,
        planCurrencyTitle
      );
      setTravelPlanRatesTableData({
        ...travelPlanRateTableData,
        pageNumber: 0,
      });
    }
    setTravelPlanRatesTableLoadingState(false);
  }, [
    travelPlanRatesListResult?.loading,
    travelPlanRatesListResult?.error,
    travelPlanRatesListResult?.data,
  ]);

  useEffect(() => {
    if (medicalPlanRatesListResult?.loading)
      setMedicalPlanRatesTableLoadingState(true);
    if (medicalPlanRatesListResult?.error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
    if (medicalPlanRatesListResult?.data) {
      const medicalPlanRateTableData = mapToMedicalPlanRatesListingData(
        medicalPlanRatesListResult?.data,
        planCurrencyTitle
      );
      setMedicalPlanRatesTableData({
        ...medicalPlanRateTableData,
        pageNumber: 0,
      });
    }
    setMedicalPlanRatesTableLoadingState(false);
  }, [
    medicalPlanRatesListResult?.loading,
    medicalPlanRatesListResult?.error,
    medicalPlanRatesListResult?.data,
  ]);

  useEffect(() => {
    if (planCoversRatesListResult?.loading) {
      setLoadingState(true);
    }

    if (planCoversRatesListResult?.error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }

    if (planCoversRatesListResult?.data) {
      const planCoversRateTableData = mapToPlanCoversRatesListingData(
        planCoversRatesListResult.data
      );
      setPlanCoversRatesTableData({
        ...planCoversRateTableData,
        pageNumber: 0,
        pageSize: 10,
      });
    }

    if (!planCoversRatesListResult?.loading) {
      setLoadingState(false);
    }
  }, [
    planCoversRatesListResult?.loading,
    planCoversRatesListResult?.error,
    planCoversRatesListResult?.data,
  ]);
  //#endregion

  const handlePlanSectionChange = async () => {
    try {
      client.refetchQueries({
        include: [getPlanDetailsInfo()],
      });
    } catch (error) {
      toast.error(<ToastErrorMessage>{error}</ToastErrorMessage>);
    }
  };

  const renderMainChildren = () => {
    const status =
      entityResult?.data?.PlanConfigManagement?.entities?.plan?.views?.PlanConfigManagement_all?.properties?.PlanStatus.toLowerCase();

    const lineStatus =
      entityResult?.data?.PlanConfigManagement?.entities?.plan?.views
        ?.PlanConfigManagement_all?.properties?.LineID?.views
        ?.PlanConfigManagement_all?.properties?.Status;

    const sublineStatus =
      entityResult?.data?.PlanConfigManagement?.entities?.plan?.views?.PlanConfigManagement_all?.properties?.SublineID?.views?.PlanConfigManagement_all?.properties?.Status.toLowerCase();

    const isPlanActive = status === "active";

    return (
      <>
        <div style={{ marginTop: "20px" }}>
          <EnhancedStepperSpecificDesign
            activeStep={getPlanStatus(status)}
            steps={steps}
            className={classes.stepper}
            showStepperButton
            buttonTitle={isPlanActive ? "Deactivate" : "Activate"}
            buttonState={
              isPlanActive ? activateButtonState : deactivateButtonState
            }
            buttonOnClick={() => {
              if (isPlanActive) {
                const confirmation: IConfirmation = {
                  open: true,
                  title: "Deactivate",
                  message: `Are you sure you want to deactivate this plan?`,
                  callback: async () => {
                    setDeactivateButtonState("loading");
                    deactivateAction()
                      .then((result) => {
                        if (isEmpty(result.errors)) {
                          setDeactivateButtonState("success");
                          toast.success(
                            <ToastSuccessMessage>
                              {
                                "Plan successfully deactivated. Plans assigned to Business Partners are deactivated as well."
                              }
                            </ToastSuccessMessage>
                          );
                        } else {
                          setDeactivateButtonState(undefined);
                          toast.error(
                            <ToastErrorMessage>
                              {getError(result)}
                            </ToastErrorMessage>
                          );
                        }
                      })
                      .finally(() => {
                        setTimeout(() => {
                          setDeactivateButtonState(undefined);
                        }, 1000);
                      });
                  },
                  submitButtonText: "Yes",
                  cancelButtonText: "No",
                };
                dispatch(OpenConfirmationAction(confirmation));
              } else {
                const confirmation: IConfirmation = {
                  open: true,
                  title: "Activate",
                  message: `Are you sure you want to activate this plan?`,
                  callback: async () => {
                    setActivateButtonState("loading");
                    activateAction()
                      .then((result) => {
                        if (isEmpty(result.errors)) {
                          setActivateButtonState("success");
                          toast.success(
                            <ToastSuccessMessage>
                              {"Plan successfully activated."}
                            </ToastSuccessMessage>
                          );
                        } else {
                          setActivateButtonState(undefined);
                          toast.error(
                            <ToastErrorMessage>
                              {getError(result)}
                            </ToastErrorMessage>
                          );
                        }
                      })
                      .finally(() => {
                        setTimeout(() => {
                          setActivateButtonState(undefined);
                        }, 1000);
                      });
                  },
                  submitButtonText: "Yes",
                  cancelButtonText: "No",
                };
                dispatch(OpenConfirmationAction(confirmation));
              }
            }}
          />
          {planDrawerOpen && (
            <PlanDrawer
              open={planDrawerOpen}
              lineId={
                entityResult?.data?.PlanConfigManagement?.entities?.plan?.views
                  ?.PlanConfigManagement_all?.properties?.LineID?.id
              }
              lineName={
                entityResult?.data?.PlanConfigManagement?.entities?.plan?.views
                  ?.PlanConfigManagement_all?.properties?.LineID?.views
                  ?.PlanConfigManagement_all?.properties?.Name
              }
              lineStatus={lineStatus.toLowerCase()}
              planId={entityId}
              sublineID={
                entityResult?.data?.PlanConfigManagement?.entities?.plan?.views
                  ?.PlanConfigManagement_all?.properties?.SublineID?.id
              }
              isSublineNameDisabled={true}
              sublineStatus={sublineStatus}
              onClose={() => setPlanDrawerOpen(false)}
              onSuccess={() => {
                handlePlanSectionChange();
              }}
            />
          )}
        </div>
      </>
    );
  };

  (
    planCoversRatesHeaders.planConfigManagement_PlanCover_PolicyCoverID_Name as IEnhanceTableHeaderClickable
  ).callback = (payload: any) => {
    navigate(
      "/plan/covers/" +
        payload.columns.planConfigManagement_PlanCover_PolicyCoverID_ID
    );
  };

  // const handleIconClick = () => {
  //   setPlanDrawerOpen(true);
  // };
  const renderLeftSection = () => {
    return (
      <>
        <EntityInfoWidget
          background="rgb(193, 0, 0)"
          classification={{ classifications: {}, value: "" }}
          progress={100}
          supportsClassification={true}
          supportsProgressBar={true}
          title={planDetailsInfo.planName}
          view={updatedLeftSideInfo}
          // iconUrl={tenant.cdnUrl + "/icons/edit-secondary.svg"}
          // onClickIcon={handleIconClick}
        ></EntityInfoWidget>
      </>
    );
  };

  let allRiskPlanRatesListingActions: IEnhancedTableMenuItem[] = [];
  allRiskPlanRatesListingActions = [
    {
      title: "New",
      onClick: () => {
        setAllRiskActionClicked("new");
        setAllRiskPlanRatesDrawerOpen(true);
      },
      isEntity: false,
      isBulk: false,
      iconUrl: tenant.cdnUrl + "/icons/add-primary-red.svg",
      hidden: false,
      disabled: false,
    },
    {
      title: "Edit",
      onClick: () => {
        setAllRiskActionClicked("edit");
        setAllRiskPlanRatesDrawerOpen(true);
      },
      isEntity: false,
      isBulk: false,
      iconUrl: tenant.cdnUrl + "/icons/edit-secondary.svg",
      hidden: false,
      disabled: selectedAllRiskPlanRatesItems !== 1,
    },
  ];

  let expatPlanRatesListingActions: IEnhancedTableMenuItem[] = [];
  expatPlanRatesListingActions = [
    {
      title: "New",
      onClick: () => {
        setExpatActionClicked("new");
        setExpatPlanRatesDrawerOpen(true);
      },
      isEntity: false,
      isBulk: false,
      iconUrl: tenant.cdnUrl + "/icons/add-primary-red.svg",
      hidden: false,
      disabled: false,
    },
    {
      title: "Edit",
      onClick: () => {
        setExpatActionClicked("edit");
        setExpatPlanRatesDrawerOpen(true);
      },
      isEntity: false,
      isBulk: false,
      iconUrl: tenant.cdnUrl + "/icons/edit-secondary.svg",
      hidden: false,
      disabled: selectedExpatPlanRatesItems !== 1,
    },
  ];

  let travelPlanRatesListingActions: IEnhancedTableMenuItem[] = [];
  travelPlanRatesListingActions = [
    {
      title: "New",
      onClick: () => {
        setTravelActionClicked("new");
        setTravelPlanRatesDrawerOpen(true);
      },
      isEntity: false,
      isBulk: false,
      iconUrl: tenant.cdnUrl + "/icons/add-primary-red.svg",
      hidden: false,
      disabled: false,
    },
    {
      title: "Edit",
      onClick: () => {
        setTravelActionClicked("edit");
        setTravelPlanRatesDrawerOpen(true);
      },
      isEntity: false,
      isBulk: false,
      iconUrl: tenant.cdnUrl + "/icons/edit-secondary.svg",
      hidden: false,
      disabled: selectedTravelPlanRatesItems !== 1,
    },
  ];

  let medicalPlanRatesListingActions: IEnhancedTableMenuItem[] = [];
  medicalPlanRatesListingActions = [
    {
      title: "New",
      onClick: () => {
        setMedicalActionClicked("new");
        setMedicalPlanRatesDrawerOpen(true);
      },
      isEntity: false,
      isBulk: false,
      iconUrl: tenant.cdnUrl + "/icons/add-primary-red.svg",
      hidden: false,
      disabled: false,
    },
    {
      title: "Edit",
      onClick: () => {
        setMedicalActionClicked("edit");
        setMedicalPlanRatesDrawerOpen(true);
      },
      isEntity: false,
      isBulk: false,
      iconUrl: tenant.cdnUrl + "/icons/edit-secondary.svg",
      hidden: false,
      disabled: selectedMedicalPlanRatesItems !== 1,
    },
  ];

  let planCoversRatesListingActions: IEnhancedTableMenuItem[] = [];
  planCoversRatesListingActions = [
    {
      title: "New",
      onClick: () => {
        setPlanCoversRatesActionClicked("new");
        setPlanCoversRatesDrawerOpen(true);
      },
      isEntity: false,
      isBulk: false,
      iconUrl: tenant.cdnUrl + "/icons/add-primary-red.svg",
      hidden: false,
      disabled: false,
    },
    {
      title: "Edit",
      onClick: () => {
        setPlanCoversRatesActionClicked("edit");
        setPlanCoversRatesDrawerOpen(true);
      },
      isEntity: false,
      isBulk: false,
      iconUrl: tenant.cdnUrl + "/icons/edit-secondary.svg",
      hidden: false,
      disabled: selectedPlanCoversRatesItems !== 1,
    },
  ];
  //#endregion

  //#region tabs
  const tabs = () => {
    planTabs.tabs[0].widgets[0].children = (
      <>
        {planInformationViewConfig && (
          <>
            <DynamicWidget
              hasFourFields={true}
              view={planInformationViewConfig}
              actions={
                <ShowForUser allowedRoles={["Insurance-Admin"]}>
                  {status != "inactive" && (
                    <div>
                      <EnhancedButton
                        type="button"
                        backgroundColor={MAIN_ONE_THEME.palette.primary1.main}
                        color="#FFF"
                        onClick={() => setPlanInformationDrawerOpen(true)}
                        className={classes.actionButton}
                      >
                        Modify
                      </EnhancedButton>
                    </div>
                  )}
                </ShowForUser>
              }
            />
          </>
        )}
        {defaultCommissionViewConfig && (
          <div style={{ marginTop: "20px" }}>
            <DynamicWidget
              hasFourFields={true}
              view={defaultCommissionViewConfig}
              actions={
                <ShowForUser allowedRoles={["Insurance-Admin"]}>
                  {status != "inactive" && (
                    <div>
                      <EnhancedButton
                        type="button"
                        backgroundColor={MAIN_ONE_THEME.palette.primary1.main}
                        color="#FFF"
                        onClick={() => setDefaultCommissionDrawerOpen(true)}
                        className={classes.actionButton}
                      >
                        Modify
                      </EnhancedButton>
                    </div>
                  )}
                </ShowForUser>
              }
            />
          </div>
        )}
      </>
    );

    planTabs.tabs[1].widgets[0].children = (
      <>
        {coversInformationViewConfig && (
          <>
            <WidgetSection style={{ margin: "-23px 0 0 0" }}>
              <form>
                {allRiskPlanRatesListResult?.loading ? (
                  <div></div>
                ) : (
                  lineExternalCode === "51" && (
                    <div style={{ marginTop: "20px", padding: "0" }}>
                      <EnhancedTable
                        title={capitalizeFirstLetter("All Risk Plan Rates")}
                        inlineTitle={capitalizeFirstLetter(
                          "All Risk Plan Rates"
                        )}
                        name="count"
                        entityName={"PlanSpecificAllRisk"}
                        entityIdColumnName={
                          "planConfigManagement_PlanSpecificAllRisk_Id"
                        }
                        showCellFullData={true}
                        isTitlePascalCase={true}
                        orderByAscendingByDefault
                        defaultOrderByColumn="name"
                        inline={true}
                        data={allRiskPlanRatesTableData}
                        headers={allRiskPlanRatesHeaders}
                        handlePageChange={(page: number) =>
                          handleAllRiskPlanRatesPageChange(page)
                        }
                        handleRowsPerPageChange={(page: number) =>
                          handleAllRiskPlanRatesRowsPerPageChange(page)
                        }
                        currentPage={allRiskPlanRatesTableData.pageNumber}
                        hideToolbar={false}
                        usePagination
                        disableSelection={false}
                        actions={allRiskPlanRatesListingActions}
                        loader={allRiskPlanRatesTableLoadingState}
                        showTablePagination={true}
                        onSelectionChange={(newDetailedSelection) => {
                          setAllRiskPlanRatesItems(newDetailedSelection.length);
                          if (newDetailedSelection.length > 0) {
                            setAllRiskPlanRatesId(newDetailedSelection[0].id);
                          } else {
                            setAllRiskPlanRatesId("");
                          }
                        }}
                      />
                      {allRiskPlanRatesDrawerOpen && (
                        <AllRiskPlanRatesDrawer
                          open={allRiskPlanRatesDrawerOpen}
                          onClose={() => setAllRiskPlanRatesDrawerOpen(false)}
                          onSuccess={() => {
                            getPlanSpecificAllRiskListLazy();
                          }}
                          planId={params.id}
                          planCurrency={planCurrency}
                          allRiskPlanRateId={
                            allRiskActionClicked === "new"
                              ? null
                              : selectedAllRiskPlanRatesId
                          }
                        />
                      )}
                    </div>
                  )
                )}
              </form>
            </WidgetSection>
            <WidgetSection style={{ margin: "-23px 0 0 0" }}>
              <form>
                {expatPlanRatesListResult?.loading ? (
                  <div></div>
                ) : (
                  lineExternalCode === "43" && (
                    <div style={{ marginTop: "20px", padding: "0" }}>
                      <EnhancedTable
                        title={capitalizeFirstLetter("Expat Plan Rates")}
                        inlineTitle={capitalizeFirstLetter("Expat Plan Rates")}
                        name="count"
                        entityName={"PlanSpecificExpat"}
                        entityIdColumnName={
                          "planConfigManagement_PlanSpecificExpat_Id"
                        }
                        showCellFullData={true}
                        isTitlePascalCase={true}
                        orderByAscendingByDefault
                        defaultOrderByColumn="name"
                        inline={true}
                        data={expatPlanRatesTableData}
                        headers={expatPlanRatesHeaders}
                        handlePageChange={(page: number) =>
                          handleExpatPlanRatesPageChange(page)
                        }
                        handleRowsPerPageChange={(page: number) =>
                          handleExpatPlanRatesRowsPerPageChange(page)
                        }
                        currentPage={expatPlanRatesTableData.pageNumber}
                        hideToolbar={false}
                        usePagination
                        disableSelection={false}
                        actions={expatPlanRatesListingActions}
                        loader={expatPlanRatesTableLoadingState}
                        showTablePagination={true}
                        onSelectionChange={(newDetailedSelection) => {
                          setExpatPlanRatesItems(newDetailedSelection.length);
                          if (newDetailedSelection.length > 0) {
                            setExpatPlanRatesId(newDetailedSelection[0].id);
                          } else {
                            setExpatPlanRatesId("");
                          }
                        }}
                      />
                      {expatPlanRatesDrawerOpen && (
                        <ExpatPlanRatesDrawer
                          open={expatPlanRatesDrawerOpen}
                          onClose={() => setExpatPlanRatesDrawerOpen(false)}
                          onSuccess={() => {
                            getPlanSpecificExpatListLazy();
                          }}
                          planId={params.id}
                          planCurrency={planCurrency}
                          expatPlanRateId={
                            expatActionClicked === "new"
                              ? null
                              : selectedExpatPlanRatesId
                          }
                        />
                      )}
                    </div>
                  )
                )}
              </form>
            </WidgetSection>
            <WidgetSection style={{ margin: "-23px 0 0 0" }}>
              <form>
                {travelPlanRatesListResult?.loading ? (
                  <div></div>
                ) : (
                  lineExternalCode === "48" && (
                    <div style={{ marginTop: "20px", padding: "0" }}>
                      <EnhancedTable
                        title={capitalizeFirstLetter("Travel Plan Rates")}
                        inlineTitle={capitalizeFirstLetter("Travel Plan Rates")}
                        name="count"
                        entityName={"PlanSpecificTravel"}
                        entityIdColumnName={
                          "planConfigManagement_PlanSpecificTravel_Id"
                        }
                        showCellFullData={true}
                        isTitlePascalCase={true}
                        orderByAscendingByDefault
                        defaultOrderByColumn="name"
                        inline={true}
                        data={travelPlanRatesTableData}
                        headers={travelPlanRatesHeaders}
                        handlePageChange={(page: number) =>
                          handleTravelPlanRatesPageChange(page)
                        }
                        handleRowsPerPageChange={(page: number) =>
                          handleTravelPlanRatesRowsPerPageChange(page)
                        }
                        currentPage={travelPlanRatesTableData.pageNumber}
                        hideToolbar={false}
                        usePagination
                        disableSelection={false}
                        actions={travelPlanRatesListingActions}
                        loader={travelPlanRatesTableLoadingState}
                        showTablePagination={true}
                        onSelectionChange={(newDetailedSelection) => {
                          setTravelPlanRatesItems(newDetailedSelection.length);
                          if (newDetailedSelection.length > 0) {
                            setTravelPlanRatesId(newDetailedSelection[0].id);
                          } else {
                            setTravelPlanRatesId("");
                          }
                        }}
                      />
                      {travelPlanRatesDrawerOpen && (
                        <TravelPlanRatesDrawer
                          open={travelPlanRatesDrawerOpen}
                          onClose={() => setTravelPlanRatesDrawerOpen(false)}
                          onSuccess={() => {
                            getPlanSpecificTravelListLazy();
                          }}
                          planId={params.id}
                          planCurrency={planCurrency}
                          travelPlanRateId={
                            travelActionClicked === "new"
                              ? null
                              : selectedTravelPlanRatesId
                          }
                        />
                      )}
                    </div>
                  )
                )}
              </form>
            </WidgetSection>
            <WidgetSection style={{ margin: "-23px 0 0 0" }}>
              <form>
                {medicalPlanRatesListResult?.loading ? (
                  <div></div>
                ) : (
                  lineExternalCode === "6" && (
                    <div style={{ marginTop: "20px", padding: "0" }}>
                      <EnhancedTable
                        title={capitalizeFirstLetter("Medical Plan Rates")}
                        inlineTitle={capitalizeFirstLetter(
                          "Medical Plan Rates"
                        )}
                        name="count"
                        entityName={"PlanSpecificMedical"}
                        entityIdColumnName={
                          "planConfigManagement_PlanSpecificMedical_Id"
                        }
                        showCellFullData={true}
                        isTitlePascalCase={true}
                        orderByAscendingByDefault
                        defaultOrderByColumn="name"
                        inline={true}
                        data={medicalPlanRatesTableData}
                        headers={medicalPlanRatesHeaders}
                        handlePageChange={(page: number) =>
                          handleMedicalPlanRatesPageChange(page)
                        }
                        handleRowsPerPageChange={(page: number) =>
                          handleMedicalPlanRatesRowsPerPageChange(page)
                        }
                        currentPage={medicalPlanRatesTableData.pageNumber}
                        hideToolbar={false}
                        usePagination
                        disableSelection={false}
                        actions={medicalPlanRatesListingActions}
                        loader={medicalPlanRatesTableLoadingState}
                        showTablePagination={true}
                        onSelectionChange={(newDetailedSelection) => {
                          setMedicalPlanRatesItems(newDetailedSelection.length);
                          if (newDetailedSelection.length > 0) {
                            setMedicalPlanRatesId(newDetailedSelection[0].id);
                          } else {
                            setMedicalPlanRatesId("");
                          }
                        }}
                      />
                      {medicalPlanRatesDrawerOpen && (
                        <MedicalPlanRatesDrawer
                          open={medicalPlanRatesDrawerOpen}
                          onClose={() => setMedicalPlanRatesDrawerOpen(false)}
                          onSuccess={() => {
                            getPlanSpecificMedicalListLazy();
                          }}
                          planId={params.id}
                          lineId={
                            entityResult?.data?.PlanConfigManagement?.entities
                              ?.plan?.views?.PlanConfigManagement_all
                              ?.properties?.LineID?.id
                          }
                          planCurrency={planCurrency}
                          medicalPlanRateId={
                            medicalActionClicked === "new"
                              ? null
                              : selectedMedicalPlanRatesId
                          }
                        />
                      )}
                    </div>
                  )
                )}
              </form>
            </WidgetSection>
          </>
        )}

        {coversInformationViewConfig && (
          <>
            <WidgetSection style={{ margin: "-23px 0 0 0" }}>
              <form>
                {planCoversRatesListResult?.loading ? (
                  <div></div>
                ) : (
                  <div style={{ marginTop: "20px", padding: "0" }}>
                    <EnhancedTable
                      title={capitalizeFirstLetter("Plan Covers & Rates")}
                      inlineTitle={capitalizeFirstLetter("Plan Covers & Rates")}
                      name="count"
                      entityName={"PlanCover"}
                      entityIdColumnName={"planConfigManagement_PlanCover_Id"}
                      showCellFullData={true}
                      isTitlePascalCase={true}
                      orderByAscendingByDefault
                      defaultOrderByColumn="name"
                      inline={true}
                      data={planCoversRatesTableData}
                      headers={planCoversRatesHeaders}
                      handlePageChange={(page: number) =>
                        handlePlanCoversRatesPageChange(page)
                      }
                      handleRowsPerPageChange={(page: number) =>
                        handlePlanCoversRatesRowsPerPageChange(page)
                      }
                      currentPage={planCoversRatesTableData.pageNumber}
                      hideToolbar={false}
                      usePagination
                      disableSelection={false}
                      actions={planCoversRatesListingActions}
                      loader={planCoversRatesTableLoadingState}
                      showTablePagination={true}
                      onSelectionChange={(newDetailedSelection) => {
                        setPlanCoversRatesItems(newDetailedSelection.length);
                        if (newDetailedSelection.length > 0) {
                          setPlanCoversRatesId(newDetailedSelection[0].id);
                        } else {
                          setPlanCoversRatesId("");
                        }
                      }}
                    />
                    {planCoversRatesDrawerOpen && (
                      <AssignPolicyCoverDrawer
                        open={planCoversRatesDrawerOpen}
                        onClose={() => setPlanCoversRatesDrawerOpen(false)}
                        onSuccess={() => {
                          handlePlanCoversRatesPageChange(0);
                        }}
                        lineId={
                          entityResult?.data?.PlanConfigManagement?.entities
                            ?.plan?.views?.PlanConfigManagement_all?.properties
                            ?.LineID?.id
                        }
                        planId={params.id}
                        planCurrencyId={planCurrency}
                        planCurrencyTitle={planCurrencyTitle}
                        assignPolicyCoverId={
                          planCoversRatesActionClicked === "new"
                            ? null
                            : selectedPlanCoversRatesId
                        }
                        planLineExternalCode={lineExternalCode}
                      />
                    )}
                  </div>
                )}
              </form>
            </WidgetSection>
          </>
        )}
        {additionalFeesandChargesViewConfig && (
          <div style={{ marginTop: "20px" }}>
            <DynamicWidget
              hasFourFields={true}
              view={additionalFeesandChargesViewConfig}
              actions={
                <ShowForUser allowedRoles={["Insurance-Admin"]}>
                  <div>
                    <EnhancedButton
                      type="button"
                      backgroundColor={MAIN_ONE_THEME.palette.primary1.main}
                      color="#FFF"
                      onClick={() =>
                        setAdditionalFeesandChargesDrawerOpen(true)
                      }
                      className={classes.actionButton}
                    >
                      Modify
                    </EnhancedButton>
                  </div>
                </ShowForUser>
              }
            />
          </div>
        )}
      </>
    );

    return planTabs;
  };
  //#endregion tabs

  return loadingState ? (
    <Loader />
  ) : (
    <>
      {planInformationDrawerOpen && (
        <PlanInformationDrawer
          open={planInformationDrawerOpen}
          onClose={() => setPlanInformationDrawerOpen(false)}
          onSuccess={() => {
            getPlanDetailsLazy();
          }}
          planId={params.id}
          planDetailsInfo={entityToPlanDetails(entityResult?.data)}
        />
      )}
      {defaultCommissionDrawerOpen && (
        <PlanDetailsDefaultCommissionDrawer
          open={defaultCommissionDrawerOpen}
          onClose={() => setDefaultCommissionDrawerOpen(false)}
          onSuccess={() => {
            getPlanDetailsLazy();
          }}
          planId={params.id}
          defaultCommissionDetailsInfo={entityToPlanDetails(entityResult?.data)}
        />
      )}
      {additionalFeesandChargesDrawerOpen && (
        <PlanAdditionalFeesAndChargesDrawer
          open={additionalFeesandChargesDrawerOpen}
          onClose={() => setAdditionalFeesandChargesDrawerOpen(false)}
          onSuccess={() => {
            getPlanDetailsLazy();
          }}
          planId={params.id}
          additionalFeesAndChargesDetailsInfo={entityToPlanDetails(
            entityResult?.data
          )}
        />
      )}
      <TabsLayout
        name="policyPageDetails"
        layout={tabs()}
        theme={tenant.theme}
        leftChildren={renderLeftSection()}
        mainChildren={renderMainChildren()}
        cdnUrl={tenant.cdnUrl}
        userInfo={user["info"]}
        tabPanelClassName={classes.tabPanelStyle}
        firstTabAsActiveTab={true}
      />
    </>
  );
};

export default PlanDetailsPage;
