// TODO Refactor this component to support enabling and disabling formatting
import { makeStyles } from 'tss-react/mui';
import React, { useCallback } from 'react';
import clsx from 'clsx';
import EnhancedIcon from '../EnhancedIcon';
import EnhancedInputsWrapper from './EnhancedInputsWrapper';
import { isEmpty, isValidNumber } from '../../utils/validationUtils';
import {
  reduceNumberOfDecimals,
  removeNumberDelimeter,
  valueCommaSeparated,
} from '../../utils/formatting-utils';
import { MAIN_ONE_THEME, contentFontFamilyRegular } from '../../constants';
import { IEnhancedFormInputBaseProps } from '.';

export interface IEnhancedCurrencyInputProps
  extends IEnhancedFormInputBaseProps,
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    > {
  name: string;
  title: string;
  description?: string;
  error?: string;
  material?: boolean;
  maxDecimalPercision?: number;
  value: string | number;
  inline?: boolean;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  currencyTitle?: string;
  currencyIcon?: string;
  inputClass?: string;
  wrapperClass?: string;
  hidden?: boolean;
}

const useStyles = makeStyles()((theme) => ({
  input: {
    fontSize: 13,
    lineHeight: '15px',
    height: 35,
    padding: '0 15px',
    margin: '0 auto',
    backgroundColor: MAIN_ONE_THEME.palette.secondary4.main,
    border: `1px solid ${MAIN_ONE_THEME.palette.secondary3.main}`,
    boxSizing: 'border-box',
    borderRadius: '5px',
    '&:disabled': {
      cursor: 'not-allowed',
      opacity: 0.4,
      color: MAIN_ONE_THEME.palette.primary2.main,
    },
    '&::placeholder': {
      color: 'rgba(0, 0, 0, 0.20)',
      fontStyle: 'italic',
    },
    fontFamily: contentFontFamilyRegular,
    '&:focus': {
      outline: 'none',
    },
  },
  // TODO make style common using JSS
  materialInput: {
    backgroundColor: 'unset',
    border: 'none',
    borderBottom: `1px solid ${MAIN_ONE_THEME.palette.secondary3.main}`,
    color: MAIN_ONE_THEME.palette.primary2.main,
    margin: '0 auto',
    borderRadius: 0,
    padding: '10px 15px 4px 4px',
    fontFamily: contentFontFamilyRegular,
    fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px`,
  },
  currencyIcon: {
    display: 'inline-block !important',
    height: '10px',
    minWidth: '10px',
    minHeight: '12px',
    top: '10px!important',
    fontFamily: contentFontFamilyRegular,
  },
  formCurrencyIcon: {
    position: 'absolute',
    bottom: '0',
    left: '7px',
    height: '11px',
    width: '15px',
    marginTop: '2px',
    fontFamily: contentFontFamilyRegular,
  },
  currencyInput: {
    paddingLeft: '25px',
    fontFamily: contentFontFamilyRegular,
  },
  inputError: {
    borderColor: MAIN_ONE_THEME.palette.error.main,
    outlineColor: MAIN_ONE_THEME.palette.error.main,
    fontFamily: contentFontFamilyRegular,
  },
  inlineContainer: {
    position: 'relative',
    '& $formCurrencyIcon': {
      height: '10px',
      width: '10px',
      display: 'block',
    },
    fontFamily: contentFontFamilyRegular,
  },
  inputWrapper: {
    height: '88.6px',
  },
}));

const EnhancedCurrencyInput: React.FC<IEnhancedCurrencyInputProps> = ({
  inline,
  className,
  inputClass,
  error,
  name,
  title,
  description,
  value,
  style,
  placeholder,
  disabled,
  maxDecimalPercision = 2,
  currencyTitle,
  currencyIcon,
  material,
  onChange,
  onFocus,
  onBlur,
  wrapperClass,
  customStyles,
  step,
  hidden = false,
}) => {
  const { classes } = useStyles();
  const showCurrency = currencyIcon && currencyTitle;

  const formatValue = useCallback(() => {
    if (typeof value === 'number') {
      return valueCommaSeparated(value.toString());
    } else {
      return valueCommaSeparated(value);
    }
  }, [value]);

  const limitNumber = useCallback(
    (targetValue: string) => {
      let newValue = removeNumberDelimeter(targetValue);
      if (
        Number(maxDecimalPercision) >= 0 &&
        isValidNumber(maxDecimalPercision)
      ) {
        newValue = reduceNumberOfDecimals(
          newValue,
          Number(maxDecimalPercision)
        );
      }
      return newValue;
    },
    [maxDecimalPercision]
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist();
      onChange({
        ...event,
        target: {
          ...event.target,
          value: limitNumber(event.target.value),
          name: event.target.name,
        },
      });
    },
    [limitNumber, onChange]
  );

  // const handleChange = useCallback(
  //   (event: React.ChangeEvent<HTMLInputElement>) => {
  //     let validation = false;
  //     if (validation) {
  //       onChange(event);
  //     }
  //   },
  //   [onChange]
  // );

  const renderInput = () => {
    return (
      <>
        {currencyIcon && currencyTitle && (
          <EnhancedIcon
            className={clsx(classes.currencyIcon, {
              [classes.formCurrencyIcon]: !material && showCurrency,
            })}
            classes={{ image: classes.currencyIcon }}
            title={currencyTitle}
            iconUrl={currencyIcon}
            hidden={hidden}
          />
        )}
        <input
          className={clsx(
            clsx(classes.input, inline ? className : inputClass, {
              [classes.materialInput]: material,
              [classes.currencyInput]: !material && showCurrency,
              [classes.inputError]: error,
            })
          )}
          style={{
            width: showCurrency && material ? '88%' : '100%',
          }}
          title={disabled && !isEmpty(value) ? value.toString() : title}
          id={name}
          name={name}
          aria-invalid={!!error}
          aria-describedby={`errorMsg-${name}`}
          value={formatValue()}
          onChange={handleChange}
          onBlur={onBlur}
          onFocus={onFocus}
          type='text'
          disabled={disabled}
          placeholder={placeholder}
          step={step}
          hidden={hidden}
        />
      </>
    );
  };

  return inline ? (
    <span className={classes.inlineContainer}>{renderInput()}</span>
  ) : (
    <EnhancedInputsWrapper
      title={title}
      description={description}
      error={error}
      name={name}
      className={clsx(classes.inputWrapper, className)}
      style={style}
      childrenClass={wrapperClass}
      customStyles={customStyles}
      hidden={hidden}
    >
      {/* Linter keeps on giving us an error if we don't explicitly test for
        currencyTitle and Icon */}
      <div style={{ position: 'relative' }}>{renderInput()}</div>
    </EnhancedInputsWrapper>
  );
};

export default EnhancedCurrencyInput;
