import { DynamicFormInputType, FormInputTypes } from '../../DynamicForm';
import { isEmpty } from '../../utils/validationUtils';

export const initialValues = {
  businessPartnerDetails: {
    maxCreditLimitAllowedCurrency: '',
    maxCreditLimitAllowed: 0,
  },
};

export const inputs: Record<string, DynamicFormInputType> = {
  maxCreditLimitAllowedCurrency: {
    name: 'maxCreditLimitAllowedCurrency',
    title: 'Max Credit Limit Allowed (Currency)',
    type: FormInputTypes.chips,
    placeholder: 'Max Credit Limit Allowed (Currency)',
    value: !isEmpty(initialValues.businessPartnerDetails.maxCreditLimitAllowedCurrency)
      ? initialValues.businessPartnerDetails.maxCreditLimitAllowedCurrency
      : '',
    multiple: false,
    disabled: false,
    required: true,
  },
  maxCreditLimitAllowed: {
    name: 'maxCreditLimitAllowed',
    title: 'Max Credit Limit Allowed',
    type: FormInputTypes.formattedNumber,
    placeholder: 'Max Credit Limit Allowed',
    value: initialValues.businessPartnerDetails.maxCreditLimitAllowed,
    minValue: 0,
    required: true,
    disabled: false,
  },
};
