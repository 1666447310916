import React, { useState, useEffect } from 'react';
import CollapsibleSection from '../common/CollapsibleSection';
import IconDescriptionTile from '../common/IconDescriptionTile';
import { makeStyles } from 'tss-react/mui';
import { IApplication } from '../../Shell';

interface IAppLauncherAppsProps {
  applications: Record<string, IApplication>;
  searchKey: string;
  closeModal: () => void;
}

const useStyles = makeStyles()(() => ({
  tilesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  tile: {
    width: 'calc(100% - 12px)',
    margin: '10px 10px 10px 0px',
    minWidth: '200px',
    fontFamily: 'HelveticaNeue-Regular',
    '@media only screen and (min-width: 750px)': {
      width: 'calc(50% - 12px)',
    },
    '@media only screen and (min-width: 1024px)': {
      width: 'calc(33% - 12px)',
    },
    '@media only screen and (min-width: 1440px)': {
      width: 'calc(25% - 12px)',
    },
  },
}));

const AppLauncherApps: React.FC<IAppLauncherAppsProps> = ({
  applications,
  closeModal,
  searchKey,
}) => {
  const {classes} = useStyles();
  const [filteredApplications, setFilteredApplications] =
    useState(applications);

  useEffect(() => {
    if (searchKey && searchKey.trim()) {
      const newApps: Record<string, IApplication> = {};
      Object.keys(applications).forEach((appKey) => {
        if (
          applications[appKey].title
            .toLowerCase()
            .includes(searchKey.toLowerCase())
        ) {
          newApps[appKey] = applications[appKey];
        }
      });
      setFilteredApplications(newApps);
    } else {
      setFilteredApplications(applications);
    }
  }, [applications, searchKey]);

  return (
    <CollapsibleSection
      title={'All Apps'}
      className={classes.tilesContainer}
      open
    >
      {Object.keys(filteredApplications).map((appName) => (
        <IconDescriptionTile
          key={appName}
          title={filteredApplications[appName].title}
          imgSrc={filteredApplications[appName].icon}
          description={filteredApplications[appName].description}
          route={
            filteredApplications[appName].route +
            Object.values(filteredApplications[appName].modules)[0].route
          }
          className={classes.tile}
          onClick={closeModal}
        />
      ))}
    </CollapsibleSection>
  );
};

export default AppLauncherApps;
