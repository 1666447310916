import { DynamicFormInputType, FormInputTypes } from "../../DynamicForm";
import { isEmpty } from "../../utils/validationUtils";

export const initialValues = {
  commissionType: '',
  lineCommissionValue: '',
  lineCommissionValueCurrency: '',
  lineCommissionRate: '',
  lineCommissionApplicableOn: '',
  activeFrom: '',
  activeTo: '',
};

export const inputs: Record<string, DynamicFormInputType> = {
  commissionType: {
    name: "commissionType",
    title: "Commission Type",
    placeholder: "Commission Type",
    type: FormInputTypes.chips,
    value: !isEmpty(initialValues.commissionType)
      ? initialValues.commissionType
      : '',
    multiple: false,
    required: false,
    hidden: true,
  },
  lineCommissionValue: {
    name: "lineCommissionValue",
    title: "Commission",
    type: FormInputTypes.formattedNumber,
    placeholder: "Commission",
    value: initialValues.lineCommissionValue,
    required: false,
    hidden: true,
  },
  lineCommissionValueCurrency: {
    name: "lineCommissionValueCurrency",
    title: "Commission Currency",
    placeholder: "Commission Currency",
    type: FormInputTypes.chips,
    value: !isEmpty(initialValues.lineCommissionValueCurrency)
      ? initialValues.lineCommissionValueCurrency
      : '',
    multiple: false,
    required: false,
    hidden: true,
  },
  lineCommissionRate: {
    name: "lineCommissionRate",
    title: "Commission Rate",
    type: FormInputTypes.percentage,
    placeholder: "Commission Rate",
    value: initialValues.lineCommissionRate,
    required: false,
    hidden: true,
    hasBetweenValidation: true,
  },
  lineCommissionApplicableOn: {
    name: "lineCommissionApplicableOn",
    title: "Commission Applicable On",
    placeholder: "Commission Applicable On",
    type: FormInputTypes.chips,
    value: !isEmpty(initialValues.lineCommissionApplicableOn)
      ? initialValues.lineCommissionApplicableOn
      : '',
    multiple: false,
    required: false,
    hidden: true,
  },
  activeFrom: {
    name: "activeFrom",
    title: "Active From",
    placeholder: "Active From",
    type: FormInputTypes.date,
    value: initialValues.activeFrom,
    required: true,
  },
  activeTo: {
    name: "activeTo",
    title: "Active To",
    placeholder: "Active To",
    type: FormInputTypes.date,
    value: initialValues.activeTo,
    required: true,
    disabled: true,
  },
};
