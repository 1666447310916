import { IGenericVignetteTableData } from ".";
import { IAbstractRecord } from "../../models";
import { IListingData } from "../../models/listing";
import { capitalizeFirstLetter } from "../../utils/formatting-utils";
import { steps } from "./content";

export function entityToBusinessPartnersDetails(data: any): any {
  const businesspartnerInfo =
    data?.Insurance?.entities?.agency?.views?.Insurance_all?.properties;

  return {
    agency: businesspartnerInfo?.AgencyName,
    type: businesspartnerInfo?.AgencyType?.Title,
    companyCode: businesspartnerInfo?.AgencyCode?.Title,
    status: businesspartnerInfo?.AgencyStatus,

    mTPCommissionPercentage: businesspartnerInfo?.MTPCommissionPercentage,
    mBICommissionPercentage: businesspartnerInfo?.MBICommissionPercentage,
    expatCommissionPercentage: businesspartnerInfo?.ExpatCommissionPercentage,

    createdBy: `${data?.Insurance?.entities?.agency?.queries?.insurance?.relatedUsers[0]?.createdBy_firstName} ${data?.Insurance?.entities?.agency?.queries?.insurance?.relatedUsers[0]?.createdBy_lastName}`,
    createdOn: businesspartnerInfo?.createdOn,
    modifiedBy: `${data?.Insurance?.entities?.agency?.queries?.insurance?.relatedUsers[0]?.modifiedBy_firstName} ${data?.Insurance?.entities?.agency?.queries?.insurance?.relatedUsers[0]?.modifiedBy_lastName}`,
    modifiedOn: businesspartnerInfo?.modifiedOn,
  };
}

export const getBusinessPartnerStatus = (status: string) => {
  switch (status) {
    case "inactive":
      return steps[0];
    case "active":
      return steps[1];
    default:
      return steps[0];
  }
};

export function mapToListingData(data: any): IListingData<any> {
  const list: IAbstractRecord = {};

  data.Insurance.queries.getAgencyVignetteRange.items.forEach(
    (obj: IGenericVignetteTableData, i: number) => {
      list[i] = {
        insurance_AgencyVignetteRange_Id: obj.insurance_AgencyVignetteRange_Id,
        insurance_AgencyVignetteRange_RangeYear:
          obj.insurance_AgencyVignetteRange_RangeYear,
        insurance_AgencyVignetteRange_RangeCode:
          obj.insurance_AgencyVignetteRange_RangeCode,
        insurance_AgencyVignetteRange_RangeFrom:
          obj.insurance_AgencyVignetteRange_RangeFrom,
        insurance_AgencyVignetteRange_RangeTo:
          obj.insurance_AgencyVignetteRange_RangeTo,
        insurance_AgencyVignetteRange_RangeStatus: capitalizeFirstLetter(
          obj.insurance_AgencyVignetteRange_RangeStatus?.replaceAll("_", " ")
        ),
      };
    }
  );

  return {
    pagedItems: list,
    pageSize: data?.Insurance?.queries?.getAgencyVignetteRange?.paging?.pageSize,
    pageNumber:
      data?.Insurance?.queries?.getAgencyVignetteRange?.paging?.pageNumber,
    totalCount:
      data?.Insurance?.queries?.getAgencyVignetteRange?.paging?.totalCount,
  };
}