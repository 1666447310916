import {
  lookupListAsRecordObject,
} from "../../utils/graph-utils";

export function toLookups(data: any) {
  const businessPartnerDetailsList: Record<string, Record<string, string>> = {};

  businessPartnerDetailsList["maxCreditLimitAllowedCurrency"] =
    lookupListAsRecordObject(data.Core.lookups.currencies);

  return businessPartnerDetailsList;
}
