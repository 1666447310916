import React, { useEffect, useState } from "react";
import GenericDrawer from "../../components/common/generic-drawer/GenericDrawer";
import DynamicForm from "../../DynamicForm/DynamicForm";
import { inputs } from "./content";
import {
  DynamicFormInputType,
  IFormSelectDynamicProps,
} from "../../DynamicForm";
import { cloneDeep, isEmpty } from "lodash";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  createPersonAddress,
  getPersonAddressEnums,
  updatePersonAddress,
  getPersonAddressDetailsInfo,
  getZonesByCountry,
} from "./queries";
import { LookupToList, graphqlEntityToPersonAddressInfo } from "./utils";
import { toast } from "react-toastify";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { DEFAULT_ERROR_TEXT } from "../../constants";
import { useNavigate } from "react-router-dom";
import { EnhancedButtonStatus } from "../../components/EnhancedButton";
import { getError, lookupListAsRecordObject } from "../../utils/graph-utils";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import { normaliseDynamicValues } from "../../utils/dynamic-utils";
import Loader from "../../components/Loader";

const PersonAddressDrawer: React.FC<IPersonAddressDrawerProps> = ({
  personAddressId,
  personId,
  open,
  onSuccess,
  onClose,
}) => {
  let personAddressInfoResult: any;

  const navigate = useNavigate();

  let newPersonAddressEnums: Record<string, Record<string, string>> = {};
  let personAddressEntity: IPersonAddressInfo = {
    personId: "",
    personAddressId: "",
    addressType: "",
    country: "",
    countryId: "",
    zone: "",
    city: "",
    street: "",
    building: "",
    addressDetails: "",
    addressDetailsArabic: "",
    isPrimary: false,
  };

  let personAddressEnumResults = useQuery(getPersonAddressEnums(), {
    fetchPolicy: "no-cache",
  });

  const [personAddressUpdateAction] = useMutation(updatePersonAddress());
  const [personAddressCreateAction] = useMutation(createPersonAddress());

  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();

  let updatedInputs: Record<string, DynamicFormInputType> = inputs;
  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);

  const [getZonesByCountryLazy, zonesByCountryRes] = useLazyQuery(
    getZonesByCountry(),
    { fetchPolicy: "no-cache" }
  );

  if (personAddressId) {
    personAddressInfoResult = useQuery(getPersonAddressDetailsInfo(), {
      variables: {
        id: personAddressId,
      },
    });
  }

  const initialize = () => {
    try {
      updatedInputs = cloneDeep(inputsForm);

      if (personAddressEnumResults.data) {
        newPersonAddressEnums = LookupToList(personAddressEnumResults.data);

        (updatedInputs.addressType as IFormSelectDynamicProps).selectOptions =
          newPersonAddressEnums["addressType"];

        (updatedInputs.country as IFormSelectDynamicProps).selectOptions =
          newPersonAddressEnums["countries"];

        (updatedInputs.country as IFormSelectDynamicProps).onSelect = (
          option
        ) => {
          getZonesByCountryLazy({
            variables: { countryID: option },
          });
        };

        if (personAddressInfoResult?.data) {
          personAddressEntity = graphqlEntityToPersonAddressInfo(
            personAddressInfoResult?.data
          );
        }

        if (personAddressEntity) {
          updatedInputs.addressType.value = personAddressEntity.addressType;
          updatedInputs.country.value = personAddressEntity.countryId;
          updatedInputs.zone.value = personAddressEntity.zone;
          updatedInputs.city.value = personAddressEntity.city;
          updatedInputs.street.value = personAddressEntity.street;
          updatedInputs.building.value = personAddressEntity.building;
          updatedInputs.addressDetails.value =
            personAddressEntity.addressDetails;
          updatedInputs.addressDetailsArabic.value =
            personAddressEntity.addressDetailsArabic;
          updatedInputs.isPrimary.value = personAddressEntity.isPrimary;

          if (personAddressEntity.countryId) {
            getZonesByCountryLazy({
              variables: { countryID: personAddressEntity.countryId },
            }).then((response) => {
              if (response.data) {
                const zones = response.data.Insurance.lookups.zones;
                const zoneOptions = zones.map((zone: { id: any; name: any; }) => ({
                  value: zone.id,
                  label: zone.name,
                }));
                (updatedInputs.zone as IFormSelectDynamicProps).selectOptions =
                  zoneOptions;
              }
            });
          }
        }
      }

      setInputsForm(updatedInputs);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputs, values);
    setFormDisabled(true);
    setSubmitButtonState("loading");
    try {
      let variables = {
        entityId: personAddressId,
        addressInputs: {
          addressType: !isEmpty(data.addressType) ? data.addressType : null,
          country: !isEmpty(data.country) ? data.country : null,
          zone: !isEmpty(data.zone) ? data.zone : null,
          city: !isEmpty(data.city) ? data.city : null,
          addressDetails: !isEmpty(data.addressDetails)
            ? data.addressDetails
            : null,
          addressDetailsArabic: !isEmpty(data.addressDetailsArabic)
            ? data.addressDetailsArabic
            : null,
          street: !isEmpty(data.street) ? data.street : null,
          building: !isEmpty(data.building) ? data.building : null,
          isPrimary: data.isPrimary ? data.isPrimary : false,
        },
      };
      personAddressId
        ? personAddressUpdateAction({
            variables: { ...variables, entityId: personAddressId },

            errorPolicy: "all",
          }).then((res) => {
            if (isEmpty(res.errors)) {
              toast.success(
                <ToastSuccessMessage>
                  {personId
                    ? "Person Address successfully updated"
                    : "Person Address successfully created"}
                </ToastSuccessMessage>
              );
              setTimeout(() => {
                setSubmitButtonState("success");
                onSuccess();
                onClose();
                if (!personId) {
                  navigate(`/salesforce/persons/` + personId);
                }
              }, 500);
            } else {
              setSubmitButtonState(undefined);
              toast.error(
                <ToastErrorMessage>{getError(res)}</ToastErrorMessage>
              );
            }
          })
        : personAddressCreateAction({
            variables: { ...variables, entityId: personId },

            errorPolicy: "all",
          }).then((res) => {
            if (isEmpty(res.errors)) {
              toast.success(
                <ToastSuccessMessage>
                  {personId
                    ? "Person Address successfully updated"
                    : "Person Address successfully created"}
                </ToastSuccessMessage>
              );
              setTimeout(() => {
                setSubmitButtonState("success");
                onSuccess();
                onClose();
                if (!personId) {
                  navigate(`/salesforce/persons/` + personId);
                }
              }, 500);
            } else {
              setSubmitButtonState(undefined);
              toast.error(
                <ToastErrorMessage>{getError(res)}</ToastErrorMessage>
              );
            }
          });
    } catch {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setFormDisabled(false);
    }
  };

  useEffect(() => {
    initialize();
  }, [personAddressEnumResults?.data, personAddressInfoResult?.data]);

  useEffect(() => {
    let updatedInputs = cloneDeep(inputsForm);

    (updatedInputs.country as IFormSelectDynamicProps).onSelect = (option) => {
      updatedInputs.zone.value = null;
      getZonesByCountryLazy({ variables: { countryID: option } });
    };

    if (zonesByCountryRes.data) {
      const zonesData = zonesByCountryRes.data.Insurance.lookups.zones;
      (updatedInputs.zone as IFormSelectDynamicProps).selectOptions =
        lookupListAsRecordObject(zonesData);
    }

    setInputsForm(updatedInputs);
  }, [zonesByCountryRes.data]);

  return (
    <GenericDrawer
      title={personAddressId ? "Edit Address" : "New Address"}
      onClose={() => onClose()}
      isOpen={open}
    >
      {(personAddressEnumResults.loading || personAddressInfoResult?.loading) &&
      open ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={"Submit"}
            submitButtonState={submitButtonState}
            //isSubmitButtonDisabled={submitButtonDisabled}
            disableForm={formDisabled}
            title="Information"
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default PersonAddressDrawer;
