import { IListingData } from '../../models/listing';
import { USER_ROLES } from '../../redux/user/types';
import { capitalizeFirstLetter } from '../../utils/formatting-utils';

export function mapToListingData(data: any): IListingData<IUsersTableData> {
  const list: Record<string, IUsersTableData> = {};
  data.Insurance.queries.listUsers.items.forEach(
    (obj: IUsersTableData, i: number) => {
      list[i] = {
        system_User_Id: obj.system_User_Id,
        system_User_firstName: obj.system_User_firstName,
        system_User_lastName: obj.system_User_lastName,
        system_User_email: obj.system_User_email,
        system_User_mobile: obj.system_User_mobile,
        system_User_roles:
          obj.system_User_roles !== null && obj.system_User_roles?.length > 0
            ? USER_ROLES[obj.system_User_roles[0] as keyof typeof USER_ROLES]
            : '',
        system_User_status: capitalizeFirstLetter(obj.system_User_status),
      };
    },
  );

  return {
    pagedItems: list,
    pageSize: data.Insurance.queries.listUsers?.paging?.pageSize,
    pageNumber: data.Insurance.queries.listUsers?.paging?.pageNumber,
    totalCount: data.Insurance.queries.listUsers?.paging?.totalCount,
  };
}
