import { makeStyles } from 'tss-react/mui';
import React, { useEffect } from 'react';
import { GridWidth } from '../../models/grid';
import { gridWidthToPercentage } from '../../utils/grid-utils';

interface IMainColumnProps {
  background: string;
  leftMargin: GridWidth;
  rightMargin: GridWidth;
  width: GridWidth;
  children?: React.ReactNode;
}

const useStyles = makeStyles()((theme) => ({
  column: {
    position: 'absolute' as 'absolute',
    left: 0,
    right: 0,
    padding: '0.8em 1em 1em',
    boxSizing: 'border-box',
  },
}));

const MainColumn: React.FC<IMainColumnProps> = (props) => {
  // Destructed props here to pass props more easily to useStyles
  const { background, leftMargin, rightMargin, width, children } = props;

  useEffect(() => {
    document.body.style.backgroundColor = background;
    return () => {
      document.body.style.backgroundColor = 'unset';
    };
  }, [background]);

  const { classes } = useStyles();

  return (
    <section
      className={classes.column}
      style={{
        width: `${gridWidthToPercentage(width as number)}%`,
        marginLeft: `${
          leftMargin ? gridWidthToPercentage(leftMargin as number) : 0
        }%`,
        marginRight: `${
          rightMargin ? gridWidthToPercentage(rightMargin as number) : 0
        }%`,
      }}
    >
      {children}
    </section>
  );
};

export default MainColumn;
