import React, { useEffect, useState } from 'react';
import { IListingData } from '../../models/listing';
import StaticLayout from '../../page-layout/static-layout/StaticLayout';
import { useLazyQuery } from '@apollo/client';
import { listQuery } from './queries';
import { mapToListingData } from './utils';
import { actions, filterSectionsContent, headers } from './content';
import EnhancedTable from '../../components/enhanced-table/EnhancedTable';
import { IListingFilterWidgetSection } from '../../components/widgets/custom-listing-filter';
import ListingFilterWidget from '../../components/widgets/custom-listing-filter/ListingFilterWidget';
import { IAbstractRecord } from '../../models';
import _, { isEmpty } from 'lodash';
import { getFilter, setFilter } from '../../utils/filter-utils';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { capitalizeFirstLetter } from '../../utils/formatting-utils';
import PolicyNumberRangeDrawer from '../../forms/policy-number-range-drawer/PolicyNumberRangeDrawer';
import { DEFAULT_ERROR_TEXT } from '../../constants';

const PolicyNumberRangesPage: React.FC = () => {
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [tableLoadingState, setTableLoadingState] = useState<boolean>(false);
  const [policyNumberRangeDrawerOpen, setPolicyNumberRangeDrawerOpen] =
    useState<boolean>(false);
  const params = useParams();
  const rangeType = params.rangeType;

  const FILTER_SESSION_KEY = 'policyNumberRangesFilter_' + rangeType;

  const [filterSections, setFilterSections] =
    useState<IListingFilterWidgetSection[]>();

  const initialfilterValues =
    Object.keys(getFilter(FILTER_SESSION_KEY)).length > 0
      ? getFilter(FILTER_SESSION_KEY)
      : {
          status: [],
        };

  const [filterValues, setFilterValues] =
    useState<IAbstractRecord>(initialfilterValues);

  const [tableData, setTableData] = useState<IListingData<any>>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 0,
    totalCount: 0,
  });

  const [listPolicyNumberPage, { loading, error, data, fetchMore }] =
    useLazyQuery(listQuery(), {
      variables: {
        currentPage: 1,
        currentPageSize: tableData.pageSize,
        rangeStatus:
          filterValues?.status && filterValues?.status?.length > 0
            ? filterValues.status
            : [],
        rangeType: rangeType.toUpperCase(),
      },
    });

  //add open and close function for action button
  actions[0].onClick = () => {
    setPolicyNumberRangeDrawerOpen(true);
  };

  useEffect(() => {
    listPolicyNumberPage();
  }, []);

  useEffect(() => {
    if (loading) setLoadingState(true);
    if (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
    if (data) {
      const tableData = mapToListingData(data);
      setTableData({ ...tableData, pageNumber: 0 });

      let savedFilters = getFilter(FILTER_SESSION_KEY);
      if (isEmpty(savedFilters)) {
        savedFilters = _.cloneDeep(filterValues);
      }
      setFilterValues(savedFilters);

      const newFilterSections = filterSectionsContent(data, savedFilters);
      setFilterSections(newFilterSections);
    }
    setLoadingState(false);
  }, [loading, error, data]);

  function handlePageChange(page: number) {
    setTableLoadingState(true);
    const nextPage = ++page;
    fetchMore({
      variables: {
        currentPage: nextPage,
        currentPageSize: tableData.pageSize,
      },
    })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const updatedTableData = {
            ...mapToListingData(fetchMoreResult.data),
            pageNumber: nextPage - 1,
          };
          setTableData(updatedTableData);
        }
      })
      .finally(() => {
        setTableLoadingState(false);
      });
  }

  function handleRowsPerPageChange(numberOfRecordsPerPage: number) {
    setTableLoadingState(true);
    setTableData({ ...tableData, pageNumber: 1 });
    fetchMore({
      variables: {
        currentPage: 1,
        currentPageSize: numberOfRecordsPerPage,
      },
    })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const updatedTableData = {
            ...mapToListingData(fetchMoreResult.data),
            pageNumber: 0,
            pageSize: numberOfRecordsPerPage,
          };
          setTableData(updatedTableData);
        }
      })
      .finally(() => {
        setTableLoadingState(false);
      });
  }

  const renderMainChildren = () => {
    return (
      <>
        {loading ? (
          <div></div>
        ) : (
          <div style={{ marginTop: '20px' }}>
            <EnhancedTable
              title={capitalizeFirstLetter(rangeType) + ' Policy Number Ranges'}
              name='count'
              orderByAscendingByDefault
              defaultOrderByColumn='name'
              inline={false}
              data={tableData}
              headers={headers}
              handlePageChange={(page: number) => handlePageChange(page)}
              handleRowsPerPageChange={(page: number) =>
                handleRowsPerPageChange(page)
              }
              currentPage={tableData.pageNumber}
              hideToolbar
              usePagination
              disableSelection
              actions={actions}
              loader={tableLoadingState}
            />
            {policyNumberRangeDrawerOpen && (
              <PolicyNumberRangeDrawer
                open={policyNumberRangeDrawerOpen}
                onClose={() => setPolicyNumberRangeDrawerOpen(false)}
                onSuccess={() => {
                  handlePageChange(0);
                }}
                extension={rangeType}
              />
            )}
          </div>
        )}
      </>
    );
  };

  const onFilterUpdate = async (v: Record<string, any>) => {
    let newFilters = _.cloneDeep(v);
    setFilter(newFilters, FILTER_SESSION_KEY);
    setFilterValues(newFilters);
  };

  const renderFilter = () => {
    return (
      filterSections && (
        <ListingFilterWidget
          name={+rangeType + ' Filter'}
          filters={filterSections}
          onApplyFilter={(v) => {
            onFilterUpdate(v);
          }}
        ></ListingFilterWidget>
      )
    );
  };

  return (
    <StaticLayout
      loading={loading || loadingState}
      name={'Policy Number Ranges'}
      leftChildren={renderFilter()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default PolicyNumberRangesPage;
