import { gql } from "@apollo/client";

export function getPersonEnums() {
  return gql`
    query getPersonEnums {
      SalesforceManagement_PersonTypes: __type(
        name: "SalesforceManagement_PersonTypes"
      ) {
        name
        enumValues {
          name
          __typename
        }
        __typename
      }
      Insurance_PhoneType: __type(name: "Insurance_PhoneType") {
        name
        enumValues {
          name
        }
      }
      Insurance {
        lookups {
          titles {
            Id
            Title
          }
        }
      }
    }
  `;
}

export function getPersonInfo() {
  return gql`
    query getPersonInfo($id: String!) {
      SalesforceManagement {
        entities {
          person(id: $id) {
            id
            views {
              SalesforceManagement_all {
                properties {
                  PersonType
                  CompanyName
                  Title {
                    Code
                    Title
                  }
                  FirstName
                  MiddleName
                  LastName
                  DateOfBirth
                  Email
                  PhoneDetails {
                    PhoneType
                    PhoneNumber
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function createPerson() {
  return gql`
    mutation createPersonDetails(
      $dateOfBirth: Date
      $personDetailsInputs: SalesforceManagement_PersonInitialDetailsInputs!
    ) {
      salesforceManagement {
        actions {
          createPerson(
            dateOfBirth: $dateOfBirth
            personDetailsInputs: $personDetailsInputs
          ) {
            id
            views {
              SalesforceManagement_all {
                properties {
                  createdOn
                  LastName
                  FullName
                  MiddleName
                  FirstName
                  CompanyName
                  DateOfBirth
                  PersonType
                  PhoneDetails {
                    PhoneType
                    PhoneNumber
                  }
                  Email
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function updatePerson() {
  return gql`
    mutation updatePlan(
      $entityId: String!
      $planDetailInputs: PlanConfigManagement_PlanDetailInputs!
    ) {
      planConfigManagement {
        entities {
          plan {
            planConfigManagement {
              updatePlanDetails(
                entityId: $entityId
                planDetailInputs: $planDetailInputs
              ) {
                id
                views {
                  PlanConfigManagement_all {
                    properties {
                      Name
                      ExternalCode
                      NameArabic
                      NameFrench
                      Abbreviation
                      EffectiveFrom
                      EffectiveTo
                      PlanStatus
                      SublineID {
                        id
                        views {
                          PlanConfigManagement_all {
                            properties {
                              Name
                              ExternalCode
                            }
                          }
                        }
                      }
                      LineID {
                        id
                        views {
                          PlanConfigManagement_all {
                            properties {
                              Name
                              ExternalCode
                            }
                          }
                        }
                      }
                      createdOn
                      createdBy
                      modifiedOn
                      modifiedBy
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function checkPersonExists() {
  return gql`
    query checkPersonExists(
      $firstName: String!
      $middleName: String!
      $lastName: String!
      $dateOfBirth: Date
    ) {
      SalesforceManagement {
        queries {
          checkPersonExists(
            firstName: $firstName
            middleName: $middleName
            lastName: $lastName
            dateOfBirth: $dateOfBirth
          ) {
            salesforceManagement_Person_Id
          }
        }
      }
    }
  `;
}

export function checkCompanyExists() {
  return gql`
    query checkCompanyExists($companyName: String!, $personID: String) {
      SalesforceManagement {
        queries {
          checkCompanyNameExists(
            companyName: $companyName
            personID: $personID
          ) {
            salesforceManagement_Person_Id
          }
        }
      }
    }
  `;
}
