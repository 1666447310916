import React, { useEffect, useState } from "react";
import GenericDrawer from "../../components/common/generic-drawer/GenericDrawer";
import {
  DynamicFormInputType,
  IFormFormattedNumberDynamicProps,
  IFormNumberDynamicProps,
  IFormSelectDynamicProps,
} from "../../DynamicForm";
import { toast } from "react-toastify";
import { EnhancedButtonStatus } from "../../components/common/EnhancedButton";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { inputs } from "./content";
import { useMutation, useQuery } from "@apollo/client";
import Loader from "../../components/Loader";
import { cloneDeep, isEmpty } from "lodash";
import { DEFAULT_ERROR_TEXT } from "../../constants";
import { IAllRiskPlanRatesDrawerInfo, IAllRiskPlanRatesDrawerProps } from ".";
import {
  createPlanSpecificAllRisk,
  getAllRiskPlanRatesEnums,
  getPlanSpecificAllRiskInfo,
  updatePlanSpecificAllRisk,
} from "./queries";
import { LookupToList, graphqlToAllRiskPlanRatesInfo } from "./utils";
import { getError } from "../../utils/graph-utils";
import { normaliseDynamicValues } from "../../utils/dynamic-utils";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import DynamicForm from "../../DynamicForm/DynamicForm";

const AllRiskPlanRatesDrawer: React.FC<IAllRiskPlanRatesDrawerProps> = ({
  open,
  onSuccess,
  onClose,
  planId,
  planCurrency,
  allRiskPlanRateId,
}) => {
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();
  let [submitButtonDisabled, setSubmitButtonDisabled] =
    useState<boolean>(false);
  const [hasPlanActiveToError, setHasPlanActiveToError] = useState("");

  const [carValueFrom, setCarValueFrom] = useState<string>();
  const [carValueTo, setCarValueTo] = useState<string>();
  const [carAgeFrom, setCarAgeFrom] = useState<string>();
  const [carAgeTo, setCarAgeTo] = useState<string>();
  const [comparFromTo, setComparFromTo] = useState<number>(0);

  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);

  const allRiskPlanRatesDetailsListResults = useQuery(
    getAllRiskPlanRatesEnums()
  );

  const [allRiskPlanRatesAction] = useMutation(
    allRiskPlanRateId
      ? updatePlanSpecificAllRisk()
      : createPlanSpecificAllRisk()
  );

  let allRiskPlanRateInfoResult: any;
  if (allRiskPlanRateId) {
    allRiskPlanRateInfoResult = useQuery(getPlanSpecificAllRiskInfo(), {
      variables: { id: allRiskPlanRateId },
    });
  }

  let allRiskPlanRatesDetailsInfo: IAllRiskPlanRatesDrawerInfo = {
    planConfigManagement_PlanSpecificAllRisk_CarAgeFrom: "",
    planConfigManagement_PlanSpecificAllRisk_CarAgeTo: "",
    planConfigManagement_PlanSpecificAllRisk_CarValueFrom: "",
    planConfigManagement_PlanSpecificAllRisk_CarValueTo: "",
    planConfigManagement_PlanSpecificAllRisk_CarCategory: "",
    planConfigManagement_PlanSpecificAllRisk_MinimumPremium: "",
    planConfigManagement_PlanSpecificAllRisk_Rate: "",
    planConfigManagement_PlanSpecificAllRisk_AgencyYear: "",
    planConfigManagement_PlanSpecificAllRisk_NoDepreciationYear: "",
  };

  let updatedInputs: Record<string, DynamicFormInputType> = inputs;
  let newAllRiskPlanRatesEnums: Record<string, Record<string, string>> = {};

  const handleCarChange = (
    carFrom: string,
    carTo: string,
    type: "Age" | "Value",
    operand: "less" | "greater"
  ) => {
    let error = "";
    if (carTo && carFrom) {
      if (operand === "less") {
        if (parseInt(carFrom) > parseInt(carTo)) {
          error = `Car ${type} From must be less than Car ${type} To`;
        }
      } else {
        if (parseInt(carTo) < parseInt(carFrom)) {
          error = `Car ${type} To must be greater than Car ${type} From`;
        }
      }
    }

    setHasPlanActiveToError(error);
    return error;
  };

  const initialize = () => {
    try {
      updatedInputs = cloneDeep(inputsForm);
      updatedInputs.planCurrency.value = planCurrency;

      if (allRiskPlanRatesDetailsListResults.data) {
        newAllRiskPlanRatesEnums = LookupToList(
          allRiskPlanRatesDetailsListResults.data
        );

        (updatedInputs.planCurrency as IFormSelectDynamicProps).selectOptions =
          newAllRiskPlanRatesEnums["currencies"];

        (
          updatedInputs.planConfigManagement_PlanSpecificAllRisk_CarCategory as IFormSelectDynamicProps
        ).selectOptions = newAllRiskPlanRatesEnums["carCategories"];
      }

      (
        updatedInputs.planConfigManagement_PlanSpecificAllRisk_CarValueFrom as IFormFormattedNumberDynamicProps
      ).onChange = (event) => {
        setCarValueFrom(event.target.value);
        setComparFromTo((oldState) => oldState + 1);
      };

      (
        updatedInputs.planConfigManagement_PlanSpecificAllRisk_CarValueTo as IFormFormattedNumberDynamicProps
      ).onChange = (event) => {
        setCarValueTo(event.target.value);
        setComparFromTo((oldState) => oldState + 1);
      };

      (
        updatedInputs.planConfigManagement_PlanSpecificAllRisk_CarAgeFrom as IFormNumberDynamicProps
      ).onChange = (event) => {
        setCarAgeFrom(event.target.value);
        setComparFromTo((oldState) => oldState + 1);
      };

      (
        updatedInputs.planConfigManagement_PlanSpecificAllRisk_CarAgeTo as IFormNumberDynamicProps
      ).onChange = (event) => {
        setCarAgeTo(event.target.value);
        setComparFromTo((oldState) => oldState + 1);
      };

      (
        updatedInputs.planConfigManagement_PlanSpecificAllRisk_AgencyYear as IFormNumberDynamicProps
      ).onChange = (event) => {
        const error =
          parseInt(event.target.value) > 10
            ? "Agency Year can not exceed 10"
            : "";
        setHasPlanActiveToError(error);
        setInputsForm((currentInputsForm) => ({
          ...currentInputsForm,
          planConfigManagement_PlanSpecificAllRisk_AgencyYear: {
            ...currentInputsForm.planConfigManagement_PlanSpecificAllRisk_AgencyYear,
            value: event.target.value.toString(),
            error: error,
          },
        }));
        setSubmitButtonDisabled(error ? true : hasPlanActiveToError !== "");
      };

      (
        updatedInputs.planConfigManagement_PlanSpecificAllRisk_NoDepreciationYear as IFormNumberDynamicProps
      ).onChange = (event) => {
        const error =
          parseInt(event.target.value) > 10
            ? "No Depreciation Year can not exceed 10"
            : "";
        setHasPlanActiveToError(error);
        setInputsForm((currentInputsForm) => ({
          ...currentInputsForm,
          planConfigManagement_PlanSpecificAllRisk_NoDepreciationYear: {
            ...currentInputsForm.planConfigManagement_PlanSpecificAllRisk_NoDepreciationYear,
            value: event.target.value.toString(),
            error: error,
          },
        }));
        setSubmitButtonDisabled(error ? true : hasPlanActiveToError !== "");
      };

      if (allRiskPlanRateInfoResult?.data) {
        allRiskPlanRatesDetailsInfo = graphqlToAllRiskPlanRatesInfo(
          allRiskPlanRateInfoResult?.data
        );
      }

      if (allRiskPlanRatesDetailsInfo) {
        updatedInputs.planConfigManagement_PlanSpecificAllRisk_CarAgeFrom.value =
          allRiskPlanRatesDetailsInfo.planConfigManagement_PlanSpecificAllRisk_CarAgeFrom;
        updatedInputs.planConfigManagement_PlanSpecificAllRisk_CarAgeTo.value =
          allRiskPlanRatesDetailsInfo.planConfigManagement_PlanSpecificAllRisk_CarAgeTo;
        updatedInputs.planConfigManagement_PlanSpecificAllRisk_CarValueFrom.value =
          allRiskPlanRatesDetailsInfo.planConfigManagement_PlanSpecificAllRisk_CarValueFrom;
        updatedInputs.planConfigManagement_PlanSpecificAllRisk_CarValueTo.value =
          allRiskPlanRatesDetailsInfo.planConfigManagement_PlanSpecificAllRisk_CarValueTo;
        updatedInputs.planConfigManagement_PlanSpecificAllRisk_CarCategory.value =
          allRiskPlanRatesDetailsInfo.planConfigManagement_PlanSpecificAllRisk_CarCategory;
        updatedInputs.planConfigManagement_PlanSpecificAllRisk_MinimumPremium.value =
          allRiskPlanRatesDetailsInfo.planConfigManagement_PlanSpecificAllRisk_MinimumPremium;
        updatedInputs.planConfigManagement_PlanSpecificAllRisk_Rate.value = `${Math.round(
          parseFloat(
            Number(
              allRiskPlanRatesDetailsInfo.planConfigManagement_PlanSpecificAllRisk_Rate
            ).toFixed(10)
          )
        )}`;
        updatedInputs.planConfigManagement_PlanSpecificAllRisk_AgencyYear.value =
          allRiskPlanRatesDetailsInfo.planConfigManagement_PlanSpecificAllRisk_AgencyYear;
        updatedInputs.planConfigManagement_PlanSpecificAllRisk_NoDepreciationYear.value =
          allRiskPlanRatesDetailsInfo.planConfigManagement_PlanSpecificAllRisk_NoDepreciationYear;

        setCarValueFrom(
          allRiskPlanRatesDetailsInfo.planConfigManagement_PlanSpecificAllRisk_CarValueFrom
        );
        setCarValueTo(
          allRiskPlanRatesDetailsInfo.planConfigManagement_PlanSpecificAllRisk_CarValueTo
        );
        setCarAgeFrom(
          allRiskPlanRatesDetailsInfo.planConfigManagement_PlanSpecificAllRisk_CarAgeFrom
        );
        setCarAgeTo(
          allRiskPlanRatesDetailsInfo.planConfigManagement_PlanSpecificAllRisk_CarAgeTo
        );
      }

      setInputsForm(updatedInputs);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputs, values);

    setFormDisabled(true);
    setSubmitButtonState("loading");

    try {
      let variablesMutation = {};
      variablesMutation = {
        planSpecificAllRiskInputs: {
          planID: planId,
          agencyYear: Number(
            data.planConfigManagement_PlanSpecificAllRisk_AgencyYear
          ),
          carAgeFrom: Number(
            data.planConfigManagement_PlanSpecificAllRisk_CarAgeFrom
          ),
          carAgeTo: Number(
            data.planConfigManagement_PlanSpecificAllRisk_CarAgeTo
          ),
          carValueFrom: Number(
            data.planConfigManagement_PlanSpecificAllRisk_CarValueFrom
          ),
          carValueTo: Number(
            data.planConfigManagement_PlanSpecificAllRisk_CarValueTo
          ),
          carCategory:
            data.planConfigManagement_PlanSpecificAllRisk_CarCategory,
          minimumPremium: Number(
            data.planConfigManagement_PlanSpecificAllRisk_MinimumPremium
          ),
          noDepreciationYear: Number(
            data.planConfigManagement_PlanSpecificAllRisk_NoDepreciationYear
          ),
          rate:
            Number(data.planConfigManagement_PlanSpecificAllRisk_Rate) / 100,
        },
      };

      allRiskPlanRatesAction({
        variables: allRiskPlanRateId
          ? {
              ...variablesMutation,
              entityId: allRiskPlanRateId,
            }
          : variablesMutation,
        errorPolicy: "all",
      })
        .then((res) => {
          if (isEmpty(res.errors)) {
            toast.success(
              <ToastSuccessMessage>
                {allRiskPlanRateId
                  ? "Plan Rate successfully updated."
                  : "Plan Rate successfully added."}
              </ToastSuccessMessage>
            );
            setTimeout(() => {
              setSubmitButtonState("success");
              onSuccess();
              onClose();
            }, 500);
          } else {
            setSubmitButtonState(undefined);
            toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
          }
        })
        .catch((err) => {
          toast.error(<ToastErrorMessage>{getError(err)}</ToastErrorMessage>);
        });
    } catch {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setFormDisabled(false);
    }
  };

  useEffect(() => {
    initialize();
  }, [
    allRiskPlanRatesDetailsListResults.data,
    allRiskPlanRateInfoResult?.data,
  ]);

  useEffect(() => {
    const errorValueFrom = handleCarChange(
      carValueFrom,
      carValueTo,
      "Value",
      "less"
    );
    setInputsForm((currentInputsForm) => ({
      ...currentInputsForm,
      planConfigManagement_PlanSpecificAllRisk_CarValueFrom: {
        ...currentInputsForm.planConfigManagement_PlanSpecificAllRisk_CarValueFrom,
        value: carValueFrom && carValueFrom.toString(),
        error: errorValueFrom,
      },
    }));
    const errorValueTo = handleCarChange(
      carValueFrom,
      carValueTo,
      "Value",
      "greater"
    );
    setInputsForm((currentInputsForm) => ({
      ...currentInputsForm,
      planConfigManagement_PlanSpecificAllRisk_CarValueTo: {
        ...currentInputsForm.planConfigManagement_PlanSpecificAllRisk_CarValueTo,
        value: carValueTo && carValueTo.toString(),
        error: errorValueTo,
      },
    }));
    const errorAgeFrom = handleCarChange(carAgeFrom, carAgeTo, "Age", "less");
    setInputsForm((currentInputsForm) => ({
      ...currentInputsForm,
      planConfigManagement_PlanSpecificAllRisk_CarAgeFrom: {
        ...currentInputsForm.planConfigManagement_PlanSpecificAllRisk_CarAgeFrom,
        value: carAgeFrom && carAgeFrom.toString(),
        error: errorAgeFrom,
      },
    }));
    const errorAgeTo = handleCarChange(carAgeFrom, carAgeTo, "Age", "greater");
    setInputsForm((currentInputsForm) => ({
      ...currentInputsForm,
      planConfigManagement_PlanSpecificAllRisk_CarAgeTo: {
        ...currentInputsForm.planConfigManagement_PlanSpecificAllRisk_CarAgeTo,
        value: carAgeTo && carAgeTo.toString(),
        error: errorAgeTo,
      },
    }));
  }, [carValueFrom, carValueTo, carAgeFrom, carAgeTo, comparFromTo]);

  useEffect(() => {
    setSubmitButtonDisabled(hasPlanActiveToError !== "");
  }, [hasPlanActiveToError]);

  return (
    <GenericDrawer
      title={allRiskPlanRateId ? "Modify Plan Rate" : "Add Plan Rate"}
      onClose={() => onClose()}
      isOpen={open}
    >
      {allRiskPlanRatesDetailsListResults.loading && open ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={"Submit"}
            submitButtonState={submitButtonState}
            isSubmitButtonDisabled={submitButtonDisabled}
            disableForm={formDisabled}
            title="Information"
            hasDoprdownSpecificBehavior={true}
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default AllRiskPlanRatesDrawer;
