import { IAbstractRecord } from '../../models';
import { IListingData } from '../../models/listing';
import { lookupListAsRecordObject } from '../../utils/graph-utils';

export function mapToListingData(data: any): IListingData<any> {
  const list: IAbstractRecord = {};

  data.Insurance.queries.listDetailsPolicy.items.forEach(
    (obj: IGenericPolicyTableData, i: number) => {
      list[obj.insurance_Policy_Id] = {
        id: obj.insurance_Policy_Id,
        applicationContactDetails: obj.insuranceCustomer_FullName,
        fullPolicyNumber: obj.insurance_Policy_FullPolicyNumber,
        lineOfBusiness: obj.insurance_Policy_LineOfBusiness.Title,
        product: obj.insurancePolicyMotorDetails_Product?.Title,
        agency: obj.insuranceAgency_AgencyName,
        agent: obj.insuranceAgent_FirstName + ' ' + obj.insuranceAgent_LastName,
        totalAnnualPremium: obj.insurance_Policy_TotalAnnualPremium,
        policyStatus: obj.insurance_Policy_PolicyStatus.Title,
        policyEffectiveDate: obj.insurance_Policy_PolicyEffectiveDate,
        policyExpiryDate: obj.insurance_Policy_PolicyExpiryDate,
        policyIssueDate: obj.insurance_Policy_PolicyIssueDate,
        createdOn: obj.insurance_Policy_createdOn,
        modifiedOn: obj.insurance_Policy_modifiedOn,
      };
    },
  );

  return {
    pagedItems: list,
    pageSize: data.Insurance.queries.listDetailsPolicy?.paging?.pageSize,
    pageNumber: data.Insurance.queries.listDetailsPolicy?.paging?.pageNumber,
    totalCount: data.Insurance.queries.listDetailsPolicy?.paging?.totalCount,
  };
}

export function dataToLovs(data: any): Record<string, Record<string, string>> {
  let lovs: Record<string, Record<string, string>> = {
    agency: {},
    plan: {},
  };
  lovs.plan = lookupListAsRecordObject(data?.Insurance?.lookups?.products);

  data?.Insurance?.queries?.allAgencies?.forEach(
    (obj: {
      insurance_Agency_Id: string;
      insurance_Agency_AgencyName: string;
    }) => {
      lovs.agency[obj.insurance_Agency_Id] = obj.insurance_Agency_AgencyName;
    },
  );

  return lovs;
}
