import { DynamicFormInputType, FormInputTypes } from "../../DynamicForm";
import { isEmpty } from "../../utils/validationUtils";

export const initialValues = {
  assignedLine: "",
  commissionType: "",
  lineCommissionValue: '',
  ValueCurrency: "",
  lineCommissionRate: '',
  ApplicableOn: "",
};

export const inputs: Record<string, DynamicFormInputType> = {
  assignedLine: {
    name: "assignedLine",
    title: "Line",
    type: FormInputTypes.text,
    placeholder: "Line",
    value: initialValues.assignedLine,
    required: true,
    disabled: true,
  },
  commissionType: {
    name: "commissionType",
    title: "Commission Type",
    placeholder: "Commission Type",
    type: FormInputTypes.chips,
    value: !isEmpty(initialValues.commissionType)
      ? [initialValues.commissionType]
      : [],
    multiple: false,
    required: true,
  },
  lineCommissionValue: {
    name: "lineCommissionValue",
    title: "Commission",
    type: FormInputTypes.formattedNumber,
    placeholder: "",
    value: initialValues.lineCommissionValue,
    required: false,
    disabled: true,
    hidden: true,
    hasBetweenValidation: true,
    minValue: 0,
  },
  ValueCurrency: {
    name: "ValueCurrency",
    title: "Commission Currency",
    placeholder: "Commission Currency",
    type: FormInputTypes.chips,
    value: !isEmpty(initialValues.ValueCurrency)
      ? initialValues.ValueCurrency
      : '',
    multiple: false,
    required: false,
    disabled: true,
    hidden: true,
  },
  lineCommissionRate: {
    name: "lineCommissionRate",
    title: "Commission Rate",
    type: FormInputTypes.percentage,
    placeholder: "Commission Rate",
    value: initialValues.lineCommissionRate,
    required: false,
    disabled: true,
    hidden: true,
    hasBetweenValidation: true,
  },
  ApplicableOn: {
    name: "ApplicableOn",
    title: "Commission Applicable On",
    placeholder: "Commission Applicable On",
    type: FormInputTypes.chips,
    value: !isEmpty(initialValues.ApplicableOn)
      ? initialValues.ApplicableOn
      : '',
    multiple: false,
    required: true,
  },
};
