import { IAbstractRecord } from '../../../models';
import { isZeroValue } from '../../../utils/validationUtils';
import { EnhancedDisplayType } from '../../enhanced-form/EnhancedDisplay';

export interface IDynamicDisplayWidgetProps {
  // TODO Should be form <Talk to Haitham>
  view: IDynamicDiplayView;
  actions?: JSX.Element;
  hasFourFields?: boolean;
  isCollapsible?: boolean;
  collapsibleSectionTitle?: string;
  onButtonClick?: () => void;
}

export interface IDynamicDiplayView {
  name: string;
  sections?: IDynamicSection[];
  isCollapsible?: boolean;
  collapsibleSectionTitle?: string;
}

export interface IDynamicSection {
  name: string;
  properties: Record<string, IEnhancedDynamicDisplay>;
  title: string;
  showActionButtons?: boolean;
  hidden?: boolean;
  middle?: JSX.Element;
  showSection?: boolean;
}

export interface ISimplifiedDynamicSection {
  name: string;
  properties: string[];
  title: string;
  showActionButtons?: boolean;
  hidden?: boolean;
  middle?: JSX.Element;
  showSection?: boolean;
}

export enum TextMode {
  SingleLine = 'SingleLine',
  Multiline = 'Multiline',
}

export enum NumberFormattingType {
  currency = 'Currency',
}

export interface IEnhancedDynamicDisplay {
  multiline: boolean;
  value?: string | JSX.Element;
  type?: EnhancedDisplayType;
  textMode?: TextMode;
  name: string;
  title: string;
  description?: string;
  tooltip?: boolean;
  tooltipContents?: JSX.Element;
  hidden?: boolean;
  url?: string;
}

export function extractAllPropertiesFromSections(sections: IDynamicSection[]): {
  extSections: ISimplifiedDynamicSection[];
  extProperties: Record<string, IEnhancedDynamicDisplay>;
} {
  let extSections: ISimplifiedDynamicSection[] = [];
  let extProperties: Record<string, IEnhancedDynamicDisplay> = {};
  sections.forEach((section) => {
    Object.keys(section.properties).forEach((propertyName) => {
      if (section.properties[propertyName].hidden) {
        delete section.properties[propertyName];
      }
    });
    extSections = [
      ...extSections,
      {
        name: section.name,
        title: section.title,
        properties: Object.keys(section.properties),
        showActionButtons: section.showActionButtons,
        hidden: section.hidden,
        middle: section.middle,
      },
    ];
    extProperties = { ...extProperties, ...section.properties };
  });
  return { extSections, extProperties };
}

export function extractValuesFromDynamicPayload(
  allProperties: Record<string, IEnhancedDynamicDisplay>
): IAbstractRecord {
  const values: IAbstractRecord = {};
  Object.values(allProperties).forEach((property) => {
    values[property.name] = isZeroValue(property.name)
      ? 0
      : property.value
      ? property.value
      : "";
  });
  return values;
}
