import React, { useEffect, useState } from "react";
import GenericDrawer from "../../components/common/generic-drawer/GenericDrawer";
import {
  DynamicFormInputType,
  IFormNumberDynamicProps,
  IFormSelectDynamicProps,
} from "../../DynamicForm";
import { toast } from "react-toastify";
import { EnhancedButtonStatus } from "../../components/common/EnhancedButton";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { inputs } from "./content";
import { useMutation, useQuery } from "@apollo/client";
import Loader from "../../components/Loader";
import { cloneDeep, isEmpty } from "lodash";
import { DEFAULT_ERROR_TEXT } from "../../constants";
import { IExpatPlanRatesDrawerInfo, IExpatPlanRatesDrawerProps } from ".";
import {
  createPlanSpecificExpat,
  getExpatPlanRatesEnums,
  getPlanSpecificExpatInfo,
  updatePlanSpecificExpat,
} from "./queries";
import { LookupToList, graphqlToExpatPlanRatesInfo } from "./utils";
import { getError } from "../../utils/graph-utils";
import { normaliseDynamicValues } from "../../utils/dynamic-utils";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import DynamicForm from "../../DynamicForm/DynamicForm";

const ExpatPlanRatesDrawer: React.FC<IExpatPlanRatesDrawerProps> = ({
  open,
  onSuccess,
  onClose,
  planId,
  planCurrency,
  expatPlanRateId,
}) => {
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();
  let [submitButtonDisabled, setSubmitButtonDisabled] =
    useState<boolean>(false);
  const [hasPlanActiveToError, setHasPlanActiveToError] = useState("");
  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);

  const [ageFrom, setAgeFrom] = useState<string>();
  const [ageTo, setAgeTo] = useState<string>();
  const [comparFromTo, setComparFromTo] = useState<number>(0);

  const expatPlanRatesDetailsListResults = useQuery(getExpatPlanRatesEnums());

  const [expatPlanRatesAction] = useMutation(
    expatPlanRateId ? updatePlanSpecificExpat() : createPlanSpecificExpat()
  );

  let expatPlanRateInfoResult: any;
  if (expatPlanRateId) {
    expatPlanRateInfoResult = useQuery(getPlanSpecificExpatInfo(), {
      variables: { id: expatPlanRateId },
    });
  }

  let expatPlanRatesDetailsInfo: IExpatPlanRatesDrawerInfo = {
    ageFrom: "",
    ageTo: "",
    gender: "",
    nationality: [],
    occupationClass: "",
    premium: "",
  };

  let updatedInputs: Record<string, DynamicFormInputType> = inputs;
  let newExpatPlanRatesEnums: Record<string, Record<string, string>> = {};

  const handleCarChange = (
    carFrom: string,
    carTo: string,
    operand: "less" | "greater"
  ) => {
    let error = "";
    if (carTo && carFrom) {
      if (operand === "less") {
        if (parseInt(carFrom) > parseInt(carTo)) {
          error = `Age From must be less than Age To`;
        }
      } else {
        if (parseInt(carTo) < parseInt(carFrom)) {
          error = `Age To must be greater than Age From`;
        }
      }
    }

    setHasPlanActiveToError(error);
    return error;
  };

  const initialize = () => {
    try {
      updatedInputs = cloneDeep(inputsForm);
      updatedInputs.planCurrency.value = planCurrency;

      if (expatPlanRatesDetailsListResults.data) {
        newExpatPlanRatesEnums = LookupToList(
          expatPlanRatesDetailsListResults.data
        );

        (updatedInputs.planCurrency as IFormSelectDynamicProps).selectOptions =
          newExpatPlanRatesEnums["currencies"];

        (updatedInputs.gender as IFormSelectDynamicProps).selectOptions =
          newExpatPlanRatesEnums["genders"];

        (
          updatedInputs.occupationClass as IFormSelectDynamicProps
        ).selectOptions = newExpatPlanRatesEnums["occupations"];

        (updatedInputs.nationality as IFormSelectDynamicProps).selectOptions =
          newExpatPlanRatesEnums["nationalities"];
      }

      (updatedInputs.ageFrom as IFormNumberDynamicProps).onChange = (event) => {
        setAgeFrom(event.target.value);
        setComparFromTo((oldState) => oldState + 1);
      };

      (updatedInputs.ageTo as IFormNumberDynamicProps).onChange = (event) => {
        setAgeTo(event.target.value);
        setComparFromTo((oldState) => oldState + 1);
      };

      if (expatPlanRateInfoResult?.data) {
        expatPlanRatesDetailsInfo = graphqlToExpatPlanRatesInfo(
          expatPlanRateInfoResult?.data
        );
      }

      if (expatPlanRatesDetailsInfo) {
        updatedInputs.ageFrom.value = expatPlanRatesDetailsInfo.ageFrom;
        updatedInputs.ageTo.value = expatPlanRatesDetailsInfo.ageTo;
        updatedInputs.gender.value = expatPlanRatesDetailsInfo.gender;
        updatedInputs.occupationClass.value =
          expatPlanRatesDetailsInfo.occupationClass;
        updatedInputs.nationality.value = expatPlanRatesDetailsInfo.nationality;
        updatedInputs.premium.value = expatPlanRatesDetailsInfo.premium;

        setAgeFrom(expatPlanRatesDetailsInfo.ageFrom);
        setAgeTo(expatPlanRatesDetailsInfo.ageTo);
      }

      setInputsForm(updatedInputs);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputs, values);

    setFormDisabled(true);
    setSubmitButtonState("loading");

    try {
      let variablesMutation = {};
      variablesMutation = {
        nationalities: data?.nationality,
        planSpecificExpatInputs: {
          planID: planId,
          ageFrom: Number(data?.ageFrom),
          ageTo: Number(data?.ageTo),
          gender: data?.gender,
          occupationClass: data?.occupationClass,
          premium: Number(data?.premium),
        },
      };

      expatPlanRatesAction({
        variables: expatPlanRateId
          ? {
              ...variablesMutation,
              entityId: expatPlanRateId,
            }
          : variablesMutation,
        errorPolicy: "all",
      })
        .then((res) => {
          if (isEmpty(res.errors)) {
            toast.success(
              <ToastSuccessMessage>
                {expatPlanRateId
                  ? "Plan Rate successfully updated."
                  : "Plan Rate successfully added."}
              </ToastSuccessMessage>
            );
            setTimeout(() => {
              setSubmitButtonState("success");
              onSuccess();
              onClose();
            }, 500);
          } else {
            setSubmitButtonState(undefined);
            toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
          }
        })
        .catch((err) => {
          toast.error(<ToastErrorMessage>{getError(err)}</ToastErrorMessage>);
        });
    } catch {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setFormDisabled(false);
    }
  };

  useEffect(() => {
    initialize();
  }, [expatPlanRatesDetailsListResults.data, expatPlanRateInfoResult?.data]);

  useEffect(() => {
    const errorFrom = handleCarChange(ageFrom, ageTo, "less");
    setInputsForm((currentInputsForm) => ({
      ...currentInputsForm,
      ageFrom: {
        ...currentInputsForm.ageFrom,
        value: ageFrom && ageFrom.toString(),
        error: errorFrom,
      },
    }));
    const errorTo = handleCarChange(ageFrom, ageTo, "greater");
    setInputsForm((currentInputsForm) => ({
      ...currentInputsForm,
      ageTo: {
        ...currentInputsForm.ageTo,
        value: ageTo && ageTo.toString(),
        error: errorTo,
      },
    }));
  }, [ageFrom, ageTo, comparFromTo]);

  useEffect(() => {
    setSubmitButtonDisabled(hasPlanActiveToError !== "");
  }, [hasPlanActiveToError]);

  return (
    <GenericDrawer
      title={expatPlanRateId ? "Modify Plan Rate" : "Add Plan Rate"}
      onClose={() => onClose()}
      isOpen={open}
    >
      {expatPlanRatesDetailsListResults.loading && open ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={"Submit"}
            submitButtonState={submitButtonState}
            isSubmitButtonDisabled={submitButtonDisabled}
            disableForm={formDisabled}
            title="Information"
            hasDoprdownSpecificBehavior={true}
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default ExpatPlanRatesDrawer;
