import { enumListAsRecordObject } from '../../utils/graph-utils';

export function UserFormLookupsToList(data: any) {
  const userLookups: Record<string, Record<string, string>> = {};

  userLookups[data.Insurance_UserRoles.name] = enumListAsRecordObject(
    data.Insurance_UserRoles.enumValues,
  );
  userLookups[data.Insurance_UserStatuses.name] = enumListAsRecordObject(
    data.Insurance_UserStatuses.enumValues,
  );

  return userLookups;
}

export function graphqlEntityToUserInfo(data: any): IUserInfo {
  if (data?.Insurance?.queries?.getUser?.length > 0) {
    return {
      id: '',
      firstName: data.Insurance.queries.getUser[0]?.system_User_firstName,
      lastName: data.Insurance.queries.getUser[0]?.system_User_lastName,
      email: data.Insurance.queries.getUser[0]?.system_User_email,
      mobile: data.Insurance.queries.getUser[0]?.system_User_mobile,
      role: data.Insurance.queries.getUser[0]?.system_User_roleEnum,
      status: data.Insurance.queries.getUser[0]?.system_User_status,
    };
  }
  return null;
}
