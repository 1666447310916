import React, { useState } from 'react';
import ForgotPasswordForm from './ForgotPasswordForm';
import LoginForm from './LoginForm';

const TABS = ['login', 'forgot-password'] as const;

type Tab = (typeof TABS)[number];

const LoginPortal: React.FC = () => {
  const [tab, setTab] = useState<Tab>('login');

  const renderTab = () => {
    switch (tab) {
      case 'forgot-password':
        return (
          <ForgotPasswordForm onBackToSignInClicked={() => setTab('login')} />
        );
      case 'login':
      default:
        return (
          <LoginForm
            onForgotPasswordClicked={() => setTab('forgot-password')}
          />
        );
    }
  };

  return renderTab();
};

export default LoginPortal;
