export interface IPlanDetailsPageProps {}

export interface IPlanDetails {
  planName: string;
  sublineName: string;
  lineName: string;
  externalCode: string;
  arabicName: string;
  effectiveFrom: string;
  effectiveTo: string;
  status: string;
}

export const initialValues: IPlanDetails = {
  planName: "",
  sublineName: "",
  lineName: "",
  externalCode: "",
  arabicName: "",
  effectiveFrom: "",
  effectiveTo: "",
  status: "",
};

export interface IGenericPolicyCoverTableData {
  planConfigManagement_PolicyCover_Id: string;
  planConfigManagement_PolicyCover_Name: string;
  planConfigManagement_PolicyCover_ExternalCode: string;
  planConfigManagement_PolicyCover_NameArabic: string;
  planConfigManagement_PolicyCover_PrintingOrder: string;
  planConfigManagement_PolicyCover_PolicyCoverDescription: string;
  planConfigManagement_PolicyCover_createdOn: string;
  planConfigManagement_PolicyCover_modifiedOn: string;
  planConfigManagement_PolicyCover_LineID: {
    views: {
      PlanConfigManagement_all: {
        properties: {
          ExternalCode: string;
          Name: string;
        };
      };
    };
  };
}

export interface IGenericAllRiskPlanRatesTableData {
  planConfigManagement_PlanSpecificAllRisk_Id: string;
  planConfigManagement_PlanSpecificAllRisk_PlanID: {
    id: string;
  };
  planConfigManagement_PlanSpecificAllRisk_CarAgeFrom: string;
  planConfigManagement_PlanSpecificAllRisk_CarAgeTo: string;
  planConfigManagement_PlanSpecificAllRisk_CarValueFrom: string;
  planConfigManagement_PlanSpecificAllRisk_CarValueTo: string;
  planConfigManagement_PlanSpecificAllRisk_CarCategory: {
    Id: string;
    Title: string;
  };
  planConfigManagement_PlanSpecificAllRisk_MinimumPremium: string;
  planConfigManagement_PlanSpecificAllRisk_Rate: string;
  planConfigManagement_PlanSpecificAllRisk_AgencyYear: string;
  planConfigManagement_PlanSpecificAllRisk_NoDepreciationYear: string;
}

export interface IGenericExpatPlanRatesTableData {
  planConfigManagement_PlanSpecificExpat_Id: string;
  planConfigManagement_PlanSpecificExpat_AgeFrom: string;
  planConfigManagement_PlanSpecificExpat_AgeTo: string;
  planConfigManagement_PlanSpecificExpat_Gender: string;
  planConfigManagement_PlanSpecificExpat_OccupationClass: {
    Code: string;
    Title: string;
  };
  planConfigManagement_PlanSpecificExpat_Nationality: [
    {
      Code: string;
      Title: string;
    }
  ];
  planConfigManagement_PlanSpecificExpat_Premium: string;
}

export interface IGenericTravelPlanRatesTableData {
  planConfigManagement_PlanSpecificTravel_Id: string;
  planConfigManagement_PlanSpecificTravel_AgeFrom: string;
  planConfigManagement_PlanSpecificTravel_AgeTo: string;
  planConfigManagement_PlanSpecificTravel_TravelDurationFrom: string;
  planConfigManagement_PlanSpecificTravel_TravelDurationTo: string;
  planConfigManagement_PlanSpecificTravel_TravelDestination: {
    Id: string;
    Title: string;
  };
  planConfigManagement_PlanSpecificTravel_NumberOfAdults: string;
  planConfigManagement_PlanSpecificTravel_NumberOfChildren: string;
  planConfigManagement_PlanSpecificTravel_Premium: string;
}

export interface IGenericMedicalPlanRatesTableData {
  planConfigManagement_PlanSpecificMedical_Id: string;
  planConfigManagement_PlanSpecificMedical_PolicyCoverID: {
    id: string;
    views: {
      PlanConfigManagement_all: {
        properties: {
          Name: string;
        };
      };
    };
  };
  planConfigManagement_PlanSpecificMedical_NbOfAdherent: string;
  planConfigManagement_PlanSpecificMedical_Class: {
    Code: string;
    Title: string;
  };
  planConfigManagement_PlanSpecificMedical_AgeFrom: string;
  planConfigManagement_PlanSpecificMedical_AgeTo: string;
  planConfigManagement_PlanSpecificMedical_CoNssf: string;
  planConfigManagement_PlanSpecificMedical_NetPremium: string;
  planConfigManagement_PlanSpecificMedical_TPAFeesPercentage: string;
  planConfigManagement_PlanSpecificMedical_MinTPAFees: string;
  planConfigManagement_PlanSpecificMedical_MaxTPAFees: string;
}

export interface IGenericPlanCoversRatesTableData {
  planConfigManagement_PlanCover_Id: string;
  planConfigManagement_PlanCover_PolicyCoverID: {
    id: string;
    views: {
      PlanConfigManagement_all: {
        properties: {
          ExternalCode: string;
          Name: string;
        };
      };
    };
  };
  planConfigManagement_PlanCover_IsMain: string;
  planConfigManagement_PlanCover_IsMandatory: string;
  planConfigManagement_PlanCover_IsEditable: string;
  planConfigManagement_PlanCover_CoverSumInsured: number | null;
  planConfigManagement_PlanCover_SumInsuredIsAdditive: string;
  planConfigManagement_PlanCover_CoverPremiumType: string;
  planConfigManagement_PlanCover_CoverPremiumValue: number | null;
  planConfigManagement_PlanCover_CoverPremiumPercentage: number | null;
  planConfigManagement_PlanCover_PlanID: {
    views: {
      PlanConfigManagement_all: {
        properties: {
          PlanCurrency: {
            Id: string;
            Code: string;
            Title: string;
          };
        };
      };
    };
  };
  planConfigManagement_PlanCover_ExcessOnClaimType: string;
  planConfigManagement_PlanCover_ExcessOnClaimAmount: number | null;
  planConfigManagement_PlanCover_ExcessOnClaimPercentage: number | null;
  planConfigManagement_PlanCover_ExcessOnClaimDays: number | null;
  planConfigManagement_PlanCover_ClauseID: {
    id: string;
    views: {
      PlanConfigManagement_all: {
        properties: {
          ExternalCode: string;
        };
      };
    };
  };
}
