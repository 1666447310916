import React, { useEffect, useState } from 'react';
import GenericDrawer from '../../components/common/generic-drawer/GenericDrawer';
import DynamicForm from '../../DynamicForm/DynamicForm';
import {
  DynamicFormInputType,
  IFormSelectDynamicProps,
} from '../../DynamicForm';
import { toast } from 'react-toastify';
import { EnhancedButtonStatus } from '../../components/common/EnhancedButton';
import { normaliseDynamicValues } from '../../utils/dynamic-utils';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { inputs } from './content';
import { useMutation, useQuery } from '@apollo/client';
import { UserFormLookupsToList, graphqlEntityToUserInfo } from './utils';
import Loader from '../../components/Loader';
import { cloneDeep } from 'lodash';
import { createUser, getUserInfo, getUserLookups, updateUser } from './queries';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import { DEFAULT_ERROR_TEXT } from '../../constants';
import { isEmpty } from '../../utils/validationUtils';
import _ from 'lodash';
import { getError } from '../../utils/graph-utils';
import { removePlusFromMobileNumber } from '../../utils/formatting-utils';

const UserDrawer: React.FC<IUserDrawerProps> = ({
  id,
  open,
  onSuccess,
  onClose,
}) => {
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();
  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);

  const userLookupResults = useQuery(getUserLookups());
  const [userAction] = useMutation(id ? updateUser() : createUser()); //useMutation(createUser());
  let userInfoResult: any;

  if (id) {
    userInfoResult = useQuery(getUserInfo(), {
      variables: { userId: id ? id : '' },
    });
  }

  const initialize = () => {
    try {
      const updatedInputs = cloneDeep(inputsForm);

      if (userLookupResults.data) {
        const newUserLookups = UserFormLookupsToList(userLookupResults.data);

        (updatedInputs.role as IFormSelectDynamicProps).selectOptions =
          newUserLookups['Insurance_UserRoles'];

        (updatedInputs.status as IFormSelectDynamicProps).selectOptions =
          newUserLookups['Insurance_UserStatuses'];
      }
      if (userInfoResult?.data) {
        const userEntity = graphqlEntityToUserInfo(userInfoResult.data);

        (updatedInputs.email as IFormSelectDynamicProps).disabled = true;

        if (userEntity) {
          updatedInputs.firstName.value = userEntity.firstName;
          updatedInputs.lastName.value = userEntity.lastName;
          updatedInputs.email.value = userEntity.email;
          updatedInputs.mobile.value = userEntity.mobile;
          updatedInputs.role.value = userEntity.role?.toUpperCase();
          updatedInputs.status.value = userEntity.status?.toUpperCase();
        }
      }
      setInputsForm(updatedInputs);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  useEffect(() => {
    initialize();
  }, [userLookupResults.data, userInfoResult?.data]);

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputs, values);
    setSubmitButtonState('loading');
    setFormDisabled(true);
    try {
      let variables = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        mobile: '+' + removePlusFromMobileNumber(data.mobile),
        role: data.role,
        status: data.status,
      };

      userAction({
        variables: id ? { ...variables, userId: id } : variables,
        errorPolicy: 'all',
      }).then((res) => {
        if (isEmpty(res.errors)) {
          toast.success(
            <ToastSuccessMessage>
              {id ? 'User updated' : 'New User created'}
            </ToastSuccessMessage>
          );
          setTimeout(() => {
            setSubmitButtonState('success');
            onSuccess();
            onClose();
          }, 500);
        } else {
          setSubmitButtonState(undefined);
          toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
        }
      });
    } catch {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setFormDisabled(false);
    }
  };

  return (
    <GenericDrawer
      title={id ? 'Edit User' : 'New User'}
      onClose={() => onClose()}
      isOpen={open}
    >
      {(userLookupResults.loading || userInfoResult?.loading) && open ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={'Submit'}
            submitButtonState={submitButtonState}
            disableForm={formDisabled}
            title='Information'
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default UserDrawer;
