import {
  enumListAsRecordObject,
  lookupListAsRecordObject,
} from "../../utils/graph-utils";

export function LookupToList(data: any) {
  const additionalFeesAndChargesEnums: Record<
    string,
    Record<string, string>
  > = {};

  additionalFeesAndChargesEnums["tPATypes"] = enumListAsRecordObject(
    data?.Core_TPATypes?.enumValues as {
      __typename: string;
      name: string;
    }[]
  );

  additionalFeesAndChargesEnums["tPAApplicableOnOptions"] =
    enumListAsRecordObject(
      data?.Core_TPAApplicableOnOptions?.enumValues as {
        __typename: string;
        name: string;
      }[]
    );

  additionalFeesAndChargesEnums["currencies"] = lookupListAsRecordObject(
    data?.Core?.lookups?.currencies
  );

  return additionalFeesAndChargesEnums;
}
