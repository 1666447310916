const getCurrentEnviroment = () => {

    if (typeof window !== 'undefined') {
        // Code that uses window
        var origin : string= (window as any)?.location?.origin || "";
        if (origin.includes("ims.ufa.com.lb")) {
            return "main";
        }
        if (origin.includes('staging')) {
            return "staging";
        } else if (origin.includes('stable')) {
            return "stable";
        } else if(origin.includes("dev")){
            return "dev";
        }
      } else {
          return "main";
      }

    return "stable";
}


export interface IEnviromentData {
    graphBaseUrl: string;
    mainHostUrl: string;
    identityBaseUrl: string;
    identityRedirectUrl: string;
}


export const getEnviromentDetails = (): IEnviromentData  => {

    const currentEnv = getCurrentEnviroment();

    switch(currentEnv){
        case "main": {
            return {
                graphBaseUrl: "https://ufapos-api.ufa.com.lb",
                mainHostUrl: 'https://ims.ufa.com.lb',
                identityBaseUrl: 'https://ufapos-id.ufa.com.lb',
                identityRedirectUrl: `https://ims.ufa.com.lb/connect/token`,
            }
        }
        case "staging": {
            return {
                graphBaseUrl: "https://ufa-api-staging.baibars.club",
                mainHostUrl: "https://ufa-ui-staging.baibars.club",
                identityBaseUrl: 'https://ufa-id-staging.baibars.club',
                identityRedirectUrl: `https://ufa-ui-staging.baibars.club/connect/token`,
            }
        }
        case "stable": {
            return {
                graphBaseUrl: "https://ufa-api-stable.baibars.club",
                mainHostUrl: "https://ufa-ui-stable.baibars.club",
                identityBaseUrl: 'https://ufa-id-stable.baibars.club',
                identityRedirectUrl: `https://ufa-ui-stable.baibars.club/connect/token`,
            }
        }
        case "dev": {
            return {
                graphBaseUrl: "https://ufa-api-dev.baibars.club",
                mainHostUrl: "https://ufa-ui-dev.baibars.club",
                identityBaseUrl: 'https://ufa-id-dev.baibars.club',
                identityRedirectUrl: `https://ufa-ui-dev.baibars.club/connect/token`,
            }
        }
        default: {
            return {
                graphBaseUrl: "https://ufa-api-dev.baibars.club",
                mainHostUrl: "https://ufa-ui-dev.baibars.club",
                identityBaseUrl: 'https://ufa-id-dev.baibars.club',
                identityRedirectUrl: `https://ufa-ui-dev.baibars.club/connect/token`,
            } 
        }
    }
}




