import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { makeStyles } from "tss-react/mui";

interface GenericPopUpProps {
  open: boolean;
  onClose: () => void;
  title: string;
  content: React.ReactNode;
  actions?: React.ReactNode;
  fullWidth?: boolean;
}

const useStyles = makeStyles()(() => ({
  dialogPaper: {
    height: '80%',
    width: '80%',
    maxWidth: '80%',
    minWidth: '80%',
  },
  dialogTitle: {
    fontFamily: 'HelveticaNeue-Regular',
    fontSize: '22px',
    lineHeight: '27px',
    borderBottom: '1px solid rgba(220, 220, 220, 0.5)',
    '& h2': {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      width: '100%',
    },
    '&.MuiDialogTitle-root': {
      display: 'flex',
    },
    marginBottom:'15px'
  },
  serachInputContainer: {
    display: 'contents',
  },
  dialogContent: {},
  searchField: {
    display: 'inline-block',
    width: '25% !important',
    minWidth: '300px',
  },
}));

const GenericPopUp: React.FC<GenericPopUpProps> = ({
  open,
  onClose,
  title,
  content,
  actions,
  fullWidth = false,
}) => {
  const {classes} = useStyles();
  
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={fullWidth}
      classes={{ paper: classes.dialogPaper }}
      BackdropProps={{ style: { backdropFilter: 'blur(20px)' } }}
    >
      <DialogTitle>
        {title}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
};

export default GenericPopUp;
