import React, { useEffect, useState } from 'react';
import GenericDrawer from '../../components/common/generic-drawer/GenericDrawer';
import DynamicForm from '../../DynamicForm/DynamicForm';
import {
  DynamicFormInputType,
} from '../../DynamicForm';
import { toast } from 'react-toastify';
import { EnhancedButtonStatus } from '../../components/common/EnhancedButton';
import { normaliseDynamicValues } from '../../utils/dynamic-utils';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { inputs } from './content';
import { useMutation } from '@apollo/client';
import { createNssfCodeRange } from './queries';
import { cloneDeep } from 'lodash';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import { DEFAULT_ERROR_TEXT } from '../../constants';
import { isEmpty } from '../../utils/validationUtils';
import { getError } from '../../utils/graph-utils';

const NssfCodeRangeDrawer: React.FC<INSSFCodeRangeDrawerProps> = ({
  open,
  onSuccess,
  onClose,
  extension,
}) => {

  const [nssfCodeRangeAction] = useMutation(createNssfCodeRange());
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();
  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);

  const initialize = () => {
    try {
      const updatedInputs = cloneDeep(inputsForm);

      setInputsForm(updatedInputs);
    } catch (err) {
      toast.error(
        <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>,
      );
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputs, values);
    setFormDisabled(true);
    setSubmitButtonState('loading');
    try {
      let variables = {
        rangeFrom: parseInt(data.rangeFrom as unknown as string),
        rangeTo: parseInt(data.rangeTo as unknown as string)
      };

      //then to handle success request and catch to handle errors, it is mandatory to use them both for similar cases
      nssfCodeRangeAction({
        variables: variables,
        errorPolicy: 'all',
      })
        .then((res) => {
          if (isEmpty(res.errors)) {
            toast.success(
              <ToastSuccessMessage>
                {'Bar Code Range created successfully'}
              </ToastSuccessMessage>,
            );
            setTimeout(() => {
              setSubmitButtonState('success');
              onSuccess();
              onClose();
            }, 500);
          } else {
            setSubmitButtonState(undefined);
            toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
          }
        });
    } catch {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setFormDisabled(false);
    }
  };

  useEffect(() => {
    initialize();
  }, [null]);

  return (
    <GenericDrawer title={'New Range'} onClose={() => onClose()} isOpen={open}>
      {(
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={'Submit'}
            submitButtonState={submitButtonState}
            disableForm={formDisabled}
            title='Information'
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default NssfCodeRangeDrawer;
