import { IEntityInfoView } from '../../components/widgets/entity-info/EntityInfoFields';

export const steps = [
  'New',
  'Quote Preparation',
  'Quote Confirmation',
  'Closed',
];

export const entityView: IEntityInfoView = {
  name: 'section1',
  title: 'Application Details 1',
  iconUrl: '',
  sections: [
    {
      name: 'applicationDetails',
      title: '',
      properties: {
        stateReason: {
          value: '',
          multiline: false,
          name: 'stateReason',
          title: 'Status Reason',
        },
        lineOfBusiness: {
          value: '',
          multiline: false,
          name: 'lineOfBusiness',
          title: 'Line Of Business',
        },
        agency: {
          value: '',
          multiline: false,
          name: 'agency',
          title: 'Business Partner',
        },
        agentName: {
          value: '',
          multiline: false,
          name: 'agentName',
          title: 'Business User',
        },
        customerName: {
          value: '',
          multiline: false,
          name: 'customerName',
          title: 'Customer Name',
        },
      },
    },
    {
      name: 'applicationDetails2',
      title: '',
      properties: {
        createdBy: {
          value: '',
          multiline: false,
          name: 'createdBy',
          title: 'Created By',
        },
        createdOn: {
          value: '',
          multiline: false,
          name: 'createdOn',
          title: 'Created On',
        },
        modifiedBy: {
          value: '',
          multiline: false,
          name: 'modifiedBy',
          title: 'Modified By',
        },
        modifiedOn: {
          value: '',
          multiline: false,
          name: 'modifiedOn',
          title: 'Modified On',
        },
      },
    },
  ],
  socialMedia: {
    facebook: '',
    linkedIn: '',
    twitter: '',
  },
};
