import { DynamicFormInputType, FormInputTypes } from '../../DynamicForm';

export const initialValues: Record<string, string> = {
  cancelationDate: '',
  reason: '',
};

export const inputs: Record<string, DynamicFormInputType> = {
  cancelationDate: {
    name: 'cancelationDate',
    title: 'Cancelation Date',
    type: FormInputTypes.date,
    value: initialValues.cancelationDate,
    required: true,
  },
  reason: {
    name: 'reason',
    title: 'Closing Reason',
    type: FormInputTypes.text,
    value: initialValues.reason,
    required: true,
  },
};
