import React, { useEffect, useState } from "react";
import GenericDrawer from "../../components/common/generic-drawer/GenericDrawer";
import {
  DynamicFormInputType,
  IDynamicSection,
  IFormDateDynamicProps,
  IFormSelectDynamicProps,
  IFormTextDynamicProps,
} from "../../DynamicForm";
import { toast } from "react-toastify";
import { EnhancedButtonStatus } from "../../components/common/EnhancedButton";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { inputs } from "./content";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import Loader from "../../components/Loader";
import { cloneDeep, isEmpty } from "lodash";
import {
  DEFAULT_ERROR_TEXT,
  SEND_TO_BACKEND_DATE_FORMAT,
} from "../../constants";
import { IPlanInformationDrawerProps } from ".";
import SectionDynamicForm from "../../DynamicForm/SectionDynamicForm";
import { getPlanDetailsEnums, updatePlanAndAdditionalDetails } from "./queries";
import { LookupToList } from "./utils";
import { getError } from "../../utils/graph-utils";
import { normaliseDynamicValues } from "../../utils/dynamic-utils";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import { checkPlanExists } from "../plan-drawer/queries";
import dayjs from "dayjs";
import { isZeroValue } from "../../utils/validationUtils";

const PlanInformationDrawer: React.FC<IPlanInformationDrawerProps> = ({
  open,
  onSuccess,
  onClose,
  planDetailsInfo,
  planId,
}) => {
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();
  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);
  const [newEffectiveFrom, setNewEffectiveFrom] = useState<any>();
  const [newEffectiveTo, setNewEffectiveTo] = useState<any>();
  const [hasPlanEffectiveToError, setHasPlanEffectiveToError] = useState("");
  let [submitButtonDisabled, setSubmitButtonDisabled] =
    useState<boolean>(false);

  const planDetailsListResults = useQuery(getPlanDetailsEnums());
  const [checkPlanExistsLazy] = useLazyQuery(checkPlanExists(), {});

  const [planDetailsAction] = useMutation(updatePlanAndAdditionalDetails());
  let planNameError = "";
  let planExternalCodeError = "";

  const sections: Record<string, IDynamicSection> = {
    section1: {
      title: "General Details",
      inputs: {
        planName: inputsForm.planName,
        externalCode: inputsForm.externalCode,
        arabicName: inputsForm.arabicName,
        effectiveFrom: inputsForm.effectiveFrom,
        effectiveTo: inputsForm.effectiveTo,
        abbreviation: inputsForm.abbreviation,
        planCurrency: inputsForm.planCurrency,
        maxNumberofInsured: inputsForm.maxNumberofInsured,
        maxNumberofBeneficiary: inputsForm.maxNumberofBeneficiary,
        clauseEditable: inputsForm.clauseEditable,
      },
    },
  };

  if (
    planDetailsInfo &&
    ["51", "50", "4"].includes(planDetailsInfo.lineIdExternalCode)
  ) {
    sections.section2 = {
      title: "Additional Details",
      inputs: {
        acalCategory: inputsForm.acalCategory,
        sOSService: inputsForm.sOSService,
        replacementCarApplied: inputsForm.replacementCarApplied,
        replacementCarValue: inputsForm.replacementCarValue,
        replacementCarDays: inputsForm.replacementCarDays,
      },
    };
  }

  useEffect(() => {
    initialize();

    if (planDetailsListResults?.data && planDetailsInfo) {
      const effectiveFromDate = planDetailsInfo.effectiveFrom;
      const effectiveToDate = planDetailsInfo.effectiveTo;
      const effectiveFromAsDate = new Date(effectiveFromDate);
      const effectiveToAsDate = effectiveToDate
        ? new Date(effectiveToDate)
        : effectiveFromAsDate;
      const effectiveToError =
        effectiveToDate &&
        dayjs(effectiveToAsDate).isBefore(dayjs(effectiveFromAsDate))
          ? "Effective To Date shall be equal or greater than Effective From Date"
          : "";

      setInputsForm((currentInputsForm) => {
        const updatedForm = { ...currentInputsForm };

        updatedForm.effectiveFrom = {
          ...updatedForm.effectiveFrom,
          value: dayjs(effectiveFromAsDate).format("YYYY-MM-DD"),
        };

        updatedForm.effectiveTo = {
          ...updatedForm.effectiveTo,
          error: effectiveToError,
        };

        return updatedForm;
      });

      setSubmitButtonDisabled(!!effectiveToError);
    }
  }, [planDetailsListResults?.data]);

  let updatedInputs: Record<string, DynamicFormInputType> = inputs;

  const validatePlanExistsOnNameChange = (
    selectedSublineId: string,
    planName?: string
  ) => {
    if (!isEmpty(planName) && !isEmpty(selectedSublineId)) {
      checkPlanExistsLazy({
        variables: {
          name: planName,
          planId: planId,
          sublineId: selectedSublineId,
          externalCode: "",
        },
      }).then((response) => {
        setInputsForm((currentInputsForm) => {
          const updatedInputs = { ...currentInputsForm };

          if (
            response.data.PlanConfigManagement?.queries?.checkPlanExists
              .length > 0
          ) {
            planNameError = "Plan Name already exists under the same Subline";
            (updatedInputs.planName as IFormTextDynamicProps).error =
              planNameError;
          } else {
            planNameError = "";
            (updatedInputs.planName as IFormTextDynamicProps).error =
              planNameError;
          }
          return updatedInputs;
        });
      });
    }
  };

  const validatePlanExistsOnExternalCodeChange = (
    selectedSublineId: string,
    externalCode?: string
  ) => {
    if (!isEmpty(externalCode) && !isEmpty(selectedSublineId)) {
      checkPlanExistsLazy({
        variables: {
          planId: planId,
          externalCode: externalCode,
          sublineId: selectedSublineId,
          name: "",
        },
      }).then((response) => {
        setInputsForm((currentInputsForm) => {
          const updatedInputs = { ...currentInputsForm };

          if (
            response.data.PlanConfigManagement?.queries?.checkPlanExists
              .length > 0
          ) {
            planExternalCodeError =
              "External Code already exists under the same Subline";
            (updatedInputs.externalCode as IFormTextDynamicProps).error =
              planExternalCodeError;
          } else {
            planExternalCodeError = "";
            (updatedInputs.externalCode as IFormTextDynamicProps).error =
              planExternalCodeError;
          }
          return updatedInputs;
        });
      });
    }
  };

  const initialize = () => {
    try {
      updatedInputs = cloneDeep(inputsForm);

      if (planDetailsListResults.data) {
        const newPlanDetailsEnums = LookupToList(planDetailsListResults.data);

        (updatedInputs.planCurrency as IFormSelectDynamicProps).selectOptions =
          newPlanDetailsEnums["planCurrency"];

        (updatedInputs.acalCategory as IFormSelectDynamicProps).selectOptions =
          newPlanDetailsEnums["acalCategory"];

        (updatedInputs.sOSService as IFormSelectDynamicProps).selectOptions =
          newPlanDetailsEnums["sOSService"];

        (
          updatedInputs.replacementCarApplied as IFormSelectDynamicProps
        ).selectOptions = newPlanDetailsEnums["sOSService"];
      }

      (updatedInputs.planName as IFormTextDynamicProps).onChange = (event) => {
        const newName = event.target.value;
        (updatedInputs.planName as IFormTextDynamicProps).value = newName;
        if (!isEmpty(newName)) {
          validatePlanExistsOnNameChange(planDetailsInfo.sublineId, newName);
        }
      };

      (updatedInputs.externalCode as IFormTextDynamicProps).onChange = (
        event
      ) => {
        const newExternalCode = event.target.value;
        (updatedInputs.externalCode as IFormTextDynamicProps).value =
          newExternalCode;
        if (!isEmpty(newExternalCode)) {
          validatePlanExistsOnExternalCodeChange(
            planDetailsInfo.sublineId,
            newExternalCode
          );
        }
      };

      (updatedInputs.effectiveFrom as IFormDateDynamicProps).onChange = (
        event
      ) => {
        setNewEffectiveFrom(event);
        setInputsForm(updatedInputs);
        (updatedInputs.effectiveFrom as IFormDateDynamicProps).value = event;
        (updatedInputs.effectiveTo as IFormDateDynamicProps).minDate =
          newEffectiveFrom;
        setInputsForm(updatedInputs);
        handleEffectiveDateChange(newEffectiveTo, newEffectiveFrom);
      };

      (updatedInputs.effectiveTo as IFormDateDynamicProps).onChange = (
        event
      ) => {
        setNewEffectiveTo(event);
        setInputsForm(updatedInputs);
        (updatedInputs.effectiveTo as IFormDateDynamicProps).value = event;

        (updatedInputs.effectiveTo as IFormDateDynamicProps).minDate =
          updatedInputs.effectiveFrom.value;
        setInputsForm(updatedInputs);
        handleEffectiveDateChange(newEffectiveTo, newEffectiveFrom);
      };

      handleEffectiveDateChange(
        planDetailsInfo.effectiveTo,
        planDetailsInfo.effectiveFrom
      );

      if (planDetailsInfo) {
        (updatedInputs.effectiveTo as IFormDateDynamicProps).minDate = new Date(
          planDetailsInfo.effectiveFrom
        );

        const externalCode = planDetailsInfo.lineIdExternalCode;
        const showAcalCategory = ["51", "50", "4"].includes(externalCode);
        const showSOSService = ["51", "4"].includes(externalCode);
        const showReplacement = ["51"].includes(externalCode);

        updatedInputs.acalCategory.hidden = !showAcalCategory;
        updatedInputs.acalCategory.disabled = !showAcalCategory;
        updatedInputs.acalCategory.required = showAcalCategory;

        updatedInputs.sOSService.hidden = !showSOSService;
        updatedInputs.sOSService.disabled = !showSOSService;
        updatedInputs.sOSService.required = showSOSService;

        updatedInputs.replacementCarApplied.hidden = !showReplacement;
        updatedInputs.replacementCarApplied.disabled = !showReplacement;
        updatedInputs.replacementCarApplied.required = showReplacement;

        updatedInputs.replacementCarValue.hidden = !showReplacement;
        updatedInputs.replacementCarValue.disabled = !showReplacement;
        updatedInputs.replacementCarValue.required = showReplacement;

        updatedInputs.replacementCarDays.hidden = !showReplacement;
        updatedInputs.replacementCarDays.disabled = !showReplacement;
        updatedInputs.replacementCarDays.required = showReplacement;

        updatedInputs.planName.value = planDetailsInfo.planName;
        updatedInputs.externalCode.value = planDetailsInfo.externalCode;
        updatedInputs.arabicName.value = planDetailsInfo.arabicName;
        updatedInputs.effectiveFrom.value = planDetailsInfo.effectiveFrom;
        updatedInputs.effectiveTo.value = planDetailsInfo.effectiveTo;
        updatedInputs.abbreviation.value = planDetailsInfo.abbreviation;
        updatedInputs.planCurrency.value = planDetailsInfo.planCurrency;
        updatedInputs.maxNumberofInsured.value =
          planDetailsInfo.maxNumberofInsured;
        updatedInputs.maxNumberofBeneficiary.value =
          planDetailsInfo.maxNumberofBeneficiary;
        updatedInputs.clauseEditable.value =
          planDetailsInfo.clauseEditable;
        updatedInputs.acalCategory.value = planDetailsInfo.acalCategory;
        updatedInputs.sOSService.value = planDetailsInfo.sOSService;
        updatedInputs.replacementCarApplied.value =
          planDetailsInfo.replacementCarApplied;
        updatedInputs.replacementCarValue.value =
          planDetailsInfo.replacementCarValue;
        updatedInputs.replacementCarDays.value =
          planDetailsInfo.replacementCarDays;

        setNewEffectiveFrom(planDetailsInfo.effectiveFrom);
        setNewEffectiveTo(planDetailsInfo.effectiveTo);
      }

      setInputsForm(updatedInputs);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const handleEffectiveDateChange = (effectiveTo: any, effectiveFrom: any) => {
    if (
      effectiveTo &&
      effectiveFrom &&
      dayjs(effectiveTo).isBefore(dayjs(effectiveFrom))
    ) {
      setHasPlanEffectiveToError(
        "Effective To Date shall be equal or greater than Effective From Date"
      );
      setInputsForm((currentInputsForm) => ({
        ...currentInputsForm,
        effectiveTo: {
          ...currentInputsForm.effectiveTo,
          minDate: effectiveFrom,
          error:
            "Effective To Date shall be equal or greater than Effective From Date",
        },
      }));
      setSubmitButtonDisabled(true);
    } else {
      setHasPlanEffectiveToError("");
      setInputsForm((currentInputsForm) => ({
        ...currentInputsForm,
        effectiveTo: {
          ...currentInputsForm.effectiveTo,
          minDate: effectiveFrom,
          error: "",
        },
      }));
      // Re-check other validation states before enabling the button
      setSubmitButtonDisabled(
        hasPlanEffectiveToError !== "" || hasPlanEffectiveToError !== ""
      );
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputs, values);

    setFormDisabled(true);
    setSubmitButtonState("loading");

    try {
      let variablesMutation = {
        entityId: planId,
        planDetailInputs: {
          externalCode: data.externalCode || null,
          lineID: planDetailsInfo.lineId || null,
          sublineID: planDetailsInfo.sublineId || null,
          name: data.planName || null,
          nameArabic: data.arabicName || null,
          abbreviation: data.abbreviation || null,
          effectiveFrom:
            dayjs(new Date(data.effectiveFrom)).format(
              SEND_TO_BACKEND_DATE_FORMAT
            ) || null,
          effectiveTo:
            dayjs(new Date(data.effectiveTo)).format(
              SEND_TO_BACKEND_DATE_FORMAT
            ) || null,
          planStatus: planDetailsInfo.status || null,
          maxNbInsured: parseInt(data?.maxNumberofInsured),
          maxNbBeneficiary: parseInt(data?.maxNumberofBeneficiary),
          clauseEditable: data?.clauseEditable,
        },
        planAdditionalDetailInputs: {
          planCurrency: data.planCurrency || null,
          acalCategory: data.acalCategory || null,
          sosService: data?.sOSService || null,
          replacementCarApplied: data.replacementCarApplied || null,
          replacementCarValue: isZeroValue(data.replacementCarValue)
            ? 0
            : Number(data.replacementCarValue) || null,
          replacementCarDays: isZeroValue(data.replacementCarDays)
            ? 0
            : Number(data.replacementCarDays) || null,
        },
      };

      planDetailsAction({
        variables: variablesMutation,
        errorPolicy: "all",
      })
        .then((res) => {
          if (isEmpty(res.errors)) {
            toast.success(
              <ToastSuccessMessage>
                Plan Details successfully updated.
              </ToastSuccessMessage>
            );
            setTimeout(() => {
              setSubmitButtonState("success");
              onSuccess();
              onClose();
            }, 500);
          } else {
            setSubmitButtonState(undefined);
            toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
          }
        })
        .catch((err) => {
          toast.error(<ToastErrorMessage>{getError(err)}</ToastErrorMessage>);
        });
    } catch {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setFormDisabled(false);
    }
  };

  useEffect(() => {
    handleEffectiveDateChange(newEffectiveTo, newEffectiveFrom);
  }, [newEffectiveFrom]);
  useEffect(() => {
    handleEffectiveDateChange(newEffectiveTo, newEffectiveFrom);
  }, [newEffectiveTo]);

  useEffect(() => {
    const isDisabled = !isEmpty(hasPlanEffectiveToError);

    setSubmitButtonDisabled(isDisabled);
  }, [hasPlanEffectiveToError]);

  return (
    <GenericDrawer
      title={"Modify Plan Details"}
      onClose={() => onClose()}
      isOpen={open}
    >
      {planDetailsListResults.loading && open ? (
        <Loader />
      ) : (
        <>
          <SectionDynamicForm
            onSubmit={(values) => submitForm(values)}
            buttonText={"Submit"}
            submitButtonState={submitButtonState}
            isSubmitButtonDisabled={submitButtonDisabled}
            disableForm={formDisabled}
            inputs={inputsForm}
            sections={sections}
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default PlanInformationDrawer;
