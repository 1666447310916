import { makeStyles } from 'tss-react/mui';
import React from 'react';
import clsx from 'clsx';
import { IEnhancedCommonProps } from '..';
import { MAIN_ONE_THEME } from '../../constants';

interface ISeparatorProps extends IEnhancedCommonProps {}

const useStyles = makeStyles()(() => ({
  separator: {
    background: `${MAIN_ONE_THEME.palette.secondary4.main} 0% 0% no-repeat padding-box`,
    height: 10,
    width: '100%',
    margin: '10px 0',
  },
}));

const Separator: React.FC<ISeparatorProps> = ({ className, style }) => {
  const { classes } = useStyles();

  return <div className={clsx(classes.separator, className)} style={style} />;
};

export default Separator;
