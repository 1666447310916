import { gql } from "@apollo/client";

export function getSublineDetailsInfo() {
  return gql`
    query getSublineDetailsInfo($id: String!) {
      PlanConfigManagement {
        entities {
          subline(id: $id) {
            views {
              PlanConfigManagement_all {
                properties {
                  LineID {
                    id
                    views {
                      PlanConfigManagement_all {
                        properties {
                          Name
                          Status
                        }
                      }
                    }
                  }
                  Name
                  Status
                  ExternalCode
                  NameArabic
                  createdBy
                  createdOn
                  modifiedBy
                  modifiedOn
                }
              }
            }
            queries {
              planConfigManagement {
                relatedUsers {
                  createdBy_firstName
                  createdBy_lastName
                  modifiedBy_firstName
                  modifiedBy_lastName
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function activateSubline() {
  return gql`
    mutation activateSubline($entityId: String!) {
      planConfigManagement {
        entities {
          subline {
            planConfigManagement {
              activateSubline(entityId: $entityId) {
                id
              }
            }
          }
        }
      }
    }
  `;
}

export function deactivateSubline() {
  return gql`
    mutation deactivateSubline($entityId: String!) {
      planConfigManagement {
        entities {
          subline {
            planConfigManagement {
              deactivateSubline (entityId: $entityId) {
                id
              }
            }
          }
        }
      }
    }
  `;
}

export function getSublineDetailsPagePlanList() {
  return gql`
    query getSublineDetailsPagePlanList(
      $currentPage: Int!
      $currentPageSize: Int!      
      $SelectedSublineID: [String]
    ) {      
        PlanConfigManagement{
          queries{
            getPlanList(
              pagination: {
              pageNumber: $currentPage,
              pageSize: $currentPageSize
            }
            SelectedSublineID: $SelectedSublineID){
              paging {
                pageSize
                pageNumber
                totalCount
              }
              items{
                planConfigManagement_Plan_Id
                planConfigManagement_Plan_EffectiveFrom
                planConfigManagement_Plan_EffectiveTo
                planConfigManagement_Plan_PlanStatus
                planConfigManagement_Plan_createdOn
                planConfigManagement_Plan_modifiedOn
                planConfigManagement_Plan_ExternalCode
                planConfigManagement_Plan_Name
                planConfigManagement_Plan_NameArabic
                planConfigManagement_Plan_SublineID{
                  views{
                    PlanConfigManagement_all{
                      properties{
                        Name
                        Status
                      }
                    }
                  }
                }
                planConfigManagement_Plan_LineID{
                  id
                  views{
                    PlanConfigManagement_all{
                      properties{
                        ExternalCode
                        NameArabic
                        Name
                        Abbreviation
                        AbbreviationArabic
                        Status
                      }
                    }
                  }
                }
              }
            }
          }
        }
      } 
  `;
}