import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import GenericDrawer from "../../components/common/generic-drawer/GenericDrawer";
import DynamicForm from "../../DynamicForm/DynamicForm";
import { inputs } from "./content";
import { cloneDeep, isEmpty } from "lodash";
import {
  DynamicFormInputType,
  IFormSelectDynamicProps,
} from "../../DynamicForm";
import { toast } from "react-toastify";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { DEFAULT_ERROR_TEXT } from "../../constants";
import { useMutation, useQuery } from "@apollo/client";
import {
  getAssignedLineInfo,
  getDefaultCommissionEnums,
  setBusinessPartnerLineCommission,
} from "./queries";
import { LookupToList, graphqlToDefaultCommissionInfo } from "./utils";
import Loader from "../../components/Loader";
import EnhancedNote from "../../components/enhanced-form/EnhancedNote";
import { normaliseDynamicValues } from "../../utils/dynamic-utils";
import { EnhancedButtonStatus } from "../../components/EnhancedButton";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import { getError } from "../../utils/graph-utils";

const useStyles = makeStyles()(() => ({
  note: {
    color: "red !important",
    fontSize: "12px !important",
    fontStyle: "italic",
  },
}));

const SetDefaultCommissionDrawer: React.FC<
  ISetDefaultCommissionDrawerProps
> = ({ businessPartnerId, assignedLineId, open, onSuccess, onClose }) => {
  const { classes } = useStyles();
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();
  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);

  let defaultCommissionInfoResult: any;
  if (assignedLineId) {
    defaultCommissionInfoResult = useQuery(getAssignedLineInfo(), {
      variables: { id: assignedLineId },
    });
  }
  //const [selectedSublineStatus, setSelectedSublineStatus] = useState<string>("");
  let newDefualtCommissionEnums: Record<string, Record<string, string>> = {};

  let defaultCommissionEnumResults = useQuery(getDefaultCommissionEnums(), {
    fetchPolicy: "no-cache",
  });

  const [defaultCommissionAction] = useMutation(
    setBusinessPartnerLineCommission()
  );

  useEffect(() => {
    initialize();
  }, [defaultCommissionEnumResults?.data, defaultCommissionInfoResult?.data]);

  let updatedInputs: Record<string, DynamicFormInputType> = inputs;
  let defaultCommissionEntity: ISetDefaultCommissionInfo = {
    assignedLineId: "",
    assignedLine: "",
    commissionType: "",
    lineCommissionValue: null,
    ValueCurrency: "",
    lineCommissionRate: null,
    ApplicableOn: "",
  };

  const initialize = () => {
    try {
      updatedInputs = cloneDeep(inputsForm);

      if (defaultCommissionEnumResults.data) {
        newDefualtCommissionEnums = LookupToList(
          defaultCommissionEnumResults.data
        );

        (
          updatedInputs.commissionType as IFormSelectDynamicProps
        ).selectOptions = newDefualtCommissionEnums["commissionTypes"];

        (updatedInputs.ValueCurrency as IFormSelectDynamicProps).selectOptions =
          newDefualtCommissionEnums["currencies"];

        (updatedInputs.ApplicableOn as IFormSelectDynamicProps).selectOptions =
          newDefualtCommissionEnums["commissionApplicableOnOptions"];

        (updatedInputs.commissionType as IFormSelectDynamicProps).onSelect = (
          option
        ) => {
          const selectedType = option.toLowerCase();
          const isValue = selectedType === "value";

          updatedInputs.ValueCurrency.hidden = !isValue;
          updatedInputs.ValueCurrency.disabled = !isValue;
          updatedInputs.ValueCurrency.required = isValue;

          updatedInputs.lineCommissionValue.hidden = !isValue;
          updatedInputs.lineCommissionValue.disabled = !isValue;
          updatedInputs.lineCommissionValue.required = isValue;

          updatedInputs.lineCommissionRate.hidden = isValue;
          updatedInputs.lineCommissionRate.disabled = isValue;
          updatedInputs.lineCommissionRate.required = !isValue;
        };

        if (defaultCommissionInfoResult?.data) {
          defaultCommissionEntity = graphqlToDefaultCommissionInfo(
            defaultCommissionInfoResult?.data
          );
        }

        if (defaultCommissionEntity) {
          updatedInputs.assignedLine.value =
            defaultCommissionEntity.assignedLine;
          updatedInputs.commissionType.value =
            defaultCommissionEntity.commissionType || "RATE";

          if (updatedInputs.commissionType.value) {
            const isCurrentValue =
              updatedInputs.commissionType.value.toLowerCase() === "value";

            updatedInputs.ValueCurrency.hidden = !isCurrentValue;
            updatedInputs.ValueCurrency.disabled = !isCurrentValue;
            updatedInputs.ValueCurrency.required = isCurrentValue;

            updatedInputs.lineCommissionValue.hidden = !isCurrentValue;
            updatedInputs.lineCommissionValue.disabled = !isCurrentValue;
            updatedInputs.lineCommissionValue.required = isCurrentValue;

            updatedInputs.lineCommissionRate.hidden = isCurrentValue;
            updatedInputs.lineCommissionRate.disabled = isCurrentValue;
            updatedInputs.lineCommissionRate.required = !isCurrentValue;
          }

          updatedInputs.ApplicableOn.value =
            defaultCommissionEntity.ApplicableOn;
          updatedInputs.lineCommissionRate.value = Math.round(
            parseFloat(
              (defaultCommissionEntity.lineCommissionRate * 100).toFixed(10)
            )
          );
          updatedInputs.lineCommissionValue.value =
            defaultCommissionEntity.lineCommissionValue;
          updatedInputs.ValueCurrency.value =
            defaultCommissionEntity.ValueCurrency || "39735";

          setInputsForm(updatedInputs);
        }
      }
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputs, values);
    setFormDisabled(true);
    setSubmitButtonState("loading");
    try {
      const isRate = data.commissionType === "RATE";
      let variables = {
        lineCommissionInputs: {
          lineCommissionType: data.commissionType,
          lineCommissionValue: isRate ? 0 : Number(data.lineCommissionValue),
          lineCommissionValueCurrency: isRate
            ? null
            : data.ValueCurrency || "39735",
          lineCommissionRate: !isRate
            ? 0
            : Number(data.lineCommissionRate) / 100,
          lineCommissionApplicableOn: data.ApplicableOn,
        },
      };
      defaultCommissionAction({
        variables: { ...variables, entityId: assignedLineId },
        errorPolicy: "all",
      }).then((res) => {
        if (isEmpty(res.errors)) {
          toast.success(
            <ToastSuccessMessage>
              {
                "Line default commissions successfully updated. Note that this change will not affected previously assigned plans"
              }
            </ToastSuccessMessage>
          );
          setTimeout(() => {
            setSubmitButtonState("success");
            onSuccess();
            onClose();
          }, 500);
        } else {
          setSubmitButtonState(undefined);
          toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
        }
      });
    } catch {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setFormDisabled(false);
    }
  };

  return (
    <GenericDrawer
      title={"Set Default Commission"}
      onClose={() => onClose()}
      isOpen={open}
    >
      {(defaultCommissionEnumResults.loading ||
        defaultCommissionInfoResult?.loading) &&
      open ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={"Submit"}
            submitButtonState={submitButtonState}
            disableForm={formDisabled}
            title="Information"
            noteSection={
              <EnhancedNote
                name="note"
                title=""
                text="Note that changing the default commissions will only affect assigned plans in the future. This change will not affected previously assigned plans."
                className={classes.note}
              />
            }
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default SetDefaultCommissionDrawer;
