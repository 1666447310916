import React, { useEffect, useState } from 'react';
import { IListingData } from '../../models/listing';
import StaticLayout from '../../page-layout/static-layout/StaticLayout';
import { filterSectionsContent, headers } from './content';
import EnhancedTable from '../../components/enhanced-table/EnhancedTable';
import _ from 'lodash';
import UserDrawer from '../../forms/user-drawer/UserDrawer';
import { listQuery } from './queries';
import { useLazyQuery } from '@apollo/client';
import {
  IEnhanceTableHeaderClickable,
  IEnhancedTableMenuItem,
} from '../../components/enhanced-table';
import { IListingFilterWidgetSection } from '../../components/widgets/custom-listing-filter';
import { IAbstractRecord } from '../../models';
import { toast } from 'react-toastify';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { DEFAULT_ERROR_TEXT } from '../../constants';
import { getFilter, setFilter } from '../../utils/filter-utils';
import ListingFilterWidget from '../../components/widgets/custom-listing-filter/ListingFilterWidget';
import { mapToListingData } from './utils';
import { isEmpty } from '../../utils/validationUtils';

const FILTER_SESSION_KEY = 'usersFilter';

const UsersPage: React.FC = () => {
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [tableLoadingState, setTableLoadingState] = useState<boolean>(false);
  const [userDrawerOpen, setUserDrawerOpen] = useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState<string>('');

  const [tableData, setTableData] = useState<IListingData<IUsersTableData>>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 0,
    totalCount: 0,
  });

  const [filterSections, setFilterSections] =
    useState<IListingFilterWidgetSection[]>();

  const initialfilterValues =
    Object.keys(getFilter(FILTER_SESSION_KEY)).length > 0
      ? getFilter(FILTER_SESSION_KEY)
      : {
          role: ['ADMIN'],
          status: ['ACTIVE'],
        };

  const [filterValues, setFilterValues] =
    useState<IAbstractRecord>(initialfilterValues);

  const [listQueryLazy, { loading, error, data, fetchMore }] = useLazyQuery(
    listQuery(),
    {
      variables: {
        currentPage: 1,
        currentPageSize: tableData.pageSize,
        userRole:
          filterValues?.role && filterValues?.role?.length > 0
            ? filterValues.role
            : null,
        userStatus:
          filterValues?.status && filterValues?.status?.length > 0
            ? filterValues.status
            : null,
      },
    }
  );

  useEffect(() => {
    listQueryLazy();
  }, []);

  useEffect(() => {
    if (loading) setLoadingState(true);
    if (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
    if (data) {
      const tableData = mapToListingData(data);
      setTableData({ ...tableData, pageNumber: 0 });

      let savedFilters = getFilter(FILTER_SESSION_KEY);
      if (isEmpty(savedFilters) || Object.keys(savedFilters).length == 0) {
        savedFilters = _.cloneDeep(filterValues);
        setFilter(savedFilters, FILTER_SESSION_KEY);
      }
      setFilterValues(savedFilters);

      const newFilterSections = filterSectionsContent(data, savedFilters);
      setFilterSections(newFilterSections);
    }
    setLoadingState(false);
  }, [loading, error, data]);

  function updateUsersList(page: number) {
    setTableLoadingState(true);
    const nextPage = ++page;
    fetchMore({
      variables: {
        currentPage: nextPage,
        currentPageSize: tableData.pageSize,
      },
    })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const updatedTableData = {
            ...mapToListingData(fetchMoreResult.data),
            pageNumber: nextPage - 1,
          };
          setTableData(updatedTableData);
        }
      })
      .finally(() => {
        setTableLoadingState(false);
      });
  }

  function handleRowsPerPageChange(numberOfRecordsPerPage: number) {
    setTableLoadingState(true);
    setTableData({ ...tableData, pageNumber: 1 });
    fetchMore({
      variables: {
        currentPage: 1,
        currentPageSize: numberOfRecordsPerPage,
      },
    })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const updatedTableData = {
            ...mapToListingData(fetchMoreResult.data),
            pageNumber: 0,
            pageSize: numberOfRecordsPerPage,
          };
          setTableData(updatedTableData);
        }
      })
      .finally(() => {
        setTableLoadingState(false);
      });
  }

  let actions: IEnhancedTableMenuItem[] = [];

  if (data?.Insurance?.actions?.able_To_CreateUser) {
    actions = [
      {
        title: '+ New',
        onClick: () => {},
        isEntity: false,
        isBulk: false,
        iconUrl: '',
      },
    ];

    actions[0].onClick = () => {
      setUserDrawerOpen(true);
    };
  }

  if (data?.Insurance?.actions?.able_To_UpdateUser) {
    actions[0].onClick = () => {
      setSelectedUserId(undefined);
      setUserDrawerOpen(true);
    };
    (headers.system_User_firstName as IEnhanceTableHeaderClickable).callback = (
      payload: any
    ) => {
      setSelectedUserId(payload.columns.system_User_Id);
      setUserDrawerOpen(true);
    };
  }

  const onFilterUpdate = async (v: Record<string, any>) => {
    let newFilters = _.cloneDeep(v);
    setFilter(newFilters, FILTER_SESSION_KEY);
    setFilterValues(newFilters);
  };

  const renderFilter = () => {
    return (
      filterSections && (
        <ListingFilterWidget
          name={'Users Filter'}
          filters={filterSections}
          onApplyFilter={(v) => {
            onFilterUpdate(v);
          }}
        ></ListingFilterWidget>
      )
    );
  };

  const renderMainChildren = () => {
    return (
      <>
        {loading ? (
          <div></div>
        ) : (
          <div style={{ marginTop: '20px' }}>
            <EnhancedTable
              title={'USERS'}
              name='count'
              orderByAscendingByDefault
              defaultOrderByColumn='firstName'
              inline={false}
              data={tableData}
              headers={headers}
              handleRowsPerPageChange={(page: number) =>
                handleRowsPerPageChange(page)
              }
              handlePageChange={(page: number) => updateUsersList(page)}
              currentPage={tableData.pageNumber}
              hideToolbar
              usePagination
              disableSelection
              actions={actions}
              loader={tableLoadingState}
            />
            {userDrawerOpen && (
              <UserDrawer
                open={userDrawerOpen}
                onClose={() => setUserDrawerOpen(false)}
                onSuccess={() => {
                  updateUsersList(0);
                }}
                id={selectedUserId}
              />
            )}
          </div>
        )}
      </>
    );
  };

  return (
    <StaticLayout
      loading={loadingState}
      name={'Users'}
      leftChildren={renderFilter()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default UsersPage;
