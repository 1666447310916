import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Shell from "./Shell";
import LoginPortal from "./auth-pages/login";
import {
  useAppDetailsApi,
  useAppDispatch,
  useAppSelector,
  useUserDetailsApi,
} from "./redux/hooks";
import { LoadUserSuccessAction, SignoutUserAction } from "./redux/user/actions";
import { LoadAppSettingsSuccessAction } from "./redux/app-settings/actions";
import { toast } from "react-toastify";
import ToastErrorMessage from "./components/ToastErrorMessage";
import { getError } from "./utils/graph-utils";

const Protected: React.FC = () => {
  const user = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [booted, setBooted] = useState<boolean>(false);

  const { getUserDetails } = useUserDetailsApi();
  const { getAppDetails } = useAppDetailsApi();

  useEffect(() => {
    tryLoadUser();
  }, [user.isAuthenticated]);
  useEffect(() => {
    tryLoadAppSettingsDetails();
  }, [user.isAuthenticated]);

  const logout = () => {
    if (!location.pathname.includes("/login")) {
      dispatch(SignoutUserAction());

      let redirect = "";
      if (window.location.pathname !== "/") {
        redirect = encodeURIComponent(
          window.location.href.slice(
            window.location.href.indexOf(window.location.pathname)
          )
        );
      }
      navigate(`/login${redirect ? `/${redirect}` : ""}`, {
        replace: true,
      });
    }
  };

  const tryLoadUser = async () => {
    try {
      setBooted(false);
      if (!user.isAuthenticated) {
        const userDetails = await getUserDetails();
        if (userDetails === null) {
          logout();
        } else {
          dispatch(LoadUserSuccessAction(userDetails));
        }
      }
    } catch {
      logout();
    } finally {
      setBooted(true);
    }
  };

  const tryLoadAppSettingsDetails = async () => {
    try {
      if (user.isAuthenticated) {
        const appDetails = await getAppDetails();

        dispatch(LoadAppSettingsSuccessAction(appDetails));
      }
    } catch {
      toast.error(<ToastErrorMessage>{getError()}</ToastErrorMessage>);
    }
  };

  return booted ? (
    <Routes>
      <Route index element={<Shell />} />
      <Route path="/login">
        <Route index element={<LoginPortal />} />
        <Route path=":redirect" element={<LoginPortal />} />
      </Route>
      <Route path="*" element={<Shell />} />
    </Routes>
  ) : (
    <></>
  );
};

export default Protected;
