import { IAbstractRecord } from "../../models";
import { IListingData } from "../../models/listing";

export function mapToListingData(data: any): IListingData<any> {
  const list: IAbstractRecord = {};

  data.Configuration.queries.getTaxationList.items.forEach(
    (obj: IGenericTaxationsTableData, i: number) => {
      list[i] = {
        configuration_Taxation_Id: obj.configuration_Taxation_Id,
        lineId: obj.line_Id,
        line: obj.line_Name,
        lineCode: obj.line_ExternalCode,
        fixedStamp: `${obj.configuration_Taxation_FixedStampCurrency?.Title} ${obj.configuration_Taxation_FixedStamp}`,
        proportionalStamp: `${Math.round(
          parseFloat(
            (
              Number(obj.configuration_Taxation_ProportionalStamp) * 100
            ).toFixed(10)
          )
        )}%`,
        municipalityTax: `${Math.round(
          parseFloat(
            (Number(obj.configuration_Taxation_MunicipalityTax) * 100).toFixed(
              10
            )
          )
        )}%`,
        taxOnCommission: `${Math.round(
          parseFloat(
            (Number(obj.configuration_Taxation_TaxOnCommission) * 100).toFixed(
              10
            )
          )
        )}%`,
        reinsuranceTax: `${Math.round(
          parseFloat(
            (Number(obj.configuration_Taxation_ReinsuranceTax) * 100).toFixed(
              10
            )
          )
        )}%`,
        effectiveFrom: obj.configuration_Taxation_EffectiveFrom,
        effectiveTo: obj.configuration_Taxation_EffectiveTo,
        createdOn: obj.configuration_Taxation_createdOn,
        modifiedOn: obj.configuration_Taxation_modifiedOn,
      };
    }
  );

  return {
    pagedItems: list,
    pageSize: data.Configuration.queries.getTaxationList?.paging?.pageSize,
    pageNumber: data.Configuration.queries.getTaxationList?.paging?.pageNumber,
    totalCount: data.Configuration.queries.getTaxationList?.paging?.totalCount,
  };
}

export function dataToLovs(data: any): Record<string, Record<string, string>> {
  let lovs: Record<string, Record<string, string>> = {
    line: {},
  };

  data?.PlanConfigManagement?.queries?.allLines?.forEach(
    (obj: {
      planConfigManagement_Line_Id: string;
      planConfigManagement_Line_Name: string;
    }) => {
      lovs.line[obj.planConfigManagement_Line_Id] =
        obj.planConfigManagement_Line_Name;
    }
  );

  return lovs;
}
