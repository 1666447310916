export interface IPolicyCoverDetailsPageProps {}

export interface IPolicyCoverDetails {
  policyCoverName: string;
  lineName: string;
  externalCode: string;
  arabicName: string;
  printingOrder: string;
  policyCoverDescription: string;
}

export const initialValues: IPolicyCoverDetails = {
  policyCoverName: '',
  lineName: '',
  externalCode: '',
  arabicName: '',
  printingOrder:'',
  policyCoverDescription: '',
};

export interface IGenericClaimCoverTableData {
  planConfigManagement_ClaimCover_Id: string;
  planConfigManagement_ClaimCover_Name: string;
  planConfigManagement_ClaimCover_ExternalCode: string;
  planConfigManagement_ClaimCover_PolicyCoverID: {
    views: {
      PlanConfigManagement_all: {
        properties: {
          Name: string;          
        };
      };
    };
  };
  planConfigManagement_ClaimCover_NameArabic: string;
  planConfigManagement_ClaimCover_PrintingOrder: string;
  planConfigManagement_ClaimCover_ClaimCoverDescription: string;
  planConfigManagement_ClaimCover_createdOn: string;
  planConfigManagement_ClaimCover_modifiedOn: string;
}