import { IAbstractRecord } from '../../models';
import { IListingData } from '../../models/listing';
import { capitalizeFirstLetter } from '../../utils/formatting-utils';
import { enumListAsRecordObject } from '../../utils/graph-utils';

export function mapToListingData(data: any): IListingData<any> {
  const list: IAbstractRecord = {};

  data.PlanConfigManagement.queries.getPlanList.items.forEach(
    (obj: IGenericPlansTableData, i: number) => {
      const sublineName =
        obj.planConfigManagement_Plan_SublineID?.views?.PlanConfigManagement_all?.properties?.Name;
      const lineName =
        obj.planConfigManagement_Plan_LineID?.views?.PlanConfigManagement_all?.properties?.Name;
      list[i] = {
        id: obj.planConfigManagement_Plan_Id,
        planName: obj.planConfigManagement_Plan_Name,
        subline: sublineName,
        line: lineName,
        externalCode: obj.planConfigManagement_Plan_ExternalCode,
        arabicName: obj.planConfigManagement_Plan_NameArabic,
        effectiveFrom: obj.planConfigManagement_Plan_EffectiveFrom,
        effectiveTo: obj.planConfigManagement_Plan_EffectiveTo,
        createdOn: obj.planConfigManagement_Plan_createdOn,
        modifiedOn: obj.planConfigManagement_Plan_modifiedOn,
        status: capitalizeFirstLetter(
          obj.planConfigManagement_Plan_PlanStatus?.replaceAll('_', ' '),
        ),
      };
    },
  );

  return {
    pagedItems: list,
    pageSize: data.PlanConfigManagement.queries.getPlanList?.paging?.pageSize,
    pageNumber: data.PlanConfigManagement.queries.getPlanList?.paging?.pageNumber,
    totalCount: data.PlanConfigManagement.queries.getPlanList?.paging?.totalCount,
  };
}

export function dataToLovs(data: any): Record<string, Record<string, string>> {
  let lovs: Record<string, Record<string, string>> = {
    line: {},
    subline: {},
    status: {},
  };

  data?.PlanConfigManagement?.queries?.allLines?.forEach(
    (obj: { planConfigManagement_Line_Id: string; planConfigManagement_Line_Name: string }) => {
      lovs.line[obj.planConfigManagement_Line_Id] = obj.planConfigManagement_Line_Name;
    },
  );

  data?.PlanConfigManagement?.queries?.allSublines?.forEach(
    (obj: { planConfigManagement_Subline_Id: string; planConfigManagement_Subline_Name: string }) => {
      lovs.subline[obj.planConfigManagement_Subline_Id] = obj.planConfigManagement_Subline_Name;
    },
  );

  lovs.status = enumListAsRecordObject(
    data?.PlanConfigManagement_PlanStatuses?.enumValues,
  );

  return lovs;
}
