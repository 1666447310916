import { DynamicFormInputType, FormInputTypes } from '../../DynamicForm';
import { isEmpty } from '../../utils/validationUtils';

export const initialValues = {
  businessPartnerDetails: {
    creditLimitCurrency: '',
    creditLimit: 0,
    gracePeriod: 0,
  },
};

export const inputs: Record<string, DynamicFormInputType> = {
  creditLimitCurrency: {
    name: 'creditLimitCurrency',
    title: 'Credit Limit Currency',
    type: FormInputTypes.chips,
    placeholder: 'Credit Limit Currency',
    value: !isEmpty(initialValues.businessPartnerDetails.creditLimitCurrency)
      ? initialValues.businessPartnerDetails.creditLimitCurrency
      : '',
    multiple: false,
    disabled: false,
    required: true,
  },
  creditLimit: {
    name: 'creditLimit',
    title: 'Credit Limit',
    type: FormInputTypes.formattedNumber,
    placeholder: 'Credit Limit',
    value: initialValues.businessPartnerDetails.creditLimit,
    required: true,
    disabled: false,
    minValue: 0,
  },
  gracePeriod: {
    name: 'gracePeriod',
    title: 'Grace Period (in days)',
    type: FormInputTypes.number,
    placeholder: 'Grace Period (in days)',
    value: initialValues.businessPartnerDetails.gracePeriod,
    required: true,
    disabled: false,
  },
};
