import { IAbstractRecord } from '../../models';
import { IListingData } from '../../models/listing';
import { enumListAsRecordObject } from '../../utils/graph-utils';
import { capitalizeFirstLetter } from '../../utils/formatting-utils';

export function mapToListingData(data: any): IListingData<any> {
  const list: IAbstractRecord = {};

  data.Insurance.queries.listAgents.items.forEach(
    (obj: IAgentGenericTableData, i: number) => {
      var agencyName = '';
      if(obj.insurance_Agent_AgenciesNames != null && obj.insurance_Agent_AgenciesNames.length > 0){
        agencyName = obj.insurance_Agent_AgenciesNames.join(", ");
      }
      list[i] = {
        insurance_Agent_Id: obj.insurance_Agent_Id,
        insurance_Agent_FirstName: obj.insurance_Agent_FirstName,
        insurance_Agent_LastName: obj.insurance_Agent_LastName,
        insurance_Agent_AgenciesNames: agencyName,
        insurance_Agent_AgentType: capitalizeFirstLetter(
          obj.insurance_Agent_AgentType
        ),
        insurance_Agent_AgentStatus: capitalizeFirstLetter(
          obj.insurance_Agent_AgentStatus
        ),
        insurance_Agent_createdOn: obj.insurance_Agent_createdOn,
        insurance_Agent_modifiedOn: obj.insurance_Agent_modifiedOn,
      };
    }
  );

  return {
    pagedItems: list,
    pageSize: data.Insurance.queries.listAgents?.paging?.pageSize,
    pageNumber: data.Insurance.queries.listAgents?.paging?.pageNumber,
    totalCount: data.Insurance.queries.listAgents?.paging?.totalCount,
  };
}

export function dataToLovs(data: any): Record<string, Record<string, string>> {
  let lovs: Record<string, Record<string, string>> = {
    agency: {},
    status: {},
    type: {},
    access: {},
  };

  data?.Insurance?.queries?.allAgencies?.forEach(
    (obj: {
      insurance_Agency_Id: string;
      insurance_Agency_AgencyName: string;
    }) => {
      lovs.agency[obj.insurance_Agency_Id] = obj.insurance_Agency_AgencyName;
    }
  );

  lovs.status = enumListAsRecordObject(
    data?.Insurance_AgentStatuses?.enumValues
  );
  lovs.type = enumListAsRecordObject(data?.Insurance_AgentTypes?.enumValues);
  lovs.access = enumListAsRecordObject(
    data?.Insurance_AgentAccesses?.enumValues
  );

  return lovs;
}
