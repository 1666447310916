import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
} from '../../components/enhanced-table';
import { IEntityInfoView } from '../../components/widgets/entity-info/EntityInfoFields';

export const steps = ['Inactive', 'Active'];

export const entityView: IEntityInfoView = {
  name: 'section1',
  title: 'Person Details',
  iconUrl: '',
  sections: [
    {
      name: 'personDetailsSection1',
      title: '',
      properties: {
        code: {
          value: '',
          multiline: false,
          name: 'code',
          title: 'Code',
        },
        name: {
          value: '',
          multiline: false,
          name: 'name',
          title: 'Name',
        },
        type: {
          value: '',
          multiline: false,
          name: 'type',
          title: 'Type',
        },
        email: {
          value: '',
          multiline: false,
          name: 'email',
          title: 'Email',
        },
        phoneNumber: {
          value: '',
          multiline: false,
          name: 'phoneNumber',
          title: 'Phone Number',
        },
      },
    },
    {
      name: 'policyCoverDetailsSection2',
      title: '',
      properties: {
        createdOn: {
          value: '',
          multiline: false,
          name: 'createdOn',
          title: 'Created On',
        },
        createdBy: {
          value: '',
          multiline: false,
          name: 'createdBy',
          title: 'Created By',
        },
        modifiedOn: {
          value: '',
          multiline: false,
          name: 'modifiedOn',
          title: 'Modified On',
        },
        modifiedBy: {
          value: '',
          multiline: false,
          name: 'modifiedBy',
          title: 'Modified By',
        },
      },
    },
  ],
  socialMedia: {
    facebook: '',
    linkedIn: '',
    twitter: '',
  },
};

export const headers: Record<string, EnhancedTableHeader> = {
  salesforceManagement_PersonAddress_IsPrimary: {
    name: 'salesforceManagement_PersonAddress_IsPrimary',
    title: 'Primary',
    type: EnhancedTableHeaderType.Text,
  },
  salesforceManagement_PersonAddress_AddressType: {
    name: 'salesforceManagement_PersonAddress_AddressType',
    title: 'Address Type',
    type: EnhancedTableHeaderType.Text,
  },
  salesforceManagement_PersonAddress_Country: {
    name: 'salesforceManagement_PersonAddress_Country',
    title: 'Country',
    type: EnhancedTableHeaderType.Text,
  },
  salesforceManagement_PersonAddress_Zone: {
    name: 'salesforceManagement_PersonAddress_Zone',
    title: 'Zone',
    type: EnhancedTableHeaderType.Text,
  },
  salesforceManagement_PersonAddress_City: {
    name: 'salesforceManagement_PersonAddress_City',
    title: 'City',
    type: EnhancedTableHeaderType.Text,
  },
  salesforceManagement_PersonAddress_Street: {
    name: 'salesforceManagement_PersonAddress_Street',
    title: 'Street',
    type: EnhancedTableHeaderType.Text,
  },
  salesforceManagement_PersonAddress_Building: {
    name: 'salesforceManagement_PersonAddress_Building',
    title: 'Building',
    type: EnhancedTableHeaderType.Text,
  },
  salesforceManagement_PersonAddress_AddressDetails: {
    name: 'salesforceManagement_PersonAddress_AddressDetails',
    title: 'Address Details',
    type: EnhancedTableHeaderType.Text,
  },
  salesforceManagement_PersonAddress_AddressDetailsArabic: {
    name: 'salesforceManagement_PersonAddress_AddressDetailsArabic',
    title: 'Address Details Arabic',
    type: EnhancedTableHeaderType.Text,
  },
};
