import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ISublineDetails, ISublineDetailsPageProps, initialValues } from ".";
import Loader from "../../components/Loader";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { IEntityInfoView } from "../../components/widgets/entity-info/EntityInfoFields";
import EntityInfoWidget from "../../components/widgets/entity-info/EntityInfoWidget";
import {
  DEFAULT_ERROR_TEXT,
  MAIN_ONE_THEME,
  contentFontFamilyBold,
} from "../../constants";
import { entityView, planHeaders, steps } from "./content";
import {
  activateSubline,
  deactivateSubline,
  getSublineDetailsInfo,
  getSublineDetailsPagePlanList,
} from "./queries";
import {
  entityToSublineDetails,
  getSublineStatus,
  mapToPlansListingData,
} from "./utils";
import { capitalizeFirstLetter } from "../../utils/formatting-utils";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import dayjs from "dayjs";
import EnhancedTable from "../../components/enhanced-table/EnhancedTable";
import { IListingData } from "../../models/listing";
import WidgetSection from "../../components/common/WidgetSection";
import EnhancedStepperSpecificDesign from "../../components/common/EnhancedStepperSpecificDesign";
import { makeStyles } from "tss-react/mui";
import { IEnhanceTableHeaderClickable, IEnhancedTableMenuItem } from "../../components/enhanced-table";
import { IDynamicDiplayView } from "../../components/widgets/dynamic-display";
import PlanDrawer from "../plan-drawer/PlanDrawer";
import StaticLayout from "../../page-layout/static-layout/StaticLayout";
import SublineDrawer from "../subline-drawer/SublineDrawer";
import { EnhancedDisplayType } from "../../components/enhanced-form/EnhancedDisplay";
import { EnhancedButtonStatus } from "../../components/EnhancedButton";
import { IConfirmation } from "../../redux/confirmation/types";
import { isEmpty } from "lodash";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import { getError } from "../../utils/graph-utils";
import { OpenConfirmationAction } from "../../redux/confirmation/actions";

const useStyles = makeStyles()(() => ({
  stepper: {
    backgroundColor: "transparent",
    maxWidth: "90%",
    marginBottom: "20px",
    "& .MuiStepLabel-root .Mui-active, .MuiStepLabel-root ": {
      color: MAIN_ONE_THEME.palette.primary5.main,
      "& .MuiStepIcon-text": {
        fontSize: 0,
        lineHeight: "0px",
        fill: "black",
      },
    },

    ".Mui-completed": {
      color: MAIN_ONE_THEME.palette.primary5.main,
      "& .MuiStepIcon-text": {
        fontSize: 0,
        lineHeight: "0px",
        fill: "black",
      },
    },

    "@media only screen and (min-width: 600px)": {
      maxWidth: "1300px",
    },
  },
  section: {
    display: "grid",
    "grid-template-columns": "19.5% 19.5% 19.5% 19.5% 19.5%",
    justifyContent: "space-between",
    flexWrap: "wrap",

    "& label": {
      "& span": {
        margin: "-2px 0",
      },
    },
  },
  sectionTitle: {
    padding: "-10x 10x!important",
  },
  sectionFullRow: {
    display: "grid",
    "grid-template-columns": "100%",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  row: {
    flexWrap: "wrap",
    margin: "15px 20px 10px 0px",
    alignItems: "flex-start",
    display: "grid",
    gap: "5px",
    "& label": {
      flexGrow: "1",
      flexBasis: "0",
      minWidth: "0",
    },
    "grid-template-columns": "19.5% 19.5% 19.5% 19% 16% 3% 3%",
  },
  thickSeperator: {
    height: 10,
    margin: "10px 0 10px 0",
    "grid-column-start": "1",
    "grid-column-end": "4",
  },
  field: {
    width: "96%",
    marginRight: "10px !important",
  },
  repeaterField: {
    margin: "0px!important",
  },
  arField: {
    width: "96%",
    marginRight: "10px !important",
    direction: "rtl",
  },
  header: {
    fontFamily: contentFontFamilyBold,
    fontSize: MAIN_ONE_THEME.typography.regular.reg3.fontSize,
    color: MAIN_ONE_THEME.palette.primary2.main,
    "&:hover": {
      color: MAIN_ONE_THEME.palette.primary1.main,
    },
  },
  content: {
    padding: "0",
  },
  dirtyChip: {
    margin: "0 0 -15px 0",
    backgroundColor: MAIN_ONE_THEME.palette.primary2.main,
    "& span": {
      color: "#ffffff",
    },
  },
  tabPanelStyle: {
    padding: "unset",
    marginTop: "20px",
  },
}));

const SublineDetailsPage: React.FC<ISublineDetailsPageProps> = () => {
  //#region react and redux hooks
  const { classes } = useStyles();
  const params = useParams();
  const tenant = useAppSelector((state) => state.tenant);
  const user = useAppSelector((state) => state.user);
  const [activateButtonState, setActivateButtonState] =
    useState<EnhancedButtonStatus>();
  const [deactivateButtonState, setDeactivateButtonState] =
    useState<EnhancedButtonStatus>();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [sublineDrawerOpen, setSublineDrawerOpen] = useState<boolean>(false);
  const [planDrawerOpen, setPlanDrawerOpen] = useState<boolean>(false);

  const [selectedPlanItems, setSelectedPlanItems] = useState<number>(0);
  //#endregion

  //#region component hooks and states
  const [sublineDetailsInfo, setSublineDetailsInfo] =
    useState<ISublineDetails>(initialValues);

  const [updatedLeftSideInfo, setUpdatedLeftSideInfo] =
    useState<IEntityInfoView>(entityView);

  const [loadingState, setLoadingState] = useState<boolean>(true);

  const [selectedPlanEntityId, setSelectedPlanEntityId] = useState("");

  const [actionClicked, setActionClicked] = useState(null);

  const entityId = params.id;

  const [planTableLoadingState, setPlanTableLoadingState] =
    useState<boolean>(false);
  //#endregion

  (planHeaders.planName as IEnhanceTableHeaderClickable).callback = (planPayload: any) => {
    navigate("/plan/plans/" + planPayload.columns.planConfigManagement_Plan_Id);
  };
  
  const isAdmin = user.userRoles.includes("Insurance-Admin");

  let planListingActions: IEnhancedTableMenuItem[] = [];
  planListingActions = [
    {
      title: "New",
      onClick: () => {},
      isEntity: false,
      isBulk: false,
      iconUrl: tenant.cdnUrl + "/icons/add-primary-red.svg",
      hidden: !isAdmin,
      disabled: false,
    },
    {
      title: "Edit",
      onClick: () => {},
      isEntity: false,
      isBulk: false,
      iconUrl: tenant.cdnUrl + "/icons/edit-secondary.svg",
      hidden: !isAdmin,
      disabled: selectedPlanItems !== 1,
    },
  ];

  //#region graphql hooks
  const client = useApolloClient();

  const entityResult = useQuery(getSublineDetailsInfo(), {
    variables: { id: entityId },
    errorPolicy: "all",
    fetchPolicy: "no-cache",
  });

  const [activateAction] = useMutation(activateSubline(), {
    variables: {
      entityId: entityId,
    },
    errorPolicy: "all",
    refetchQueries: [getSublineDetailsInfo()],
  });
  const [deactivateAction] = useMutation(deactivateSubline(), {
    variables: {
      entityId: entityId,
    },
    refetchQueries: [getSublineDetailsInfo(), getSublineDetailsPagePlanList()],
  });

  planListingActions[0].onClick = () => {
    setActionClicked("new");
    setPlanDrawerOpen(true);
  };
  planListingActions[1].onClick = () => {
    setActionClicked("edit");
    setPlanDrawerOpen(true);
  };

  const [planTableData, setPlanTableData] = useState<IListingData<any>>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 0,
    totalCount: 0,
  });

  const planListResult = useQuery(getSublineDetailsPagePlanList(), {
    variables: {
      currentPage: 1,
      currentPageSize: planTableData.pageSize,
      SelectedSublineID: params.id,
    },
    errorPolicy: "all",
  });

  const [plansViewConfig, setPlansViewConfig] = useState<IDynamicDiplayView>();
  //#endregion

  //#region useEffect
  useEffect(() => {
    try {
      if (!entityResult.loading) {
        setLoadingState(false);
      }

      if (entityResult?.data) {
        const sublineDetails = entityToSublineDetails(entityResult?.data);

        //get application section properties
        const applicationProperties = entityView.sections[0];
        applicationProperties.properties.externalCode.value =
          sublineDetails.externalCode;
        applicationProperties.properties.lineName = {
          title: "Line",
          name: "Line",
          multiline: false,
          value: sublineDetails.lineName,
          type: EnhancedDisplayType.RelativeLink,
          tooltip: false,
          url:
            "/plan/lines/" +
            entityResult?.data?.PlanConfigManagement?.entities?.subline?.views
              ?.PlanConfigManagement_all?.properties?.LineID?.id,
        };
        applicationProperties.properties.arabicName.value =
          sublineDetails.arabicName;
        // applicationProperties.properties.status.value = capitalizeFirstLetter(
        //   sublineDetails.status
        // );

        //get the system propeties section
        const systemProperties = entityView.sections[1];
        systemProperties.properties.createdBy.value = sublineDetails.createdBy;
        systemProperties.properties.createdOn.value = dayjs(
          new Date(sublineDetails.createdOn)
        ).format("DD/MM/YYYY");
        systemProperties.properties.modifiedBy.value =
          sublineDetails.modifiedBy;
        systemProperties.properties.modifiedOn.value = dayjs(
          new Date(sublineDetails.modifiedOn)
        ).format("DD/MM/YYYY");

        const newLeftEntityView = {
          ...entityView,
          sections: [applicationProperties, systemProperties],
        };

        setSublineDetailsInfo(sublineDetails);

        setUpdatedLeftSideInfo(newLeftEntityView);

        const planDynamicView: IDynamicDiplayView = {
          name: "plans",
          sections: [],
        };
        setPlansViewConfig(planDynamicView);
      }
    } catch (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  }, [entityResult?.data]);

  useEffect(() => {
    const isLoading = planListResult?.loading;
    setLoadingState(isLoading);

    if (planListResult?.data) {
      const planTableData = mapToPlansListingData(planListResult.data);
      setPlanTableData({ ...planTableData, pageNumber: 0 });
    }
  
    if (!isLoading) {
      setLoadingState(false);
    }
  }, [planListResult?.loading, planListResult?.error, planListResult?.data]);
  //#endregion

  //#region functions
  const handleSublineSectionChange = async () => {
    try {
      client.refetchQueries({
        include: [getSublineDetailsInfo(), getSublineDetailsPagePlanList()],
      });
    } catch (error) {
      toast.error(<ToastErrorMessage>{error}</ToastErrorMessage>);
    }
  };

  function handlePlanPageChange(page: number) {
    setPlanTableLoadingState(true);
    planListResult
      ?.fetchMore({
        variables: {
          currentPage: ++page,
          currentPageSize: planTableData.pageSize,
        },
      })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const newApplicationList = mapToPlansListingData(
            fetchMoreResult.data
          );
          const updatedTableData = {
            ...newApplicationList,
            pageNumber: --page,
          };
          setPlanTableData(updatedTableData);
        }
      })
      .finally(() => {
        setPlanTableLoadingState(false);
      });
  }

  function handleRowsPerPlanPageChange(numberOfRecordsPerPage: number) {
    setPlanTableLoadingState(true);
    setPlanTableData({ ...planTableData, pageNumber: 1 });
    planListResult
      ?.fetchMore({
        variables: {
          currentPage: 1,
          currentPageSize: numberOfRecordsPerPage,
        },
      })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const newApplicationList = mapToPlansListingData(
            fetchMoreResult.data
          );

          const updatedTableData = {
            ...newApplicationList,
            pageNumber: 0,
            pageSize: numberOfRecordsPerPage,
          };
          setPlanTableData(updatedTableData);
        }
      })
      .finally(() => {
        setPlanTableLoadingState(false);
      });
  }

  const renderMainChildren = () => {
    const status =
      entityResult?.data?.PlanConfigManagement?.entities?.subline?.views?.PlanConfigManagement_all?.properties?.Status.toLowerCase();
    const lineStatus =
      entityResult?.data?.PlanConfigManagement?.entities?.subline?.views?.PlanConfigManagement_all
        ?.properties?.LineID?.views?.PlanConfigManagement_all?.properties?.Status;
    const isSublineActive = status === "active";

    return (
      <>
        <div style={{ marginTop: "20px" }}>
          <EnhancedStepperSpecificDesign
            activeStep={getSublineStatus(status)}
            steps={steps}
            className={classes.stepper}
            showStepperButton
            buttonTitle={isSublineActive ? "Deactivate" : "Activate"}
            buttonState={
              isSublineActive ? activateButtonState : deactivateButtonState
            }
            buttonOnClick={() => {
              if (isSublineActive) {
                const confirmation: IConfirmation = {
                  open: true,
                  title: "Deactivate",
                  message: `Are you sure you want to deactivate this subline?`,
                  callback: async () => {
                    setDeactivateButtonState("loading");
                    deactivateAction()
                      .then((result) => {
                        if (isEmpty(result.errors)) {
                          setDeactivateButtonState("success");
                          toast.success(
                            <ToastSuccessMessage>
                              {
                                "Subline successfully deactivated. Relevant plans are deactivated as well."
                              }
                            </ToastSuccessMessage>
                          );
                        } else {
                          setDeactivateButtonState(undefined);
                          toast.error(
                            <ToastErrorMessage>
                              {getError(result)}
                            </ToastErrorMessage>
                          );
                        }
                      })
                      .finally(() => {
                        setTimeout(() => {
                          setDeactivateButtonState(undefined);
                        }, 1000);
                      });
                  },
                  submitButtonText: "Yes",
                  cancelButtonText: "No",
                };
                dispatch(OpenConfirmationAction(confirmation));
              } else {
                const confirmation: IConfirmation = {
                  open: true,
                  title: "Activate",
                  message: `Are you sure you want to activate this subline?`,
                  callback: async () => {
                    setActivateButtonState("loading");
                    activateAction()
                      .then((result) => {
                        if (isEmpty(result.errors)) {
                          setActivateButtonState("success");
                          toast.success(
                            <ToastSuccessMessage>
                              {"Subline successfully activated."}
                            </ToastSuccessMessage>
                          );
                        } else {
                          setActivateButtonState(undefined);
                          toast.error(
                            <ToastErrorMessage>
                              {getError(result)}
                            </ToastErrorMessage>
                          );
                        }
                      })
                      .finally(() => {
                        setTimeout(() => {
                          setActivateButtonState(undefined);
                        }, 1000);
                      });
                  },
                  submitButtonText: "Yes",
                  cancelButtonText: "No",
                };
                dispatch(OpenConfirmationAction(confirmation));
              }
            }}
          />
          <WidgetSection style={{ margin: "-23px 0 0 0" }}>
            {plansViewConfig && (
              <form>
                {planListResult?.loading ? (
                  <div></div>
                ) : (
                  <div
                    style={{
                      marginTop: "20px",
                      padding: "-23px 0 0 0 !important",
                    }}
                  >
                    <EnhancedTable
                      title={capitalizeFirstLetter("Plans")}
                      inlineTitle={capitalizeFirstLetter("Plans")}
                      name="count"
                      entityName={"Plan"}
                      entityIdColumnName={"planConfigManagement_Plan_Id"}
                      showCellFullData={true}
                      isTitlePascalCase={true}
                      orderByAscendingByDefault
                      defaultOrderByColumn="name"
                      inline={true}
                      data={planTableData}
                      headers={planHeaders}
                      handlePageChange={(page: number) =>
                        handlePlanPageChange(page)
                      }
                      handleRowsPerPageChange={(page: number) =>
                        handleRowsPerPlanPageChange(page)
                      }
                      currentPage={planTableData.pageNumber}
                      hideToolbar={false}
                      usePagination
                      disableSelection={false}
                      actions={planListingActions}
                      loader={planTableLoadingState}
                      showTablePagination={true}
                      onSelectionChange={(newDetailedSelection) => {
                        setSelectedPlanItems(newDetailedSelection.length);
                        if (newDetailedSelection.length > 0) {
                          setSelectedPlanEntityId(newDetailedSelection[0].id);
                        } else {
                          setSelectedPlanEntityId("");
                        }
                      }}
                    />
                    {planDrawerOpen && (
                      <PlanDrawer
                        open={planDrawerOpen}
                        lineId={
                          entityResult?.data?.PlanConfigManagement?.entities?.subline
                            ?.views?.PlanConfigManagement_all?.properties?.LineID?.id
                        }
                        lineName={
                          entityResult?.data?.PlanConfigManagement?.entities?.subline
                            ?.views?.PlanConfigManagement_all?.properties?.LineID?.views
                            ?.PlanConfigManagement_all?.properties?.Name
                        }
                        lineStatus={lineStatus.toLowerCase()}
                        planId={
                          actionClicked === "new" ? null : selectedPlanEntityId
                        }
                        sublineID={entityId}
                        isSublineNameDisabled={true}
                        onClose={() => setPlanDrawerOpen(false)}
                        onSuccess={() => {
                          handlePlanPageChange(0);
                        }}
                      />
                    )}
                    {sublineDrawerOpen && (
                      <SublineDrawer
                        open={sublineDrawerOpen}
                        lineId={
                          entityResult?.data?.PlanConfigManagement?.entities?.subline
                            ?.views?.PlanConfigManagement_all?.properties?.LineID?.id
                        }
                        lineName={
                          entityResult?.data?.PlanConfigManagement?.entities?.subline
                            ?.views?.PlanConfigManagement_all?.properties?.LineID?.views
                            ?.PlanConfigManagement_all?.properties?.Name
                        }
                        lineStatus={lineStatus.toLowerCase()}
                        sublineId={entityId}
                        onClose={() => setSublineDrawerOpen(false)}
                        onSuccess={() => {
                          handleSublineSectionChange();
                        }}
                      />
                    )}
                  </div>
                )}
              </form>
            )}
          </WidgetSection>
        </div>
      </>
    );
  };

  const handleIconClick = () => {
    setSublineDrawerOpen(true);
  };
  const renderLeftSection = () => {
    return (
      <>
        <EntityInfoWidget
          background="rgb(193, 0, 0)"
          classification={{ classifications: {}, value: "" }}
          progress={100}
          supportsClassification={true}
          supportsProgressBar={true}
          title={sublineDetailsInfo.sublineName}
          view={updatedLeftSideInfo}
          iconUrl={tenant.cdnUrl + "/icons/edit-secondary.svg"}
          onClickIcon={handleIconClick}
        ></EntityInfoWidget>
      </>
    );
  };

  //#endregion

  return loadingState ? (
    <Loader />
  ) : (
    <StaticLayout
      name={"Subline Details"}
      leftChildren={renderLeftSection()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default SublineDetailsPage;
