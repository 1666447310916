import { enumListAsRecordObject } from "../../utils/graph-utils";
import { DynamicFormInputType, FormInputTypes } from "../../DynamicForm";
import { HEADQUARTER } from "./content";
import { cloneDeep } from "lodash";

export function lookupToList(data: any) {
  const agentLookups: Record<string, Record<string, string>> = {};
  let agencies: Record<string, string> = {};

  agentLookups[data.Insurance_AgentTypes.name] = enumListAsRecordObject(
    data.Insurance_AgentTypes.enumValues
  );
  agentLookups[data.Insurance_AgentStatuses.name] = enumListAsRecordObject(
    data.Insurance_AgentStatuses.enumValues
  );
  agentLookups[data.Insurance_AgentAccesses.name] = enumListAsRecordObject(
    data.Insurance_AgentAccesses.enumValues
  );

  data.Insurance.queries.allAgencies.forEach(
    (element: {
      insurance_Agency_Id: string;
      insurance_Agency_AgencyName: string;
    }) => {
      agencies[element.insurance_Agency_Id] =
        element.insurance_Agency_AgencyName;
    }
  );

  agentLookups["agencies"] = agencies;

  return agentLookups;
}

export function toAgenciesTypes(data: any): Record<string, string> {
  let agencies: Record<string, string> = {};

  data.forEach(
    (element: {
      insurance_Agency_Id: string;
      insurance_Agency_AgencyName: string;
      insurance_Agency_AgencyType: { Id: string };
    }) => {
      agencies[element.insurance_Agency_Id] =
        element.insurance_Agency_AgencyType.Id;
    }
  );

  return agencies;
}

export function graphqlEntityToAgentInfo(data: any): IAgentInfo {
  return {
    agentId: "",
    name: data?.Insurance?.entities?.agent?.views?.Insurance_all?.properties?.AgencyID?.map(
      (res: {
        views: {
          Insurance_all: { properties: { Id: string } };
        };
      }) => res?.views?.Insurance_all?.properties?.Id
    ),
    firstName:
      data?.Insurance?.entities?.agent?.views?.Insurance_all?.properties
        ?.FirstName,
    lastName:
      data?.Insurance?.entities?.agent?.views?.Insurance_all?.properties
        ?.LastName,
    email:
      data?.Insurance?.entities?.agent?.views?.Insurance_all?.properties?.Email,
    mobileNumber:
      data?.Insurance?.entities?.agent?.views?.Insurance_all?.properties
        ?.MobileNumber,
    type: data?.Insurance?.entities?.agent?.views?.Insurance_all?.properties
      ?.AgentType,
    access:
      data?.Insurance?.entities?.agent?.views?.Insurance_all?.properties
        ?.AgentAccess,
    status:
      data?.Insurance?.entities?.agent?.views?.Insurance_all?.properties
        ?.AgentStatus,
    linesOfBusiness:
      data?.Insurance?.entities?.agent?.views?.Insurance_all?.properties?.LinesOfBusiness.map(
        (res: { Code: string }) => res.Code
      ),
    products:
      data?.Insurance?.entities?.agent?.views?.Insurance_all?.properties?.Products.map(
        (res: { Code: string }) => res.Code
      ),
    eligiblePlans:
      data?.Insurance?.entities?.agent?.views?.Insurance_all?.properties?.EligiblePlans.map(
        (res: { Code: string }) => res.Code
      ),
    agencyType:
      data?.Insurance?.entities?.agent?.views?.Insurance_all?.properties?.AgencyID?.map(
        (res: {
          views: {
            Insurance_all: { properties: { AgencyType: { Id: string } } };
          };
        }) => res?.views?.Insurance_all?.properties?.AgencyType?.Id
      ),
    agentICCNumber:
      data?.Insurance?.entities?.agent?.views?.Insurance_all?.properties
        ?.AgentICCNumber,
    agentRegistrationNumber:
      data?.Insurance?.entities?.agent?.views?.Insurance_all?.properties
        ?.AgentRegistrationNumber,
    agentRegistrationExpiryDate:
      data?.Insurance?.entities?.agent?.views?.Insurance_all?.properties
        ?.AgentRegistrationExpiryDate,
    address:
      data?.Insurance?.entities?.agent?.views?.Insurance_all?.properties
        ?.AgentAddress,
    ufaCode:
      data?.Insurance?.entities?.agent?.views?.Insurance_all?.properties
        ?.UFACode,
    canViewCommission:
      data?.Insurance?.entities?.agent?.views?.Insurance_all?.properties
        ?.CanViewCommission,
  };
}

export function updateInputFormFieldsByAgency(
  formInputs: Record<string, DynamicFormInputType>,
  agencyIds: string[],
  agenciesType?: Record<string, string>
): Record<string, DynamicFormInputType> {
  const newFormInputs: Record<string, DynamicFormInputType> =
    cloneDeep(formInputs);

  let allSameType = true;
  let firstType: string | undefined;
  for (const id of agencyIds) {
    const type = agenciesType && agenciesType[id];
    if (type) {
      if (!firstType) {
        firstType = type;
      } else if (type !== firstType) {
        allSameType = false;
        break;
      }
    }
  }

  if (allSameType && firstType != HEADQUARTER) {
    newFormInputs["agentICCNumber"] = {
      name: "agentICCNumber",
      title: "ICC Number",
      placeholder: "ICC Number",
      type: FormInputTypes.text,
      value: "",
      required: false,
      order: 15,
    };

    newFormInputs["agentRegistrationNumber"] = {
      name: "agentRegistrationNumber",
      title: "Registration Number",
      placeholder: "Registration Number",
      type: FormInputTypes.text,
      value: "",
      required: false,
      order: 16,
    };

    newFormInputs["agentRegistrationExpiryDate"] = {
      name: "agentRegistrationExpiryDate",
      title: "Registration Expiry Date",
      placeholder: "Registration Expiry Date",
      type: FormInputTypes.date,
      value: null,
      required: false,
      order: 17,
    };
  } else {
    delete newFormInputs["agentICCNumber"];
    delete newFormInputs["agentRegistrationNumber"];
    delete newFormInputs["agentRegistrationExpiryDate"];
  }

  return newFormInputs;
}
