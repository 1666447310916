import { ISalesForceLineCreditLimitDrawerInfo } from ".";
import { lookupListAsRecordObject } from "../../utils/graph-utils";

export function toLookups(data: any) {
  const businessPartnerDetailsList: Record<string, Record<string, string>> = {};

  businessPartnerDetailsList["creditLimitCurrency"] = lookupListAsRecordObject(
    data.Core.lookups.currencies
  );

  return businessPartnerDetailsList;
}

export function graphqlToDefaultLineCreditLimitInfo(
  data: any
): ISalesForceLineCreditLimitDrawerInfo {
  const businessPartnerLine =
    data?.SalesforceManagement?.entities?.businessPartnerLine?.views
      ?.SalesforceManagement_all?.properties;
  return {
    creditLimit: businessPartnerLine?.CreditLimit,
    creditLimitCurrency: businessPartnerLine?.CreditLimitCurrency?.Id,
    gracePeriod: businessPartnerLine?.GracePeriod,
  };
}
