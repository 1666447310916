import React, { useEffect, useState } from "react";
// import { makeStyles } from "tss-react/mui";
import GenericDrawer from "../../components/common/generic-drawer/GenericDrawer";
import DynamicForm from "../../DynamicForm/DynamicForm";
import { inputs } from "./content";
import { cloneDeep, isEmpty } from "lodash";
import {
  DynamicFormInputType,
  IFormDateDynamicProps,
  IFormSelectDynamicProps,
} from "../../DynamicForm";
import { toast } from "react-toastify";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import {
  DEFAULT_ERROR_TEXT,
  SEND_TO_BACKEND_DATE_FORMAT,
} from "../../constants";
import { useMutation, useQuery } from "@apollo/client";
import {
  setBusinessPartnerPlanCommission,
  getDefaultCommissionEnums,
} from "./queries";
import {
  LookupToList,
  graphqlToSalesforceBusinessPartnerCommissionInfo,
} from "./utils";
import Loader from "../../components/Loader";
// import EnhancedNote from "../../components/enhanced-form/EnhancedNote";
import { normaliseDynamicValues } from "../../utils/dynamic-utils";
import { EnhancedButtonStatus } from "../../components/EnhancedButton";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import { getError } from "../../utils/graph-utils";
import { formatDateTime } from "../../utils/formatting-utils";
import dayjs from "dayjs";
import {
  ISalesforceBusinessPartnerCommissionDrawerProps,
  ISalesforceBusinessPartnerCommissionInfo,
} from ".";

// const useStyles = makeStyles()(() => ({
//   note: {
//     color: "red !important",
//     fontSize: "12px !important",
//     fontStyle: "italic",
//     margin: "10px 0 0 0",
//   },
// }));

const SalesforceBusinessPartnerCommissionDrawer: React.FC<
  ISalesforceBusinessPartnerCommissionDrawerProps
> = ({
  businessPartnerId,
  businessPartnerType,
  assignedLineId,
  selectedBusinessPlanIds,
  businessPartnerLinesIds,
  selectedDetailedSelection,
  open,
  onSuccess,
  onClose,
}) => {
  // const { classes } = useStyles();
  let defaultCommissionInfoResult: any;

  let newDefualtCommissionEnums: Record<string, Record<string, string>> = {};
  let defaultCommissionEntity: ISalesforceBusinessPartnerCommissionInfo = {
    assignedLineId: "",
    commissionType: "",
    lineCommissionValue: "",
    lineCommissionValueCurrency: "",
    lineCommissionRate: "",
    lineCommissionApplicableOn: "",
    activeFrom: "",
    activeTo: "",
  };

  const initializeUpdatedInputs = () => {
    const isMultipleSelection = selectedBusinessPlanIds.length > 1;
    const isSingleSelection = selectedBusinessPlanIds.length === 1;

    if (isMultipleSelection) {
      updatedInputs.commissionType.value = "RATE";
      updatedInputs.lineCommissionValue.value = 0;
      updatedInputs.lineCommissionValueCurrency.value = "39735";
      updatedInputs.lineCommissionRate.value = 0;
      updatedInputs.lineCommissionApplicableOn.value = "";
      updatedInputs.activeFrom.value = "";
      updatedInputs.activeTo.value = "";
    } else if (isSingleSelection) {
      const type =
        selectedDetailedSelection[0]?.businessPartnerCommissionType !== null
          ? selectedDetailedSelection[0]?.businessPartnerCommissionType
          : selectedDetailedSelection[0]
              ?.salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionApplicable ===
            true
          ? selectedDetailedSelection[0]
              ?.salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionType.toUpperCase()
          : selectedDetailedSelection[0]
              ?.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_LineCommissionType !==
            null
          ? selectedDetailedSelection[0]
              ?.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_LineCommissionType
          : "RATE";
      const rate =
        selectedDetailedSelection[0]?.businessPartnerCommissionRate !==
        (0 || undefined || null)
          ? selectedDetailedSelection[0]?.businessPartnerCommissionRate * 100
          : selectedDetailedSelection[0]
              ?.salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionApplicable ===
            true
          ? selectedDetailedSelection[0]
              ?.salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionRate *
            100
          : selectedDetailedSelection[0]
              ?.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_LineCommissionRate !==
            (0 || undefined || null)
          ? selectedDetailedSelection[0]
              ?.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_LineCommissionRate *
            100
          : 0;
      const value =
        selectedDetailedSelection[0]?.businessPartnerCommissionValue !==
        (0 || undefined || null)
          ? selectedDetailedSelection[0]?.businessPartnerCommissionValue
          : selectedDetailedSelection[0]
              ?.salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionApplicable ===
            true
          ? selectedDetailedSelection[0]
              ?.salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionValue
          : selectedDetailedSelection[0]
              ?.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_LineCommissionValue !==
            (0 || undefined || null)
          ? selectedDetailedSelection[0]
              ?.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_LineCommissionValue
          : 0;

      const valueCurrency =
        selectedDetailedSelection[0]?.businessPartnerCommissionValueCurrency !==
        (null || undefined)
          ? selectedDetailedSelection[0]?.businessPartnerCommissionValueCurrency
          : selectedDetailedSelection[0]
              ?.salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionApplicable ===
            true
          ? selectedDetailedSelection[0]
              ?.salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionValueCurrency
          : selectedDetailedSelection[0]
              ?.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_ValueCurrency !==
            null
          ? selectedDetailedSelection[0]
              ?.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_ValueCurrency
          : "39735";
      const applicableOn =
        selectedDetailedSelection[0]?.businessPartnerCommissionApplicableOn !==
        (null || undefined)
          ? selectedDetailedSelection[0]?.businessPartnerCommissionApplicableOn
          : selectedDetailedSelection[0]
              ?.salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionApplicable ===
            true
          ? selectedDetailedSelection[0]
              ?.salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionApplicableOn
          : selectedDetailedSelection[0]
              ?.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_ApplicableOn !==
            null
          ? selectedDetailedSelection[0]
              ?.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_ApplicableOn
          : "";
      const activeFrom =
        selectedDetailedSelection[0]?.businessPartnerActiveFrom !== null
          ? selectedDetailedSelection[0]?.businessPartnerActiveFrom
          : "";
      const activeTo =
        selectedDetailedSelection[0]?.businessPartnerActiveTo !== null
          ? selectedDetailedSelection[0]?.businessPartnerActiveTo
          : "";

      updatedInputs.commissionType.value = type;
      updatedInputs.lineCommissionValue.value = value;
      updatedInputs.lineCommissionValueCurrency.value = valueCurrency;
      updatedInputs.lineCommissionRate.value = Math.round(
        parseFloat((Number(rate)).toFixed(10))
      );
      updatedInputs.lineCommissionApplicableOn.value = applicableOn;
      updatedInputs.activeFrom.value = activeFrom;
      updatedInputs.activeTo.value = activeTo;

      updatedInputs.activeTo.disabled = false;
    }
  };

  let defaultCommissionEnumResults = useQuery(getDefaultCommissionEnums(), {
    fetchPolicy: "no-cache",
  });

  const [setBusinessPartnerPlanCommissionAction] = useMutation(
    setBusinessPartnerPlanCommission()
  );

  let [submitButtonDisabled, setSubmitButtonDisabled] =
    useState<boolean>(false);
  const [hasPlanActiveToError, setHasPlanActiveToError] = useState("");
  let [newActiveTo, setNewActiveTo] = useState<any>();
  let [newActiveFrom, setNewActiveFrom] = useState<any>();
  const relatedBusinessPartnerType = businessPartnerType;

  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();

  let updatedInputs: Record<string, DynamicFormInputType> = inputs;
  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);

  const initialize = () => {
    try {
      updatedInputs = cloneDeep(inputsForm);

      (updatedInputs.activeFrom as IFormDateDynamicProps).onChange = (
        event
      ) => {
        setNewActiveFrom(event);
        setInputsForm((currentInputsForm) => ({
          ...currentInputsForm,
          activeTo: {
            ...currentInputsForm.activeTo,
            minDate: event,
          },
        }));
        if (event !== null && newActiveFrom !== null) {
          updatedInputs.activeTo.disabled = false;
        }

        handleActiveDateChange(newActiveTo, newActiveFrom);
      };

      (updatedInputs.activeTo as IFormDateDynamicProps).onChange = (event) => {
        setNewActiveTo(event);

        (updatedInputs.activeTo as IFormDateDynamicProps).minDate =
          updatedInputs.activeFrom.value;

        handleActiveDateChange(newActiveTo, newActiveFrom);
      };

      const isBusinessPartnerTypeDurectSalesforce =
        relatedBusinessPartnerType === "Direct_Salesforce";

      if (defaultCommissionEnumResults.data) {
        newDefualtCommissionEnums = LookupToList(
          defaultCommissionEnumResults.data
        );

        if (selectedBusinessPlanIds.length > 1) {
          updatedInputs.commissionType.value = "RATE";
          updatedInputs.lineCommissionValue.value = 0;
          updatedInputs.lineCommissionValueCurrency.value = "39735";
          updatedInputs.lineCommissionRate.value = 0;
          updatedInputs.lineCommissionApplicableOn.value = "";
          updatedInputs.activeFrom.value = "";
          updatedInputs.activeTo.value = "";
        }
        updatedInputs.commissionType.hidden =
          isBusinessPartnerTypeDurectSalesforce ? true : false;
        updatedInputs.lineCommissionApplicableOn.hidden =
          isBusinessPartnerTypeDurectSalesforce;

        updatedInputs.commissionType.hidden =
          businessPartnerType === "Direct_Salesforce";
        updatedInputs.commissionType.required =
          businessPartnerType != "Direct_Salesforce";

        (
          updatedInputs.commissionType as IFormSelectDynamicProps
        ).selectOptions = newDefualtCommissionEnums["commissionTypes"];

        const isRate =
          updatedInputs.commissionType.value.toLowerCase() === "rate";
        updatedInputs.lineCommissionValue.hidden =
          isRate || businessPartnerType === "Direct_Salesforce";
        updatedInputs.lineCommissionValue.disabled = isRate;
        updatedInputs.lineCommissionValue.required = !isRate;
        updatedInputs.lineCommissionValueCurrency.hidden =
          isRate || businessPartnerType === "Direct_Salesforce";
        updatedInputs.lineCommissionValueCurrency.disabled = isRate;
        updatedInputs.lineCommissionValueCurrency.required = !isRate;

        updatedInputs.lineCommissionRate.hidden =
          !isRate || businessPartnerType === "Direct_Salesforce";
        updatedInputs.lineCommissionRate.disabled = !isRate;
        updatedInputs.lineCommissionRate.required = isRate;
        updatedInputs.lineCommissionApplicableOn.hidden =
          businessPartnerType === "Direct_Salesforce";
        updatedInputs.lineCommissionApplicableOn.disabled =
          businessPartnerType === "Direct_Salesforce";
        updatedInputs.lineCommissionApplicableOn.required = !(
          businessPartnerType === "Direct_Salesforce"
        );

        (updatedInputs.commissionType as IFormSelectDynamicProps).onSelect = (
          option
        ) => {
          const selectedType = option.toLowerCase();
          const isRate = selectedType === "rate";

          updatedInputs.lineCommissionValue.hidden =
            isRate || businessPartnerType === "Direct_Salesforce";
          updatedInputs.lineCommissionValue.disabled = isRate;
          updatedInputs.lineCommissionValue.required = !isRate;
          updatedInputs.lineCommissionValueCurrency.hidden =
            isRate || businessPartnerType === "Direct_Salesforce";
          updatedInputs.lineCommissionValueCurrency.disabled = isRate;
          updatedInputs.lineCommissionValueCurrency.required = !isRate;

          updatedInputs.lineCommissionRate.hidden =
            !isRate || businessPartnerType === "Direct_Salesforce";
          updatedInputs.lineCommissionRate.disabled = !isRate;
          updatedInputs.lineCommissionRate.required = isRate;
          updatedInputs.lineCommissionApplicableOn.hidden =
            businessPartnerType === "Direct_Salesforce";
          updatedInputs.lineCommissionApplicableOn.disabled =
            businessPartnerType === "Direct_Salesforce";
          updatedInputs.lineCommissionApplicableOn.required = !(
            businessPartnerType === "Direct_Salesforce"
          );
        };

        (
          updatedInputs.lineCommissionValueCurrency as IFormSelectDynamicProps
        ).selectOptions = newDefualtCommissionEnums["currencies"];

        (
          updatedInputs.lineCommissionApplicableOn as IFormSelectDynamicProps
        ).selectOptions =
          newDefualtCommissionEnums["commissionApplicableOnOptions"];

        if (defaultCommissionInfoResult?.data) {
          defaultCommissionEntity =
            graphqlToSalesforceBusinessPartnerCommissionInfo(
              defaultCommissionInfoResult?.data
            );
        }

        if (defaultCommissionEntity) {
          updatedInputs.commissionType.value =
            defaultCommissionEntity.commissionType;
          updatedInputs.lineCommissionApplicableOn.value =
            defaultCommissionEntity.lineCommissionApplicableOn;
          updatedInputs.lineCommissionRate.value =
            defaultCommissionEntity.lineCommissionRate;
          updatedInputs.lineCommissionValue.value =
            defaultCommissionEntity.lineCommissionValue;
          updatedInputs.lineCommissionValueCurrency.value =
            defaultCommissionEntity.lineCommissionValueCurrency;
        }
      }

      setInputsForm(updatedInputs);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const handleActiveDateChange = (activeTo: any, activeFrom: any) => {
    if (activeTo && activeFrom && dayjs(activeTo).isBefore(dayjs(activeFrom))) {
      setHasPlanActiveToError(
        "Effective To Date shall be equal or greater than Effective From Date"
      );
      setInputsForm((currentInputsForm) => ({
        ...currentInputsForm,
        activeTo: {
          ...currentInputsForm.activeTo,
          minDate: activeFrom,
          error:
            "Effective To Date shall be equal or greater than Effective From Date",
        },
      }));
      setSubmitButtonDisabled(true);
    } else {
      setHasPlanActiveToError("");
      setInputsForm((currentInputsForm) => ({
        ...currentInputsForm,
        activeTo: {
          ...currentInputsForm.activeTo,
          minDate: activeFrom,
          error: "",
        },
      }));

      setSubmitButtonDisabled(hasPlanActiveToError !== "");
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputs, values);

    setFormDisabled(true);
    setSubmitButtonState("loading");

    try {
      const isRate = data.commissionType.toLowerCase() === "rate";

      let variables = {
        selectedBusinessPartnerPlanIds: businessPartnerLinesIds,
        businessPartnerPlanInputs: {
          activeFrom: formatDateTime(
            data.activeFrom,
            SEND_TO_BACKEND_DATE_FORMAT
          ),
          activeTo: formatDateTime(data.activeTo, SEND_TO_BACKEND_DATE_FORMAT),
          planCommissionApplicableOn: data.lineCommissionApplicableOn || null,
          planCommissionRate: isRate
            ? parseInt(data.lineCommissionRate, 10) / 100
            : parseInt("0", 10),
          planCommissionType: data.commissionType,
          planCommissionValue: !isRate
            ? parseInt(data.lineCommissionValue, 10)
            : parseInt("0", 10),
          planCommissionValueCurrency: !isRate
            ? data.lineCommissionValueCurrency
            : "39735",
        },
      };

      setBusinessPartnerPlanCommissionAction({
        variables: { ...variables, entityId: businessPartnerId },
        errorPolicy: "all",
      }).then((res) => {
        if (isEmpty(res.errors)) {
          toast.success(
            <ToastSuccessMessage>
              {"Commissions successfully updated."}
            </ToastSuccessMessage>
          );
          setTimeout(() => {
            setSubmitButtonState("success");
            onSuccess();
            onClose();
          }, 500);
        } else {
          setSubmitButtonState(undefined);
          toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
        }
      });
    } catch {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setFormDisabled(false);
    }
  };

  useEffect(() => {
    initialize();
  }, [defaultCommissionEnumResults?.data, defaultCommissionInfoResult?.data]);

  useEffect(() => {
    handleActiveDateChange(newActiveTo, newActiveFrom);
  }, [newActiveFrom]);
  useEffect(() => {
    handleActiveDateChange(newActiveTo, newActiveFrom);
  }, [newActiveTo]);
  useEffect(() => {
    initializeUpdatedInputs();
  }, [selectedBusinessPlanIds]);

  return (
    <GenericDrawer
      title={"Set Commission"}
      onClose={() => onClose()}
      isOpen={open}
    >
      {(defaultCommissionEnumResults.loading ||
        defaultCommissionInfoResult?.loading) &&
      open ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={"Submit"}
            submitButtonState={submitButtonState}
            isSubmitButtonDisabled={submitButtonDisabled}
            disableForm={formDisabled}
            title="Information"
            // noteSection={
            //   <EnhancedNote
            //     name="note"
            //     title=""
            //     text="Note that plans' preset special commissions will override the commissions entered in this form."
            //     className={classes.note}
            //   />
            // }
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default SalesforceBusinessPartnerCommissionDrawer;
