import { gql } from '@apollo/client';

export function listQuery() {
  return gql`
    query GET_NSSFCODERANGE(
      $currentPage: Int!
      $currentPageSize: Int!
      $rangeStatus: [Insurance_RangeStatuses]
    ) {
      Insurance {
        queries {
          listNSSFCodeRange(
            RangeStatus: $rangeStatus
            pagination: { pageNumber: $currentPage, pageSize: $currentPageSize }
          ) {
            paging {
              pageSize
              pageNumber
              totalCount
            }
            items {
              insurance_NSSFCodeRange_Id
              insurance_NSSFCodeRange_RangeFrom
              insurance_NSSFCodeRange_RangeTo
              insurance_NSSFCodeRange_NSSFCodeReached
              insurance_NSSFCodeRange_RangeStatus
              insurance_NSSFCodeRange_createdOn
              insurance_NSSFCodeRange_modifiedOn
            }
          }
        }
      }
      Insurance_RangeStatuses: __type(name: "Insurance_RangeStatuses") {
        name
        enumValues {
          name
        }
      }
    }
  `;
}
