import { DEFAULT_ERROR_TEXT } from '../constants';
import { capitalizeFirstLetter } from './formatting-utils';
import { isEmpty } from './validationUtils';

export const enumListAsRecordObject = (
  enumList: { __typename: string; name: string }[]
): Record<string, string> => {
  let dropDownList: Record<string, string> = {};
  enumList.forEach((element: { __typename: string; name: string }) => {
    if (element.name) {
      dropDownList[element.name] = capitalizeFirstLetter(
        element.name.replaceAll('_', ' ')
      );
    }
  });

  return dropDownList;
};

export const lookupListAsRecordObject = (
  lookupList: { [key: string]: string }[],
  key = 'Id',
  title = 'Title'
): Record<string, string> => {
  let lookuplistAsRecordObject: Record<string, string> = {};

  lookupList.forEach((element: { [key: string]: string }) => {
    if (element[key] && element[title]) {
      lookuplistAsRecordObject[element[key]] = element[title];
    }
  });

  return lookuplistAsRecordObject;
};

export const getError = (response?: any) => {
  let errors = response?.errors || response?.graphQLErrors;
  if (errors && !isEmpty(errors) && errors.length != 0) {
    if (errors[0]?.extensions?.code) {
      return errors[0]?.message;
    } else {
      return DEFAULT_ERROR_TEXT;
    }
  } else {
    return DEFAULT_ERROR_TEXT;
  }
};

export const lookupListAsRecordObjectWithArabicTitle = (
  lookupList: { [key: string]: string }[],
  key = 'Id',
  title = 'Title',
  titleAr = 'TitleAr'
): Record<string, string> => {
  let lookuplistAsRecordObject: Record<string, string> = {};

  lookupList.forEach((element: { [key: string]: string }) => {
    if (element[key] && element[title]) {
      lookuplistAsRecordObject[element[key]] = element[title] + " - " + element[titleAr];
    }
  });

  return lookuplistAsRecordObject;
};
