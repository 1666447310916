import { gql } from "@apollo/client";

export function currencyLookup() {
  return gql`
    query currencyLookup {
      Core {
        lookups {
          currencies {
            Id
            Title
          }
        }
      }
    }
  `;
}

export function updateSystemCurrency() {
  return gql`
    mutation updateSystemCurrency(
      $entityId: String!
      $basicCurrency: String!
      $secondaryCurrency: String!
    ) {
      core {
        entities {
          systemCurrency {
            core {
              updateSystemCurrency(
                entityId: $entityId
                basicCurrency: $basicCurrency
                secondaryCurrency: $secondaryCurrency
              ) {
                id
              }
            }
          }
        }
      }
    }
  `;
}
