import React, { useEffect, useState } from "react";
import GenericDrawer from "../../components/common/generic-drawer/GenericDrawer";
import DynamicForm from "../../DynamicForm/DynamicForm";
import {
  DynamicFormInputType,
  IFormSelectDynamicProps,
} from "../../DynamicForm";
import { toast } from "react-toastify";
import { EnhancedButtonStatus } from "../../components/common/EnhancedButton";
import { normaliseDynamicValues } from "../../utils/dynamic-utils";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { inputs } from "./content";
import { useMutation, useQuery } from "@apollo/client";
import {
  createPartnerVignetteRange,
  getSalesforceVignetteRangeInfo,
  getSalesforceVignetteStatusesEnums,
  updatePartnerVignetteRange,
} from "./queries";
import { cloneDeep } from "lodash";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import { DEFAULT_ERROR_TEXT } from "../../constants";
import { isEmpty } from "../../utils/validationUtils";
import { getError } from "../../utils/graph-utils";
import { VignetteLookupsToList, graphqlToVignetteRangeInfo } from "./utils";
import { useParams } from "react-router-dom";

const SalesforceVignetteRangeDrawer: React.FC<
  ISalesforceVignetteRangeDrawerProps
> = ({ open, onSuccess, onClose, vignetteRangeId }) => {
  const [vignetteRangeAction] = useMutation(
    vignetteRangeId
      ? updatePartnerVignetteRange()
      : createPartnerVignetteRange()
  );
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();
  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);
  const params = useParams();

  let vignetteRangeInfoResult: any;
  if (vignetteRangeId) {
    vignetteRangeInfoResult = useQuery(getSalesforceVignetteRangeInfo(), {
      variables: { id: vignetteRangeId },
    });
  }

  let vignetteRangeInfo: IVignetteRangeInfo = {
    rangeCode: "",
    rangeYear: 0,
    rangeFrom: 0,
    rangeTo: 0,
    rangeStatus: "ACTIVE",
  };

  const vignetteStatusesEnumsResults = useQuery(
    getSalesforceVignetteStatusesEnums()
  );

  const initialize = () => {
    try {
      const updatedInputs = cloneDeep(inputsForm);

      if (vignetteStatusesEnumsResults.data) {
        const newVignetteLookups = VignetteLookupsToList(
          vignetteStatusesEnumsResults.data
        );

        (updatedInputs.rangeStatus as IFormSelectDynamicProps).selectOptions =
          newVignetteLookups["SalesforceManagement_VignetteRangeStatuses"];
      }

      if (vignetteRangeInfoResult?.data) {
        vignetteRangeInfo = graphqlToVignetteRangeInfo(
          vignetteRangeInfoResult?.data
        );
      }

      if (vignetteRangeInfo) {
        updatedInputs.rangeYear.value = vignetteRangeInfo.rangeYear;
        updatedInputs.rangeCode.value = vignetteRangeInfo.rangeCode;
        updatedInputs.rangeFrom.value = vignetteRangeInfo.rangeFrom;
        updatedInputs.rangeTo.value = vignetteRangeInfo.rangeTo;
        updatedInputs.rangeStatus.value = vignetteRangeInfo.rangeStatus;

        setInputsForm(updatedInputs);
      }

      setInputsForm(updatedInputs);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputs, values);

    setFormDisabled(true);
    setSubmitButtonState("loading");
    try {
      let variables = {
        entityId: params.id,
        rangeCode: data.rangeCode,
        rangeFrom: parseInt(data.rangeFrom as unknown as string),
        rangeStatus: data.rangeStatus,
        rangeTo: parseInt(data.rangeTo as unknown as string),
        rangeYear: parseInt(data.rangeYear as unknown as string),
      };

      vignetteRangeAction({
        variables: vignetteRangeId
          ? {
              ...variables,
              selectedBusinessPartnerVignetteRangeId: vignetteRangeId,
            }
          : variables,
        errorPolicy: "all",
      }).then((res) => {
        if (isEmpty(res.errors)) {
          toast.success(
            <ToastSuccessMessage>
              {vignetteRangeId
                ? "Vignette Range successfully updated"
                : "Vignette Range successfully created"}
            </ToastSuccessMessage>
          );
          setTimeout(() => {
            setSubmitButtonState("success");
            onSuccess();
            onClose();
          }, 500);
        } else {
          setSubmitButtonState(undefined);
          toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
        }
      });
    } catch {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setFormDisabled(false);
    }
  };

  useEffect(() => {
    initialize();
  }, [vignetteStatusesEnumsResults.data, vignetteRangeInfoResult?.data]);

  return (
    <GenericDrawer
      title={vignetteRangeId ? "Modify Vignette Range" : "New Vignette Range"}
      onClose={() => onClose()}
      isOpen={open}
    >
      {
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={"Submit"}
            submitButtonState={submitButtonState}
            disableForm={formDisabled}
            title="Information"
          />
        </>
      }
    </GenericDrawer>
  );
};

export default SalesforceVignetteRangeDrawer;
