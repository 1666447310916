import React, { useEffect, useState } from "react";
import GenericDrawer from "../../components/common/generic-drawer/GenericDrawer";
import {
  DynamicFormInputType,
  IFormSelectDynamicProps,
} from "../../DynamicForm";
import { toast } from "react-toastify";
import { EnhancedButtonStatus } from "../../components/common/EnhancedButton";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { inputs } from "./content";
import { useMutation, useQuery } from "@apollo/client";
import Loader from "../../components/Loader";
import { cloneDeep, isEmpty } from "lodash";
import { DEFAULT_ERROR_TEXT } from "../../constants";
import { IPlanAdditionalFeesAndChargesDrawerProps } from ".";
import { getAdditionalFeesAndChargesEnums, updatePlanFees } from "./queries";
import { LookupToList } from "./utils";
import { getError } from "../../utils/graph-utils";
import { normaliseDynamicValues } from "../../utils/dynamic-utils";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import DynamicForm from "../../DynamicForm/DynamicForm";

const PlanAdditionalFeesAndChargesDrawer: React.FC<
  IPlanAdditionalFeesAndChargesDrawerProps
> = ({
  open,
  onSuccess,
  onClose,
  additionalFeesAndChargesDetailsInfo,
  planId,
}) => {
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();
  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);
  let [submitButtonDisabled, setSubmitButtonDisabled] =
    useState<boolean>(false);
  const [hasPlanActiveToError, setHasPlanActiveToError] = useState("");
  const [tpaAmount, setTpaAmount] = useState<string>();
  const [minTPA, setMinTPA] = useState<string>();
  const [maxTPA, setMaxTPA] = useState<string>();
  const [newAllValues, setNewAllValues] = useState<Record<string, any>>();
  const [comparFromTo, setComparFromTo] = useState<number>(0);

  const additionalFeesAndChargesDetailsListResults = useQuery(
    getAdditionalFeesAndChargesEnums()
  );

  const [additionalFeesAndChargesAction] = useMutation(updatePlanFees());

  useEffect(() => {
    initialize();
  }, [additionalFeesAndChargesDetailsListResults.data]);

  let updatedInputs: Record<string, DynamicFormInputType> = inputs;
  let newAdditionalFeesAndChargesEnums: Record<
    string,
    Record<string, string>
  > = {};

  const initialize = () => {
    try {
      updatedInputs = cloneDeep(inputsForm);

      if (additionalFeesAndChargesDetailsListResults.data) {
        newAdditionalFeesAndChargesEnums = LookupToList(
          additionalFeesAndChargesDetailsListResults.data
        );

        (updatedInputs.tPAFeesType as IFormSelectDynamicProps).selectOptions =
          newAdditionalFeesAndChargesEnums["tPATypes"];

        (
          updatedInputs.tPAFeesApplicableOn as IFormSelectDynamicProps
        ).selectOptions =
          newAdditionalFeesAndChargesEnums["tPAApplicableOnOptions"];

        (updatedInputs.planCurrency as IFormSelectDynamicProps).selectOptions =
          newAdditionalFeesAndChargesEnums["currencies"];
      }

      (updatedInputs.tPAFeesType as IFormSelectDynamicProps).onSelect = (
        option
      ) => {
        const selectedType = option.toLowerCase();
        const isPercentage = selectedType === "percentage";

        updatedInputs.tPAFeesPercentage.hidden = !isPercentage;
        updatedInputs.tPAFeesPercentage.disabled = !isPercentage;
        updatedInputs.tPAFeesPercentage.required = isPercentage;

        updatedInputs.tPAFeesAmount.hidden = isPercentage;
        updatedInputs.tPAFeesAmount.disabled = isPercentage;
        updatedInputs.tPAFeesAmount.required = !isPercentage;
      };

      if (additionalFeesAndChargesDetailsInfo) {
        updatedInputs.planCurrency.value =
          additionalFeesAndChargesDetailsInfo.planCurrency;
        updatedInputs.policyCost.value =
          additionalFeesAndChargesDetailsInfo.policyCost;
        updatedInputs.chargesPercentage.value = Math.round(
          parseFloat(
            (
              Number(additionalFeesAndChargesDetailsInfo.chargesPercentage) *
              100
            ).toFixed(10)
          )
        );
        updatedInputs.tPAFeesType.value =
          additionalFeesAndChargesDetailsInfo.tPAFeesType || "PERCENTAGE";
        updatedInputs.tPAFeesAmount.value =
          additionalFeesAndChargesDetailsInfo.tPAFeesAmount;
        updatedInputs.tPAFeesPercentage.value = Math.round(
          parseFloat(
            (
              Number(additionalFeesAndChargesDetailsInfo.tPAFeesPercentage) *
              100
            ).toFixed(10)
          )
        );
        updatedInputs.tPAFeesApplicableOn.value =
          additionalFeesAndChargesDetailsInfo.tPAFeesApplicableOn;
        updatedInputs.minTPA.value = additionalFeesAndChargesDetailsInfo.minTPA;
        updatedInputs.maxTPA.value = additionalFeesAndChargesDetailsInfo.maxTPA;

        if (additionalFeesAndChargesDetailsInfo.tPAFeesAmount) {
          setTpaAmount(additionalFeesAndChargesDetailsInfo.tPAFeesAmount);
        }

        const isPercentage =
          updatedInputs.tPAFeesType.value.toLowerCase() === "percentage";

        updatedInputs.tPAFeesPercentage.hidden = !isPercentage;
        updatedInputs.tPAFeesPercentage.disabled = !isPercentage;
        updatedInputs.tPAFeesPercentage.required = isPercentage;

        updatedInputs.tPAFeesAmount.hidden = isPercentage;
        updatedInputs.tPAFeesAmount.disabled = isPercentage;
        updatedInputs.tPAFeesAmount.required = !isPercentage;
      }

      setInputsForm(updatedInputs);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputs, values);

    setFormDisabled(true);
    setSubmitButtonState("loading");

    try {
      const isPercentage = data.tPAFeesType.toLowerCase() === "percentage";
      let variablesMutation = {};
      variablesMutation = {
        entityId: planId,
        planFeesInputs: {
          policyCost: Number(data?.policyCost),
          chargesPercentage: Number(data?.chargesPercentage) / 100,
          tpaFeesType: data?.tPAFeesType,
          tpaFeesAmount: !isPercentage ? Number(data?.tPAFeesAmount) : 0,
          tpaFeesPercentage: isPercentage
            ? Number(data?.tPAFeesPercentage) / 100
            : 0,
          tpaFeesApplicableOn: data?.tPAFeesApplicableOn,
          minTPA: Number(data?.minTPA),
          maxTPA: Number(data?.maxTPA),
        },
      };

      additionalFeesAndChargesAction({
        variables: variablesMutation,
        errorPolicy: "all",
      })
        .then((res) => {
          if (isEmpty(res.errors)) {
            toast.success(
              <ToastSuccessMessage>
                Additional Fees and Charges successfully updated.
              </ToastSuccessMessage>
            );
            setTimeout(() => {
              setSubmitButtonState("success");
              onSuccess();
              onClose();
            }, 500);
          } else {
            setSubmitButtonState(undefined);
            toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
          }
        })
        .catch((err) => {
          toast.error(<ToastErrorMessage>{getError(err)}</ToastErrorMessage>);
        });
    } catch {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setFormDisabled(false);
    }
  };

  useEffect(() => {
    const errorMin =
      parseInt(minTPA) > parseInt(maxTPA)
        ? "Min TPA must be less than Max TPA"
        : tpaAmount && parseInt(minTPA) > parseInt(tpaAmount)
        ? "TPA Amount cannot be less than Min TPA"
        : "";
    setHasPlanActiveToError(errorMin);
    setInputsForm((currentInputsForm) => ({
      ...createInputsFormFromAllValues(currentInputsForm, newAllValues),
      minTPA: {
        ...currentInputsForm.minTPA,
        value: minTPA && minTPA.toString(),
        error: errorMin,
      },
    }));
    setSubmitButtonDisabled(errorMin ? true : hasPlanActiveToError !== "");

    const errorMax =
      parseInt(maxTPA) < parseInt(minTPA)
        ? "Max TPA must be greater than Min TPA"
        : tpaAmount && parseInt(maxTPA) < parseInt(tpaAmount)
        ? "TPA Amount cannot be greater than Max TPA"
        : "";
    setHasPlanActiveToError(errorMax);
    setInputsForm((currentInputsForm) => ({
      ...createInputsFormFromAllValues(currentInputsForm, newAllValues),
      maxTPA: {
        ...currentInputsForm.maxTPA,
        value: maxTPA && maxTPA.toString(),
        error: errorMax,
      },
    }));
    setSubmitButtonDisabled(errorMax ? true : hasPlanActiveToError !== "");
  }, [minTPA, maxTPA, tpaAmount, newAllValues, comparFromTo]);

  const createInputsFormFromAllValues = (
    currentInputsForm: any,
    allValues: Record<string, any>
  ) => {
    if (newAllValues) {
      const newInputsForm = {
        planCurrency: {
          ...currentInputsForm.planCurrency,
          value: allValues["planCurrency"],
        },
        policyCost: {
          ...currentInputsForm.policyCost,
          value: allValues["policyCost"],
        },
        chargesPercentage: {
          ...currentInputsForm.chargesPercentage,
          value: allValues["chargesPercentage"],
        },
        tPAFeesType: {
          ...currentInputsForm.tPAFeesType,
          value: allValues["tPAFeesType"],
        },
        tPAFeesAmount: {
          ...currentInputsForm.tPAFeesAmount,
          value: allValues["tPAFeesAmount"],
        },
        tPAFeesPercentage: {
          ...currentInputsForm.tPAFeesPercentage,
          value: allValues["tPAFeesPercentage"],
        },
        tPAFeesApplicableOn: {
          ...currentInputsForm.tPAFeesApplicableOn,
          value: allValues["tPAFeesApplicableOn"],
        },
        minTPA: {
          ...currentInputsForm.minTPA,
          value: allValues["minTPA"],
        },
        maxTPA: {
          ...currentInputsForm.maxTPA,
          value: allValues["maxTPA"],
        },
      };

      (newInputsForm.tPAFeesType as IFormSelectDynamicProps).onSelect = (
        option
      ) => {
        const selectedType = option.toLowerCase();
        const isPercentage = selectedType === "percentage";

        newInputsForm.tPAFeesPercentage.hidden = !isPercentage;
        newInputsForm.tPAFeesPercentage.disabled = !isPercentage;
        newInputsForm.tPAFeesPercentage.required = isPercentage;

        newInputsForm.tPAFeesAmount.hidden = isPercentage;
        newInputsForm.tPAFeesAmount.disabled = isPercentage;
        newInputsForm.tPAFeesAmount.required = !isPercentage;
      };

      return newInputsForm;
    } else return currentInputsForm;
  };

  return (
    <GenericDrawer
      title={"Modify Additional Fees and Charges"}
      onClose={() => onClose()}
      isOpen={open}
    >
      {additionalFeesAndChargesDetailsListResults.loading && open ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={"Submit"}
            submitButtonState={submitButtonState}
            isSubmitButtonDisabled={submitButtonDisabled}
            disableForm={formDisabled}
            title="Information"
            hasDoprdownSpecificBehavior={true}
            onChange={(
              fieldName: string,
              value: string | string[],
              allValues
            ) => {
              if (fieldName === "minTPA") {
                setMinTPA(value as string);
                setNewAllValues(allValues);
                setComparFromTo((oldState) => oldState + 1);
              }
              if (fieldName === "maxTPA") {
                setMaxTPA(value as string);
                setNewAllValues(allValues);
                setComparFromTo((oldState) => oldState + 1);
              }
              if (fieldName === "tPAFeesAmount") {
                setTpaAmount(value as string);
                setNewAllValues(allValues);
                setComparFromTo((oldState) => oldState + 1);
              }
            }}
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default PlanAdditionalFeesAndChargesDrawer;
