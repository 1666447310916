import { lookupListAsRecordObject } from "../../utils/graph-utils";

export function LookupToList(data: any) {
  const currencyLookup: Record<string, Record<string, string>> = {};

  currencyLookup['currencies'] = lookupListAsRecordObject(data.Core.lookups.currencies);

  return currencyLookup;
}

export function graphqlEntityToSystemCurrencyConfigInfo(
  basicCurrency: any,
  secondaryCurrency: any,
): ISystemCurrencyConfigDrawerPayload {
  return {
    basicCurrency: basicCurrency,
    secondaryCurrency: secondaryCurrency,
  };
}
