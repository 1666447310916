export function extractApplicationDrawerData(data: any) {
  const returnData: Record<string, Record<string, string>> = {};
  let agencies: Record<string, string> = {};

  data.Insurance.queries.allAgencies.forEach(
    (element: {
      insurance_Agency_Id: string;
      insurance_Agency_AgencyName: string;
    }) => {
      agencies[element.insurance_Agency_Id] =
        element.insurance_Agency_AgencyName;
    },
  );

  returnData['agencies'] = agencies;

  return returnData;
}

export function extractAgents(data: any) {
  let agents: Record<string, string> = {};

  data?.Insurance?.queries?.listAgencyAgents.forEach(
    (element: {
      insurance_Agent_Id: string;
      insurance_Agent_FirstName: string;
      insurance_Agent_LastName: string;
    }) => {
      agents[element.insurance_Agent_Id] =
        element.insurance_Agent_FirstName +
        ' ' +
        element.insurance_Agent_LastName;
    },
  );

  return agents;
}
