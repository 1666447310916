import { DynamicFormInputType, FormInputTypes } from '../../DynamicForm';
import { isEmpty } from '../../utils/validationUtils';

export const initialValues = {
  businessPartnerDetails: {
    person: '',
    relatedCompany: '',
    type: '',
    category: '',
    isCompany: false,
    isLocal: false,
    isRegistered: false,
    registerNumber: '',
    taxAccount: '',
    account: '',
    maxCreditLimitAllowedCurrency: '',
    maxCreditLimitAllowed: 0,
    SalesforceManagement_BusinessPartnerStatuses: 'NEW',
  },
};

export const inputs: Record<string, DynamicFormInputType> = {
  person: {
    name: 'person',
    title: 'Person',
    type: FormInputTypes.chips,
    placeholder: 'Type to search for Person',
    value: !isEmpty(initialValues.businessPartnerDetails.person)
      ? [initialValues.businessPartnerDetails.person]
      : [],
    multiple: false,
    disabled: false,
    required: true,
    hasPagination: true,
    itemsPerPage: 15,
  },
  relatedCompany: {
    name: 'relatedCompany',
    title: 'Related Company',
    type: FormInputTypes.chips,
    placeholder: 'Related Company',
    value: !isEmpty(initialValues.businessPartnerDetails.relatedCompany)
      ? initialValues.businessPartnerDetails.relatedCompany
      : '',
    multiple: false,
    disabled: false,
    required: true,
  },
  type: {
    name: 'type',
    title: 'Type',
    type: FormInputTypes.chips,
    placeholder: 'Type',
    value: !isEmpty(initialValues.businessPartnerDetails.type)
      ? initialValues.businessPartnerDetails.type
      : '',
    multiple: false,
    disabled: false,
    required: true,
  },
  category: {
    name: 'category',
    title: 'Category',
    type: FormInputTypes.chips,
    placeholder: 'Category',
    value: !isEmpty(initialValues.businessPartnerDetails.category)
      ? initialValues.businessPartnerDetails.category
      : '',
    multiple: false,
    disabled: false,
    required: false,
  },
  isCompany: {
    name: 'isCompany',
    title: 'Is Company',
    placeholder: 'Is Company',
    type: FormInputTypes.switch,
    value: initialValues.businessPartnerDetails.isCompany,
    required: false,
    disabled: false,
  },
  isLocal: {
    name: 'isLocal',
    title: 'Is Local',
    placeholder: 'Is Local',
    type: FormInputTypes.switch,
    value: initialValues.businessPartnerDetails.isLocal,
    required: false,
    disabled: false,
  },
  isRegistered: {
    name: 'isRegistered',
    title: 'Is Registered',
    placeholder: 'Is Registered',
    type: FormInputTypes.switch,
    value: initialValues.businessPartnerDetails.isRegistered,
    required: false,
    disabled: false,
  },
  registerNumber: {
    name: 'registerNumber',
    title: 'Register Number',
    placeholder: 'Register Number',
    type: FormInputTypes.text,
    value: initialValues.businessPartnerDetails.registerNumber,
    required: false,
    disabled: false,
  },
  taxAccount: {
    name: 'taxAccount',
    title: 'Tax Account',
    placeholder: 'Tax Account',
    type: FormInputTypes.text,
    value: initialValues.businessPartnerDetails.taxAccount,
    required: false,
    disabled: false,
  },
  account: {
    name: 'account',
    title: 'Account',
    placeholder: 'Account',
    type: FormInputTypes.text,
    value: initialValues.businessPartnerDetails.account,
    required: false,
    disabled: false,
  },
  maxCreditLimitAllowedCurrency: {
    name: 'maxCreditLimitAllowedCurrency',
    title: 'Max Credit Limit Allowed (Currency)',
    type: FormInputTypes.chips,
    placeholder: 'Max Credit Limit Allowed (Currency)',
    value: !isEmpty(initialValues.businessPartnerDetails.maxCreditLimitAllowedCurrency)
      ? initialValues.businessPartnerDetails.maxCreditLimitAllowedCurrency
      : '',
    multiple: false,
    disabled: false,
    required: true,
  },
  maxCreditLimitAllowed: {
    name: 'maxCreditLimitAllowed',
    title: 'Max Credit Limit Allowed',
    type: FormInputTypes.formattedNumber,
    placeholder: 'Max Credit Limit Allowed',
    value: initialValues.businessPartnerDetails.maxCreditLimitAllowed,
    required: true,
    disabled: false,
  },
  SalesforceManagement_BusinessPartnerStatuses: {
    name: 'SalesforceManagement_BusinessPartnerStatuses',
    title: 'Status',
    type: FormInputTypes.chips,
    value: initialValues.businessPartnerDetails.SalesforceManagement_BusinessPartnerStatuses,
    required: true,
    multiple: false,
    disabled: true,
  },
};
