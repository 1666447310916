import React, { useEffect, useState } from "react";
import GenericDrawer from "../../components/common/generic-drawer/GenericDrawer";
import DynamicForm from "../../DynamicForm/DynamicForm";
import { inputs } from "./content";
import { cloneDeep, isEmpty } from "lodash";
import {
  DynamicFormInputType,
  IFormDateDynamicProps,
  IFormSelectDynamicProps,
  IFormTextDynamicProps,
} from "../../DynamicForm";
import { toast } from "react-toastify";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { DEFAULT_ERROR_TEXT } from "../../constants";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  checkCompanyExists,
  checkPersonExists,
  createPerson,
  getPersonEnums,
  getPersonInfo,
  updatePerson,
} from "./queries";
import { graphqlEntityToPersonInfo, LookupToList } from "./utils";
import { useNavigate } from "react-router-dom";
import { normaliseDynamicValues } from "../../utils/dynamic-utils";
import { EnhancedButtonStatus } from "../../components/EnhancedButton";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import { getError } from "../../utils/graph-utils";
import Loader from "../../components/Loader";
import dayjs from "dayjs";

const PersonDrawer: React.FC<IPersonDrawerProps> = ({
  personId,
  open,
  onSuccess,
  onClose,
}) => {
  let personInfoResult: any;

  const navigate = useNavigate();

  const [hasPersonNameError, setHasPersonNameError] = useState("");
  const [hasCompanyNameError, setHasCompanyNameError] = useState("");
  let personNameError = "";
  let companyNameError = "";

  let [submitButtonDisabled, setSubmitButtonDisabled] =
    useState<boolean>(false);

  //const [selectedSublineStatus, setSelectedSublineStatus] = useState<string>("");
  let newPersonEnums: Record<string, Record<string, string>> = {};
  let personEntity: IPersonInfo = {
    personId: "",
    type: "",
    companyName: "",
    title: "",
    firstName: "",
    middleName: "",
    lastName: "",
    dateOfBirth: "",
    email: "",
    phoneType: "",
    phoneNumber: "",
  };

  let personEnumResults = useQuery(getPersonEnums(), {
    fetchPolicy: "no-cache",
  });

  const [checkPersonExistsLazy] = useLazyQuery(checkPersonExists(), {});
  const [checkCompanyExistsLazy] = useLazyQuery(checkCompanyExists(), {});

  const [personAction] = useMutation(
    personId ? updatePerson() : createPerson()
  );

  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();

  let updatedInputs: Record<string, DynamicFormInputType> = inputs;
  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);

  if (personId) {
    personInfoResult = useQuery(getPersonInfo(), {
      variables: { id: personId },
    });
  }

  const initialize = () => {
    try {
      updatedInputs = cloneDeep(inputsForm);

      if (personEnumResults.data) {
        newPersonEnums = LookupToList(personEnumResults.data);

        (updatedInputs.type as IFormSelectDynamicProps).selectOptions =
          newPersonEnums["SalesforceManagement_PersonTypes"];

        (updatedInputs.type as IFormSelectDynamicProps).selectOptions =
          newPersonEnums["SalesforceManagement_PersonTypes"];

        (updatedInputs.phoneType as IFormSelectDynamicProps).selectOptions =
          newPersonEnums["phoneType"];

        (updatedInputs.title as IFormSelectDynamicProps).selectOptions =
          newPersonEnums["titles"];

        (updatedInputs.type as IFormSelectDynamicProps).onSelect = (option) => {
          const selectedType = option.toLowerCase();
          const isPerson = selectedType === "person";

          updatedInputs.companyName.hidden = isPerson;
          updatedInputs.companyName.disabled = isPerson;
          updatedInputs.companyName.required = !isPerson;

          updatedInputs.title.hidden = !isPerson;
          updatedInputs.title.disabled = !isPerson;
          updatedInputs.title.required = isPerson;

          updatedInputs.firstName.hidden = !isPerson;
          updatedInputs.firstName.disabled = !isPerson;
          updatedInputs.firstName.required = isPerson;

          updatedInputs.middleName.hidden = !isPerson;
          updatedInputs.middleName.disabled = !isPerson;
          updatedInputs.middleName.required = isPerson;

          updatedInputs.lastName.hidden = !isPerson;
          updatedInputs.lastName.disabled = !isPerson;
          updatedInputs.lastName.required = isPerson;

          updatedInputs.dateOfBirth.hidden = !isPerson;
          updatedInputs.dateOfBirth.disabled = !isPerson;
        };

        (updatedInputs.companyName as IFormTextDynamicProps).onChange = (
          event
        ) => {
          const newName = event.target.value;
          (updatedInputs.companyName as IFormTextDynamicProps).value = newName;
          if (!isEmpty(newName)) {
            validateCompanyNameExists(newName);
          }
        };
        (updatedInputs.companyName as IFormTextDynamicProps).onChange = (
          event
        ) => {
          const newName = event.target.value;
          (updatedInputs.companyName as IFormTextDynamicProps).value = newName;
          if (!isEmpty(newName)) {
            validateCompanyNameExists(newName);
          }
        };
        (updatedInputs.firstName as IFormTextDynamicProps).onChange = (
          event
        ) => {
          const newName = event.target.value;
          (updatedInputs.firstName as IFormTextDynamicProps).value = newName;
          if (!isEmpty(newName)) {
            validatePersonNameExistsOnChange(
              newName,
              updatedInputs.middleName.value,
              updatedInputs.lastName.value,
              updatedInputs.dateOfBirth.value
            );
          }
        };
        (updatedInputs.middleName as IFormTextDynamicProps).onChange = (
          event
        ) => {
          const newName = event.target.value;
          (updatedInputs.middleName as IFormTextDynamicProps).value = newName;
          if (!isEmpty(newName)) {
            validatePersonNameExistsOnChange(
              updatedInputs.firstName.value,
              newName,
              updatedInputs.lastName.value,
              updatedInputs.dateOfBirth.value
            );
          }
        };
        (updatedInputs.lastName as IFormTextDynamicProps).onChange = (
          event
        ) => {
          const newName = event.target.value;
          (updatedInputs.lastName as IFormTextDynamicProps).value = newName;
          if (!isEmpty(newName)) {
            validatePersonNameExistsOnChange(
              updatedInputs.firstName.value,
              updatedInputs.middleName.value,
              newName,
              updatedInputs.dateOfBirth.value
            );
          }
        };

        (updatedInputs.dateOfBirth as IFormDateDynamicProps).onChange = (
          event
        ) => {
          const newDate = event;
          (updatedInputs.dateOfBirth as IFormDateDynamicProps).value = newDate;
          if (newDate != null) {
            validatePersonNameExistsOnChange(
              updatedInputs.firstName.value,
              updatedInputs.middleName.value,
              updatedInputs.lastName.value,
              newDate
            );
          }
        };

        if (personInfoResult?.data) {
          personEntity = graphqlEntityToPersonInfo(personInfoResult?.data);
        }

        if (personEntity) {
          updatedInputs.phoneNumber.value = personEntity.phoneNumber;
          updatedInputs.type.value = personEntity.type;
          updatedInputs.companyName.value = personEntity.companyName;
          updatedInputs.title.value = personEntity.title;
          updatedInputs.firstName.value = personEntity.firstName;
          updatedInputs.middleName.value = personEntity.middleName;
          updatedInputs.lastName.value = personEntity.lastName;
          updatedInputs.dateOfBirth.value = personEntity.dateOfBirth;
          updatedInputs.email.value = personEntity.email;
          updatedInputs.phoneType.value = personEntity.phoneType;
        }
      }

      setInputsForm(updatedInputs);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputs, values);
    setFormDisabled(true);
    setSubmitButtonState("loading");
    try {
      let variables = {
        dateOfBirth:
          data.dateOfBirth != null
            ? new Date(dayjs(new Date(data.dateOfBirth)).format("YYYY-MM-DD"))
            : null,
        personDetailsInputs: {
          personType: data.type,
          companyName: !isEmpty(data.companyName) ? data.companyName : null,
          title: !isEmpty(data.title) ? data.title : null,
          firstName: !isEmpty(data.firstName) ? data.firstName : null,
          middleName: !isEmpty(data.middleName) ? data.middleName : null,
          lastName: !isEmpty(data.lastName) ? data.lastName : null,
          email: !isEmpty(data.email) ? data.email : null,
          phoneType: !isEmpty(data.phoneType) ? data.phoneType : null,
          phoneNumber: !isEmpty(data.phoneNumber) ? data.phoneNumber : null,
        },
      };
      personAction({
        variables: personId ? { ...variables, entityId: personId } : variables,
        errorPolicy: "all",
      }).then((res) => {
        if (isEmpty(res.errors)) {
          toast.success(
            <ToastSuccessMessage>
              {personId
                ? "Person successfully updated"
                : "Person successfully created"}
            </ToastSuccessMessage>
          );
          const newPersonId =
            res.data.salesforceManagement.actions.createPerson.id;
          setTimeout(() => {
            setSubmitButtonState("success");
            onSuccess();
            onClose();
            if (!personId) {
              navigate(`/salesforce/persons/` + newPersonId);
            }
          }, 500);
        } else {
          setSubmitButtonState(undefined);
          toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
        }
      });
    } catch {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setFormDisabled(false);
    }
  };

  const validateCompanyNameExists = (companyName: string) => {
    if (!isEmpty(companyName)) {
      checkCompanyExistsLazy({
        variables: {
          companyName: companyName,
          personID: personId,
        },
      }).then((response) => {
        setInputsForm((currentInputsForm) => {
          const updatedInputs = { ...currentInputsForm };

          if (
            response.data.SalesforceManagement?.queries?.checkCompanyNameExists
              .length > 0
          ) {
            companyNameError = "Company already Exists";
            setHasCompanyNameError("Company already Exists");
            (updatedInputs.companyName as IFormTextDynamicProps).error =
              companyNameError;
          } else {
            companyNameError = "";
            setHasCompanyNameError("");
            (updatedInputs.companyName as IFormTextDynamicProps).error =
              companyNameError;
          }

          return updatedInputs;
        });
      });
    }
  };

  const validatePersonNameExistsOnChange = (
    firstName: string,
    middleName: string,
    lastName: string,
    dateOfBirth: Date
  ) => {
    if (!isEmpty(firstName) && !isEmpty(middleName) && !isEmpty(lastName)) {
      checkPersonExistsLazy({
        variables: {
          firstName: firstName,
          middleName: middleName,
          lastName: lastName,
          dateOfBirth: new Date(
            dayjs(new Date(dateOfBirth)).format("YYYY-MM-DD")
          ),
        },
      }).then((response) => {
        setInputsForm((currentInputsForm) => {
          const updatedInputs = { ...currentInputsForm };

          if (
            response.data.SalesforceManagement?.queries?.checkPersonExists
              .length > 0
          ) {
            personNameError = "Person already Exists";
            setHasPersonNameError("Person already Exists");
            (updatedInputs.firstName as IFormTextDynamicProps).error =
              personNameError;
            (updatedInputs.lastName as IFormTextDynamicProps).error =
              personNameError;
            (updatedInputs.middleName as IFormTextDynamicProps).error =
              personNameError;
          } else {
            personNameError = "";
            setHasPersonNameError("");
            (updatedInputs.firstName as IFormTextDynamicProps).error =
              personNameError;
            (updatedInputs.lastName as IFormTextDynamicProps).error =
              personNameError;
            (updatedInputs.middleName as IFormTextDynamicProps).error =
              personNameError;
          }

          return updatedInputs;
        });
      });
    }
  };

  useEffect(() => {
    initialize();
  }, [personEnumResults?.data, personInfoResult?.data]);

  useEffect(() => {
    const isDisabled =
      !isEmpty(hasCompanyNameError) || !isEmpty(hasPersonNameError);

    setSubmitButtonDisabled(isDisabled);
  }, [hasCompanyNameError, hasPersonNameError]);

  return (
    <GenericDrawer
      title={personId ? "Edit Person" : "New Person"}
      onClose={() => onClose()}
      isOpen={open}
    >
      {(personEnumResults.loading || personInfoResult?.loading) && open ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={"Submit"}
            submitButtonState={submitButtonState}
            isSubmitButtonDisabled={submitButtonDisabled}
            disableForm={formDisabled}
            title="Information"
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default PersonDrawer;
