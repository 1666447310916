import { DynamicFormInputType, FormInputTypes } from "../../DynamicForm";
import { isEmpty } from "../../utils/validationUtils";

export const initialValues = {
  planCurrency: "",
  policyCost: "",
  chargesPercentage: "",
  tPAFeesType: "",
  tPAFeesAmount: "",
  tPAFeesPercentage: "",
  tPAFeesApplicableOn: "",
  minTPA: "",
  maxTPA: "",
};

export const inputs: Record<string, DynamicFormInputType> = {
  planCurrency: {
    name: "planCurrency",
    title: "Currency",
    type: FormInputTypes.chips,
    placeholder: "Currency",
    value: !isEmpty(initialValues.planCurrency)
      ? initialValues.planCurrency
      : "",
    multiple: false,
    disabled: true,
    required: true,
  },
  policyCost: {
    name: "policyCost",
    title: "Policy Cost",
    type: FormInputTypes.formattedNumber,
    placeholder: "Policy Cost",
    value: initialValues.policyCost,
    required: true,
    disabled: false,
    minValue: 0,
  },
  chargesPercentage: {
    name: "chargesPercentage",
    title: "Charges Percentage",
    type: FormInputTypes.percentage,
    placeholder: "Charges Percentage",
    value: initialValues.chargesPercentage,
    required: true,
    disabled: false,
  },
  tPAFeesType: {
    name: "tPAFeesType",
    title: "TPA Fees Type",
    type: FormInputTypes.chips,
    placeholder: "TPA Fees Type",
    value: !isEmpty(initialValues.tPAFeesType) ? initialValues.tPAFeesType : "",
    multiple: false,
    disabled: false,
    required: true,
  },
  tPAFeesAmount: {
    name: "tPAFeesAmount",
    title: "TPA Fees Amount",
    type: FormInputTypes.formattedNumber,
    placeholder: "TPA Fees Amount",
    value: initialValues.tPAFeesAmount,
    required: true,
    disabled: false,
    minValue: 0,
  },
  tPAFeesPercentage: {
    name: "tPAFeesPercentage",
    title: "TPA Fees Percentage",
    type: FormInputTypes.percentage,
    placeholder: "TPA Fees Percentage",
    value: initialValues.tPAFeesPercentage,
    required: true,
    disabled: false,
  },
  tPAFeesApplicableOn: {
    name: "tPAFeesApplicableOn",
    title: "TPA Fees ApplicableOn",
    type: FormInputTypes.chips,
    placeholder: "TPA Fees ApplicableOn",
    value: !isEmpty(initialValues.tPAFeesApplicableOn)
      ? initialValues.tPAFeesApplicableOn
      : "",
    multiple: false,
    disabled: false,
    required: true,
  },
  minTPA: {
    name: "minTPA",
    title: "Min TPA",
    type: FormInputTypes.formattedNumber,
    placeholder: "Min TPA",
    value: initialValues.minTPA,
    required: true,
    disabled: false,
  },
  maxTPA: {
    name: "maxTPA",
    title: "Max TPA",
    type: FormInputTypes.formattedNumber,
    placeholder: "Max TPA",
    value: initialValues.maxTPA,
    required: true,
    disabled: false,
  },
};
