import _ from "lodash";
import { IAbstractRecord } from "../models";
import { isEmpty } from "./validationUtils";

const FILTER_KEY = 'userFilters';

export function getFilter(key = FILTER_KEY): Record<string, any> {
  const filterString = window.localStorage.getItem(key);
  if(filterString){
    return JSON.parse(filterString);
  }
  return {};
}

export function setFilter(filter: Record<string, any>, key = FILTER_KEY ): void {
  window.localStorage.setItem(key, JSON.stringify(filter));
}

export function clearFilter(key = FILTER_KEY): void {
  window.localStorage.removeItem(key);
}

export function prepareFilterValues(values: IAbstractRecord){

  let newValues = _.cloneDeep(values);
  Object.keys(newValues).map(key => {
    if(Array.isArray(newValues[key])){
      if(newValues[key].length === 0){
        delete newValues[key];
      }
    } else if(isEmpty(newValues[key])){
      delete newValues[key];
    } 
  });

  return newValues;
}
