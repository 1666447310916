import { ITravelPlanRatesDrawerInfo } from ".";
import { lookupListAsRecordObject } from "../../utils/graph-utils";

export function LookupToList(data: any) {
  const travelPlanRatesEnums: Record<string, Record<string, string>> = {};

  travelPlanRatesEnums["currencies"] = lookupListAsRecordObject(
    data?.Core?.lookups?.currencies
  );

  travelPlanRatesEnums["travelDestinations"] = lookupListAsRecordObject(
    data?.Core?.lookups?.travelDestinations
  );

  return travelPlanRatesEnums;
}

export function graphqlToTravelPlanRatesInfo(
  data: any
): ITravelPlanRatesDrawerInfo {
  const travelPlanRates =
    data?.PlanConfigManagement?.entities?.planSpecificTravel?.views
      ?.PlanConfigManagement_all?.properties;

  return {
    ageFrom: travelPlanRates?.AgeFrom,
    ageTo: travelPlanRates?.AgeTo,
    travelDurationFrom: travelPlanRates?.TravelDurationFrom,
    travelDurationTo: travelPlanRates?.TravelDurationTo,
    travelDestination: travelPlanRates?.TravelDestination?.Id,
    numberOfAdults: travelPlanRates?.NumberOfAdults,
    numberOfChildren: travelPlanRates?.NumberOfChildren,
    premium: travelPlanRates?.Premium,
  };
}
