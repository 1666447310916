import { gql } from '@apollo/client';

export function listQuery() {
  return gql`
    query GET_AGENTS(
      $currentPage: Int!
      $currentPageSize: Int!
      $agencyID: String
      $agentStatus: [Insurance_AgentStatuses]!
      $agentType: [Insurance_AgentTypes]!
      $agentAccesses: [Insurance_AgentAccesses]
    ) {
      Insurance {
        queries {
          listAgents(
            AgencyID: $agencyID
            AgentStatus: $agentStatus
            AgentType: $agentType
            AgentAccesses: $agentAccesses
            pagination: { pageNumber: $currentPage, pageSize: $currentPageSize }
          ) {
            paging {
              pageSize
              pageNumber
              totalCount
            }
            items {
              insurance_Agent_Id
              insurance_Agent_FirstName
              insurance_Agent_LastName
              insurance_Agent_AgentType
              insurance_Agent_AgentStatus
              insurance_Agent_createdOn
              insurance_Agent_modifiedOn
              insurance_Agent_AgenciesNames
            }
          }
        }
      }
    }
  `;
}

export function filterQuery() {
  return gql`
    query AGENTS_LISTING_LOVS {
      Insurance {
        queries {
          allAgencies {
            insurance_Agency_Id
            insurance_Agency_AgencyName
          }
        }
      }
      Insurance_AgentTypes: __type(name: "Insurance_AgentTypes") {
        name
        enumValues {
          name
        }
      }
      Insurance_AgentStatuses: __type(name: "Insurance_AgentStatuses") {
        name
        enumValues {
          name
        }
      }
      Insurance_AgentAccesses: __type(name: "Insurance_AgentAccesses") {
        name
        enumValues {
          name
        }
      }
    }
  `;
}
