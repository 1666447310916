import React from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import Logo from '../Logo';
import { makeStyles } from 'tss-react/mui';
import { MAIN_ONE_THEME } from '../../constants';

interface IIconTileProps {
  title: string;
  description: string;
  imgSrc: string;
  className?: string;
  route?: string;
  onClick: () => void;
}

const useStyles = makeStyles()(() => ({
  tileContainer: {
    display: 'flex',
    border: '1px solid #dddbda',
    borderRadius: '4px',
    cursor: 'pointer',
    height: '100px',
    '&&:hover': {
      borderColor: MAIN_ONE_THEME.palette.primary1.main,
    },
  },
  tileText: {
    borderLeft: '1px solid #dddbda',
    padding: '.5rem .75rem',
    overflow: 'hidden',
    background: MAIN_ONE_THEME.palette.secondary5.main,
    width: '100%',
    boxSizing: 'border-box',
  },
  tileTitle: {
    margin: '0 0 0 0',
    lineHeight: 'normal',
  },
  tileDescription: {
    marginTop: 5,
    fontFamily:'SourceSansPro-Regular',
    fontSize: MAIN_ONE_THEME.typography.small.sm3.fontSize,
  },
  appLink: {
    fontStyle: 'unset',
    textDecoration: 'none',
    color: 'unset',
    display: 'flex',
  },
}));

const IconDescriptionTile: React.FC<IIconTileProps> = ({
  title,
  description,
  imgSrc,
  className,
  route,
  onClick,
}) => {
  const { classes } = useStyles();

  function renderTile() {
    return (
      <>
        <Logo
          verticalPadding={20}
          horizontalPadding={20}
          src={imgSrc}
          alt={title}
          height={100}
          width={100}
        />
        <div className={classes.tileText}>
          <h4 className={classes.tileTitle}>{title}</h4>
          <p className={classes.tileDescription}>{description}</p>
        </div>
      </>
    );
  }

  function containerClasses(): string {
    return classes.tileContainer + (className ? ` ${className}` : '');
  }

  return route ? (
    <NavLink
      to={route}
      className={clsx(classes.appLink, containerClasses())}
      onClick={onClick}
    >
      {renderTile()}
    </NavLink>
  ) : (
    <button className={containerClasses()} onClick={onClick} type='button'>
      {renderTile()}
    </button>
  );
};

export default IconDescriptionTile;
