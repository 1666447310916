import { DynamicFormInputType, FormInputTypes } from "../../DynamicForm";
import { isEmpty } from "../../utils/validationUtils";

export const initialValues = {
  generalDetails: {
    planName: "",
    externalCode: "",
    arabicName: "",
    effectiveFrom: "",
    effectiveTo: "",
    abbreviation: "",
    planCurrency: "",
    maxNumberofInsured: "",
    maxNumberofBeneficiary: "",
    clauseEditable: "",
  },
  additionalDetails: {
    acalCategory: "",
    sOSService: "",
    replacementCarApplied: "",
    replacementCarValue: "",
    replacementCarDays: "",
  },
};

export const inputs: Record<string, DynamicFormInputType> = {
  planName: {
    name: "planName",
    title: "Plan Name",
    type: FormInputTypes.text,
    placeholder: "Plan Name",
    value: initialValues.generalDetails.planName,
    disabled: false,
    required: true,
  },
  externalCode: {
    name: "externalCode",
    title: "External Code",
    type: FormInputTypes.text,
    placeholder: "External Code",
    value: initialValues.generalDetails.externalCode,
    disabled: false,
    required: true,
  },
  arabicName: {
    name: "arabicName",
    title: "Arabic Name",
    type: FormInputTypes.text,
    placeholder: "Arabic Name",
    value: initialValues.generalDetails.arabicName,
    required: true,
    disabled: false,
  },
  abbreviation: {
    name: "abbreviation",
    title: "Abbreviation",
    type: FormInputTypes.text,
    placeholder: "Abbreviation",
    value: initialValues.generalDetails.abbreviation,
    disabled: false,
    required: true,
  },
  effectiveFrom: {
    name: "effectiveFrom",
    title: "Effective From",
    type: FormInputTypes.date,
    placeholder: "Effective From",
    value: initialValues.generalDetails.effectiveFrom,
    disabled: false,
    required: true,
  },
  effectiveTo: {
    name: "effectiveTo",
    title: "Effective To",
    type: FormInputTypes.date,
    placeholder: "Effective To",
    value: initialValues.generalDetails.effectiveTo,
    required: true,
    disabled: false,
  },
  planCurrency: {
    name: "planCurrency",
    title: "Plan Currency",
    type: FormInputTypes.chips,
    placeholder: "Plan Currency",
    value: !isEmpty(initialValues.generalDetails.planCurrency)
      ? initialValues.generalDetails.planCurrency
      : "",
    multiple: false,
    disabled: false,
    required: true,
  },
  maxNumberofInsured: {
    name: "maxNumberofInsured",
    title: "Max Number of Insured",
    type: FormInputTypes.number,
    placeholder: "Max Number of Insured",
    value: initialValues.generalDetails.maxNumberofInsured,
    disabled: false,
    required: true,
    minNumber: 0,
  },
  maxNumberofBeneficiary: {
    name: "maxNumberofBeneficiary",
    title: "Max Number of Beneficiary",
    type: FormInputTypes.number,
    placeholder: "Max Number of Beneficiary",
    value: initialValues.generalDetails.maxNumberofBeneficiary,
    disabled: false,
    required: true,
    minNumber: 0,
  },
  clauseEditable: {
    name: "clauseEditable",
    title: "Clause Editable",
    placeholder: "Clause Editable",
    type: FormInputTypes.switch,
    value: initialValues.generalDetails.clauseEditable,
    required: true,
  },

  acalCategory: {
    name: "acalCategory",
    title: "Acal Category",
    type: FormInputTypes.chips,
    placeholder: "Acal Category",
    value: !isEmpty(initialValues.additionalDetails.acalCategory)
      ? initialValues.additionalDetails.acalCategory
      : "",
    multiple: false,
    disabled: false,
    required: true,
  },
  sOSService: {
    name: "sOSService",
    title: "SOS Service",
    type: FormInputTypes.chips,
    placeholder: "SOS Service",
    value: !isEmpty(initialValues.additionalDetails.sOSService)
      ? initialValues.additionalDetails.sOSService
      : "",
    multiple: false,
    disabled: false,
    required: true,
  },
  replacementCarApplied: {
    name: "replacementCarApplied",
    title: "Replacement Car Applied",
    type: FormInputTypes.chips,
    placeholder: "Replacement Car Applied",
    value: !isEmpty(initialValues.additionalDetails.replacementCarApplied)
      ? initialValues.additionalDetails.replacementCarApplied
      : "",
    multiple: false,
    disabled: false,
    required: true,
  },
  replacementCarValue: {
    name: "replacementCarValue",
    title: "Replacement Car Value",
    type: FormInputTypes.currency,
    placeholder: "Replacement Car Value",
    value: initialValues.additionalDetails.replacementCarValue,
    disabled: false,
    required: true,
  },
  replacementCarDays: {
    name: "replacementCarDays",
    title: "Replacement Car Days",
    type: FormInputTypes.number,
    placeholder: "Replacement Car Days",
    value: initialValues.additionalDetails.replacementCarDays,
    disabled: false,
    required: true,
  },
};
