import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import EnhancedTableBody, {
  DisableSelectionType,
} from '../enhanced-table/EnhancedTableBody';
import Loader from '../Loader';
import { EnhancedTableHeader } from '../enhanced-table';
import { TableSortOrder, getSorting, rowSort } from '../../utils/table-utils';
import TableHead from '@mui/material/TableHead/TableHead';
import TableCell from '@mui/material/TableCell/TableCell';
import TableRow from '@mui/material/TableRow/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel/TableSortLabel';
import Table from '@mui/material/Table/Table';
import { makeStyles } from 'tss-react/mui';
import { MAIN_ONE_THEME } from '../../constants';
import { useAppSelector } from '../../redux/hooks';

export interface HeadRow {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
}

interface IEnhancedTableProps {
  headers: Record<string, EnhancedTableHeader>;
  defaultValueWhenNoData?: string;
}

const useStyles = makeStyles()(() => ({
  cellRoot: {
    color: MAIN_ONE_THEME.palette.primary2.main,
    fontSize: MAIN_ONE_THEME.typography.small.sm2.fontSize,
    lineHeight: '13px',
    height: 25,
    fontFamily: 'HelveticaNeue-Bold',
    border: 'none',
    padding: '4px 2px',
    borderBottom: '1px solid rgba(0,0,0,.06)',
  },
  firstCellRoot: {
    padding: '0px 6px 0px 1px !important',
  },
  paddedCellRoot: {
    padding: '4px 2px 4px 15px',
  },
  checkboxCellRoot: {
    width: 14,
    padding: '0 14px 0 8px',
    border: 'none',
    height: 34,
  },
  table: {
    minWidth: 300,
  },
  bodyRoot: {
    '& tr:nth-of-type(odd)': {
      backgroundColor: MAIN_ONE_THEME.palette.secondary4.main,
    },
  },
  border: {
    width: '100%',
    display: 'block',
    borderBottom: '1px solid rgba(0,0,0,.06)',
  },
  loader: {
    display: 'block',
    width: '40px',
    height: '40px',
    margin: '0 auto',
  },
  noDataCell: {
    color: MAIN_ONE_THEME.palette.primary1.main,
    border: 'none',
    height: '34px',
    fontSize: '14px',
    lineHeight: '12px',
  },
}));

const EnhancedToolTipTable: React.FC<
  IEnhancedTableProps & {
    rowId: string;
    tooltipFetch?: (rowId: string) => Promise<Record<string, any>>;
  }
> = ({ headers, rowId = '', tooltipFetch }) => {
  const tenant = useAppSelector((state) => state.tenant);
  const { classes } = useStyles();
  const [loader, setLoader] = useState<boolean>(false);
  const [rows, setRows] = useState<Record<string, any>>({});
  const [order, setOrder] = React.useState<TableSortOrder>('desc');
  const [orderBy, setOrderBy] = React.useState<string>('ActionDate');

  useEffect(() => {
    setLoader(true);
    tooltipFetch(rowId).then((rows) => {
      setRows(rows);
      setLoader(false);
    });
  }, []);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string,
  ) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    };

  const renderTableHeader = () => {
    return (
      <TableHead>
        <TableRow>
          {Object.keys(headers).map((headerKey, headerIndex) => (
            <TableCell
              classes={{
                root: clsx(classes.cellRoot),
              }}
              key={headerKey}
              padding="none"
            >
              <TableSortLabel
                active={false}
                direction={order}
                onClick={createSortHandler(headerKey)}
                disabled
              >
                {headers[headerKey].title}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  const renderTableBody = () => {
    if (rows) {
      return (
        <EnhancedTableBody
          tableClasses={{
            bodyRootClass: classes.bodyRoot,
            cellRootClass: classes.firstCellRoot,
          }}
          emptyRows={0}
          isSelected={() => false}
          disableSelection={DisableSelectionType.Hide}
          handleClick={() => {}}
          headers={headers}
          rows={rowSort(rows, getSorting(order, orderBy, headers))}
          currencySymbol={tenant.currencySymbol}
          dateFormat={tenant.dateFormat}
          phoneNumberPattern={tenant.phoneNumberPattern}
        />
      );
    }

    return <></>;
  };

  return (
    <Table className={classes.table} aria-labelledby="tableTitle" size="small">
      {renderTableHeader()}
      {loader ? (
        <TableRow>
          <TableCell
            rowSpan={2}
            colSpan={
              Object.keys(headers).length > 0
                ? Object.keys(headers).length + 1
                : 1
            }
            align="center"
            classes={{ root: classes.noDataCell }}
          >
            <Loader className={classes.loader} />
          </TableCell>
        </TableRow>
      ) : (
        renderTableBody()
      )}
    </Table>
  );
};

export default EnhancedToolTipTable;
