import { gql } from '@apollo/client';

export function listQuery() {
  return gql`
    query LIST_PLANSAPPLICATIONS(
      $currentPage: Int!
      $currentPageSize: Int!
      $SelectedLineId: [String]
      $SelectedSublineID: [String]
      $SelectedStatuses: [PlanConfigManagement_PlanStatuses]
      $FromCreatedOn: Date
      $ToCreatedOn: Date
    ) {
      PlanConfigManagement {
        queries {
          getPlanList(
            SelectedLineId: $SelectedLineId
            SelectedSublineID: $SelectedSublineID
            SelectedStatuses: $SelectedStatuses
            FromCreatedOn: $FromCreatedOn
            ToCreatedOn: $ToCreatedOn
            pagination: { pageNumber: $currentPage, pageSize: $currentPageSize }
          ) {
            paging {
              pageSize
              pageNumber
              totalCount
            }
            items {
              planConfigManagement_Plan_Id
              planConfigManagement_Plan_EffectiveFrom
              planConfigManagement_Plan_EffectiveTo
              planConfigManagement_Plan_PlanStatus
              planConfigManagement_Plan_createdOn
              planConfigManagement_Plan_modifiedOn
              planConfigManagement_Plan_ExternalCode
              planConfigManagement_Plan_Name
              planConfigManagement_Plan_NameArabic
              planConfigManagement_Plan_SublineID {
                id
                views {
                  PlanConfigManagement_all {
                    properties {
                      Name
                      Status
                    }
                  }
                }
              }
              planConfigManagement_Plan_LineID {
                id
                views {
                  PlanConfigManagement_all {
                    properties {
                      Name
                      Status
                    }
                  }
                }
              }
            }
          }
        }
      }
      PlanConfigManagement_PlanStatuses: __type(name: "PlanConfigManagement_PlanStatuses") {
        name
        enumValues {
          name
        }
      }
    }
  `;
}

export function filterQuery() {
  return gql`
    query PLANS_LISTING_LOVS($SelectedLineId: [String]) {
      PlanConfigManagement {
        queries {
          allLines {
            planConfigManagement_Line_Id
            planConfigManagement_Line_Name
            planConfigManagement_Line_Status
          }
          allSublines(SelectedLineId: $SelectedLineId) {
            planConfigManagement_Subline_Id
            planConfigManagement_Subline_Name
            planConfigManagement_Subline_Status
          }
        }
      }
      PlanConfigManagement_PlanStatuses: __type(name: "PlanConfigManagement_PlanStatuses") {
        name
        enumValues {
          name
        }
      }
    }
  `;
}
