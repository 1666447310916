import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
} from '../../components/enhanced-table';
import { IEntityInfoView } from '../../components/widgets/entity-info/EntityInfoFields';

export const steps = ['Inactive', 'Active'];

export const entityView: IEntityInfoView = {
  name: 'section1',
  title: 'Subline Details',
  iconUrl: '',
  sections: [
    {
      name: 'sublineDetailsSection1',
      title: '',
      properties: {
        lineName: {
          value: '',
          multiline: false,
          name: 'lineName',
          title: 'Line',
        },
        externalCode: {
          value: '',
          multiline: false,
          name: 'externalCode',
          title: 'External Code',
        },
        arabicName: {
          value: '',
          multiline: false,
          name: 'arabicName',
          title: 'Arabic Name',
        },
        // status: {
        //   value: '',
        //   multiline: false,
        //   name: 'status',
        //   title: 'Status',
        // },
      },
    },
    {
      name: 'sublineDetailsSection2',
      title: '',
      properties: {
        createdBy: {
          value: '',
          multiline: false,
          name: 'createdBy',
          title: 'Created By',
        },
        createdOn: {
          value: '',
          multiline: false,
          name: 'createdOn',
          title: 'Created On',
        },
        modifiedBy: {
          value: '',
          multiline: false,
          name: 'modifiedBy',
          title: 'Modified By',
        },
        modifiedOn: {
          value: '',
          multiline: false,
          name: 'modifiedOn',
          title: 'Modified On',
        },
      },
    },
  ],
  socialMedia: {
    facebook: '',
    linkedIn: '',
    twitter: '',
  },
};

export const planHeaders: Record<string, EnhancedTableHeader> = {
  planName: {
    name: 'planConfigManagement_Plan_Name',
    title: 'Plan Name',
    type: EnhancedTableHeaderType.Clickable,
    callback: () => {},
  },
  sublineName: {
    name: 'planConfigManagement_Plan_SublineName',
    title: 'Subline Name',
    type: EnhancedTableHeaderType.Text,
  },
  externalCode: {
    name: 'planConfigManagement_Plan_ExternalCode',
    title: 'External Code',
    type: EnhancedTableHeaderType.Text,
  },
  arabicName: {
    name: 'planConfigManagement_Plan_NameArabic',
    title: 'Arabic Name',
    type: EnhancedTableHeaderType.Text,
  },
  effectiveFrom: {
    name: 'planConfigManagement_Plan_EffectiveFrom',
    title: 'Effective From',
    type: EnhancedTableHeaderType.Date,
  },
  effectiveTo: {
    name: 'planConfigManagement_Plan_EffectiveTo',
    title: 'Effective To',
    type: EnhancedTableHeaderType.Date,
  },
  status: {
    name: 'planConfigManagement_Plan_PlanStatus',
    title: 'Status',
    type: EnhancedTableHeaderType.Text,
  },
  createdOn: {
    name: 'planConfigManagement_Plan_createdOn',
    title: 'Created On',
    type: EnhancedTableHeaderType.Date,
  },
  modifiedOn: {
    name: 'planConfigManagement_Plan_modifiedOn',
    title: 'Modified On',
    type: EnhancedTableHeaderType.Date,
  },
};