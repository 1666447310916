import { IMedicalPlanRatesDrawerInfo } from ".";
import {
  enumListAsRecordObject,
  lookupListAsRecordObject,
} from "../../utils/graph-utils";

export function LookupToList(data: any) {
  const medicalPlanRatesEnums: Record<string, Record<string, string>> = {};

  medicalPlanRatesEnums["currencies"] = lookupListAsRecordObject(
    data?.Core?.lookups?.currencies
  );

  medicalPlanRatesEnums["medicalPlanClasses"] = lookupListAsRecordObject(
    data?.Core?.lookups?.medicalPlanClasses
  );

  medicalPlanRatesEnums["coNSSFOptions"] = enumListAsRecordObject(
    data?.Core_CoNSSFOptions?.enumValues as {
      __typename: string;
      name: string;
    }[]
  );

  return medicalPlanRatesEnums;
}

export function graphqlToMedicalPlanRatesInfo(
  data: any
): IMedicalPlanRatesDrawerInfo {
  const medicalPlanRates =
    data?.PlanConfigManagement?.entities?.planSpecificMedical?.views
      ?.PlanConfigManagement_all?.properties;
  const PolicyCoverData =
    medicalPlanRates?.PolicyCoverID?.views?.PlanConfigManagement_all
      ?.properties;

  return {
    policyCover: medicalPlanRates?.PolicyCoverID?.id,
    policyCoverTitle: `${PolicyCoverData?.ExternalCode} - ${PolicyCoverData?.Name}`,
    nbOfAdherent: medicalPlanRates?.NbOfAdherent,
    class: medicalPlanRates?.Class?.Id,
    ageFrom: medicalPlanRates?.AgeFrom,
    ageTo: medicalPlanRates?.AgeTo,
    coNssf: medicalPlanRates?.CoNssf,
    netPremium: medicalPlanRates?.NetPremium,
    tpaFeesPercentage: `${Number(medicalPlanRates?.TPAFeesPercentage) * 100}`,
    minTPAFees: medicalPlanRates?.MinTPAFees,
    maxTPAFees: medicalPlanRates?.MaxTPAFees,
  };
}

export function extractPolicyCovers(data: any) {
  let policyCoverList: Record<string, string> = {};

  data.PlanConfigManagement.queries.allPolicyCoverList.forEach(
    (element: {
      planConfigManagement_PolicyCover_Id: string;
      planConfigManagement_PolicyCover_Name: string;
      planConfigManagement_PolicyCover_ExternalCode: string;
    }) => {
      policyCoverList[
        element.planConfigManagement_PolicyCover_Id
      ] = `${element.planConfigManagement_PolicyCover_ExternalCode} - ${element.planConfigManagement_PolicyCover_Name}`;
    }
  );

  return policyCoverList;
}
