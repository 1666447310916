import React, { ComponentProps, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { makeStyles } from 'tss-react/mui';
import EnhancedButton from '../../components/EnhancedButton';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import EnhancedInput from '../../components/enhanced-form/EnhancedInput';
import { MAIN_ONE_THEME, contentFontFamilyBold } from '../../constants';
import AuthLayout from '../../layouts/AuthBody';
import { useAppSelector } from '../../redux/hooks';
import DataService from '../../services/dataService';
import { getError } from '../../utils/graph-utils';
import { REGEX } from '../../utils/validationUtils';

const useStyles = makeStyles()((theme) => ({
  mainContainer: {
    backgroundColor: '#F8F8F8',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100vh',
    alignItems: 'center',
    width: '100%',
  },
  logoFig: {
    margin: '0 auto',
    lineHeight: '0',
  },
  boxContainer: {
    maxWidth: '430px',
    width: 'calc(100% - 16px)',
    margin: '46px auto 0',
    boxSizing: 'border-box',
    backgroundColor: '#fff',
    border: '1px solid rgba(0,0,0,.125)',
    boxShadow:
      '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    borderRadius: '4px',
  },
  greeting: {
    color: MAIN_ONE_THEME.palette.primary2.main,
    fontSize: '28px',
    lineHeight: '36px',
    textAlign: 'center',
    fontFamily: contentFontFamilyBold,
    letterSpacing: '0px',
    width: '100%',
    margin: '0',
    padding: '28px 0 0 0',
    fontWeight: 'normal',
  },
  submitBtn: {
    margin: '24px 0 12px 0',
    borderRadius: '8px !important',
    fontFamily: 'HelveticaNeue-Regular !important',
    fontSize: '13px !important',
    lineHeight: '15px !important',
  },
  formContainer: {
    padding: '24px 30px',
    width: '100%',
  },
  formError: {
    color: theme.palette.error.main,
    height: 'fit-content',
    marginTop: '8px',
    boxSizing: 'border-box',
    padding: '0px 12px 0px 12px',
    fontSize: '11px',
    textAlign: 'center',
  },
  inputLabel: {
    fontFamily: 'HelveticaNeue-Regular !important',
    fontSize: '13px',
    lineHeight: '15px',
  },
  footerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  backToSignIn: {
    color: MAIN_ONE_THEME.palette.primary5.main,
    border: 0,
    cursor: 'pointer',
    background: 'none',
    textDecoration: 'underline',
    marginTop: '66px',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

type ForgotPasswordFormProps = {
  onBackToSignInClicked: () => void;
};

type ForgotPasswordForm = {
  email: string;
};

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({
  onBackToSignInClicked,
}) => {
  const tenant = useAppSelector((state) => state.tenant);
  const loginMeta = tenant.login;
  const { classes } = useStyles();

  const form = useForm<ForgotPasswordForm>({
    defaultValues: {
      email: '',
    },
  });

  const { handleSubmit, formState } = form;

  const [btnState, setBtnState] =
    useState<ComponentProps<typeof EnhancedButton>['state']>();

  const onSubmit = async (formValues: ForgotPasswordForm) => {
    setBtnState('loading');
    const emailURI = encodeURIComponent(formValues.email);
    try {
      const response = await DataService.get(
        `/api/Accounts/resetpassword/${emailURI}`
      );
      if (response.ok) {
        setBtnState('success');
        toast.success(
          <ToastSuccessMessage>
            An email has been sent to your inbox!
          </ToastSuccessMessage>
        );
        setTimeout(() => {
          setBtnState(undefined);
          // navigate(`/login${window.location.search}`);
        }, 500);
      } else if (response.status === 401) {
        setBtnState(undefined);
        toast.error(
          <ToastErrorMessage>
            Access Denied. Please contact system administrator
          </ToastErrorMessage>
        );
        // navigate(`/login${window.location.search}`);
      } else {
        setBtnState(undefined);
        toast.error(<ToastErrorMessage>{getError()}</ToastErrorMessage>);
      }
    } catch {
      setBtnState(undefined);
      toast.error(<ToastErrorMessage>{getError()}</ToastErrorMessage>);
    }
  };

  return (
    <AuthLayout>
      <figure className={classes.logoFig}>
        <img
          height={117}
          width={125}
          src={tenant.cdnUrl + loginMeta.logoUrl}
          alt={'UFA logo'}
        />
      </figure>
      <div className={classes.boxContainer}>
        <h1 className={classes.greeting}>Forgot Password</h1>
        <div className={classes.formContainer}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormProvider {...form}>
              <Controller
                name={'email'}
                control={form.control}
                rules={{
                  required: 'Required',
                  pattern: {
                    value: REGEX.email,
                    message: 'Invalid format',
                  },
                }}
                render={({ field, fieldState }) => (
                  <EnhancedInput
                    key={field.name}
                    title={'Email'}
                    type='text'
                    placeholder='john@example.com'
                    error={fieldState.error?.message || ''}
                    disabled={formState.isSubmitting || formState.isLoading}
                    customStyles={{
                      labelStyles: classes.inputLabel,
                    }}
                    {...field}
                  />
                )}
              />
              <div className={classes.footerContainer}>
                <EnhancedButton
                  type='submit'
                  disabled={formState.isSubmitting || formState.isLoading}
                  variant='contained'
                  backgroundColor={MAIN_ONE_THEME.palette.primary5.main}
                  color='rgba(255, 255, 255, 1)'
                  style={{ width: '108px' }}
                  state={btnState}
                  className={classes.submitBtn}
                >
                  SUBMIT
                </EnhancedButton>
              </div>
            </FormProvider>
          </form>
          <button
            className={classes.backToSignIn}
            onClick={onBackToSignInClicked}
          >
            Back to Sign In
          </button>
        </div>
      </div>
    </AuthLayout>
  );
};

export default ForgotPasswordForm;
