import { gql } from "@apollo/client";

export function deactivateProvider() {
  return gql`
    mutation deactivateProvider($entityId: String!, $expiryDate: Date!) {
      configuration {
        entities {
          provider {
            configuration {
              deactivateProvider(entityId: $entityId, expiryDate: $expiryDate) {
                id
              }
            }
          }
        }
      }
    }
  `;
}
