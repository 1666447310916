import { IAbstractRecord } from '../../models';
import { IListingData } from '../../models/listing';

export function mapToListingData(data: any): IListingData<any> {
  const list: IAbstractRecord = {};

  data.PlanConfigManagement.queries.getPolicyCoverList.items.forEach(
    (obj: IGenericPolicyCoversTableData, i: number) => {
      const lineName =
        obj.planConfigManagement_PolicyCover_LineID?.views?.PlanConfigManagement_all?.properties?.Name;
      list[i] = {
        id: obj.planConfigManagement_PolicyCover_Id,
        policyCoverName: obj.planConfigManagement_PolicyCover_Name,
        line: lineName,
        externalCode: obj.planConfigManagement_PolicyCover_ExternalCode,
        arabicName: obj.planConfigManagement_PolicyCover_NameArabic,
        createdOn: obj.planConfigManagement_PolicyCover_createdOn,
        modifiedOn: obj.planConfigManagement_PolicyCover_modifiedOn,
        printingOrder: obj.planConfigManagement_PolicyCover_PrintingOrder,
        description: obj.planConfigManagement_PolicyCover_PolicyCoverDescription,
      };
    },
  );

  return {
    pagedItems: list,
    pageSize: data.PlanConfigManagement.queries.getPolicyCoverList?.paging?.pageSize,
    pageNumber: data.PlanConfigManagement.queries.getPolicyCoverList?.paging?.pageNumber,
    totalCount: data.PlanConfigManagement.queries.getPolicyCoverList?.paging?.totalCount,
  };
}

export function dataToLovs(data: any): Record<string, Record<string, string>> {
  let lovs: Record<string, Record<string, string>> = {
    line: {},
    status: {},
  };

  data?.PlanConfigManagement?.queries?.allLines?.forEach(
    (obj: { planConfigManagement_Line_Id: string; planConfigManagement_Line_Name: string }) => {
      lovs.line[obj.planConfigManagement_Line_Id] = obj.planConfigManagement_Line_Name;
    },
  );

  return lovs;
}
