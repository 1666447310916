import { FormInputTypes } from '../../DynamicForm';
import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
  IEnhancedTableMenuItem,
} from '../../components/enhanced-table';
import { IListingFilterWidgetSection } from '../../components/widgets/custom-listing-filter';
import { IAbstractRecord } from '../../models';

export const headers: Record<string, EnhancedTableHeader> = {
  line: {
    name: 'line',
    title: 'Line',
    type: EnhancedTableHeaderType.Clickable,
    callback: () => {},
  },
  lineCode: {
    name: 'lineCode',
    title: 'Line Code',
    type: EnhancedTableHeaderType.Clickable,
    callback: () => {},
  },
  fixedStamp: {
    name: 'fixedStamp',
    title: 'Fixed Stamp',
    type: EnhancedTableHeaderType.Text,
  },
  proportionalStamp: {
    name: 'proportionalStamp',
    title: 'Proportional Stamp',
    type: EnhancedTableHeaderType.Text,
  },
  municipalityTax: {
    name: 'municipalityTax',
    title: 'Municipality Tax',
    type: EnhancedTableHeaderType.Text,
  },
  taxOnCommission: {
    name: 'taxOnCommission',
    title: 'Tax On Commission',
    type: EnhancedTableHeaderType.Text,
  },
  reinsuranceTax: {
    name: 'reinsuranceTax',
    title: 'Reinsurance Tax',
    type: EnhancedTableHeaderType.Text,
  },
  effectiveFrom: {
    name: 'effectiveFrom',
    title: 'Effective From',
    type: EnhancedTableHeaderType.Date,
  },
  effectiveTo: {
    name: 'effectiveTo',
    title: 'Effective To',
    type: EnhancedTableHeaderType.Date,
  },
  createdOn: {
    name: 'createdOn',
    title: 'Created On',
    type: EnhancedTableHeaderType.Date,
  },
  modifiedOn: {
    name: 'modifiedOn',
    title: 'Modified On',
    type: EnhancedTableHeaderType.Date,
  },
};

export function filterSectionsContent(
  lovs: Record<string, Record<string, string>>,
  filterValues: IAbstractRecord,
): IListingFilterWidgetSection[] {
  const newFilterSections: IListingFilterWidgetSection[] = [
    {
      title: 'Filters',
      inputs: {
        line: {
          name: 'line',
          title: 'Line',
          type: FormInputTypes.chips,
          value: filterValues?.line || [],
          placeholder: 'Line',
          triggerUpdate: true,
          selectOptions: { ...lovs.line },
        },
        date: {
          name: 'date',
          title: 'Date',
          type: FormInputTypes.date,
          value: filterValues.date || null,
          placeholder: 'Date',
          triggerUpdate: true,
          allowDeleteValue: true,
          canClearDate: true,
        },
        createdOn: {
          name: 'createdOn',
          title: 'Created On',
          type: FormInputTypes.daterange,
          value: filterValues.createdOn || [],
          placeholder: 'Created On',
          triggerUpdate: true,
        },
      },
    },
  ];

  return newFilterSections;
}

export const actions: IEnhancedTableMenuItem[] = [
  {
    title: '+ New',
    onClick: () => {},
    isEntity: false,
    isBulk: false,
    iconUrl: '',
  },
  {
    title: 'Modify',
    onClick: () => {},
    isEntity: false,
    isBulk: false,
    iconUrl: '',
    isSecondary: true,
  },
];