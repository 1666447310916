import { makeStyles } from 'tss-react/mui';
import React from 'react';
import ProfileSection from './ProfileSection';
import NavMenu from './NavMenu';
import HeaderShell from '../components/HeaderShell';
import AppLauncher from '../components/app-launcher/AppLauncher';
import { IApplication } from '../Shell';
import { useAppSelector } from '../redux/hooks';
import { useLocation } from 'react-router-dom';

interface IHeaderProps {
  currentApp: string | undefined;
}

const useStyles = makeStyles()(() => ({
  rightContainer: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'flex-end',
    minHeight: '62px',
    maxHeight: '600px',
    margin: '0 20px 0 0',
    textAlign: 'center',
    width: '100%',
  },
}));

const Header: React.FC<
  IHeaderProps & {
    applications: Record<string, IApplication>;
  }
> = ({ currentApp,  applications }) => {
  const { classes } = useStyles();
  const tenant = useAppSelector((state) => state.tenant);
  const location = useLocation();

  return !location.pathname.includes('/privacypolicy') ? (
    <HeaderShell>
      <AppLauncher
        appLauncherUrl={tenant.cdnUrl + tenant.header.appLauncherUrl}
        applications={applications}
      />
      <NavMenu applications={applications} currentApp={currentApp} />
      <div className={classes.rightContainer}>
        {/* <Notifications /> */}
        <ProfileSection />
      </div>
    </HeaderShell>
  ) : (<HeaderShell></HeaderShell>);
};

export default Header;
