import React, { useEffect, useState } from "react";
import EnhancedStepper from "../../components/common/EnhancedStepper";
import EntityInfoWidget from "../../components/widgets/entity-info/EntityInfoWidget";
import {
  entityView,
  businessUserTabs,
  businessPartnersPlansHeaders,
} from "./content";
import { makeStyles } from "tss-react/mui";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  activateAssignedBPPlan,
  activateBusinessUser,
  deactivateAssignedBPPlan,
  deactivateBusinessUser,
  getAssignedBPListWithoutPagination,
  getBusinessPartnersPlansList,
} from "./queries";
import { useNavigate, useParams } from "react-router-dom";
import { IEntityInfoView } from "../../components/widgets/entity-info/EntityInfoFields";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";
import ToastErrorMessage from "../../components/ToastErrorMessage";

import {
  ISalesforceBusinessUserDetailsPageProps,
  ISalesforceBusinessUserDetails,
  initialValues,
} from ".";

import {
  DEFAULT_ERROR_TEXT,
  MAIN_ONE_THEME,
  contentFontFamilyBold,
} from "../../constants";

import dayjs from "dayjs";
import TabsLayout from "../../page-layout/tabs-layout/TabsLayout";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import EnhancedButton, {
  EnhancedButtonStatus,
} from "../../components/EnhancedButton";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import { getError } from "../../utils/graph-utils";
import { IConfirmation } from "../../redux/confirmation/types";
import { getBusinessUserDetailsInfo } from "./queries";
import { OpenConfirmationAction } from "../../redux/confirmation/actions";
import {
  entityToBusinessUserDetails,
  extractAssignedBP,
  extractDistinctLines,
  extractDistinctPlans,
  getBusinessUserStatus,
  mapToBusinessPartnersPlansListingData,
} from "./utils";
import { isEmpty } from "../../utils/validationUtils";
import { EnhancedDisplayType } from "../../components/enhanced-form/EnhancedDisplay";
import DynamicWidget from "../../components/widgets/dynamic-display/DynamicWidget";
import ShowForUser from "../../components/user/ShowForUser";
import { IDynamicDiplayView } from "../../components/widgets/dynamic-display";
import { capitalizeFirstLetter } from "../../utils/formatting-utils";
import SalesForceBusinessUserDrawer from "../salesforce-business-users-drawer/SalesForceBusinessUserDrawer";
import WidgetSection from "../../components/common/WidgetSection";
import EnhancedTable from "../../components/enhanced-table/EnhancedTable";
import { IListingData } from "../../models/listing";
import {
  IEnhancedTableMenuItem,
  IEnhanceTableHeaderClickable,
} from "../../components/enhanced-table";
import SalesforceAssignedBPPlanDrawer from "../salesforce-assign-business-partner-plan/SalesforceAssignedBPPlanDrawer";
import { Controller, useForm } from "react-hook-form";
import EnhancedChipInput from "../../components/enhanced-form/EnhancedChipInput";
import SalesForceBusinessUserAuthoritiesDrawer from "../salesforce-business-users-authorities-drawer/SalesForceBusinessUserAuthoritiesDrawer";

const useStyles = makeStyles()(() => ({
  stepper: {
    backgroundColor: "transparent",
    maxWidth: "90%",
    marginBottom: "20px",
    "& .MuiStepLabel-root .Mui-active, .MuiStepLabel-root .Mui-completed": {
      color: MAIN_ONE_THEME.palette.primary5.main,
    },

    "@media only screen and (min-width: 600px)": {
      maxWidth: "1300px",
    },
  },
  section: {
    display: "grid",
    "grid-template-columns": "32% 32% 32%",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  sectionFullRow: {
    display: "grid",
    "grid-template-columns": "100%",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  row: {
    flexWrap: "wrap",
    margin: "10px",
    alignItems: "center",
    display: "flex",
    gap: "10px",
    "& label": {
      flexGrow: "1",
      flexBasis: "0",
      minWidth: "0",
      maxWidth: "25%",
    },
  },
  thickSeperator: {
    height: 10,
    margin: "10px 0 10px 0",
    "grid-column-start": "1",
    "grid-column-end": "4",
  },
  field: {
    width: "100%",
    marginRight: "10px !important",
    backgroundColor: "white",
  },
  arField: {
    width: "100%",
    marginRight: "10px !important",
    direction: "rtl",
  },
  header: {
    fontFamily: contentFontFamilyBold,
    fontSize: MAIN_ONE_THEME.typography.regular.reg3.fontSize,
    color: MAIN_ONE_THEME.palette.primary2.main,
    "&:hover": {
      color: MAIN_ONE_THEME.palette.primary1.main,
    },
  },
  content: {
    padding: "0",
  },
  tabPanelStyle: {
    padding: "unset",
    marginTop: "20px",
  },
  dirtyChip: {
    margin: 0,
    backgroundColor: MAIN_ONE_THEME.palette.primary2.main,
    "& span": {
      color: "#ffffff",
    },
  },
  actionButton: {
    display: "inline-block",
    marginRight: "5px",
  },
  container: {
    display: "flex",
    justifyContent: "space-evenly",
    marginTop: "0px",
  },
}));

const SalesforceBusinessUserDetailsPage: React.FC<
  ISalesforceBusinessUserDetailsPageProps
> = () => {
  const { classes } = useStyles();
  const params = useParams();
  const navigate = useNavigate();

  const tenant = useAppSelector((state) => state.tenant);
  const user = useAppSelector((state) => state.user);
  const [activateButtonState, setActivateButtonState] =
    useState<EnhancedButtonStatus>();
  const [deactivateButtonState, setDeactivateButtonState] =
    useState<EnhancedButtonStatus>();

  const [updatedLeftSideInfo, setUpdatedLeftSideInfo] =
    useState<IEntityInfoView>(entityView);

  const [loadingState, setLoadingState] = useState<boolean>(true);
  const [businessUserInfoDrawerOpen, setBusinessUserInfoDrawerOpen] =
    useState<boolean>(false);
  const [businessPartnersPlansDrawerOpen, setBusinessPartnersPlansDrawerOpen] =
    useState<boolean>(false);
  const [
    businessUserAuthoritiesDrawerOpen,
    setBusinessUserAuthoritiesDrawerOpen,
  ] = useState<boolean>(false);

  const [businessUserDetailsInfo, setBusinessUserDetailsInfo] =
    useState<ISalesforceBusinessUserDetails>(initialValues);
  const [
    businessUserInformationViewConfig,
    setBusinessUserInformationViewConfig,
  ] = useState<IDynamicDiplayView>();
  const [
    businessUserAuthoritiesViewConfig,
    setBusinessUserAuthoritiesViewConfig,
  ] = useState<IDynamicDiplayView>();

  const [status, setStatus] = useState<string>();
  const [businessUserType, setBusinessUserType] = useState<string>();

  const [businessUserStatus, setBusinessUserStatus] = useState<string>("");
  const [selectedAssignedBPPlanIds, setSelectedAssignedBPPlanIds] = useState<
    string[]
  >([]);

  const [selectedAssignedBPPlanStatus, setSelectedAssignedBPPlanStatus] =
    useState<string[]>([]);
  const [
    selectedAssignedBPPlanBusinessPartnerLineStatus,
    setSelectedAssignedBPPlanBusinessPartnerLineStatus,
  ] = useState<string[]>([]);
  const [
    selectedAssignedBPPlanBusinessPartnerPlanStatus,
    setSelectedAssignedBPPlanBusinessPartnerPlanStatus,
  ] = useState<string[]>([]);

  const dispatch = useAppDispatch();
  const entityId = params.id;

  const [lovs, setLovs] =
    useState<Record<string, Record<string, string>>>(null);
  const [selectedBPFilter, setSelectedBPFilter] = useState<string>(null);
  const [transformedExtractedLinesObject, setTransformedExtractedLinesObject] =
    useState<Record<string, Record<string, string>>>(null);
  const [transformedExtractedPlansObject, setTransformedExtractedPlansObject] =
    useState<Record<string, Record<string, string>>>(null);
  const [selectedLineFilter, setSelectedLineFilter] = useState<string>(null);
  const [selectedPlanFilter, setSelectedPlanFilter] = useState<string>(null);

  const [
    getAssignedBPListWithoutPaginationLazy,
    assignedBPListWithoutPaginationQueryRes,
  ] = useLazyQuery(getAssignedBPListWithoutPagination(), {
    fetchPolicy: "no-cache",
    variables: { selectedBusinessUserID: entityId },
  });

  const [activateAssignedBPPlanAction] = useMutation(activateAssignedBPPlan(), {
    variables: {
      businessUserID: entityId,
      selectedAssignedBPPlanIds: selectedAssignedBPPlanIds,
    },
    errorPolicy: "all",
    refetchQueries: [getBusinessPartnersPlansList()],
    // onCompleted: () => {
    //   setBusinessPartnerStatus("");
    // },
  });
  const [deactivateAssignedBPPlanAction] = useMutation(
    deactivateAssignedBPPlan(),
    {
      variables: {
        businessUserID: entityId,
        selectedAssignedBPPlanIds: selectedAssignedBPPlanIds,
      },
      errorPolicy: "all",
      refetchQueries: [getBusinessPartnersPlansList()],
      // onCompleted: () => {
      //   setBusinessPartnerStatus("");
      // },
    }
  );

  const [getBusinessUserDetailsLazy, entityResult] = useLazyQuery(
    getBusinessUserDetailsInfo(),
    {
      variables: { id: entityId },
      errorPolicy: "all",
      fetchPolicy: "no-cache",
    }
  );

  const [activateAction] = useMutation(activateBusinessUser(), {
    variables: {
      entityId: entityId,
    },
    errorPolicy: "all",
    refetchQueries: [getBusinessUserDetailsInfo()],
    onCompleted: () => {
      setBusinessUserStatus("");
    },
  });
  const [deactivateAction] = useMutation(deactivateBusinessUser(), {
    variables: {
      entityId: entityId,
    },
    errorPolicy: "all",
    refetchQueries: [getBusinessUserDetailsInfo()],
    onCompleted: () => {
      setBusinessUserStatus("");
    },
  });

  const [selectedBusinessPartnersPlansItems, setBusinessPartnersPlansItems] =
    useState<number>(0);

  const [
    businessPartnersPlansTableLoadingState,
    setBusinessPartnersPlansTableLoadingState,
  ] = useState<boolean>(false);
  const [businessPartnersPlansTableData, setBusinessPartnersPlansTableData] =
    useState<IListingData<any>>({
      pagedItems: {},
      pageSize: 10,
      pageNumber: 0,
      totalCount: 0,
    });

  const isAdmin = user.userRoles.includes("Insurance-Admin");
  let businessPartnersPlansListingActions: IEnhancedTableMenuItem[] = [];
  
  const assignedBPPlanStatus_AllActive = selectedAssignedBPPlanStatus.every(
    (status) => status === "Active"
  );
  const assignedBPPlanStatus_SomeInactive = selectedAssignedBPPlanStatus.some(
    (status) => status === "Inactive"
  );
  const assignedBPPlanStatus_SomeActive = selectedAssignedBPPlanStatus.some(
    (status) => status === "Active"
  );
  const assignedBPPlanBusinessPartnerLineStatus_AllActive =
    selectedAssignedBPPlanBusinessPartnerLineStatus.every(
      (status) => status === "ACTIVE"
    );
  const assignedBPPlanBusinessPartnerPlanStatus_AllActive =
    selectedAssignedBPPlanBusinessPartnerPlanStatus.every(
      (status) => status === "ACTIVE"
    );

  businessPartnersPlansListingActions = [
    {
      title: "Assign Business Partner and Plan",
      onClick: () => {
        setBusinessPartnersPlansDrawerOpen(true);
      },
      isEntity: false,
      isBulk: false,
      iconUrl: tenant.cdnUrl + "/icons/add-primary-red.svg",
      hidden: !isAdmin,
      disabled: businessUserStatus === "inactive",
    },
    {
      title: "Activate Plan(s)",
      onClick: () => {},
      isEntity: false,
      isBulk: false,
      iconUrl: tenant.cdnUrl + "/icons/edit-secondary.svg",
      hidden: !isAdmin,
      disabled:
        selectedBusinessPartnersPlansItems === 0
          ? true
          : selectedBusinessPartnersPlansItems > 1
          ? !(
              (selectedBusinessPartnersPlansItems > 1 &&
                businessUserStatus === "active") ||
              (businessUserStatus === "new" &&
                assignedBPPlanStatus_AllActive === false &&
                assignedBPPlanStatus_SomeActive === false &&
                assignedBPPlanBusinessPartnerLineStatus_AllActive === true &&
                assignedBPPlanBusinessPartnerPlanStatus_AllActive === true)
            )
          : !(
              (selectedBusinessPartnersPlansItems > 0 &&
                businessUserStatus === "active") ||
              (businessUserStatus === "new" &&
                assignedBPPlanStatus_AllActive === false &&
                assignedBPPlanBusinessPartnerLineStatus_AllActive === true &&
                assignedBPPlanBusinessPartnerPlanStatus_AllActive === true)
            ),
    },
    {
      title: "Deactivate Plan(s)",
      onClick: () => {},
      isEntity: false,
      isBulk: false,
      iconUrl: tenant.cdnUrl + "/icons/edit-secondary.svg",
      hidden: !isAdmin,
      disabled:
        selectedBusinessPartnersPlansItems === 0
          ? true
          : selectedBusinessPartnersPlansItems > 1
          ? !(
              (selectedBusinessPartnersPlansItems > 1 &&
                businessUserStatus === "active") ||
              (businessUserStatus === "new" &&
                assignedBPPlanStatus_AllActive === true &&
                assignedBPPlanStatus_SomeInactive === false &&
                assignedBPPlanBusinessPartnerLineStatus_AllActive === true &&
                assignedBPPlanBusinessPartnerPlanStatus_AllActive === true)
            )
          : !(
              (selectedBusinessPartnersPlansItems > 0 &&
                businessUserStatus === "active") ||
              (businessUserStatus === "new" &&
                assignedBPPlanStatus_AllActive === true &&
                assignedBPPlanBusinessPartnerLineStatus_AllActive === true &&
                assignedBPPlanBusinessPartnerPlanStatus_AllActive === true)
            ),
    },
  ];
  businessPartnersPlansListingActions[1].onClick = () => {
    const confirmation: IConfirmation = {
      open: true,
      title: "Activate",
      message: `Are you sure you want to activate the selected assigned plan(s)?`,
      callback: async () => {
        activateAssignedBPPlanAction()
          .then((result) => {
            if (isEmpty(result.errors)) {
              toast.success(
                <ToastSuccessMessage>
                  {"Assigned plan(s) successfully activated."}
                </ToastSuccessMessage>
              );
            } else {
              toast.error(
                <ToastErrorMessage>{getError(result)}</ToastErrorMessage>
              );
            }
          })
          .finally(() => {});
      },
      submitButtonText: "Submit",
      cancelButtonText: "Cancel",
    };
    dispatch(OpenConfirmationAction(confirmation));
  };
  businessPartnersPlansListingActions[2].onClick = () => {
    const confirmation: IConfirmation = {
      open: true,
      title: "Deactivate",
      message: `Are you sure you want to deactivate the selected assigned plan(s)?`,
      callback: async () => {
        deactivateAssignedBPPlanAction()
          .then((result) => {
            if (isEmpty(result.errors)) {
              toast.success(
                <ToastSuccessMessage>
                  {"Assigned plan(s) successfully deactivated."}
                </ToastSuccessMessage>
              );
            } else {
              toast.error(
                <ToastErrorMessage>{getError(result)}</ToastErrorMessage>
              );
            }
          })
          .finally(() => {});
      },
      submitButtonText: "Submit",
      cancelButtonText: "Cancel",
    };
    dispatch(OpenConfirmationAction(confirmation));
  };

  const [getBusinessPartnersPlansListLazy, businessPartnersPlansListResult] =
    useLazyQuery(getBusinessPartnersPlansList(), {
      variables: {
        currentPage: 1,
        currentPageSize: businessPartnersPlansTableData.pageSize,
        selectedBusinessUserID: entityId,
        selectedBusinessPartnerID: selectedBPFilter,
        selectedLineID: selectedLineFilter,
        selectedPlanID: selectedPlanFilter,
      },
      errorPolicy: "all",
    });
  function handleBusinessPartnersPlansPageChange(page: number) {
    setBusinessPartnersPlansTableLoadingState(true);
    businessPartnersPlansListResult
      ?.fetchMore({
        variables: {
          currentPage: ++page,
          currentPageSize: businessPartnersPlansTableData.pageSize,
        },
      })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const newApplicationList = mapToBusinessPartnersPlansListingData(
            fetchMoreResult.data
          );
          const updatedTableData = {
            ...newApplicationList,
            pageNumber: --page,
          };
          setBusinessPartnersPlansTableData(updatedTableData);
        }
      })
      .finally(() => {
        setBusinessPartnersPlansTableLoadingState(false);
      });
  }
  function handleBusinessPartnersPlansRowsPerPageChange(
    numberOfRecordsPerPage: number
  ) {
    setBusinessPartnersPlansTableLoadingState(true);
    setBusinessPartnersPlansTableData({
      ...businessPartnersPlansTableData,
      pageNumber: 1,
    });
    businessPartnersPlansListResult
      ?.fetchMore({
        variables: {
          currentPage: 1,
          currentPageSize: numberOfRecordsPerPage,
        },
      })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const newApplicationList = mapToBusinessPartnersPlansListingData(
            fetchMoreResult.data
          );

          const updatedTableData = {
            ...newApplicationList,
            pageNumber: 0,
            pageSize: numberOfRecordsPerPage,
          };
          setBusinessPartnersPlansTableData(updatedTableData);
        }
      })
      .finally(() => {
        setBusinessPartnersPlansTableLoadingState(false);
      });
  }

  const {
    control,
    formState: { errors },
  } = useForm<ISalesforceBusinessUserDetails>({
    defaultValues: initialValues,
    shouldUnregister: true,
  });

  (
    businessPartnersPlansHeaders.businessPartner_PersonID as IEnhanceTableHeaderClickable
  ).callback = (payload: any) => {
    navigate(
      "/salesforce/persons/" + payload.columns.businessPartner_PersonID_ID
    );
  };
  (
    businessPartnersPlansHeaders.salesforceManagement_AssignedBPPlan_LineID_Name as IEnhanceTableHeaderClickable
  ).callback = (payload: any) => {
    navigate(
      "/plan/lines/" +
        payload.columns.salesforceManagement_AssignedBPPlan_LineID_ID
    );
  };
  (
    businessPartnersPlansHeaders.salesforceManagement_AssignedBPPlan_PlanID_Name as IEnhanceTableHeaderClickable
  ).callback = (payload: any) => {
    navigate(
      "/plan/plans/" +
        payload.columns.salesforceManagement_AssignedBPPlan_PlanID_ID
    );
  };

  useEffect(() => {
    try {
      if (!entityResult.loading) {
        setLoadingState(false);
      }

      if (entityResult?.data) {
        if (isEmpty(businessUserStatus)) {
          setBusinessUserStatus(
            entityResult?.data?.SalesforceManagement?.entities?.businessUser?.views?.SalesforceManagement_all?.properties?.Status.toLowerCase()
          );
        }

        if (isEmpty(businessUserType)) {
          setBusinessUserType(
            entityResult?.data?.SalesforceManagement?.entities?.businessUser?.views?.SalesforceManagement_all?.properties?.Type?.Title.toLowerCase()
          );
        }

        const businessUserDetails = entityToBusinessUserDetails(
          entityResult?.data
        );

        //get application section properties
        const applicationProperties = entityView.sections[0];
        applicationProperties.properties.name = {
          multiline: false,
          name: "name",
          title: "Full Name",
          value: businessUserDetails.name,
          type: EnhancedDisplayType.RelativeLink,
          tooltip: false,
          url: "/salesforce/persons/" + businessUserDetails.personId,
        };
        applicationProperties.properties.businessUserCode.value =
          businessUserDetails.businessUserCode;
        applicationProperties.properties.dateOfBirth.value =
          businessUserDetails.dateOfBirth
            ? dayjs(new Date(businessUserDetails.dateOfBirth)).format(
                "DD/MM/YYYY"
              )
            : null;
        applicationProperties.properties.email.value =
          businessUserDetails.email;
        applicationProperties.properties.phoneType.value =
          businessUserDetails.phoneType;
        applicationProperties.properties.phoneNumber.value =
          businessUserDetails.phoneNumber;
        applicationProperties.properties.taxable.value =
          businessUserDetails.taxable;

        //get the system propeties section
        const systemProperties = entityView.sections[1];
        systemProperties.properties.createdBy.value =
          businessUserDetails.createdBy;
        systemProperties.properties.createdOn.value = dayjs(
          new Date(businessUserDetails.createdOn)
        ).format("DD/MM/YYYY");
        systemProperties.properties.modifiedBy.value =
          businessUserDetails.modifiedBy;
        systemProperties.properties.modifiedOn.value = dayjs(
          new Date(businessUserDetails.modifiedOn)
        ).format("DD/MM/YYYY");

        const newLeftEntityView = {
          ...entityView,
          sections: [applicationProperties, systemProperties],
        };

        setBusinessUserDetailsInfo(businessUserDetails);
        setUpdatedLeftSideInfo(newLeftEntityView);

        const dynamicView: IDynamicDiplayView = {
          name: "Business User Information",
          sections: [
            {
              name: "businessUserDetails",
              title: "Business User Details",
              showActionButtons: true,
              properties: {
                relatedCompany: {
                  multiline: false,
                  value: businessUserDetails.relatedCompanyTitle,
                  name: "relatedCompany",
                  title: "Related Company",
                },
                type: {
                  multiline: false,
                  value: capitalizeFirstLetter(businessUserDetails.typeTitle),
                  name: "type",
                  title: "Type",
                },
                account: {
                  multiline: false,
                  value: businessUserDetails.account,
                  name: "account",
                  title: "Account",
                },
                access: {
                  multiline: false,
                  value: businessUserDetails.accessTitle,
                  name: "access",
                  title: "Access",
                },
              },
            },
          ],
        };
        setBusinessUserInformationViewConfig(dynamicView);

        const authoritiesView: IDynamicDiplayView = {
          name: "Business User Authorities",
          sections: [
            {
              name: "authoritiesDetails",
              title: "Authorities",
              showActionButtons: true,
              properties: {
                editableLineCharges: {
                  multiline: false,
                  value: businessUserDetails.editableLineChargesTitle,
                  name: "editableLineCharges",
                  title: "Editable Line Charges",
                },
                allowPolicyEndDateEdits: {
                  multiline: false,
                  value: businessUserDetails.allowPolicyEndDateEdits
                    ? "Yes"
                    : "No",
                  name: "allowPolicyEndDateEdits",
                  title: "Allow Policy End Date Edits",
                },
                allowPlanCoverEdits: {
                  multiline: false,
                  value: businessUserDetails.allowPlanCoverEdits ? "Yes" : "No",
                  name: "allowPlanCoverEdits",
                  title: "Allow Plan Cover Details Edits",
                },
                allowClauseEdits: {
                  multiline: false,
                  value: businessUserDetails.allowClauseEdits ? "Yes" : "No",
                  name: "allowClauseEdits",
                  title: "Allow Clause Edits",
                },
              },
            },
          ],
        };
        setBusinessUserAuthoritiesViewConfig(authoritiesView);

        const newStatus =
          entityResult?.data?.SalesforceManagement?.entities?.businessUser?.views?.SalesforceManagement_all?.properties?.Status.toLowerCase();
        setStatus(newStatus);
      }
    } catch (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  }, [entityResult?.data]);

  useEffect(() => {
    getBusinessUserDetailsLazy();
    getBusinessPartnersPlansListLazy();
  }, []);

  useEffect(() => {
    if (businessPartnersPlansListResult?.loading) setLoadingState(true);
    if (businessPartnersPlansListResult?.error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
    if (businessPartnersPlansListResult?.data) {
      const businessPartnersPlanTableData =
        mapToBusinessPartnersPlansListingData(
          businessPartnersPlansListResult?.data
        );

      const extractedLines = extractDistinctLines(
        businessPartnersPlansListResult?.data
      );
      const extractedLinesPagedItemsObject = extractedLines.pagedItems;
      const extractedLinesPagedItemsArray = Object.keys(
        extractedLinesPagedItemsObject
      ).map((key) => extractedLinesPagedItemsObject[key]);
      setTransformedExtractedLinesObject(
        extractedLinesPagedItemsArray.reduce((acc, item) => {
          acc[item.salesforceManagement_AssignedBPPlan_LineID_ID] =
            item.salesforceManagement_AssignedBPPlan_LineID_Name;
          return acc;
        }, {})
      );

      const extractedPlans = extractDistinctPlans(
        businessPartnersPlansListResult?.data
      );
      const extractedPlansPagedItemsObject = extractedPlans.pagedItems;
      const extractedPlansPagedItemsArray = Object.keys(
        extractedPlansPagedItemsObject
      ).map((key) => extractedPlansPagedItemsObject[key]);
      setTransformedExtractedPlansObject(
        extractedPlansPagedItemsArray.reduce((acc, item) => {
          acc[item.salesforceManagement_AssignedBPPlan_PlanID_ID] =
            item.salesforceManagement_AssignedBPPlan_PlanID_Name;
          return acc;
        }, {})
      );

      setBusinessPartnersPlansTableData({
        ...businessPartnersPlanTableData,
        pageNumber: 0,
      });
    }
    setLoadingState(false);
  }, [
    businessPartnersPlansListResult?.loading,
    businessPartnersPlansListResult?.error,
    businessPartnersPlansListResult?.data,
  ]);

  useEffect(() => {
    if (isEmpty(assignedBPListWithoutPaginationQueryRes.data)) {
      getAssignedBPListWithoutPaginationLazy();
    }

    if (assignedBPListWithoutPaginationQueryRes.error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }

    if (assignedBPListWithoutPaginationQueryRes?.data) {
      const extractedAssignedBP = extractAssignedBP(
        assignedBPListWithoutPaginationQueryRes.data
      );
      setLovs(extractedAssignedBP);
    }
  }, [
    assignedBPListWithoutPaginationQueryRes.error,
    assignedBPListWithoutPaginationQueryRes.data,
  ]);

  const renderMainChildren = () => {
    const isBusinessUserActive = status === "active";
    const isBusinessUserNew = status === "new";
    const steps = isBusinessUserNew
      ? ["New", "Active"]
      : ["New", isBusinessUserActive ? "Active" : "Inactive"];

    return (
      <>
        <div style={{ marginTop: "20px" }}>
          <EnhancedStepper
            activeStep={getBusinessUserStatus(steps, status)}
            steps={steps}
            className={classes.stepper}
            showStepperButton
            buttonTitle={isBusinessUserActive ? "Deactivate" : "Activate"}
            buttonState={
              isBusinessUserActive ? activateButtonState : deactivateButtonState
            }
            buttonOnClick={() => {
              if (isBusinessUserActive) {
                const confirmation: IConfirmation = {
                  open: true,
                  title: "Deactivate",
                  message: `Are you sure you want to deactivate this business user?`,
                  callback: async () => {
                    setDeactivateButtonState("loading");
                    deactivateAction()
                      .then((result) => {
                        if (isEmpty(result.errors)) {
                          setDeactivateButtonState("success");
                          toast.success(
                            <ToastSuccessMessage>
                              {"Business user successfully deactivated."}
                            </ToastSuccessMessage>
                          );
                        } else {
                          setDeactivateButtonState(undefined);
                          toast.error(
                            <ToastErrorMessage>
                              {getError(result)}
                            </ToastErrorMessage>
                          );
                        }
                      })
                      .finally(() => {
                        setTimeout(() => {
                          setDeactivateButtonState(undefined);
                        }, 1000);
                      });
                  },
                  submitButtonText: "Yes",
                  cancelButtonText: "No",
                };
                dispatch(OpenConfirmationAction(confirmation));
              } else {
                const confirmation: IConfirmation = {
                  open: true,
                  title: "Activate",
                  message: `Are you sure you want to activate this business user?`,
                  callback: async () => {
                    setActivateButtonState("loading");
                    activateAction()
                      .then((result) => {
                        if (isEmpty(result.errors)) {
                          setActivateButtonState("success");
                          toast.success(
                            <ToastSuccessMessage>
                              {"Business User successfully activated."}
                            </ToastSuccessMessage>
                          );
                        } else {
                          setActivateButtonState(undefined);
                          toast.error(
                            <ToastErrorMessage>
                              {getError(result)}
                            </ToastErrorMessage>
                          );
                        }
                      })
                      .finally(() => {
                        setTimeout(() => {
                          setActivateButtonState(undefined);
                        }, 1000);
                      });
                  },
                  submitButtonText: "Yes",
                  cancelButtonText: "No",
                };
                dispatch(OpenConfirmationAction(confirmation));
              }
            }}
          />
        </div>
      </>
    );
  };

  const renderLeftSection = () => {
    return (
      <>
        <EntityInfoWidget
          background="rgb(193, 0, 0)"
          classification={{ classifications: {}, value: "" }}
          progress={100}
          supportsClassification={true}
          supportsProgressBar={true}
          title={businessUserDetailsInfo.name}
          view={updatedLeftSideInfo}
        ></EntityInfoWidget>
      </>
    );
  };

  //#region tabs
  const tabs = () => {
    businessUserTabs.tabs[0].widgets[0].children = (
      <>
        {businessUserInformationViewConfig && (
          <>
            <DynamicWidget
              hasFourFields={true}
              view={businessUserInformationViewConfig}
              actions={
                <ShowForUser allowedRoles={["Insurance-Admin"]}>
                  {status != "inactive" && (
                    <div>
                      <EnhancedButton
                        type="button"
                        backgroundColor={MAIN_ONE_THEME.palette.primary1.main}
                        color="#FFF"
                        onClick={() => setBusinessUserInfoDrawerOpen(true)}
                        className={classes.actionButton}
                      >
                        Modify
                      </EnhancedButton>
                    </div>
                  )}
                </ShowForUser>
              }
            />
          </>
        )}
        {businessUserAuthoritiesViewConfig && (
          <div
            style={{
              marginTop: "20px",
              padding: "-23px 0 0 0 !important",
            }}
          >
            <DynamicWidget
              hasFourFields={true}
              view={businessUserAuthoritiesViewConfig}
              actions={
                <ShowForUser allowedRoles={["Insurance-Admin"]}>
                  {status != "inactive" && (
                    <div>
                      <EnhancedButton
                        type="button"
                        backgroundColor={MAIN_ONE_THEME.palette.primary1.main}
                        color="#FFF"
                        onClick={() =>
                          setBusinessUserAuthoritiesDrawerOpen(true)
                        }
                        className={classes.actionButton}
                      >
                        Modify
                      </EnhancedButton>
                    </div>
                  )}
                </ShowForUser>
              }
            />
          </div>
        )}
      </>
    );

    businessUserTabs.tabs[1].widgets[0].children = (
      <>
        {
          <>
            <WidgetSection style={{ margin: "-23px 0 0 0" }}>
              <form>
                {businessPartnersPlansListResult?.loading ? (
                  <div></div>
                ) : (
                  <div style={{ marginTop: "20px", padding: "0" }}>
                    <EnhancedTable
                      title={"Business Partners and Plans"}
                      inlineTitle={"Business Partners and Plans"}
                      lineNameController={
                        <>
                          <div className={classes.container}>
                            <Controller
                              name={"businessPartnerName"}
                              control={control}
                              rules={{}}
                              key={"businessPartnerName"}
                              render={({
                                field: { onChange, onBlur, value },
                              }) => (
                                <EnhancedChipInput
                                  name={"businessPartnerName"}
                                  title=""
                                  value={selectedBPFilter}
                                  placeholder="Filter by Business Partner"
                                  onChange={(newValue) => {
                                    onChange(newValue);
                                    setSelectedBPFilter(newValue);
                                  }}
                                  onBlur={(e) => {
                                    onBlur();
                                  }}
                                  selectOptions={lovs?.bp}
                                  error={errors?.businessPartnerName?.message}
                                  style={{ width: "20%", marginTop: "30px" }}
                                  hasSpecificStyle={true}
                                  material
                                  canClearSingleValueSelection={false}
                                  isInlineButton={true}
                                />
                              )}
                            />
                            <Controller
                              name={"businessPartnerName"}
                              control={control}
                              rules={{}}
                              key={"businessPartnerName"}
                              render={({
                                field: { onChange, onBlur, value },
                              }) => (
                                <EnhancedChipInput
                                  name={"businessPartnerName"}
                                  title=""
                                  value={selectedLineFilter}
                                  placeholder="Filter by Line"
                                  onChange={(newValue) => {
                                    onChange(newValue);
                                    setSelectedLineFilter(newValue);
                                  }}
                                  onBlur={(e) => {
                                    onBlur();
                                  }}
                                  selectOptions={
                                    transformedExtractedLinesObject
                                  }
                                  error={errors?.businessPartnerName?.message}
                                  style={{ width: "20%", marginTop: "30px" }}
                                  hasSpecificStyle={true}
                                  material
                                  canClearSingleValueSelection={false}
                                  isInlineButton={true}
                                />
                              )}
                            />
                            <Controller
                              name={"businessPartnerName"}
                              control={control}
                              rules={{}}
                              key={"businessPartnerName"}
                              render={({
                                field: { onChange, onBlur, value },
                              }) => (
                                <EnhancedChipInput
                                  name={"businessPartnerName"}
                                  title=""
                                  value={selectedPlanFilter}
                                  placeholder="Filter by Plan"
                                  onChange={(newValue) => {
                                    onChange(newValue);
                                    setSelectedPlanFilter(newValue);
                                  }}
                                  onBlur={(e) => {
                                    onBlur();
                                  }}
                                  selectOptions={
                                    transformedExtractedPlansObject
                                  }
                                  error={errors?.businessPartnerName?.message}
                                  style={{ width: "20%", marginTop: "30px" }}
                                  hasSpecificStyle={true}
                                  material
                                  canClearSingleValueSelection={false}
                                  isInlineButton={true}
                                />
                              )}
                            />
                          </div>
                        </>
                      }
                      name="count"
                      entityName={"AssignedBPPlan"}
                      entityIdColumnName={
                        "salesforceManagement_AssignedBPPlan_Id"
                      }
                      showCellFullData={true}
                      isTitlePascalCase={true}
                      orderByAscendingByDefault
                      defaultOrderByColumn="name"
                      inline={true}
                      data={businessPartnersPlansTableData}
                      headers={businessPartnersPlansHeaders}
                      handlePageChange={(page: number) =>
                        handleBusinessPartnersPlansPageChange(page)
                      }
                      handleRowsPerPageChange={(page: number) =>
                        handleBusinessPartnersPlansRowsPerPageChange(page)
                      }
                      currentPage={businessPartnersPlansTableData.pageNumber}
                      hideToolbar={false}
                      usePagination
                      secondaryActions={true}
                      specificDesign={true}
                      disableSelection={false}
                      actions={businessPartnersPlansListingActions}
                      loader={businessPartnersPlansTableLoadingState}
                      showTablePagination={true}
                      onSelectionChange={(newDetailedSelection) => {
                        setBusinessPartnersPlansItems(
                          newDetailedSelection.length
                        );
                        if (newDetailedSelection.length > 0) {
                          const itemIds = [];

                          const assignedBPPlanStatus = [];
                          const assignedBPPlanBusinessPartnerLineStatus = [];
                          const assignedBPPlanBusinessPartnerPlanStatus = [];

                          for (const item of newDetailedSelection) {
                            itemIds.push(item.id);
                            assignedBPPlanStatus.push(
                              item.salesforceManagement_AssignedBPPlan_Status
                            );
                            assignedBPPlanBusinessPartnerLineStatus.push(
                              item.salesforceManagement_AssignedBPPlan_BusinessPartnerLineID_Status
                            );
                            assignedBPPlanBusinessPartnerPlanStatus.push(
                              item.salesforceManagement_AssignedBPPlan_BusinessPartnerPlanID_Status
                            );
                          }
                          setSelectedAssignedBPPlanIds(itemIds);
                          setSelectedAssignedBPPlanStatus(assignedBPPlanStatus);
                          setSelectedAssignedBPPlanBusinessPartnerLineStatus(
                            assignedBPPlanBusinessPartnerLineStatus
                          );
                          setSelectedAssignedBPPlanBusinessPartnerPlanStatus(
                            assignedBPPlanBusinessPartnerPlanStatus
                          );
                        } else {
                          setSelectedAssignedBPPlanIds([]);
                          setSelectedAssignedBPPlanStatus([]);
                          setSelectedAssignedBPPlanBusinessPartnerLineStatus(
                            []
                          );
                          setSelectedAssignedBPPlanBusinessPartnerPlanStatus(
                            []
                          );
                        }
                      }}
                    />
                    {businessPartnersPlansDrawerOpen && (
                      <SalesforceAssignedBPPlanDrawer
                        open={businessPartnersPlansDrawerOpen}
                        onClose={() =>
                          setBusinessPartnersPlansDrawerOpen(false)
                        }
                        businessUserId={entityId}
                        onSuccess={() => {
                          getAssignedBPListWithoutPaginationLazy();
                          handleBusinessPartnersPlansPageChange(0);
                        }}
                      />
                    )}
                  </div>
                )}
              </form>
            </WidgetSection>
          </>
        }
      </>
    );

    return businessUserTabs;
  };
  //#endregion tabs

  return loadingState ? (
    <Loader />
  ) : (
    <>
      <div>
        {businessUserInfoDrawerOpen && (
          <SalesForceBusinessUserDrawer
            open={businessUserInfoDrawerOpen}
            onClose={() => setBusinessUserInfoDrawerOpen(false)}
            onSuccess={() => {
              getBusinessUserDetailsLazy();
            }}
            businessUserId={params.id}
            salesForceBusinessUserDetailsInfo={entityToBusinessUserDetails(
              entityResult?.data
            )}
          />
        )}
        {businessUserAuthoritiesDrawerOpen && (
          <SalesForceBusinessUserAuthoritiesDrawer
            open={businessUserAuthoritiesDrawerOpen}
            onClose={() => setBusinessUserAuthoritiesDrawerOpen(false)}
            onSuccess={() => {
              getBusinessUserDetailsLazy();
            }}
            businessUserId={params.id}
            authoritiesDetailsInfo={entityToBusinessUserDetails(
              entityResult?.data
            )}
          />
        )}
      </div>
      <TabsLayout
        name="policyPageDetails"
        layout={tabs()}
        theme={tenant.theme}
        leftChildren={renderLeftSection()}
        mainChildren={renderMainChildren()}
        cdnUrl={tenant.cdnUrl}
        userInfo={user["info"]}
        tabPanelClassName={classes.tabPanelStyle}
        firstTabAsActiveTab={true}
      />
    </>
  );
};

export default SalesforceBusinessUserDetailsPage;
