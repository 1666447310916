import { FormInputTypes } from '../../DynamicForm';
import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
  IEnhancedTableMenuItem,
} from '../../components/enhanced-table';
import { IListingFilterWidgetSection } from '../../components/widgets/custom-listing-filter';
import { IAbstractRecord } from '../../models';
import { enumListAsRecordObject } from '../../utils/graph-utils';

export const headers: Record<string, EnhancedTableHeader> = {
  insurance_PolicyNumberRange_RangeFrom: {
    name: 'insurance_PolicyNumberRange_RangeFrom',
    title: 'Policy Number From',
    type: EnhancedTableHeaderType.Text,
  },
  insurance_PolicyNumberRange_RangeTo: {
    name: 'insurance_PolicyNumberRange_RangeTo',
    title: 'Policy Number To',
    type: EnhancedTableHeaderType.Text,
  },
  insurance_PolicyNumberRange_PolicyNumberReached: {
    name: 'insurance_PolicyNumberRange_PolicyNumberReached',
    title: 'Policy Number Reached',
    type: EnhancedTableHeaderType.Text,
  },
  insurance_PolicyNumberRange_RangeStatus: {
    name: 'insurance_PolicyNumberRange_RangeStatus',
    title: 'Status',
    type: EnhancedTableHeaderType.Text,
  },
  insurance_PolicyNumberRange_createdOn: {
    name: 'insurance_PolicyNumberRange_createdOn',
    title: 'Created on',
    type: EnhancedTableHeaderType.Date,
  },
  insurance_PolicyNumberRange_modifiedOn: {
    name: 'insurance_PolicyNumberRange_modifiedOn',
    title: 'Modified on',
    type: EnhancedTableHeaderType.Date,
  },
};

export function filterSectionsContent(
  data: any,
  filterValues: IAbstractRecord,
): IListingFilterWidgetSection[] {
  const newFilterSections: IListingFilterWidgetSection[] = [
    {
      title: 'Filters',
      inputs: {
        status: {
          name: 'status',
          title: 'Status',
          type: FormInputTypes.chips,
          value: filterValues?.status || [],
          placeholder: 'Status',
          triggerUpdate: true,
          selectOptions: enumListAsRecordObject(
            data?.Insurance_RangeStatuses?.enumValues,
          ),
        },
      },
    },
  ];
  return newFilterSections;
}

export const actions: IEnhancedTableMenuItem[] = [
  {
    title: '+ New',
    onClick: () => {},
    isEntity: false,
    isBulk: false,
    iconUrl: '',
  },
];
