import { DynamicFormInputType, FormInputTypes } from "../../DynamicForm";
import { isEmpty } from "../../utils/validationUtils";

export const initialValues = {
  policyCover: "",
  currency: "",
  sumInsured: "",
  premiumType: "VALUE",
  premiumPercentage: "",
  premium: "",
  additiveSumInsured: false,
  main: false,
  mandatory: false,
  editable: false,
  excessOnClaimType: "PERCENTAGE",
  excessOnClaim: "",
  excessOnClaimPercentage: "",
  excessOnClaimDays: "",
  clauseReference: "",
};

export const inputs: Record<string, DynamicFormInputType> = {
  policyCover: {
    name: "policyCover",
    title: "Policy Cover",
    type: FormInputTypes.chips,
    placeholder: "Policy Cover",
    value: !isEmpty(initialValues.policyCover) ? initialValues.policyCover : "",
    multiple: false,
    disabled: false,
    required: true,
    hasPagination: true,
    itemsPerPage: 15,
    order: 1,
  },
  currency: {
    name: "currency",
    title: "Currency",
    type: FormInputTypes.text,
    placeholder: "Currency",
    value: !isEmpty(initialValues.currency)
      ? initialValues.currency
      : "",
    disabled: true,
    required: true,
    hidden: false,
    order: 2,
  },
  // // currency: {
  // //   name: "currency",
  // //   title: "Currency",
  // //   type: FormInputTypes.chips,
  // //   placeholder: "Currency",
  // //   value: !isEmpty(initialValues.currency)
  // //     ? initialValues.currency
  // //     : "",
  // //   disabled: true,
  // //   required: false,
  // //   hidden: true,
  // //   multiple: false,
  // //   order: 2,
  // // },
  premiumType: {
    name: "premiumType",
    title: "Premium Type",
    placeholder: "Premium Type",
    type: FormInputTypes.chips,
    value: !isEmpty(initialValues.premiumType) ? initialValues.premiumType : "",
    hidden: true,
    required: false,
    multiple: false,
    order: 3,
  },
  premiumPercentage: {
    name: "premiumPercentage",
    title: "Premium Percentage",
    placeholder: "Premium Percentage",
    type: FormInputTypes.percentage,
    value: initialValues.premiumPercentage,
    required: false,
    hidden: true,
    order: 4,
  },
  premium: {
    name: "premium",
    title: "Premium",
    placeholder: "Premium",
    type: FormInputTypes.formattedNumber,
    value: initialValues.premium,
    required: false,
    hidden: true,
    minValue: 0,
    order: 5,
  },
  sumInsured: {
    name: "sumInsured",
    title: "Sum Insured",
    placeholder: "Sum Insured",
    type: FormInputTypes.formattedNumber,
    value: initialValues.sumInsured,
    required: true,
    minValue: 0,
    order: 6,
  },
  additiveSumInsured: {
    name: "additiveSumInsured",
    title: "Additive Sum Insured",
    placeholder: "Additive Sum Insured",
    type: FormInputTypes.switch,
    value: initialValues.additiveSumInsured,
    required: true,
    order: 7,
  },
  main: {
    name: "main",
    title: "Main",
    placeholder: "Main",
    type: FormInputTypes.switch,
    value: initialValues.main,
    required: true,
    order: 8,
  },
  mandatory: {
    name: "mandatory",
    title: "Mandatory",
    placeholder: "Mandatory",
    type: FormInputTypes.switch,
    value: initialValues.mandatory,
    required: true,
    order: 9,
  },
  editable: {
    name: "editable",
    title: "Editable",
    placeholder: "Editable",
    type: FormInputTypes.switch,
    value: initialValues.editable,
    required: true,
    order: 10,
  },
  excessOnClaimType: {
    name: "excessOnClaimType",
    title: "Excess On Claim Type",
    placeholder: "Excess On Claim Type",
    type: FormInputTypes.chips,
    value: !isEmpty(initialValues.excessOnClaimType)
      ? initialValues.excessOnClaimType
      : "",
    required: true,
    multiple: false,
    order: 11,
  },
  excessOnClaim: {
    name: "excessOnClaim",
    title: "Excess On Claim",
    placeholder: "Excess On Claim",
    type: FormInputTypes.formattedNumber,
    value: initialValues.excessOnClaim,
    required: false,
    hidden: true,
    minValue: 0,
    order: 12,
  },
  excessOnClaimPercentage: {
    name: "excessOnClaimPercentage",
    title: "Excess On Claim Percentage",
    placeholder: "Excess On Claim (Percentage)",
    type: FormInputTypes.percentage,
    value: initialValues.excessOnClaimPercentage,
    required: false,
    hidden: true,
    order: 13,
  },
  excessOnClaimDays: {
    name: "excessOnClaimDays",
    title: "Excess On Claim Days",
    placeholder: "Excess On Claim (Days)",
    type: FormInputTypes.number,
    value: initialValues.excessOnClaimDays,
    required: false,
    hidden: true,
    minNumber: 0,
    order: 14,
  },
  clauseReference: {
    name: "clauseReference",
    title: "Clause Reference",
    placeholder: "Clause Reference",
    type: FormInputTypes.chips,
    value: initialValues.clauseReference,
    required: false,
    hasPagination: true,
    itemsPerPage: 15,
    multiple: false,
    order: 15,
  },
};
