import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { IEntityClassificationData } from '.';
import { contentFontFamilyRegular } from '../../../constants';

const useStyles = makeStyles()(() => ({
  classification: {
    minHeight: 24,
    width: 70,
    margin: 'auto',
    font: 'normal 12px/14px',
    fontFamily: contentFontFamilyRegular,
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
  },
}));

const EntityInfoClassification: React.FC<IEntityClassificationData> = ({
  classifications,
  value,
}) => {
  const { classes } = useStyles();

  return classifications && classifications[value] ? (
    <div
      className={classes.classification}
      style={{ backgroundColor: classifications[value].color }}
    >
      {classifications[value].text}
    </div>
  ) : (
    <></>
  );
};

export default EntityInfoClassification;
