import { makeStyles } from 'tss-react/mui';
import React from 'react';
import clsx from 'clsx';
import EnhancedInputsWrapper from './EnhancedInputsWrapper';
import { MAIN_ONE_THEME, contentFontFamilyRegular } from '../../constants';

export interface IEnhancedTextAreaProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  type: string;
  name: string;
  title: string;
  error?: string;
  rows?: number;
  value: string | number;
  onBlur: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const useStyles = makeStyles()(() => ({
  labelWrapper: {
    display: 'block',
    textAlign: 'left',
    fontSize: MAIN_ONE_THEME.typography.regular.reg3.fontSize,
    lineHeight: '15px',
  },
  label: {
    display: 'block',
    color: MAIN_ONE_THEME.palette.primary1.main,
    textAlign: 'left',
    fontFamily: contentFontFamilyRegular,
  },
  input: {
    fontSize: MAIN_ONE_THEME.typography.regular.reg2.fontSize,
    lineHeight: '15px',
    width: '100%',
    padding: '10px 15px 5px 15px',
    margin: '0 auto',
    backgroundColor: MAIN_ONE_THEME.palette.secondary4.main,
    border: `1px solid ${MAIN_ONE_THEME.palette.secondary3.main}`,
    boxSizing: 'border-box',
    borderRadius: '5px',
    fontFamily: contentFontFamilyRegular,
    resize: 'none',
    '&:focus-visible': {
      outline: 'none',
    },
    '&::placeholder': {
      color: 'rgba(0, 0, 0, 0.20)',
      fontStyle: 'italic',
    },
  },
  inputError: {
    borderColor: MAIN_ONE_THEME.palette.error.main,
    outlineColor: MAIN_ONE_THEME.palette.error.main,
    fontFamily: contentFontFamilyRegular,
  },
}));

const EnhancedTextArea: React.FC<IEnhancedTextAreaProps> = ({
  rows = 6,
  className,
  error,
  name,
  title,
  value,
  placeholder,
  type,
  style,
  disabled,
  onChange,
  onBlur,
}) => {
  const { classes } = useStyles();
  return (
    <>
      <EnhancedInputsWrapper
        title={title}
        error={error}
        name={name}
        className={className}
        style={style}
      >
        <textarea
          className={clsx(classes.input, {
            [classes.inputError]: error,
          })}
          rows={rows}
          title={title}
          id={name}
          name={name}
          aria-invalid={!!error}
          aria-describedby={`errorMsg-${name}`}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          placeholder={placeholder}
        />
      </EnhancedInputsWrapper>
    </>
  );
};

export default EnhancedTextArea;
