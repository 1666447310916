import React from 'react';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';
import HeaderContainer from './HeaderContainer';
import Logo from './Logo';
import { IEnhancedCommonProps } from '.';
import { HEADER_HEIGHT } from '../constants';
import { useAppSelector } from '../redux/hooks';

const useStyles = makeStyles()(() => ({
  header: {
    left: 0,
    position: 'fixed' as 'fixed',
    right: 0,
    top: 0,
    zIndex: 20,
    textAlign: 'center',
  },
}));

const HeaderShell: React.FC<
  IEnhancedCommonProps & {
    children?: React.ReactNode;
  }
> = ({ className, style, children }) => {
  const { classes } = useStyles();
  const tenant = useAppSelector((state) => state.tenant);

  return (
    <HeaderContainer
      className={clsx(classes.header, className)}
      style={style}
      height={HEADER_HEIGHT}
      background={tenant.backgroundTemplate}
    >
      <Logo
        alt={tenant.header.logoAlt}
        src={tenant.cdnUrl + tenant.header.logoUrl}
        height={79}
        width={82}
        horizontalPadding={67}
        verticalPadding={17}
      />
      {children}
    </HeaderContainer>
  );
};

export default HeaderShell;
