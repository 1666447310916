import React, { useEffect, useState } from "react";
import GenericDrawer from "../../components/common/generic-drawer/GenericDrawer";
import DynamicForm from "../../DynamicForm/DynamicForm";
import { inputs } from "./content";
import { cloneDeep, isEmpty } from "lodash";
import {
  DynamicFormInputType,
  IFormSelectDynamicProps,
} from "../../DynamicForm";
import { toast } from "react-toastify";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { DEFAULT_ERROR_TEXT } from "../../constants";
import { useMutation, useQuery } from "@apollo/client";
import {
  getAdditionalInformationEnums,
  getAdditionalInfoPersonDetailsInfo,
  modifyAdditionalInfo,
} from "./queries";
import { graphqlEntityToAdditionalInfo, LookupToList } from "./utils";

import Loader from "../../components/Loader";
import { normaliseDynamicValues } from "../../utils/dynamic-utils";
import { EnhancedButtonStatus } from "../../components/EnhancedButton";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import { useNavigate } from "react-router-dom";
import { getError } from "../../utils/graph-utils";
import { getPersonDetailsInfo } from "../person-details-page/queries";

const AdditionalInformationDrawer: React.FC<
  IPersonAdditionalInformationDrawerProps
> = ({ personId, personType, open, onSuccess, onClose }) => {
  let additionalInfoResult: any;

  const navigate = useNavigate();

  // const [hasPersonNameError, setHasPersonNameError] = useState('');
  // const [hasCompanyNameError, setHasCompanyNameError] = useState('');
  // let personNameError = '';
  // let companyNameError = '';

  // let [submitButtonDisabled, setSubmitButtonDisabled] =
  //   useState<boolean>(false);

  let newAdditionalInformationEnums: Record<
    string,
    Record<string, string>
  > = {};
  let additionalInfoEntity: IPersonAdditionalInformationInfo = {
    personId: "",
    gender: "",
    maritalStatus: "",
    fatherName: "",
    motherName: "",
    countryOfBirth: "",
    cityOfBirth: "",
    nationalityID: "",
    passportNumber: "",
    secondNationality: "",
  };

  let additionalInformationEnumResults = useQuery(
    getAdditionalInformationEnums(),
    {
      fetchPolicy: "no-cache",
    }
  );

  // const [checkPersonExistsLazy] = useLazyQuery(checkPersonExists(), {});
  // const [checkCompanyExistsLazy] = useLazyQuery(checkCompanyExists(), {});

  const [additionalInfoAction] = useMutation(modifyAdditionalInfo(), {
    refetchQueries: [getPersonDetailsInfo()],
  });

  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();

  let updatedInputs: Record<string, DynamicFormInputType> = inputs;
  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);

  if (personId) {
    additionalInfoResult = useQuery(getAdditionalInfoPersonDetailsInfo(), {
      variables: { id: personId },
    });
  }

  const initialize = () => {
    try {
      updatedInputs = cloneDeep(inputsForm);

      validateFields(personType);

      if (additionalInformationEnumResults.data) {
        newAdditionalInformationEnums = LookupToList(
          additionalInformationEnumResults.data
        );

        (updatedInputs.gender as IFormSelectDynamicProps).selectOptions =
          newAdditionalInformationEnums["genders"];

        (updatedInputs.maritalStatus as IFormSelectDynamicProps).selectOptions =
          newAdditionalInformationEnums["maritalStatuses"];

        (
          updatedInputs.countryOfBirth as IFormSelectDynamicProps
        ).selectOptions = newAdditionalInformationEnums["countries"];

        (
          updatedInputs.secondNationality as IFormSelectDynamicProps
        ).selectOptions = newAdditionalInformationEnums["nationalities"];

        if (additionalInfoResult?.data) {
          additionalInfoEntity = graphqlEntityToAdditionalInfo(
            additionalInfoResult?.data
          );
        }

        if (additionalInfoEntity) {
          updatedInputs.gender.value = additionalInfoEntity.gender;
          updatedInputs.maritalStatus.value =
            additionalInfoEntity.maritalStatus;
          updatedInputs.fatherName.value = additionalInfoEntity.fatherName;
          updatedInputs.motherName.value = additionalInfoEntity.motherName;
          updatedInputs.countryOfBirth.value =
            additionalInfoEntity.countryOfBirth;
          updatedInputs.cityOfBirth.value = additionalInfoEntity.cityOfBirth;
          updatedInputs.nationalityID.value =
            additionalInfoEntity.nationalityID;
          updatedInputs.passportNumber.value =
            additionalInfoEntity.passportNumber;
          updatedInputs.secondNationality.value =
            additionalInfoEntity.secondNationality;
        }
      }

      setInputsForm(updatedInputs);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputs, values);

    setFormDisabled(true);
    setSubmitButtonState("loading");

    try {
      let variables = {
        additionalInfoInputs: {
          gender: data.gender || null,
          maritalStatus: data.maritalStatus || null,
          fatherName: data.fatherName || null,
          motherName: data.motherName || null,
          countryOfBirth: data.countryOfBirth || null,
          cityOfBirth: data.cityOfBirth || null,
          nationalityID: data.nationalityID || null,
          passportNumber: data.passportNumber || null,
          nationalityTwo: data.secondNationality || null,
        },
      };
      additionalInfoAction({
        variables: personId ? { ...variables, entityId: personId } : variables,
        errorPolicy: "all",
      }).then((res) => {
        if (isEmpty(res.errors)) {
          toast.success(
            <ToastSuccessMessage>
              {personId
                ? "Additional Information successfully updated"
                : "Additional Information successfully updated"}
            </ToastSuccessMessage>
          );

          setTimeout(() => {
            setSubmitButtonState("success");
            onSuccess();
            onClose();
            if (!personId) {
              const newPersonId =
                res.data.salesforceManagement.actions.createPerson.id;
              navigate(`/salesforce/persons/` + newPersonId);
            }
          }, 500);
        } else {
          setSubmitButtonState(undefined);
          toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
        }
      });
    } catch {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setFormDisabled(false);
    }
  };

  const validateFields = (option: any) => {
    if (!isEmpty(option)) {
      const selectedType = option.toLowerCase();
      const isPerson = selectedType === 'person';

      updatedInputs.gender.hidden = !isPerson;
      updatedInputs.gender.disabled = !isPerson;
      
      updatedInputs.maritalStatus.hidden = !isPerson;
      updatedInputs.maritalStatus.disabled = !isPerson;

      updatedInputs.fatherName.hidden = !isPerson;
      updatedInputs.fatherName.disabled = !isPerson;

      updatedInputs.motherName.hidden = !isPerson;
      updatedInputs.motherName.disabled = !isPerson;

      updatedInputs.countryOfBirth.hidden = !isPerson;
      updatedInputs.countryOfBirth.disabled = !isPerson;

      updatedInputs.cityOfBirth.hidden = !isPerson;
      updatedInputs.cityOfBirth.disabled = !isPerson;

      updatedInputs.passportNumber.hidden = !isPerson;
      updatedInputs.passportNumber.disabled = !isPerson;
      
      updatedInputs.secondNationality.hidden = !isPerson;
      updatedInputs.secondNationality.disabled = !isPerson;
    }
  };

  useEffect(() => {
    initialize();
  }, [additionalInformationEnumResults?.data, additionalInfoResult?.data]);

  // useEffect(() => {
  //   const isDisabled =
  //     !isEmpty(hasCompanyNameError) || !isEmpty(hasPersonNameError);

  //   setSubmitButtonDisabled(isDisabled);
  // }, [hasCompanyNameError, hasPersonNameError]);

  return (
    <GenericDrawer
      title={"Modify Additional Information"}
      onClose={() => onClose()}
      isOpen={open}
    >
      {(additionalInformationEnumResults.loading ||
        additionalInfoResult?.loading) &&
      open ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={"Submit"}
            submitButtonState={submitButtonState}
            //isSubmitButtonDisabled={submitButtonDisabled}
            disableForm={formDisabled}
            title="Information"
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default AdditionalInformationDrawer;
