
export interface IColumn {
  backgroundColor: string;
  // TODO Tell backend to fix spelling of collapsible
  collapsable: boolean;
  collapsed?: boolean;
  width: number;
}

export const initialColumnData: IColumn = {
  backgroundColor: '',
  collapsable: false,
  collapsed: true,
  width: 0,
};

export enum ColumnDirection {
  left = 'left',
  right = 'right',
}

export const COLLAPSED_COLUMN_WIDTH = 0.25;
