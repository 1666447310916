import { gql } from '@apollo/client';

export function listQuery() {
  return gql`
    query GET_USERS(
      $currentPage: Int!
      $currentPageSize: Int!
      $userRole: [Insurance_UserRoles!]
      $userStatus: [Insurance_UserStatuses!]
    ) {
      Insurance {
        actions {
          able_To_CreateUser
          able_To_UpdateUser
        }
        queries {
          listUsers(
            roles: $userRole
            statuses: $userStatus
            pagination: { pageNumber: $currentPage, pageSize: $currentPageSize }
          ) {
            paging {
              pageSize
              pageNumber
              totalCount
            }
            items {
              system_User_Id
              system_User_firstName
              system_User_lastName
              system_User_email
              system_User_mobile
              system_User_roles
              system_User_status
            }
          }
        }
      }
      Insurance_UserRoles: __type(name: "Insurance_UserRoles") {
        name
        enumValues {
          name
        }
      }
      Insurance_UserStatuses: __type(name: "Insurance_UserStatuses") {
        name
        enumValues {
          name
        }
      }
    }
  `;
}
