import React, { useEffect, useState } from "react";
import DynamicForm from "../../DynamicForm/DynamicForm";
import {
  DynamicFormInputType,
  IFormSelectDynamicProps,
  IFormTextDynamicProps,
} from "../../DynamicForm";
import { toast } from "react-toastify";
import { EnhancedButtonStatus } from "../../components/common/EnhancedButton";
import { normaliseDynamicValues } from "../../utils/dynamic-utils";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { inputs } from "./content";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  checkClauseExists,
  createClause,
  getClauseEnums,
  getClauseInfo,
  updateClause,
} from "./queries";
import { LookupToList, graphqlEntityToClauseInfo } from "./utils";
import Loader from "../../components/Loader";
import { cloneDeep } from "lodash";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import { isEmpty } from "../../utils/validationUtils";
import { DEFAULT_ERROR_TEXT } from "../../constants";
import { getError } from "../../utils/graph-utils";
import GenericPopUp from "../../components/custom/GenericPopUp";

const ClausePopUp: React.FC<IClausePopUpProps> = ({
  clauseId,
  lineId,
  lineName,
  isLineFieldEnabled = false,
  open,
  onSuccess,
  onClose,
}) => {
  let clauseInfoResult: any;

  const [hasClauseNameError, setHasClauseNameError] = useState("");
  const [hasClauseExternalCodeError, setHasClauseExternalCodeError] =
    useState("");
  let clauseNameError = "";
  let clauseExternalCodeError = "";
  let [selectedLineId, setSelectedLineId] = useState("");
  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);

  let updatedInputs: any = null;

  let [submitButtonDisabled, setSubmitButtonDisabled] =
    useState<boolean>(false);

  const clauseEnumResults = useQuery(getClauseEnums(), {
    fetchPolicy: "no-cache",
  });

  const [clauseAction] = useMutation(
    clauseId ? updateClause() : createClause()
  );

  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();

  if (clauseId) {
    clauseInfoResult = useQuery(getClauseInfo(), {
      variables: { id: clauseId },
    });
  }

  function handleLineSelection(selectedOption: any) {
    selectedLineId = selectedOption;
    validateClauseNameExistsOnLineChange(
      selectedLineId,
      updatedInputs.clauseName?.value
    );
    validateClauseExternalCodeExistsOnLineChange(
      selectedLineId,
      updatedInputs.clauseExternalCode?.value
    );
  }

  const [checkClauseExistsLazy] = useLazyQuery(checkClauseExists(), {});

  let initialize = () => {
    try {
      if (clauseId) {
        updatedInputs = { ...inputsForm };
      } else {
        updatedInputs = cloneDeep(inputsForm);
      }

      if (isLineFieldEnabled) {
        updatedInputs.lineName.disabled = false;
      } else {
        updatedInputs.lineName.disabled = true;
      }
      updatedInputs.lineName.value = lineId;
      if (!clauseId) {
        updatedInputs.clauseName.value = "";
        updatedInputs.clauseExternalCode.value = "";
        updatedInputs.clauseDescription.value = "";
        updatedInputs.clauseStatus.value = "INACTIVE";
      }

      if (clauseEnumResults.data) {
        const newClauseEnums = LookupToList(clauseEnumResults.data);

        if (!lineId) {
          updatedInputs.lineName.value = lineId;
        }
        (updatedInputs.lineName as IFormSelectDynamicProps).selectOptions =
          newClauseEnums["lines"];

        (updatedInputs.lineName as IFormSelectDynamicProps).onSelect = (
          option
        ) => {
          setSelectedLineId(option);
          handleLineSelection(option);
        };

        (updatedInputs.clauseStatus as IFormSelectDynamicProps).selectOptions =
          newClauseEnums["PlanConfigManagement_ClauseStatuses"];
        if (!clauseId) {
          updatedInputs.clauseStatus.value = "INACTIVE";
        }

        if (clauseInfoResult?.data) {
          const clauseEntity = graphqlEntityToClauseInfo(
            clauseInfoResult.data,
            lineId,
            lineName
          );
          if (clauseEntity) {
            updatedInputs.lineName.value = clauseEntity.lineId;
            updatedInputs.clauseName.value = clauseEntity.clauseName;
            updatedInputs.clauseExternalCode.value =
              clauseEntity.clauseExternalCode;
            updatedInputs.clauseDescription.value =
              clauseEntity.clauseDescription;
            updatedInputs.clauseStatus.value = clauseEntity.clauseStatus;
            setSelectedLineId(clauseEntity.lineId);
          }
        }
      }

      (updatedInputs.clauseName as IFormTextDynamicProps).onChange = (
        event
      ) => {
        const newName = event.target.value;
        setInputsForm(updatedInputs);
        (updatedInputs.clauseName as IFormTextDynamicProps).value = newName;
        if (!isEmpty(newName)) {
          validateClauseExistsOnNameChange(selectedLineId, newName);
        }
      };

      (updatedInputs.clauseExternalCode as IFormTextDynamicProps).onChange = (
        event
      ) => {
        const newExternalCode = event.target.value;
        setInputsForm(updatedInputs);
        (updatedInputs.clauseExternalCode as IFormTextDynamicProps).value =
          newExternalCode;
        if (!isEmpty(newExternalCode)) {
          validateClauseExistsOnExternalCodeChange(
            selectedLineId,
            newExternalCode
          );
        }
      };

      setInputsForm(updatedInputs);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    if (clauseNameError === "" && clauseExternalCodeError === "") {
      const [data] = normaliseDynamicValues(inputs, values);
      setFormDisabled(true);
      setSubmitButtonState("loading");
      try {
        let variables = {
          clauseInputs: {
            lineID: lineId || selectedLineId,
            externalCode: data.clauseExternalCode,
            name: data.clauseName,
            description: data.clauseDescription,
            status: data.clauseStatus,
          },
        };

        clauseAction({
          variables: clauseId
            ? { ...variables, entityId: clauseId }
            : variables,
          errorPolicy: "all",
        }).then((res) => {
          if (isEmpty(res.errors)) {
            toast.success(
              <ToastSuccessMessage>
                {clauseId
                  ? "Clause successfully updated"
                  : "Clause successfully created"}
              </ToastSuccessMessage>
            );

            setTimeout(() => {
              setSubmitButtonState("success");
              onSuccess();
              onClose();
            }, 500);
          } else {
            setSubmitButtonState(undefined);
            toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
          }
        });
      } catch {
        setSubmitButtonState(undefined);
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
      } finally {
        setFormDisabled(false);
      }
    } else {
      setFormDisabled(true);
      setSubmitButtonState(undefined);
    }
  };

  const validateClauseExistsOnNameChange = (
    selectedLineId?: string,
    clauseName?: string
  ) => {
    if (!isEmpty(lineId || selectedLineId) && !isEmpty(clauseName)) {
      checkClauseExistsLazy({
        variables: {
          lineId: lineId || selectedLineId,
          name: clauseName,
          clauseId: clauseId,
          externalCode: "",
        },
      }).then((response) => {
        setInputsForm((currentInputsForm) => {
          const updatedInputs = { ...currentInputsForm };

          if (
            response.data.PlanConfigManagement?.queries?.checkClauseExists
              .length > 0
          ) {
            clauseNameError = "Clause Name already exists under the same Line";
            setHasClauseNameError(
              "Clause Name already exists under the same Line"
            );
            (updatedInputs.clauseName as IFormTextDynamicProps).error =
              clauseNameError;
          } else {
            clauseNameError = "";
            setHasClauseNameError("");
            (updatedInputs.clauseName as IFormTextDynamicProps).error =
              clauseNameError;
          }

          const newClauseEnums = LookupToList(clauseEnumResults.data);

          (
            updatedInputs.clauseStatus as IFormSelectDynamicProps
          ).selectOptions =
            newClauseEnums["PlanConfigManagement_ClauseStatuses"];
          updatedInputs.clauseStatus.value =
            currentInputsForm.clauseStatus.value;

          return updatedInputs;
        });
      });
    }
  };
  const validateClauseNameExistsOnLineChange = (
    selectedLineId?: string,
    clauseName?: string
  ) => {
    if (!isEmpty(selectedLineId) && !isEmpty(clauseName)) {
      checkClauseExistsLazy({
        variables: {
          lineId: selectedLineId,
          name: clauseName,
          clauseId: clauseId,
          externalCode: "",
        },
      }).then((response) => {
        setInputsForm((currentInputsForm) => {
          const updatedInputs = { ...currentInputsForm };
          updatedInputs.lineName.value = selectedLineId;

          if (
            response.data.PlanConfigManagement?.queries?.checkClauseExists
              .length > 0
          ) {
            clauseNameError = "Clause Name already exists under the same Line";
            setHasClauseNameError(
              "Clause Name already exists under the same Line"
            );
            (updatedInputs.clauseName as IFormTextDynamicProps).error =
              clauseNameError;
          } else {
            clauseNameError = "";
            setHasClauseNameError("");
            (updatedInputs.clauseName as IFormTextDynamicProps).error =
              clauseNameError;
          }

          const newClauseEnums = LookupToList(clauseEnumResults.data);

          (
            updatedInputs.clauseStatus as IFormSelectDynamicProps
          ).selectOptions =
            newClauseEnums["PlanConfigManagement_ClauseStatuses"];
          updatedInputs.clauseStatus.value =
            currentInputsForm.clauseStatus.value;

          return updatedInputs;
        });
      });
    }
  };

  const validateClauseExistsOnExternalCodeChange = (
    selectedLineId?: string,
    clauseExternalCode?: string
  ) => {
    if (!isEmpty(lineId || selectedLineId) && !isEmpty(clauseExternalCode)) {
      checkClauseExistsLazy({
        variables: {
          lineId: lineId || selectedLineId,
          externalCode: clauseExternalCode,
          clauseId: clauseId || "",
          name: "",
        },
      }).then((response) => {
        setInputsForm((currentInputsForm) => {
          const updatedInputs = { ...currentInputsForm };
          if (
            response?.data?.PlanConfigManagement?.queries?.checkClauseExists
              .length > 0
          ) {
            clauseExternalCodeError =
              "Clause External Code already exists under the same Line";
            setHasClauseExternalCodeError(
              "Clause External Code already exists under the same Line"
            );
            (updatedInputs.clauseExternalCode as IFormTextDynamicProps).error =
              clauseExternalCodeError;
          } else {
            clauseExternalCodeError = "";
            setHasClauseExternalCodeError("");
            (updatedInputs.clauseExternalCode as IFormTextDynamicProps).error =
              clauseExternalCodeError;
          }

          const newClauseEnums = LookupToList(clauseEnumResults.data);

          (
            updatedInputs.clauseStatus as IFormSelectDynamicProps
          ).selectOptions =
            newClauseEnums["PlanConfigManagement_ClauseStatuses"];
          updatedInputs.clauseStatus.value =
            currentInputsForm.clauseStatus.value;
          selectedLineId = Object.keys(newClauseEnums["lines"])[0];

          return updatedInputs;
        });
      });
    }
  };
  const validateClauseExternalCodeExistsOnLineChange = (
    selectedLineId?: string,
    clauseExternalCode?: string
  ) => {
    if (!isEmpty(selectedLineId) && !isEmpty(clauseExternalCode)) {
      checkClauseExistsLazy({
        variables: {
          lineId: selectedLineId,
          externalCode: clauseExternalCode,
          clauseId: clauseId || "",
          name: "",
        },
      }).then((response) => {
        setInputsForm((currentInputsForm) => {
          const updatedInputs = { ...currentInputsForm };
          updatedInputs.lineName.value = selectedLineId;
          
          if (
            response?.data?.PlanConfigManagement?.queries?.checkClauseExists
              .length > 0
          ) {
            clauseExternalCodeError =
              "Clause External Code already exists under the same Line";
            setHasClauseExternalCodeError(
              "Clause External Code already exists under the same Line"
            );
            (updatedInputs.clauseExternalCode as IFormTextDynamicProps).error =
              clauseExternalCodeError;
          } else {
            clauseExternalCodeError = "";
            setHasClauseExternalCodeError("");
            (updatedInputs.clauseExternalCode as IFormTextDynamicProps).error =
              clauseExternalCodeError;
          }

          const newClauseEnums = LookupToList(clauseEnumResults.data);

          (
            updatedInputs.clauseStatus as IFormSelectDynamicProps
          ).selectOptions =
            newClauseEnums["PlanConfigManagement_ClauseStatuses"];
          updatedInputs.clauseStatus.value =
            currentInputsForm.clauseStatus.value;
          selectedLineId = Object.keys(newClauseEnums["lines"])[0];

          return updatedInputs;
        });
      });
    }
  };

  useEffect(() => {
    initialize();
  }, [clauseId, lineName, clauseEnumResults.data, clauseInfoResult?.data]);

  useEffect(() => {
    const isDisabled =
      !isEmpty(hasClauseNameError) || !isEmpty(hasClauseExternalCodeError);

    setSubmitButtonDisabled(isDisabled);
  }, [hasClauseNameError, hasClauseExternalCodeError]);

  return (
    <GenericPopUp
      open={open}
      onClose={onClose}
      title={clauseId ? "Edit Clause" : "New Clause"}
      content={
        <div>
          {(clauseEnumResults.loading || clauseInfoResult?.loading) && open ? (
            <Loader />
          ) : (
            <DynamicForm
              inputs={inputsForm}
              onSubmit={(values) => submitForm(values)}
              buttonText={"Submit"}
              submitButtonState={submitButtonState}
              isSubmitButtonDisabled={submitButtonDisabled}
              disableForm={formDisabled}
              title=""
              popUpStyling={true}
            />
          )}
        </div>
      }
      fullWidth={true}
    />
  );
};

export default ClausePopUp;
