import { DynamicFormInputType, FormInputTypes } from '../../DynamicForm';
import { REGEX, isEmpty } from '../../utils/validationUtils';

export const initialValues = {
  personDetails: {
    type: '',
    companyName: '',
    title: '',
    titleId: '',
    firstName: '',
    middleName: '',
    lastName: '',
    surname: '',
    initials: '',
    dateOfBirth: '',
    nationality: '',
    nationalityId: '',
    email: '',
    phoneType: '',
    phoneNumber: '',
    relativeOf: '',
    smoker: 'NO',
    weight: 0,
    height: 0,
    dateOfDeath: '',
    dateOfDeathNotified: '',
    taxable: false,
    financialReference: '',
    occupationClass: '',
    profession: '',
    employer: '',
    dateOfEmployment: '',
    salary: 0,
    dateOfSelfCertificateReceived: '',
    dateOfSelfCertificateValidated: '',
    category: '',
    dateOfKYC: '',
    comments: '',
  },
};

export const inputs: Record<string, DynamicFormInputType> = {
  type: {
    name: 'type',
    title: 'Type',
    type: FormInputTypes.chips,
    placeholder: 'Type',
    value: !isEmpty(initialValues.personDetails.type)
      ? [initialValues.personDetails.type]
      : [],
    multiple: false,
    disabled: false,
    required: true,
  },
  companyName: {
    name: 'companyName',
    title: 'Company Name',
    placeholder: 'Company Name',
    type: FormInputTypes.text,
    value: initialValues.personDetails.companyName,
    required: false,
    disabled: true,
    hidden: true,
    minCharacters: 2,
    maxCharacters: 300,
  },
  title: {
    name: 'title',
    title: 'Title',
    placeholder: 'Title',
    type: FormInputTypes.chips,
    value: !isEmpty(initialValues.personDetails.titleId)
      ? [initialValues.personDetails.titleId]
      : [],
    multiple: false,
    required: false,
    disabled: true,
    hidden: true,
  },
  firstName: {
    name: 'firstName',
    title: 'First Name',
    placeholder: 'First Name',
    type: FormInputTypes.text,
    value: initialValues.personDetails.firstName,
    required: false,
    disabled: true,
    hidden: true,
    minCharacters: 2,
    maxCharacters: 100,
  },
  middleName: {
    name: 'middleName',
    title: 'Middle Name',
    placeholder: 'Middle Name',
    type: FormInputTypes.text,
    value: initialValues.personDetails.middleName,
    required: false,
    disabled: true,
    hidden: true,
    minCharacters: 1,
    maxCharacters: 100,
  },
  lastName: {
    name: 'lastName',
    title: 'Last Name',
    placeholder: 'Last Name',
    type: FormInputTypes.text,
    value: initialValues.personDetails.lastName,
    required: false,
    disabled: true,
    hidden: true,
    minCharacters: 2,
    maxCharacters: 100,
  },
  surname: {
    name: 'surname',
    title: 'Maiden Name',
    placeholder: 'Maiden Name',
    type: FormInputTypes.text,
    value: initialValues.personDetails.surname,
    required: false,
    disabled: true,
    hidden: true,
    minCharacters: 2,
    maxCharacters: 100,
  },
  initials: {
    name: 'initials',
    title: 'Initials',
    placeholder: 'Initials',
    type: FormInputTypes.text,
    value: initialValues.personDetails.initials,
    required: false,
  },
  dateOfBirth: {
    name: 'dateOfBirth',
    title: 'Date of Birth',
    placeholder: 'Date of Birth',
    type: FormInputTypes.date,
    value: initialValues.personDetails.dateOfBirth,
    required: false,
    disabled: true,
    hidden: true,
  },
  nationality: {
    name: 'nationality',
    title: 'Nationality',
    placeholder: 'Nationality',
    type: FormInputTypes.chips,
    value: !isEmpty(initialValues.personDetails.nationalityId)
      ? initialValues.personDetails.nationalityId
      : [],
    required: false,
    disabled: true,
    hidden: true,
    multiple: false,
  },
  email: {
    name: 'email',
    title: 'Email',
    type: FormInputTypes.text,
    placeholder: 'Email',
    value: initialValues.personDetails.email,
    regex: REGEX.email,
    regexError: 'Invalid Email',
    required: false,
    disabled: true,
  },
  phoneType: {
    name: 'phoneType',
    title: 'Phone Type',
    type: FormInputTypes.chips,
    placeholder: 'Phone Type',
    value: !isEmpty(initialValues.personDetails.phoneType)
      ? initialValues.personDetails.phoneType
      : [],
    required: false,
    multiple: false,
  },
  phoneNumber: {
    name: 'phoneNumber',
    title: 'Phone Number',
    type: FormInputTypes.phoneNumber,
    placeholder: 'Phone Number',
    value: initialValues.personDetails.phoneNumber,
    required: false,
    editCountryCode: false,
  },
  relativeOf: {
    name: 'relativeOf',
    title: 'Relative of',
    type: FormInputTypes.text,
    placeholder: 'Relative of',
    value: initialValues.personDetails.relativeOf,
    required: false,
    minCharacters: 2,
    maxCharacters: 200,
  },
  smoker: {
    name: 'smoker',
    title: 'Smoker',
    type: FormInputTypes.chips,
    placeholder: 'Smoker',
    value: !isEmpty(initialValues.personDetails.smoker)
      ? initialValues.personDetails.smoker
      : [],
    multiple: false,
    required: false,
    disabled: true,
    hidden: true,
  },
  weight: {
    name: 'weight',
    title: 'Weight (kg)',
    type: FormInputTypes.number,
    placeholder: 'Weight (kg)',
    value: initialValues.personDetails.weight,
    required: false,
    disabled: true,
    hidden: true,
    hasBetweenValidation: true,
    minNumber: 0,
    maxNumber: 300,
  },
  height: {
    name: 'height',
    title: 'Height (cm)',
    type: FormInputTypes.number,
    placeholder: 'Height (cm)',
    value: initialValues.personDetails.height,
    required: false,
    disabled: true,
    hidden: true,
    hasBetweenValidation: true,
    minNumber: 0,
    maxNumber: 300,
  },
  dateOfDeath: {
    name: 'dateOfDeath',
    title: 'Date of Death',
    placeholder: 'Date of Death',
    type: FormInputTypes.date,
    value: initialValues.personDetails.dateOfDeath,
    required: false,
    disabled: true,
    hidden: true,
  },
  dateOfDeathNotified: {
    name: 'dateOfDeathNotified',
    title: 'Date of Death Notified',
    placeholder: 'Date of Death Notified',
    type: FormInputTypes.date,
    value: initialValues.personDetails.dateOfDeathNotified,
    required: false,
    disabled: true,
    hidden: true,
  },
  taxable: {
    name: 'taxable',
    title: 'Taxable',
    placeholder: 'Taxable',
    type: FormInputTypes.switch,
    value: initialValues.personDetails.taxable,
  },
  financialReference: {
    name: 'financialReference',
    title: 'Financial Reference',
    placeholder: 'Financial Reference',
    type: FormInputTypes.text,
    value: initialValues.personDetails.financialReference,
  },
  occupationClass: {
    name: 'occupationClass',
    title: 'Occupation Class',
    placeholder: 'Occupation Class',
    type: FormInputTypes.chips,
    value: !isEmpty(initialValues.personDetails.occupationClass)
      ? initialValues.personDetails.occupationClass
      : [],
    multiple: false,
    required: false,
    disabled: true,
    hidden: true,
  },
  profession: {
    name: 'profession',
    title: 'Profession',
    placeholder: 'Profession',
    type: FormInputTypes.text,
    value: initialValues.personDetails.profession,
    required: false,
    disabled: true,
    hidden: true,
    minCharacters: 2,
    maxCharacters: 100,
  },
  employer: {
    name: 'employer',
    title: 'Employer / Institute',
    placeholder: 'Employer / Institute',
    type: FormInputTypes.text,
    value: initialValues.personDetails.employer,
    required: false,
    disabled: true,
    hidden: true,
    minCharacters: 2,
    maxCharacters: 100,
  },
  dateOfEmployment: {
    name: 'dateOfEmployment',
    title: 'Date of Employment',
    placeholder: 'Date of Employment',
    type: FormInputTypes.date,
    value: initialValues.personDetails.dateOfEmployment,
    required: false,
    disabled: true,
    hidden: true,
  },
  salary: {
    name: 'salary',
    title: 'Salary',
    type: FormInputTypes.currency,
    placeholder: 'Salary',
    value: initialValues.personDetails.salary,
    required: false,
    disabled: true,
    hidden: true,
  },
  dateOfSelfCertificateReceived: {
    name: 'dateOfSelfCertificateReceived',
    title: 'Date of Self Certificate Received',
    placeholder: 'Date of Self Certificate Received',
    type: FormInputTypes.date,
    value: initialValues.personDetails.dateOfSelfCertificateReceived,
    required: false,
    disabled: true,
    hidden: true,
  },
  dateOfSelfCertificateValidated: {
    name: 'dateOfSelfCertificateValidated',
    title: 'Date of Self Certificate Validated',
    placeholder: 'Date of Self Certificate Validated',
    type: FormInputTypes.date,
    value: initialValues.personDetails.dateOfSelfCertificateValidated,
    required: false,
    disabled: true,
    hidden: true,
  },
  category: {
    name: 'category',
    title: 'Category',
    placeholder: 'Category',
    type: FormInputTypes.chips,
    value: !isEmpty(initialValues.personDetails.category)
      ? initialValues.personDetails.category
      : [],
    multiple: false,
    required: false,
    disabled: true,
    hidden: true,
  },
  dateOfKYC: {
    name: 'dateOfKYC',
    title: 'Date of KYC',
    placeholder: 'Date of KYC',
    type: FormInputTypes.date,
    value: initialValues.personDetails.dateOfKYC,
    required: false,
    disabled: true,
    hidden: true,
  },
  comments: {
    name: 'comments',
    title: 'Comments',
    placeholder: 'Comments',
    type: FormInputTypes.text,
    value: initialValues.personDetails.comments,
    multiline: true,
  },
};
