import React, { useEffect, useState } from "react";
import GenericDrawer from "../../components/common/generic-drawer/GenericDrawer";
import {
  DynamicFormInputType,
  IFormSelectDynamicProps,
} from "../../DynamicForm";
import { toast } from "react-toastify";
import { EnhancedButtonStatus } from "../../components/common/EnhancedButton";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { inputs } from "./content";
import { useMutation, useQuery } from "@apollo/client";
import Loader from "../../components/Loader";
import { cloneDeep, isEmpty } from "lodash";
import { DEFAULT_ERROR_TEXT } from "../../constants";
import { IPlanDetailsDefaultCommissionDrawerProps } from ".";
import {
  getDefaultCommissionEnums,
  updatePlanSpecialCommissions,
} from "./queries";
import { LookupToList } from "./utils";
import { getError } from "../../utils/graph-utils";
import { normaliseDynamicValues } from "../../utils/dynamic-utils";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import DynamicForm from "../../DynamicForm/DynamicForm";

const PlanDetailsDefaultCommissionDrawer: React.FC<
  IPlanDetailsDefaultCommissionDrawerProps
> = ({ open, onSuccess, onClose, defaultCommissionDetailsInfo, planId }) => {
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();
  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);

  const defaultCommissionDetailsListResults = useQuery(
    getDefaultCommissionEnums()
  );

  const [defaultCommissionAction] = useMutation(updatePlanSpecialCommissions());

  useEffect(() => {
    initialize();
  }, [defaultCommissionDetailsListResults.data]);

  let updatedInputs: Record<string, DynamicFormInputType> = inputs;
  let newDefualtCommissionEnums: Record<string, Record<string, string>> = {};

  const showWhenRoles = (isApplicable: boolean, isCurrentValue: boolean) => {
    const hideIsCurrent = !isApplicable || !isCurrentValue;
    const hideIsRate = !isApplicable || isCurrentValue;

    updatedInputs.specialCommissionType.hidden = !isApplicable;
    updatedInputs.specialCommissionType.disabled = !isApplicable;
    updatedInputs.specialCommissionType.required = isApplicable;

    updatedInputs.specialCommissionValueCurrency.hidden = hideIsCurrent;
    updatedInputs.specialCommissionValueCurrency.disabled = hideIsCurrent;
    updatedInputs.specialCommissionValueCurrency.required = !hideIsCurrent;

    updatedInputs.specialCommissionValue.hidden = hideIsCurrent;
    updatedInputs.specialCommissionValue.disabled = hideIsCurrent;
    updatedInputs.specialCommissionValue.required = !hideIsCurrent;

    updatedInputs.specialCommissionRate.hidden = hideIsRate;
    updatedInputs.specialCommissionRate.disabled = hideIsRate;
    updatedInputs.specialCommissionRate.required = !hideIsRate;

    updatedInputs.specialCommissionApplicableOn.hidden = !isApplicable;
    updatedInputs.specialCommissionApplicableOn.disabled = !isApplicable;
    updatedInputs.specialCommissionApplicableOn.required = isApplicable;
  };

  const initialize = () => {
    try {
      updatedInputs = cloneDeep(inputsForm);

      if (defaultCommissionDetailsListResults.data) {
        newDefualtCommissionEnums = LookupToList(
          defaultCommissionDetailsListResults.data
        );

        (
          updatedInputs.specialCommissionApplicable as IFormSelectDynamicProps
        ).selectOptions =
          newDefualtCommissionEnums["specialCommissionApplicable"];

        (
          updatedInputs.specialCommissionType as IFormSelectDynamicProps
        ).selectOptions = newDefualtCommissionEnums["commissionTypes"];

        (
          updatedInputs.specialCommissionValueCurrency as IFormSelectDynamicProps
        ).selectOptions = newDefualtCommissionEnums["currencies"];

        (
          updatedInputs.specialCommissionApplicableOn as IFormSelectDynamicProps
        ).selectOptions =
          newDefualtCommissionEnums["commissionApplicableOnOptions"];
      }

      (
        updatedInputs.specialCommissionApplicable as IFormSelectDynamicProps
      ).onSelect = (option) => {
        const selectedType = option.toLowerCase();
        const isApplicable = selectedType === "yes";
        const isCurrentValue =
          updatedInputs.specialCommissionType.value.toLowerCase() === "value";

        showWhenRoles(isApplicable, isCurrentValue);
      };

      (
        updatedInputs.specialCommissionType as IFormSelectDynamicProps
      ).onSelect = (option) => {
        const selectedType = option.toLowerCase();
        const isValue = selectedType === "value";

        updatedInputs.specialCommissionValueCurrency.hidden = !isValue;
        updatedInputs.specialCommissionValueCurrency.disabled = !isValue;
        updatedInputs.specialCommissionValueCurrency.required = isValue;

        updatedInputs.specialCommissionValue.hidden = !isValue;
        updatedInputs.specialCommissionValue.disabled = !isValue;
        updatedInputs.specialCommissionValue.required = isValue;

        updatedInputs.specialCommissionRate.hidden = isValue;
        updatedInputs.specialCommissionRate.disabled = isValue;
        updatedInputs.specialCommissionRate.required = !isValue;
      };

      if (defaultCommissionDetailsInfo) {
        updatedInputs.specialCommissionApplicable.value =
          defaultCommissionDetailsInfo.specialCommissionApplicable
            ? "Yes"
            : "No";
        updatedInputs.specialCommissionType.value =
          defaultCommissionDetailsInfo.specialCommissionType || "RATE";

        updatedInputs.specialCommissionValue.value =
          defaultCommissionDetailsInfo.specialCommissionValue;
        updatedInputs.specialCommissionValueCurrency.value =
          defaultCommissionDetailsInfo.specialCommissionValueCurrency ||
          "39735";
        updatedInputs.specialCommissionRate.value = `${Math.round(
          parseFloat(
            (
              Number(defaultCommissionDetailsInfo.specialCommissionRate) * 100
            ).toFixed(10)
          )
        )}`;
        updatedInputs.specialCommissionApplicableOn.value =
          defaultCommissionDetailsInfo.specialCommissionApplicableOn;
      }

      const applicable =
        updatedInputs.specialCommissionApplicable.value.toLowerCase() === "yes";
      const currentValue =
        updatedInputs.specialCommissionType.value.toLowerCase() === "value";

      showWhenRoles(applicable, currentValue);

      setInputsForm(updatedInputs);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputs, values);

    setFormDisabled(true);
    setSubmitButtonState("loading");

    try {
      const isApplicable = data.specialCommissionApplicable === "Yes";
      const isRate = data.specialCommissionType === "RATE";
      let variablesMutation = {};
      variablesMutation = {
        entityId: planId,
        planSpecialCommissionInputs: {
          specialCommissionApplicable:
            data.specialCommissionApplicable === "Yes",
          specialCommissionType: isApplicable
            ? data.specialCommissionType
            : null,
          specialCommissionValue: isApplicable
            ? isRate
              ? 0
              : parseInt(data.specialCommissionValue as unknown as string)
            : null,
          specialCommissionValueCurrency: isApplicable
            ? isRate
              ? null
              : data.specialCommissionValueCurrency || "39735"
            : null,
          specialCommissionRate: isApplicable
            ? !isRate
              ? 0
              : parseInt(data.specialCommissionRate as unknown as string) / 100
            : null,
          specialCommissionApplicableOn: isApplicable
            ? data.specialCommissionApplicableOn
            : null,
        },
      };

      defaultCommissionAction({
        variables: variablesMutation,
        errorPolicy: "all",
      })
        .then((res) => {
          if (isEmpty(res.errors)) {
            toast.success(
              <ToastSuccessMessage>
                Commissions successfully updated.
              </ToastSuccessMessage>
            );
            setTimeout(() => {
              setSubmitButtonState("success");
              onSuccess();
              onClose();
            }, 500);
          } else {
            setSubmitButtonState(undefined);
            toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
          }
        })
        .catch((err) => {
          toast.error(<ToastErrorMessage>{getError(err)}</ToastErrorMessage>);
        });
    } catch {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setFormDisabled(false);
    }
  };

  return (
    <GenericDrawer
      title={"Modify Business Partner Default Commissions"}
      onClose={() => onClose()}
      isOpen={open}
    >
      {defaultCommissionDetailsListResults.loading && open ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={"Submit"}
            submitButtonState={submitButtonState}
            disableForm={formDisabled}
            title="Information"
            hasDoprdownSpecificBehavior={true}
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default PlanDetailsDefaultCommissionDrawer;
