import React, { useEffect, useState } from 'react';
import GenericDrawer from '../../components/common/generic-drawer/GenericDrawer';
import DynamicForm from '../../DynamicForm/DynamicForm';
import {
  DynamicFormInputType,
  IFormDateDynamicProps,
  IFormSelectDynamicProps,
  IFormTextDynamicProps,
} from '../../DynamicForm';
import { toast } from 'react-toastify';
import { EnhancedButtonStatus } from '../../components/common/EnhancedButton';
import { normaliseDynamicValues } from '../../utils/dynamic-utils';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { inputs } from './content';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  checkPlanExists,
  createPlan,
  getPlanEnums,
  getPlanInfo,
  relatedSublines,
  updatePlan,
} from './queries';
import {
  LookupToList,
  extractSublines,
  graphqlEntityToPlanInfo,
} from './utils';
import Loader from '../../components/Loader';
import { cloneDeep } from 'lodash';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import { isEmpty } from '../../utils/validationUtils';
import {
  DEFAULT_ERROR_TEXT,
  SEND_TO_BACKEND_DATE_FORMAT,
} from '../../constants';
import { getError } from '../../utils/graph-utils';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

const PlanDrawer: React.FC<IPlanDrawerProps> = ({
  planId,
  lineId,
  lineName,
  lineStatus,
  sublineStatus,
  sublineID,
  isSublineNameDisabled = false,
  isLineNameDisabled = true,
  canNavigateToDetailsPage = false,
  open,
  onSuccess,
  onClose,
}) => {
  let planInfoResult: any;

  const navigate = useNavigate();

  const [hasPlanNameError, setHasPlanNameError] = useState('');
  const [hasPlanExternalCodeError, setHasPlanExternalCodeError] = useState('');
  const [hasPlanEffectiveToError, setHasPlanEffectiveToError] = useState('');
  const [newEffectiveFrom, setNewEffectiveFrom] = useState<any>();
  const [newEffectiveTo, setNewEffectiveTo] = useState<any>();
  let isLineActive: boolean;
  let isSublineActive: boolean;
  let planNameError = '';
  let planExternalCodeError = '';

  let [submitButtonDisabled, setSubmitButtonDisabled] =
    useState<boolean>(false);

  let [selectedLineId, setSelectedLineId] = useState<string>('');
  let [selectedSublineId, setSelectedSublineId] = useState<string>('');
  const [selectedSublineStatus, setSelectedSublineStatus] =
    useState<string>('');
  let newPlanEnums: Record<string, Record<string, string>> = {};
  let planEntity: IPlanInfo = {
    planId: '',
    lineId: '',
    lineName: '',
    sublineName: '',
    planName: '',
    externalCode: '',
    arabicName: '',
    abbreviation: '',
    effectiveFrom: '',
    effectiveTo: '',
    planStatus: '',
  };

  let planEnumResults = useQuery(getPlanEnums(), {
    variables: { SelectedLineId: lineId },
  });
  const [checkPlanExistsLazy] = useLazyQuery(checkPlanExists(), {});
  const [lineSublinesLazy, sublinesQueryResult] = useLazyQuery(
    relatedSublines(),
    {
      variables: { id: lineId },
    }
  );

  isLineActive = lineStatus === 'active' ? true : false;
  isSublineActive =
    !isEmpty(selectedSublineStatus) &&
    selectedSublineStatus.toLowerCase() === 'active';

  const [planAction] = useMutation(
    planId && !canNavigateToDetailsPage ? updatePlan() : createPlan()
  );

  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();

  let updatedInputs: Record<string, DynamicFormInputType> = inputs;
  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);

  if (planId) {
    planInfoResult = useQuery(getPlanInfo(), {
      variables: { id: planId },
    });
  }

  function handleSublineSelection(selectedOption: string) {
    setSelectedSublineId(selectedOption);
    sublineID = selectedOption;

    const sublineStatusValue = newPlanEnums['sublineStatus'][selectedOption];
    setSelectedSublineStatus(sublineStatusValue);
    const isSublineActiveUpdated =
      sublineStatusValue.toLowerCase() === 'active';

    updatedInputs.planStatus.disabled =
      (!isLineActive && !isSublineActiveUpdated) || !isSublineActiveUpdated;

    validatePlanExistsOnNameChange(
      selectedOption,
      updatedInputs.planName.value
    );
    validatePlanExistsOnExternalCodeChange(
      selectedOption,
      updatedInputs.externalCode.value
    );
  }
  function handleLineSelection(selectedOption: string) {
    lineId = selectedOption;
    setSelectedLineId(selectedOption);
    lineSublinesLazy({ variables: { id: selectedOption } });
  }

  const initialize = () => {
    try {
      updatedInputs = cloneDeep(inputsForm);

      if (isLineNameDisabled) {
        updatedInputs.lineName.disabled = isLineNameDisabled;
        updatedInputs.lineName.value = lineId;
      }

      if (planEnumResults.data) {
        newPlanEnums = LookupToList(planEnumResults.data);

        if (!lineId) {
          updatedInputs.lineName.value = lineId;
        }
        (updatedInputs.lineName as IFormSelectDynamicProps).selectOptions =
          newPlanEnums['lines'];

        (updatedInputs.planStatus as IFormSelectDynamicProps).selectOptions =
          newPlanEnums['PlanConfigManagement_PlanStatuses'];
        if (!planId) {
          updatedInputs.planStatus.value = 'INACTIVE';
        }

        if (isSublineNameDisabled) {
          updatedInputs.sublineName.disabled = isSublineNameDisabled;
          updatedInputs.sublineName.value = sublineID;
        }

        if (isLineNameDisabled) {
          (updatedInputs.sublineName as IFormSelectDynamicProps).selectOptions =
            newPlanEnums['sublines'];
        } else {
          (updatedInputs.lineName as IFormSelectDynamicProps).selectOptions =
            newPlanEnums['lines'];
          (updatedInputs.lineName as IFormSelectDynamicProps).onSelect = (
            option
          ) => {
            handleLineSelection(option);
          };
        }
        (updatedInputs.sublineName as IFormSelectDynamicProps).onSelect = (
          option
        ) => {
          handleSublineSelection(option);
        };
      }

      (updatedInputs.planName as IFormTextDynamicProps).onChange = (event) => {
        const newName = event.target.value;
        // setInputsForm(updatedInputs);
        (updatedInputs.planName as IFormTextDynamicProps).value = newName;
        if (!isEmpty(newName)) {
          validatePlanExistsOnNameChange(
            selectedSublineId === '' ? sublineID : selectedSublineId,
            newName
          );
        }
      };

      (updatedInputs.externalCode as IFormTextDynamicProps).onChange = (
        event
      ) => {
        const newExternalCode = event.target.value;
        // setInputsForm(updatedInputs);
        (updatedInputs.externalCode as IFormTextDynamicProps).value =
          newExternalCode;
        if (!isEmpty(newExternalCode)) {
          validatePlanExistsOnExternalCodeChange(
            selectedSublineId === '' ? sublineID : selectedSublineId,
            newExternalCode
          );
        }
      };

      (updatedInputs.effectiveFrom as IFormDateDynamicProps).onChange = (
        event
      ) => {
        setNewEffectiveFrom(event);
        setInputsForm(updatedInputs);
        (updatedInputs.effectiveTo as IFormDateDynamicProps).minDate =
          newEffectiveFrom;
        setInputsForm(updatedInputs);
        handleEffectiveDateChange(newEffectiveTo, newEffectiveFrom);
      };

      (updatedInputs.effectiveTo as IFormDateDynamicProps).onChange = (
        event
      ) => {
        setNewEffectiveTo(event);
        setInputsForm(updatedInputs);
        (updatedInputs.effectiveTo as IFormDateDynamicProps).value =
          event;

        (updatedInputs.effectiveTo as IFormDateDynamicProps).minDate =
          updatedInputs.effectiveFrom.value;
        setInputsForm(updatedInputs);
        handleEffectiveDateChange(newEffectiveTo, newEffectiveFrom);
      };

      updatedInputs.planStatus.disabled = !isLineActive || !isSublineActive;

      if (planInfoResult?.data) {
        planEntity = graphqlEntityToPlanInfo(
          planInfoResult.data,
          lineId,
          lineName,
          sublineID
        );

        handleEffectiveDateChange(
          planEntity.effectiveTo,
          planEntity.effectiveFrom
        );

        if (planEntity) {
          (updatedInputs.effectiveTo as IFormDateDynamicProps).minDate =
            new Date(planEntity.effectiveFrom);

          updatedInputs.lineName.value = planEntity.lineName;
          updatedInputs.planName.value = planEntity.planName;
          updatedInputs.sublineName.value = planEntity.sublineName;
          updatedInputs.externalCode.value = planEntity.externalCode;
          updatedInputs.arabicName.value = planEntity.arabicName;
          updatedInputs.abbreviation.value = planEntity.abbreviation;
          updatedInputs.effectiveFrom.value = planEntity.effectiveFrom;
          updatedInputs.effectiveTo.value = planEntity.effectiveTo;
          updatedInputs.planStatus.value = planEntity.planStatus;
          selectedSublineId = sublineID = planEntity.sublineName;

          handleSublineSelection(planEntity.sublineName);
        }
      }

      setInputsForm(updatedInputs);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputs, values);
    setFormDisabled(true);
    setSubmitButtonState('loading');
    try {
      let variables = {
        planDetailInputs: {
          externalCode: data.externalCode,
          lineID: selectedLineId || lineId,
          sublineID: data.sublineName,
          name: data.planName,
          nameArabic: data.arabicName,
          abbreviation: data.abbreviation,
          effectiveFrom: dayjs(new Date(data.effectiveFrom)).format(
            SEND_TO_BACKEND_DATE_FORMAT
          ),
          effectiveTo: dayjs(new Date(data.effectiveTo)).format(
            SEND_TO_BACKEND_DATE_FORMAT
          ),
          planStatus: data.planStatus,
        },
      };
      planAction({
        variables:
          planId && !canNavigateToDetailsPage
            ? { ...variables, entityId: planId }
            : variables,
        errorPolicy: 'all',
      }).then((res) => {
        if (isEmpty(res.errors)) {
          toast.success(
            <ToastSuccessMessage>
              {planId && !canNavigateToDetailsPage
                ? 'Plan successfully updated'
                : 'Plan successfully created'}
            </ToastSuccessMessage>
          );

          if (canNavigateToDetailsPage) {
            const newPlanId =
              res.data.planConfigManagement.actions.createPlanDetails.id;

            setTimeout(() => {
              setSubmitButtonState('success');
              onSuccess();
              onClose();
              navigate(`/plan/plans/` + newPlanId);
            }, 500);
          } else {
            setTimeout(() => {
              setSubmitButtonState('success');
              onSuccess();
              onClose();
            }, 500);
          }
        } else {
          setSubmitButtonState(undefined);
          toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
        }
      });
    } catch {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setFormDisabled(false);
    }
  };

  const validatePlanExistsOnNameChange = (
    selectedSublineId: string,
    planName?: string
  ) => {
    isEmpty(selectedSublineId)
      ? setSelectedSublineId(
          !isEmpty(sublineID) ? sublineID : selectedSublineId
        )
      : selectedSublineId;

    if (!isEmpty(planName) && !isEmpty(selectedSublineId)) {
      checkPlanExistsLazy({
        variables: {
          name: planName,
          planId: planId,
          sublineId: !isEmpty(sublineID) ? sublineID : selectedSublineId,
          externalCode: '',
        },
      }).then((response) => {
        setInputsForm((currentInputsForm) => {
          const updatedInputs = { ...currentInputsForm };

          if (
            response.data.PlanConfigManagement?.queries?.checkPlanExists
              .length > 0
          ) {
            planNameError = 'Plan Name already exists under the same Subline';
            setHasPlanNameError(
              'Plan Name already exists under the same Subline'
            );
            (updatedInputs.planName as IFormTextDynamicProps).error =
              planNameError;
          } else {
            planNameError = '';
            setHasPlanNameError('');
            (updatedInputs.planName as IFormTextDynamicProps).error =
              planNameError;
          }

          const newPlanEnums = LookupToList(planEnumResults.data);

          (updatedInputs.planStatus as IFormSelectDynamicProps).selectOptions =
            newPlanEnums['PlanConfigManagement_PlanStatuses'];
          updatedInputs.planStatus.value = currentInputsForm.planStatus.value;

          return updatedInputs;
        });
      });
    }
  };

  const validatePlanExistsOnExternalCodeChange = (
    selectedSublineId: string,
    externalCode?: string
  ) => {
    setSelectedSublineId(!isEmpty(sublineID) ? sublineID : selectedSublineId);
    if (!isEmpty(externalCode) && !isEmpty(selectedSublineId)) {
      checkPlanExistsLazy({
        variables: {
          planId: planId,
          externalCode: externalCode,
          sublineId: selectedSublineId,
          name: '',
        },
      }).then((response) => {
        setInputsForm((currentInputsForm) => {
          const updatedInputs = { ...currentInputsForm };

          if (
            response.data.PlanConfigManagement?.queries?.checkPlanExists
              .length > 0
          ) {
            planExternalCodeError =
              'External Code already exists under the same Subline';
            setHasPlanExternalCodeError(
              'External Code already exists under the same Subline'
            );
            (updatedInputs.externalCode as IFormTextDynamicProps).error =
              planExternalCodeError;
          } else {
            planExternalCodeError = '';
            setHasPlanExternalCodeError('');
            (updatedInputs.externalCode as IFormTextDynamicProps).error =
              planExternalCodeError;
          }

          const newPlanEnums = LookupToList(planEnumResults.data);

          (updatedInputs.planStatus as IFormSelectDynamicProps).selectOptions =
            newPlanEnums['PlanConfigManagement_PlanStatuses'];
          setSelectedSublineId(Object.keys(newPlanEnums['sublines'])[0]);
          updatedInputs.planStatus.value = currentInputsForm.planStatus.value;

          return updatedInputs;
        });
      });
    }
  };

  const handleEffectiveDateChange = (effectiveTo: any, effectiveFrom: any) => {
    if (
      effectiveTo &&
      effectiveFrom &&
      dayjs(effectiveTo).isBefore(dayjs(effectiveFrom))
    ) {
      setHasPlanEffectiveToError(
        'Effective To Date shall be equal or greater than Effective From Date'
      );
      setInputsForm((currentInputsForm) => ({
        ...currentInputsForm,
        effectiveTo: {
          ...currentInputsForm.effectiveTo,
          minDate: effectiveFrom,
          error:
            'Effective To Date shall be equal or greater than Effective From Date',
        },
      }));
      setSubmitButtonDisabled(true);
    } else {
      setHasPlanEffectiveToError('');
      setInputsForm((currentInputsForm) => ({
        ...currentInputsForm,
        effectiveTo: {
          ...currentInputsForm.effectiveTo,
          minDate: effectiveFrom,
          error: '',
        },
      }));
      // Re-check other validation states before enabling the button
      setSubmitButtonDisabled(
        hasPlanEffectiveToError !== '' || hasPlanEffectiveToError !== ''
      );
    }
  };

  useEffect(() => {
    initialize();

    if (planInfoResult?.data) {
      if (planEntity) {
        const effectiveFromDate = planEntity.effectiveFrom;
        const effectiveToDate = planEntity.effectiveTo;
        const effectiveFromAsDate = new Date(effectiveFromDate);
        const effectiveToAsDate = effectiveToDate
          ? new Date(effectiveToDate)
          : effectiveFromAsDate;
        const effectiveToError =
          effectiveToDate &&
          dayjs(effectiveToAsDate).isBefore(dayjs(effectiveFromAsDate))
            ? 'Effective To Date shall be equal or greater than Effective From Date'
            : '';

        setInputsForm((currentInputsForm) => {
          const updatedForm = { ...currentInputsForm };

          updatedForm.effectiveFrom = {
            ...updatedForm.effectiveFrom,
            value: dayjs(effectiveFromAsDate).format('YYYY-MM-DD'),
          };

          updatedForm.effectiveTo = {
            ...updatedForm.effectiveTo,
            error: effectiveToError,
          };

          return updatedForm;
        });

        setSubmitButtonDisabled(!!effectiveToError);
      }
    }

    if (sublinesQueryResult?.data) {
      const lineSublines = extractSublines(sublinesQueryResult.data);

      setInputsForm((currentInputsForm) => {
        const updatedForm = { ...currentInputsForm };

        (updatedForm.sublineName as IFormSelectDynamicProps).selectOptions = {
          ...updatedForm.sublineName.value,
          ...lineSublines,
        };

        return updatedForm;
      });
    }
  }, [
    lineId,
    lineName,
    planEnumResults.data,
    planInfoResult?.data,
    planEntity?.sublineName,
    sublinesQueryResult.data,
  ]);

  useEffect(() => {
    const isDisabled =
      !isEmpty(hasPlanNameError) ||
      !isEmpty(hasPlanExternalCodeError) ||
      !isEmpty(hasPlanEffectiveToError);

    setSubmitButtonDisabled(isDisabled);
  }, [hasPlanNameError, hasPlanExternalCodeError, hasPlanEffectiveToError]);

  useEffect(() => {
    isLineActive = lineStatus === 'active';
  }, [lineStatus]);
  useEffect(() => {
    isSublineActive =
      !isEmpty(selectedSublineStatus) &&
      selectedSublineStatus.trim().toLowerCase() === 'active';
  }, [selectedSublineStatus]);
  useEffect(() => {
    handleEffectiveDateChange(newEffectiveTo, newEffectiveFrom);
  }, [newEffectiveFrom]);
  useEffect(() => {
    handleEffectiveDateChange(newEffectiveTo, newEffectiveFrom);
  }, [newEffectiveTo]);

  return (
    <GenericDrawer
      title={planId ? 'Edit Plan' : 'New Plan'}
      onClose={() => onClose()}
      isOpen={open}
    >
      {(planEnumResults.loading || planInfoResult?.loading) && open ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={'Submit'}
            submitButtonState={submitButtonState}
            isSubmitButtonDisabled={submitButtonDisabled}
            disableForm={formDisabled}
            title='Information'
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default PlanDrawer;
