import React, { useEffect, useState } from "react";
import GenericDrawer from "../../components/common/generic-drawer/GenericDrawer";
import {
  DynamicFormInputType,
  IFormChipsDynamicProps,
  IFormSelectDynamicProps,
} from "../../DynamicForm";
import { toast } from "react-toastify";
import { EnhancedButtonStatus } from "../../components/common/EnhancedButton";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { inputs } from "./content";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import Loader from "../../components/Loader";
import { cloneDeep, isEmpty } from "lodash";
import { DEFAULT_ERROR_TEXT } from "../../constants";
import { ISalesForceBusinessUserDrawerProps } from ".";
import {
  getListForm,
  checkBusinessUserByPerson,
  createBusinessUser,
  updateBusinessUser,
  getPersonEmailDetailsInfo,
} from "./queries";
import { getError } from "../../utils/graph-utils";
import { normaliseDynamicValues } from "../../utils/dynamic-utils";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import DynamicForm from "../../DynamicForm/DynamicForm";
import { useNavigate } from "react-router-dom";
import { fetchedPersons } from "../salesforce-business-partners-drawer/queries";
import { extractPersons, toLookups } from "./utils";

const SalesForceBusinessUserDrawer: React.FC<
  ISalesForceBusinessUserDrawerProps
> = ({
  personId,
  open,
  onSuccess,
  onClose,
  salesForceBusinessUserDetailsInfo,
  businessUserId,
}) => {
  const navigate = useNavigate();
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();
  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);

  const [hasBusinessUserPersonError, setHasBusinessUserPersonError] =
    useState("");
  let businessUserPersonError = "";
  let [submitButtonDisabled, setSubmitButtonDisabled] =
    useState<boolean>(false);

  const businessUsersDetailsListResults = useQuery(getListForm());

  const [selectedPersonID, setSelectedPersonID] = useState("");

  const [fetchedPersonsLazy, fetchedPersonsQueryResult] = useLazyQuery(
    fetchedPersons()
  );

  const [checkBusinessUserByPersonLazy] = useLazyQuery(
    checkBusinessUserByPerson(),
    {}
  );

  const [getPersonEmailDetailsLazy] = useLazyQuery(getPersonEmailDetailsInfo());

  const [salesForceBusinessUserAction] = useMutation(
    businessUserId ? updateBusinessUser() : createBusinessUser()
  );

  const validateBusinessUserExistsOnPersonChange = (
    selectedPersonID: string
  ) => {
    if (!isEmpty(selectedPersonID)) {
      checkBusinessUserByPersonLazy({
        variables: {
          selectedPersonID: selectedPersonID,
        },
      }).then((response) => {
        setInputsForm((currentInputsForm) => {
          const updatedInputs = { ...currentInputsForm };

          if (
            response.data.SalesforceManagement?.queries
              ?.checkBusinessUserByPerson.length > 0
          ) {
            businessUserPersonError = `Business User already created for ${response.data.SalesforceManagement?.queries?.checkBusinessUserByPerson[0]?.salesforceManagement_BusinessUser_PersonID?.views?.SalesforceManagement_all?.properties?.PersonCode} - ${response.data.SalesforceManagement?.queries?.checkBusinessUserByPerson[0]?.salesforceManagement_BusinessUser_PersonID?.views?.SalesforceManagement_all?.properties?.FullName}`;
            setHasBusinessUserPersonError(
              `Business User already created for ${response.data.SalesforceManagement?.queries?.checkBusinessUserByPerson?.salesforceManagement_BusinessUser_PersonID?.views?.SalesforceManagement_all?.properties?.PersonCode} - ${response.data.SalesforceManagement?.queries?.checkBusinessUserByPerson?.salesforceManagement_BusinessUser_PersonID?.views?.SalesforceManagement_all?.properties?.FullName}`
            );
            (updatedInputs.person as IFormSelectDynamicProps).error =
              businessUserPersonError;
          } else {
            businessUserPersonError = "";
            setHasBusinessUserPersonError("");
            (updatedInputs.person as IFormSelectDynamicProps).error =
              businessUserPersonError;
          }

          return updatedInputs;
        });
      });
    }
  };

  const getPersonEmailDetails = (selectedPersonID: string) => {
    if (!isEmpty(selectedPersonID)) {
      getPersonEmailDetailsLazy({
        variables: {
          id: selectedPersonID,
        },
      }).then((response) => {
        setInputsForm((currentInputsForm) => {
          const updatedInputs = { ...currentInputsForm };

          updatedInputs.email.value =
            response.data?.SalesforceManagement?.entities?.person?.views?.SalesforceManagement_all?.properties?.Email;

          return updatedInputs;
        });
      });
    }
  };

  function handlePersonSelection(selectedOption: any) {
    setSelectedPersonID(selectedOption);
    if (selectedOption.length > 5) {
      getPersonEmailDetails(selectedOption);
      validateBusinessUserExistsOnPersonChange(selectedOption);
    }
  }

  function handlePersonInput(inputValue: any) {
    if (inputValue !== null) {
      if (inputValue.length >= 3) {
        fetchedPersonsLazy({
          variables: {
            searchKeyword: inputValue,
          },
        });
      }
    } else {
      (updatedInputs.person as IFormChipsDynamicProps).selectOptions = {};
    }
  }

  useEffect(() => {
    initialize();
  }, [businessUsersDetailsListResults.data]);

  useEffect(() => {
    const isDisabled = !isEmpty(hasBusinessUserPersonError);

    setSubmitButtonDisabled(isDisabled);
  }, [hasBusinessUserPersonError]);

  useEffect(() => {
    if (fetchedPersonsQueryResult?.data) {
      const extractedFetchedPersons = extractPersons(
        fetchedPersonsQueryResult.data
      );

      setInputsForm((currentInputsForm) => {
        const updatedForm = { ...currentInputsForm };

        (updatedForm.person as IFormChipsDynamicProps).selectOptions = {
          ...updatedForm.person.value,
          ...extractedFetchedPersons,
        };

        return updatedForm;
      });
    } else {
      (updatedInputs.person as IFormChipsDynamicProps).selectOptions = {};
    }
  }, [fetchedPersonsQueryResult.data]);

  let updatedInputs: Record<string, DynamicFormInputType> = inputs;

  const initialize = () => {
    try {
      updatedInputs = cloneDeep(inputsForm);

      updatedInputs.person.hidden = !!businessUserId;
      updatedInputs.person.required = !businessUserId;

      updatedInputs.email.hidden = !!businessUserId;
      updatedInputs.email.required = !businessUserId;

      updatedInputs.password.hidden = !!businessUserId;

      updatedInputs.SalesforceManagement_BusinessUserStatuses.hidden =
        !!businessUserId;
      updatedInputs.SalesforceManagement_BusinessUserStatuses.required =
        !businessUserId;

      if (businessUsersDetailsListResults.data) {
        const lookupLists = toLookups(businessUsersDetailsListResults.data);

        // Set selectOptions for various fields
        (
          updatedInputs.SalesforceManagement_BusinessUserStatuses as IFormSelectDynamicProps
        ).selectOptions =
          lookupLists["SalesforceManagement_BusinessUserStatuses"];

        (
          updatedInputs.relatedCompany as IFormSelectDynamicProps
        ).selectOptions = lookupLists["relatedCompany"];

        (updatedInputs.type as IFormSelectDynamicProps).selectOptions =
          lookupLists["type"];

        (updatedInputs.access as IFormSelectDynamicProps).selectOptions =
          lookupLists["access"];

        (updatedInputs.person as IFormChipsDynamicProps).onChange = (
          option
        ) => {
          handlePersonInput(option);
          handlePersonSelection(option[0]);
        };
      }

      if (salesForceBusinessUserDetailsInfo) {
        updatedInputs.person.value = salesForceBusinessUserDetailsInfo.person;
        updatedInputs.email.value = salesForceBusinessUserDetailsInfo.email;
        updatedInputs.password.value =
          salesForceBusinessUserDetailsInfo.password;
        updatedInputs.relatedCompany.value =
          salesForceBusinessUserDetailsInfo.relatedCompany;
        updatedInputs.type.value = salesForceBusinessUserDetailsInfo.type;
        updatedInputs.account.value = salesForceBusinessUserDetailsInfo.account;
        updatedInputs.access.value = salesForceBusinessUserDetailsInfo.access;
        updatedInputs.SalesforceManagement_BusinessUserStatuses.value =
          salesForceBusinessUserDetailsInfo.SalesforceManagement_BusinessUserStatuses;
      }

      setInputsForm(updatedInputs);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputs, values);

    setFormDisabled(true);
    setSubmitButtonState("loading");

    try {
      let variablesMutation = {};
      if (!businessUserId) {
        variablesMutation = {
          businessUserInputs: {
            personID: selectedPersonID,
            password: data.password || null,
            relatedCompany: data.relatedCompany,
            type: data.type,
            account: data.account || null,
            access: data.access,
            status: data.SalesforceManagement_BusinessUserStatuses,
          },
        };
      } else {
        variablesMutation = {
          personID: salesForceBusinessUserDetailsInfo.personId,
          relatedCompany: data.relatedCompany,
          type: data.type,
          account: data.account || null,
          access: data.access,
        };
      }

      salesForceBusinessUserAction({
        variables: businessUserId
          ? {
              ...variablesMutation,
              entityId: businessUserId,
            }
          : variablesMutation,
        errorPolicy: "all",
      }).then((res) => {
        if (isEmpty(res.errors)) {
          toast.success(
            <ToastSuccessMessage>
              {businessUserId
                ? "Business User successfully updated"
                : "Business User successfully created"}
            </ToastSuccessMessage>
          );
          setTimeout(() => {
            setSubmitButtonState("success");
            onSuccess();
            onClose();
            if (!businessUserId) {
              const newbusinessUserId =
                res.data.salesforceManagement.actions.createBusinessUser.id;
              navigate(`/salesforce/business-users/` + newbusinessUserId);
            }
          }, 500);
        } else {
          setSubmitButtonState(undefined);
          toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
        }
      });
    } catch {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setFormDisabled(false);
    }
  };

  return (
    <GenericDrawer
      title={businessUserId ? "Modify Business User" : "New Business User"}
      onClose={() => onClose()}
      isOpen={open}
    >
      {businessUsersDetailsListResults.loading && open ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={"Submit"}
            submitButtonState={submitButtonState}
            isSubmitButtonDisabled={submitButtonDisabled}
            disableForm={formDisabled}
            title="Information"
            hasDoprdownSpecificBehavior={true}
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default SalesForceBusinessUserDrawer;
