import { IAbstractRecord } from "../../models";
import { IListingData } from "../../models/listing";
import { capitalizeFirstLetter } from "../../utils/formatting-utils";
import { enumListAsRecordObject } from "../../utils/graph-utils";

export function mapToListingData(data: any): IListingData<any> {
  const list: IAbstractRecord = {};

  data.SalesforceManagement.queries.getPersonList.items.forEach(
    (obj: IGenericPersonsTableData, i: number) => {
      list[i] = {
        id: obj.salesforceManagement_Person_Id,
        code: obj.salesforceManagement_Person_PersonCode,
        name: obj.salesforceManagement_Person_FullName,
        title: obj.salesforceManagement_Person_Title?.Title,
        type:
          obj.salesforceManagement_Person_PersonType.toLowerCase() === "person"
            ? "Individual"
            : capitalizeFirstLetter(obj.salesforceManagement_Person_PersonType),
        email: obj.salesforceManagement_Person_Email,
        phoneNumber: obj.salesforceManagement_Person_PhoneDetails?.PhoneNumber,
        status: capitalizeFirstLetter(
          obj.salesforceManagement_Person_Status?.replaceAll("_", " ")
        ),
        createdOn: obj.salesforceManagement_Person_createdOn,
        modifiedOn: obj.salesforceManagement_Person_modifiedOn,
      };
    }
  );

  return {
    pagedItems: list,
    pageSize: data.SalesforceManagement.queries.getPersonList?.paging?.pageSize,
    pageNumber:
      data.SalesforceManagement.queries.getPersonList?.paging?.pageNumber,
    totalCount:
      data.SalesforceManagement.queries.getPersonList?.paging?.totalCount,
  };
}

export function dataToLovs(data: any): Record<string, Record<string, string>> {
  let lovs: Record<string, Record<string, string>> = {
    type: {},
    status: {},
  };

  lovs.status = enumListAsRecordObject(
    data?.SalesforceManagement_PersonStatuses?.enumValues
  );
  lovs.type = enumListAsRecordObject(
    data?.SalesforceManagement_PersonTypes?.enumValues
  );

  if (lovs.type["PERSON"]) {
    lovs.type["PERSON"] = "Individual";
  }

  return lovs;
}
