import { DynamicFormInputType, FormInputTypes } from "../../DynamicForm";
import { isEmpty } from "../../utils/validationUtils";

export const initialValues = {
  assignedLine: "",
  assignedPlans: '',
  commissionType: "RATE",
  lineCommissionValue: 0,
  ValueCurrency: "39735",
  lineCommissionRate: 0,
  ApplicableOn: "",
  activeFrom: "",
  activeTo: "",
};

export const inputs: Record<string, DynamicFormInputType> = {
  assignedLine: {
    name: "assignedLine",
    title: "Line",
    type: FormInputTypes.chips,
    placeholder: "Line",
    value: initialValues.assignedLine,
    required: true,
    multiple: false,
    keyToClear:"assignedPlans"
  },
  assignedPlans: {
    name: "assignedPlans",
    title: "Plan(s)",
    type: FormInputTypes.chips,
    placeholder: "Plan(s)",
    value: !isEmpty(initialValues.assignedPlans)
    ? [initialValues.assignedPlans]
    : [],
    required: true,
    multiple: true,
  },
  commissionType: {
    name: "commissionType",
    title: "Commission Type",
    placeholder: "Commission Type",
    type: FormInputTypes.chips,
    value: !isEmpty(initialValues.commissionType)
    ? initialValues.commissionType
    : '',
    multiple: false,
    required: false,
    hidden: true,
  },
  lineCommissionValue: {
    name: "lineCommissionValue",
    title: "Commission",
    type: FormInputTypes.formattedNumber,
    placeholder: "Commission",
    value: initialValues.lineCommissionValue,
    required: false,
    hidden: true,
  },
  ValueCurrency: {
    name: "ValueCurrency",
    title: "Commission Currency",
    placeholder: "Commission Currency",
    type: FormInputTypes.chips,
    value: !isEmpty(initialValues.ValueCurrency)
      ? initialValues.ValueCurrency
      : '',
    multiple: false,
    required: false,
    hidden: true,
  },
  lineCommissionRate: {
    name: "lineCommissionRate",
    title: "Commission Rate",
    type: FormInputTypes.percentage,
    placeholder: "Commission Rate",
    value: initialValues.lineCommissionRate,
    required: false,
    hidden: true,
    hasBetweenValidation: true,
  },
  ApplicableOn: {
    name: "ApplicableOn",
    title: "Commission Applicable On",
    placeholder: "Commission Applicable On",
    type: FormInputTypes.chips,
    value: !isEmpty(initialValues.ApplicableOn)
      ? initialValues.ApplicableOn
      : '',
    multiple: false,
    required: false,
    hidden: true,
    allowDeleteValue: true,
  },
  activeFrom: {
    name: "activeFrom",
    title: "Active From",
    placeholder: "Active From",
    type: FormInputTypes.date,
    value: initialValues.activeFrom,
    required: true,
  },
  activeTo: {
    name: "activeTo",
    title: "Active To",
    placeholder: "Active To",
    type: FormInputTypes.date,
    value: initialValues.activeTo,
    required: true,
    disabled: true,
  },
};
