import { CSSProperties } from 'react';

export type ListingDataItemType<T> = Record<string, T & { actions?: string[] }>;

export interface IEnhancedCommonProps {
  className?: string;
  style?: CSSProperties;
  loader?: boolean;
}

export interface IEnhancedMenuItem {
  title: string;
  disabled?: boolean;
  onClick: () => void;
  isSecondary?: boolean;
  icon?: string;
  hidden?: boolean;
  customTooltips?: boolean;
  customTooltipActive?: string;
  customTooltipDisabled?: string;
  backgroundColor?: string;
  color?: string;
}

export interface IFilterCommonInputProps {
  name: string;
  // title: string;
  type: FilterInputTypes;
  placeholder?: string;
  value?: any;
  disabled?: boolean;
}

export enum FilterInputTypes {
  date = 'date',
  select = 'select',
}

export type FilterInputType = IFilterDateRangeProps | IFilterSelectProps;

export interface IFilterDateRangeProps extends IFilterCommonInputProps {
  type: FilterInputTypes.date;
  min?: Date;
  max?: Date;
}

export interface IFilterSelectProps extends IFilterCommonInputProps {
  type: FilterInputTypes.select;
  splitCharacter?: string;
  selectedValues? : string[];
}
