import { FormInputTypes } from '../../DynamicForm';
import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
} from '../../components/enhanced-table';
import { IListingFilterWidgetSection } from '../../components/widgets/custom-listing-filter';
import { IAbstractRecord } from '../../models';

export const headers: Record<string, EnhancedTableHeader> = {
  applicationContactDetails: {
    name: 'applicationContactDetails',
    title: 'Customer',
    type: EnhancedTableHeaderType.Clickable,
    callback: () => {},
  },
  fullPolicyNumber: {
    name: 'fullPolicyNumber',
    title: 'Policy Number',
    type: EnhancedTableHeaderType.Text,
  },
  lineOfBusiness: {
    name: 'lineOfBusiness',
    title: 'Line Of Business',
    type: EnhancedTableHeaderType.Text,
  },
  agency: {
    name: 'agency',
    title: 'Business Partner',
    type: EnhancedTableHeaderType.Text,
  },
  agent: {
    name: 'agent',
    title: 'Business User',
    type: EnhancedTableHeaderType.Text,
  },
  totalAnnualPremium: {
    name: 'totalAnnualPremium',
    title: 'Premium',
    type: EnhancedTableHeaderType.Currency,
  },
  policyStatus: {
    name: 'policyStatus',
    title: 'Status',
    type: EnhancedTableHeaderType.Text,
  },
  policyEffectiveDate: {
    name: 'policyEffectiveDate',
    title: 'Effective Date',
    type: EnhancedTableHeaderType.Date,
  },
  policyExpiryDate: {
    name: 'policyExpiryDate',
    title: 'Expiry Date',
    type: EnhancedTableHeaderType.Date,
  },
  policyIssueDate: {
    name: 'policyIssueDate',
    title: 'Issue Date',
    type: EnhancedTableHeaderType.Date,
  },
  createdOn: {
    name: 'createdOn',
    title: 'Created on',
    type: EnhancedTableHeaderType.Date,
  },
  modifiedOn: {
    name: 'modifiedOn',
    title: 'Modified on',
    type: EnhancedTableHeaderType.Date,
  },
};

export function filterSectionsContent(
  lovs: Record<string, Record<string, string>>,
  filterValues: IAbstractRecord,
  userRoles: string[],
): IListingFilterWidgetSection[] {
  const newFilterSections: IListingFilterWidgetSection[] = [
    {
      title: 'Filters',
      inputs: {
        ...((userRoles.includes('Insurance-Admin') ||
          userRoles.includes('Insurance-Underwriter')) && {
          agency: {
            name: 'agency',
            title: 'Business Partner',
            type: FormInputTypes.chips,
            value: filterValues?.agency || [],
            placeholder: 'Business Partner',
            triggerUpdate: true,
            selectOptions: { ...lovs.agency },
          },
        }),
        effectiveDate: {
          name: 'effectiveDate',
          title: 'Effective Date',
          type: FormInputTypes.daterange,
          value: filterValues.effectiveDate || [],
          placeholder: 'Effective Date',
          triggerUpdate: true,
        },
        issueDate: {
          name: 'issueDate',
          title: 'Issue Date',
          type: FormInputTypes.daterange,
          value: filterValues.issueDate || [],
          placeholder: 'issue Date',
          triggerUpdate: true,
        },
      },
    },
  ];
  return newFilterSections;
}
