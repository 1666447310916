import { AnyAction } from 'redux';

export interface IConfirmation {
  open: boolean;
  title: string;
  message?: string | JSX.Element;
  callback?: () => Promise<void>;
  submitButtonText?: string;
  cancelButtonText?: string;
  hideCancelButton?: boolean;
}

export enum ConfirmationActionTypes {
  OPEN_CONFIRMATION = 'OPEN_CONFIRMATION',
  CLOSE_CONFIRMATION = 'CLOSE_CONFIRMATION',
}

export interface OpenConfirmationAction extends AnyAction {
  type: typeof ConfirmationActionTypes.OPEN_CONFIRMATION;
  confirmation: IConfirmation;
}

export interface CloseConfirmationAction extends AnyAction {
  type: typeof ConfirmationActionTypes.CLOSE_CONFIRMATION;
}

/*
 * Combines the 2 action types that handle the confirmation store
 */
export type ConfirmationAction =
  | OpenConfirmationAction
  | CloseConfirmationAction;
