import React, { useEffect, useState } from "react";
import GenericDrawer from "../../components/common/generic-drawer/GenericDrawer";
import DynamicForm from "../../DynamicForm/DynamicForm";
import {
  DynamicFormInputType,
  IFormSelectDynamicProps,
} from "../../DynamicForm";
import { toast } from "react-toastify";
import { EnhancedButtonStatus } from "../../components/common/EnhancedButton";

import ToastErrorMessage from "../../components/ToastErrorMessage";
import { inputs } from "./content";
import { QueryResult, useMutation, useQuery } from "@apollo/client";
import {
  createActionAsAgent,
  createActionAsUnderwriter,
  ListAgenciesByAgent,
} from "./queries";
import Loader from "../../components/Loader";
import { extractApplicationDrawerData } from "./utils";
import { cloneDeep } from "lodash";
import { normaliseDynamicValues } from "../../utils/dynamic-utils";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import { useNavigate } from "react-router-dom";

const ApplicationDrawerWithMultipleAgencies: React.FC<
  IApplicationWithMultipleAgenciesDrawer
> = ({
  open,
  loggedInAgentId,
  loggedInuserFirstName,
  loggedInuserLastName,
  lineOfBusiness = "Motor",
  agentType = "agent",
  onSuccess,
  onClose,
}) => {
  const navigate = useNavigate();

  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();

  inputs["lineOfBusiness"].value = lineOfBusiness;
  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);

  const [loadingState, setLoadingState] = useState<boolean>(true);

  const [loadingFields, setLoadingFields] = useState<Record<string, boolean>>({
    agencies: false,
  });

  const [formDisabled, setFormDisabled] = useState(false);

  const agentAgenciesQueryRes: QueryResult = useQuery(ListAgenciesByAgent(), {
    variables: { agentId: loggedInAgentId },
  });

  const [mutateUnderwriterFunction] = useMutation(createActionAsUnderwriter());
  const [mutateAgentFunction] = useMutation(createActionAsAgent());

  useEffect(() => {
    if (agentAgenciesQueryRes.loading) {
      setLoadingState(true);
      setLoadingFields({ ...loadingFields, agencies: true });
    }
    if (agentAgenciesQueryRes.error) {
      toast.error(
        <ToastErrorMessage>Something wrong happened</ToastErrorMessage>
      );
    }

    if (agentAgenciesQueryRes.data) {
      const updatedInputs = cloneDeep(inputsForm);

      const extractedData = extractApplicationDrawerData(
        agentAgenciesQueryRes.data
      );

      (updatedInputs.agency as IFormSelectDynamicProps).selectOptions =
        extractedData["agencies"];

      (updatedInputs.agent as IFormSelectDynamicProps).value =
        loggedInuserFirstName + " " + loggedInuserLastName;

      setInputsForm(updatedInputs);
      setLoadingState(false);
      setLoadingFields({ ...loadingFields, agencies: false });
    }
  }, [agentAgenciesQueryRes]);

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputs, values);
    setFormDisabled(true);
    setSubmitButtonState("loading");
    try {
      if (agentType === "agent") {
        mutateAgentFunction({
          variables: {
            agencyId: data.agency,
            agentId: loggedInAgentId,
            lineOfBusiness: lineOfBusiness,
          },
        })
          .then((data) => {
            toast.success(
              <ToastSuccessMessage>
                {"Application created successfully"}
              </ToastSuccessMessage>
            );
            const newApplicationId =
              data.data.insurance.actions.createApplicationAsAgent.id;
            setTimeout(() => {
              setSubmitButtonState("success");
              onSuccess();
              onClose();
              navigate(
                `/sales/applications/${lineOfBusiness.toLowerCase()}/` +
                  newApplicationId
              );
            }, 500);
          })
          .catch((error) => {
            setSubmitButtonState(undefined);
            toast.error(
              <ToastErrorMessage>Something wrong happened.</ToastErrorMessage>
            );
          });
      } else if (agentType === "underwriter") {
        mutateUnderwriterFunction({
          variables: {
            agencyId: data.agency,
            agentId: loggedInAgentId,
            lineOfBusiness: lineOfBusiness,
          },
        })
          .then((data) => {
            toast.success(
              <ToastSuccessMessage>
                {"Application created successfully"}
              </ToastSuccessMessage>
            );
            const newApplicationId =
              data.data.insurance.actions.createApplicationAsUnderwriter.id;
            setTimeout(() => {
              setSubmitButtonState("success");
              onSuccess();
              onClose();
              navigate(
                `/sales/applications/${lineOfBusiness.toLowerCase()}/` +
                  newApplicationId
              );
            }, 500);
          })
          .catch((error) => {
            setSubmitButtonState(undefined);
            toast.error(
              <ToastErrorMessage>Something wrong happened.</ToastErrorMessage>
            );
          });
      }
    } catch {
      setSubmitButtonState(undefined);
      toast.error(
        <ToastErrorMessage>Something wrong happened.</ToastErrorMessage>
      );
    } finally {
      setFormDisabled(false);
    }
  };

  return (
    <GenericDrawer
      title={"New Application"}
      onClose={() => onClose()}
      isOpen={open}
    >
      {loadingState && open ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={"Submit"}
            submitButtonState={submitButtonState}
            disableForm={formDisabled}
            loadingFields={loadingFields}
            title="Information"
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default ApplicationDrawerWithMultipleAgencies;
