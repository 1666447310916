import { DynamicFormInputType, FormInputTypes } from "../../DynamicForm";
import { isEmpty } from "../../utils/validationUtils";

export const initialValues = {
  lineName: "",
  sublineName: "",
  planName: "",
  externalCode: "",
  arabicName: "",
  abbreviation: "",
  effectiveFrom: "",
  effectiveTo: "",
  planStatus: "",
};

export const inputs: Record<string, DynamicFormInputType> = {
  lineName: {
    name: "lineName",
    title: "Line",
    type: FormInputTypes.chips,
    placeholder: "Line",
    value: !isEmpty(initialValues.lineName) ? [initialValues.lineName] : [],
    multiple: false,
    disabled: false,
  },
  sublineName: {
    name: "sublineName",
    title: "Subline",
    placeholder: "Subline",
    type: FormInputTypes.chips,
    value: !isEmpty(initialValues.sublineName) ? initialValues.sublineName : [],
    required: true,
    multiple: false,
  },
  planName: {
    name: "planName",
    title: "Plan Name",
    placeholder: "Plan Name",
    type: FormInputTypes.text,
    value: initialValues.planName,
    required: true,
  },
  externalCode: {
    name: "externalCode",
    title: "External Code",
    placeholder: "External Code",
    type: FormInputTypes.text,
    value: initialValues.externalCode,
    required: true,
  },
  arabicName: {
    name: "arabicName",
    title: "Arabic Name",
    placeholder: "Arabic Name",
    type: FormInputTypes.text,
    value: initialValues.arabicName,
    required: true,
  },
  abbreviation: {
    name: "abbreviation",
    title: "Abbreviation",
    placeholder: "Abbreviation",
    type: FormInputTypes.text,
    value: initialValues.abbreviation,
    required: true,
  },
  effectiveFrom: {
    name: "effectiveFrom",
    title: "Effective From",
    placeholder: "Effective From",
    type: FormInputTypes.date,
    value: initialValues.effectiveFrom,
    required: true,
  },
  effectiveTo: {
    name: "effectiveTo",
    title: "Effective To",
    placeholder: "Effective To",
    type: FormInputTypes.date,
    value: initialValues.effectiveTo,
    required: true,
  },
  planStatus: {
    name: "planStatus",
    title: "Status",
    placeholder: "Status",
    type: FormInputTypes.chips,
    value: !isEmpty(initialValues.planStatus) ? initialValues.planStatus : [],
    required: true,
    multiple: false,
    hidden: true,
  },
};
