import { useApolloClient, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  IPolicyCoverDetailsPageProps,
  IPolicyCoverDetails,
  initialValues,
} from ".";
import Loader from "../../components/Loader";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { IEntityInfoView } from "../../components/widgets/entity-info/EntityInfoFields";
import EntityInfoWidget from "../../components/widgets/entity-info/EntityInfoWidget";
import {
  DEFAULT_ERROR_TEXT,
  MAIN_ONE_THEME,
  contentFontFamilyBold,
} from "../../constants";
import { claimCoverHeaders, entityView, steps } from "./content";
import { getPolicyCoverClaimCoverList, getPolicyCoverDetailsInfo } from "./queries";
import {
  entityToPolicyCoverDetails,
  mapToClaimCoversListingData,
} from "./utils";
import { useAppSelector } from "../../redux/hooks";
import dayjs from "dayjs";
import EnhancedStepperSpecificDesign from "../../components/common/EnhancedStepperSpecificDesign";
import { makeStyles } from "tss-react/mui";
import StaticLayout from "../../page-layout/static-layout/StaticLayout";
import { EnhancedDisplayType } from "../../components/enhanced-form/EnhancedDisplay";
import PolicyCoverDrawer from "../policy-cover-drawer/PolicyCoverDrawer";
import { IListingData } from "../../models/listing";
import { IDynamicDiplayView } from "../../components/widgets/dynamic-display";
import { IEnhancedTableMenuItem } from "../../components/enhanced-table";
import WidgetSection from "../../components/common/WidgetSection";
import EnhancedTable from "../../components/enhanced-table/EnhancedTable";
import { capitalizeFirstLetter } from "../../utils/formatting-utils";
import ClaimCoverDrawer from "../claim-cover-drawer/ClaimCoverDrawer";

const useStyles = makeStyles()(() => ({
  stepper: {
    backgroundColor: "transparent",
    maxWidth: "90%",
    marginBottom: "20px",
    "& .MuiStepLabel-root .Mui-active, .MuiStepLabel-root ": {
      color: MAIN_ONE_THEME.palette.primary5.main,
      "& .MuiStepIcon-text": {
        fontSize: 0,
        lineHeight: "0px",
        fill: "black",
      },
    },

    ".Mui-completed": {
      color: MAIN_ONE_THEME.palette.primary5.main,
      "& .MuiStepIcon-text": {
        fontSize: 0,
        lineHeight: "0px",
        fill: "black",
      },
    },

    "@media only screen and (min-width: 600px)": {
      maxWidth: "1300px",
    },
  },
  section: {
    display: "grid",
    "grid-template-columns": "19.5% 19.5% 19.5% 19.5% 19.5%",
    justifyContent: "space-between",
    flexWrap: "wrap",

    "& label": {
      "& span": {
        margin: "-2px 0",
      },
    },
  },
  sectionTitle: {
    padding: "-10x 10x!important",
  },
  sectionFullRow: {
    display: "grid",
    "grid-template-columns": "100%",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  row: {
    flexWrap: "wrap",
    margin: "15px 20px 10px 0px",
    alignItems: "flex-start",
    display: "grid",
    gap: "5px",
    "& label": {
      flexGrow: "1",
      flexBasis: "0",
      minWidth: "0",
    },
    "grid-template-columns": "19.5% 19.5% 19.5% 19% 16% 3% 3%",
  },
  thickSeperator: {
    height: 10,
    margin: "10px 0 10px 0",
    "grid-column-start": "1",
    "grid-column-end": "4",
  },
  field: {
    width: "96%",
    marginRight: "10px !important",
  },
  repeaterField: {
    margin: "0px!important",
  },
  arField: {
    width: "96%",
    marginRight: "10px !important",
    direction: "rtl",
  },
  header: {
    fontFamily: contentFontFamilyBold,
    fontSize: MAIN_ONE_THEME.typography.regular.reg3.fontSize,
    color: MAIN_ONE_THEME.palette.primary2.main,
    "&:hover": {
      color: MAIN_ONE_THEME.palette.primary1.main,
    },
  },
  content: {
    padding: "0",
  },
  dirtyChip: {
    margin: "0 0 -15px 0",
    backgroundColor: MAIN_ONE_THEME.palette.primary2.main,
    "& span": {
      color: "#ffffff",
    },
  },
  tabPanelStyle: {
    padding: "unset",
    marginTop: "20px",
  },
}));

const PolicyCoverDetailsPage: React.FC<IPolicyCoverDetailsPageProps> = () => {
  //#region react and redux hooks
  const { classes } = useStyles();
  const params = useParams();
  const tenant = useAppSelector((state) => state.tenant);
  const client = useApolloClient();
  const user = useAppSelector((state) => state.user);

  const [policyCoverDrawerOpen, setPolicyCoverDrawerOpen] =
    useState<boolean>(false);

  const [selectedClaimCoverItems, setSelectedClaimCoverItems] =
    useState<number>(0);
  const [claimCoverTableLoadingState, setClaimCoverTableLoadingState] =
    useState<boolean>(false);
  //#endregion

  //#region component hooks and states
  const [policyCoverDetailsInfo, setPolicyCoverDetailsInfo] =
    useState<IPolicyCoverDetails>(initialValues);

  const [updatedLeftSideInfo, setUpdatedLeftSideInfo] =
    useState<IEntityInfoView>(entityView);

  const [loadingState, setLoadingState] = useState<boolean>(true);

  const entityId = params.id;
  //#endregion

  //#region graphql hooks
  const [claimCoverTableData, setClaimCoverTableData] = useState<
    IListingData<any>
  >({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 0,
    totalCount: 0,
  });

  const entityResult = useQuery(getPolicyCoverDetailsInfo(), {
    variables: { id: entityId },
    errorPolicy: "all",
    fetchPolicy: "no-cache",
  });

  const claimCoverListResult = useQuery(getPolicyCoverClaimCoverList(), {
    variables: {
      currentPage: 1,
      currentPageSize: claimCoverTableData.pageSize,
      SelectedLineId: entityResult?.data?.PlanConfigManagement?.entities?.policyCover?.views?.PlanConfigManagement_all?.properties?.LineID?.id,
      selectedPolicyCoverID: params.id,
    },
    errorPolicy: "all",
  });

  const [claimCoversViewConfig, setClaimCoversViewConfig] =
    useState<IDynamicDiplayView>();
  const [selectedClaimCoverEntityId, setSelectedClaimCoverEntityId] =
    useState("");
  const [claimCoverDrawerOpen, setClaimCoverDrawerOpen] =
    useState<boolean>(false);
  const [actionClicked, setActionClicked] = useState(null);
  //#endregion

  const isAdmin = user.userRoles.includes("Insurance-Admin");
  let claimCoverListingActions: IEnhancedTableMenuItem[] = [];
  claimCoverListingActions = [
    {
      title: "New",
      onClick: () => {},
      isEntity: false,
      isBulk: false,
      iconUrl: tenant.cdnUrl + "/icons/add-primary-red.svg",
      hidden: !isAdmin,
      disabled: false,
    },
    {
      title: "Edit",
      onClick: () => {},
      isEntity: false,
      isBulk: false,
      iconUrl: tenant.cdnUrl + "/icons/edit-secondary.svg",
      hidden: !isAdmin,
      disabled: selectedClaimCoverItems !== 1,
    },
  ];

  claimCoverListingActions[0].onClick = () => {
    setActionClicked("new");
    setClaimCoverDrawerOpen(true);
  };
  claimCoverListingActions[1].onClick = () => {
    setActionClicked("edit");
    setClaimCoverDrawerOpen(true);
  };

  //#region useEffect
  useEffect(() => {
    try {
      if (!entityResult.loading) {
        setLoadingState(false);
      }

      if (entityResult?.data) {
        const policyCoverDetails = entityToPolicyCoverDetails(
          entityResult?.data
        );

        //get application section properties
        const applicationProperties = entityView.sections[0];
        applicationProperties.properties.lineName = {
          title: "Line",
          name: "Line",
          multiline: false,
          value: policyCoverDetails.lineName,
          type: EnhancedDisplayType.RelativeLink,
          tooltip: false,
          url:
            "/plan/lines/" +
            entityResult?.data?.PlanConfigManagement?.entities?.policyCover?.views
              ?.PlanConfigManagement_all?.properties?.LineID?.id,
        };
        applicationProperties.properties.externalCode.value =
          policyCoverDetails.externalCode;
        applicationProperties.properties.arabicName.value =
          policyCoverDetails.arabicName;
        applicationProperties.properties.printingOrder.value =
          policyCoverDetails.printingOrder;
        applicationProperties.properties.policyCoverDescription.value =
          policyCoverDetails.policyCoverDescription;

        //get the system propeties section
        const systemProperties = entityView.sections[1];
        systemProperties.properties.createdBy.value =
          policyCoverDetails.createdBy;
        systemProperties.properties.createdOn.value = dayjs(
          new Date(policyCoverDetails.createdOn)
        ).format("DD/MM/YYYY");
        systemProperties.properties.modifiedBy.value =
          policyCoverDetails.modifiedBy;
        systemProperties.properties.modifiedOn.value = dayjs(
          new Date(policyCoverDetails.modifiedOn)
        ).format("DD/MM/YYYY");

        const newLeftEntityView = {
          ...entityView,
          sections: [applicationProperties, systemProperties],
        };

        setPolicyCoverDetailsInfo(policyCoverDetails);

        setUpdatedLeftSideInfo(newLeftEntityView);

        const claimCoversDynamicView: IDynamicDiplayView = {
          name: "plans",
          sections: [],
        };
        setClaimCoversViewConfig(claimCoversDynamicView);
      }
    } catch (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  }, [entityResult?.data]);

  useEffect(() => {
    const isLoading = claimCoverListResult?.loading;
    setLoadingState(isLoading);

    if (claimCoverListResult?.data) {
      const claimCoverTableData = mapToClaimCoversListingData(claimCoverListResult.data);
      setClaimCoverTableData({ ...claimCoverTableData, pageNumber: 0 });
    }
  
    if (!isLoading) {
      setLoadingState(false);
    }
  }, [claimCoverListResult?.loading, claimCoverListResult?.error, claimCoverListResult?.data]);
  //#endregion

  //#region functions
  function handleClaimCoverPageChange(page: number) {
    setClaimCoverTableLoadingState(true);
    claimCoverListResult
      ?.fetchMore({
        variables: {
          currentPage: ++page,
          currentPageSize: claimCoverTableData.pageSize,
        },
      })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const newApplicationList = mapToClaimCoversListingData(
            fetchMoreResult.data
          );
          const updatedTableData = {
            ...newApplicationList,
            pageNumber: --page,
          };
          setClaimCoverTableData(updatedTableData);
        }
      })
      .finally(() => {
        setClaimCoverTableLoadingState(false);
      });
  }
  function handleRowsPerClaimCoverPageChange(numberOfRecordsPerPage: number) {
    setClaimCoverTableLoadingState(true);
    setClaimCoverTableData({ ...claimCoverTableData, pageNumber: 1 });
    claimCoverListResult
      ?.fetchMore({
        variables: {
          currentPage: 1,
          currentPageSize: numberOfRecordsPerPage,
        },
      })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const newApplicationList = mapToClaimCoversListingData(
            fetchMoreResult.data
          );

          const updatedTableData = {
            ...newApplicationList,
            pageNumber: 0,
            pageSize: numberOfRecordsPerPage,
          };
          setClaimCoverTableData(updatedTableData);
        }
      })
      .finally(() => {
        setClaimCoverTableLoadingState(false);
      });
  }

  const handlePolicyCoverSectionChange = async () => {
    try {
      client.refetchQueries({
        include: [getPolicyCoverDetailsInfo()],
      });
    } catch (error) {
      toast.error(<ToastErrorMessage>{error}</ToastErrorMessage>);
    }
  };

  const renderMainChildren = () => {
    return (
      <>
        <div style={{ marginTop: "20px" }}>
          <EnhancedStepperSpecificDesign
            activeStep={""}
            steps={steps}
            className={classes.stepper}
            showStepperButton={false}
            style={{visibility: 'hidden'}}
          />
          {policyCoverDrawerOpen && (
            <PolicyCoverDrawer
              open={policyCoverDrawerOpen}
              onClose={() => setPolicyCoverDrawerOpen(false)}
              onSuccess={() => {
                handlePolicyCoverSectionChange();
              }}
              isLineNameDisabled={true}
              lineId={entityResult?.data?.PlanConfigManagement?.entities?.policyCover?.views?.PlanConfigManagement_all?.properties?.LineID?.id}
              canNavigateToDetailsPage={false}
              policyCoverId={entityId}
            />
          )}
          <WidgetSection style={{ margin: "-23px 0 0 0" }}>
            {claimCoversViewConfig && (
              <form>
                {claimCoverListResult?.loading ? (
                  <div></div>
                ) : (
                  <div
                    style={{
                      marginTop: "20px",
                      padding: "-23px 0 0 0 !important",
                    }}
                  >
                    <EnhancedTable
                      title={capitalizeFirstLetter("Claim Covers")}
                      inlineTitle={capitalizeFirstLetter("Claim Covers")}
                      name="count"
                      entityName={"ClaimCover"}
                      entityIdColumnName={"planConfigManagement_ClaimCover_Id"}
                      showCellFullData={true}
                      isTitlePascalCase={true}
                      orderByAscendingByDefault
                      defaultOrderByColumn="name"
                      inline={true}
                      data={claimCoverTableData}
                      headers={claimCoverHeaders}
                      handlePageChange={(page: number) =>
                        handleClaimCoverPageChange(page)
                      }
                      handleRowsPerPageChange={(page: number) =>
                        handleRowsPerClaimCoverPageChange(page)
                      }
                      currentPage={claimCoverTableData.pageNumber}
                      hideToolbar={false}
                      usePagination
                      disableSelection={false}
                      actions={claimCoverListingActions}
                      loader={claimCoverTableLoadingState}
                      showTablePagination={true}
                      onSelectionChange={(newDetailedSelection) => {
                        setSelectedClaimCoverItems(newDetailedSelection.length);
                        if (newDetailedSelection.length > 0) {
                          setSelectedClaimCoverEntityId(
                            newDetailedSelection[0].id
                          );
                        } else {
                          setSelectedClaimCoverEntityId("");
                        }
                      }}
                    />
                    {claimCoverDrawerOpen && (
                      <ClaimCoverDrawer
                        open={claimCoverDrawerOpen}
                        lineId={entityResult?.data?.PlanConfigManagement?.entities?.policyCover?.views?.PlanConfigManagement_all?.properties?.LineID?.id}
                        lineName={
                          entityResult?.data?.PlanConfigManagement?.entities?.policyCover?.views?.PlanConfigManagement_all?.properties?.LineID?.views
                            ?.PlanConfigManagement_all?.properties?.Name
                        }
                        claimCoverId={actionClicked === "new" ? null : selectedClaimCoverEntityId}
                        onClose={() => setClaimCoverDrawerOpen(false)}
                        policyCoverId={entityId}
                        isPolicyCoverNameDisabled={true}
                        onSuccess={() => {
                          handleClaimCoverPageChange(0);
                        }}
                      />
                    )}
                  </div>
                )}
              </form>
            )}
          </WidgetSection>
        </div>
      </>
    );
  };

  const handleIconClick = () => {
    setPolicyCoverDrawerOpen(true);
  };
  const renderLeftSection = () => {
    return (
      <>
        <EntityInfoWidget
          background="rgb(193, 0, 0)"
          classification={{ classifications: {}, value: "" }}
          progress={100}
          supportsClassification={true}
          supportsProgressBar={true}
          title={policyCoverDetailsInfo.policyCoverName}
          view={updatedLeftSideInfo}
          iconUrl={tenant.cdnUrl + "/icons/edit-secondary.svg"}
          onClickIcon={handleIconClick}
        ></EntityInfoWidget>
      </>
    );
  };

  //#endregion

  return loadingState ? (
    <Loader />
  ) : (
    <StaticLayout
      name={"Policy Cover Details"}
      leftChildren={renderLeftSection()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default PolicyCoverDetailsPage;
