import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import CopyToClipboard from 'react-copy-to-clipboard';
import clsx from 'clsx';
import { Tooltip } from '@mui/material';
import { IEnhancedCommonProps } from '.';

interface CopyIconButtonProps extends IEnhancedCommonProps {
  value: string;
  initialTooltip?: string;
  children?: React.ReactNode;
}

const useStyles = makeStyles()(() => ({
  container: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  button: {
    '&:focus': {
      outline: 'none',
    },
  },
}));

const CopyIconButton: React.FC<CopyIconButtonProps> = ({
  className,
  children,
  value,
  initialTooltip,
}) => {
  const { classes } = useStyles();
  const toolTipHoverText = initialTooltip || 'Copy URL to clipboard';
  const [tooltip, setTooltip] = useState<string>(toolTipHoverText);

  return (
    <div className={clsx(classes.container, className)}>
      {children}
      <Tooltip
        title={tooltip}
        onMouseOutCapture={() => {
          setTimeout(() => {
            setTooltip(toolTipHoverText);
          }, 250);
        }}
      >
        <CopyToClipboard
          text={value}
          onCopy={() => {
            setTooltip('Copied to clickboard!');
          }}
        >
          {/* <EnhancedIconButton
            onClick={() => {}}
            title=""
            iconUrl={`${cdnUrl}/icons/copy-primary.svg`}
            iconOnly
            className={classes.button}
          /> */}
        </CopyToClipboard>
      </Tooltip>
    </div>
  );
};

export default CopyIconButton;
