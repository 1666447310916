import React, { useEffect, useState } from "react";
import GenericDrawer from "../../components/common/generic-drawer/GenericDrawer";
import {
  DynamicFormInputType,
  IFormChipsDynamicProps,
  IFormSelectDynamicProps,
} from "../../DynamicForm";
import { toast } from "react-toastify";
import { EnhancedButtonStatus } from "../../components/common/EnhancedButton";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { inputs } from "./content";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import Loader from "../../components/Loader";
import { cloneDeep, isEmpty } from "lodash";
import { DEFAULT_ERROR_TEXT } from "../../constants";
import { IMedicalPlanRatesDrawerInfo, IMedicalPlanRatesDrawerProps } from ".";
import {
  createPlanSpecificMedical,
  fetchedPolicyCoverList,
  getMedicalPlanRatesEnums,
  getPlanSpecificMedicalInfo,
  updatePlanSpecificMedical,
} from "./queries";
import {
  LookupToList,
  extractPolicyCovers,
  graphqlToMedicalPlanRatesInfo,
} from "./utils";
import { getError } from "../../utils/graph-utils";
import { normaliseDynamicValues } from "../../utils/dynamic-utils";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import DynamicForm from "../../DynamicForm/DynamicForm";
import { isZeroValue } from "../../utils/validationUtils";

const MedicalPlanRatesDrawer: React.FC<IMedicalPlanRatesDrawerProps> = ({
  open,
  onSuccess,
  onClose,
  planId,
  lineId,
  planCurrency,
  medicalPlanRateId,
}) => {
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();
  let [submitButtonDisabled, setSubmitButtonDisabled] =
    useState<boolean>(false);
  const [hasPlanActiveToError, setHasPlanActiveToError] = useState("");
  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);

  const [ageFrom, setAgeFrom] = useState<string>();
  const [ageTo, setAgeTo] = useState<string>();
  const [minTPAFees, setMinTPAFees] = useState<string>();
  const [maxTPAFees, setMaxTPAFees] = useState<string>();
  const [comparFromTo, setComparFromTo] = useState<number>(0);
  const [newAllValues, setNewAllValues] = useState<Record<string, any>>();

  const [selectedPolicyCoverID, setSelectedPolicyCoverID] = useState("");
  const [fetchedPolicyCoverListLazy, fetchedPolicyCoverListQueryResult] =
    useLazyQuery(fetchedPolicyCoverList());

  const medicalPlanRatesDetailsListResults = useQuery(
    getMedicalPlanRatesEnums()
  );

  const [medicalPlanRatesAction] = useMutation(
    medicalPlanRateId
      ? updatePlanSpecificMedical()
      : createPlanSpecificMedical()
  );

  let medicalPlanRateInfoResult: any;
  if (medicalPlanRateId) {
    medicalPlanRateInfoResult = useQuery(getPlanSpecificMedicalInfo(), {
      variables: { id: medicalPlanRateId },
    });
  }

  let medicalPlanRatesDetailsInfo: IMedicalPlanRatesDrawerInfo = {
    policyCover: "",
    nbOfAdherent: "",
    class: "",
    ageFrom: "",
    ageTo: "",
    coNssf: "",
    netPremium: "",
    tpaFeesPercentage: "",
    minTPAFees: "",
    maxTPAFees: "",
  };

  let updatedInputs: Record<string, DynamicFormInputType> = inputs;
  let newMedicalPlanRatesEnums: Record<string, Record<string, string>> = {};

  const handleCarChange = (
    carFrom: string,
    carTo: string,
    type1: "Age From" | "Min TPA Fees",
    type2: "Age To" | "Max TPA Fees",
    operand: "less" | "greater"
  ) => {
    let error = "";
    if (carTo && carFrom) {
      if (operand === "less") {
        if (parseInt(carFrom) > parseInt(carTo)) {
          error = `${type1} must be less than ${type2}`;
        }
      } else {
        if (parseInt(carTo) < parseInt(carFrom)) {
          error = `${type2} must be greater than ${type1}`;
        }
      }
    }

    setHasPlanActiveToError(error);
    return error;
  };

  function handlePolicyCoverSelection(selectedOption: any) {
    setSelectedPolicyCoverID(selectedOption);
  }

  function handlePolicyCoverInput(inputValue: any) {
    if (inputValue !== null) {
      if (inputValue.length >= 3) {
        fetchedPolicyCoverListLazy({
          variables: {
            selectedLineIds: [lineId],
            searchKeyword: inputValue,
          },
        });
      }
    } else {
      (updatedInputs.policyCover as IFormChipsDynamicProps).selectOptions = {};
    }
  }

  const initialize = () => {
    try {
      updatedInputs = cloneDeep(inputsForm);
      updatedInputs.planCurrency.value = planCurrency;

      if (medicalPlanRatesDetailsListResults.data) {
        newMedicalPlanRatesEnums = LookupToList(
          medicalPlanRatesDetailsListResults.data
        );

        (updatedInputs.planCurrency as IFormSelectDynamicProps).selectOptions =
          newMedicalPlanRatesEnums["currencies"];

        (updatedInputs.class as IFormSelectDynamicProps).selectOptions =
          newMedicalPlanRatesEnums["medicalPlanClasses"];

        (updatedInputs.coNssf as IFormSelectDynamicProps).selectOptions =
          newMedicalPlanRatesEnums["coNSSFOptions"];
      }

      (updatedInputs.policyCover as IFormChipsDynamicProps).onChange = (
        option
      ) => {
          handlePolicyCoverInput(option);
          handlePolicyCoverSelection(option[0]);
      };

      if (medicalPlanRateInfoResult?.data) {
        medicalPlanRatesDetailsInfo = graphqlToMedicalPlanRatesInfo(
          medicalPlanRateInfoResult?.data
        );
      }

      if (medicalPlanRatesDetailsInfo) {
        updatedInputs.policyCover.value =
          medicalPlanRatesDetailsInfo.policyCover;
        updatedInputs.nbOfAdherent.value =
          medicalPlanRatesDetailsInfo.nbOfAdherent;
        updatedInputs.class.value = medicalPlanRatesDetailsInfo.class;
        updatedInputs.ageFrom.value = medicalPlanRatesDetailsInfo.ageFrom;
        updatedInputs.ageTo.value = medicalPlanRatesDetailsInfo.ageTo;
        updatedInputs.coNssf.value = medicalPlanRatesDetailsInfo.coNssf;
        updatedInputs.netPremium.value = medicalPlanRatesDetailsInfo.netPremium;
        updatedInputs.tpaFeesPercentage.value = `${Math.round(
          parseFloat(
            Number(medicalPlanRatesDetailsInfo.tpaFeesPercentage).toFixed(10)
          )
        )}`;
        updatedInputs.minTPAFees.value = medicalPlanRatesDetailsInfo.minTPAFees;
        updatedInputs.maxTPAFees.value = medicalPlanRatesDetailsInfo.maxTPAFees;

        setAgeFrom(medicalPlanRatesDetailsInfo.ageFrom);
        setAgeTo(medicalPlanRatesDetailsInfo.ageTo);

        setMinTPAFees(medicalPlanRatesDetailsInfo.minTPAFees);
        setMaxTPAFees(medicalPlanRatesDetailsInfo.maxTPAFees);

        if (medicalPlanRatesDetailsInfo?.policyCover) {
          updatedInputs.policyCover.preselectedValues =
            medicalPlanRatesDetailsInfo?.policyCoverTitle;

          handlePolicyCoverInput(medicalPlanRatesDetailsInfo?.policyCover);
          handlePolicyCoverSelection(medicalPlanRatesDetailsInfo?.policyCover);
        }
      }

      setInputsForm(updatedInputs);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputs, values);

    setFormDisabled(true);
    setSubmitButtonState("loading");

    try {
      let variablesMutation = {};
      variablesMutation = {
        planSpecificMedicalInputs: {
          planID: planId,
          ageFrom: Number(data?.ageFrom),
          ageTo: Number(data?.ageTo),
          class: data?.class,
          coNssf: data?.coNssf,
          nbOfAdherent: isZeroValue(data?.nbOfAdherent)
            ? 0
            : Number(data?.nbOfAdherent) || null,
          policyCoverID: selectedPolicyCoverID,
          netPremium: Number(data?.netPremium),
          maxTPAFees: Number(data?.maxTPAFees),
          minTPAFees: Number(data?.minTPAFees),
          tpaFeesPercentage: Number(data?.tpaFeesPercentage) / 100,
        },
      };

      medicalPlanRatesAction({
        variables: medicalPlanRateId
          ? {
              ...variablesMutation,
              entityId: medicalPlanRateId,
            }
          : variablesMutation,
        errorPolicy: "all",
      })
        .then((res) => {
          if (isEmpty(res.errors)) {
            toast.success(
              <ToastSuccessMessage>
                {medicalPlanRateId
                  ? "Plan Rate successfully updated."
                  : "Plan Rate successfully added."}
              </ToastSuccessMessage>
            );
            setTimeout(() => {
              setSubmitButtonState("success");
              onSuccess();
              onClose();
            }, 500);
          } else {
            setSubmitButtonState(undefined);
            toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
          }
        })
        .catch((err) => {
          toast.error(<ToastErrorMessage>{getError(err)}</ToastErrorMessage>);
        });
    } catch {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setFormDisabled(false);
    }
  };

  useEffect(() => {
    initialize();
  }, [
    medicalPlanRatesDetailsListResults.data,
    medicalPlanRateInfoResult?.data,
  ]);

  useEffect(() => {
    if (fetchedPolicyCoverListQueryResult?.data) {
      const extractedFetchedPolicyCover = extractPolicyCovers(
        fetchedPolicyCoverListQueryResult.data
      );

      setInputsForm((currentInputsForm) => {
        const updatedForm = { ...currentInputsForm };

        (updatedForm.policyCover as IFormChipsDynamicProps).selectOptions = {
          ...extractedFetchedPolicyCover,
        };

        return updatedForm;
      });
    } else {
      (updatedInputs.policyCover as IFormChipsDynamicProps).selectOptions = {};
    }
  }, [fetchedPolicyCoverListQueryResult.data]);

  useEffect(() => {
    const errorFrom = handleCarChange(
      ageFrom,
      ageTo,
      "Age From",
      "Age To",
      "less"
    );
    setInputsForm((currentInputsForm) => ({
      ...currentInputsForm,
      ageFrom: {
        ...currentInputsForm.ageFrom,
        value: ageFrom && ageFrom.toString(),
        error: errorFrom,
      },
      nbOfAdherent: {
        ...currentInputsForm.nbOfAdherent,
        value:
          newAllValues &&
          newAllValues.nbOfAdherent &&
          newAllValues.nbOfAdherent.toString(),
      },
    }));
    const errorTo = handleCarChange(
      ageFrom,
      ageTo,
      "Age From",
      "Age To",
      "greater"
    );
    setInputsForm((currentInputsForm) => ({
      ...currentInputsForm,
      ageTo: {
        ...currentInputsForm.ageTo,
        value: ageTo && ageTo.toString(),
        error: errorTo,
      },
      nbOfAdherent: {
        ...currentInputsForm.nbOfAdherent,
        value:
          newAllValues &&
          newAllValues.nbOfAdherent &&
          newAllValues.nbOfAdherent.toString(),
      },
    }));
    const errorminTPA = handleCarChange(
      minTPAFees,
      maxTPAFees,
      "Min TPA Fees",
      "Max TPA Fees",
      "less"
    );
    setInputsForm((currentInputsForm) => ({
      ...currentInputsForm,
      minTPAFees: {
        ...currentInputsForm.minTPAFees,
        value: minTPAFees && minTPAFees.toString(),
        error: errorminTPA,
      },
      nbOfAdherent: {
        ...currentInputsForm.nbOfAdherent,
        value:
          newAllValues &&
          newAllValues.nbOfAdherent &&
          newAllValues.nbOfAdherent.toString(),
      },
    }));
    const errormaxTPA = handleCarChange(
      minTPAFees,
      maxTPAFees,
      "Min TPA Fees",
      "Max TPA Fees",
      "greater"
    );
    setInputsForm((currentInputsForm) => ({
      ...currentInputsForm,
      maxTPAFees: {
        ...currentInputsForm.maxTPAFees,
        value: maxTPAFees && maxTPAFees.toString(),
        error: errormaxTPA,
      },
      nbOfAdherent: {
        ...currentInputsForm.nbOfAdherent,
        value:
          newAllValues &&
          newAllValues.nbOfAdherent &&
          newAllValues.nbOfAdherent.toString(),
      },
    }));
  }, [ageFrom, ageTo, minTPAFees, maxTPAFees, comparFromTo, newAllValues]);

  useEffect(() => {
    setSubmitButtonDisabled(hasPlanActiveToError !== "");
  }, [hasPlanActiveToError]);

  return (
    <GenericDrawer
      title={medicalPlanRateId ? "Modify Plan Rate" : "Add Plan Rate"}
      onClose={() => onClose()}
      isOpen={open}
    >
      {medicalPlanRatesDetailsListResults.loading && open ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={"Submit"}
            submitButtonState={submitButtonState}
            isSubmitButtonDisabled={submitButtonDisabled}
            disableForm={formDisabled}
            title="Information"
            hasDoprdownSpecificBehavior={true}
            onChange={(
              fieldName: string,
              value: string | string[],
              allValues
            ) => {
              if (fieldName === "ageFrom") {
                setAgeFrom(value as string);
                setNewAllValues(allValues);
                setComparFromTo((oldState) => oldState + 1);
              }
              if (fieldName === "ageTo") {
                setAgeTo(value as string);
                setNewAllValues(allValues);
                setComparFromTo((oldState) => oldState + 1);
              }
              if (fieldName === "minTPAFees") {
                setMinTPAFees(value as string);
                setNewAllValues(allValues);
                setComparFromTo((oldState) => oldState + 1);
              }
              if (fieldName === "maxTPAFees") {
                setMaxTPAFees(value as string);
                setNewAllValues(allValues);
                setComparFromTo((oldState) => oldState + 1);
              }
            }}
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default MedicalPlanRatesDrawer;
