import { useLazyQuery, useMutation } from "@apollo/client";
import { Chip } from "@mui/material";
import dayjs from "dayjs";
import { cloneDeep } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { makeStyles } from "tss-react/mui";
import {
  IApplicationDetails,
  IApplicationDetailsPageExpatProps,
  initialValues,
} from ".";
import EnhancedButton from "../../components/EnhancedButton";
import Loader from "../../components/Loader";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { EnhancedButtonStatus } from "../../components/common/EnhancedButton";
import EnhancedStepper from "../../components/common/EnhancedStepper";
import Separator from "../../components/common/Separator";
import WidgetPaper from "../../components/common/WidgetPaper";
import WidgetSection from "../../components/common/WidgetSection";
import QuotesCards, { quote } from "../../components/custom/QuotesCards";
import EnhancedChipInput from "../../components/enhanced-form/EnhancedChipInput";
import EnhancedDatePicker from "../../components/enhanced-form/EnhancedDatePicker";
import EnhancedInput from "../../components/enhanced-form/EnhancedInput";
import EnhancedInternationalPhoneInput from "../../components/enhanced-form/EnhancedInternationalPhoneInput";
import { IEntityInfoView } from "../../components/widgets/entity-info/EntityInfoFields";
import EntityInfoWidget from "../../components/widgets/entity-info/EntityInfoWidget";
import {
  DEFAULT_ERROR_TEXT,
  MAIN_ONE_THEME,
  contentFontFamilyBold,
} from "../../constants";
import StaticLayout from "../../page-layout/static-layout/StaticLayout";
import { useAppSelector } from "../../redux/hooks";
import { getDefaultDate } from "../../utils/date-utils";
import {
  capitalizeFirstLetter,
  dateStringToISOString,
} from "../../utils/formatting-utils";
import { isEmpty } from "../../utils/validationUtils";
import { entityView, steps } from "./content";
import {
  calculateAction,
  checkActiveExpatPolicy,
  getApplicationDetails,
  issuePolicy,
  previewPolicies,
} from "./queries";
import {
  checkDate,
  checkEmail,
  checkPhoneNumber,
  entityToApplicationDetails,
  extractLovs,
  getApplicationProgress,
  getApplicationStatus,
  getFirstInsuranceQuoteProductCode,
  getFirstInsuranceQuoteProductName,
  graphqlEntityToQuoteDetails,
} from "./utils";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import { getError } from "../../utils/graph-utils";
import { createActionAsAgent } from "../../pages/expat-applications-listing/queries";
import ApplicationDrawer from "../application-drawer/ApplicationDrawer";
import ApplicationDrawerWithMultipleAgencies from "../application-with-multiple-agencies-drawer/ApplicationDrawerWithMultipleAgencies";
import ShowForUser from "../../components/user/ShowForUser";

const useStyles = makeStyles()(() => ({
  stepper: {
    backgroundColor: "transparent",
    maxWidth: "90%",
    marginBottom: "20px",
    "& .MuiStepLabel-root .Mui-active, .MuiStepLabel-root .Mui-completed": {
      color: MAIN_ONE_THEME.palette.primary5.main,
    },

    "@media only screen and (min-width: 600px)": {
      maxWidth: "1300px",
    },
  },
  section: {
    display: "grid",
    "grid-template-columns": "19.5% 19.5% 19.5% 19.5% 19.5%",
    justifyContent: "space-between",
    flexWrap: "wrap",
    "& label": {
      "& span": {
        margin: "0 0 0",
      },
    },
  },
  sectionFullRow: {
    display: "grid",
    "grid-template-columns": "100%",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  row: {
    flexWrap: "wrap",
    margin: "10px",
    alignItems: "flex-start",
    display: "grid",
    gap: "20px",
    "& label": {
      flexGrow: "1",
      flexBasis: "0",
      minWidth: "0",
    },
    "grid-template-columns": "15% 15% 15% 15% 15% 12.5% 12.5%",
  },
  thickSeperator: {
    height: 10,
    margin: "10px 0 10px 0",
    "grid-column-start": "1",
    "grid-column-end": "4",
  },
  field: {
    width: "96%",
    marginRight: "10px !important",
  },
  arField: {
    width: "96%",
    marginRight: "10px !important",
    direction: "rtl",
  },
  header: {
    fontFamily: contentFontFamilyBold,
    fontSize: MAIN_ONE_THEME.typography.regular.reg3.fontSize,
    color: MAIN_ONE_THEME.palette.primary2.main,
    "&:hover": {
      color: MAIN_ONE_THEME.palette.primary1.main,
    },
  },
  content: {
    padding: "0",
  },
  dirtyChip: {
    margin: "0 0 -15px 0",
    backgroundColor: MAIN_ONE_THEME.palette.primary2.main,
    "& span": {
      color: "#ffffff",
    },
  },
  repeaterField: {
    margin: "0px!important",
  },
}));

const ApplicationDetailsPageExpat: React.FC<
  IApplicationDetailsPageExpatProps
> = () => {
  //#region react and redux hooks
  const { classes } = useStyles();
  const tenant = useAppSelector((state) => state.tenant);
  const params = useParams();
  const navigate = useNavigate();
  //#endregion

  //#region component hooks and states
  const [applicationInfo, setApplicationInfo] =
    useState<IApplicationDetails>(initialValues);

  const [updatedLeftSideInfo, setUpdatedLeftSideInfo] =
    useState<IEntityInfoView>(entityView);

  const [lovs, setLovs] =
    useState<Record<string, Record<string, string>>>(null);

  const [loadingState, setLoadingState] = useState<boolean>(true);
  const [lob, setLob] = useState<string>("");

  const [showUpdateMessage, setsShowUpdateMessage] = useState<boolean>(false);
  const [disabledSendBtn, setDisabledSendBtn] = useState<boolean>(true);
  const [disabledAcceptBtn, setDisabledAcceptBtn] = useState<boolean>(true);
  const [disabledIssuePolicyBtn, setDisabledIssuePolicyBtn] =
    useState<boolean>(true);
  const [newAppDrawerOpen, setNewAppDrawerOpen] = useState<boolean>(false);
  const [
    newApplicationWithMultipleAgencieDrawerOpen,
    setNewApplicationWithMultipleAgencieDrawerOpen,
  ] = useState<boolean>(false);
  const [newButtonState, setNewButtonState] = useState<EnhancedButtonStatus>();

  const countryCode = useRef<string>();
  const calculateClickedFirstTime = useRef<boolean>();

  const [isUnderwriter, setIsUnderwriter] = useState<boolean>(false);
  const [isIssuingAgent, setIsIssuingAgent] = useState<boolean>(false);
  const [isQuotingAgent, setIsQuotingAgent] = useState<boolean>(false);

  const [agentType, setAgentType] = useState<string>("");

  const {
    control,
    handleSubmit,
    trigger,
    getValues,
    reset,
    setValue,
    setError,
    clearErrors,
    formState: { isDirty, errors },
  } = useForm<IApplicationDetails>({
    defaultValues: initialValues,
    shouldUnregister: true,
  });

  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();

  const [formDisabled, setFormDisabled] = useState(false);

  const user = useAppSelector((state) => state.user);
  const entityId = params.id;
  //#endregion

  let today = new Date();
  let threeMonthsAgo = new Date(today);
  threeMonthsAgo.setMonth(today.getMonth() - 3);
  const userAgentType = user.info.agentType;

  //#region graphql hooks
  const [getApplicationDetailsLazy, entityResult] = useLazyQuery(
    getApplicationDetails(),
    {
      variables: { id: entityId },
      errorPolicy: "all",
      fetchPolicy: "no-cache",
    }
  );

  const [issuePolicyAction] = useMutation(issuePolicy(), {
    refetchQueries: [getApplicationDetails()],
  });

  const [previewPoliciesAction] = useMutation(previewPolicies(), {
    refetchQueries: [getApplicationDetails()],
  });

  const [checkActiveExpatPolicyLazy] = useLazyQuery(
    checkActiveExpatPolicy(),
    {}
  );

  const [mutateFunction] = useMutation(calculateAction(), {
    refetchQueries: [getApplicationDetails()],
    errorPolicy: "all",
    fetchPolicy: "no-cache",
  });

  const [createAppMutateFunction] = useMutation(createActionAsAgent(), {
    refetchQueries: [getApplicationDetails()],
    awaitRefetchQueries: true,
    errorPolicy: "all",
  });

  const isClosed =
    entityResult?.data?.Insurance?.entities?.application?.views?.Insurance_all?.properties?.Status.toLowerCase() ===
    "closed";
  const isEnabled =
    entityResult?.data?.Insurance?.entities?.application?.actions
      ?.able_To_SimulateAndSaveExpat;
  const buttonLabel = "Calculate";
  const buttonType = Object.keys(errors).length === 0 ? "submit" : "button";
  //#endregion

  //#region useEffect

  useEffect(() => {
    reset({
      ...initialValues,
    });
    getApplicationDetailsLazy();
  }, [entityId]);

  useEffect(() => {
    try {
      if (!entityResult.loading) {
        setLoadingState(false);
      }

      if (entityResult?.data) {
        const extractedLovs = extractLovs(entityResult?.data, userAgentType);

        setLovs(extractedLovs);
        const applicationDetails = entityToApplicationDetails(
          entityResult?.data
        );

        applicationDetails.product = getFirstInsuranceQuoteProductCode(
          entityResult?.data
        );
        if (isEmpty(applicationDetails.product)) {
          const products = user?.info?.products?.filter((a) =>
            Object.keys(extractedLovs?.products || {}).includes(a)
          );
          if (products?.length === 1) {
            applicationDetails.product = products[0];
            applicationDetails.name =
              extractedLovs.products[applicationDetails.product];
          }
        } else {
          applicationDetails.name = getFirstInsuranceQuoteProductName(
            entityResult?.data
          );
        }

        applicationDetails.createdOn = getDefaultDate(
          dayjs(
            applicationDetails.createdOn
              ? new Date(applicationDetails.createdOn)
              : new Date()
          ).toDate()
        ).toDateString();

        applicationDetails.effectiveDate = getDefaultDate(
          dayjs(
            applicationDetails.effectiveDate
              ? new Date(applicationDetails.effectiveDate)
              : new Date()
          ).toDate()
        ).toDateString();

        if (applicationDetails.expiryDate) {
          applicationDetails.expiryDate = getDefaultDate(
            dayjs(new Date(applicationDetails.expiryDate)).toDate()
          ).toDateString();
        } else {
          applicationDetails.expiryDate = getDefaultDate(
            dayjs(new Date()).add(1, "y").toDate()
          ).toDateString();
        }

        //get application section properties
        const applicationProperties = entityView.sections[0];
        applicationProperties.properties.stateReason.value =
          capitalizeFirstLetter(
            applicationDetails?.statusReason?.replaceAll("_", " ")
          );
        applicationProperties.properties.lineOfBusiness.value =
          applicationDetails.lineOfBusiness;
        applicationProperties.properties.agency.value =
          applicationDetails.agencyName;
        applicationProperties.properties.agentName.value =
          applicationDetails.agentName;
        applicationProperties.properties.customerName.value =
          applicationDetails.customerName;

        setLob(applicationDetails.lineOfBusiness);

        //get the system propeties section
        const systemProperties = entityView.sections[1];
        systemProperties.properties.createdBy.value =
          applicationDetails.createdBy;
        //before setting date in date picker, use dayjs to format the datetime in case the value is coming from BE
        systemProperties.properties.createdOn.value = dayjs(
          new Date(applicationDetails.createdOn)
        ).format("DD/MM/YYYY");
        systemProperties.properties.modifiedBy.value =
          applicationDetails.modifiedBy;
        systemProperties.properties.modifiedOn.value = dayjs(
          new Date(applicationDetails.modifiedOn)
        ).format("DD/MM/YYYY");

        const newLeftEntityView = {
          ...entityView,
          sections: [applicationProperties, systemProperties],
        };

        setApplicationInfo(applicationDetails);

        setUpdatedLeftSideInfo(newLeftEntityView);

        reset({
          ...applicationDetails,
          mobileNumber: applicationDetails.mobileNumber
            ? applicationDetails.mobileNumber
            : countryCode.current,
        });

        const isUnderwriteer = user.userRoles.includes("Insurance-Underwriter");
        const isQuotiing = user.userRoles.includes("Insurance-QuotingAgent");
        const isIssuiing = user.userRoles.includes("Insurance-IssuingAgent");
        setIsUnderwriter(isUnderwriteer);
        setIsQuotingAgent(isQuotiing);
        setIsIssuingAgent(isIssuiing);

        setAgentType(
          isUnderwriteer
            ? "underwriter"
            : isQuotiing || isIssuiing
            ? "agent"
            : ""
        );

        setFormDisabled(
          entityResult?.data?.Insurance?.entities?.application?.views?.Insurance_all?.properties?.Status.toLowerCase() ==
            "closed" || !(isUnderwriteer || isQuotiing || isIssuiing)
        );
      }
    } catch (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  }, [entityResult?.data]);

  useEffect(() => {
    const updatedDropDownLists = cloneDeep(lovs);
    setLovs(updatedDropDownLists);
  }, []);

  useEffect(() => {
    if (isDirty && calculateClickedFirstTime.current) {
      setsShowUpdateMessage(true);
      setDisabledSendBtn(true);
      setDisabledAcceptBtn(true);
      setDisabledIssuePolicyBtn(true);
    } else {
      setDisabledSendBtn(false);
      setDisabledSendBtn(false);
      setDisabledAcceptBtn(false);
      setDisabledIssuePolicyBtn(false);
    }
  }, [isDirty]);
  //#endregion

  //#region functions

  const onSubmit: SubmitHandler<IApplicationDetails> = (data) => {
    if (isEnabled && buttonLabel === "Calculate" && !isClosed) {
      if (!calculateClickedFirstTime.current) {
        calculateClickedFirstTime.current = true;
      }
      setsShowUpdateMessage(false);
      setFormDisabled(true);
      setSubmitButtonState("loading");

      mutateFunction({
        variables: {
          entityId: entityId,
          effectiveDate: dateStringToISOString(data.effectiveDate),
          isRenewal: data.isRenewal,
          product: data.product || applicationInfo.product,
          renewalNumber: data.renewalNumber,
          insuredInputs: {
            insuredFirstName: data.insuredFirstName,
            insuredMiddleName: data.insuredMiddleName || null,
            insuredLastName: data.insuredLastName,
            insuredDateOfBirth: dateStringToISOString(data.insuredDateofBirth),
            insuredNationality: data.insuredNationality,
            insuredGender: data.insuredGender,
            insuredPassportNumber: data.insuredPassportNumber,
            insuredOccupation: data.insuredOccupation,
          },
          applicationContactInputs: {
            title: data.title,
            firstName: data.firstName,
            middleName: data.middleName,
            lastName: data.lastName,
            email: data.email || null,
            phoneType: data.phoneType,
            mobileNumber:
              data.mobileNumber && data.mobileNumber.length > 3
                ? data.mobileNumber.charAt(0) == "+"
                  ? data.mobileNumber
                  : "+" + data.mobileNumber
                : undefined,
            country: data.country,
            city: data.city,
            street: data.street || null,
            building: data.building || null,
          },
        },
      })
        .then((res) => {
          if (isEmpty(res.errors)) {
            setSubmitButtonState("success");
            toast.success(<ToastSuccessMessage>Success</ToastSuccessMessage>);
          } else {
            setSubmitButtonState(undefined);
            toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
          }
        })
        .finally(() => {
          setSubmitButtonState(undefined);
          setFormDisabled(false);
        });
    }
  };

  const checkSubmission = () => {
    if (isEnabled && isClosed) {
      if ((isIssuingAgent || isQuotingAgent) && !isUnderwriter) {
        user.info.agencyId.length === 0 || user.info.agencyId.length === 1
          ? submitFormAsAgent()
          : setNewApplicationWithMultipleAgencieDrawerOpen(true);

        setNewButtonState(undefined);
      } else if (isUnderwriter && !(isIssuingAgent || isQuotingAgent)) {
        user.info.agencyId.length === 0 || user.info.agencyId.length === 1
          ? setNewAppDrawerOpen(true)
          : setNewApplicationWithMultipleAgencieDrawerOpen(true);

        setNewButtonState(undefined);
      }
    }

    reset({
      ...initialValues,
    });
  };

  const submitFormAsAgent = async () => {
    try {
      const { data, errors } = await createAppMutateFunction({
        variables: { lineOfBusiness: "Expat" },
      });
      if (isEmpty(errors)) {
        setNewButtonState("success");
        toast.success(
          <ToastSuccessMessage>
            {"Application created successfully"}
          </ToastSuccessMessage>
        );
        const newApplicationId =
          data.insurance.actions.createApplicationAsAgent.id;
        setTimeout(() => {
          navigate("/sales/applications/expat/" + newApplicationId);
        }, 500);
      } else {
        toast.error(<ToastErrorMessage>{getError(data)}</ToastErrorMessage>);
      }
    } catch {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setNewButtonState(undefined);
    }
  };

  const validatePolicyonEffectiveDateorPassportNumberChange = (
    effectiveDate: string,
    passportNumber: string
  ) => {
    if (
      !isEmpty(getValues("effectiveDate")) &&
      !isEmpty(getValues("insuredPassportNumber"))
    ) {
      checkActiveExpatPolicyLazy({
        variables: {
          effectiveDate: dayjs(new Date(getValues("effectiveDate"))).format(
            "YYYY-MM-DD"
          ),
          insuredPassportNumber: getValues("insuredPassportNumber"),
        },
      }).then((response) => {
        if (
          response.data.Insurance?.queries?.CheckActiveExpatPolicy.length > 0
        ) {
          const policyAlreadyExistsErrorMessage = `Current Expat policy exists for this insured with Policy Number ${
            response?.data?.Insurance?.queries.CheckActiveExpatPolicy[0]
              ?.insurance_Policy_FullPolicyNumber
          } that expires on ${dayjs(
            new Date(
              response?.data?.Insurance?.queries.CheckActiveExpatPolicy[0]?.insurance_Policy_PolicyExpiryDate
            )
          ).format("DD/MM/YYYY")}`;
          setError("insuredPassportNumber" as keyof typeof initialValues, {
            type: "custom",
            message: policyAlreadyExistsErrorMessage,
          });
        } else {
          clearErrors("insuredPassportNumber" as keyof typeof initialValues);
        }
      });
    }
  };

  const renderMainChildren = () => {
    let applicationStatus =
      entityResult?.data?.Insurance?.entities?.application?.views?.Insurance_all?.properties?.Status.toLowerCase();

    let quotes: quote[] = [];
    entityResult?.data?.Insurance?.entities?.application?.queries?.insurance?.applicationQuotes?.map(
      (plan: any, i: number) => {
        let quoteEntity = graphqlEntityToQuoteDetails(
          entityResult?.data,
          i,
          tenant.currencySymbol,
          lob
        );
        quotes.push(quoteEntity);
      }
    );

    return (
      <>
        <div style={{ marginTop: "20px" }}>
          <EnhancedStepper
            showStepperButton={isClosed}
            buttonTitle={"New"}
            buttonState={newButtonState}
            activeStep={getApplicationStatus(
              entityResult?.data?.Insurance?.entities?.application?.views
                ?.Insurance_all?.properties?.Status
            )}
            steps={steps}
            className={classes.stepper}
            buttonOnClick={() => {
              setNewButtonState("loading");
              checkSubmission();
            }}
            reverseButton={true}
          />
          {applicationStatus != "new" && (
            <>
              <QuotesCards
                entityId={entityId}
                applicationStatus={applicationStatus}
                quotes={quotes}
                issuePolicyAction={issuePolicyAction}
                previewPoliciesAction={previewPoliciesAction}
                customerEmail={getValues("email") || null}
                disabled={formDisabled}
                applicationCard={true}
                userCanSend={
                  entityResult?.data?.Insurance?.entities?.application?.actions
                    ?.able_To_SendQuotes
                }
                disabledSendButton={disabledSendBtn}
                disabledAcceptButton={disabledAcceptBtn}
                disabledIssuePolicyButton={disabledIssuePolicyBtn}
                userCanAccept={
                  entityResult?.data?.Insurance?.entities?.application?.actions
                    ?.able_To_AcceptQuotes
                }
                userCanIssue={
                  entityResult?.data?.Insurance?.entities?.application?.actions
                    ?.able_To_IssuePolicy
                }
                userCanDownloadQuote={
                  entityResult?.data?.Insurance?.entities?.application?.actions
                    ?.able_To_PreviewQuotes
                }
                userCanPreviewPolicies={
                  entityResult?.data?.Insurance?.entities?.application?.actions
                    ?.able_To_PreviewPolicies
                }
                lineOfBusiness={lob}
                quoteInputs={{
                  applicationContactInputs: {
                    building: getValues("building"),
                    city: getValues("city"),
                    country: getValues("country"),
                    email: getValues("email"),
                    fullName:
                      getValues("firstName") +
                      getValues("middleName") +
                      getValues("lastName"),
                    mobileNumber: getValues("mobileNumber"),
                    street: getValues("street"),
                    title: getValues("title"),
                  },
                }}
              />
              <Separator className={classes.thickSeperator} />
            </>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <WidgetPaper>
              <WidgetSection
                title='Application Details'
                narrowSpacing={true}
                style={{ margin: "-20px 0" }}
                classes={{ container: classes.section }}
                actions={
                  !isClosed && isEnabled && (
                    <EnhancedButton
                      type={buttonType}
                      state={submitButtonState}
                      backgroundColor={MAIN_ONE_THEME.palette.primary1.main}
                      color='#FFF'
                      style={{ margin: "0 0 -15px 0" }}
                    >
                      {buttonLabel}
                    </EnhancedButton>
                  )
                }
                middle={
                  showUpdateMessage &&
                  isDirty &&
                  calculateClickedFirstTime.current ? (
                    <Chip
                      label={
                        "Form fields changed, please re-calculate to update the application accordingly"
                      }
                      className={classes.dirtyChip}
                    />
                  ) : (
                    <></>
                  )
                }
                useSeparator={false}
              >
                <Controller
                  name='effectiveDate'
                  control={control}
                  rules={{
                    required: "Required",
                    validate: (value) => checkDate(value) || "Invalid Date",
                  }}
                  render={({ field: { onBlur, onChange, value } }) => (
                    <EnhancedDatePicker
                      name={"effectiveDate"}
                      onBlur={onBlur}
                      onDateChange={(e) => {
                        onChange(e);
                        trigger("effectiveDate" as keyof typeof initialValues);

                        const selectedDate = dayjs(e);
                        const effectiveDate = selectedDate
                          .toDate()
                          .toDateString();
                        let expiryDate;

                        if (selectedDate.month() === 11) {
                          // If the selected month is December, set expiryDate to the last day of December
                          expiryDate = selectedDate
                            .endOf("month")
                            .toDate()
                            .toDateString();
                        } else {
                          // Otherwise, set expiryDate to one year later
                          expiryDate = selectedDate
                            .add(1, "y")
                            .toDate()
                            .toDateString();
                        }

                        setValue("effectiveDate", effectiveDate);
                        setValue("expiryDate", expiryDate);

                        validatePolicyonEffectiveDateorPassportNumberChange(
                          effectiveDate,
                          getValues("insuredPassportNumber")
                        );
                      }}
                      title='Effective Date*'
                      material
                      format={tenant.dateFormat}
                      value={
                        !isEmpty(value) ? value : initialValues.effectiveDate
                      }
                      disabled={formDisabled}
                      className={classes.field}
                      error={errors?.effectiveDate?.message}
                      minDate={
                        userAgentType === "INTERNAL"
                          ? threeMonthsAgo
                          : new Date()
                      }
                      maxDate={
                        dayjs().month() === 11
                          ? dayjs().endOf("month").toDate()
                          : dayjs().add(1, "month").toDate()
                      }
                    />
                  )}
                />
                <Controller
                  name='expiryDate'
                  control={control}
                  render={({ field: { onBlur, onChange, value } }) => (
                    <EnhancedDatePicker
                      name={"expiryDate"}
                      onBlur={onBlur}
                      onDateChange={(e) => {}}
                      title='Expiry Date*'
                      material
                      disabled
                      format={tenant.dateFormat}
                      value={value}
                      className={classes.field}
                    />
                  )}
                />

                <ShowForUser allowedRoles={["Insurance-IssuingAgent"]}>
                  <Controller
                    name={"isRenewal"}
                    control={control}
                    rules={{ required: "required" }}
                    key={"isRenewal"}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <EnhancedChipInput
                        name={"isRenewal"}
                        title='Renewal*'
                        value={value || ""}
                        onChange={(e) => {
                          const newValue = e as string;
                          onChange(newValue);
                          trigger(`isRenewal` as keyof typeof initialValues);
                        }}
                        onBlur={(e) => {
                          onBlur();
                          trigger("isRenewal" as keyof typeof initialValues);
                        }}
                        selectOptions={lovs?.renewalStatus}
                        material
                        error={errors?.isRenewal?.message}
                        multiple={false}
                        disabled={formDisabled}
                        className={classes.field}
                      />
                    )}
                  />
                  {getValues("isRenewal") == "YES" && (
                    <Controller
                      name='renewalNumber'
                      control={control}
                      rules={{
                        required: {
                          value: getValues("isRenewal") == "YES",
                          message: "Required",
                        },
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <EnhancedInput
                          type='text'
                          name='renewalNumber'
                          title='Renewal Number*'
                          onChange={(e) => {
                            trigger(
                              "renewalNumber" as keyof typeof initialValues
                            );
                            onChange(e);
                          }}
                          onBlur={(e) => {
                            trigger(
                              "renewalNumber" as keyof typeof initialValues
                            );
                            onBlur();
                          }}
                          value={!isEmpty(value) ? value : ""}
                          error={errors?.renewalNumber?.message}
                          className={classes.field}
                          material
                          disabled={formDisabled}
                        />
                      )}
                    />
                  )}
                </ShowForUser>
              </WidgetSection>
              <WidgetSection
                title='Insured Details'
                narrowSpacing={true}
                classes={{
                  container: classes.section,
                }}
                useSeparator={false}
                style={{ marginTop: "15px" }}
              >
                <Controller
                  name='insuredFirstName'
                  control={control}
                  rules={{ required: "Required" }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <EnhancedInput
                      type='text'
                      name='insuredFirstName'
                      title='Insured First Name*'
                      placeholder={"Insured First Name"}
                      onChange={(e) => {
                        trigger(
                          "insuredFirstName" as keyof typeof initialValues
                        );
                        onChange(e);
                      }}
                      onBlur={(e) => {
                        trigger(
                          "insuredFirstName" as keyof typeof initialValues
                        );
                        onBlur();
                      }}
                      value={!isEmpty(value) ? value : ""}
                      error={errors?.insuredFirstName?.message}
                      className={classes.field}
                      material
                      disabled={formDisabled}
                    />
                  )}
                />
                <Controller
                  name='insuredMiddleName'
                  control={control}
                  rules={{ required: false }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <EnhancedInput
                      type='text'
                      name='insuredMiddleName'
                      title='Insured Middle Name'
                      placeholder={"Insured Middle Name"}
                      onChange={(e) => {
                        trigger(
                          "insuredMiddleName" as keyof typeof initialValues
                        );
                        onChange(e);
                      }}
                      onBlur={(e) => {
                        trigger(
                          "insuredMiddleName" as keyof typeof initialValues
                        );
                        onBlur();
                      }}
                      value={!isEmpty(value) ? value : ""}
                      error={errors?.insuredMiddleName?.message}
                      className={classes.field}
                      material
                      disabled={formDisabled}
                    />
                  )}
                />
                <Controller
                  name='insuredLastName'
                  control={control}
                  rules={{ required: "Required" }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <EnhancedInput
                      type='text'
                      name='insuredLastName'
                      title='Insured Last Name*'
                      placeholder={"Insured Last Name"}
                      onChange={(e) => {
                        trigger(
                          "insuredLastName" as keyof typeof initialValues
                        );
                        onChange(e);
                      }}
                      onBlur={(e) => {
                        trigger(
                          "insuredLastName" as keyof typeof initialValues
                        );
                        onBlur();
                      }}
                      value={!isEmpty(value) ? value : ""}
                      error={errors?.insuredLastName?.message}
                      className={classes.field}
                      material
                      disabled={formDisabled}
                    />
                  )}
                />
                <Controller
                  name='insuredDateofBirth'
                  control={control}
                  render={({ field: { onBlur, onChange, value } }) => (
                    <EnhancedDatePicker
                      name={"insuredDateofBirth"}
                      onBlur={onBlur}
                      onDateChange={(e) => {
                        onChange(e);
                        trigger(
                          "insuredDateofBirth" as keyof typeof initialValues
                        );

                        const insuredDOB = dayjs(e).toDate().toDateString();

                        setValue("insuredDateofBirth", insuredDOB);
                      }}
                      title='Insured Date of Birth*'
                      material
                      format={tenant.dateFormat}
                      value={
                        !isEmpty(value) ? value : initialValues.effectiveDate
                      }
                      disabled={formDisabled}
                      className={classes.field}
                    />
                  )}
                />
                {lovs != null && (
                  <>
                    <Controller
                      name='insuredNationality'
                      control={control}
                      rules={{ required: "Required" }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <EnhancedChipInput
                          name={`insuredNationality`}
                          title='Insured Nationality*'
                          placeholder={"Insured Nationality"}
                          value={!isEmpty(value) ? value : ""}
                          onChange={(e) => {
                            onChange(e as string);
                            trigger(
                              `insuredNationality` as keyof typeof initialValues
                            );
                          }}
                          onBlur={(e) => {
                            onBlur();
                            trigger(
                              "insuredNationality" as keyof typeof initialValues
                            );
                          }}
                          selectOptions={lovs?.nationalities}
                          error={
                            errors.insuredNationality != undefined &&
                            !isEmpty(errors?.insuredNationality)
                              ? errors?.insuredNationality?.message
                              : null
                          }
                          multiple={false}
                          material
                          disabled={formDisabled}
                          className={classes.field}
                        />
                      )}
                    />
                    <Controller
                      name='insuredGender'
                      control={control}
                      rules={{ required: "Required" }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <EnhancedChipInput
                          name={`insuredGender`}
                          title='Insured Gender*'
                          placeholder={"Insured Gender"}
                          value={!isEmpty(value) ? value : ""}
                          onChange={(e) => {
                            onChange(e as string);
                            trigger(
                              `insuredGender` as keyof typeof initialValues
                            );
                          }}
                          onBlur={(e) => {
                            onBlur();
                            trigger(
                              "insuredGender" as keyof typeof initialValues
                            );
                          }}
                          selectOptions={lovs?.genders}
                          error={
                            errors.insuredGender != undefined &&
                            !isEmpty(errors?.insuredGender)
                              ? errors?.insuredGender?.message
                              : null
                          }
                          multiple={false}
                          material
                          disabled={formDisabled}
                          className={classes.field}
                        />
                      )}
                    />
                  </>
                )}
                <Controller
                  name='insuredPassportNumber'
                  control={control}
                  rules={{ required: "Required" }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <EnhancedInput
                      type='text'
                      name='insuredPassportNumber'
                      title='Insured Passport Number*'
                      placeholder={"Insured Passport Number"}
                      onChange={(e) => {
                        const input = e as unknown as string;
                        trigger(
                          "insuredPassportNumber" as keyof typeof initialValues
                        );
                        onChange(e);
                        validatePolicyonEffectiveDateorPassportNumberChange(
                          getValues("effectiveDate"),
                          input
                        );
                      }}
                      onBlur={(e) => {
                        const selectedValue = getValues(
                          "insuredPassportNumber"
                        );
                        validatePolicyonEffectiveDateorPassportNumberChange(
                          getValues("effectiveDate"),
                          selectedValue
                        );
                      }}
                      value={!isEmpty(value) ? value : ""}
                      error={errors?.insuredPassportNumber?.message}
                      className={classes.field}
                      material
                      disabled={formDisabled}
                    />
                  )}
                />

                {lovs != null && (
                  <Controller
                    name='insuredOccupation'
                    control={control}
                    rules={{ required: "Required" }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <EnhancedChipInput
                        name='insuredOccupation'
                        title='Insured Occupation*'
                        placeholder={"Insured Occupation"}
                        value={!isEmpty(value) ? value : ""}
                        onChange={(e) => {
                          onChange(e as string);
                          trigger(
                            `insuredOccupation` as keyof typeof initialValues
                          );
                        }}
                        onBlur={(e) => {
                          onBlur();
                          trigger(
                            "insuredOccupation" as keyof typeof initialValues
                          );
                        }}
                        selectOptions={lovs?.occupations}
                        error={
                          errors.insuredOccupation != undefined &&
                          !isEmpty(errors?.insuredOccupation)
                            ? errors?.insuredOccupation?.message
                            : null
                        }
                        multiple={false}
                        material
                        disabled={formDisabled}
                        className={classes.field}
                      />
                    )}
                  />
                )}
              </WidgetSection>
              <WidgetSection
                specificTitleDesign={
                  <>
                    Subscriber's Details{" "}
                    <span
                      style={{
                        fontStyle: "italic",
                        fontSize: `${MAIN_ONE_THEME.typography.regular.reg3.fontSize}px`,
                      }}
                    >
                      (Please enter all details in Arabic)
                    </span>
                  </>
                }
                hasTitleSpecificDesign={true}
                narrowSpacing={true}
                classes={{
                  container: classes.section,
                }}
                useSeparator={false}
                style={{ marginTop: "15px" }}
              >
                {lovs != null && (
                  <Controller
                    name='title'
                    control={control}
                    rules={{ required: "Required" }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <EnhancedChipInput
                        name={`title`}
                        title='Title*'
                        placeholder={"Title"}
                        value={!isEmpty(value) ? value : ""}
                        onChange={(e) => {
                          onChange(e as string);
                          trigger(`title` as keyof typeof initialValues);
                        }}
                        onBlur={(e) => {
                          onBlur();
                          trigger("title" as keyof typeof initialValues);
                        }}
                        selectOptions={lovs?.titles}
                        error={
                          errors.title != undefined && !isEmpty(errors?.title)
                            ? errors?.title?.message
                            : null
                        }
                        multiple={false}
                        material
                        disabled={formDisabled}
                        className={classes.field}
                      />
                    )}
                  />
                )}

                <Controller
                  name='firstName'
                  control={control}
                  rules={{
                    required: "Required",
                    // validate: {
                    //   isArabic: (value) =>
                    //     isArabicText(value) ||
                    //     "Please enter all details in Arabic",
                    // },
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <EnhancedInput
                      type='text'
                      name='firstName'
                      title='First Name*'
                      placeholder={"First Name"}
                      onChange={(e) => {
                        trigger("firstName" as keyof typeof initialValues);
                        onChange(e);
                      }}
                      onBlur={(e) => {
                        trigger("firstName" as keyof typeof initialValues);
                        onBlur();
                      }}
                      value={!isEmpty(value) ? value : ""}
                      error={errors?.firstName?.message}
                      className={classes.field}
                      material
                      disabled={formDisabled}
                    />
                  )}
                />
                <Controller
                  name='middleName'
                  control={control}
                  rules={{
                    required: "Required",
                    // validate: {
                    //   isArabic: (value) =>
                    //     isArabicText(value) ||
                    //     "Please enter all details in Arabic",
                    // },
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <EnhancedInput
                      type='text'
                      name='middleName'
                      title='Middle Name*'
                      placeholder={"Middle Name"}
                      onChange={(e) => {
                        trigger("middleName" as keyof typeof initialValues);
                        onChange(e);
                      }}
                      onBlur={(e) => {
                        trigger("middleName" as keyof typeof initialValues);
                        onBlur();
                      }}
                      value={!isEmpty(value) ? value : ""}
                      error={errors?.middleName?.message}
                      className={classes.field}
                      material
                      disabled={formDisabled}
                    />
                  )}
                />
                <Controller
                  name='lastName'
                  control={control}
                  rules={{
                    required: "Required",
                    // validate: {
                    //   isArabic: (value) =>
                    //     isArabicText(value) ||
                    //     "Please enter all details in Arabic",
                    // },
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <EnhancedInput
                      type='text'
                      name='lastName'
                      title='Last Name*'
                      placeholder={"Last Name"}
                      onChange={(e) => {
                        trigger("lastName" as keyof typeof initialValues);
                        onChange(e);
                      }}
                      onBlur={(e) => {
                        trigger("lastName" as keyof typeof initialValues);
                        onBlur();
                      }}
                      value={!isEmpty(value) ? value : ""}
                      error={errors?.lastName?.message}
                      className={classes.field}
                      material
                      disabled={formDisabled}
                    />
                  )}
                />

                <Controller
                  name='mobileNumber'
                  control={control}
                  rules={{
                    validate: {
                      requried: (value) =>
                        checkPhoneNumber(value) || "required",
                    },
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <EnhancedInternationalPhoneInput
                      name={"mobileNumber"}
                      title={"Phone Number*"}
                      placeholder={"Phone Number"}
                      onChange={(e) => {
                        onChange(e);
                        trigger("mobileNumber" as keyof typeof initialValues);

                        if (isEmpty(e)) {
                          setValue("mobileNumber", undefined);
                        }
                      }}
                      onBlur={(e) => {
                        onBlur();

                        if (isEmpty(e)) {
                          setValue("mobileNumber", undefined);
                        }

                        trigger("mobileNumber" as keyof typeof initialValues);
                      }}
                      value={!isEmpty(value) ? value : ""}
                      error={errors?.mobileNumber?.message}
                      className={classes.field}
                      material
                      disabled={formDisabled}
                      disableDropDown={false}
                      editCountryCode={false}
                      defaultCountry={"lb"}
                    />
                  )}
                />

                {lovs != null && (
                  <Controller
                    name='country'
                    control={control}
                    rules={{ required: "Required" }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <EnhancedChipInput
                        name={`country`}
                        title='Country*'
                        placeholder={"Country"}
                        value={!isEmpty(value) ? value : ""}
                        onChange={(e) => {
                          onChange(e as string);
                          trigger(`country` as keyof typeof initialValues);
                        }}
                        onBlur={(e) => {
                          onBlur();
                          trigger("country" as keyof typeof initialValues);
                        }}
                        selectOptions={lovs?.countries}
                        error={
                          errors.country != undefined &&
                          !isEmpty(errors?.country)
                            ? errors?.country?.message
                            : null
                        }
                        multiple={false}
                        material
                        disabled={formDisabled}
                        className={classes.field}
                      />
                    )}
                  />
                )}
                <Controller
                  name='city'
                  control={control}
                  rules={{
                    required: "Required",
                    minLength: { value: 2, message: "Invalid" },
                    // validate: {
                    //   isArabic: (value) =>
                    //     isArabicText(value) ||
                    //     "Please enter all details in Arabic",
                    // },
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <EnhancedInput
                      type='text'
                      name='city'
                      title='City*'
                      placeholder={"City"}
                      onChange={(e) => {
                        onChange(e);
                        trigger("city" as keyof typeof initialValues);
                      }}
                      onBlur={(e) => {
                        trigger("city" as keyof typeof initialValues);
                        onBlur();
                      }}
                      value={!isEmpty(value) ? value : ""}
                      error={errors?.city?.message}
                      className={classes.field}
                      material
                      disabled={formDisabled}
                    />
                  )}
                />
              </WidgetSection>
              <WidgetSection
                specificTitleDesign={
                  <>
                    Additional Details
                    <span
                      style={{
                        fontStyle: "italic",
                        fontSize: `${MAIN_ONE_THEME.typography.regular.reg3.fontSize}px`,
                      }}
                    ></span>
                  </>
                }
                hasTitleSpecificDesign={true}
                narrowSpacing={true}
                classes={{
                  container: classes.section,
                }}
                useSeparator={false}
                style={{ marginTop: "15px" }}
              >
                <Controller
                  name='email'
                  control={control}
                  rules={{
                    required: false,
                    validate: (value) =>
                      checkEmail(value) || "Invalid email format",
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <EnhancedInput
                      type='text'
                      name='email'
                      title='Email'
                      placeholder={"Email"}
                      onChange={(e) => {
                        trigger("email" as keyof typeof initialValues);
                        onChange(e);
                      }}
                      onBlur={(e) => {
                        trigger("email" as keyof typeof initialValues);
                        onBlur();
                      }}
                      value={!isEmpty(value) ? value : ""}
                      error={errors?.email?.message}
                      className={classes.field}
                      material
                      disabled={formDisabled}
                    />
                  )}
                />

                <Controller
                  name='phoneType'
                  control={control}
                  rules={{ required: "Required" }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <EnhancedChipInput
                      name={`phoneType`}
                      title='Phone Type*'
                      placeholder={"Phone Type"}
                      value={!isEmpty(value) ? value : ""}
                      onChange={(e) => {
                        onChange(e as string);
                        trigger(`phoneType` as keyof typeof initialValues);
                      }}
                      onBlur={(e) => {
                        onBlur();
                        trigger("phoneType" as keyof typeof initialValues);
                      }}
                      selectOptions={lovs?.phoneTypes}
                      error={
                        errors.phoneType != undefined &&
                        !isEmpty(errors?.phoneType)
                          ? errors?.phoneType?.message
                          : null
                      }
                      multiple={false}
                      material
                      disabled={formDisabled}
                      className={classes.field}
                    />
                  )}
                />
                <Controller
                  name='street'
                  control={control}
                  rules={{
                    minLength: { value: 2, message: "Invalid" },
                    required: false,
                    // validate: {
                    //   isArabic: (value) =>
                    //     isArabicText(value) ||
                    //     "Please enter all details in Arabic",
                    // },
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <EnhancedInput
                      type='text'
                      name='street'
                      title='Street'
                      placeholder={"Street"}
                      onChange={(e) => {
                        onChange(e);
                        trigger("street" as keyof typeof initialValues);
                      }}
                      onBlur={(e) => {
                        trigger("street" as keyof typeof initialValues);
                        onBlur();
                      }}
                      value={!isEmpty(value) ? value : ""}
                      error={errors?.street?.message}
                      className={classes.field}
                      material
                      disabled={formDisabled}
                    />
                  )}
                />
                <Controller
                  name='building'
                  control={control}
                  rules={
                    {
                      // validate: {
                      //   isArabic: (value) =>
                      //     isArabicText(value) ||
                      //     "Please enter all details in Arabic",
                      // },
                    }
                  }
                  render={({ field: { onChange, onBlur, value } }) => (
                    <EnhancedInput
                      type='text'
                      name='building'
                      title='Building'
                      placeholder={"Building"}
                      onChange={(e) => {
                        trigger("building" as keyof typeof initialValues);
                        onChange(e);
                      }}
                      onBlur={(e) => {
                        trigger("building" as keyof typeof initialValues);
                        onBlur();
                      }}
                      value={
                        !isEmpty(value) || value !== undefined ? value : null
                      }
                      error={errors?.building?.message}
                      className={classes.field}
                      material
                      disabled={formDisabled}
                    />
                  )}
                />
              </WidgetSection>
              {user?.info?.products?.filter((a) =>
                Object.keys(lovs?.products || {}).includes(a)
              )?.length > 1 && (
                <WidgetSection
                  title='Product Details'
                  narrowSpacing={true}
                  classes={{ container: classes.sectionFullRow }}
                  useSeparator={false}
                  style={{ margin: "-4px 0" }}
                >
                  {lovs != null && (
                    <div className={classes.row}>
                      <Controller
                        name={`name`}
                        control={control}
                        rules={{
                          required: false,
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <EnhancedInput
                            type='text'
                            name={`name`}
                            placeholder={"Quote Name"}
                            title='Quote Name*'
                            // value={f.name}
                            onChange={(e) => {}}
                            onBlur={(e) => {}}
                            material
                            disabled
                            style={{ margin: "-10px 0" }}
                            customStyles={{
                              labelStyles: classes.repeaterField,
                            }}
                            value={value}
                          />
                        )}
                      />

                      <Controller
                        name={`product`}
                        control={control}
                        rules={{
                          required: "Required",
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <EnhancedChipInput
                            name={`product`}
                            title='Product*'
                            placeholder={"Product"}
                            value={(value as string) || ""}
                            onChange={(e) => {
                              const selectedValue = e as string;

                              setValue(
                                "name",
                                lovs.products[selectedValue] + " 1"
                              );
                              onChange(selectedValue);
                              trigger(`product`);
                            }}
                            onBlur={(e) => {
                              onBlur();
                              trigger(`product`);
                            }}
                            selectOptions={lovs.products}
                            material
                            error={
                              errors.product != undefined &&
                              !isEmpty(errors?.product)
                                ? errors?.product.message
                                : null
                            }
                            multiple={false}
                            disabled={formDisabled}
                            className={classes.field}
                            style={{ margin: "-10px 0" }}
                            customStyles={{
                              labelStyles: classes.repeaterField,
                            }}
                          />
                        )}
                      />
                    </div>
                  )}
                </WidgetSection>
              )}
              {
               !isClosed && isEnabled && buttonLabel === "Calculate" &&
                <div style={{ width: "100%", justifyContent: "flex-end", display: "flex", padding: "15px 0 0" }}>
                  <EnhancedButton
                    type={buttonType}
                    state={submitButtonState}
                    backgroundColor={MAIN_ONE_THEME.palette.primary1.main}
                    color="#FFF"
                    style={{ display: "inline"}}
                  >
                    {buttonLabel}
                  </EnhancedButton>
                </div>
              }
            </WidgetPaper>
          </form>

          {newAppDrawerOpen && (
            <ApplicationDrawer
              open={newAppDrawerOpen}
              onClose={() => setNewAppDrawerOpen(false)}
              lineOfBusiness='Expat'
              onSuccess={() => {}}
            />
          )}
          {newApplicationWithMultipleAgencieDrawerOpen && (
            <ApplicationDrawerWithMultipleAgencies
              open={newApplicationWithMultipleAgencieDrawerOpen}
              loggedInAgentId={user.info.agentId}
              loggedInuserFirstName={user.info.firstName}
              loggedInuserLastName={user.info.lastName}
              lineOfBusiness={"Expat"}
              agentType={agentType}
              onClose={() =>
                setNewApplicationWithMultipleAgencieDrawerOpen(false)
              }
              onSuccess={() => {}}
            />
          )}
        </div>
      </>
    );
  };

  const renderLeftSection = () => {
    return (
      <>
        <EntityInfoWidget
          background='linear-gradient(98.3deg, rgb(0, 0, 0) 10.6%, rgb(255, 0, 0) 97.7%)'
          classification={{ classifications: {}, value: "" }}
          progress={getApplicationProgress(
            entityResult?.data?.Insurance?.entities?.application?.views
              ?.Insurance_all?.properties?.Status
          )}
          supportsClassification={true}
          supportsProgressBar={true}
          title={applicationInfo.applicationName}
          view={updatedLeftSideInfo}
        ></EntityInfoWidget>
      </>
    );
  };
  //#endregion

  return loadingState ? (
    <Loader />
  ) : (
    <StaticLayout
      name={"Application Details"}
      leftChildren={renderLeftSection()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default ApplicationDetailsPageExpat;
