import React from 'react';

const ToastErrorMessage: React.FC<{
  classes?: { content?: string };
  children?: React.ReactNode;
}> = ({ classes = {}, children }) => {
  return (
    <>
      {/* <span role="img" aria-label="success">
        ✔️
      </span> */}
      <div className={classes.content}>{children}</div>
    </>
  );
};

export default ToastErrorMessage;
