import React, { useEffect, useState } from "react";
import GenericDrawer from "../../components/common/generic-drawer/GenericDrawer";
import {
  DynamicFormInputType,
  IFormChipsDynamicProps,
  IFormSelectDynamicProps,
  IFormSwitchDynamicProps,
} from "../../DynamicForm";
import { toast } from "react-toastify";
import { EnhancedButtonStatus } from "../../components/common/EnhancedButton";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { inputs } from "./content";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import Loader from "../../components/Loader";
import { cloneDeep, isEmpty } from "lodash";
import { DEFAULT_ERROR_TEXT } from "../../constants";
import { ISalesForceBusinessPartnerDrawerProps } from ".";
import {
  getListForm,
  checkBusinessPartnerByPerson,
  createBusinessPartner,
  fetchedPersons,
  updateBusinessPartne,
} from "./queries";
import { extractPersons, toLookups } from "./utils";
import { getError } from "../../utils/graph-utils";
import { normaliseDynamicValues } from "../../utils/dynamic-utils";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import DynamicForm from "../../DynamicForm/DynamicForm";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  isRegisteredStyle: {
    marginBottom: "17px",
  },
}));

const SalesForceBusinessPartnerDrawer: React.FC<
  ISalesForceBusinessPartnerDrawerProps
> = ({
  personId,
  open,
  onSuccess,
  onClose,
  salesForceBusinessPartnerDetailsInfo,
  businessPartnerId,
}) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();
  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);
  const [newAllValues, setNewAllValues] = useState<Record<string, any>>();

  const [hasBusinessPartnerPersonError, setHasBusinessPartnerPersonError] =
    useState("");
  let businessPartnerPersonError = "";
  let [submitButtonDisabled, setSubmitButtonDisabled] =
    useState<boolean>(false);

  const businessPartnersDetailsListResults = useQuery(getListForm());

  const [selectedPersonID, setSelectedPersonID] = useState("");

  const [fetchedPersonsLazy, fetchedPersonsQueryResult] = useLazyQuery(
    fetchedPersons()
  );

  const [checkBusinessPartnerByPersonLazy] = useLazyQuery(
    checkBusinessPartnerByPerson(),
    {}
  );

  const [salesForceBusinessPartnerAction] = useMutation(
    businessPartnerId ? updateBusinessPartne() : createBusinessPartner()
  );

  const validateBusinessPartnerExistsOnPersonChange = (
    selectedPersonID: string
  ) => {
    if (!isEmpty(selectedPersonID)) {
      checkBusinessPartnerByPersonLazy({
        variables: {
          selectedPersonID: selectedPersonID,
        },
      }).then((response) => {
        setInputsForm((currentInputsForm) => {
          const updatedInputs = {
            ...createInputsFormFromAllValues(currentInputsForm, newAllValues),
          };

          if (
            response.data.SalesforceManagement?.queries
              ?.checkBusinessPartnerByPerson.length > 0
          ) {
            businessPartnerPersonError = `Business Partner already created for ${response.data.SalesforceManagement?.queries?.checkBusinessPartnerByPerson[0]?.salesforceManagement_BusinessPartner_PersonID?.views?.SalesforceManagement_all?.properties?.PersonCode} - ${response.data.SalesforceManagement?.queries?.checkBusinessPartnerByPerson[0]?.salesforceManagement_BusinessPartner_PersonID?.views?.SalesforceManagement_all?.properties?.FullName}`;
            setHasBusinessPartnerPersonError(
              `Business Partner already created for ${response.data.SalesforceManagement?.queries?.checkBusinessPartnerByPerson?.salesforceManagement_BusinessPartner_PersonID?.views?.SalesforceManagement_all?.properties?.PersonCode} - ${response.data.SalesforceManagement?.queries?.checkBusinessPartnerByPerson?.salesforceManagement_BusinessPartner_PersonID?.views?.SalesforceManagement_all?.properties?.FullName}`
            );
            (updatedInputs.person as IFormSelectDynamicProps).error =
              businessPartnerPersonError;
          } else {
            businessPartnerPersonError = "";
            setHasBusinessPartnerPersonError("");
            (updatedInputs.person as IFormSelectDynamicProps).error =
              businessPartnerPersonError;
          }

          return updatedInputs;
        });
      });
    }
  };

  function handlePersonSelection(selectedOption: any) {
    setSelectedPersonID(selectedOption);
    if (selectedOption.length > 5) {
      validateBusinessPartnerExistsOnPersonChange(selectedOption);
    }
  }

  function handlePersonInput(inputValue: any) {
    if (inputValue !== null) {
      if (inputValue.length >= 3) {
        fetchedPersonsLazy({
          variables: {
            searchKeyword: inputValue,
          },
        });
      }
    } else {
      (updatedInputs.person as IFormChipsDynamicProps).selectOptions = {};
    }
  }

  useEffect(() => {
    initialize();
  }, [businessPartnersDetailsListResults.data]);

  useEffect(() => {
    const isDisabled = !isEmpty(hasBusinessPartnerPersonError);

    setSubmitButtonDisabled(isDisabled);
  }, [hasBusinessPartnerPersonError]);

  useEffect(() => {
    if (fetchedPersonsQueryResult?.data) {
      const extractedFetchedPersons = extractPersons(
        fetchedPersonsQueryResult.data
      );

      setInputsForm((currentInputsForm) => {
        const updatedForm = {
          ...createInputsFormFromAllValues(currentInputsForm, newAllValues),
        };

        (updatedForm.person as IFormChipsDynamicProps).selectOptions = {
          ...extractedFetchedPersons,
        };

        return updatedForm;
      });
    } else {
      (updatedInputs.person as IFormChipsDynamicProps).selectOptions = {};
    }
  }, [fetchedPersonsQueryResult.data]);

  let updatedInputs: Record<string, DynamicFormInputType> = inputs;

  //validateFields(updatedInputs.type.value);

  const initialize = () => {
    try {
      updatedInputs = cloneDeep(inputsForm);

      updatedInputs.person.hidden = !!businessPartnerId;
      updatedInputs.person.required = !businessPartnerId;

      updatedInputs.maxCreditLimitAllowedCurrency.hidden = !!businessPartnerId;
      updatedInputs.maxCreditLimitAllowedCurrency.required = !businessPartnerId;

      updatedInputs.maxCreditLimitAllowed.hidden = !!businessPartnerId;
      updatedInputs.maxCreditLimitAllowed.required = !businessPartnerId;

      updatedInputs.SalesforceManagement_BusinessPartnerStatuses.hidden =
        !!businessPartnerId;
      updatedInputs.SalesforceManagement_BusinessPartnerStatuses.required =
        !businessPartnerId;

      (updatedInputs.isRegistered as IFormSwitchDynamicProps).className =
        classes.isRegisteredStyle;

      if (businessPartnersDetailsListResults.data) {
        const lookupLists = toLookups(businessPartnersDetailsListResults.data);

        // Set selectOptions for various fields
        (
          updatedInputs.SalesforceManagement_BusinessPartnerStatuses as IFormSelectDynamicProps
        ).selectOptions =
          lookupLists["SalesforceManagement_BusinessPartnerStatuses"];

        (updatedInputs.category as IFormSelectDynamicProps).selectOptions =
          lookupLists["category"];

        (
          updatedInputs.maxCreditLimitAllowedCurrency as IFormSelectDynamicProps
        ).selectOptions = lookupLists["maxCreditLimitAllowedCurrency"];

        (
          updatedInputs.relatedCompany as IFormSelectDynamicProps
        ).selectOptions = lookupLists["relatedCompany"];

        (updatedInputs.type as IFormSelectDynamicProps).selectOptions =
          lookupLists["type"];

        (updatedInputs.person as IFormChipsDynamicProps).onChange = (
          option
        ) => {
          handlePersonInput(option);
          handlePersonSelection(option[0]);
        };
      }

      if (salesForceBusinessPartnerDetailsInfo) {
        updatedInputs.person.value =
          salesForceBusinessPartnerDetailsInfo.person;
        updatedInputs.relatedCompany.value =
          salesForceBusinessPartnerDetailsInfo.relatedCompany;
        updatedInputs.type.value = salesForceBusinessPartnerDetailsInfo.type;
        updatedInputs.category.value =
          salesForceBusinessPartnerDetailsInfo.category;
        updatedInputs.isCompany.value =
          salesForceBusinessPartnerDetailsInfo.isCompany;

        updatedInputs.isLocal.value =
          salesForceBusinessPartnerDetailsInfo.isLocal;
        updatedInputs.isRegistered.value =
          salesForceBusinessPartnerDetailsInfo.isRegistered;
        updatedInputs.registerNumber.value =
          salesForceBusinessPartnerDetailsInfo.registerNumber;
        updatedInputs.taxAccount.value =
          salesForceBusinessPartnerDetailsInfo.taxAccount;

        updatedInputs.account.value =
          salesForceBusinessPartnerDetailsInfo.account;
        updatedInputs.maxCreditLimitAllowedCurrency.value =
          salesForceBusinessPartnerDetailsInfo.maxCreditLimitAllowedCurrency;
        updatedInputs.maxCreditLimitAllowed.value =
          salesForceBusinessPartnerDetailsInfo.maxCreditLimitAllowed;
        updatedInputs.SalesforceManagement_BusinessPartnerStatuses.value =
          salesForceBusinessPartnerDetailsInfo.SalesforceManagement_BusinessPartnerStatuses;
      }

      updatedInputs.maxCreditLimitAllowedCurrency.value = updatedInputs
        .maxCreditLimitAllowedCurrency.value
        ? updatedInputs.maxCreditLimitAllowedCurrency.value
        : "39735";

      setInputsForm(updatedInputs);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputs, values);

    setFormDisabled(true);
    setSubmitButtonState("loading");

    try {
      let variablesMutation = {};
      if (!businessPartnerId) {
        variablesMutation = {
          businessPartnerInputs: {
            personID: selectedPersonID,
            relatedCompany: data.relatedCompany,
            type: data.type,
            category: data.category || null,
            isCompany: data.isCompany ? data.isCompany : false,
            isLocal: data.isLocal ? data.isLocal : false,
            isRegistered: data.isRegistered ? data.isRegistered : false,
            registerNumber: data.registerNumber || null,
            taxAccount: data.taxAccount || null,
            account: data.account || null,
            maxCreditLimitAllowedCurrency:
              data.maxCreditLimitAllowedCurrency || null,
            maxCreditLimitAllowed: parseInt(
              data.maxCreditLimitAllowed as unknown as string
            ),
            status: data.SalesforceManagement_BusinessPartnerStatuses,
          },
        };
      } else {
        variablesMutation = {
          businessPartnerUpdateInputs: {
            relatedCompany: data.relatedCompany,
            type: data.type,
            category: data.category || null,
            isCompany: data.isCompany ? data.isCompany : false,
            isLocal: data.isLocal ? data.isLocal : false,
            isRegistered: data.isRegistered ? data.isRegistered : false,
            registerNumber: data.registerNumber || null,
            taxAccount: data.taxAccount || null,
            account: data.account || null,
          },
        };
      }

      salesForceBusinessPartnerAction({
        variables: businessPartnerId
          ? {
              ...variablesMutation,
              entityId: businessPartnerId,
            }
          : variablesMutation,
        errorPolicy: "all",
      }).then((res) => {
        if (isEmpty(res.errors)) {
          toast.success(
            <ToastSuccessMessage>
              {businessPartnerId
                ? "Business Partner successfully updated"
                : "Business Partner successfully created"}
            </ToastSuccessMessage>
          );
          setTimeout(() => {
            setSubmitButtonState("success");
            onSuccess();
            onClose();
            if (!businessPartnerId) {
              const newBusinessPartnerId =
                res.data.salesforceManagement.actions.createBusinessPartner.id;
              navigate(`/salesforce/business-partners/` + newBusinessPartnerId);
            }
          }, 500);
        } else {
          setSubmitButtonState(undefined);
          toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
        }
      });
    } catch {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setFormDisabled(false);
    }
  };

  const createInputsFormFromAllValues = (
    currentInputsForm: any,
    allValues: Record<string, any>
  ) => {
    if (newAllValues) {
      const newInputsForm = {
        person: {
          ...currentInputsForm.person,
          value: allValues["person"],
        },
        relatedCompany: {
          ...currentInputsForm.relatedCompany,
          value: allValues["relatedCompany"],
        },
        type: {
          ...currentInputsForm.type,
          value: allValues["type"],
        },
        category: {
          ...currentInputsForm.category,
          value: allValues["category"],
        },
        isCompany: {
          ...currentInputsForm.isCompany,
          value: allValues["isCompany"],
        },
        isLocal: {
          ...currentInputsForm.isLocal,
          value: allValues["isLocal"],
        },
        isRegistered: {
          ...currentInputsForm.isRegistered,
          value: allValues["isRegistered"],
        },
        registerNumber: {
          ...currentInputsForm.registerNumber,
          value: allValues["registerNumber"],
        },
        taxAccount: {
          ...currentInputsForm.taxAccount,
          value: allValues["taxAccount"],
        },
        account: {
          ...currentInputsForm.account,
          value: allValues["account"],
        },
        maxCreditLimitAllowedCurrency: {
          ...currentInputsForm.maxCreditLimitAllowedCurrency,
          value: allValues["maxCreditLimitAllowedCurrency"],
        },
        maxCreditLimitAllowed: {
          ...currentInputsForm.maxCreditLimitAllowed,
          value: allValues["maxCreditLimitAllowed"],
        },
        SalesforceManagement_BusinessPartnerStatuses: {
          ...currentInputsForm.SalesforceManagement_BusinessPartnerStatuses,
          value: allValues["SalesforceManagement_BusinessPartnerStatuses"],
        },
      };

      return newInputsForm;
    } else return currentInputsForm;
  };

  return (
    <GenericDrawer
      title={
        businessPartnerId ? "Modify Business Partner" : "New Business Partner"
      }
      onClose={() => onClose()}
      isOpen={open}
    >
      {businessPartnersDetailsListResults.loading && open ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={"Submit"}
            submitButtonState={submitButtonState}
            isSubmitButtonDisabled={submitButtonDisabled}
            disableForm={formDisabled}
            title="Information"
            hasDoprdownSpecificBehavior={true}
            onChange={(
              fieldName: string,
              value: string | string[],
              allValues
            ) => {
              if (fieldName === "person") {
                setNewAllValues(allValues);
              }
            }}
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default SalesForceBusinessPartnerDrawer;
