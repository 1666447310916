import { IEnhancedRow } from '../components/enhanced-table/EnhancedTableBody';
import { isEmpty, isValidNumber } from './validationUtils';
import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
} from '../components/enhanced-table';
import {
  ITableRowSettingsCondition,
  PropertyOperator,
  ITableRowSettings,
  ConditionOperator,
} from '../components/widgets/listing-widget';

export type TableSortOrder = 'asc' | 'desc' | undefined;

/**
 * Compares two rows according to the value of the given property in the columns
 * @param {IEnhancedRow} a The first row to compare
 * @param {IEnhancedRow} b The second row to compare
 * @param {string | undefined} orderBy the property to be compared against
 * @return {number} the result which is any of {-1,0,1}
 * If the property in a < property in b returns -1,if  a > b returns 1 and if a=b returns 0
 * If the property is not defined in both a and b then it returns 0, if the
 * property only defined in a returns 1 , and if the property only defined in b returns -1
 * If orderBy is not defined returns 0
 */
export function desc(
  a: IEnhancedRow,
  b: IEnhancedRow,
  order: number,
  orderBy: string | undefined,
  headers: Record<string, EnhancedTableHeader>,
  secondOrder: number,
  secondOrderBy?: string | undefined,
) {
  let defaultOrder = order;
  let defaultOrderBy = orderBy;
  if (defaultOrderBy !== undefined) {
    if (
      secondOrderBy !== undefined &&
      a.columns[defaultOrderBy] === b.columns[defaultOrderBy]
    ) {
      defaultOrder = secondOrder;
      defaultOrderBy = secondOrderBy;
    }
    let left: string | number | Date = a.columns[defaultOrderBy];
    let right: string | number | Date = b.columns[defaultOrderBy];
    let isNumber = false;
    if (
      headers[defaultOrderBy] &&
      (headers[defaultOrderBy].type === EnhancedTableHeaderType.Currency ||
        headers[defaultOrderBy].type === EnhancedTableHeaderType.Percentage ||
        headers[defaultOrderBy].type === EnhancedTableHeaderType.Rate)
    ) {
      left = isEmpty(left) ? NaN : Number(left);
      right = isEmpty(right) ? NaN : Number(right);
      isNumber = true;
    } else if (
      headers[defaultOrderBy] &&
      headers[defaultOrderBy].type === EnhancedTableHeaderType.Date
    ) {
      left = isEmpty(left) ? NaN : new Date(left);
      right = isEmpty(right) ? NaN : new Date(right);
    }

    const isValid = isNumber
      ? (input: string | number | Date) => isValidNumber(input)
      : (input: string | number | Date) => !isEmpty(input);
    if (isValid(left) && isValid(right)) {
      if (typeof left === 'string' && typeof right === 'string') {
        if (left.toLowerCase() > right.toLowerCase()) {
          return -1 * defaultOrder;
        }
        if (left.toLowerCase() < right.toLowerCase()) {
          return 1 * defaultOrder;
        }
      } else {
        if (left > right) {
          return -1 * defaultOrder;
        }
        if (left < right) {
          return 1 * defaultOrder;
        }
      }
    } else {
      if (!isValid(left) && !isValid(right)) {
        return 0;
      }
      if (!isValid(left)) {
        return 1;
      }
      if (!isValid(right)) {
        return -1;
      }
    }
  }
  return 0;
}

/**
 * Sorts a record of rows according to a comparator function
 * @param {Record<string, Record<string, string>>} items The rows to be sorted
 * @param {(a: IEnhancedRow, b: IEnhancedRow) => number} cmp The comparator function
 * @return {IEnhancedRow[]} The sorted array of rows
 */
export function rowSort(
  items: Record<string, Record<string, string>>,
  cmp: (a: IEnhancedRow, b: IEnhancedRow) => number,
): IEnhancedRow[] {
  const stabilizedThis: IEnhancedRow[] = Object.keys(items).map(
    (itemKey, index) => {
      return { key: itemKey, columns: items[itemKey], index };
    },
  );

  stabilizedThis.sort((a, b) => {
    const order = cmp(a, b);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis;
}

/**
 * Returns the rows comparator functions from the specified order and property
 * @param {TableSortOrder} order The order of the comparison {'asc', 'desc', undefined}
 * @param {string | undefined} orderBy The property to compare
 * @return {(a: IEnhancedRow, b: IEnhancedRow) => number} The comparator function
 */

export function getSorting(
  order: TableSortOrder,
  orderBy: string | undefined,
  headers: Record<string, EnhancedTableHeader>,
  secondOrder?: TableSortOrder,
  secondOrderBy?: string | undefined,
): (a: IEnhancedRow, b: IEnhancedRow) => number {
  return (a, b) =>
    desc(
      a,
      b,
      order === 'desc' ? 1 : -1,
      orderBy,
      headers,
      secondOrder === 'desc' ? 1 : -1,
      secondOrderBy,
    );
}

// function desc<T>(a: T, b: T, orderBy: keyof T) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function stableSort<T>(array: T[], cmp: (a: T, b: T) => number) {
//   const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
//   stabilizedThis.sort((a, b) => {
//     const order = cmp(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   return stabilizedThis.map(el => el[0]);
// }

// type Order = 'asc' | 'desc';

// function getSorting<K extends keyof any>(
//   order: Order,
//   orderBy: K,
// ): (a: { [key in K]: number | string }, b: { [key in K]: number | string }) => number {
//   return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
// }

export function getRowConditions(
  rowSettings: ITableRowSettings,
  row: IEnhancedRow,
) {
  switch (rowSettings.operator) {
    case ConditionOperator.And:
      return rowSettings.conditions.every((condition) =>
        getRowCondition(condition, row),
      );
    case ConditionOperator.Or:
      return rowSettings.conditions.some((condition) =>
        getRowCondition(condition, row),
      );
    default:
      return rowSettings.conditions.every((condition) =>
        getRowCondition(condition, row),
      );
  }
}

export function getRowCondition(
  condition: ITableRowSettingsCondition,
  row: IEnhancedRow,
) {
  return condition.iconVisiblePropertyValues.some((value) => {
    switch (condition.operator) {
      case PropertyOperator.Equal:
        return value === row.columns[condition.iconVisiblePropertyName];
      case PropertyOperator.GreaterThan:
        return row.columns[condition.iconVisiblePropertyName] > value;
      case PropertyOperator.GreaterThanOrEqual:
        return row.columns[condition.iconVisiblePropertyName] >= value;
      case PropertyOperator.LessThan:
        return row.columns[condition.iconVisiblePropertyName] < value;
      case PropertyOperator.LessThanOrEqual:
        return row.columns[condition.iconVisiblePropertyName] <= value;
      case PropertyOperator.NotEqual:
        return value !== row.columns[condition.iconVisiblePropertyName];
      default:
        return false;
    }
  });
}
