import React, { useEffect, useState } from 'react';
import { IListingData } from '../../models/listing';
import StaticLayout from '../../page-layout/static-layout/StaticLayout';
import { useLazyQuery } from '@apollo/client';
import { listQuery } from './queries';
import { mapToListingData } from './utils';
import { actions, filterSectionsContent, headers } from './content';
import EnhancedTable from '../../components/enhanced-table/EnhancedTable';
import { IListingFilterWidgetSection } from '../../components/widgets/custom-listing-filter';
import ListingFilterWidget from '../../components/widgets/custom-listing-filter/ListingFilterWidget';
import { IAbstractRecord } from '../../models';
import _, { isEmpty } from 'lodash';
import { getFilter, setFilter } from '../../utils/filter-utils';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { toast } from 'react-toastify';
import AgencyDrawer from '../../forms/agency-drawer/AgencyDrawer';
import { IEnhanceTableHeaderClickable } from '../../components/enhanced-table';
import { DEFAULT_ERROR_TEXT } from '../../constants';
import { useNavigate } from 'react-router-dom';

const AgenciesPage: React.FC = () => {
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [tableLoadingState, setTableLoadingState] = useState<boolean>(false);
  const [agencyDrawerOpen, setAgencyDrawerOpen] = useState<boolean>(false);
  const [chosenEditId, setChosenEditId] = useState<string>('');

  const navigate = useNavigate();

  const FILTER_SESSION_KEY = 'agenciesFilter';

  const [filterSections, setFilterSections] =
    useState<IListingFilterWidgetSection[]>();

  const initialfilterValues =
    Object.keys(getFilter(FILTER_SESSION_KEY)).length > 0
      ? getFilter(FILTER_SESSION_KEY)
      : {
          status: ['ACTIVE'],
          type: [],
        };

  const [filterValues, setFilterValues] =
    useState<IAbstractRecord>(initialfilterValues);

  const [tableData, setTableData] = useState<IListingData<any>>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 0,
    totalCount: 0,
  });

  const [listQueryLazy, { loading, error, data, fetchMore }] = useLazyQuery(
    listQuery(),
    {
      variables: {
        currentPage: 1,
        currentPageSize: tableData.pageSize,
        agencyStatus:
          filterValues?.status && filterValues?.status?.length > 0
            ? filterValues.status
            : null,
        agencyType:
          filterValues?.type && filterValues?.type?.length > 0
            ? filterValues.type
            : null,
      },
    }
  );

  useEffect(() => {
    listQueryLazy();
  }, []);

  //add open and close function for action button
  actions[0].onClick = () => {
    setChosenEditId(undefined);
    setAgencyDrawerOpen(true);
  };
  (
    headers.insurance_Agency_AgencyName as IEnhanceTableHeaderClickable
  ).callback = (payload: any) => {
    navigate(
      '/business/partners/' + payload.columns.insurance_Agency_Id
    );
  };

  useEffect(() => {
    if (loading) setLoadingState(true);
    if (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
    if (data) {
      const tableData = mapToListingData(data);
      setTableData({ ...tableData, pageNumber: 0 });

      let savedFilters = getFilter(FILTER_SESSION_KEY);
      if (isEmpty(savedFilters) || Object.keys(savedFilters).length == 0) {
        savedFilters = _.cloneDeep(filterValues);
        setFilter(savedFilters, FILTER_SESSION_KEY);
      }
      setFilterValues(savedFilters);

      const newFilterSections = filterSectionsContent(data, savedFilters);
      setFilterSections(newFilterSections);
    }
    setLoadingState(false);
  }, [loading, error, data]);

  function handlePageChange(page: number) {
    setTableLoadingState(true);
    const nextPage = ++page;
    fetchMore({
      variables: {
        currentPage: nextPage,
        currentPageSize: tableData.pageSize,
      },
    })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const updatedTableData = {
            ...mapToListingData(fetchMoreResult.data),
            pageNumber: nextPage - 1,
          };
          setTableData(updatedTableData);
        }
      })
      .finally(() => {
        setTableLoadingState(false);
      });
  }

  function handleRowsPerPageChange(numberOfRecordsPerPage: number) {
    setTableLoadingState(true);
    setTableData({ ...tableData, pageNumber: 1 });
    fetchMore({
      variables: {
        currentPage: 1,
        currentPageSize: numberOfRecordsPerPage,
      },
    })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const updatedTableData = {
            ...mapToListingData(fetchMoreResult.data),
            pageNumber: 0,
            pageSize: numberOfRecordsPerPage,
          };
          setTableData(updatedTableData);
        }
      })
      .finally(() => {
        setTableLoadingState(false);
      });
  }

  const renderMainChildren = () => {
    return (
      <>
        {loading ? (
          <div></div>
        ) : (
          <div style={{ marginTop: '20px' }}>
            <EnhancedTable
              title="Business Partners"
              name="count"
              orderByAscendingByDefault
              defaultOrderByColumn="name"
              inline={false}
              data={tableData}
              headers={headers}
              handlePageChange={(page: number) => handlePageChange(page)}
              handleRowsPerPageChange={(page: number) =>
                handleRowsPerPageChange(page)
              }
              currentPage={tableData.pageNumber}
              hideToolbar
              usePagination
              disableSelection
              actions={actions}
              loader={tableLoadingState}
            />
            {agencyDrawerOpen && (
              <AgencyDrawer
                open={agencyDrawerOpen}
                onClose={() => setAgencyDrawerOpen(false)}
                onSuccess={() => {
                  handlePageChange(0);
                }}
                agencyId={chosenEditId}
              />
            )}
          </div>
        )}
      </>
    );
  };

  const onFilterUpdate = async (v: Record<string, any>) => {
    let newFilters = _.cloneDeep(v);
    setFilter(newFilters, FILTER_SESSION_KEY);
    setFilterValues(newFilters);
  };

  const renderFilter = () => {
    return (
      filterSections && (
        <ListingFilterWidget
          name={'Business Partners Filter'}
          filters={filterSections}
          onApplyFilter={(v) => {
            onFilterUpdate(v);
          }}
        ></ListingFilterWidget>
      )
    );
  };

  return (
    <StaticLayout
      loading={loading || loadingState}
      name={'Business Partners'}
      leftChildren={renderFilter()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default AgenciesPage;
