import React, { useEffect, useState } from "react";
import EnhancedStepper from "../../components/common/EnhancedStepper";
import EntityInfoWidget from "../../components/widgets/entity-info/EntityInfoWidget";
import {
  entityView,
  businessPartnerTabs,
  headers,
  assignedPLansHeaders,
  vignetteRangeheaders,
} from "./content";
import { makeStyles } from "tss-react/mui";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  activateBusinessPartner,
  activateBusinessPartnerLine,
  deactivateBusinessPartner,
  deactivateBusinessPartnerLine,
  getBusinessPartnerLinesList,
  getBusinessPartnerLinesListWithoutPagination,
  getBusinessPartnerPlanList,
  activateBusinessPartnerPlan,
  deactivateBusinessPartnerPlan,
  getBusinessPartnerVignetteRangeList,
} from "./queries";
import { useNavigate, useParams } from "react-router-dom";
import { IEntityInfoView } from "../../components/widgets/entity-info/EntityInfoFields";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";
import ToastErrorMessage from "../../components/ToastErrorMessage";

import {
  ISalesforceBusinessPartnerDetailsPageProps,
  ISalesforceBusinessPartnerDetails,
  initialValues,
  IDetailedSelection,
} from ".";

import {
  DEFAULT_ERROR_TEXT,
  MAIN_ONE_THEME,
  contentFontFamilyBold,
} from "../../constants";

import dayjs from "dayjs";
import TabsLayout from "../../page-layout/tabs-layout/TabsLayout";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { IDynamicDiplayView } from "../../components/widgets/dynamic-display";
import DynamicWidget from "../../components/widgets/dynamic-display/DynamicWidget";
import { capitalizeFirstLetter } from "../../utils/formatting-utils";
import EnhancedButton, {
  EnhancedButtonStatus,
} from "../../components/EnhancedButton";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import { getError } from "../../utils/graph-utils";
import { IConfirmation } from "../../redux/confirmation/types";
import { getBusinessPartnerDetailsInfo } from "./queries";
import { OpenConfirmationAction } from "../../redux/confirmation/actions";
import {
  entityToBusinessPartnerDetails,
  extractLines,
  getBusinessPartnerStatus,
  mapToAssignedLinesListingData,
  mapToAssignedPlanListingData,
  mapToVignetteRangeListingData,
} from "./utils";
import WidgetSection from "../../components/common/WidgetSection";
import { IListingData } from "../../models/listing";
import EnhancedTable from "../../components/enhanced-table/EnhancedTable";
import {
  IEnhancedTableMenuItem,
  IEnhanceTableHeaderClickable,
} from "../../components/enhanced-table";
import SetDefaultCommissionDrawer from "../set-default-commission-drawer/SetDefaultCommissionDrawer";
import ShowForUser from "../../components/user/ShowForUser";
import SalesForceBusinessPartnerDrawer from "../salesforce-business-partners-drawer/SalesForceBusinessPartnerDrawer";
import EnhancedChipInput from "../../components/enhanced-form/EnhancedChipInput";
import { Controller, useForm } from "react-hook-form";
import { isEmpty } from "../../utils/validationUtils";
import BusinessPartnerAddPlansDrawer from "../business-partner-add-plans-drawer/BusinessPartnerAddPlansDrawer";
import SalesForceMaxCreditLimitDrawer from "../salesforce-max-credit-limit-drawer/SalesForceMaxCreditLimitDrawer";
import SalesForceLineCreditLimitDrawer from "../salesforce-line-credit-limit-drawer/SalesForceLineCreditLimitDrawer";
import SalesforceBusinessPartnerCommissionDrawer from "../set-default-commission-drawer copy/SalesforceBusinessPartnerCommissionDrawer";
import { EnhancedDisplayType } from "../../components/enhanced-form/EnhancedDisplay";
import SalesforceVignetteRangeDrawer from "../salesforce-vignette-range-drawer/SalesforceVignetteRangeDrawer";

const useStyles = makeStyles()(() => ({
  stepper: {
    backgroundColor: "transparent",
    maxWidth: "90%",
    marginBottom: "20px",
    "& .MuiStepLabel-root .Mui-active, .MuiStepLabel-root .Mui-completed": {
      color: MAIN_ONE_THEME.palette.primary5.main,
    },

    "@media only screen and (min-width: 600px)": {
      maxWidth: "1300px",
    },
  },
  section: {
    display: "grid",
    "grid-template-columns": "32% 32% 32%",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  sectionFullRow: {
    display: "grid",
    "grid-template-columns": "100%",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  row: {
    flexWrap: "wrap",
    margin: "10px",
    alignItems: "center",
    display: "flex",
    gap: "10px",
    "& label": {
      flexGrow: "1",
      flexBasis: "0",
      minWidth: "0",
      maxWidth: "25%",
    },
  },
  thickSeperator: {
    height: 10,
    margin: "10px 0 10px 0",
    "grid-column-start": "1",
    "grid-column-end": "4",
  },
  field: {
    width: "100%",
    marginRight: "10px !important",
    backgroundColor: "white",
  },
  arField: {
    width: "100%",
    marginRight: "10px !important",
    direction: "rtl",
  },
  header: {
    fontFamily: contentFontFamilyBold,
    fontSize: MAIN_ONE_THEME.typography.regular.reg3.fontSize,
    color: MAIN_ONE_THEME.palette.primary2.main,
    "&:hover": {
      color: MAIN_ONE_THEME.palette.primary1.main,
    },
  },
  content: {
    padding: "0",
  },
  tabPanelStyle: {
    padding: "unset",
    marginTop: "20px",
  },
  dirtyChip: {
    margin: 0,
    backgroundColor: MAIN_ONE_THEME.palette.primary2.main,
    "& span": {
      color: "#ffffff",
    },
  },
  actionButton: {
    display: "inline-block",
    marginRight: "5px",
  },
}));

const SalesforceBusinessPartnerDetailsPage: React.FC<
  ISalesforceBusinessPartnerDetailsPageProps
> = () => {
  const { classes } = useStyles();
  const params = useParams();
  const tenant = useAppSelector((state) => state.tenant);
  const user = useAppSelector((state) => state.user);
  const [activateButtonState, setActivateButtonState] =
    useState<EnhancedButtonStatus>();
  const [deactivateButtonState, setDeactivateButtonState] =
    useState<EnhancedButtonStatus>();

  const [updatedLeftSideInfo, setUpdatedLeftSideInfo] =
    useState<IEntityInfoView>(entityView);

  const [loadingState, setLoadingState] = useState<boolean>(true);
  const [businessPartnerInfoDrawerOpen, setBusinessPartnerInfoDrawerOpen] =
    useState<boolean>(false);
  const [defaultCommissionDrawerOpen, setDefaultCommissionDrawerOpen] =
    useState<boolean>(false);
  const [maximumCreditLimitDrawerOpen, setMaximumCreditLimitDrawerOpen] =
    useState<boolean>(false);
  const [lineCreditLimitDrawerOpen, setLineCreditLimitDrawerOpen] =
    useState<boolean>(false);
  const [addPlansDrawerOpen, setAddPlansDrawerOpen] = useState<boolean>(false);
  const [
    assignedPlansCommissionDrawerOpen,
    setAssignedPlansCommissionDrawerOpen,
  ] = useState<boolean>(false);

  const [selectedDetailedSelection, setSelectedDetailedSelection] = useState<
    IDetailedSelection[]
  >([]);
  const [vignetteRangeDrawerOpen, setVignetteRangeDrawerOpen] =
    useState<boolean>(false);

  const [businessPartnerDetailsInfo, setBusinessPartnerDetailsInfo] =
    useState<ISalesforceBusinessPartnerDetails>(initialValues);
  const [
    businessPartnerInformationViewConfig,
    setBusinessPartnerInformationViewConfig,
  ] = useState<IDynamicDiplayView>();
  const [
    creditLimitInformationViewConfig,
    setCreditLimitInformationViewConfig,
  ] = useState<IDynamicDiplayView>();
  const [status, setStatus] = useState<string>();
  const [businessPartnerType, setBusinessPartnerType] = useState<string>();

  const [assignedLinesViewConfig, setAssignedLinesViewConfig] =
    useState<IDynamicDiplayView>();

  const [selectedLineItems, setSelectedLineItems] = useState<number>(0);
  const [selectedBusinessLineId, setSelectedBusinessLineId] =
    useState<string>("");
  const [selectedBusinessLineStatus, setSelectedBusinessLineStatus] =
    useState<string>("");
  const [selectedBusinessLineIdStatus, setSelectedBusinessLineIdStatus] =
    useState<string>("");

  const [businessPartnerStatus, setBusinessPartnerStatus] =
    useState<string>("");
  const [businessPartnerPlan_Status, setBusinessPartnerPlan_Status] = useState<
    string[]
  >([]);
  const [
    businessPartnerPlan_BPLine_Status,
    setBusinessPartnerPlan_BPLine_Status,
  ] = useState<string[]>([]);

  const [businessPartnerPlan_Line_Status, setBusinessPartner_Line_Status] =
    useState<string[]>([]);
  const [businessPartnerPlan_Plan_Status, setBusinessPartner_Plan_Status] =
    useState<string[]>([]);
  const [selectedPlanItems, setSelectedPlanItems] = useState<number>(0);
  const [selectedBusinessPlanIds, setSelectedBusinessPlanIds] = useState<
    string[]
  >([]);

  const [selectedLineCreditLimitItemsIds, setSelectedLineCreditLimitItemsIds] =
    useState<string[]>([]);

  const [selectedVignetteRangeItems, setSelectedVignetteRangeItems] =
    useState<number>(0);
  const [selectedVignetteRangeId, setSelectedVignetteRangeId] =
    useState<string>("");

  const [actionClicked, setActionClicked] = useState(null);

  const dispatch = useAppDispatch();
  const entityId = params.id;
  const navigate = useNavigate();

  const [lovs, setLovs] =
    useState<Record<string, Record<string, string>>>(null);

  const [assignedLinesTableLoadingState, setAssignedLinesTableLoadingState] =
    useState<boolean>(false);
  const [assignedPlansTableLoadingState, setAssignedPlansTableLoadingState] =
    useState<boolean>(false);
  const [vignetteRangeTableLoadingState, setVignetteRangeTableLoadingState] =
    useState<boolean>(false);

  const [selectedLineFilter, setSelectedLineFilter] = useState<string>(null);

  (
    headers.salesforceManagement_BusinessPartnerLine_LineName as IEnhanceTableHeaderClickable
  ).callback = (assignedLinesPayload: any) => {
    navigate(
      "/plan/lines/" +
        assignedLinesPayload.columns
          .salesforceManagement_BusinessPartnerLine_LineId
    );
  };

  (assignedPLansHeaders.line_Name as IEnhanceTableHeaderClickable).callback = (
    assignedLinesPayload: any
  ) => {
    navigate(
      "/plan/lines/" +
        assignedLinesPayload.columns
          .salesforceManagement_BusinessPartnerPlan_LineID
    );
  };
  (assignedPLansHeaders.plan_Name as IEnhanceTableHeaderClickable).callback = (
    assignedPlansPayload: any
  ) => {
    navigate(
      "/plan/plans/" +
        assignedPlansPayload.columns
          .salesforceManagement_BusinessPartnerPlan_PlanID
    );
  };

  const {
    control,
    formState: { errors },
  } = useForm<ISalesforceBusinessPartnerDetails>({
    defaultValues: initialValues,
    shouldUnregister: true,
  });

  // // const [isModalOpen, setIsModalOpen] = useState(true);
  // // const handleDeactivate = async () => {
  // //   try {
  // //     const result = await deactivateBusinessPartnerLineAction();
  // //     if (isEmpty(result.errors)) {
  // //       toast.success(
  // //         <ToastSuccessMessage>
  // //           Assigned line successfully deactivated. Note that Credit Limit is
  // //           set to 0 and all relevant plans are deactivated as well.
  // //         </ToastSuccessMessage>
  // //       );
  // //     } else {
  // //       toast.error(<ToastErrorMessage>{getError(result)}</ToastErrorMessage>);
  // //     }
  // //   } catch (error) {
  // //     console.error(error);
  // //   } finally {
  // //     setIsModalOpen(false);
  // //   }
  // // };

  //#region tableMenu
  const isAdmin = user.userRoles.includes("Insurance-Admin");
  let assignedLinesListingActions: IEnhancedTableMenuItem[] = [];
  assignedLinesListingActions = [
    {
      title: "Set Default Commission",
      onClick: () => {},
      isEntity: false,
      isBulk: false,
      iconUrl: "",
      hidden: !(isAdmin && businessPartnerType !== "Direct_Salesforce"),
      disabled: !(
        selectedLineItems === 1 &&
        businessPartnerType !== "Direct_Salesforce" &&
        status !== "inactive" &&
        selectedBusinessLineStatus === "Active"
      ),
    },
    {
      title: "Update Credit Limit",
      onClick: () => {},
      isEntity: false,
      isBulk: false,
      iconUrl: "",
      hidden: !(
        isAdmin &&
        status !== "inactive" &&
        businessPartnerType !== "Direct_Salesforce"
      ),
      disabled: !(selectedLineItems >= 1 && status !== "inactive"),
    },
    {
      title: "Activate",
      onClick: () => {},
      isEntity: false,
      isBulk: false,
      iconUrl: "",
      hidden: !isAdmin,
      disabled: !(
        selectedLineItems === 1 &&
        selectedBusinessLineStatus === "Inactive" &&
        selectedBusinessLineIdStatus === "Active" &&
        status !== "inactive"
      ),
    },
    {
      title: "Deactivate",
      onClick: () => {},
      isEntity: false,
      isBulk: false,
      iconUrl: "",
      hidden: !isAdmin,
      disabled: !(
        selectedLineItems === 1 &&
        selectedBusinessLineStatus === "Active" &&
        selectedBusinessLineIdStatus === "Active" &&
        status !== "inactive"
      ),
    },
  ];
  const businessPartnerPlan_Status_AllActive = businessPartnerPlan_Status.every(
    (status) => status === "active"
  );
  const businessPartnerPlan_BPLine_Status_AllActive =
    businessPartnerPlan_BPLine_Status.every((status) => status === "active");
  const businessPartnerPlan_Line_Status_AllActive =
    businessPartnerPlan_Line_Status.every((status) => status === "active");
  const businessPartnerPlan_Plan_Status_AllActive =
    businessPartnerPlan_Plan_Status.every((status) => status === "active");

  let assignedPlansListingActions: IEnhancedTableMenuItem[] = [];
  assignedPlansListingActions = [
    {
      title: "Add Plan(s)",
      onClick: () => {
        setAddPlansDrawerOpen(true);
      },
      isEntity: false,
      isBulk: false,
      iconUrl: "",
      hidden: !isAdmin,
      disabled: false,
    },
    {
      title: "Set Commission",
      onClick: () => {
        setAssignedPlansCommissionDrawerOpen(true);
      },
      isEntity: false,
      isBulk: false,
      iconUrl: "",
      hidden: !isAdmin || businessPartnerType === "Direct_Salesforce",
      disabled:
        businessPartnerType === "Direct_Salesforce" ||
        selectedPlanItems === 0 ||
        businessPartnerPlan_Status_AllActive === false ||
        businessPartnerPlan_BPLine_Status_AllActive === false ||
        businessPartnerPlan_Line_Status_AllActive === false ||
        businessPartnerPlan_Plan_Status_AllActive === false ||
        businessPartnerStatus === "inactive",
    },
    {
      title: "Activate Plan(s)",
      onClick: () => {},
      isEntity: false,
      isBulk: false,
      iconUrl: "",
      hidden: !isAdmin || businessPartnerStatus === "inactive",
      disabled:
        businessPartnerPlan_Status_AllActive === true ||
        businessPartnerPlan_BPLine_Status_AllActive === false ||
        businessPartnerPlan_Line_Status_AllActive === false ||
        businessPartnerPlan_Plan_Status_AllActive === false ||
        selectedPlanItems === 0 ||
        businessPartnerStatus === "inactive",
    },
    {
      title: "Deactivate Plan(s)",
      onClick: () => {},
      isEntity: false,
      isBulk: false,
      iconUrl: "",
      hidden: !isAdmin || businessPartnerStatus === "inactive",
      disabled:
        businessPartnerPlan_Status_AllActive === false ||
        businessPartnerPlan_BPLine_Status_AllActive === false ||
        businessPartnerPlan_Line_Status_AllActive === false ||
        businessPartnerPlan_Plan_Status_AllActive === false ||
        selectedPlanItems === 0 ||
        businessPartnerStatus === "inactive",
    },
  ];

  let vignetteRangeActions: IEnhancedTableMenuItem[] = [];
  vignetteRangeActions = [
    {
      title: "New",
      onClick: () => {},
      isEntity: false,
      isBulk: false,
      iconUrl: tenant.cdnUrl + "/icons/add-primary-red.svg",
      hidden: !(isAdmin && status !== "inactive"),
      disabled: status === "inactive",
    },
    {
      title: "Edit",
      onClick: () => {},
      isEntity: false,
      isBulk: false,
      iconUrl: tenant.cdnUrl + "/icons/edit-secondary.svg",
      hidden: !(isAdmin && status !== "inactive"),
      disabled: selectedVignetteRangeItems !== 1,
    },
  ];

  vignetteRangeActions[0].onClick = () => {
    setActionClicked("new");
    setVignetteRangeDrawerOpen(true);
  };
  vignetteRangeActions[1].onClick = () => {
    setActionClicked("edit");
    setVignetteRangeDrawerOpen(true);
  };

  assignedLinesListingActions[0].onClick = () => {
    setDefaultCommissionDrawerOpen(true);
  };
  assignedLinesListingActions[1].onClick = () => {
    setLineCreditLimitDrawerOpen(true);
  };
  assignedLinesListingActions[2].onClick = () => {
    const confirmation: IConfirmation = {
      open: true,
      title: "Activate",
      message: `Are you sure you want to activate the selected assigned line?`,
      callback: async () => {
        activateBusinessPartnerLineAction()
          .then((result) => {
            if (isEmpty(result.errors)) {
              toast.success(
                <ToastSuccessMessage>
                  {
                    "Assigned line successfully activated. Ensure to update Line Credit Limit. Note that activating the assigned line will not activate the relevant plans."
                  }
                </ToastSuccessMessage>
              );
            } else {
              toast.error(
                <ToastErrorMessage>{getError(result)}</ToastErrorMessage>
              );
            }
          })
          .finally(() => {});
      },
      submitButtonText: "Submit",
      cancelButtonText: "Cancel",
    };
    dispatch(OpenConfirmationAction(confirmation));
  };
  assignedLinesListingActions[3].onClick = () => {
    const confirmation: IConfirmation = {
      open: true,
      title: "Deactivate",
      message: `Are you sure you want to deactivate the selected assigned line?
      Note that deactivating the assigned line will deactivate all assigned plans.`,
      callback: async () => {
        deactivateBusinessPartnerLineAction()
          .then((result) => {
            if (isEmpty(result.errors)) {
              toast.success(
                <ToastSuccessMessage>
                  {
                    "Assigned line successfully deactivated. Note that Credit Limit is set to 0 and all relevant plans are deactivated as well."
                  }
                </ToastSuccessMessage>
              );
            } else {
              toast.error(
                <ToastErrorMessage>{getError(result)}</ToastErrorMessage>
              );
            }
          })
          .finally(() => {});
      },
      submitButtonText: "Submit",
      cancelButtonText: "Cancel",
    };
    dispatch(OpenConfirmationAction(confirmation));
  };

  assignedPlansListingActions[2].onClick = () => {
    const confirmation: IConfirmation = {
      open: true,
      title: "Activate",
      message: `Are you sure you want to activate the selected assigned plan(s)?`,
      callback: async () => {
        activateBusinessPartnerPlanAction()
          .then((result) => {
            if (isEmpty(result.errors)) {
              toast.success(
                <ToastSuccessMessage>
                  {"Assigned plan successfully activated."}
                </ToastSuccessMessage>
              );
            } else {
              toast.error(
                <ToastErrorMessage>{getError(result)}</ToastErrorMessage>
              );
            }
          })
          .finally(() => {});
      },
      submitButtonText: "Submit",
      cancelButtonText: "Cancel",
    };
    dispatch(OpenConfirmationAction(confirmation));
  };
  assignedPlansListingActions[3].onClick = () => {
    const confirmation: IConfirmation = {
      open: true,
      title: "Deactivate",
      message: `Are you sure you want to deactivate the selected assigned plan(s)?`,
      callback: async () => {
        deactivateBusinessPartnerPlanAction()
          .then((result) => {
            if (isEmpty(result.errors)) {
              toast.success(
                <ToastSuccessMessage>
                  {"Assigned plan successfully deactivated."}
                </ToastSuccessMessage>
              );
            } else {
              toast.error(
                <ToastErrorMessage>{getError(result)}</ToastErrorMessage>
              );
            }
          })
          .finally(() => {});
      },
      submitButtonText: "Submit",
      cancelButtonText: "Cancel",
    };
    dispatch(OpenConfirmationAction(confirmation));
  };
  //#endregion tableMenu

  const [getBusinessPartnerDetailsLazy, entityResult] = useLazyQuery(
    getBusinessPartnerDetailsInfo(),
    {
      variables: { id: entityId },
      errorPolicy: "all",
      fetchPolicy: "no-cache",
    }
  );

  const [
    getBusinessPartnerLinesListWithoutPaginationLazy,
    businessPartnerLinesListWithoutPaginationQueryRes,
  ] = useLazyQuery(getBusinessPartnerLinesListWithoutPagination(), {
    fetchPolicy: "no-cache",
    variables: { selectedBusinessPartnerID: entityId },
  });

  const [activateAction] = useMutation(activateBusinessPartner(), {
    variables: {
      entityId: entityId,
    },
    errorPolicy: "all",
    refetchQueries: [getBusinessPartnerDetailsInfo()],
    onCompleted: () => {
      setBusinessPartnerStatus("");
    },
  });
  const [deactivateAction] = useMutation(deactivateBusinessPartner(), {
    variables: {
      entityId: entityId,
    },
    errorPolicy: "all",
    refetchQueries: [getBusinessPartnerDetailsInfo()],
    onCompleted: () => {
      setBusinessPartnerStatus("");
    },
  });

  const [tableData, setTableData] = useState<IListingData<any>>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 1,
    totalCount: 0,
  });

  const [assignedPlansTableData, setAssignedPlansTableData] = useState<
    IListingData<any>
  >({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 1,
    totalCount: 0,
  });

  const [vignetteRangeTableData, setVignetteRangeTableData] = useState<
    IListingData<any>
  >({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 1,
    totalCount: 0,
  });

  const assignedLineListResult = useQuery(getBusinessPartnerLinesList(), {
    variables: {
      currentPage: 1,
      currentPageSize: tableData.pageSize,
      selectedBusinessPartnerID: entityId,
    },
    errorPolicy: "all",
  });
  const assignedPlanListResult = useQuery(getBusinessPartnerPlanList(), {
    variables: {
      currentPage: 1,
      currentPageSize: assignedPlansTableData.pageSize,
      selectedBusinessPartnerID: entityId,
      selectedBusinessPartnerLineID: selectedLineFilter,
    },
    errorPolicy: "all",
  });
  const vignetteRangeListResult = useQuery(
    getBusinessPartnerVignetteRangeList(),
    {
      variables: {
        currentPage: 1,
        currentPageSize: vignetteRangeTableData.pageSize,
        selectedBusinessPartnerID: entityId,
      },
      errorPolicy: "all",
    }
  );

  const [activateBusinessPartnerLineAction] = useMutation(
    activateBusinessPartnerLine(),
    {
      variables: {
        entityId: selectedBusinessLineId,
      },
      errorPolicy: "all",
      refetchQueries: [
        getBusinessPartnerLinesList(),
        getBusinessPartnerPlanList(),
      ],
    }
  );
  const [deactivateBusinessPartnerLineAction] = useMutation(
    deactivateBusinessPartnerLine(),
    {
      variables: {
        entityId: selectedBusinessLineId,
      },
      errorPolicy: "all",
      refetchQueries: [
        getBusinessPartnerLinesList(),
        getBusinessPartnerPlanList(),
      ],
    }
  );

  const [activateBusinessPartnerPlanAction] = useMutation(
    activateBusinessPartnerPlan(),
    {
      variables: {
        entityId: entityId,
        selectedBusinessPartnerPlanIds: selectedBusinessPlanIds,
      },
      errorPolicy: "all",
      refetchQueries: [getBusinessPartnerPlanList()],
    }
  );
  const [deactivateBusinessPartnerPlanAction] = useMutation(
    deactivateBusinessPartnerPlan(),
    {
      variables: {
        entityId: entityId,
        selectedBusinessPartnerPlanIds: selectedBusinessPlanIds,
      },
      errorPolicy: "all",
      refetchQueries: [getBusinessPartnerPlanList()],
    }
  );

  useEffect(() => {
    try {
      if (!entityResult.loading) {
        setLoadingState(false);
      }

      if (entityResult?.data) {
        if (isEmpty(businessPartnerStatus)) {
          setBusinessPartnerStatus(
            entityResult?.data?.SalesforceManagement?.entities?.businessPartner?.views?.SalesforceManagement_all?.properties?.Status.toLowerCase()
          );
        }

        if (isEmpty(businessPartnerType)) {
          setBusinessPartnerType(
            entityResult?.data?.SalesforceManagement?.entities?.businessPartner?.views?.SalesforceManagement_all?.properties?.Type?.Title.toLowerCase()
          );
        }

        const businessPartnerDetails = entityToBusinessPartnerDetails(
          entityResult?.data
        );

        //get application section properties
        const applicationProperties = entityView.sections[0];
        applicationProperties.properties.name = {
          multiline: false,
          name: "name",
          title: "Full Name",
          value: businessPartnerDetails.name,
          type: EnhancedDisplayType.RelativeLink,
          tooltip: false,
          url: "/salesforce/persons/" + businessPartnerDetails.personId,
        };
        applicationProperties.properties.businessPartnerCode.value =
          businessPartnerDetails.businessPartnerCode;
        applicationProperties.properties.dateOfBirth.value =
          businessPartnerDetails.dateOfBirth
            ? dayjs(new Date(businessPartnerDetails.dateOfBirth)).format(
                "DD/MM/YYYY"
              )
            : null;
        applicationProperties.properties.email.value =
          businessPartnerDetails.email;
        applicationProperties.properties.phoneType.value =
          businessPartnerDetails.phoneType;
        applicationProperties.properties.phoneNumber.value =
          businessPartnerDetails.phoneNumber;
        applicationProperties.properties.taxable.value =
          businessPartnerDetails.taxable;

        //get the system propeties section
        const systemProperties = entityView.sections[1];
        systemProperties.properties.createdBy.value =
          businessPartnerDetails.createdBy;
        systemProperties.properties.createdOn.value = dayjs(
          new Date(businessPartnerDetails.createdOn)
        ).format("DD/MM/YYYY");
        systemProperties.properties.modifiedBy.value =
          businessPartnerDetails.modifiedBy;
        systemProperties.properties.modifiedOn.value = dayjs(
          new Date(businessPartnerDetails.modifiedOn)
        ).format("DD/MM/YYYY");

        const newLeftEntityView = {
          ...entityView,
          sections: [applicationProperties, systemProperties],
        };

        setBusinessPartnerDetailsInfo(businessPartnerDetails);
        setUpdatedLeftSideInfo(newLeftEntityView);

        const dynamicView: IDynamicDiplayView = {
          name: "Business Partner Information",
          sections: [
            {
              name: "businessPartnerDetails",
              title: "Business Partner Details",
              showActionButtons: true,
              properties: {
                relatedCompany: {
                  multiline: false,
                  value: businessPartnerDetails.relatedCompanyTitle,
                  name: "relatedCompany",
                  title: "Related Company",
                },
                type: {
                  multiline: false,
                  value: capitalizeFirstLetter(
                    businessPartnerDetails.typeTitle
                  ),
                  name: "type",
                  title: "Type",
                },
                category: {
                  multiline: false,
                  value: businessPartnerDetails.categoryTitle,
                  name: "category",
                  title: "Category",
                },
                isCompany: {
                  multiline: false,
                  value: businessPartnerDetails.isCompany ? "Yes" : "No",
                  name: "isCompany",
                  title: "Is Company",
                },
                isLocal: {
                  multiline: false,
                  value: businessPartnerDetails.isLocal ? "Yes" : "No",
                  name: "isLocal",
                  title: "Is Local",
                },
                isRegistered: {
                  multiline: false,
                  value: businessPartnerDetails.isRegistered ? "Yes" : "No",
                  name: "isRegistered",
                  title: "Is Registered",
                },
                registerNumber: {
                  multiline: false,
                  value: businessPartnerDetails.registerNumber,
                  name: "registerNumber",
                  title: "Register Number",
                },
                taxAccount: {
                  multiline: false,
                  value: businessPartnerDetails.taxAccount,
                  name: "taxAccount",
                  title: "Tax Account",
                },
                account: {
                  multiline: false,
                  value: businessPartnerDetails.account,
                  name: "account",
                  title: "Account",
                },
              },
            },
          ],
        };

        const creditLimitDynamicView: IDynamicDiplayView = {
          name: "Business Partner Information",
          sections: [
            {
              name: "maximumCreditLimit",
              title: "Max Credit Limit",
              showActionButtons: true,
              properties: {
                relatedCompany: {
                  multiline: false,
                  value:
                    businessPartnerDetails.maxCreditLimitAllowedCurrencyTitle,
                  name: "maxCreditLimitAllowedCurrency",
                  title: "Max Credit Limit Allowed (Currency)",
                },
                type: {
                  multiline: false,
                  value: businessPartnerDetails.maxCreditLimitAllowedShow,
                  name: "maxCreditLimitAllowed",
                  title: "Max Credit Limit Allowed",
                },
              },
            },
          ],
          isCollapsible: true,
        };
        setBusinessPartnerInformationViewConfig(dynamicView);
        setAssignedLinesViewConfig(dynamicView);
        setCreditLimitInformationViewConfig(creditLimitDynamicView);

        const newStatus =
          entityResult?.data?.SalesforceManagement?.entities?.businessPartner?.views?.SalesforceManagement_all?.properties?.Status.toLowerCase();
        setStatus(newStatus);
      }
    } catch (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  }, [entityResult?.data]);

  useEffect(() => {
    if (isEmpty(businessPartnerLinesListWithoutPaginationQueryRes.data)) {
      getBusinessPartnerLinesListWithoutPaginationLazy();
    }

    if (businessPartnerLinesListWithoutPaginationQueryRes.error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }

    if (businessPartnerLinesListWithoutPaginationQueryRes?.data) {
      const extractedLineSublines = extractLines(
        businessPartnerLinesListWithoutPaginationQueryRes.data
      );
      setLovs(extractedLineSublines);
    }
  }, [
    businessPartnerLinesListWithoutPaginationQueryRes.error,
    businessPartnerLinesListWithoutPaginationQueryRes.data,
  ]);

  useEffect(() => {
    if (assignedLineListResult?.loading) setLoadingState(true);
    if (assignedLineListResult?.error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
    if (assignedLineListResult?.data) {
      const lineTableData = mapToAssignedLinesListingData(
        assignedLineListResult?.data
      );
      setTableData({ ...lineTableData, pageNumber: 0 });
    }
    setLoadingState(false);
  }, [
    assignedLineListResult?.loading,
    assignedLineListResult?.error,
    assignedLineListResult?.data,
  ]);

  useEffect(() => {
    if (vignetteRangeListResult?.loading) setLoadingState(true);
    if (vignetteRangeListResult?.error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
    if (vignetteRangeListResult?.data) {
      const vignetteRangeTableData = mapToVignetteRangeListingData(
        vignetteRangeListResult?.data
      );
      setVignetteRangeTableData({ ...vignetteRangeTableData, pageNumber: 0 });
    }
    setLoadingState(false);
  }, [
    vignetteRangeListResult?.loading,
    vignetteRangeListResult?.error,
    vignetteRangeListResult?.data,
  ]);

  useEffect(() => {
    if (assignedPlanListResult?.loading) setLoadingState(true);
    if (assignedPlanListResult?.error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
    if (assignedPlanListResult?.data) {
      const planTableData = mapToAssignedPlanListingData(
        assignedPlanListResult?.data
      );
      setAssignedPlansTableData({ ...planTableData, pageNumber: 0 });
    }
    setLoadingState(false);
  }, [
    assignedPlanListResult?.loading,
    assignedPlanListResult?.error,
    assignedPlanListResult?.data,
  ]);

  function handlePageChange(page: number) {
    setAssignedLinesTableLoadingState(true);
    assignedLineListResult
      ?.fetchMore({
        variables: {
          currentPage: ++page,
          currentPageSize: tableData.pageSize,
        },
      })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const newApplicationList = mapToAssignedLinesListingData(
            fetchMoreResult.data
          );
          const updatedTableData = {
            ...newApplicationList,
            pageNumber: --page,
          };
          setTableData(updatedTableData);
        }
      })
      .finally(() => {
        setAssignedLinesTableLoadingState(false);
      });
  }
  function handleRowsPerPageChange(numberOfRecordsPerPage: number) {
    setAssignedLinesTableLoadingState(true);
    setTableData({ ...tableData, pageNumber: 1 });
    assignedLineListResult
      ?.fetchMore({
        variables: {
          currentPage: 1,
          currentPageSize: numberOfRecordsPerPage,
        },
      })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const newApplicationList = mapToAssignedLinesListingData(
            fetchMoreResult.data
          );

          const updatedTableData = {
            ...newApplicationList,
            pageNumber: 0,
            pageSize: numberOfRecordsPerPage,
          };
          setTableData(updatedTableData);
        }
      })
      .finally(() => {
        setAssignedLinesTableLoadingState(false);
      });
  }

  function handleAssignedPlansPageChange(page: number) {
    setAssignedPlansTableLoadingState(true);
    assignedPlanListResult
      ?.fetchMore({
        variables: {
          currentPage: ++page,
          currentPageSize: assignedPlansTableData.pageSize,
        },
      })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const newApplicationList = mapToAssignedPlanListingData(
            fetchMoreResult.data
          );
          const updatedTableData = {
            ...newApplicationList,
            pageNumber: --page,
          };
          setAssignedPlansTableData(updatedTableData);
        }
      })
      .finally(() => {
        setAssignedPlansTableLoadingState(false);
      });
  }
  function handleAssignedPlansRowsPerPageChange(
    numberOfRecordsPerPage: number
  ) {
    setAssignedPlansTableLoadingState(true);
    setAssignedPlansTableData({ ...assignedPlansTableData, pageNumber: 1 });
    assignedPlanListResult
      ?.fetchMore({
        variables: {
          currentPage: 1,
          currentPageSize: numberOfRecordsPerPage,
        },
      })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const newApplicationList = mapToAssignedPlanListingData(
            fetchMoreResult.data
          );

          const updatedTableData = {
            ...newApplicationList,
            pageNumber: 0,
            pageSize: numberOfRecordsPerPage,
          };
          setAssignedPlansTableData(updatedTableData);
        }
      })
      .finally(() => {
        setAssignedPlansTableLoadingState(false);
      });
  }

  function handleVignetteRangePageChange(page: number) {
    setVignetteRangeTableLoadingState(true);
    vignetteRangeListResult
      ?.fetchMore({
        variables: {
          currentPage: ++page,
          currentPageSize: vignetteRangeTableData.pageSize,
        },
      })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const newApplicationList = mapToVignetteRangeListingData(
            fetchMoreResult.data
          );
          const updatedTableData = {
            ...newApplicationList,
            pageNumber: --page,
          };
          setVignetteRangeTableData(updatedTableData);
        }
      })
      .finally(() => {
        setVignetteRangeTableLoadingState(false);
      });
  }
  function handleVignetteRangeRowsPerPageChange(
    numberOfRecordsPerPage: number
  ) {
    setVignetteRangeTableLoadingState(true);
    setVignetteRangeTableData({ ...vignetteRangeTableData, pageNumber: 1 });
    vignetteRangeListResult
      ?.fetchMore({
        variables: {
          currentPage: 1,
          currentPageSize: numberOfRecordsPerPage,
        },
      })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const newApplicationList = mapToVignetteRangeListingData(
            fetchMoreResult.data
          );

          const updatedTableData = {
            ...newApplicationList,
            pageNumber: 0,
            pageSize: numberOfRecordsPerPage,
          };
          setVignetteRangeTableData(updatedTableData);
        }
      })
      .finally(() => {
        setVignetteRangeTableLoadingState(false);
      });
  }

  useEffect(() => {
    getBusinessPartnerDetailsLazy();
  }, []);

  const renderMainChildren = () => {
    const isBusinessPartnerActive = status === "active";
    const isBusinessPartnerNew = status === "new";
    const steps = isBusinessPartnerNew
      ? ["New", "Active"]
      : ["New", isBusinessPartnerActive ? "Active" : "Inactive"];

    return (
      <>
        {/* {isModalOpen && (
          <ConfirmationModal
            open={isModalOpen}
            title="Deactivate"
            message="AAAAAAAAAAAAre you sure you want to deactivate the selected assigned line? Note that deactivating the assigned line will deactivate all assigned plans."
            onConfirm={handleDeactivate}
            onCancel={() => setIsModalOpen(false)}
            submitButtonText="Submit"
            cancelButtonText="Cancel"
          />
        )} */}

        <div style={{ marginTop: "20px" }}>
          <EnhancedStepper
            activeStep={getBusinessPartnerStatus(steps, status)}
            steps={steps}
            className={classes.stepper}
            showStepperButton
            buttonTitle={isBusinessPartnerActive ? "Deactivate" : "Activate"}
            buttonState={
              isBusinessPartnerActive
                ? activateButtonState
                : deactivateButtonState
            }
            buttonOnClick={() => {
              if (isBusinessPartnerActive) {
                const confirmation: IConfirmation = {
                  open: true,
                  title: "Deactivate",
                  message: `Are you sure you want to deactivate this business partner?`,
                  callback: async () => {
                    setDeactivateButtonState("loading");
                    deactivateAction()
                      .then((result) => {
                        if (isEmpty(result.errors)) {
                          setDeactivateButtonState("success");
                          toast.success(
                            <ToastSuccessMessage>
                              {"Business Partner successfully deactivated."}
                            </ToastSuccessMessage>
                          );
                        } else {
                          setDeactivateButtonState(undefined);
                          toast.error(
                            <ToastErrorMessage>
                              {getError(result)}
                            </ToastErrorMessage>
                          );
                        }
                      })
                      .finally(() => {
                        setTimeout(() => {
                          setDeactivateButtonState(undefined);
                        }, 1000);
                      });
                  },
                  submitButtonText: "Yes",
                  cancelButtonText: "No",
                };
                dispatch(OpenConfirmationAction(confirmation));
              } else {
                const confirmation: IConfirmation = {
                  open: true,
                  title: "Activate",
                  message: `Are you sure you want to activate this business partner?`,
                  callback: async () => {
                    setActivateButtonState("loading");
                    activateAction()
                      .then((result) => {
                        if (isEmpty(result.errors)) {
                          setActivateButtonState("success");
                          toast.success(
                            <ToastSuccessMessage>
                              {"Business Partner successfully activated."}
                            </ToastSuccessMessage>
                          );
                        } else {
                          setActivateButtonState(undefined);
                          toast.error(
                            <ToastErrorMessage>
                              {getError(result)}
                            </ToastErrorMessage>
                          );
                        }
                      })
                      .finally(() => {
                        setTimeout(() => {
                          setActivateButtonState(undefined);
                        }, 1000);
                      });
                  },
                  submitButtonText: "Yes",
                  cancelButtonText: "No",
                };
                dispatch(OpenConfirmationAction(confirmation));
              }
            }}
          />
        </div>
      </>
    );
  };

  const renderLeftSection = () => {
    return (
      <>
        <EntityInfoWidget
          background="rgb(193, 0, 0)"
          classification={{ classifications: {}, value: "" }}
          progress={100}
          supportsClassification={true}
          supportsProgressBar={true}
          title={businessPartnerDetailsInfo.name}
          view={updatedLeftSideInfo}
        ></EntityInfoWidget>
      </>
    );
  };

  //#region tabs
  const tabs = () => {
    businessPartnerTabs.tabs[0].widgets[0].children =
      businessPartnerInformationViewConfig && (
        <>
          <DynamicWidget
            hasFourFields={true}
            view={businessPartnerInformationViewConfig}
            actions={
              <ShowForUser allowedRoles={["Insurance-Admin"]}>
                {status != "inactive" && (
                  <div>
                    <EnhancedButton
                      type="button"
                      backgroundColor={MAIN_ONE_THEME.palette.primary1.main}
                      color="#FFF"
                      onClick={() => setBusinessPartnerInfoDrawerOpen(true)}
                      className={classes.actionButton}
                    >
                      Modify
                    </EnhancedButton>
                  </div>
                )}
              </ShowForUser>
            }
          />
        </>
      );

    businessPartnerTabs.tabs[1].widgets[0].children = (
      <>
        {creditLimitInformationViewConfig &&
          businessPartnerType !== "Direct_Salesforce" && (
            <DynamicWidget
              hasFourFields={true}
              view={creditLimitInformationViewConfig}
              actions={
                <ShowForUser allowedRoles={["Insurance-Admin"]}>
                  {status != "inactive" && (
                    <div>
                      <EnhancedButton
                        type="button"
                        backgroundColor={MAIN_ONE_THEME.palette.primary1.main}
                        color="#FFF"
                        onClick={() => setMaximumCreditLimitDrawerOpen(true)}
                        className={classes.actionButton}
                      >
                        Modify
                      </EnhancedButton>
                    </div>
                  )}
                </ShowForUser>
              }
            />
          )}
        <WidgetSection style={{ margin: "-23px 0 0 0" }}>
          {assignedLinesViewConfig && (
            <form>
              {assignedLineListResult?.loading ? (
                <div></div>
              ) : (
                <div
                  style={{
                    marginTop: "20px",
                    padding: "-23px 0 0 0 !important",
                  }}
                >
                  <EnhancedTable
                    title={capitalizeFirstLetter("Assigned Lines")}
                    inlineTitle={capitalizeFirstLetter("Assigned Lines")}
                    name="count"
                    entityName={"AssignedLines"}
                    entityIdColumnName={
                      "salesforceManagement_BusinessPartnerLine_Id"
                    }
                    showCellFullData={true}
                    isTitlePascalCase={true}
                    orderByAscendingByDefault
                    defaultOrderByColumn="name"
                    inline={true}
                    data={tableData}
                    headers={headers}
                    handlePageChange={(page: number) => handlePageChange(page)}
                    handleRowsPerPageChange={(page: number) =>
                      handleRowsPerPageChange(page)
                    }
                    currentPage={tableData.pageNumber}
                    hideToolbar={false}
                    usePagination
                    disableSelection={false}
                    actions={assignedLinesListingActions}
                    loader={assignedLinesTableLoadingState}
                    showTablePagination={true}
                    secondaryActions={true}
                    specificDesign={true}
                    onSelectionChange={(newDetailedSelection) => {
                      setSelectedLineItems(newDetailedSelection.length);
                      const itemIds = [];
                      for (const item of newDetailedSelection) {
                        itemIds.push(item.id);
                      }
                      setSelectedLineCreditLimitItemsIds(itemIds);
                      if (newDetailedSelection.length > 0) {
                        setSelectedBusinessLineId(newDetailedSelection[0].id);
                        setSelectedBusinessLineStatus(
                          newDetailedSelection[0].lineStatus
                        );
                        setSelectedBusinessLineIdStatus(
                          newDetailedSelection[0].lineIdStatus
                        );
                      } else {
                        setSelectedBusinessLineId("");
                        setSelectedBusinessLineStatus("");
                        setSelectedBusinessLineIdStatus("");
                      }
                    }}
                  />
                  {defaultCommissionDrawerOpen && (
                    <SetDefaultCommissionDrawer
                      open={defaultCommissionDrawerOpen}
                      businessPartnerId={entityId}
                      onClose={() => setDefaultCommissionDrawerOpen(false)}
                      onSuccess={() => {
                        handlePageChange(0);
                        handleAssignedPlansPageChange(0);
                      }}
                      assignedLineId={selectedBusinessLineId}
                    />
                  )}
                </div>
              )}

              <div>
                <div>
                  <div
                    style={{
                      marginTop: "20px",
                      padding: "-23px 0 0 0 !important",
                    }}
                  >
                    <EnhancedTable
                      title={capitalizeFirstLetter("Assigned Plans")}
                      inlineTitle={capitalizeFirstLetter("Assigned Plans")}
                      lineNameController={
                        <Controller
                          name={"lineName"}
                          control={control}
                          rules={{}}
                          key={"lineName"}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <EnhancedChipInput
                              name={"lineName"}
                              title=""
                              value={selectedLineFilter}
                              placeholder="Filter by Line"
                              onChange={(newValue) => {
                                onChange(newValue);
                                setSelectedLineFilter(newValue);
                              }}
                              onBlur={(e) => {
                                onBlur();
                              }}
                              selectOptions={lovs?.lines}
                              error={errors?.lineName?.message}
                              style={{ width: "20%", marginTop: "30px" }}
                              hasSpecificStyle={true}
                              material
                              canClearSingleValueSelection={false}
                              isInlineButton={true}
                            />
                          )}
                        />
                      }
                      name="count"
                      entityName={"AssignedPlans"}
                      entityIdColumnName={
                        "salesforceManagement_BusinessPartnerPlan_Id"
                      }
                      showCellFullData={true}
                      isTitlePascalCase={true}
                      orderByAscendingByDefault
                      defaultOrderByColumn="name"
                      inline={true}
                      data={assignedPlansTableData}
                      headers={assignedPLansHeaders}
                      handlePageChange={(page: number) =>
                        handleAssignedPlansPageChange(page)
                      }
                      handleRowsPerPageChange={(page: number) =>
                        handleAssignedPlansRowsPerPageChange(page)
                      }
                      currentPage={assignedPlansTableData.pageNumber}
                      hideToolbar={false}
                      usePagination
                      disableSelection={false}
                      actions={assignedPlansListingActions}
                      loader={assignedPlansTableLoadingState}
                      showTablePagination={true}
                      secondaryActions={true}
                      specificDesign={true}
                      onSelectionChange={(newDetailedSelection) => {
                        setSelectedPlanItems(newDetailedSelection.length);
                        if (newDetailedSelection.length > 0) {
                          if (newDetailedSelection.length === 1) {
                            setSelectedDetailedSelection(newDetailedSelection);
                          } else {
                            setSelectedDetailedSelection([]);
                          }
                          const itemIds = [];
                          const businessPartnerPlanStatuses = [];
                          const businessPartnerPlan_BusinessPartnerLine_Status =
                            [];
                          const businessPartnerPlan_Line_Status = [];
                          const businessPartnerPlan_Plan_Status = [];

                          for (const item of newDetailedSelection) {
                            itemIds.push(item.id);
                            businessPartnerPlanStatuses.push(
                              item.businessPartnerPlanStatus.toLowerCase()
                            );
                            businessPartnerPlan_BusinessPartnerLine_Status.push(
                              item.businessPartnerPlan_BusinessPartnerLine_Status.toLowerCase()
                            );
                            businessPartnerPlan_Line_Status.push(
                              item.businessPartnerPlan_Line_Status.toLowerCase()
                            );
                            businessPartnerPlan_Plan_Status.push(
                              item.businessPartnerPlan_Plan_Status.toLowerCase()
                            );
                          }
                          setSelectedBusinessPlanIds(itemIds);
                          setBusinessPartnerPlan_Status(
                            businessPartnerPlanStatuses
                          );
                          setBusinessPartnerPlan_BPLine_Status(
                            businessPartnerPlan_BusinessPartnerLine_Status
                          );
                          setBusinessPartner_Line_Status(
                            businessPartnerPlan_Line_Status
                          );
                          setBusinessPartner_Plan_Status(
                            businessPartnerPlan_Plan_Status
                          );
                        } else {
                          setSelectedBusinessPlanIds([]);
                          setBusinessPartnerPlan_Status([]);
                          setBusinessPartnerPlan_BPLine_Status([]);
                          setBusinessPartner_Line_Status([]);
                          setBusinessPartner_Plan_Status([]);
                        }
                      }}
                    />
                    {addPlansDrawerOpen && (
                      <BusinessPartnerAddPlansDrawer
                        open={addPlansDrawerOpen}
                        businessPartnerId={entityId}
                        businessPartnerType={businessPartnerType}
                        selectedBusinessPlanIds={selectedBusinessPlanIds}
                        selectedDetailedSelection={selectedDetailedSelection}
                        onClose={() => setAddPlansDrawerOpen(false)}
                        onSuccess={() => {
                          handleAssignedPlansPageChange(0);
                        }}
                      />
                    )}
                    {assignedPlansCommissionDrawerOpen && (
                      <SalesforceBusinessPartnerCommissionDrawer
                        open={assignedPlansCommissionDrawerOpen}
                        businessPartnerId={entityId}
                        businessPartnerLinesIds={selectedBusinessPlanIds}
                        selectedBusinessPlanIds={selectedBusinessPlanIds}
                        selectedDetailedSelection={selectedDetailedSelection}
                        onClose={() =>
                          setAssignedPlansCommissionDrawerOpen(false)
                        }
                        onSuccess={() => {
                          handleAssignedPlansPageChange(0);
                        }}
                        businessPartnerType={businessPartnerType}
                      />
                    )}
                  </div>
                </div>
              </div>
            </form>
          )}
        </WidgetSection>
      </>
    );

    businessPartnerTabs.tabs[2].widgets[0].children = (
      <WidgetSection>
        <form>
          <EnhancedTable
            title={capitalizeFirstLetter("Vignette Range Listing")}
            inlineTitle={capitalizeFirstLetter("Vignette Range Listing")}
            name="count"
            entityName={"Vignette"}
            entityIdColumnName={
              "salesforceManagement_BusinessPartnerVignetteRange_Id"
            }
            showCellFullData={true}
            isTitlePascalCase={true}
            orderByAscendingByDefault
            defaultOrderByColumn="name"
            inline={true}
            data={vignetteRangeTableData}
            headers={vignetteRangeheaders}
            handlePageChange={(page: number) =>
              handleVignetteRangePageChange(page)
            }
            handleRowsPerPageChange={(page: number) =>
              handleVignetteRangeRowsPerPageChange(page)
            }
            currentPage={vignetteRangeTableData.pageNumber}
            hideToolbar={false}
            usePagination
            disableSelection={false}
            actions={vignetteRangeActions}
            loader={vignetteRangeTableLoadingState}
            showTablePagination={true}
            onSelectionChange={(newDetailedSelection) => {
              setSelectedVignetteRangeItems(newDetailedSelection.length);
              if (newDetailedSelection.length > 0) {
                setSelectedVignetteRangeId(newDetailedSelection[0].id);
              } else {
                setSelectedVignetteRangeId("");
              }
            }}
          />
          {vignetteRangeDrawerOpen && (
            <SalesforceVignetteRangeDrawer
              open={vignetteRangeDrawerOpen}
              onClose={() => setVignetteRangeDrawerOpen(false)}
              onSuccess={() => {
                handleVignetteRangePageChange(0);
              }}
              vignetteRangeId={
                actionClicked === "new" ? null : selectedVignetteRangeId
              }
            />
          )}
        </form>
      </WidgetSection>
    );

    return businessPartnerTabs;
  };
  //#endregion tabs

  return loadingState ? (
    <Loader />
  ) : (
    <>
      <div>
        {businessPartnerInfoDrawerOpen && (
          <SalesForceBusinessPartnerDrawer
            open={businessPartnerInfoDrawerOpen}
            onClose={() => setBusinessPartnerInfoDrawerOpen(false)}
            onSuccess={() => {
              getBusinessPartnerDetailsLazy();
            }}
            businessPartnerId={params.id}
            salesForceBusinessPartnerDetailsInfo={entityToBusinessPartnerDetails(
              entityResult?.data
            )}
          />
        )}
        {maximumCreditLimitDrawerOpen && (
          <SalesForceMaxCreditLimitDrawer
            open={maximumCreditLimitDrawerOpen}
            onClose={() => setMaximumCreditLimitDrawerOpen(false)}
            onSuccess={() => {
              getBusinessPartnerDetailsLazy();
            }}
            businessPartnerId={params.id}
            salesForceMaxCreditLimitDetailsInfo={entityToBusinessPartnerDetails(
              entityResult?.data
            )}
          />
        )}
        {lineCreditLimitDrawerOpen && (
          <SalesForceLineCreditLimitDrawer
            open={lineCreditLimitDrawerOpen}
            businessPartnerId={entityId}
            businessPartnerLinesId={selectedLineCreditLimitItemsIds}
            onClose={() => setLineCreditLimitDrawerOpen(false)}
            onSuccess={() => {
              handlePageChange(0);
              handleAssignedPlansPageChange(0);
            }}
            assignedLineId={selectedBusinessLineId}
          />
        )}
      </div>
      <TabsLayout
        name="policyPageDetails"
        layout={tabs()}
        theme={tenant.theme}
        leftChildren={renderLeftSection()}
        mainChildren={renderMainChildren()}
        cdnUrl={tenant.cdnUrl}
        userInfo={user["info"]}
        tabPanelClassName={classes.tabPanelStyle}
        firstTabAsActiveTab={true}
      />
    </>
  );
};

export default SalesforceBusinessPartnerDetailsPage;
