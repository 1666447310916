import {
  DocumentNode,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import { cloneDeep } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import {
  DynamicFormInputType,
  IFormMultipleSelectDynamicProps,
  IFormSelectDynamicProps,
} from "../../DynamicForm";
import DynamicForm from "../../DynamicForm/DynamicForm";
import Loader from "../../components/Loader";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import { EnhancedButtonStatus } from "../../components/common/EnhancedButton";
import GenericDrawer from "../../components/common/generic-drawer/GenericDrawer";
import { DEFAULT_ERROR_TEXT } from "../../constants";
import { normaliseDynamicValues } from "../../utils/dynamic-utils";
import { dateStringToISOString } from "../../utils/formatting-utils";
import { getError } from "../../utils/graph-utils";
import { isEmpty } from "../../utils/validationUtils";
import { HEADQUARTER, inputs } from "./content";
import {
  createAgent,
  getAgentInfo,
  getAgentLookups,
  getAgentPlansLookups,
  getAgentProcuctsLookups,
  updateAgent,
} from "./queries";
import {
  lookupToList,
  graphqlEntityToAgentInfo,
  toAgenciesTypes,
} from "./utils";

const AgentDrawer: React.FC<IAgentDrawerProps> = ({
  agentId,
  open,
  onSuccess,
  onClose,
}) => {
  const agentLookupResults = useQuery(getAgentLookups());
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();
  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);

  let agentInfoResult: any;
  if (agentId) {
    agentInfoResult = useQuery(getAgentInfo(), {
      variables: { id: agentId },
    });
  }

  const [agentAction] = useMutation(
    agentId ? updateAgent() : (createAgent() as DocumentNode)
  );

  const [agentProductsQuery] = useLazyQuery(getAgentProcuctsLookups());
  const [agentPlansQuery] = useLazyQuery(getAgentPlansLookups());

  const lovsDependecies = useRef<{
    products: Record<string, Record<string, string>>;
    eligiblePlans: Record<string, Record<string, string>>;
  }>({
    products: {},
    eligiblePlans: {},
  });

  const agenciesType = useRef<Record<string, string>>({});

  const getProductsLovs = (
    formInputs: typeof inputsForm,
    linesOfBusiness: string[]
  ) => {
    const newSelectOptions = linesOfBusiness
      .map((lineOfBusiness) => lovsDependecies.current.products[lineOfBusiness])
      .reduce((acc, curr) => ({ ...acc, ...curr }), {});
    let newFormInputs = cloneDeep(formInputs);
    (newFormInputs.products as IFormMultipleSelectDynamicProps).selectOptions =
      newSelectOptions;

    const newProducts = (
      newFormInputs.products as IFormMultipleSelectDynamicProps
    ).value.filter((val: string) =>
      Object.keys(newSelectOptions).includes(val)
    );

    (newFormInputs.products as IFormMultipleSelectDynamicProps).value =
      newProducts;

    const productCodes = Object.keys(newSelectOptions);
    setInputsForm(newFormInputs);

    updatePlansLovs(newFormInputs, productCodes);

    return newFormInputs;
  };

  const updatePlansLovs = (
    formInputs: typeof inputsForm,
    products: string[]
  ) => {
    const newSelectOptions = products
      .map((product) => lovsDependecies.current.eligiblePlans[product] || {})
      .reduce((acc, curr) => ({ ...acc, ...curr }), {});

    let newFormInputs = formInputs;

    const eligiblePlansInput =
      newFormInputs.eligiblePlans as IFormMultipleSelectDynamicProps;
    eligiblePlansInput.selectOptions = newSelectOptions;

    if (Object.keys(newSelectOptions).length === 0) {
      formInputs.eligiblePlans.value = [];
      eligiblePlansInput.value = [];
      eligiblePlansInput.required = false;
    } else {
      eligiblePlansInput.value = eligiblePlansInput.value.filter(
        (val: string) => Object.keys(newSelectOptions).includes(val)
      );
      eligiblePlansInput.required = true;
    }

    setInputsForm(newFormInputs);
    return newFormInputs;
  };

  const getPlansLovs = (
    formInputs: typeof inputsForm,
    products: string[],
    allValues?: Record<string, string>
  ) => {
    const newSelectOptions = products
      .map((val) => lovsDependecies.current.eligiblePlans[val])
      .reduce((acc, curr) => ({ ...acc, ...curr }), {});
    const newFormInputs = cloneDeep(formInputs);
    (
      newFormInputs.eligiblePlans as IFormMultipleSelectDynamicProps
    ).selectOptions = newSelectOptions;

    (newFormInputs.eligiblePlans as IFormMultipleSelectDynamicProps).value = (
      newFormInputs.eligiblePlans as IFormMultipleSelectDynamicProps
    ).value.filter((val: string) =>
      Object.keys(newSelectOptions).includes(val)
    );

    if (Object.keys(newSelectOptions).length === 0) {
      (
        newFormInputs.eligiblePlans as IFormMultipleSelectDynamicProps
      ).required = false;
    } else {
      (
        newFormInputs.eligiblePlans as IFormMultipleSelectDynamicProps
      ).required = true;
    }

    return newFormInputs;
  };
  let updatedInputs: Record<string, DynamicFormInputType> = inputs;
  const initialize = async () => {
    try {
      updatedInputs = cloneDeep(inputsForm);

      if (agentId) {
        delete updatedInputs["agentPassword"];
      }

      if (agentLookupResults.data) {
        const newAgentLookups = lookupToList(agentLookupResults.data);
        agenciesType.current = toAgenciesTypes(
          agentLookupResults.data.Insurance.queries.allAgencies
        );

        (updatedInputs.agentType as IFormSelectDynamicProps).selectOptions =
          newAgentLookups["Insurance_AgentTypes"];

        (updatedInputs.agentStatus as IFormSelectDynamicProps).selectOptions =
          newAgentLookups["Insurance_AgentStatuses"];

        (updatedInputs.agencyName as IFormSelectDynamicProps).selectOptions =
          newAgentLookups["agencies"];
        (updatedInputs.agencyName as IFormSelectDynamicProps).onSelect = (
          option
        ) => {
          handleAgencyNameSelect(option);
        };
        
        (
          updatedInputs.agentAccess as IFormMultipleSelectDynamicProps
        ).selectOptions = newAgentLookups["Insurance_AgentAccesses"];

        const newAgentsLinesOfBusiness =
          agentLookupResults.data?.Insurance?.lookups?.linesOfBusiness?.reduce(
            (
              acc: Record<string, string>,
              curr: {
                Code: string;
                Title: string;
              }
            ) => ({
              ...acc,
              [curr.Code]: curr.Title,
            }),
            {}
          );

        (
          updatedInputs.linesOfBusiness as IFormSelectDynamicProps
        ).selectOptions = newAgentsLinesOfBusiness;

        let agentProductsPromises: Promise<[string, Record<string, string>]>[] =
          [];

        Object.keys(newAgentsLinesOfBusiness).forEach((lineOfBusiness) => {
          agentProductsPromises.push(
            agentProductsQuery({
              variables: {
                lineOfBusiness,
              },
            }).then((res) => {
              const lineOfBusinessProducts =
                res.data?.Insurance?.lookups?.products?.reduce(
                  (
                    acc: Record<string, string>,
                    curr: {
                      Code: string;
                      Title: string;
                    }
                  ) => ({
                    ...acc,
                    [curr.Code]: curr.Title,
                  }),
                  {}
                );

              return [lineOfBusiness, lineOfBusinessProducts];
            })
          );
        });

        let agentPlansPromises: Record<
          string,
          Promise<[string, Record<string, string>]>
        > = {};

        (await Promise.all(agentProductsPromises)).forEach(
          ([lineOfBusiness, prodcuts]) => {
            lovsDependecies.current = {
              ...lovsDependecies.current,
              products: {
                ...lovsDependecies.current.products,
                [lineOfBusiness]: prodcuts,
              },
            };

            Object.keys(prodcuts).forEach((productCode) => {
              agentPlansPromises = {
                ...agentPlansPromises,
                [productCode]: agentPlansQuery({
                  variables: {
                    product: productCode,
                  },
                }).then((res) => {
                  const productPlans =
                    res.data?.Insurance?.lookups?.eligiblePlans?.reduce(
                      (
                        acc: Record<string, string>,
                        curr: {
                          Code: string;
                          Title: string;
                        }
                      ) => ({
                        ...acc,
                        [curr.Code]: curr.Title,
                      }),
                      {}
                    );

                  return [productCode, productPlans];
                }),
              };
            });
          }
        );

        (await Promise.all(Object.values(agentPlansPromises))).forEach(
          ([productCode, plans]) => {
            lovsDependecies.current = {
              ...lovsDependecies.current,
              eligiblePlans: {
                ...lovsDependecies.current.eligiblePlans,
                [productCode]: plans,
              },
            };
          }
        );
      }

      if (agentInfoResult?.data) {
        const agentEntity = graphqlEntityToAgentInfo(agentInfoResult.data);

        if (agentEntity) {
          let newInputs = { ...updatedInputs };

          newInputs.agencyName.value = agentEntity.name;
          newInputs.firstName.value = agentEntity.firstName;
          newInputs.lastName.value = agentEntity.lastName;
          newInputs.email.value = agentEntity.email;
          newInputs.email.disabled = true;

          newInputs.agentType.value = agentEntity.type;
          newInputs.agentAccess.value = agentEntity.access;
          newInputs.agentStatus.value = agentEntity.status;
          newInputs.linesOfBusiness.value = agentEntity.linesOfBusiness;
          newInputs.products.value = agentEntity.products;
          newInputs.eligiblePlans.value = agentEntity.eligiblePlans;
          newInputs.agentAddress.value = agentEntity.address;
          newInputs.ufaCode.value = agentEntity.ufaCode;

          const isHeadquarter = agentEntity.agencyType.every(
            (m) => m === HEADQUARTER
          );
          if (newInputs.agentICCNumber) {
            newInputs.agentICCNumber.value = agentEntity.agentICCNumber || "";
            newInputs.agentICCNumber.hidden = isHeadquarter;
          }
          if (newInputs.agentRegistrationNumber) {
            newInputs.agentRegistrationNumber.value =
              agentEntity.agentRegistrationNumber || "";
            newInputs.agentRegistrationNumber.hidden = isHeadquarter;
          }
          if (newInputs.agentRegistrationExpiryDate) {
            newInputs.agentRegistrationExpiryDate.value =
              agentEntity.agentRegistrationExpiryDate || "";
            newInputs.agentRegistrationExpiryDate.hidden = isHeadquarter;
          }

          if (isHeadquarter) {
            newInputs.agentICCNumber.hidden = isHeadquarter;
            newInputs.agentRegistrationNumber.hidden = isHeadquarter;
            newInputs.agentRegistrationExpiryDate.hidden = isHeadquarter;
            delete newInputs["agentICCNumber"];
            delete newInputs["agentRegistrationNumber"];
            delete newInputs["agentRegistrationExpiryDate"];
          }

          setInputsForm(newInputs);

          newInputs = getProductsLovs(newInputs, agentEntity.linesOfBusiness);
          newInputs = getPlansLovs(newInputs, agentEntity.products);
          setInputsForm(newInputs);

          if (
            !isEmpty(agentEntity.mobileNumber) ||
            agentEntity.mobileNumber === ""
          ) {
            newInputs.mobileNumber.value = agentEntity.mobileNumber;
            setInputsForm(newInputs);
          }

          if (!isEmpty(agentEntity.canViewCommission)) {
            newInputs.canViewCommission.value = agentEntity.canViewCommission;
            setInputsForm(newInputs);
          }
        }
      } else {
        setInputsForm(updatedInputs);
      }
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputs, values);
    setSubmitButtonState("loading");
    setFormDisabled(true);
    try {
      let variables = {
        agencyId: data.agencyName,
        firstName: data.firstName,
        lastName: data.lastName,
        mobileNumber: data.mobileNumber,
        agentType: data.agentType,
        access: data.agentAccess,
        agentStatus: data.agentStatus,
        linesOfBusiness: data.linesOfBusiness,
        products: data.products,
        eligiblePlans: data.eligiblePlans || [],
        agentICCNumber: data?.agentICCNumber,
        agentRegistrationNumber: data?.agentRegistrationNumber,
        agentRegistrationExpiryDate: data.agentRegistrationExpiryDate
          ? dateStringToISOString(data.agentRegistrationExpiryDate)
          : null,
        agentAddress: data.agentAddress,
        ufaCode: data.ufaCode,
        canViewCommission: data.canViewCommission,
      };

      agentAction({
        variables: agentId
          ? {
              ...variables,
              entityId: agentId,
            }
          : {
              ...variables,
              email: data.email,
              password: !isEmpty(data.agentPassword)
                ? data.agentPassword
                : undefined,
            },
        errorPolicy: "all",
      }).then((res) => {
        if (isEmpty(res.errors)) {
          toast.success(
            <ToastSuccessMessage>
              {agentId
                ? "Business User successfully updated"
                : "Business User successfully created"}
            </ToastSuccessMessage>
          );
          setTimeout(() => {
            setSubmitButtonState("success");
            onSuccess();
            onClose();
          }, 500);
        } else {
          setSubmitButtonState(undefined);
          toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
        }
      });
    } catch {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setFormDisabled(false);
    }
  };

  const createInputsFormFromAllValues = (
    currentInputsForm: any,
    allValues: Record<string, any>
  ) => {
    const newInputsForm = {
      agencyName: {
        ...currentInputsForm.agencyName,
        value: allValues["agencyName"],
      },
      firstName: {
        ...currentInputsForm.firstName,
        value: allValues["firstName"],
      },
      lastName: {
        ...currentInputsForm.lastName,
        value: allValues["lastName"],
      },
      email: {
        ...currentInputsForm.email,
        value: allValues["email"],
      },
      agentPassword: {
        ...currentInputsForm.agentPassword,
        value: allValues["agentPassword"],
      },
      ufaCode: {
        ...currentInputsForm.ufaCode,
        value: allValues["ufaCode"],
      },
      mobileNumber: {
        ...currentInputsForm.mobileNumber,
        value: allValues["mobileNumber"],
      },
      agentAddress: {
        ...currentInputsForm.agentAddress,
        value: allValues["agentAddress"],
      },
      agentType: {
        ...currentInputsForm.agentType,
        value: allValues["agentType"],
      },
      agentAccess: {
        ...currentInputsForm.agentAccess,
        value: allValues["agentAccess"],
      },
      linesOfBusiness: {
        ...currentInputsForm.linesOfBusiness,
        value: allValues["linesOfBusiness"],
      },
      products: {
        ...currentInputsForm.products,
        value: allValues["products"],
      },
      eligiblePlans: {
        ...currentInputsForm.eligiblePlans,
        value: allValues["eligiblePlans"],
      },
      agentStatus: {
        ...currentInputsForm.agentStatus,
        value: allValues["agentStatus"],
      },
      agentICCNumber: {
        ...currentInputsForm.agentICCNumber,
        value: allValues["agentICCNumber"],
      },
      agentRegistrationNumber: {
        ...currentInputsForm.agentRegistrationNumber,
        value: allValues["agentRegistrationNumber"],
      },
      agentRegistrationExpiryDate: {
        ...currentInputsForm.agentRegistrationExpiryDate,
        value: allValues["agentRegistrationExpiryDate"],
      },
      canViewCommission: {
        ...currentInputsForm.canViewCommission,
        value: allValues["canViewCommission"],
      },
    };

    return newInputsForm;
  };

  useEffect(() => {
    initialize();
  }, [agentLookupResults.data, agentInfoResult?.data]);

  function handleAgencyNameSelect(option: any) {
    const selectedOptions = Array.isArray(option) ? option : [option];

    let allSameType: boolean = true;
    let firstType;
    for (const id of selectedOptions) {
      const type = agenciesType && agenciesType.current[id];
      if (type) {
        if (!firstType) {
          firstType = type;
        } else if (type !== firstType) {
          allSameType = false;
          break;
        }
      }
    }

    let updatedInputsForm = { ...inputs };

    if (allSameType && firstType !== HEADQUARTER) {
      updatedInputsForm = {
        ...updatedInputsForm,
        agentICCNumber: {
          ...updatedInputsForm.agentICCNumber,
          hidden: false,
        },
        agentRegistrationNumber: {
          ...updatedInputsForm.agentRegistrationNumber,
          hidden: false,
        },
        agentRegistrationExpiryDate: {
          ...updatedInputsForm.agentRegistrationExpiryDate,
          hidden: false,
        },
      };
    } else {
      updatedInputsForm = { ...updatedInputsForm };

      updatedInputsForm.agentICCNumber.hidden = true;
      updatedInputsForm.agentRegistrationNumber.hidden = true;
      updatedInputsForm.agentRegistrationExpiryDate.hidden = true;
      delete updatedInputsForm["agentICCNumber"];
      delete updatedInputsForm["agentRegistrationNumber"];
      delete updatedInputsForm["agentRegistrationExpiryDate"];
    }

    // setInputsForm(updatedInputsForm);
  }

  return (
    <GenericDrawer
      title={agentId ? "Edit Business User" : "New Business User"}
      onClose={() => onClose()}
      isOpen={open}
    >
      {(agentLookupResults.loading || agentInfoResult?.loading) && open ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={"Submit"}
            submitButtonState={submitButtonState}
            disableForm={formDisabled}
            title="Information"
            onChange={(
              fieldName: string,
              value: string | string[],
              allValues
            ) => {
              if (fieldName === "linesOfBusiness") {
                let newFormInputs = getProductsLovs(
                  inputsForm,
                  Array.isArray(value) ? value : [value]
                );

                // Determine related products based on the selected lines of business
                const relatedProducts = Object.keys(
                  lovsDependecies.current.products
                )
                  .filter((lineOfBusiness) => value.includes(lineOfBusiness))
                  .flatMap((lineOfBusiness) =>
                    Object.keys(
                      lovsDependecies.current.products[lineOfBusiness]
                    )
                  );

                // Update selected products to include only those related to the selected lines of business
                newFormInputs.products.value =
                  newFormInputs.products.value.filter((product: string) =>
                    relatedProducts.includes(product)
                  );

                setInputsForm((currentInputsForm) => ({
                  ...createInputsFormFromAllValues(
                    currentInputsForm,
                    allValues
                  ),
                  products: newFormInputs.products,
                }));
              }

              if (fieldName === "products") {
                const currentProducts = Array.isArray(value) ? value : [value];

                let newFormInputs = getPlansLovs(inputsForm, currentProducts);

                // Get all plans related to currently selected products
                const selectedProductPlans = currentProducts.flatMap(
                  (product) =>
                    Object.keys(
                      lovsDependecies.current.eligiblePlans[product] || {}
                    )
                );

                // Filter plans to only keep those related to selected products
                newFormInputs.eligiblePlans.value =
                  newFormInputs.eligiblePlans.value.filter((plan: string) =>
                    selectedProductPlans.includes(plan)
                  );

                setInputsForm((currentInputsForm) => ({
                  ...createInputsFormFromAllValues(
                    currentInputsForm,
                    allValues
                  ),
                  eligiblePlans: newFormInputs.eligiblePlans,
                }));
              }
            }}
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default AgentDrawer;
