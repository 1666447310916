import { FormInputTypes } from '../../DynamicForm';
import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
} from '../../components/enhanced-table';
import { IListingFilterWidgetSection } from '../../components/widgets/custom-listing-filter';
import { IAbstractRecord } from '../../models';

export const headers: Record<string, EnhancedTableHeader> = {
  line: {
    name: 'line',
    title: 'Line Name',
    type: EnhancedTableHeaderType.Clickable,
    callback: () => {},
  },
  clauseName: {
    name: 'clauseName',
    title: 'Clause Name',
    type: EnhancedTableHeaderType.Clickable,
    callback: () => {},
  },
  externalCode: {
    name: 'externalCode',
    title: 'External Code',
    type: EnhancedTableHeaderType.Text,
  },
  description: {
    name: "description",
    title: "Description",
    type: EnhancedTableHeaderType.Text,
  },
  status: {
    name: "status",
    title: "Status",
    type: EnhancedTableHeaderType.Text,
  },
  createdOn: {
    name: 'createdOn',
    title: 'Created On',
    type: EnhancedTableHeaderType.Date,
  },
  modifiedOn: {
    name: 'modifiedOn',
    title: 'Modified On',
    type: EnhancedTableHeaderType.Date,
  },
};

export function filterSectionsContent(
  lovs: Record<string, Record<string, string>>,
  filterValues: IAbstractRecord,
): IListingFilterWidgetSection[] {
  const newFilterSections: IListingFilterWidgetSection[] = [
    {
      title: 'Filters',
      inputs: {
        line: {
          name: 'line',
          title: 'Line',
          type: FormInputTypes.chips,
          value: filterValues?.line || [],
          placeholder: 'Line',
          triggerUpdate: true,
          selectOptions: { ...lovs.line },
        },
        status: {
          name: 'status',
          title: 'Status',
          type: FormInputTypes.chips,
          value: filterValues?.status || [],
          placeholder: 'Status',
          triggerUpdate: true,
          selectOptions: { ...lovs.status },
        },
        createdOn: {
          name: 'createdOn',
          title: 'Created On',
          type: FormInputTypes.daterange,
          value: filterValues.createdOn || [],
          placeholder: 'Created On',
          triggerUpdate: true,
        },
      },
    },
  ];
  return newFilterSections;
}
