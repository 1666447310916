import { FormInputTypes } from '../../DynamicForm';
import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
  IEnhancedTableMenuItem,
} from '../../components/enhanced-table';
import { IListingFilterWidgetSection } from '../../components/widgets/custom-listing-filter';
import { IAbstractRecord } from '../../models';

export const headers: Record<string, EnhancedTableHeader> = {
  planName: {
    name: 'planName',
    title: 'Plan Name',
    type: EnhancedTableHeaderType.Clickable,
    callback: () => {},
  },
  subline: {
    name: 'subline',
    title: 'Subline Name',
    type: EnhancedTableHeaderType.Text,
  },
  line: {
    name: 'line',
    title: 'Line',
    type: EnhancedTableHeaderType.Text,
  },
  externalCode: {
    name: 'externalCode',
    title: 'External Code',
    type: EnhancedTableHeaderType.Text,
  },
  arabicName: {
    name: 'arabicName',
    title: 'Arabic Name',
    type: EnhancedTableHeaderType.Text,
  },
  effectiveFrom: {
    name: 'effectiveFrom',
    title: 'Effective From',
    type: EnhancedTableHeaderType.Date,
  },
  effectiveTo: {
    name: 'effectiveTo',
    title: 'Effective To',
    type: EnhancedTableHeaderType.Date,
  },
  status: {
    name: 'status',
    title: 'Status',
    type: EnhancedTableHeaderType.Text,
  },
  createdOn: {
    name: 'createdOn',
    title: 'Created On',
    type: EnhancedTableHeaderType.Date,
  },
  modifiedOn: {
    name: 'modifiedOn',
    title: 'Modified On',
    type: EnhancedTableHeaderType.Date,
  },
};

export function filterSectionsContent(
  lovs: Record<string, Record<string, string>>,
  filterValues: IAbstractRecord,
): IListingFilterWidgetSection[] {
  const newFilterSections: IListingFilterWidgetSection[] = [
    {
      title: 'Filters',
      inputs: {
        line: {
          name: 'line',
          title: 'Line',
          type: FormInputTypes.chips,
          value: filterValues?.line || [],
          placeholder: 'Line',
          triggerUpdate: true,
          selectOptions: { ...lovs.line },
        },
        subline: {
          name: 'subline',
          title: 'Subline',
          type: FormInputTypes.chips,
          value: filterValues?.subline || [],
          placeholder: 'Subline',
          triggerUpdate: true,
          selectOptions: { ...lovs.subline },
        },
        status: {
          name: 'status',
          title: 'Status',
          type: FormInputTypes.chips,
          value: filterValues?.status || [],
          placeholder: 'Status',
          triggerUpdate: true,
          selectOptions: { ...lovs.status },
        },
        createdOn: {
          name: 'createdOn',
          title: 'Created On',
          type: FormInputTypes.daterange,
          value: filterValues.createdOn || [],
          placeholder: 'Created On',
          triggerUpdate: true,
        },
      },
    },
  ];
  return newFilterSections;
}

export const actions: IEnhancedTableMenuItem[] = [
  {
    title: '+ New',
    onClick: () => {},
    isEntity: false,
    isBulk: false,
    iconUrl: '',
  },
];