import React, { useEffect, useState } from "react";
import GenericDrawer from "../../components/common/generic-drawer/GenericDrawer";
import DynamicForm from "../../DynamicForm/DynamicForm";
import {
  DynamicFormInputType,
  IFormChipsDynamicProps,
} from "../../DynamicForm";
import { toast } from "react-toastify";
import { EnhancedButtonStatus } from "../../components/common/EnhancedButton";
import { normaliseDynamicValues } from "../../utils/dynamic-utils";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { inputs } from "./content";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  createAssignedBPPlan,
  getBPPlanListByAssignedLines,
  getBusinessPartnerListWithoutPagination,
} from "./queries";
import { cloneDeep } from "lodash";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import { DEFAULT_ERROR_TEXT } from "../../constants";
import { isEmpty } from "../../utils/validationUtils";
import { getError } from "../../utils/graph-utils";
import { extractBusinessPartners, extractLines, extractPlans } from "./utils";
import { getBusinessPartnerLinesList } from "../salesforce-business-partner-details-page/queries";
import Loader from "../../components/Loader";

const SalesforceAssignedBPPlanDrawer: React.FC<
  ISalesforceAssignedBPPlanDrawerProps
> = ({ open, onSuccess, onClose, businessUserId }) => {
  const [assignedBPPlanAction] = useMutation(createAssignedBPPlan());
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();
  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);
  const [businessPartnerId, setBunisessPartnerId] = useState<string>("");
  const [assignedLineId, setAssignedLineId] = useState<string>("");

  let updatedInputs: Record<string, DynamicFormInputType> = inputs;

  const getBusinessPartnersResults = useQuery(
    getBusinessPartnerListWithoutPagination(),
    {
      variables: {
        businessPartnerStatus: ["ACTIVE"],
      },
    }
  );
  const [getAssignedLineListLazy] = useLazyQuery(getBusinessPartnerLinesList());
  const [getBPPlanListByAssignedLinesLazy] = useLazyQuery(
    getBPPlanListByAssignedLines()
  );

  useEffect(() => {
    initialize();

    if (getBusinessPartnersResults?.data) {
      const extractedFetchedBusinessPartners = extractBusinessPartners(
        getBusinessPartnersResults.data
      );

      setInputsForm((currentInputsForm) => {
        const updatedForm = {
          ...currentInputsForm,
        };

        (updatedForm.businessPartner as IFormChipsDynamicProps).selectOptions =
          {
            ...extractedFetchedBusinessPartners,
          };

        return updatedForm;
      });
    } else {
      (updatedInputs.businessPartner as IFormChipsDynamicProps).selectOptions =
        {};
    }
  }, [getBusinessPartnersResults.data]);

  useEffect(() => {
    if (!isEmpty(businessPartnerId)) {
      handleBusinessPartnerSelection(businessPartnerId);
    }
  }, [businessPartnerId]);

  useEffect(() => {
    if (!isEmpty(assignedLineId) && !isEmpty(businessPartnerId)) {
      handleLineSelection(assignedLineId, businessPartnerId);
    }
  }, [assignedLineId, businessPartnerId]);

  function handleBusinessPartnerSelection(selectedOption: any) {
    if (!isEmpty(businessPartnerId) || !isEmpty(selectedOption)) {
      getAssignedLineListLazy({
        variables: {
          currentPage: 1,
          currentPageSize: 10,
          selectedBusinessPartnerID: selectedOption,
        },
        errorPolicy: "all",
      }).then((response) => {
        if (response?.data) {
          const extractedLines = extractLines(response.data);

          setInputsForm((currentInputsForm) => {
            const updatedForm = {
              ...currentInputsForm,
            };

            (updatedForm.line as IFormChipsDynamicProps).selectOptions = {
              ...extractedLines,
            };

            return updatedForm;
          });
        } else {
          (updatedInputs.line as IFormChipsDynamicProps).selectOptions = {};
        }
      });
    }
  }

  function handleLineSelection(selectedOption: any, businessPartner: any) {
    if (!isEmpty(assignedLineId) || !isEmpty(selectedOption)) {
      getBPPlanListByAssignedLinesLazy({
        variables: {
          selectedBusienssUserID: businessUserId,
          selectedBusinessPartnerID: businessPartner,
          selectedLineID: selectedOption,
        },
        errorPolicy: "all",
      }).then((response) => {
        if (response?.data) {
          const extractedLines = extractPlans(response.data);

          setInputsForm((currentInputsForm) => {
            const updatedForm = {
              ...currentInputsForm,
            };

            (updatedForm.plan as IFormChipsDynamicProps).selectOptions = {
              ...extractedLines,
            };

            return updatedForm;
          });
        } else {
          (updatedInputs.plan as IFormChipsDynamicProps).selectOptions = {};
        }
      });
    }
  }

  const initialize = () => {
    try {
      const updatedInputs = cloneDeep(inputsForm);

      (updatedInputs.businessPartner as IFormChipsDynamicProps).onChange = (
        option
      ) => {
        setBunisessPartnerId(option);
        handleBusinessPartnerSelection(option);
      };

      (updatedInputs.line as IFormChipsDynamicProps).onSelect = (option) => {
        setAssignedLineId(option);
      };

      setInputsForm(updatedInputs);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputs, values);

    setFormDisabled(true);
    setSubmitButtonState("loading");
    try {
      let variables = {
        selectedBusinessPartnerID: data.businessPartner || null,
        selectedBusinessPartnerLineID: data.line || null,
        selectedBusinessPartnerPlanIDs: data.plan || [],
        selectedBusinessUserID: businessUserId,
      };

      assignedBPPlanAction({
        variables: variables,
        errorPolicy: "all",
      }).then((res) => {
        if (isEmpty(res.errors)) {
          toast.success(
            <ToastSuccessMessage>
              {"Business Partner and Plan(s) successfully assigned."}
            </ToastSuccessMessage>
          );
          setTimeout(() => {
            setSubmitButtonState("success");
            onSuccess();
            onClose();
          }, 500);
        } else {
          setSubmitButtonState(undefined);
          toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
        }
      });
    } catch {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setFormDisabled(false);
    }
  };

  return (
    <GenericDrawer
      title={"Assign Business Partner and Plan"}
      onClose={() => onClose()}
      isOpen={open}
    >
      {getBusinessPartnersResults.loading && open ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={"Submit"}
            submitButtonState={submitButtonState}
            disableForm={formDisabled}
            title="Information"
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default SalesforceAssignedBPPlanDrawer;
