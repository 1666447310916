export interface IAppVisibilityDetails {
  isPlanConfigurationEnabled: boolean;
  isSalesForceAppEnabled: boolean;
}

export enum AppSettingsActionTypes {
  LOAD_APPSETTINGS_SUCCESS = 'LOAD_APPSETTINGS_SUCCESS',
}

export interface LoadAppVisibilitySuccessAction {
  type: typeof AppSettingsActionTypes.LOAD_APPSETTINGS_SUCCESS;
  app: IAppVisibilityDetails;
}