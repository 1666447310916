import {
  enumListAsRecordObject,
  lookupListAsRecordObject,
} from "../../utils/graph-utils";

export function LookupToList(data: any) {
  const planDetailsEnums: Record<string, Record<string, string>> = {};

  planDetailsEnums["planCurrency"] = lookupListAsRecordObject(
    data?.Core?.lookups?.currencies
  );

  planDetailsEnums["acalCategory"] = lookupListAsRecordObject(
    data?.Core?.lookups?.acalCategories
  );

  planDetailsEnums["sOSService"] = enumListAsRecordObject(
    data?.Core_SOSServiceOptions?.enumValues as {
      __typename: string;
      name: string;
    }[]
  );

  return planDetailsEnums;
}
