import { enumListAsRecordObject } from "../../utils/graph-utils";

export function VignetteLookupsToList(data: any) {
  const vigentteLookups: Record<string, Record<string, string>> = {};

  vigentteLookups[data.SalesforceManagement_VignetteRangeStatuses.name] =
    enumListAsRecordObject(
      data.SalesforceManagement_VignetteRangeStatuses.enumValues
    );

  return vigentteLookups;
}

export function graphqlToVignetteRangeInfo(data: any): IVignetteRangeInfo {
  const businessPartnerVignetteRange =
    data?.SalesforceManagement?.entities?.businessPartnerVignetteRange?.views
      ?.SalesforceManagement_all?.properties;

  return {
    rangeCode: businessPartnerVignetteRange?.RangeCode,
    rangeYear: businessPartnerVignetteRange?.RangeYear,
    rangeFrom: businessPartnerVignetteRange?.RangeFrom,
    rangeTo: businessPartnerVignetteRange?.RangeTo,
    rangeStatus: businessPartnerVignetteRange?.RangeStatus,
  };
}
