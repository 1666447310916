import { gql } from "@apollo/client";

export function listQuery() {
  return gql`
    query LIST_BUSINESSUSERSAPPLICATIONS(
      $currentPage: Int!
      $currentPageSize: Int!
      $selectedTypes: [String]
      $businessUserStatus: [SalesforceManagement_BusinessUserStatuses]
      $selectedRelatedCompany: [String]
      $selectedAccesses: [String]
      $createdDateFrom: Date
      $createdDateTo: Date
    ) {
      SalesforceManagement {
        queries {
          getBusinessUserList(
            pagination: { pageNumber: $currentPage, pageSize: $currentPageSize }
            selectedRelatedCompany: $selectedRelatedCompany
            selectedTypes: $selectedTypes
            selectedAccesses: $selectedAccesses
            SelectedStatuses: $businessUserStatus
            FromCreatedOn: $createdDateFrom
            ToCreatedOn: $createdDateTo
          ) {
            paging {
              pageSize
              pageNumber
              totalCount
            }
            items {
              salesforceManagement_BusinessUser_Id
              salesforceManagement_BusinessUser_Code
              person_FullName
              salesforceManagement_BusinessUser_RelatedCompany {
                Id
                Title
              }
              salesforceManagement_BusinessUser_Type {
                Id
                Title
              }
              salesforceManagement_BusinessUser_Access {
                Id
                Title
              }
              person_Email
              person_PhoneDetails {
                PhoneNumber
                PhoneType
              }
              salesforceManagement_BusinessUser_Status
              salesforceManagement_BusinessUser_createdOn
              salesforceManagement_BusinessUser_createdBy
              salesforceManagement_BusinessUser_modifiedOn
              salesforceManagement_BusinessUser_modifiedBy
              person_Id
              person_PersonType
              salesforceManagement_BusinessUser_PersonID {
                id
                views {
                  SalesforceManagement_all {
                    properties {
                      FullName
                      Email
                      PhoneDetails {
                        PhoneNumber
                      }
                    }
                  }
                }
              }
            }
          }
        }
        lookups {
          businessUserTypes {
            Code
            Title
            Id
          }
          businessUserAccesses {
            Code
            Title
            Id
          }
          relatedCompanies {
            Code
            Title
            Id
          }
        }
      }
      SalesforceManagement_BusinessUserStatuses: __type(
        name: "SalesforceManagement_BusinessUserStatuses"
      ) {
        name
        enumValues {
          name
        }
      }
    }
  `;
}
