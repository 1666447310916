import { DynamicFormInputType, FormInputTypes } from "../../DynamicForm";
import { isEmpty } from "../../utils/validationUtils";

export const initialValues = {
  planCurrency: "",
  policyCover: "",
  nbOfAdherent: "",
  class: "",
  ageFrom: "",
  ageTo: "",
  coNssf: "",
  netPremium: "",
  tpaFeesPercentage: "",
  minTPAFees: "",
  maxTPAFees: "",
};

export const inputs: Record<string, DynamicFormInputType> = {
  planCurrency: {
    name: "planCurrency",
    title: "Plan Currency",
    type: FormInputTypes.chips,
    placeholder: "Plan Currency",
    value: !isEmpty(initialValues.planCurrency)
      ? initialValues.planCurrency
      : "",
    multiple: false,
    disabled: true,
    required: true,
  },
  policyCover: {
    name: "policyCover",
    title: "Policy Cover",
    type: FormInputTypes.chips,
    placeholder: "Type to search for Policy Cover",
    value: !isEmpty(initialValues.policyCover)
      ? [initialValues.policyCover]
      : [],
    multiple: false,
    disabled: false,
    required: true,
    hasPagination: true,
    itemsPerPage: 15,
  },
  nbOfAdherent: {
    name: "nbOfAdherent",
    title: "Nb Of Adherent",
    placeholder: "Nb Of Adherent",
    type: FormInputTypes.number,
    value: initialValues.nbOfAdherent,
    required: true,
    minNumber: 0,
  },
  class: {
    name: "class",
    title: "Class",
    type: FormInputTypes.chips,
    placeholder: "Class",
    value: !isEmpty(initialValues.class) ? initialValues.class : "",
    multiple: false,
    disabled: false,
    required: true,
  },
  ageFrom: {
    name: "ageFrom",
    title: "Age From",
    placeholder: "Age From",
    type: FormInputTypes.number,
    value: initialValues.ageFrom,
    required: true,
    minNumber: 0,
    maxNumber: 50,
  },
  ageTo: {
    name: "ageTo",
    title: "Age To",
    placeholder: "Age To",
    type: FormInputTypes.number,
    value: initialValues.ageTo,
    required: true,
    maxNumber: 50,
  },
  coNssf: {
    name: "coNssf",
    title: "Co-Nssf",
    type: FormInputTypes.chips,
    placeholder: "Co-Nssf",
    value: !isEmpty(initialValues.coNssf) ? initialValues.coNssf : "",
    multiple: false,
    disabled: false,
    required: true,
  },
  netPremium: {
    name: "netPremium",
    title: "Net Premium",
    placeholder: "Net Premium",
    type: FormInputTypes.formattedNumber,
    value: initialValues.netPremium,
    required: true,
    minValue: 0,
  },
  tpaFeesPercentage: {
    name: "tpaFeesPercentage",
    title: "TPA Fees Percentage",
    placeholder: "TPA Fees Percentage",
    type: FormInputTypes.percentage,
    value: initialValues.tpaFeesPercentage,
    required: true,
  },
  minTPAFees: {
    name: "minTPAFees",
    title: "Min TPA Fees",
    placeholder: "Min TPA Fees",
    type: FormInputTypes.formattedNumber,
    value: initialValues.minTPAFees,
    required: true,
    minValue: 0,
  },
  maxTPAFees: {
    name: "maxTPAFees",
    title: "Max TPA Fees",
    placeholder: "Max TPA Fees",
    type: FormInputTypes.formattedNumber,
    value: initialValues.maxTPAFees,
    required: true,
  },
};
