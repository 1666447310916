import React from 'react';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';
import { IEnhancedCommonProps } from '..';
import { Paper } from '@mui/material';

interface IWidgetPaperProps extends IEnhancedCommonProps {
  classes?: { paper?: string };
  children?: React.ReactNode;
}

const useStyles = makeStyles()(() => ({
  paper: {
    padding: '15px 25px',
    boxSizing: 'border-box',
    width: 'calc(100% - 6px)',
    boxShadow: '0px 0px 6px #C7C7C7',
    margin: '3px auto',
  },
}));

const WidgetPaper: React.FC<IWidgetPaperProps> = ({
  classes = {},
  className,
  style,
  children,
}) => {
  const { classes: paperClasses } = useStyles();

  return (
    <Paper
      className={clsx(paperClasses.paper, classes.paper, className)}
      style={style}
    >
      {children}
    </Paper>
  );
};

export default WidgetPaper;
