import { IAbstractRecord } from "../../models";
import { IListingData } from "../../models/listing";
import { capitalizeFirstLetter, removeHTMLTagesFromDescription } from "../../utils/formatting-utils";
import { enumListAsRecordObject } from "../../utils/graph-utils";

export function mapToListingData(data: any): IListingData<any> {
  const list: IAbstractRecord = {};

  data.PlanConfigManagement.queries.getClauseList.items.forEach(
    (obj: IGenericClausesTableData, i: number) => {
      const lineName =
        obj.planConfigManagement_Clause_LineID?.views?.PlanConfigManagement_all
          ?.properties?.Name;
      const lineId = obj.planConfigManagement_Clause_LineID?.id;
      list[i] = {
        id: obj.planConfigManagement_Clause_Id,
        clauseName: obj.planConfigManagement_Clause_Name,
        line: lineName,
        lineId: lineId,
        externalCode: obj.planConfigManagement_Clause_ExternalCode,
        createdOn: obj.planConfigManagement_Clause_createdOn,
        modifiedOn: obj.planConfigManagement_Clause_modifiedOn,
        description: removeHTMLTagesFromDescription(
          obj.planConfigManagement_Clause_Description
        ),
        status: capitalizeFirstLetter(obj.planConfigManagement_Clause_Status),
      };
    }
  );

  return {
    pagedItems: list,
    pageSize: data.PlanConfigManagement.queries.getClauseList?.paging?.pageSize,
    pageNumber:
      data.PlanConfigManagement.queries.getClauseList?.paging?.pageNumber,
    totalCount:
      data.PlanConfigManagement.queries.getClauseList?.paging?.totalCount,
  };
}

export function dataToLovs(data: any): Record<string, Record<string, string>> {
  let lovs: Record<string, Record<string, string>> = {
    line: {},
    status: {},
  };

  data?.PlanConfigManagement?.queries?.allLines?.forEach(
    (obj: {
      planConfigManagement_Line_Id: string;
      planConfigManagement_Line_Name: string;
    }) => {
      lovs.line[obj.planConfigManagement_Line_Id] =
        obj.planConfigManagement_Line_Name;
    }
  );

  lovs.status = enumListAsRecordObject(
    data?.PlanConfigManagement_ClauseStatuses?.enumValues
  );

  return lovs;
}
