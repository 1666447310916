import { DynamicFormInputType, FormInputTypes } from '../../DynamicForm';
import { isEmpty } from '../../utils/validationUtils';

export const initialValues = {
  basicCurrency: '',
  secondaryCurrency: '',
};

export const inputs: Record<string, DynamicFormInputType> = {
  basicCurrency: {
    name: 'basicCurrency',
    title: 'Basic Currency',
    type: FormInputTypes.chips,
    placeholder: 'Basic Currency (Local Currency)',
    value: !isEmpty(initialValues.basicCurrency)
      ? initialValues.basicCurrency
      : [],
    required: true,
    disabled: false,
    hasTooltip: true,
    tooltipText: 'Local Currency',
    multiple: false,
  },
  secondaryCurrency: {
    name: 'secondaryCurrency',
    title: 'Secondary Currency',
    type: FormInputTypes.chips,
    placeholder: 'Secondary Currency (Foreign Currency)',
    value: !isEmpty(initialValues.secondaryCurrency)
      ? initialValues.secondaryCurrency
      : [],
    required: true,
    disabled: false,
    hasTooltip: true,
    tooltipText: 'Foreign Currency',
    multiple: false,
  },
};
