import { makeStyles } from "tss-react/mui";
import React, { useRef, useEffect, useState } from "react";
import clsx from "clsx";
import { CircularProgress } from "@mui/material";
import EnhancedInputsWrapper from "./EnhancedInputsWrapper";
import { IEnhancedFormInputBaseProps } from ".";
import { IAbstractRecord } from "../../models";
import { MAIN_ONE_THEME, contentFontFamilyRegular } from "../../constants";
import PhoneInput, {
  Country,
  isValidPhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { isEmpty } from "../../utils/validationUtils";
import { getCountries } from "react-phone-number-input";
import Input from "react-phone-number-input/input";

export interface IEnhancedInternationalPhoneInputProps
  extends IEnhancedFormInputBaseProps {
  classes?: { input?: string; wrapper?: string };
  value: string;
  loader?: boolean;
  pattern?: string;
  inline?: boolean;
  onBlur: (value: string, name: string, data?: IAbstractRecord) => void;
  onChange: (value: string, name: string, data?: IAbstractRecord) => void;
  onFocus?: (event: React.ChangeEvent<string>) => void;
  onKeyPress?: (event: React.KeyboardEvent<string>) => void;
  countriesToShow?: string[];
  disableDropDown?: boolean;
  editCountryCode?: boolean;
  preferredCountries?: string[];
  defaultCountry?: string;
  allowChangingCountry?: boolean;
  customFormat?: Record<string, string>;
}

const useStyles = makeStyles()(() => ({
  input: {
    lineHeight: "15px",
    height: 35,
    width: "100% !important",
    padding: "0 15px",
    margin: "0 auto",
    backgroundColor: `${MAIN_ONE_THEME.palette.secondary4.main} !important`,
    border: `1px solid ${MAIN_ONE_THEME.palette.secondary3.main} !important`,
    boxSizing: "border-box",
    borderRadius: "5px",
    "&:disabled": {
      cursor: "not-allowed",
      opacity: 0.4,
      color: MAIN_ONE_THEME.palette.primary2.main,
      fontFamily: `${contentFontFamilyRegular} !important`,
      fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px !important`,
    },
    "&::placeholder": {
      color: "rgba(0, 0, 0, 0.20)",
      fontStyle: "italic",
      fontFamily: `${contentFontFamilyRegular} !important`,
      fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px !important`,
    },
    " & .PhoneInputInput": {
      border: "none",
      backgroundColor: MAIN_ONE_THEME.palette.secondary4.main,
      fontFamily: `${contentFontFamilyRegular} !important`,
      fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px !important`,
    },
    " & .PhoneInputCountrySelect": {
      border: "none",
      backgroundColor: MAIN_ONE_THEME.palette.secondary4.main,
      fontFamily: `${contentFontFamilyRegular} !important`,
      fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px !important`,
    },
    fontFamily: `${contentFontFamilyRegular} !important`,
    fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px !important`,
  },
  loader: {
    position: "absolute",
    top: "calc(50% - 10px)",
    right: "3%",
    opacity: 1,
    height: "25px",
    width: "25px",
  },
  // TODO make style common using JSS
  materialInput: {
    backgroundColor: "unset !important",
    border: "none !important",
    borderBottom: `1px solid ${MAIN_ONE_THEME.palette.secondary3.main} !important`,
    color: MAIN_ONE_THEME.palette.primary2.main,
    margin: "0 auto",
    borderRadius: "0 !important",
    padding: "10px 15px 4px 4px",
    fontFamily: `${contentFontFamilyRegular} !important`,
    fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px !important`,
    lineHeight: "15px",
    " & .PhoneInputInput": {
      border: "none",
      backgroundColor: "unset",
    },
  },
  inputError: {
    borderColor: `${MAIN_ONE_THEME.palette.error.main} !important`,
    outlineColor: `${MAIN_ONE_THEME.palette.error.main} !important`,
  },
  flagButtonMaterial: {
    border: "none !important",
    backgroundColor: "unset !important",
    "& .selected-flag .flag": {
      marginTop: "0",
    },
  },
  dropDownStyle: {
    height: "97px",
    maxHeight: "150px !important",
    border: `2px solid ${MAIN_ONE_THEME.palette.secondary3.main}`,
    "& li": {
      padding: "4px 9px !important",
    },
  },
}));

const EnhancedInternationalPhoneInput: React.FC<
  IEnhancedInternationalPhoneInputProps
> = ({
  classes = {},
  className,
  error,
  name,
  style,
  title,
  description,
  value,
  placeholder,
  disabled,
  material,
  onChange,
  onBlur,
  loader,
  countriesToShow,
  disableDropDown,
  editCountryCode,
  defaultCountry,
  customFormat,
}) => {
  const { classes: inputClasses } = useStyles();
  const inputRef = useRef(null);
  const updateInput = useRef<boolean>(false);
  const [phone, setPhone] = useState<string>(value);

  useEffect(() => {
    setPhone(value);
  }, [value]);
  
  
  useEffect(() => {
    if (updateInput.current && value && inputRef && inputRef.current) {
      updateInput.current = false;
      setTimeout(() => {
        const input: HTMLInputElement = (inputRef.current as any).tel;
        input.setSelectionRange(value.length, value.length);
      }, 5);
    }
  }, [value]);

  const toCountryCode = (countryCode: string) => {
    if (!isEmpty(countryCode)) {
      return countryCode.toUpperCase() as Country;
    }

    return "LB";
  };

  const renderCountries = () => {
    const availableCountries = getCountries();
    return availableCountries.filter((country) => {
      if (country != "IL") {
        return country;
      }

      return undefined;
    });
  };

  const renderInput = () => {
    if (disableDropDown) {
      return (
        <Input
          international
          country={toCountryCode(defaultCountry)}
          value={phone}
          onChange={(v) => {
            setPhone(v);
            onChange(v?.toString(), name);
          }}
          onBlur={() => {
            onBlur(phone, name);
          }}
          withCountryCallingCode
          className={clsx(
            clsx(inputClasses.input, classes.input, {
              [inputClasses.materialInput]: material,
              [inputClasses.inputError]: error,
            })
          )}
          error={
            phone ? (isValidPhoneNumber(phone) ? undefined : error) : "required"
          }
        />
      );
    }
    return (
      <PhoneInput
        international
        disabled={disabled}
        value={phone}
        placeholder={placeholder}
        onChange={(v) => {
          setPhone(v);
          onChange(v?.toString(), name);
        }}
        onBlur={() => {
          onBlur(phone, name);
        }}
        className={clsx(
          clsx(inputClasses.input, classes.input, {
            [inputClasses.materialInput]: material,
            [inputClasses.inputError]: error,
          })
        )}
        countryCallingCodeEditable={editCountryCode}
        defaultCountry={toCountryCode(defaultCountry)}
        countries={renderCountries()}
        error={
          phone ? (isValidPhoneNumber(phone) ? undefined : error) : "required"
        }
      />
    );
  };

  return (
    <>
      <EnhancedInputsWrapper
        title={title}
        description={description}
        error={error}
        name={name}
        className={clsx(classes.wrapper, className)}
        style={style}
      >
        <div style={{ position: "relative" }}>
          {renderInput()}
          {loader && (
            <CircularProgress
              className={inputClasses.loader}
              size={20}
              thickness={3}
            />
          )}
        </div>
      </EnhancedInputsWrapper>
    </>
  );
};

export default EnhancedInternationalPhoneInput;
