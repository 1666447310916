import { DynamicFormInputType, FormInputTypes } from "../../DynamicForm";
import { isEmpty } from "../../utils/validationUtils";

export const initialValues = {
  vignetteRangeInfo: {
    rangeCode: "",
    rangeYear: "",
    rangeFrom: "",
    rangeTo: "",
    rangeStatus: "ACTIVE",
  },
};

export const inputs: Record<string, DynamicFormInputType> = {
  rangeYear: {
    name: "rangeYear",
    title: "Vignette Range Year",
    type: FormInputTypes.number,
    placeholder: "Vignette Range Year",
    value: initialValues.vignetteRangeInfo.rangeYear,
    required: true,
    material: false,
    hasBetweenValidation: true,
    isYear: true,
    minYear: 1900,
    maxYear: 2100,
  },
  rangeCode: {
    name: "rangeCode",
    title: "Vignette Range Code",
    type: FormInputTypes.text,
    placeholder: "Vignette Range Code",
    value: initialValues.vignetteRangeInfo.rangeCode,
    required: true,
    material: false,
  },
  rangeFrom: {
    name: "rangeFrom",
    title: "Vignette Range From",
    type: FormInputTypes.number,
    placeholder: "Vignette Range From",
    value: initialValues.vignetteRangeInfo.rangeFrom,
    required: true,
    material: false,
  },
  rangeTo: {
    name: "rangeTo",
    title: "Vignette Range To",
    type: FormInputTypes.number,
    placeholder: "Vignette Range To",
    value: initialValues.vignetteRangeInfo.rangeTo,
    required: true,
    material: false,
  },
  rangeStatus: {
    name: "rangeStatus",
    title: "Vignette Range Status",
    type: FormInputTypes.chips,
    placeholder: "Vignette Range Status",
    value: !isEmpty(initialValues.vignetteRangeInfo.rangeStatus)
      ? initialValues.vignetteRangeInfo.rangeStatus
      : [],
    required: true,
    multiple: false,
  },
};
