import React, { useEffect, useState } from 'react';
import GenericDrawer from '../../components/common/generic-drawer/GenericDrawer';
import {
  DynamicFormInputType,
  IDynamicSection,
  IFormDateDynamicProps,
  IFormSelectDynamicProps,
} from '../../DynamicForm';
import { toast } from 'react-toastify';
import { EnhancedButtonStatus } from '../../components/common/EnhancedButton';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { inputs } from './content';
import { useMutation, useQuery } from '@apollo/client';
import Loader from '../../components/Loader';
import { cloneDeep, isEmpty } from 'lodash';
import {
  DEFAULT_ERROR_TEXT,
  MAIN_ONE_THEME,
  SEND_TO_BACKEND_DATE_FORMAT,
} from '../../constants';
import { IExpatPolicyDetailsDrawerProps } from '.';
import SectionDynamicForm from '../../DynamicForm/SectionDynamicForm';
import { getListForm, updateExpatPolicy } from './queries';
import { toLookups } from './utils';
import { getError } from '../../utils/graph-utils';
import { normaliseDynamicValues } from '../../utils/dynamic-utils';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import {
  dateStringToISOString,
  formatDateTime,
} from "../../utils/formatting-utils";
import { useAppSelector } from "../../redux/hooks";
import { AddMonths } from '../../utils/date-utils';

const ExpatPolicyDetailsDrawer: React.FC<IExpatPolicyDetailsDrawerProps> = ({
  policyId,
  open,
  onSuccess,
  onClose,
  policyDetailsInfo,
}) => {
  const user = useAppSelector((state) => state.user);
  const isAdmin = user.userRoles.includes("Insurance-Admin");


  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();
  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);

  const policyDetailsListResults = useQuery(getListForm());

  const [policyAction] = useMutation(updateExpatPolicy());

  const sections: Record<string, IDynamicSection> = {
    section1: {
      title: 'Policy Details',
      inputs: {
        policyNumber: inputsForm.policyNumber,
        isRenewal: inputsForm.isRenewal,
        renewalNumber: inputsForm.renewalNumber,
        policyEffectiveDate: inputsForm.policyEffectiveDate,
        createdOn: inputsForm.createdOn,
        policyExpiryDate: inputsForm.policyExpiryDate,
        product: inputsForm.product,
      },
    },
    section2: {
      specificTitleDesign: () => (
        <>
          Subscriber's Details{' '}
          <span
            style={{
              fontStyle: 'italic',
              fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px`,
            }}
          >
            (Please enter all details in Arabic)
          </span>
        </>
      ),
      hasTitleSpecificDesign: true,
      inputs: {
        title: inputsForm.title,
        firstName: inputsForm.firstName,
        middleName: inputsForm.middleName,
        lastName: inputsForm.lastName,
        email: inputsForm.email,
        phoneType: inputsForm.phoneType,
        mobileNumber: inputsForm.mobileNumber,
        country: inputsForm.country,
        city: inputsForm.city,
        street: inputsForm.street,
        building: inputsForm.building,
      },
    },
    section3: {
      title: 'Insured Details',
      inputs: {
        insuredFirstName: inputsForm.insuredFirstName,
        insuredMiddleName: inputsForm.insuredMiddleName,
        insuredLastName: inputsForm.insuredLastName,
        insuredDateOfBirth: inputsForm.insuredDateOfBirth,
        insuredNationality: inputsForm.insuredNationality,
        insuredGender: inputsForm.insuredGender,
        insuredPassportNumber: inputsForm.insuredPassportNumber,
        insuredOccupation: inputsForm.insuredOccupation,
      },
    },
  };

    let today = new Date();
    let threeMonthsAgo = new Date(today);
    threeMonthsAgo.setMonth(today.getMonth() - 3);
    const userAgentType = user.info.agentType;

  useEffect(() => {
    initialize();
  }, [policyDetailsListResults]);

  const initialize = () => {
    try {
      const updatedInputs = cloneDeep(inputsForm);

      if (policyDetailsListResults.data) {
        const lookupLists = toLookups(policyDetailsListResults.data, userAgentType);

        (updatedInputs.phoneType as IFormSelectDynamicProps).selectOptions =
          lookupLists['phoneType'];

        (updatedInputs.country as IFormSelectDynamicProps).selectOptions =
          lookupLists['countries'];

          (updatedInputs.isRenewal as IFormSelectDynamicProps).selectOptions =
          lookupLists["renewalStatus"];

        (
          updatedInputs.insuredNationality as IFormSelectDynamicProps
        ).selectOptions = lookupLists['nationalities'];

        (updatedInputs.title as IFormSelectDynamicProps).selectOptions =
          lookupLists['title'];

        (updatedInputs.insuredGender as IFormSelectDynamicProps).selectOptions =
          lookupLists['insuredGender'];
          

        (
          updatedInputs.insuredOccupation as IFormSelectDynamicProps
        ).selectOptions = lookupLists['occupations'];

      }

      updatedInputs.insuredGender.disabled = userAgentType !== "INTERNAL";
      updatedInputs.insuredNationality.disabled = userAgentType !== "INTERNAL";
      updatedInputs.insuredDateOfBirth.disabled = userAgentType !== "INTERNAL";

      (updatedInputs.policyEffectiveDate as IFormDateDynamicProps).minDate =
        userAgentType === "INTERNAL" ? threeMonthsAgo : new Date();

      (updatedInputs.policyEffectiveDate as IFormDateDynamicProps).onChange = (
        event
      ) => {
        if(!!event){
          const newDate = new Date(event);
          var date = new Date(
            newDate.getFullYear() + 1,
            newDate.getMonth(),
            newDate.getDate()
          );
          setInputsForm((currentInputsForm) => ({
            ...currentInputsForm,
            policyExpiryDate: {
              ...currentInputsForm.policyExpiryDate,
              value: date?.toISOString(),
            },
          }));
        } else {
          setInputsForm((currentInputsForm) => ({
            ...currentInputsForm,
            policyExpiryDate: {
              ...currentInputsForm.policyExpiryDate,
              value: ""
            },
          }));
        }
      };

      if (policyDetailsInfo) {
        updatedInputs.policyEffectiveDate.value =
          policyDetailsInfo.policyEffectiveDate;
        updatedInputs.createdOn.value = policyDetailsInfo.createdOn;
        updatedInputs.policyExpiryDate.value =
          policyDetailsInfo.policyExpiryDate;
        updatedInputs.product.value = policyDetailsInfo.plan;
        updatedInputs.productCode.value = policyDetailsInfo.productCode;
        updatedInputs.title.value = policyDetailsInfo.title;
        updatedInputs.firstName.value = policyDetailsInfo.firstName;

        updatedInputs.middleName.value = policyDetailsInfo.middleName;
        updatedInputs.lastName.value = policyDetailsInfo.lastName;
        updatedInputs.email.value = policyDetailsInfo.email;
        updatedInputs.phoneType.value = policyDetailsInfo.phoneType;
        updatedInputs.mobileNumber.value = policyDetailsInfo.mobileNumber;
        updatedInputs.country.value = policyDetailsInfo.countryId;
        updatedInputs.city.value = policyDetailsInfo.city;
        updatedInputs.street.value = policyDetailsInfo.street;
        updatedInputs.building.value = policyDetailsInfo.building;
        updatedInputs.isRenewal.value = policyDetailsInfo.isRenewal;


        updatedInputs.isRenewal.value = policyDetailsInfo.isRenewal;;
        updatedInputs.renewalNumber.value = policyDetailsInfo.renewalNumber;

        updatedInputs.renewalNumber.hidden = policyDetailsInfo.isRenewal !== "YES";
        updatedInputs.renewalNumber.required = policyDetailsInfo.isRenewal === "YES";


        updatedInputs.policyNumber.value = policyDetailsInfo.fullPolicyNumber;

        updatedInputs.insuredFirstName.value =
          policyDetailsInfo.insuredFirstName;
        updatedInputs.insuredMiddleName.value =
          policyDetailsInfo.insuredMiddleName;
        updatedInputs.insuredLastName.value = policyDetailsInfo.insuredLastName;
        updatedInputs.insuredDateOfBirth.value =
          policyDetailsInfo.insuredDateOfBirth;
        updatedInputs.insuredNationality.value =
          policyDetailsInfo.insuredNationalityId;
        updatedInputs.insuredGender.value = policyDetailsInfo.insuredGenderCode;
        updatedInputs.insuredPassportNumber.value =
          policyDetailsInfo.insuredPassportNumber;
        updatedInputs.insuredOccupation.value =
          policyDetailsInfo.insuredOccupationId;
      }

      if(isAdmin){
        const today = new Date();
        (updatedInputs.policyEffectiveDate as IFormDateDynamicProps).maxDate = AddMonths(today, 6);
      }

      setInputsForm(updatedInputs);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputs, values);

    if (data.isRenewal == "NO") {
      data.renewalNumber = null;
    }

    setFormDisabled(true);
    setSubmitButtonState('loading');

    try {
      let variablesMutation = {
        entityId: policyId,
        insuredInputs: {
          insuredFirstName: data.insuredFirstName,
          insuredMiddleName: data.insuredMiddleName || null,
          insuredLastName: data.insuredLastName,
          insuredDateOfBirth: dateStringToISOString(data.insuredDateOfBirth),
          insuredNationality: data.insuredNationality,
          insuredGender: data.insuredGender,
          insuredPassportNumber: data.insuredPassportNumber,
          insuredOccupation: data.insuredOccupation,
        },
        isRenewal: data.isRenewal,
        renewalNumber: data.renewalNumber,
        applicationContactInputs: {
          title: data.title,
          firstName: data.firstName,
          middleName: data.middleName,
          lastName: data.lastName,
          email: data.email || null,
          phoneType: data.phoneType,
          mobileNumber:
            data.mobileNumber && data.mobileNumber.length > 3
              ? data.mobileNumber.charAt(0) == '+'
                ? data.mobileNumber
                : '+' + data.mobileNumber
              : undefined,
          country: data.country,
          city: data.city,
          street: data.street || null,
          building: data.building || null,
        },
        effectiveDate: formatDateTime(
          data.policyEffectiveDate,
          SEND_TO_BACKEND_DATE_FORMAT
        ),
      };
      policyAction({
        variables: variablesMutation,
        errorPolicy: 'all',
      }).then((res) => {
        if (isEmpty(res.errors)) {
          toast.success(
            <ToastSuccessMessage>
              {'Policy Updated Successfully'}
            </ToastSuccessMessage>
          );
          setTimeout(() => {
            setSubmitButtonState('success');
            onSuccess();
            onClose();
          }, 500);
        } else {
          setSubmitButtonState(undefined);
          toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
        }
      });
    } catch {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setFormDisabled(false);
    }
  };

  return (
    <GenericDrawer
      title='Edit Policy Details'
      onClose={() => onClose()}
      isOpen={open}
    >
      {policyDetailsListResults.loading && open ? (
        <Loader />
      ) : (
        <>
          <SectionDynamicForm
            onSubmit={(values) => submitForm(values)}
            buttonText={'Submit'}
            submitButtonState={submitButtonState}
            disableForm={formDisabled}
            inputs={inputsForm}
            sections={sections}
            onChange={(
              fieldName: string,
              value: string | string[],
              allValues
            ) => {
              //TODO: refactor code in way to map values automatically

              const formInputs = cloneDeep(inputsForm);

              if (fieldName === "isRenewal") {
                if (value == "YES") {
                  formInputs.renewalNumber.required = true;
                  formInputs.renewalNumber.hidden = false;
                } else {
                  formInputs.renewalNumber.hidden = true;
                  formInputs.renewalNumber.required = false;
                }

                Object.values(formInputs).forEach((input) => {
                  input.value = allValues[input.name];
                });

                setInputsForm({ ...formInputs });
              }
            }}
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default ExpatPolicyDetailsDrawer;
