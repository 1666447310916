export function toLookups(data: any) {
  const assignedBPPlanList: Record<string, string> = {};
  
  data.SalesforceManagement.queries.getAssignedBPPlanListWithoutPagination.forEach(
    (element: {
      salesforceManagement_AssignedBPPlan_LineID: {
        id: string;
        views: {
          PlanConfigManagement_all: {
            properties: {
              Name: string;
              ExternalCode: string;
            };
          };
        };
      };
    }) => {
      assignedBPPlanList[
        element.salesforceManagement_AssignedBPPlan_LineID.id
      ] = `${element.salesforceManagement_AssignedBPPlan_LineID.views.PlanConfigManagement_all.properties.ExternalCode} - ${element.salesforceManagement_AssignedBPPlan_LineID.views.PlanConfigManagement_all.properties.Name}`;
    }
  );

  return assignedBPPlanList;
}
