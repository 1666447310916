import { enumListAsRecordObject } from '../../utils/graph-utils';

export function LookupToList(data: any) {
  const rangeEnums: Record<string, Record<string, string>> = {};

  rangeEnums[data.Insurance_RangeTypes.name] = enumListAsRecordObject(
    data.Insurance_RangeTypes.enumValues,
  );

  return rangeEnums;
}
