import { DynamicFormInputType, FormInputTypes } from "../../DynamicForm";
import { isEmpty } from "../../utils/validationUtils";

export const initialValues = {
  planCurrency: "",
  planConfigManagement_PlanSpecificAllRisk_CarAgeFrom: "",
  planConfigManagement_PlanSpecificAllRisk_CarAgeTo: "",
  planConfigManagement_PlanSpecificAllRisk_CarValueFrom: "",
  planConfigManagement_PlanSpecificAllRisk_CarValueTo: "",
  planConfigManagement_PlanSpecificAllRisk_CarCategory: "",
  planConfigManagement_PlanSpecificAllRisk_MinimumPremium: "",
  planConfigManagement_PlanSpecificAllRisk_Rate: "",
  planConfigManagement_PlanSpecificAllRisk_AgencyYear: "",
  planConfigManagement_PlanSpecificAllRisk_NoDepreciationYear: "",
};

export const inputs: Record<string, DynamicFormInputType> = {
  planCurrency: {
    name: "planCurrency",
    title: "Plan Currency",
    type: FormInputTypes.chips,
    placeholder: "Plan Currency",
    value: !isEmpty(initialValues.planCurrency)
      ? initialValues.planCurrency
      : "",
    multiple: false,
    disabled: true,
    required: true,
  },
  planConfigManagement_PlanSpecificAllRisk_CarValueFrom: {
    name: "planConfigManagement_PlanSpecificAllRisk_CarValueFrom",
    title: "Car Value From",
    placeholder: "Car Value From",
    type: FormInputTypes.formattedNumber,
    value: initialValues.planConfigManagement_PlanSpecificAllRisk_CarValueFrom,
    required: true,
    minValue: 0,
  },
  planConfigManagement_PlanSpecificAllRisk_CarValueTo: {
    name: "planConfigManagement_PlanSpecificAllRisk_CarValueTo",
    title: "Car Value To",
    placeholder: "Car Value To",
    type: FormInputTypes.formattedNumber,
    value: initialValues.planConfigManagement_PlanSpecificAllRisk_CarValueTo,
    required: true,
  },
  planConfigManagement_PlanSpecificAllRisk_CarAgeFrom: {
    name: "planConfigManagement_PlanSpecificAllRisk_CarAgeFrom",
    title: "Car Age From",
    placeholder: "Car Age From",
    type: FormInputTypes.number,
    value: initialValues.planConfigManagement_PlanSpecificAllRisk_CarAgeFrom,
    required: true,
    maxNumber: 50,
  },
  planConfigManagement_PlanSpecificAllRisk_CarAgeTo: {
    name: "planConfigManagement_PlanSpecificAllRisk_CarAgeTo",
    title: "Car Age To",
    placeholder: "Car Age To",
    type: FormInputTypes.number,
    value: initialValues.planConfigManagement_PlanSpecificAllRisk_CarAgeTo,
    required: true,
    maxNumber: 50,
  },
  planConfigManagement_PlanSpecificAllRisk_CarCategory: {
    name: "planConfigManagement_PlanSpecificAllRisk_CarCategory",
    title: "Car Category",
    type: FormInputTypes.chips,
    placeholder: "Car Category",
    value: !isEmpty(
      initialValues.planConfigManagement_PlanSpecificAllRisk_CarCategory
    )
      ? initialValues.planConfigManagement_PlanSpecificAllRisk_CarCategory
      : "",
    multiple: false,
    disabled: false,
    required: true,
  },
  planConfigManagement_PlanSpecificAllRisk_MinimumPremium: {
    name: "planConfigManagement_PlanSpecificAllRisk_MinimumPremium",
    title: "Minimum Premium",
    placeholder: "Minimum Premium",
    type: FormInputTypes.formattedNumber,
    value:
      initialValues.planConfigManagement_PlanSpecificAllRisk_MinimumPremium,
    required: true,
    minValue: 0,
  },
  planConfigManagement_PlanSpecificAllRisk_Rate: {
    name: "planConfigManagement_PlanSpecificAllRisk_Rate",
    title: "Rate",
    type: FormInputTypes.percentage,
    placeholder: "Rate",
    value: initialValues.planConfigManagement_PlanSpecificAllRisk_Rate,
    required: true,
    disabled: false,
  },
  planConfigManagement_PlanSpecificAllRisk_AgencyYear: {
    name: "planConfigManagement_PlanSpecificAllRisk_AgencyYear",
    title: "Agency Year",
    placeholder: "Agency Year",
    type: FormInputTypes.number,
    value: initialValues.planConfigManagement_PlanSpecificAllRisk_AgencyYear,
    required: false,
  },
  planConfigManagement_PlanSpecificAllRisk_NoDepreciationYear: {
    name: "planConfigManagement_PlanSpecificAllRisk_NoDepreciationYear",
    title: "No Depreciation Year",
    placeholder: "No Depreciation Year",
    type: FormInputTypes.number,
    value:
      initialValues.planConfigManagement_PlanSpecificAllRisk_NoDepreciationYear,
    required: false,
  },
};
