import { gql } from "@apollo/client";

export function getBusinessPartnersDetailsInfo() {
  return gql`
    query getBusinessPartnersDetailsInfo($id: String!) {
      Insurance {
        entities {
          agency(id: $id) {
            views {
              Insurance_all {
                properties {
                  AgencyName
                  AgencyStatus
                  AgencyType {
                    Id
                    Title
                  }
                  AgencyCode {
                    Id
                    Title
                  }
                  MTPCommissionPercentage
                  MBICommissionPercentage
                  ExpatCommissionPercentage
                  createdBy
                  modifiedBy
                  createdOn
                  modifiedOn
                }
              }
            }
            queries {
              insurance {
                relatedUsers {
                  createdBy_firstName
                  createdBy_lastName
                  modifiedBy_firstName
                  modifiedBy_lastName
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function getAgencyVignetteRange() {
  return gql`
    query getAgencyVignetteRange(
      $currentPage: Int!
      $currentPageSize: Int!
      $SelectedAgencyID: [String!]!
    ) {
      Insurance {
        queries {
          getAgencyVignetteRange(
            SelectedAgencyID: $SelectedAgencyID
            pagination: { pageNumber: $currentPage, pageSize: $currentPageSize }
          ) {
            paging {
              pageSize
              pageNumber
              totalCount
            }
            items {
              insurance_AgencyVignetteRange_Id
              insurance_AgencyVignetteRange_RangeFrom
              insurance_AgencyVignetteRange_RangeTo
              insurance_AgencyVignetteRange_RangeStatus
              insurance_AgencyVignetteRange_RangeCode
              insurance_AgencyVignetteRange_RangeYear
            }
          }
        }
      }

      Insurance_VignetteRangeStatuses: __type(
        name: "Insurance_VignetteRangeStatuses"
      ) {
        name
        enumValues {
          name
        }
      }
    }
  `;
}
