import React, { useEffect, useState } from "react";
import GenericDrawer from "../../components/common/generic-drawer/GenericDrawer";
import {
  DynamicFormInputType,
  IFormSelectDynamicProps,
} from "../../DynamicForm";
import { toast } from "react-toastify";
import { EnhancedButtonStatus } from "../../components/common/EnhancedButton";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { inputs } from "./content";
import { useMutation, useQuery } from "@apollo/client";
import Loader from "../../components/Loader";
import { cloneDeep, isEmpty } from "lodash";
import { DEFAULT_ERROR_TEXT } from "../../constants";
import {
  ISalesForceLineCreditLimitDrawerInfo,
  ISalesForceLineCreditLimitDrawerProps,
} from ".";
import { getListForm, updateLineCreditLimit } from "./queries";
import { graphqlToDefaultLineCreditLimitInfo, toLookups } from "./utils";
import { getError } from "../../utils/graph-utils";
import { normaliseDynamicValues } from "../../utils/dynamic-utils";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import DynamicForm from "../../DynamicForm/DynamicForm";
import { getAssignedLineInfo } from "../set-default-commission-drawer/queries";

const SalesForceLineCreditLimitDrawer: React.FC<
  ISalesForceLineCreditLimitDrawerProps
> = ({
  open,
  onSuccess,
  onClose,
  businessPartnerId,
  businessPartnerLinesId,
}) => {
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();
  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);

  let defaultLineCreditLimitInfoResult: any;
  if (businessPartnerLinesId.length === 1) {
    defaultLineCreditLimitInfoResult = useQuery(getAssignedLineInfo(), {
      variables: { id: businessPartnerLinesId[0] },
    });
  }
  const lineCreditLimitsDetailsListResults = useQuery(getListForm());

  const [lineCreditLimitAction] = useMutation(updateLineCreditLimit());

  useEffect(() => {
    initialize();
  }, [
    lineCreditLimitsDetailsListResults.data,
    defaultLineCreditLimitInfoResult?.data,
  ]);

  let updatedInputs: Record<string, DynamicFormInputType> = inputs;
  let defaultLineCreditLimit: ISalesForceLineCreditLimitDrawerInfo = {
    creditLimit: null,
    creditLimitCurrency: "39735",
    gracePeriod: null,
  };

  const initialize = () => {
    try {
      updatedInputs = cloneDeep(inputsForm);

      if (lineCreditLimitsDetailsListResults.data) {
        const lookupLists = toLookups(lineCreditLimitsDetailsListResults.data);

        (
          updatedInputs.creditLimitCurrency as IFormSelectDynamicProps
        ).selectOptions = lookupLists["creditLimitCurrency"];
      }

      if (defaultLineCreditLimitInfoResult?.data) {
        defaultLineCreditLimit = graphqlToDefaultLineCreditLimitInfo(
          defaultLineCreditLimitInfoResult?.data
        );
      }

      if (defaultLineCreditLimit) {
        updatedInputs.creditLimitCurrency.value =
          defaultLineCreditLimit.creditLimitCurrency || "39735";
        updatedInputs.creditLimit.value = defaultLineCreditLimit.creditLimit;
        updatedInputs.gracePeriod.value = defaultLineCreditLimit.gracePeriod;
        setInputsForm(updatedInputs);
      }
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputs, values);

    setFormDisabled(true);
    setSubmitButtonState("loading");

    try {
      let variablesMutation = {
        entityId: businessPartnerId,
        selectedBusinessPartnerLineIds: businessPartnerLinesId,
        creditLimit: parseInt(data.creditLimit as unknown as string),
        creditLimitCurrency: data.creditLimitCurrency || null,
        gracePeriod: parseInt(data.gracePeriod as unknown as string),
      };

      lineCreditLimitAction({
        variables: variablesMutation,
        errorPolicy: "all",
      })
        .then((res) => {
          if (isEmpty(res.errors)) {
            toast.success(
              <ToastSuccessMessage>
                Credit Limit successfully updated.
              </ToastSuccessMessage>
            );
            setTimeout(() => {
              setSubmitButtonState("success");
              onSuccess();
              onClose();
            }, 500);
          } else {
            setSubmitButtonState(undefined);
            toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
          }
        })
        .catch((err) => {
          toast.error(<ToastErrorMessage>{getError(err)}</ToastErrorMessage>);
        });
    } catch {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setFormDisabled(false);
    }
  };

  return (
    <GenericDrawer
      title={"Modify Line Credit Limit"}
      onClose={() => onClose()}
      isOpen={open}
    >
      {lineCreditLimitsDetailsListResults.loading && open ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={"Submit"}
            submitButtonState={submitButtonState}
            disableForm={formDisabled}
            title="Information"
            hasDoprdownSpecificBehavior={true}
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default SalesForceLineCreditLimitDrawer;
