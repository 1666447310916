import { DynamicFormInputType, FormInputTypes } from "../../DynamicForm";
import { isEmpty } from "../../utils/validationUtils";

export const initialValues = {
  editableLineCharges: "",
  allowPolicyEndDateEdits: false,
  allowPlanCoverEdits: false,
  allowClauseEdits: false,
};

export const inputs: Record<string, DynamicFormInputType> = {
  editableLineCharges: {
    name: "editableLineCharges",
    title: "Editable Line Charges",
    type: FormInputTypes.chips,
    placeholder: "Editable Line Charges",
    value: !isEmpty(initialValues.editableLineCharges)
      ? [initialValues.editableLineCharges]
      : [],
    multiple: true,
    disabled: false,
    required: false,
  },
  allowPolicyEndDateEdits: {
    name: "allowPolicyEndDateEdits",
    title: "Allow Policy End Date Edits",
    placeholder: "Allow Policy End Date Edits",
    type: FormInputTypes.switch,
    value: initialValues.allowPolicyEndDateEdits,
    required: true,
    disabled: false,
  },
  allowPlanCoverEdits: {
    name: "allowPlanCoverEdits",
    title: "Allow Plan Cover Details Edits",
    placeholder: "Allow Plan Cover Details Edits",
    type: FormInputTypes.switch,
    value: initialValues.allowPlanCoverEdits,
    required: true,
    disabled: false,
  },
  allowClauseEdits: {
    name: "allowClauseEdits",
    title: "Allow Clause Edits",
    placeholder: "Allow Clause Edits",
    type: FormInputTypes.switch,
    value: initialValues.allowClauseEdits,
    required: true,
    disabled: false,
  },
};
