import { FormInputTypes } from '../../DynamicForm';
import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
} from '../../components/enhanced-table';
import { IListingFilterWidgetSection } from '../../components/widgets/custom-listing-filter';
import { IAbstractRecord } from '../../models';
import { enumListAsRecordObject } from '../../utils/graph-utils';

export const headers: Record<string, EnhancedTableHeader> = {
  lineName: {
    name: 'lineName',
    title: 'Line Name',
    type: EnhancedTableHeaderType.Clickable,
    callback: () => {},
  },
  externalCode: {
    name: 'externalCode',
    title: 'External Code',
    type: EnhancedTableHeaderType.Text,
  },
  abbreviation: {
    name: 'abbreviation',
    title: 'Abbreviation',
    type: EnhancedTableHeaderType.Text,
  },
  arabicName: {
    name: 'arabicName',
    title: 'Arabic Name',
    type: EnhancedTableHeaderType.Text,
  },
  status: {
    name: 'status',
    title: 'Status',
    type: EnhancedTableHeaderType.Text,
  },
  createdOn: {
    name: 'createdOn',
    title: 'Created on',
    type: EnhancedTableHeaderType.Date,
  },
  modifiedOn: {
    name: 'modifiedOn',
    title: 'Modified on',
    type: EnhancedTableHeaderType.Date,
  },
};

export function filterSectionsContent(
  data: any,
  filterValues: IAbstractRecord,
): IListingFilterWidgetSection[] {
  const newFilterSections: IListingFilterWidgetSection[] = [
    {
      title: 'Filters',
      inputs: {
        status: {
          name: 'status',
          title: 'Status',
          type: FormInputTypes.chips,
          value: filterValues?.status || [],
          placeholder: 'Status',
          triggerUpdate: true,
          selectOptions: enumListAsRecordObject(
            data?.PlanConfigManagement_LineStatuses?.enumValues,
          ),
        },
        createdDate: {
          name: 'createdDate',
          title: 'Created On',
          type: FormInputTypes.daterange,
          value: filterValues.createdDate || [],
          placeholder: 'Created On',
          triggerUpdate: true,
        },
      },
    },
  ];
  return newFilterSections;
}
