import { graphql } from "../../gql/__generated__";
import { gql } from "@apollo/client";

export function getPolicyDetails() {
  return gql`
    query getPolicyDetails($id: String!) {
      Insurance {
        entities {
          policy(id: $id) {
            views {
              Insurance_all {
                properties {
                  PolicyNumber
                  FullPolicyNumber
                  CancelationEffectiveDate
                  PolicyEffectiveDate
                  PolicyExpiryDate
                  createdOn
                  createdBy
                  modifiedOn
                  modifiedBy
                  PendingDocumentRegeneration
                  PolicyIssueDate
                  PolicyHolderID {
                    views {
                      Insurance_all {
                        properties {
                          FullName
                          FirstName
                          MiddleName
                          LastName
                          Email
                          MobileNumber
                          PhoneType
                          Address {
                            Country {
                              Id
                              Code
                              Title
                            }
                            City
                            Street
                            Building
                          }
                        }
                      }
                    }
                  }
                  ApplicationID {
                    id
                  }
                  PolicyStatus {
                    Code
                    Title
                  }
                  PolicyStatusReason
                  LineOfBusiness {
                    Code
                    Title
                  }
                  MotorDetailsID {
                    views {
                      Insurance_all {
                        properties {
                          NameOnLicenseEn
                          NameOnLicenseAr
                          Product {
                            Code
                            Title
                          }
                          TowingService {
                            Code
                            Title
                          }
                          VignetteCode
                          Vignette
                          PlateCode {
                            Code
                            Title
                          }
                          PlateNumber
                          UsageType {
                            Code
                            Title
                          }
                          BodyType {
                            Code
                            Title
                          }
                          EngineType {
                            Code
                            Title
                          }
                          Brand {
                            Id
                            Code
                            Title
                          }
                          Model {
                            Id
                            Code
                            Title
                          }
                          YearOfMake
                          Motor
                          Weight
                          EngineSize
                          Seats
                          Chassis
                          HorsePower
                          AdditionalDocument {
                            id
                            location
                            path
                            fileName
                            length
                            contentType
                          }
                        }
                      }
                    }
                  }
                  AssignedAgencyID {
                    views {
                      Insurance_all {
                        properties {
                          AgencyName
                        }
                      }
                    }
                  }
                  AssignedAgentID {
                    views {
                      Insurance_all {
                        properties {
                          FirstName
                          LastName
                          CanViewCommission
                        }
                      }
                    }
                  }
                  TotalAnnualPremium
                  IsRenewal
                  RenewalNumber
                  QuoteID {
                    id
                    views {
                      Insurance_all {
                        properties {
                          ApplicationID {
                            id
                          }
                          MotorDetailsID {
                            id
                            views {
                              Insurance_all {
                                properties {
                                  SumInsured
                                }
                              }
                            }
                          }
                          createdOn
                          createdBy
                          modifiedOn
                          modifiedBy
                          ApplicationMotorPlanDetailsID {
                            id
                            views {
                              Insurance_all {
                                properties {
                                  TowingService {
                                    Code
                                    Title
                                  }
                                  VignetteCode
                                  Vignette
                                }
                              }
                            }
                          }
                          Name
                          Product {
                            Id
                            Title
                          }
                          Status
                          StatusReason
                          TotalBasePremium
                          PolicyCost
                          TotalAnnualPremium
                        }
                      }
                    }
                  }
                  PolicyDocument {
                    id
                    location
                    path
                    fileName
                    length
                    contentType
                  }
                }
              }
            }
            queries {
              insurance {
                relatedUsers {
                  createdBy_firstName
                  createdBy_lastName
                  modifiedBy_firstName
                  modifiedBy_lastName
                }
              }
            }
            actions {
              able_To_UploadAdditionalDocument
            }
          }
        }
        queries {
          regeneratedDocuments: getNewPolicyDocuments(
            policyId: $id
            uploadedManually: false
          ) {
            insurance_Policy_Id
            docs_Id
            docs_DocumentCode
            docs_Version
            docs_createdOn
            docs_PolicyDocument {
              id
              location
              path
              fileName
              length
              contentType
            }
          }
          additionalDocuments: getNewPolicyDocuments(
            policyId: $id
            uploadedManually: true
          ) {
            insurance_Policy_Id
            docs_Id
            docs_DocumentCode
            docs_Version
            docs_createdOn
            docs_PolicyDocument {
              id
              location
              path
              fileName
              length
              contentType
            }
          }
        }
      }
    }
  `;
}

export function regeneratePolicyDocument() {
  return graphql(/* GraphQL */ `
    mutation regeneratePolicyDocument($id: String!) {
      insurance {
        entities {
          policy {
            insurance {
              regeneratePolicyDocuments(entityId: $id) {
                Items {
                  EntityTypeId
                  EntityId
                  EntityViewId
                  PropertyId
                  File {
                    id
                    location
                    path
                    fileName
                    length
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
}

export function generateCommissionDocument() {
  return gql `
    mutation generateCommissionDocument($id: String!) {
      insurance {
        entities {
          policy {
            insurance {
              generateCommissionDocument(entityId: $id) {
                Items {
                  EntityTypeId
                  EntityId
                  EntityViewId
                  PropertyId
                  File {
                    id
                    location
                    path
                    fileName
                    length
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}
