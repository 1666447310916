import { DynamicFormInputType, FormInputTypes } from "../../DynamicForm";
import { isEmpty } from "../../utils/validationUtils";

export const initialValues = {
  claimCoverId: "",
  lineId: "",
  lineName: "",
  policyCover: "",
  claimCoverName: "",
  claimCoverExternalCode: "",
  arabicName: "",
  claimCoverPrintingOrder: "",
  claimCoverDescription: "",
};

export const inputs: Record<string, DynamicFormInputType> = {
  lineName: {
    name: "lineName",
    title: "Line",
    type: FormInputTypes.chips,
    placeholder: "Line",
    value: !isEmpty(initialValues.lineName) ? initialValues.lineName : "",
    disabled: true,
    multiple: false,
  },
  policyCover: {
    name: "policyCover",
    title: "Policy Cover",
    type: FormInputTypes.chips,
    placeholder: "Policy Cover",
    value: isEmpty(initialValues.policyCover) ? initialValues.policyCover : "",
    required: true,
    multiple: false,
  },
  claimCoverName: {
    name: "claimCoverName",
    title: "Claim Cover Name",
    type: FormInputTypes.text,
    placeholder: "Claim Cover Name",
    value: !isEmpty(initialValues.claimCoverName) ? initialValues.claimCoverName : "",
    required: true,
  },
  claimCoverExternalCode: {
    name: "claimCoverExternalCode",
    title: "External Code",
    type: FormInputTypes.text,
    placeholder: "External Code",
    value: !isEmpty(initialValues.claimCoverExternalCode)
      ? initialValues.claimCoverExternalCode
      : "",
    required: true,
  },
  arabicName: {
    name: "arabicName",
    title: "Arabic Name",
    type: FormInputTypes.text,
    placeholder: "Arabic Name",
    value: !isEmpty(initialValues.arabicName) ? initialValues.arabicName : "",
    required: true,
  },
  claimCoverPrintingOrder: {
    name: "claimCoverPrintingOrder",
    title: "Printing Order",
    placeholder: "Printing Order",
    type: FormInputTypes.number,
    value: !isEmpty(initialValues.claimCoverPrintingOrder)
      ? initialValues.claimCoverPrintingOrder
      : [],
    required: true,
  },
  claimCoverDescription: {
    name: "claimCoverDescription",
    title: "Description",
    placeholder: "Description",
    type: FormInputTypes.text,
    value: !isEmpty(initialValues.claimCoverDescription)
      ? initialValues.claimCoverDescription
      : [],
    required: true,
  },
};
