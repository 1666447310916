import React, { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { IEnhancedCommonProps } from "..";
import { makeStyles } from "tss-react/mui";
import { MAIN_ONE_THEME, TinyMCEApiKey } from "../../constants";

export interface IEnhancedEditor extends IEnhancedCommonProps {
  name: string;
  title: string;
  placeholder: string;
  error?: string;
  value: string;
  onChange: (name: string, value: string) => void;
  disabled?: boolean;
}

const useStyles = makeStyles()((theme) => ({
  label: {
    display: "block",
    color: MAIN_ONE_THEME.palette.primary1.main,
    textAlign: "left",
    fontSize: MAIN_ONE_THEME.typography.regular.reg1.fontSize,
    lineHeight: "15px",
    marginBottom: "13px",
  },
  error: {
    color: "red",
    margin: "5px 0 0",
    display: "block",
    fontSize: "11px",
    minHeight: "15px",
    textAlign: "right",
  },
  editorContainer: {
    width: "100%",
    height: "150px",
    minHeight: "150px",
    maxHeight: "150px",
  },
}));

const EnhancedTinyMCEEditor: React.FC<IEnhancedEditor> = ({
  name,
  title,
  placeholder,
  error,
  value,
  onChange,
  disabled,
}) => {
  const { classes } = useStyles();
  const editorRef = useRef(null);

  const handleKeyDown = (event: { key: string }) => {
    if (event.key === "Enter") {
      const editor = editorRef.current;
      if (editor) {
        const selection = editor.selection.getRng();
        const cursorIndex = selection.startOffset;

        // Insert newline character at the cursor position
        editor.selection.setContent("\n", { format: "text" });

        // Move the cursor to the next line
        editor.selection.setCursorLocation(cursorIndex + 1);
      }
    }
  };

  return (
    <>
      <div style={{ display: "relative", height: "100%", width: "95%" }}>
        <span className={classes.label}>{title}</span>
        <div
          style={{
            height: "280px",
            minHeight: "280px",
            maxHeight: "280px",
            overflowY: "auto",
          }}
        >
          <Editor
            apiKey={TinyMCEApiKey}
            init={{
              directionality: "ltr",
              plugins: "lists",
              textpattern_text_alignments: "Left Align",
              statusbar: false,
              placeholder,
              branding: false,
              height: 280,
              menubar: false,
              resize: false,
              toolbar_mode: "sliding",
              toolbar:
                "emoticons |  forecolor backcolor |" +
                "bold italic underline strikethrough | " +
                // // "alignright alignjustify | outdent indent | " +
                "numlist bullist |",
              // // "link | removeformat | undo redo",
              font_size_formats:
                "8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt",
              font_family_formats:
                "Arial=arial; Arial Black=arial black; Courier New=courier new,courier; Georgia=georgia; Tahoma=tahoma,arial,helvetica,sans-serif; Times New Roman=times new roman,times;",
            }}
            value={value}
            onEditorChange={(stringifiedHtmlValue) =>
              onChange(name, stringifiedHtmlValue)
            }
            disabled={disabled}
            onKeyDown={handleKeyDown}
            ref={editorRef}
          />
        </div>
        <span className={classes.error}>{error}</span>
      </div>
    </>
  );
};

export default EnhancedTinyMCEEditor;
