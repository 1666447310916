export interface IEnvironmentSettingsPageProps {}

export interface ISystemCurrency {
  basicCurrency: string;
  secondaryCurrency: string;
}

export const initialValues: ISystemCurrency = {
  basicCurrency: '',
  secondaryCurrency: '',
};
