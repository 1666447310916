import { gql } from "@apollo/client";

export function getAssignedBPPlanLists() {
  return gql`
    query getAssignedBPPlanListWithoutPagination(
      $selectedBusinessUserID: String!
    ) {
      SalesforceManagement {
        queries {
          getAssignedBPPlanListWithoutPagination(
            selectedBusinessUserID: $selectedBusinessUserID
          ) {
            businessPartner_Id
            businessPartner_PersonID {
              id
              views {
                SalesforceManagement_all {
                  properties {
                    FullName
                  }
                }
              }
            }
            salesforceManagement_AssignedBPPlan_Id
            salesforceManagement_AssignedBPPlan_BusinessPartnerID {
              id
              views {
                SalesforceManagement_all {
                  properties {
                    Code
                  }
                }
              }
            }
            salesforceManagement_AssignedBPPlan_BusinessUserID {
              id
              views {
                SalesforceManagement_all {
                  properties {
                    Code
                  }
                }
              }
            }
            salesforceManagement_AssignedBPPlan_PlanID {
              id
              views {
                PlanConfigManagement_all {
                  properties {
                    Name
                    ExternalCode
                  }
                }
              }
            }
            salesforceManagement_AssignedBPPlan_BusinessPartnerLineID {
              id
              views {
                SalesforceManagement_all {
                  properties {
                    Status
                  }
                }
              }
            }
            salesforceManagement_AssignedBPPlan_BusinessPartnerPlanID {
              id
              views {
                SalesforceManagement_all {
                  properties {
                    Status
                  }
                }
              }
            }
            salesforceManagement_AssignedBPPlan_LineID {
              id
              views {
                PlanConfigManagement_all {
                  properties {
                    Name
                    ExternalCode
                  }
                }
              }
            }
            salesforceManagement_AssignedBPPlan_Status
          }
        }
      }
    }
  `;
}

export function updateBusinessUserAuthorities() {
  return gql`
    mutation updateBusinessUserAuthorities(
      $entityId: String!
      $allowPlanCoverEdits: Boolean!
      $allowPolicyEndDateEdits: Boolean!
      $allowClauseEdits: Boolean!
      $editableLineChargesIDs: [String!]!
    ) {
      salesforceManagement {
        entities {
          businessUser {
            salesforceManagement {
              updateBusinessUserAuthorities(
                entityId: $entityId
                allowPlanCoverEdits: $allowPlanCoverEdits
                allowPolicyEndDateEdits: $allowPolicyEndDateEdits
                allowClauseEdits: $allowClauseEdits
                editableLineChargesIDs: $editableLineChargesIDs
              ) {
                id
              }
            }
          }
        }
      }
    }
  `;
}
