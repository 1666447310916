// TODO Replaced with material-ui drawer
import { ButtonBase } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import clsx from 'clsx';
import { ColumnDirection, COLLAPSED_COLUMN_WIDTH } from '.';
import { HEADER_HEIGHT, MAIN_ONE_THEME } from '../../constants';
import { GridWidth } from '../../models/grid';
import { gridWidthToPercentage } from '../../utils/grid-utils';

interface IFixedColumnProps {
  background: string;
  collapsible?: boolean;
  collapsed: boolean;
  direction: ColumnDirection;
  width: GridWidth;
  onToggle: (direction: ColumnDirection) => void;
  children: any;
}

const useStyles = makeStyles<{props: IFixedColumnProps}>()((theme, {props}) => ({
  button: {
    backgroundColor: MAIN_ONE_THEME.palette.primary1.main,
    color: 'white',
    height: 40,
    left: props.direction === ColumnDirection.right ? -10 : undefined,
    position: 'absolute' as 'absolute',
    right: props.direction === ColumnDirection.left ? -10 : undefined,
    top: 15,
    width: 20,
  },
  column:{
    background: props.background,
    borderLeft:
      props.direction === ColumnDirection.right
        ? `1px solid ${MAIN_ONE_THEME.palette.secondary3.main}`
        : undefined,
    borderRight:
      props.direction === ColumnDirection.left
        ? `1px solid ${MAIN_ONE_THEME.palette.secondary3.main}`
        : undefined,
    bottom: 0,
    left: props.direction === ColumnDirection.left ? 0 : undefined,
    position: 'fixed' as 'fixed',
    right: props.direction === ColumnDirection.right ? 0 : undefined,
    top: HEADER_HEIGHT,
    width: `${gridWidthToPercentage(props.width as number)}%`,
    zIndex: 10,
    boxSizing: 'border-box' as 'border-box',
    // transition: (theme as unknown as Theme).transitions.create('top', {
    //   duration: 250,
    //   delay: 100,
    //   easing: (theme as unknown as Theme).transitions.easing.easeIn,
    // },
  },
  collapsed: {
    width: `${gridWidthToPercentage(COLLAPSED_COLUMN_WIDTH)}%`,
    '& button': {
      transform: 'rotate(180deg)',
    },
  },
  children: {
    width: '100%',
    height: '100%',
    padding: '1em 0.5em',
    boxSizing: 'border-box' as 'border-box',
    overflowY: 'auto' as 'auto',
  },
  collapsedChildren: {
    visibility: 'hidden',
  },
}));

const FixedColumn: React.FC<IFixedColumnProps> = (props) => {
  // Destructed props here to pass props more easily to useStyles
  const { collapsible, collapsed, direction, onToggle, width, children } =
    props;

  const {classes} = useStyles({props});
  return !!width && width > 0 ? (
    <section
      className={clsx(classes.column, {
        [classes.collapsed]: collapsed,
      })}
    >
      {collapsible && (
        <ButtonBase
          className={classes.button}
          onClick={() => onToggle(direction)}
        >
          {direction === ColumnDirection.right ? '>' : '<'}
        </ButtonBase>
      )}
      <div
        className={clsx(classes.children, {
          [classes.collapsedChildren]: collapsed,
        })}
      >
        {children}
      </div>
    </section>
  ) : (
    <></>
  );
};

export default FixedColumn;
