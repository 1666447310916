import { DynamicFormInputType, FormInputTypes } from "../../DynamicForm";
import { isEmpty } from "../../utils/validationUtils";

export const initialValues = {
  businessPartnerDetails: {
    specialCommissionApplicable: "",
    specialCommissionType: "",
    specialCommissionValue: "",
    specialCommissionValueCurrency: "",
    specialCommissionRate: "",
    specialCommissionApplicableOn: "",
  },
};

export const inputs: Record<string, DynamicFormInputType> = {
  specialCommissionApplicable: {
    name: "specialCommissionApplicable",
    title: "Special Commission Applicable",
    type: FormInputTypes.chips,
    placeholder: "Special Commission Applicable",
    value: !isEmpty(
      initialValues.businessPartnerDetails.specialCommissionApplicable
    )
      ? initialValues.businessPartnerDetails.specialCommissionApplicable
      : "",
    multiple: false,
    disabled: false,
    required: true,
  },
  specialCommissionType: {
    name: "specialCommissionType",
    title: "Special Commission Type",
    type: FormInputTypes.chips,
    placeholder: "Special Commission Type",
    value: !isEmpty(initialValues.businessPartnerDetails.specialCommissionType)
      ? initialValues.businessPartnerDetails.specialCommissionType
      : "",
    multiple: false,
    disabled: false,
    required: true,
  },
  specialCommissionValue: {
    name: "specialCommissionValue",
    title: "Special Commission Value",
    type: FormInputTypes.formattedNumber,
    placeholder: "Special Commission Value",
    value: initialValues.businessPartnerDetails.specialCommissionValue,
    minValue: 0,
    required: true,
    disabled: false,
  },
  specialCommissionValueCurrency: {
    name: "specialCommissionValueCurrency",
    title: "Special Commission Value Currency",
    type: FormInputTypes.chips,
    placeholder: "Special Commission Value Currency",
    value: !isEmpty(
      initialValues.businessPartnerDetails.specialCommissionValueCurrency
    )
      ? initialValues.businessPartnerDetails.specialCommissionValueCurrency
      : "",
    multiple: false,
    disabled: false,
    required: true,
  },
  specialCommissionRate: {
    name: "specialCommissionRate",
    title: "Special Commission Rate",
    type: FormInputTypes.percentage,
    placeholder: "Special Commission Rate",
    value: initialValues.businessPartnerDetails.specialCommissionRate,
    required: true,
    disabled: false,
  },
  specialCommissionApplicableOn: {
    name: "specialCommissionApplicableOn",
    title: "Special Commission Applicable On",
    type: FormInputTypes.chips,
    placeholder: "Special Commission Applicable On",
    value: !isEmpty(
      initialValues.businessPartnerDetails.specialCommissionApplicableOn
    )
      ? initialValues.businessPartnerDetails.specialCommissionApplicableOn
      : "",
    multiple: false,
    disabled: false,
    required: true,
  },
};
