import { enumListAsRecordObject, lookupListAsRecordObject } from '../../utils/graph-utils';

export function LookupToList(data: any) {
  const agencyEnums: Record<string, Record<string, string>> = {};
  agencyEnums['agencyType'] = lookupListAsRecordObject(data.Insurance.lookups.agencyTypes);
  agencyEnums[data.Insurance_AgencyStatuses.name] = enumListAsRecordObject(
    data.Insurance_AgencyStatuses.enumValues,
  );
  agencyEnums['agencyCode'] = lookupListAsRecordObject(data.Insurance.lookups.agencyCodes);

  return agencyEnums;
}

export function graphqlEntityToAgencyInfo(data: any): IAgencyInfo {
  return {
    agencyId: '',
    agencyName:
      data?.Insurance?.entities?.agency?.views?.Insurance_all?.properties
        ?.AgencyName,
    agencyStatus:
      data?.Insurance?.entities?.agency?.views?.Insurance_all?.properties
        ?.AgencyStatus,
    agencyType:
      data?.Insurance?.entities?.agency?.views?.Insurance_all?.properties
        ?.AgencyType?.Id,
    agencyCode:
      data?.Insurance?.entities?.agency?.views?.Insurance_all?.properties
        ?.AgencyCode?.Id,
    mtpCommissionPercentage:
      data?.Insurance?.entities?.agency?.views?.Insurance_all?.properties
        ?.MTPCommissionPercentage,
    mbiCommissionPercentage:
      data?.Insurance?.entities?.agency?.views?.Insurance_all?.properties
        ?.MBICommissionPercentage,
    expatCommissionPercentage:
      data?.Insurance?.entities?.agency?.views?.Insurance_all?.properties
        ?.ExpatCommissionPercentage,
    ufaCode:
      data?.Insurance?.entities?.agency?.views?.Insurance_all?.properties
            ?.UFACode,
  };
}
