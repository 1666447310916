export interface IConfigurationProviderDetailsPageProps {}

export interface IConfigurationProviderDetails {
  name: string;
  providerName: string;
}

export const initialValues: IConfigurationProviderDetails = {
  name: "",
  providerName: "",
};
