import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ILineDetails, ILineDetailsPageProps, initialValues } from ".";
import Loader from "../../components/Loader";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { IEntityInfoView } from "../../components/widgets/entity-info/EntityInfoFields";
import EntityInfoWidget from "../../components/widgets/entity-info/EntityInfoWidget";
import {
  DEFAULT_ERROR_TEXT,
  MAIN_ONE_THEME,
  contentFontFamilyBold,
} from "../../constants";
import {
  entityView,
  headers,
  lineDetailsTabs,
  planHeaders,
  claimCoverHeaders,
  steps,
  policyCoverHeaders,
  clauseHeaders,
} from "./content";
import {
  activateLine,
  deactivateLine,
  getSubLineList,
  getLineDetailsInfo,
  getPlanList,
  getClaimCoverList,
  getPolicyCoverList,
  getClauseList,
} from "./queries";
import {
  entityToLineDetails,
  getLineStatus,
  mapToClaimCoversListingData,
  mapToClausesListingData,
  mapToLSublinesListingData,
  mapToPlansListingData,
  mapToPolicyCoversListingData,
} from "./utils";
import { capitalizeFirstLetter } from "../../utils/formatting-utils";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import dayjs from "dayjs";
import EnhancedTable from "../../components/enhanced-table/EnhancedTable";
import { IListingData } from "../../models/listing";
import WidgetSection from "../../components/common/WidgetSection";
import EnhancedStepperSpecificDesign from "../../components/common/EnhancedStepperSpecificDesign";
import { makeStyles } from "tss-react/mui";
import {
  IEnhanceTableHeaderClickable,
  IEnhancedTableMenuItem,
} from "../../components/enhanced-table";
import TabsLayout from "../../page-layout/tabs-layout/TabsLayout";
import { IDynamicDiplayView } from "../../components/widgets/dynamic-display";
import { EnhancedButtonStatus } from "../../components/EnhancedButton";
import SublineDrawer from "../subline-drawer/SublineDrawer";
import { getError } from "../../utils/graph-utils";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import { isEmpty } from "../../utils/validationUtils";
import { IConfirmation } from "../../redux/confirmation/types";
import { OpenConfirmationAction } from "../../redux/confirmation/actions";
import PlanDrawer from "../plan-drawer/PlanDrawer";
import ClaimCoverDrawer from "../claim-cover-drawer/ClaimCoverDrawer";
import PolicyCoverDrawer from "../policy-cover-drawer/PolicyCoverDrawer";
import ClausePopUp from "../clause-popup/ClausePopUp";

const useStyles = makeStyles()(() => ({
  stepper: {
    backgroundColor: "transparent",
    maxWidth: "90%",
    marginBottom: "20px",
    "& .MuiStepLabel-root .Mui-active, .MuiStepLabel-root ": {
      color: MAIN_ONE_THEME.palette.primary5.main,
      "& .MuiStepIcon-text": {
        fontSize: 0,
        lineHeight: "0px",
        fill: "black",
      },
    },

    ".Mui-completed": {
      color: MAIN_ONE_THEME.palette.primary5.main,
      "& .MuiStepIcon-text": {
        fontSize: 0,
        lineHeight: "0px",
        fill: "black",
      },
    },

    "@media only screen and (min-width: 600px)": {
      maxWidth: "1300px",
    },
  },
  section: {
    display: "grid",
    "grid-template-columns": "19.5% 19.5% 19.5% 19.5% 19.5%",
    justifyContent: "space-between",
    flexWrap: "wrap",

    "& label": {
      "& span": {
        margin: "-2px 0",
      },
    },
  },
  sectionTitle: {
    padding: "-10x 10x!important",
  },
  sectionFullRow: {
    display: "grid",
    "grid-template-columns": "100%",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  row: {
    flexWrap: "wrap",
    margin: "15px 20px 10px 0px",
    alignItems: "flex-start",
    display: "grid",
    gap: "5px",
    "& label": {
      flexGrow: "1",
      flexBasis: "0",
      minWidth: "0",
    },
    "grid-template-columns": "19.5% 19.5% 19.5% 19% 16% 3% 3%",
  },
  thickSeperator: {
    height: 10,
    margin: "10px 0 10px 0",
    "grid-column-start": "1",
    "grid-column-end": "4",
  },
  field: {
    width: "96%",
    marginRight: "10px !important",
  },
  repeaterField: {
    margin: "0px!important",
  },
  arField: {
    width: "96%",
    marginRight: "10px !important",
    direction: "rtl",
  },
  header: {
    fontFamily: contentFontFamilyBold,
    fontSize: MAIN_ONE_THEME.typography.regular.reg3.fontSize,
    color: MAIN_ONE_THEME.palette.primary2.main,
    "&:hover": {
      color: MAIN_ONE_THEME.palette.primary1.main,
    },
  },
  content: {
    padding: "0",
  },
  dirtyChip: {
    margin: "0 0 -15px 0",
    backgroundColor: MAIN_ONE_THEME.palette.primary2.main,
    "& span": {
      color: "#ffffff",
    },
  },
  tabPanelStyle: {
    padding: "unset",
    marginTop: "20px",
    justifyContent: 'flex-start',
  },
}));

const LineDetailsPage: React.FC<ILineDetailsPageProps> = () => {
  //#region react and redux hooks
  const { classes } = useStyles();
  const params = useParams();
  const tenant = useAppSelector((state) => state.tenant);
  const user = useAppSelector((state) => state.user);
  const [activateButtonState, setActivateButtonState] =
    useState<EnhancedButtonStatus>();
  const [deactivateButtonState, setDeactivateButtonState] =
    useState<EnhancedButtonStatus>();
  const [sublineDrawerOpen, setSublineDrawerOpen] = useState<boolean>(false);
  const [planDrawerOpen, setPlanDrawerOpen] = useState<boolean>(false);
  const [claimCoverDrawerOpen, setClaimCoverDrawerOpen] =
    useState<boolean>(false);
  const [policyCoverDrawerOpen, setPolicyCoverDrawerOpen] =
    useState<boolean>(false);
  const [firstTabAsActiveTab, setFirstTabAsActiveTab] =
    useState<boolean>(false);
  const [clausePopUpOpen, setClausePopUpOpen] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [selectedSublineItems, setSelectedSublineItems] = useState<number>(0);
  const [selectedPlanItems, setSelectedPlanItems] = useState<number>(0);
  const [selectedClaimCoverItems, setSelectedClaimCoverItems] =
    useState<number>(0);
  const [selectedPolicyCoverItems, setSelectedPolicyCoverItems] =
    useState<number>(0);
  const [selectedClauseItems, setSelectedClauseItems] = useState<number>(0);
  //#endregion

  //#region component hooks and states
  const [lineDetailsInfo, setLineDetailsInfo] =
    useState<ILineDetails>(initialValues);

  const [updatedLeftSideInfo, setUpdatedLeftSideInfo] =
    useState<IEntityInfoView>(entityView);

  const [loadingState, setLoadingState] = useState<boolean>(true);

  const [selectedSublineEntityId, setSelectedSublineEntityId] = useState("");
  const [selectedPlanEntityId, setSelectedPlanEntityId] = useState("");
  const [selectedClaimCoverEntityId, setSelectedClaimCoverEntityId] =
    useState("");
  const [selectedPolicyCoverEntityId, setSelectedPolicyCoverEntityId] =
    useState("");
  const [selectedClauseEntityId, setSelectedClauseEntityId] = useState("");

  const [actionClicked, setActionClicked] = useState(null);

  const entityId = params.id;

  const [selectedPlanSublineStatus, setSelectedPlanSublineStatus] =
    useState<string>(null);
  const [selectedPlanSublineID, setSelectedPlanSublineID] =
    useState<string>(null);

  const [tableLoadingState, setTableLoadingState] = useState<boolean>(false);
  const [planTableLoadingState, setPlanTableLoadingState] =
    useState<boolean>(false);
  const [claimCoverTableLoadingState, setClaimCoverTableLoadingState] =
    useState<boolean>(false);
  const [policyCoverTableLoadingState, setPolicyCoverTableLoadingState] =
    useState<boolean>(false);
  const [clauseTableLoadingState, setClauseTableLoadingState] =
    useState<boolean>(false);
  //#endregion

  (
    headers.planConfigManagement_Subline_Name as IEnhanceTableHeaderClickable
  ).callback = (sublinePayload: any) => {
    navigate(
      "/plan/sublines/" + sublinePayload.columns.planConfigManagement_Subline_Id
    );
  };
  (planHeaders.planName as IEnhanceTableHeaderClickable).callback = (
    planPayload: any
  ) => {
    navigate("/plan/plans/" + planPayload.columns.planConfigManagement_Plan_Id);
  };
  (
    policyCoverHeaders.planConfigManagement_PolicyCover_Name as IEnhanceTableHeaderClickable
  ).callback = (payload: any) => {
    navigate(
      "/plan/covers/" + payload.columns.planConfigManagement_PolicyCover_Id
    );
  };

  const isAdmin = user.userRoles.includes("Insurance-Admin");
  let sublineListingActions: IEnhancedTableMenuItem[] = [];
  sublineListingActions = [
    {
      title: "New",
      onClick: () => {},
      isEntity: false,
      isBulk: false,
      iconUrl: tenant.cdnUrl + "/icons/add-primary-red.svg",
      hidden: !isAdmin,
      disabled: false,
    },
    {
      title: "Edit",
      onClick: () => {},
      isEntity: false,
      isBulk: false,
      iconUrl: tenant.cdnUrl + "/icons/edit-secondary.svg",
      hidden: !isAdmin,
      disabled: selectedSublineItems !== 1,
    },
  ];

  let planListingActions: IEnhancedTableMenuItem[] = [];
  planListingActions = [
    {
      title: "New",
      onClick: () => {},
      isEntity: false,
      isBulk: false,
      iconUrl: tenant.cdnUrl + "/icons/add-primary-red.svg",
      hidden: !isAdmin,
      disabled: false,
    },
    {
      title: "Edit",
      onClick: () => {},
      isEntity: false,
      isBulk: false,
      iconUrl: tenant.cdnUrl + "/icons/edit-secondary.svg",
      hidden: !isAdmin,
      disabled: selectedPlanItems !== 1,
    },
  ];

  let claimCoverListingActions: IEnhancedTableMenuItem[] = [];
  claimCoverListingActions = [
    {
      title: "New",
      onClick: () => {},
      isEntity: false,
      isBulk: false,
      iconUrl: tenant.cdnUrl + "/icons/add-primary-red.svg",
      hidden: !isAdmin,
      disabled: false,
    },
    {
      title: "Edit",
      onClick: () => {},
      isEntity: false,
      isBulk: false,
      iconUrl: tenant.cdnUrl + "/icons/edit-secondary.svg",
      hidden: !isAdmin,
      disabled: selectedClaimCoverItems !== 1,
    },
  ];

  let policyCoverListingActions: IEnhancedTableMenuItem[] = [];
  policyCoverListingActions = [
    {
      title: "New",
      onClick: () => {},
      isEntity: false,
      isBulk: false,
      iconUrl: tenant.cdnUrl + "/icons/add-primary-red.svg",
      hidden: !isAdmin,
      disabled: false,
    },
    {
      title: "Edit",
      onClick: () => {},
      isEntity: false,
      isBulk: false,
      iconUrl: tenant.cdnUrl + "/icons/edit-secondary.svg",
      hidden: !isAdmin,
      disabled: selectedPolicyCoverItems !== 1,
    },
  ];

  let clauseListingActions: IEnhancedTableMenuItem[] = [];
  clauseListingActions = [
    {
      title: "New",
      onClick: () => {},
      isEntity: false,
      isBulk: false,
      iconUrl: tenant.cdnUrl + "/icons/add-primary-red.svg",
      hidden: !isAdmin,
      disabled: false,
    },
    {
      title: "Edit",
      onClick: () => {},
      isEntity: false,
      isBulk: false,
      iconUrl: tenant.cdnUrl + "/icons/edit-secondary.svg",
      hidden: !isAdmin,
      disabled: selectedClauseItems !== 1,
    },
  ];

  //#region graphql hooks
  const client = useApolloClient();
  const entityResult = useQuery(getLineDetailsInfo(), {
    variables: { id: entityId },
    errorPolicy: "all",
    fetchPolicy: "no-cache",
  });

  const [activateAction] = useMutation(activateLine(), {
    variables: {
      entityId: entityId,
    },
    refetchQueries: [getLineDetailsInfo()],
  });
  const [deactivateAction] = useMutation(deactivateLine(), {
    variables: {
      entityId: entityId,
    },
    refetchQueries: [getLineDetailsInfo(), getSubLineList(), getPlanList()],
  });

  sublineListingActions[0].onClick = () => {
    setActionClicked("new");
    setSublineDrawerOpen(true);
  };
  sublineListingActions[1].onClick = () => {
    setActionClicked("edit");
    setSublineDrawerOpen(true);
  };

  planListingActions[0].onClick = () => {
    setActionClicked("new");
    setPlanDrawerOpen(true);
  };
  planListingActions[1].onClick = () => {
    setActionClicked("edit");
    setPlanDrawerOpen(true);
  };

  claimCoverListingActions[0].onClick = () => {
    setActionClicked("new");
    setClaimCoverDrawerOpen(true);
  };
  claimCoverListingActions[1].onClick = () => {
    setActionClicked("edit");
    setClaimCoverDrawerOpen(true);
  };

  policyCoverListingActions[0].onClick = () => {
    setActionClicked("new");
    setPolicyCoverDrawerOpen(true);
  };
  policyCoverListingActions[1].onClick = () => {
    setActionClicked("edit");
    setPolicyCoverDrawerOpen(true);
  };

  clauseListingActions[0].onClick = () => {
    setActionClicked("new");
    setClausePopUpOpen(true);
  };
  clauseListingActions[1].onClick = () => {
    setActionClicked("edit");
    setClausePopUpOpen(true);
  };

  const [tableData, setTableData] = useState<IListingData<any>>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 2,
    totalCount: 0,
  });
  const [planTableData, setPlanTableData] = useState<IListingData<any>>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 0,
    totalCount: 0,
  });
  const [claimCoverTableData, setClaimCoverTableData] = useState<
    IListingData<any>
  >({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 0,
    totalCount: 0,
  });
  const [policyCoverTableData, setPolicyCoverTableData] = useState<
    IListingData<any>
  >({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 0,
    totalCount: 0,
  });
  const [clauseTableData, setClauseTableData] = useState<IListingData<any>>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 0,
    totalCount: 0,
  });

  const sublineListResult = useQuery(getSubLineList(), {
    variables: {
      currentPage: 1,
      currentPageSize: tableData.pageSize,
      SelectedLineId: params.id,
    },
    errorPolicy: "all",
  });
  const planListResult = useQuery(getPlanList(), {
    variables: {
      currentPage: 1,
      currentPageSize: planTableData.pageSize,
      SelectedLineId: params.id,
    },
    errorPolicy: "all",
  });
  const claimCoverListResult = useQuery(getClaimCoverList(), {
    variables: {
      currentPage: 1,
      currentPageSize: claimCoverTableData.pageSize,
      SelectedLineId: params.id,
    },
    errorPolicy: "all",
  });
  const policyCoverListResult = useQuery(getPolicyCoverList(), {
    variables: {
      currentPage: 1,
      currentPageSize: policyCoverTableData.pageSize,
      SelectedLineId: params.id,
    },
    errorPolicy: "all",
  });
  const clauseListResult = useQuery(getClauseList(), {
    variables: {
      currentPage: 1,
      currentPageSize: planTableData.pageSize,
      selectedLineIds: params.id,
    },
    errorPolicy: "all",
  });

  const [sublinesViewConfig, setSublinesViewConfig] =
    useState<IDynamicDiplayView>();

  const [plansViewConfig, setPlansViewConfig] = useState<IDynamicDiplayView>();

  const [claimCoversViewConfig, setClaimCoversViewConfig] =
    useState<IDynamicDiplayView>();

  const [policyCoversViewConfig, setPolicyCoversViewConfig] =
    useState<IDynamicDiplayView>();

  const [clausesViewConfig, setClausesViewConfig] =
    useState<IDynamicDiplayView>();
  //#endregion

  //#region useEffect
  useEffect(() => {
    try {
      if (!entityResult.loading) {
        setLoadingState(false);
      }

      if (entityResult?.data) {
        const lineDetails = entityToLineDetails(entityResult?.data);

        //get application section properties
        const applicationProperties = entityView.sections[0];
        applicationProperties.properties.externalCode.value =
          lineDetails.externalCode;
        applicationProperties.properties.abbreviation.value =
          lineDetails.abbreviation;
        applicationProperties.properties.arabicName.value =
          lineDetails.arabicName;

        //get the system propeties section
        const systemProperties = entityView.sections[1];
        systemProperties.properties.createdBy.value = lineDetails.createdBy;
        systemProperties.properties.createdOn.value = dayjs(
          new Date(lineDetails.createdOn)
        ).format("DD/MM/YYYY");
        systemProperties.properties.modifiedBy.value = lineDetails.modifiedBy;
        systemProperties.properties.modifiedOn.value = dayjs(
          new Date(lineDetails.modifiedOn)
        ).format("DD/MM/YYYY");

        const newLeftEntityView = {
          ...entityView,
          sections: [applicationProperties, systemProperties],
        };

        setLineDetailsInfo(lineDetails);

        setUpdatedLeftSideInfo(newLeftEntityView);

        const dynamicView: IDynamicDiplayView = {
          name: "sublines",
          sections: [],
        };
        setSublinesViewConfig(dynamicView);

        const planDynamicView: IDynamicDiplayView = {
          name: "plans",
          sections: [],
        };
        setPlansViewConfig(planDynamicView);

        const claimCoversDynamicView: IDynamicDiplayView = {
          name: "claims",
          sections: [],
        };
        setClaimCoversViewConfig(claimCoversDynamicView);

        const policyCoversDynamicView: IDynamicDiplayView = {
          name: "policy",
          sections: [],
        };
        setPolicyCoversViewConfig(policyCoversDynamicView);

        const clausesDynamicView: IDynamicDiplayView = {
          name: "clauses",
          sections: [],
        };
        setClausesViewConfig(clausesDynamicView);
      }
    } catch (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  }, [entityResult?.data]);

  useEffect(() => {
    // Initial loading state set to true if any of the results is loading
    const isLoading =
      sublineListResult?.loading ||
      planListResult?.loading ||
      policyCoverListResult?.loading ||
      claimCoverListResult?.loading;
    setLoadingState(isLoading);

    // Handling errors
    if (
      sublineListResult?.error ||
      planListResult?.error ||
      policyCoverListResult?.error ||
      claimCoverListResult?.error ||
      clauseListResult?.error
    ) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }

    // Handling sublineListResult data
    if (sublineListResult?.data) {
      const sublineTableData = mapToLSublinesListingData(
        sublineListResult.data
      );
      setTableData({ ...sublineTableData, pageNumber: 0 });
    }

    // Handling planListResult data
    if (planListResult?.data) {
      const planTableData = mapToPlansListingData(planListResult.data);
      setPlanTableData({ ...planTableData, pageNumber: 0 });
    }

    // Handling policyCoverListResult data
    if (policyCoverListResult?.data) {
      const policyCoverTableData = mapToPolicyCoversListingData(
        policyCoverListResult.data
      );
      setPolicyCoverTableData({ ...policyCoverTableData, pageNumber: 0 });
    }

    // Handling claimCoverListResult data
    if (claimCoverListResult?.data) {
      const claimCoverTableData = mapToClaimCoversListingData(
        claimCoverListResult.data
      );
      setClaimCoverTableData({ ...claimCoverTableData, pageNumber: 0 });
    }

    // Handling clauseListResult data
    if (clauseListResult?.data) {
      const clausesTableData = mapToClausesListingData(clauseListResult.data);
      setClauseTableData({ ...clausesTableData, pageNumber: 0 });
    }

    // Finally, set loading state to false if none are loading
    if (!isLoading) {
      setLoadingState(false);
    }
  }, [
    sublineListResult?.loading,
    sublineListResult?.error,
    sublineListResult?.data,
    planListResult?.loading,
    planListResult?.error,
    planListResult?.data,
    policyCoverListResult?.loading,
    policyCoverListResult?.error,
    policyCoverListResult?.data,
    claimCoverListResult?.loading,
    claimCoverListResult?.error,
    claimCoverListResult?.data,
    clauseListResult?.loading,
    clauseListResult?.error,
    clauseListResult?.data,
  ]);

  useEffect(() => {
    // Reset the active tab to "Sublines & Plans" every time the lineId changes
    setFirstTabAsActiveTab(true);
  }, [params.id]);
  //#endregion

  //#region functions
  function handlePageChange(page: number) {
    setTableLoadingState(true);
    sublineListResult
      ?.fetchMore({
        variables: {
          currentPage: ++page,
          currentPageSize: tableData.pageSize,
        },
      })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const newApplicationList = mapToLSublinesListingData(
            fetchMoreResult.data
          );
          const updatedTableData = {
            ...newApplicationList,
            pageNumber: --page,
          };
          setTableData(updatedTableData);
          handlePlanListingEffectedBySublineChange();
        }
      })
      .finally(() => {
        setTableLoadingState(false);
      });
  }
  function handlePlanPageChange(page: number) {
    setPlanTableLoadingState(true);
    planListResult
      ?.fetchMore({
        variables: {
          currentPage: ++page,
          currentPageSize: planTableData.pageSize,
        },
      })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const newApplicationList = mapToPlansListingData(
            fetchMoreResult.data
          );
          const updatedTableData = {
            ...newApplicationList,
            pageNumber: --page,
          };
          setPlanTableData(updatedTableData);
        }
      })
      .finally(() => {
        setPlanTableLoadingState(false);
      });
  }
  function handleClaimCoverPageChange(page: number) {
    setClaimCoverTableLoadingState(true);
    claimCoverListResult
      ?.fetchMore({
        variables: {
          currentPage: ++page,
          currentPageSize: claimCoverTableData.pageSize,
        },
      })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const newApplicationList = mapToClaimCoversListingData(
            fetchMoreResult.data
          );
          const updatedTableData = {
            ...newApplicationList,
            pageNumber: --page,
          };
          setClaimCoverTableData(updatedTableData);
        }
      })
      .finally(() => {
        setClaimCoverTableLoadingState(false);
      });
  }
  function handlePolicyCoverPageChange(page: number) {
    setPolicyCoverTableLoadingState(true);
    policyCoverListResult
      ?.fetchMore({
        variables: {
          currentPage: ++page,
          currentPageSize: policyCoverTableData.pageSize,
        },
      })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const newApplicationList = mapToPolicyCoversListingData(
            fetchMoreResult.data
          );
          const updatedTableData = {
            ...newApplicationList,
            pageNumber: --page,
          };
          setPolicyCoverTableData(updatedTableData);
          handleClaimCoberListingEffectedByPolicyCoverChange();
        }
      })
      .finally(() => {
        setPolicyCoverTableLoadingState(false);
      });
  }
  function handleClausePageChange(page: number) {
    setClauseTableLoadingState(true);
    clauseListResult
      ?.fetchMore({
        variables: {
          currentPage: ++page,
          currentPageSize: clauseTableData.pageSize,
        },
      })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const newApplicationList = mapToClausesListingData(
            fetchMoreResult.data
          );
          const updatedTableData = {
            ...newApplicationList,
            pageNumber: --page,
          };
          setClauseTableData(updatedTableData);
        }
      })
      .finally(() => {
        setClauseTableLoadingState(false);
      });
  }

  function handleRowsPerPageChange(numberOfRecordsPerPage: number) {
    setTableLoadingState(true);
    setTableData({ ...tableData, pageNumber: 1 });
    sublineListResult
      ?.fetchMore({
        variables: {
          currentPage: 1,
          currentPageSize: numberOfRecordsPerPage,
        },
      })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const newApplicationList = mapToLSublinesListingData(
            fetchMoreResult.data
          );

          const updatedTableData = {
            ...newApplicationList,
            pageNumber: 0,
            pageSize: numberOfRecordsPerPage,
          };
          setTableData(updatedTableData);
        }
      })
      .finally(() => {
        setTableLoadingState(false);
      });
  }
  function handleRowsPerPlanPageChange(numberOfRecordsPerPage: number) {
    setPlanTableLoadingState(true);
    setPlanTableData({ ...planTableData, pageNumber: 1 });
    planListResult
      ?.fetchMore({
        variables: {
          currentPage: 1,
          currentPageSize: numberOfRecordsPerPage,
        },
      })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const newApplicationList = mapToPlansListingData(
            fetchMoreResult.data
          );

          const updatedTableData = {
            ...newApplicationList,
            pageNumber: 0,
            pageSize: numberOfRecordsPerPage,
          };
          setPlanTableData(updatedTableData);
        }
      })
      .finally(() => {
        setPlanTableLoadingState(false);
      });
  }
  function handleRowsPerClaimCoverPageChange(numberOfRecordsPerPage: number) {
    setClaimCoverTableLoadingState(true);
    setClaimCoverTableData({ ...claimCoverTableData, pageNumber: 1 });
    claimCoverListResult
      ?.fetchMore({
        variables: {
          currentPage: 1,
          currentPageSize: numberOfRecordsPerPage,
        },
      })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const newApplicationList = mapToClaimCoversListingData(
            fetchMoreResult.data
          );

          const updatedTableData = {
            ...newApplicationList,
            pageNumber: 0,
            pageSize: numberOfRecordsPerPage,
          };
          setClaimCoverTableData(updatedTableData);
        }
      })
      .finally(() => {
        setClaimCoverTableLoadingState(false);
      });
  }
  function handleRowsPerPolicyCoverPageChange(numberOfRecordsPerPage: number) {
    setPolicyCoverTableLoadingState(true);
    setPolicyCoverTableData({ ...policyCoverTableData, pageNumber: 1 });
    policyCoverListResult
      ?.fetchMore({
        variables: {
          currentPage: 1,
          currentPageSize: numberOfRecordsPerPage,
        },
      })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const newApplicationList = mapToPolicyCoversListingData(
            fetchMoreResult.data
          );

          const updatedTableData = {
            ...newApplicationList,
            pageNumber: 0,
            pageSize: numberOfRecordsPerPage,
          };
          setPolicyCoverTableData(updatedTableData);
        }
      })
      .finally(() => {
        setPolicyCoverTableLoadingState(false);
      });
  }
  function handleRowsPerClausePageChange(numberOfRecordsPerPage: number) {
    setClauseTableLoadingState(true);
    setClauseTableData({ ...clauseTableData, pageNumber: 1 });
    clauseListResult
      ?.fetchMore({
        variables: {
          currentPage: 1,
          currentPageSize: numberOfRecordsPerPage,
        },
      })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const newApplicationList = mapToClausesListingData(
            fetchMoreResult.data
          );

          const updatedTableData = {
            ...newApplicationList,
            pageNumber: 0,
            pageSize: numberOfRecordsPerPage,
          };
          setClauseTableData(updatedTableData);
        }
      })
      .finally(() => {
        setClauseTableLoadingState(false);
      });
  }

  const renderMainChildren = () => {
    const status =
      entityResult?.data?.PlanConfigManagement?.entities?.line?.views?.PlanConfigManagement_all?.properties?.Status.toLowerCase();
    const isLineActive = status === "active";

    return (
      <>
        <div style={{ marginTop: "20px" }}>
          <EnhancedStepperSpecificDesign
            activeStep={getLineStatus(status)}
            steps={steps}
            className={classes.stepper}
            showStepperButton
            buttonTitle={isLineActive ? "Deactivate" : "Activate"}
            buttonState={
              isLineActive ? activateButtonState : deactivateButtonState
            }
            buttonOnClick={() => {
              if (isLineActive) {
                const confirmation: IConfirmation = {
                  open: true,
                  title: "Deactivate",
                  message: `Are you sure you want to deactivate this line?`,
                  callback: async () => {
                    setDeactivateButtonState("loading");
                    deactivateAction()
                      .then((result) => {
                        if (isEmpty(result.errors)) {
                          setDeactivateButtonState("success");
                          toast.success(
                            <ToastSuccessMessage>
                              {
                                "Line successfully deactivated. Relevant sublines and plans are deactivated as well."
                              }
                            </ToastSuccessMessage>
                          );
                        } else {
                          setDeactivateButtonState(undefined);
                          toast.error(
                            <ToastErrorMessage>
                              {getError(result)}
                            </ToastErrorMessage>
                          );
                        }
                      })
                      .finally(() => {
                        setTimeout(() => {
                          setDeactivateButtonState(undefined);
                        }, 1000);
                      });
                  },
                  submitButtonText: "Yes",
                  cancelButtonText: "No",
                };
                dispatch(OpenConfirmationAction(confirmation));
              } else {
                const confirmation: IConfirmation = {
                  open: true,
                  title: "Activate",
                  message: `Are you sure you want to activate this line?`,
                  callback: async () => {
                    setActivateButtonState("loading");
                    activateAction()
                      .then((result) => {
                        if (isEmpty(result.errors)) {
                          setActivateButtonState("success");
                          toast.success(
                            <ToastSuccessMessage>
                              {"Line successfully activated."}
                            </ToastSuccessMessage>
                          );
                        } else {
                          setActivateButtonState(undefined);
                          toast.error(
                            <ToastErrorMessage>
                              {getError(result)}
                            </ToastErrorMessage>
                          );
                        }
                      })
                      .finally(() => {
                        setTimeout(() => {
                          setActivateButtonState(undefined);
                        }, 1000);
                      });
                  },
                  submitButtonText: "Yes",
                  cancelButtonText: "No",
                };
                dispatch(OpenConfirmationAction(confirmation));
              }
            }}
          />
        </div>
      </>
    );
  };

  const renderLeftSection = () => {
    return (
      <>
        <EntityInfoWidget
          background="rgb(193, 0, 0)"
          classification={{ classifications: {}, value: "" }}
          progress={100}
          supportsClassification={true}
          supportsProgressBar={true}
          title={lineDetailsInfo.lineName}
          view={updatedLeftSideInfo}
        ></EntityInfoWidget>
      </>
    );
  };

  const tabs = () => {
    lineDetailsTabs.tabs[0].widgets[0].children = sublinesViewConfig && (
      <>
        <WidgetSection style={{ margin: "-23px 0 0 0" }}>
          <form>
            {sublineListResult?.loading ? (
              <div></div>
            ) : (
              <div
                style={{
                  marginTop: "20px",
                  padding: "-23px 0 0 0 !important",
                }}
              >
                <EnhancedTable
                  title={capitalizeFirstLetter("Sublines")}
                  inlineTitle={capitalizeFirstLetter("Sublines")}
                  name="count"
                  entityName={"Subline"}
                  entityIdColumnName={"planConfigManagement_Subline_Id"}
                  showCellFullData={true}
                  isTitlePascalCase={true}
                  orderByAscendingByDefault
                  defaultOrderByColumn="name"
                  inline={true}
                  data={tableData}
                  headers={headers}
                  handlePageChange={(page: number) => handlePageChange(page)}
                  handleRowsPerPageChange={(page: number) =>
                    handleRowsPerPageChange(page)
                  }
                  currentPage={tableData.pageNumber}
                  hideToolbar={false}
                  usePagination
                  disableSelection={false}
                  actions={sublineListingActions}
                  loader={tableLoadingState}
                  showTablePagination={true}
                  onSelectionChange={(newDetailedSelection) => {
                    setSelectedSublineItems(newDetailedSelection.length);
                    if (newDetailedSelection.length > 0) {
                      setSelectedSublineEntityId(newDetailedSelection[0].id);
                    } else {
                      setSelectedSublineEntityId("");
                    }
                  }}
                />
                {sublineDrawerOpen && (
                  <SublineDrawer
                    open={sublineDrawerOpen}
                    lineId={entityId}
                    lineName={
                      entityResult?.data?.PlanConfigManagement?.entities?.line
                        ?.views?.PlanConfigManagement_all?.properties?.Name
                    }
                    sublineId={
                      actionClicked === "new" ? null : selectedSublineEntityId
                    }
                    lineStatus={entityResult?.data?.PlanConfigManagement?.entities?.line?.views?.PlanConfigManagement_all?.properties?.Status.toLowerCase()}
                    onClose={() => setSublineDrawerOpen(false)}
                    onSuccess={() => {
                      handlePageChange(0);
                    }}
                  />
                )}
              </div>
            )}
          </form>
          {plansViewConfig && (
            <form>
              {planListResult?.loading ? (
                <div></div>
              ) : (
                <div
                  style={{
                    marginTop: "20px",
                    padding: "-23px 0 0 0 !important",
                  }}
                >
                  <EnhancedTable
                    title={capitalizeFirstLetter("Plans")}
                    inlineTitle={capitalizeFirstLetter("Plans")}
                    name="count"
                    entityName={"Plan"}
                    entityIdColumnName={"planConfigManagement_Plan_Id"}
                    showCellFullData={true}
                    isTitlePascalCase={true}
                    orderByAscendingByDefault
                    defaultOrderByColumn="name"
                    inline={true}
                    data={planTableData}
                    headers={planHeaders}
                    handlePageChange={(page: number) =>
                      handlePlanPageChange(page)
                    }
                    handleRowsPerPageChange={(page: number) =>
                      handleRowsPerPlanPageChange(page)
                    }
                    currentPage={planTableData.pageNumber}
                    hideToolbar={false}
                    usePagination
                    disableSelection={false}
                    actions={planListingActions}
                    loader={planTableLoadingState}
                    showTablePagination={true}
                    onSelectionChange={(newDetailedSelection) => {
                      setSelectedPlanItems(newDetailedSelection.length);
                      if (newDetailedSelection.length > 0) {
                        setSelectedPlanEntityId(newDetailedSelection[0].id);
                        setSelectedPlanSublineID(
                          newDetailedSelection[0].sublineID
                        );
                        setSelectedPlanSublineStatus(
                          newDetailedSelection[0].sublineStatus
                        );
                      } else {
                        setSelectedPlanEntityId("");
                        setSelectedPlanSublineID("");
                        setSelectedPlanSublineStatus("");
                      }
                    }}
                  />
                  {planDrawerOpen && (
                    <PlanDrawer
                      open={planDrawerOpen}
                      lineId={entityId}
                      lineName={
                        entityResult?.data?.PlanConfigManagement?.entities?.line
                          ?.views?.PlanConfigManagement_all?.properties?.Name
                      }
                      planId={
                        actionClicked === "new" ? null : selectedPlanEntityId
                      }
                      lineStatus={entityResult?.data?.PlanConfigManagement?.entities?.line?.views?.PlanConfigManagement_all?.properties?.Status.toLowerCase()}
                      sublineStatus={selectedPlanSublineStatus}
                      isSublineNameDisabled={
                        actionClicked === "edit" ? true : false
                      }
                      sublineID={selectedPlanSublineID}
                      onClose={() => setPlanDrawerOpen(false)}
                      onSuccess={() => {
                        handlePlanPageChange(0);
                      }}
                    />
                  )}
                </div>
              )}
            </form>
          )}
        </WidgetSection>
      </>
    );

    lineDetailsTabs.tabs[1].widgets[0].children = claimCoversViewConfig && (
      <>
        <WidgetSection style={{ margin: "-23px 0 0 0" }}>
          {policyCoversViewConfig && (
            <form>
              {policyCoverListResult?.loading ? (
                <div></div>
              ) : (
                <div
                  style={{
                    marginTop: "20px",
                    padding: "-23px 0 0 0 !important",
                  }}
                >
                  <EnhancedTable
                    title={capitalizeFirstLetter("Policy Covers")}
                    inlineTitle={capitalizeFirstLetter("Policy Covers")}
                    name="count"
                    entityName={"PolicyCover"}
                    entityIdColumnName={"planConfigManagement_PolicyCover_Id"}
                    showCellFullData={true}
                    isTitlePascalCase={true}
                    orderByAscendingByDefault
                    defaultOrderByColumn="name"
                    inline={true}
                    data={policyCoverTableData}
                    headers={policyCoverHeaders}
                    handlePageChange={(page: number) =>
                      handlePolicyCoverPageChange(page)
                    }
                    handleRowsPerPageChange={(page: number) =>
                      handleRowsPerPolicyCoverPageChange(page)
                    }
                    currentPage={policyCoverTableData.pageNumber}
                    hideToolbar={false}
                    usePagination
                    disableSelection={false}
                    actions={policyCoverListingActions}
                    loader={policyCoverTableLoadingState}
                    showTablePagination={true}
                    onSelectionChange={(newDetailedSelection) => {
                      setSelectedPolicyCoverItems(newDetailedSelection.length);
                      if (newDetailedSelection.length > 0) {
                        setSelectedPolicyCoverEntityId(
                          newDetailedSelection[0].id
                        );
                      } else {
                        setSelectedPolicyCoverEntityId("");
                      }
                    }}
                  />
                  {policyCoverDrawerOpen && (
                    <PolicyCoverDrawer
                      open={policyCoverDrawerOpen}
                      lineId={entityId}
                      lineName={
                        entityResult?.data?.PlanConfigManagement?.entities?.line
                          ?.views?.PlanConfigManagement_all?.properties?.Name
                      }
                      policyCoverId={
                        actionClicked === "new"
                          ? null
                          : selectedPolicyCoverEntityId
                      }
                      onClose={() => setPolicyCoverDrawerOpen(false)}
                      onSuccess={() => {
                        handlePolicyCoverPageChange(0);
                      }}
                    />
                  )}
                </div>
              )}
            </form>
          )}
          {claimCoversViewConfig && (
            <form>
              {claimCoverListResult?.loading ? (
                <div></div>
              ) : (
                <div
                  style={{
                    marginTop: "20px",
                    padding: "-23px 0 0 0 !important",
                  }}
                >
                  <EnhancedTable
                    title={capitalizeFirstLetter("Claim Covers")}
                    inlineTitle={capitalizeFirstLetter("Claim Covers")}
                    name="count"
                    entityName={"ClaimCover"}
                    entityIdColumnName={"planConfigManagement_ClaimCover_Id"}
                    showCellFullData={true}
                    isTitlePascalCase={true}
                    orderByAscendingByDefault
                    defaultOrderByColumn="name"
                    inline={true}
                    data={claimCoverTableData}
                    headers={claimCoverHeaders}
                    handlePageChange={(page: number) =>
                      handleClaimCoverPageChange(page)
                    }
                    handleRowsPerPageChange={(page: number) =>
                      handleRowsPerClaimCoverPageChange(page)
                    }
                    currentPage={claimCoverTableData.pageNumber}
                    hideToolbar={false}
                    usePagination
                    disableSelection={false}
                    actions={claimCoverListingActions}
                    loader={claimCoverTableLoadingState}
                    showTablePagination={true}
                    onSelectionChange={(newDetailedSelection) => {
                      setSelectedClaimCoverItems(newDetailedSelection.length);
                      if (newDetailedSelection.length > 0) {
                        setSelectedClaimCoverEntityId(
                          newDetailedSelection[0].id
                        );
                      } else {
                        setSelectedClaimCoverEntityId("");
                      }
                    }}
                  />
                  {claimCoverDrawerOpen && (
                    <ClaimCoverDrawer
                      open={claimCoverDrawerOpen}
                      lineId={entityId}
                      lineName={
                        entityResult?.data?.PlanConfigManagement?.entities?.line
                          ?.views?.PlanConfigManagement_all?.properties?.Name
                      }
                      claimCoverId={
                        actionClicked === "new"
                          ? null
                          : selectedClaimCoverEntityId
                      }
                      onClose={() => setClaimCoverDrawerOpen(false)}
                      onSuccess={() => {
                        handleClaimCoverPageChange(0);
                      }}
                    />
                  )}
                </div>
              )}
            </form>
          )}
        </WidgetSection>
      </>
    );

    lineDetailsTabs.tabs[2].widgets[0].children = clausesViewConfig && (
      <>
        <WidgetSection style={{ margin: "-23px 0 0 0" }}>
          <form>
            {clauseListResult?.loading ? (
              <div></div>
            ) : (
              <div
                style={{
                  marginTop: "20px",
                  padding: "-23px 0 0 0 !important",
                }}
              >
                <EnhancedTable
                  title={capitalizeFirstLetter("Clauses")}
                  inlineTitle={capitalizeFirstLetter("Clauses")}
                  name="count"
                  entityName={"Clause"}
                  entityIdColumnName={"planConfigManagement_Clause_Id"}
                  showCellFullData={false}
                  isTitlePascalCase={true}
                  orderByAscendingByDefault
                  defaultOrderByColumn="name"
                  inline={true}
                  data={clauseTableData}
                  headers={clauseHeaders}
                  handlePageChange={(page: number) =>
                    handleClausePageChange(page)
                  }
                  handleRowsPerPageChange={(page: number) =>
                    handleRowsPerClausePageChange(page)
                  }
                  currentPage={clauseTableData.pageNumber}
                  hideToolbar={false}
                  usePagination
                  disableSelection={false}
                  actions={clauseListingActions}
                  loader={clauseTableLoadingState}
                  showTablePagination={true}
                  onSelectionChange={(newDetailedSelection) => {
                    setSelectedClauseItems(newDetailedSelection.length);
                    if (newDetailedSelection.length > 0) {
                      setSelectedClauseEntityId(newDetailedSelection[0].id);
                    } else {
                      setSelectedClauseEntityId("");
                    }
                  }}
                />
                {clausePopUpOpen && (
                  <ClausePopUp
                    open={clausePopUpOpen}
                    lineId={entityId}
                    lineName={
                      entityResult?.data?.PlanConfigManagement?.entities?.line
                        ?.views?.PlanConfigManagement_all?.properties?.Name
                    }
                    clauseId={
                      actionClicked === "new" ? null : selectedClauseEntityId
                    }
                    onClose={() => setClausePopUpOpen(false)}
                    onSuccess={() => {
                      handleClausePageChange(0);
                    }}
                  />
                )}
              </div>
            )}
          </form>
        </WidgetSection>
      </>
    );

    return lineDetailsTabs;
  };

  const handlePlanListingEffectedBySublineChange = async () => {
    try {
      client.refetchQueries({
        include: [getPlanList()],
      });
    } catch (error) {
      toast.error(<ToastErrorMessage>{error}</ToastErrorMessage>);
    }
  };
  const handleClaimCoberListingEffectedByPolicyCoverChange = async () => {
    try {
      client.refetchQueries({
        include: [getClaimCoverList()],
      });
    } catch (error) {
      toast.error(<ToastErrorMessage>{error}</ToastErrorMessage>);
    }
  };
  //#endregion

  return loadingState ? (
    <Loader />
  ) : (
    <TabsLayout
      name="lineDetailsPage"
      layout={tabs()}
      theme={tenant.theme}
      leftChildren={renderLeftSection()}
      mainChildren={renderMainChildren()}
      cdnUrl={tenant.cdnUrl}
      userInfo={user["info"]}
      tabPanelClassName={classes.tabPanelStyle}
      firstTabAsActiveTab={firstTabAsActiveTab}
    />
  );
};

export default LineDetailsPage;
