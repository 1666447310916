import { DynamicFormInputType, FormInputTypes } from "../../DynamicForm";
import { isEmpty } from "../../utils/validationUtils";

export const initialValues = {
  policyNumber: "",
};

export const inputs: Record<string, DynamicFormInputType> = {
  policyNumber: {
    name: "policyNumber",
    title: "Policy Number",
    type: FormInputTypes.text,
    placeholder: "Policy Number",
    value: !isEmpty(initialValues.policyNumber)
      ? initialValues.policyNumber
      : "",
    required: true,
  },
};
