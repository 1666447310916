import React, { useEffect, useState } from "react";
import GenericDrawer from "../../components/common/generic-drawer/GenericDrawer";
import {
  DynamicFormInputType,
  IDynamicSection,
  IFormDateDynamicProps,
  IFormSelectDynamicProps,
} from "../../DynamicForm";
import { toast } from "react-toastify";
import { EnhancedButtonStatus } from "../../components/common/EnhancedButton";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { inputs } from "./content";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import Loader from "../../components/Loader";
import { cloneDeep, isEmpty } from "lodash";
import {
  DEFAULT_ERROR_TEXT,
  SEND_TO_BACKEND_DATE_FORMAT,
} from "../../constants";
import { IPolicyDetailsDrawerProps } from ".";
import SectionDynamicForm from "../../DynamicForm/SectionDynamicForm";
import { getListForm, getModelsByBrands, updatePolicy } from "./queries";
import { toLookups } from "./utils";
import { getError, lookupListAsRecordObject } from "../../utils/graph-utils";
import { normaliseDynamicValues } from "../../utils/dynamic-utils";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import { formatDateTime } from "../../utils/formatting-utils";
import { useAppSelector } from "../../redux/hooks";

const PolicyDetailsDrawer: React.FC<IPolicyDetailsDrawerProps> = ({
  policyId,
  open,
  onSuccess,
  onClose,
  policyDetailsInfo,
}) => {
  const user = useAppSelector((state) => state.user);
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();
  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);

  const [loadingFields, setLoadingFields] = useState<Record<string, boolean>>({
    model: false,
  });

  const policyDetailsListResults = useQuery(getListForm());
  const [getModelsByBrandsLazy, modelByBrandsQueryRes] = useLazyQuery(
    getModelsByBrands()
  );

  const [policyAction] = useMutation(updatePolicy());

  const sections: Record<string, IDynamicSection> = {
    section1: {
      title: "Policy Detail",
      inputs: {
        policyNumber: inputsForm.policyNumber,
        isRenewal: inputsForm.isRenewal,
        renewalNumber: inputsForm.renewalNumber,
        policyEffectiveDate: inputsForm.policyEffectiveDate,
        policyIssueDate: inputsForm.policyIssueDate,
        policyExpiryDate: inputsForm.policyExpiryDate,
        product: inputsForm.product,
        towingService: inputsForm.towingService,
        vignetteCode: inputsForm.vignetteCode,
        vignette: inputsForm.vignette,
      },
    },
    section2: {
      title: "Customer Details",
      inputs: {
        nameOnLicenseEn: inputsForm.nameOnLicenseEn,
        nameOnLicenseAr: inputsForm.nameOnLicenseAr,
        firstName: inputsForm.firstName,
        middleName: inputsForm.middleName,
        lastName: inputsForm.lastName,
        email: inputsForm.email,
        phoneType: inputsForm.phoneType,
        mobileNumber: inputsForm.mobileNumber,
        country: inputsForm.country,
        city: inputsForm.city,
        street: inputsForm.street,
        building: inputsForm.building,
      },
    },
    section3: {
      title: "Vehicle Details",
      inputs: {
        plateCode: inputsForm.plateCode,
        plateNumber: inputsForm.plateNumber,
        usageType: inputsForm.usageType,
        bodyType: inputsForm.bodyType,
        engineType: inputsForm.engineType,
        brand: inputsForm.brand,
        model: inputsForm.model,
        yearOfMake: inputsForm.yearOfMake,
        motor: inputsForm.motor,
        weight: inputsForm.weight,
        engineSize: inputsForm.engineSize,
        seats: inputsForm.seats,
        chassis: inputsForm.chassis,
        horsePower: inputsForm.horsePower,
      },
    },
  };

  let today = new Date();
  let threeMonthsAgo = new Date(today);
  threeMonthsAgo.setMonth(today.getMonth() - 3);
  const userAgentType = user.info.agentType;

  useEffect(() => {
    initialize();
  }, [policyDetailsListResults]);

  useEffect(() => {
    if (modelByBrandsQueryRes.loading) {
      setLoadingFields({ ...loadingFields, model: true });
    }
    if (modelByBrandsQueryRes.error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
    if (modelByBrandsQueryRes.data) {
      const newInputForm = cloneDeep(inputsForm);
      const models = lookupListAsRecordObject(
        modelByBrandsQueryRes.data?.Insurance?.lookups?.models
      );

      (newInputForm.model as IFormSelectDynamicProps).selectOptions = models;

      setInputsForm({ ...newInputForm });
      setLoadingFields({ ...loadingFields, model: false });
    }
  }, [modelByBrandsQueryRes]);

  const initialize = () => {
    try {
      const updatedInputs = cloneDeep(inputsForm);

      if (policyDetailsListResults.data) {
        const lookupLists = toLookups(policyDetailsListResults.data);

        (updatedInputs.phoneType as IFormSelectDynamicProps).selectOptions =
          lookupLists["phoneType"];

        (updatedInputs.isRenewal as IFormSelectDynamicProps).selectOptions =
          lookupLists["renewalStatus"];

        (updatedInputs.country as IFormSelectDynamicProps).selectOptions =
          lookupLists["countries"];

        (updatedInputs.brand as IFormSelectDynamicProps).selectOptions =
          lookupLists["brands"];

        (updatedInputs.plateCode as IFormSelectDynamicProps).selectOptions =
          lookupLists["plateCode"];
      }

      (updatedInputs.policyEffectiveDate as IFormDateDynamicProps).minDate =
        userAgentType === "INTERNAL" ? threeMonthsAgo : new Date();

      (updatedInputs.policyEffectiveDate as IFormDateDynamicProps).onChange = (
        event
      ) => {
        const newDate = new Date(event);
        var date = new Date(
          newDate.getFullYear() + 1,
          newDate.getMonth(),
          newDate.getDate()
        );
        setInputsForm((currentInputsForm) => ({
          ...currentInputsForm,
          policyExpiryDate: {
            ...currentInputsForm.policyExpiryDate,
            value: date.toISOString(),
          },
        }));
      };

      if (policyDetailsInfo) {
        updatedInputs.policyNumber.value = policyDetailsInfo.policyNumber;
        updatedInputs.isRenewal.value = policyDetailsInfo.isRenewal;
        updatedInputs.renewalNumber.value = policyDetailsInfo.renewalNumber;
        updatedInputs.policyEffectiveDate.value =
          policyDetailsInfo.policyEffectiveDate;
        updatedInputs.policyIssueDate.value = policyDetailsInfo.policyIssueDate;
        updatedInputs.policyExpiryDate.value =
          policyDetailsInfo.policyExpiryDate;
        updatedInputs.product.value = policyDetailsInfo.plan;
        updatedInputs.productCode.value = policyDetailsInfo.productCode;
        updatedInputs.towingService.value = policyDetailsInfo.towingService;
        updatedInputs.vignetteCode.value = policyDetailsInfo.vignetteCode;
        updatedInputs.vignette.value = policyDetailsInfo.vignette;

        updatedInputs.nameOnLicenseEn.value = policyDetailsInfo.nameOnLicenseEn;
        updatedInputs.nameOnLicenseAr.value = policyDetailsInfo.nameOnLicenseAr;
        updatedInputs.firstName.value = policyDetailsInfo.firstName;
        updatedInputs.middleName.value = policyDetailsInfo.middleName;
        updatedInputs.lastName.value = policyDetailsInfo.lastName;
        updatedInputs.email.value = policyDetailsInfo.email;
        updatedInputs.phoneType.value = policyDetailsInfo.phoneType;
        updatedInputs.mobileNumber.value = policyDetailsInfo.mobileNumber;
        updatedInputs.country.value = policyDetailsInfo.countryId;
        updatedInputs.city.value = policyDetailsInfo.city;
        updatedInputs.street.value = policyDetailsInfo.street;
        updatedInputs.building.value = policyDetailsInfo.building;

        updatedInputs.plateCode.value = policyDetailsInfo.plateCode;
        updatedInputs.plateNumber.value = policyDetailsInfo.plateNumber;
        updatedInputs.usageType.value = policyDetailsInfo.usageType;
        updatedInputs.bodyType.value = policyDetailsInfo.bodyType;
        updatedInputs.engineType.value = policyDetailsInfo.engineType;
        updatedInputs.brand.value = policyDetailsInfo.brandId;
        updatedInputs.model.value = policyDetailsInfo.modelId;
        updatedInputs.yearOfMake.value = policyDetailsInfo.yearOfMake;
        updatedInputs.motor.value = policyDetailsInfo.motor;
        updatedInputs.weight.value = policyDetailsInfo.weight;
        updatedInputs.engineSize.value = policyDetailsInfo.engineSize;
        updatedInputs.seats.value = policyDetailsInfo.seats;
        updatedInputs.chassis.value = policyDetailsInfo.chassis;
        updatedInputs.horsePower.value = policyDetailsInfo.horsePower;
      }

      if (updatedInputs.isRenewal.value == "YES") {
        updatedInputs.renewalNumber.hidden = false;
        updatedInputs.renewalNumber.required = true;
      } else {
        updatedInputs.renewalNumber.hidden = true;
      }

      if (updatedInputs.brand.value) {
        getModelsByBrandsLazy({
          variables: { modelId: updatedInputs.brand.value },
        });
      }

      if (updatedInputs.productCode.value == "BodilyInjury") {
        updatedInputs.vignetteCode.hidden = false;
        updatedInputs.vignetteCode.required = true;

        updatedInputs.vignette.hidden = false;
        updatedInputs.vignette.required = true;
      } else {
        updatedInputs.vignetteCode.hidden = true;
        updatedInputs.vignette.hidden = true;
      }

      setInputsForm(updatedInputs);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputs, values);

    if (data.isRenewal == "NO") {
      data.renewalNumber = null;
    }
    setFormDisabled(true);
    setSubmitButtonState("loading");

    try {
      let variablesMutation = {
        entityId: policyId,
        country: data.country,
        city: data.city,
        street: data.street,
        building: data.building || null,
        mobileNumber: data.mobileNumber,
        isRenewal: data.isRenewal,
        renewalNumber: data.renewalNumber,
        brand: data.brand,
        model: data.model,
        chassis: data.chassis,
        motor: data.motor,
        nameOnLicenseAr: data.nameOnLicenseAr,
        nameOnLicenseEn: data.nameOnLicenseEn,
        firstName: data.firstName,
        middleName: data.middleName,
        lastName: data.lastName,
        horsePower: parseInt(data.horsePower as unknown as string),
        phoneType: data.phoneType,
        plateCode: !isEmpty(data.plateCode) ? data.plateCode : undefined,
        plateNumber: !isEmpty(data.plateNumber)
          ? `${data.plateNumber}`
          : undefined,
        vignetteCode: !isEmpty(data.vignetteCode)
          ? data.vignetteCode
          : undefined,
        vignette: data.vignette ? Number(data.vignette) : undefined,
        effectiveDate: formatDateTime(
          data.PolicyEffectiveDate,
          SEND_TO_BACKEND_DATE_FORMAT
        ),
      };

      policyAction({
        variables: variablesMutation,
        errorPolicy: "all",
      }).then((res) => {
        if (isEmpty(res.errors)) {
          toast.success(
            <ToastSuccessMessage>
              {"Policy Updated Successfully"}
            </ToastSuccessMessage>
          );
          setTimeout(() => {
            setSubmitButtonState("success");
            onSuccess();
            onClose();
          }, 500);
        } else {
          setSubmitButtonState(undefined);
          toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
        }
      });
    } catch {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setFormDisabled(false);
    }
  };

  return (
    <GenericDrawer
      title="Edit Policy Details"
      onClose={() => onClose()}
      isOpen={open}
    >
      {policyDetailsListResults.loading && open ? (
        <Loader />
      ) : (
        <>
          <SectionDynamicForm
            onSubmit={(values) => submitForm(values)}
            buttonText={"Submit"}
            submitButtonState={submitButtonState}
            disableForm={formDisabled}
            inputs={inputsForm}
            sections={sections}
            loadingFields={loadingFields}
            onChange={(
              fieldName: string,
              value: string | string[],
              allValues
            ) => {
              //TODO: refactor code in way to map values automatically

              const formInputs = cloneDeep(inputsForm);

              if (fieldName === "isRenewal") {
                if (value == "YES") {
                  formInputs.renewalNumber.required = true;
                  formInputs.renewalNumber.hidden = false;
                } else {
                  formInputs.renewalNumber.hidden = true;
                }

                Object.values(formInputs).forEach((input) => {
                  input.value = allValues[input.name];
                });

                setInputsForm({ ...formInputs });
              }

              if (fieldName === "brand") {
                getModelsByBrandsLazy({
                  variables: { modelId: value as string },
                });

                Object.values(formInputs).forEach((input) => {
                  input.value = allValues[input.name];
                });

                setInputsForm({ ...formInputs });
              }
            }}
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default PolicyDetailsDrawer;
