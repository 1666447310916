import * as React from 'react';
import { makeStyles } from 'tss-react/mui';
import { useAppSelector } from '../redux/hooks';

type AuthLayoutProps = {
  children?: React.ReactNode;
  mainContainer?: string | React.CSSProperties;
};

const useStyles = makeStyles<{
  background: string;
  mainContainer?: string | React.CSSProperties;
}>()((theme, { background, mainContainer }) => ({
  mainContainer: {
    width: '100%',
    height: '100vh',
    padding: mainContainer ? '0' : '20px 0',
    background: mainContainer ? 'none' : `${background}`,
    ...(mainContainer && typeof mainContainer === 'object'
      ? mainContainer
      : {}),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
}));

const AuthLayout: React.FC<AuthLayoutProps> = ({ children, mainContainer }) => {
  const loginMeta = useAppSelector((state) => state.tenant.login);
  const background = loginMeta.background;
  const { classes: styles } = useStyles({ background, mainContainer });

  return <div className={styles.mainContainer}>{children}</div>;
};

export default AuthLayout;
