import React, { useEffect, useState } from "react";
import { IListingData, ListingDataItemType } from "../../models/listing";
import StaticLayout from "../../page-layout/static-layout/StaticLayout";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  createActionAsAgent,
  filterLovsQuery,
  listQuery,
  planDetailsQuery,
} from "./queries";
import { dataToLovs, mapToListingData } from "./utils";
import { filterSectionsContent, headers } from "./content";
import EnhancedTable from "../../components/enhanced-table/EnhancedTable";
import { IListingFilterWidgetSection } from "../../components/widgets/custom-listing-filter";
import ListingFilterWidget from "../../components/widgets/custom-listing-filter/ListingFilterWidget";
import { IAbstractRecord } from "../../models";
import _, { isEmpty } from "lodash";
import { getFilter, setFilter } from "../../utils/filter-utils";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { toast } from "react-toastify";
import ApplicationDrawer from "../../forms/application-drawer/ApplicationDrawer";
import {
  IEnhanceTableHeaderClickable,
  IEnhancedTableMenuItem,
} from "../../components/enhanced-table";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { capitalizeFirstLetter } from "../../utils/formatting-utils";
import {
  DEFAULT_ERROR_TEXT,
  MAIN_ONE_THEME,
  SEND_TO_BACKEND_DATE_FORMAT,
} from "../../constants";
import { makeStyles } from "tss-react/mui";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import { getError } from "../../utils/graph-utils";
import { useAppSelector } from "../../redux/hooks";
import ApplicationDrawerWithMultipleAgencies from "../../forms/application-with-multiple-agencies-drawer/ApplicationDrawerWithMultipleAgencies";

interface IApplicationsPage {}

const useStyles = makeStyles()(() => ({
  linkHolder: {
    width: "130px",
    fontSize: "12px",
    marginTop: "10px",
    marginBottom: "10px",
  },
  linkStyle: {
    color: MAIN_ONE_THEME.palette.primary5.main,
  },
}));

const ApplicationsPage: React.FC<IApplicationsPage> = ({}) => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const user = useAppSelector((state) => state.user);

  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [
    applicationWithMultipleAgencieDrawerOpen,
    setApplicationWithMultipleAgencieDrawerOpen,
  ] = useState<boolean>(false);

  const [loadingState, setLoadingState] = useState<boolean>(false);

  const [tableLoadingState, setTableLoadingState] = useState<boolean>(false);

  const FILTER_SESSION_KEY = "applicationsFilter";

  const [filterSections, setFilterSections] =
    useState<IListingFilterWidgetSection[]>();

  const [agentType, setAgentType] = useState<string>("");

  const initialfilterValues =
    Object.keys(getFilter(FILTER_SESSION_KEY)).length > 0
      ? getFilter(FILTER_SESSION_KEY)
      : {
          agency: [],
          plan: [],
          effectiveDate: [],
          createdDate: [],
          status: ["QUOTE_PREPARATION", "QUOTE_CONFIRMATION", "CLOSED"]
        };

  const [filterValues, setFilterValues] =
    useState<IAbstractRecord>(initialfilterValues);

  const [tableData, setTableData] = useState<IListingData<any>>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 0,
    totalCount: 0,
  });

  const [getApplicationsLazy, { loading, error, data, fetchMore }] =
    useLazyQuery(listQuery(), {
      variables: {
        currentPage: 1,
        currentPageSize: tableData.pageSize,
        agencyId:
          filterValues?.agency && filterValues?.agency?.length > 0
            ? filterValues?.agency
            : null,
        product:
          filterValues?.plan && filterValues?.plan?.length > 0
            ? filterValues?.plan
            : null,
        effectiveDateFrom: filterValues?.effectiveDate[0]
          ? dayjs(filterValues.effectiveDate[0]).format(
              SEND_TO_BACKEND_DATE_FORMAT
            )
          : null,

        effectiveDateTo: filterValues?.effectiveDate[1]
          ? dayjs(filterValues.effectiveDate[1]).format(
              SEND_TO_BACKEND_DATE_FORMAT
            )
          : null,

        createdDateFrom: filterValues?.createdDate[0]
          ? dayjs(filterValues.createdDate[0]).format(
              SEND_TO_BACKEND_DATE_FORMAT
            )
          : null,
        createdDateTo: filterValues?.createdDate[1]
          ? dayjs(filterValues.createdDate[1]).format(
              SEND_TO_BACKEND_DATE_FORMAT
            )
          : null,
          selectedStatuses: filterValues?.status && filterValues?.status?.length > 0
          ? filterValues?.status
          : null,
      },
    });

  const [getLovsLazy, filterLovsResponse] = useLazyQuery(filterLovsQuery(), {
    fetchPolicy: "no-cache",
  });
  const [mutateFunction] = useMutation(createActionAsAgent());

  useEffect(() => {
    getApplicationsLazy();
    getLovsLazy();

    setAgentType(
      user.userRoles.includes("Insurance-Underwriter")
        ? "underwriter"
        : user.userRoles.includes("Insurance-QuotingAgent") ||
          user.userRoles.includes("Insurance-IssuingAgent")
        ? "agent"
        : ""
    );
  }, []);

  let actions: IEnhancedTableMenuItem[] = [];
  // If user is Business User, the new motor application details will be created directly without a drawer
  // and directed to the new created page

  if (data?.Insurance?.actions?.able_To_CreateApplicationAsAgent) {
    actions = [
      {
        title: "+ New",
        onClick: () => {},
        isEntity: false,
        isBulk: false,
        iconUrl: "",
      },
    ];
    actions[0].onClick = () => {
      user.info.agencyId.length === 0 || user.info.agencyId.length === 1
        ? submitForm()
        : setApplicationWithMultipleAgencieDrawerOpen(true);
    };
  }
  // If user is Underwriter, a drawer will show to be filled accordingly
  // and then the submit of the drawer will direct to the new created page
  else if (data?.Insurance?.actions?.able_To_CreateApplicationAsUnderwriter) {
    actions = [
      {
        title: "+ New",
        onClick: () => {},
        isEntity: false,
        isBulk: false,
        iconUrl: "",
      },
    ];

    actions[0].onClick = () => {
      user.info.agencyId.length === 0 || user.info.agencyId.length === 1
        ? setDrawerOpen(true)
        : setApplicationWithMultipleAgencieDrawerOpen(true);
    };
  }

  const submitForm = async () => {
    try {
      const { data, errors } = await mutateFunction({
        variables: { lineOfBusiness: "Motor" },
      });
      if (isEmpty(errors)) {
        toast.success(
          <ToastSuccessMessage>
            {"Application created successfully"}
          </ToastSuccessMessage>
        );
        const newApplicationId =
          data.insurance.actions.createApplicationAsAgent.id;
        setTimeout(() => {
          navigate("/sales/applications/motor/" + newApplicationId);
        }, 500);
      } else {
        toast.error(<ToastErrorMessage>{getError(data)}</ToastErrorMessage>);
      }
    } catch {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
    }
  };

  useEffect(() => {
    if (filterLovsResponse?.loading) setLoadingState(true);
    if (filterLovsResponse?.error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
    if (filterLovsResponse?.data) {
      let savedFilters = getFilter(FILTER_SESSION_KEY);
      if (isEmpty(savedFilters)) {
        savedFilters = _.cloneDeep(filterValues);
      }
      setFilterValues(savedFilters);

      const lovs = dataToLovs(filterLovsResponse.data);

      const newFilterSections = filterSectionsContent(
        lovs,
        savedFilters,
        user.userRoles
      );
      setFilterSections(newFilterSections);
    }
    setLoadingState(false);
  }, [filterLovsResponse]);

  useEffect(() => {
    if (loading) setTableLoadingState(true);
    if (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
    if (data) {
      const tableData = mapToListingData(data);

      convertToPolicyNumberJsxElement(tableData.pagedItems);
      setTableData({ ...tableData, pageNumber: 0 });
    }
    setTableLoadingState(false);
  }, [loading, error, data]);

  function handlePageChange(page: number) {
    setTableLoadingState(true);
    fetchMore({
      variables: {
        currentPage: ++page,
        currentPageSize: tableData.pageSize,
      },
    })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const newApplicationList = mapToListingData(fetchMoreResult.data);
          convertToPolicyNumberJsxElement(newApplicationList.pagedItems);

          const updatedTableData = {
            ...newApplicationList,
            pageNumber: --page,
          };
          setTableData(updatedTableData);
        }
      })
      .finally(() => {
        setTableLoadingState(false);
      });
  }

  function handleRowsPerPageChange(numberOfRecordsPerPage: number) {
    setTableLoadingState(true);
    setTableData({ ...tableData, pageNumber: 1 });
    fetchMore({
      variables: {
        currentPage: 1,
        currentPageSize: numberOfRecordsPerPage,
      },
    })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const newApplicationList = mapToListingData(fetchMoreResult.data);
          convertToPolicyNumberJsxElement(newApplicationList.pagedItems);

          const updatedTableData = {
            ...newApplicationList,
            pageNumber: 0,
            pageSize: numberOfRecordsPerPage,
          };
          setTableData(updatedTableData);
        }
      })
      .finally(() => {
        setTableLoadingState(false);
      });
  }

  (headers.name as IEnhanceTableHeaderClickable).callback = (payload: any) => {
    navigate("/sales/applications/motor/" + payload.columns.id);
  };

  const [getPlanDetailsLazy] = useLazyQuery(planDetailsQuery(), {});

  async function handleTooltipFetch(id: string): Promise<Record<string, any>> {
    let popoverRows: Record<string, any> = {};
    await getPlanDetailsLazy({
      variables: {
        id: id,
      },
    }).then((response) => {
      response?.data?.Insurance?.entities?.application?.queries?.insurance?.applicationQuotes.forEach(
        (obj: any, i: number) => {
          popoverRows[i] = {
            Plan: obj?.insuranceQuote_Product?.Title || "N/A",
            Coverage: obj?.insuranceQuoteMotorDetails_SumInsured,
            TotalPremium: obj?.insuranceQuote_TotalAnnualPremium,
            Status:
              capitalizeFirstLetter(obj?.insuranceQuote_Status, "_") || "N/A",
            StatusReason:
              capitalizeFirstLetter(obj?.insuranceQuote_StatusReason, "_") ||
              "N/A",
          };
        }
      );
    });
    return popoverRows;
  }
  headers.plan.tooltipSettings.tooltipFetch = handleTooltipFetch;

  const renderMainChildren = () => {
    return (
      <>
        {loading ? (
          <div></div>
        ) : (
          <div style={{ marginTop: "20px" }}>
            <EnhancedTable
              title="Motor Applications"
              name="count"
              orderByAscendingByDefault
              inline={false}
              data={tableData}
              headers={headers}
              handlePageChange={(page: number) => handlePageChange(page)}
              handleRowsPerPageChange={(page: number) =>
                handleRowsPerPageChange(page)
              }
              currentPage={tableData.pageNumber}
              hideToolbar
              usePagination
              disableSelection
              actions={actions}
              loader={tableLoadingState}
            />
            {drawerOpen && (
              <ApplicationDrawer
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                onSuccess={() => {
                  handlePageChange(0);
                }}
              />
            )}
            {applicationWithMultipleAgencieDrawerOpen && (
              <ApplicationDrawerWithMultipleAgencies
                open={applicationWithMultipleAgencieDrawerOpen}
                loggedInAgentId={user.info.agentId}
                loggedInuserFirstName={user.info.firstName}
                loggedInuserLastName={user.info.lastName}
                lineOfBusiness={"Motor"}
                agentType={agentType}
                onClose={() =>
                  setApplicationWithMultipleAgencieDrawerOpen(false)
                }
                onSuccess={() => {
                  handlePageChange(0);
                }}
              />
            )}
          </div>
        )}
      </>
    );
  };

  const onFilterUpdate = async (v: Record<string, any>) => {
    let newFilters = _.cloneDeep(v);
    setFilter(newFilters, FILTER_SESSION_KEY);
    setFilterValues(newFilters);
  };

  const renderFilter = () => {
    return (
      filterSections && (
        <ListingFilterWidget
          name={""}
          filters={filterSections}
          onApplyFilter={(v) => {
            onFilterUpdate(v);
          }}
        ></ListingFilterWidget>
      )
    );
  };

  const renderPolicyNumberLinks = (data: any): React.ReactNode => {
    return data?.map((element: { id: string; title: string }) => (
      <div className={classes.linkHolder}>
        <Link
          to={`/plm/policies/` + element.id}
          className={classes.linkStyle}
          target="_blank"
        >
          {element.title}
        </Link>
      </div>
    ));
  };

  const convertToPolicyNumberJsxElement = (data: ListingDataItemType<any>) => {
    Object.keys(data).forEach((id, index) => {
      if (data[index]) {
        data[index].policyNumber = renderPolicyNumberLinks(
          data[index]?.policyNumber
        );
      }
    });
  };

  return (
    <StaticLayout
      loading={loading || loadingState}
      name={"Applications"}
      leftChildren={renderFilter()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default ApplicationsPage;
