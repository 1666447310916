export interface IApplicationDetailsPageExpatProps {}

export interface IApplicationDetails {
  status: string;
  product: string;
  name: string;
  applicationName: string;
  lineOfBusiness: string;
  createdBy: string;
  createdOn: string;
  modifiedBy: string;
  modifiedOn: string;
  statusReason: string;
  
  effectiveDate: string;
  expiryDate: string;
  isRenewal: string;
  renewalNumber: string;

  title: string;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  phoneType: string;
  mobileNumber: string;
  country: string;
  city: string;
  street: string;
  building: string;

  insuredName: string;
  insuredFirstName: string;
  insuredMiddleName: string;
  insuredLastName: string;
  insuredDateofBirth: string;
  insuredNationality: string;
  insuredGender: string;
  insuredPassportNumber: string;
  insuredOccupation: string;

  agencyName: string;
  agentName: string;
  customerName: string;
}

export const initialValues: IApplicationDetails = {
  status: '',
  product: '',
  name: '',
  applicationName: '',
  lineOfBusiness: '',
  createdBy: '',
  createdOn: '',
  modifiedBy: '',
  modifiedOn: '',
  statusReason: '',

  effectiveDate: '',
  expiryDate: '',
  isRenewal: 'NO',
  renewalNumber: '',

  title: '',
  firstName: '',
  middleName: '',
  lastName: '',
  email: '',
  phoneType: 'MOBILE',
  mobileNumber: '',
  country: 'LB',
  city: '',
  street: '',
  building: '',

  insuredName: '',
  insuredFirstName: '',
  insuredMiddleName: '',
  insuredLastName: '',
  insuredDateofBirth: '',
  insuredNationality: '',
  insuredGender: '',
  insuredPassportNumber: '',
  insuredOccupation: '',

  agencyName: '',
  agentName: '',
  customerName: '',

};